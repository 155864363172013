class window.AuthenticationUtils
  userCreds = {}

  @connect_facebook_page: ->
    userCreds = {}
    $modal = $('#fb_page_selection.modal')
    fbReachMin = $('#fb-reach-min').val()
    modalTitle('Loading pages...')
    hideModalElementsExceptFor('loading')
    toggleButtons(hide = ['cancel-fb-page-submit', 'submit-fb-page'], show = [])
    $modal.modal('show')

    $.getScript '//connect.facebook.net/en_US/sdk.js', ->
      FB.init
        appId: $('#fb-app-id').val()
        version: 'v2.10'
      FB.login ((response) ->
        if response.authResponse && insightsProvided(response.authResponse.grantedScopes)
          userCreds = response
          FB.api '/me/accounts?fields=id,name,fan_count', (response) ->
            if response.data && response.data.length > 0
              pages = response.data
              pagesList = buildRadios(pages)
              $('#submit-fb-page').on 'click', ->
                AuthenticationUtils.submit_facebook_page(localStorageItemString||null)
              modalTitle('Select a Page')
              $('#fb_page_selection.modal .modal-body .pages-list').html(pagesList)
              hideModalElementsExceptFor('account-selection')
              toggleButtons(hide = [], show = ['cancel-fb-page-submit', 'submit-fb-page'])
            else
              modalTitle('No Pages Available')
              hideModalElementsExceptFor('no-pages')
              toggleButtons(hide = [], show = ['cancel-fb-page-submit'])
        else
          modalTitle('Error Authenticating')
          hideModalElementsExceptFor('error-authing')
          toggleButtons(hide = [], show = ['cancel-fb-page-submit'])
          $modal.modal('show')
      ), { scope: 'manage_pages,read_insights', return_scopes: true }

      insightsProvided = (scopes) ->
        _.includes(scopes, 'read_insights')

      buildRadios = (pages) ->
        listItems = ''
        for page in pages
          if page.fan_count < fbReachMin then continue
          listItems += [
            "<li class='radio'>",
              "<label>",
                "<input type='radio' name='page' ",
                  "id='page_" + page.id + "' ",
                  "value='" + page.id + "' ",
                  "data-page-name='" + page.name + "' ",
                  (if pages.length == 1 then 'checked' else ''),
                  ">",
                page.name,
              "</label>",
            "</li>"
          ].join ''

        listItems

  @connect_youtube: () ->
    win = window.open('/auth/google_youtube?location=default', '_blank')
    win.focus()

  @connect_instagram_page: (localStorageItemString)->
    userCreds = {}
    $modal = $('#ig_page_selection.modal')
    fbReachMin = $('#fb-reach-min').val()
    modalTitle('Loading pages...')
    hideModalElementsExceptFor('loading')
    toggleButtons(hide = ['cancel-ig-page-submit', 'submit-ig-page'], show = [])
    $modal.modal('show')

    $.getScript '//connect.facebook.net/en_US/sdk.js', ->
      FB.init
        appId: $('#ig-app-id').val()
        version: 'v3.0'
      FB.login ((response) ->
        if response.authResponse #&& insightsProvided(response.authResponse.grantedScopes)
          userCreds = response
          FB.api '/me/accounts?fields=instagram_business_account,name', (response) ->
            if response.data && response.data.length > 0
              pages = response.data
              # Need to only select pages with an instagram business account
              pages = pages.filter((page) ->
                page.instagram_business_account != undefined
              )
              # Rebuild the pages object
              pages = pages.map((page) ->
                {
                  name: page.name
                  id: page.instagram_business_account.id
                }
              )
              pagesList = buildRadios(pages)
              $('#submit-ig-page').on 'click', ->
                AuthenticationUtils.submit_instagram_page(localStorageItemString||null)
              igModalTitle('Select a Page')
              $('#ig_page_selection.modal .modal-body .pages-list').html(pagesList)
              hideIgModalElementsExceptFor('account-selection')
              toggleButtons(hide = [], show = ['cancel-ig-page-submit', 'submit-ig-page'])
            else
              igModalTitle('No Pages Available')
              hideIgModalElementsExceptFor('no-pages')
              toggleButtons(hide = [], show = ['cancel-ig-page-submit'])
        else
          igModalTitle('Error Authenticating')
          hideIgModalElementsExceptFor('error-authing')
          toggleButtons(hide = [], show = ['cancel-ig-page-submit'])
          $modal.modal('show')
      ), { scope: 'manage_pages, instagram_basic, instagram_manage_insights', return_scopes: true }

     # insightsProvided = (scopes) ->
     #   _.includes(scopes, 'read_insights')

      buildRadios = (pages) ->
        listItems = ''
        for page in pages
          listItems += [
            "<li class='radio'>",
            "<label>",
            "<input type='radio' name='page' ",
            "id='page_" + page.id + "' ",
            "value='" + page.id + "' ",
            "data-page-name='" + page.name + "' ",
            (if pages.length == 1 then 'checked' else ''),
            ">",
            page.name,
            "</label>",
            "</li>"
          ].join ''

        listItems

  @submit_facebook_page = (localStorageItemString) ->
    toggleButtons(hide = ['cancel-fb-page-submit', 'submit-fb-page'], show = [])
    hideIgModalElementsExceptFor('loading')
    modalTitle('Processing')
    $selectedPage = $('input[name=page]:checked')
    submitFbPageAuth($selectedPage, userCreds, localStorageItemString)

  @submit_instagram_page = (localStorageItemString) ->
    toggleButtons(hide = ['cancel-ig-page-submit', 'submit-ig-page'], show = [])
    hideModalElementsExceptFor('loading')
    igModalTitle('Processing')
    $selectedPage = $('input[name=page]:checked')
    submitInstagramPageAuth($selectedPage, userCreds, localStorageItemString)

  hideModalElementsExceptFor = (exceptFor) ->
    for e in $('#fb_page_selection.modal .modal-body').children()
      $e = $(e)
      if $e.hasClass(exceptFor) then $e.show() else $e.hide()

  hideIgModalElementsExceptFor = (exceptFor) ->
    for e in $('#ig_page_selection.modal .modal-body').children()
      $e = $(e)
      if $e.hasClass(exceptFor) then $e.show() else $e.hide()

  toggleButtons = (hide = [], show = []) ->
    for button in hide
      $('#' + button).hide()

    for button in show
      $('#' + button).show()

  modalTitle = (title) ->
    $('#ig_page_selection.modal .modal-header h4').text(title)

  igModalTitle = (title) ->
    $('#ig_page_selection.modal .modal-header h4').text(title)

  submitFbPageAuth = ($selectedPage, userCreds, localStorageItemString) ->
    params = {
      user: {
        access_token: userCreds.authResponse.accessToken,
        uid: userCreds.authResponse.userID
      },
      page: {
        id: $selectedPage.val()
      }
    }

    $.ajax
      type: 'POST',
      url: '/authentications/client_facebook_page',
      data: params,
      success: (response) ->
        $('#facebook-requirements-met').val('true')
        storeCheckboxState(localStorageItemString)
        window.location = self.location
      error: (response) ->
        modalTitle('Internal Error')
        hideModalElementsExceptFor('internal-error')
        toggleButtons(hide = ['submit-fb-page'], show = ['cancel-fb-page-submit'])

    storeCheckboxState = (localStorageItemString) ->
      if (localStorageItemString == null) then return
      checkedBoxes = []
      for checkbox in $('.js_round_checkbox:checked')
        checkedBoxes.push($(checkbox).val())

      window.localStorage.setItem(localStorageItemString, checkedBoxes)

  submitInstagramPageAuth = ($selectedPage, userCreds, localStorageItemString) ->
    params = {
      user: {
        access_token: userCreds.authResponse.accessToken,
        uid: userCreds.authResponse.userID
      },
      page: $selectedPage.val()
    }

    $.ajax
      type: 'POST',
      url: '/authentications/finish_facebook_instagram_business',
      data: params,
      success: (response) ->
        $('#facebook-requirements-met').val('true')
        storeCheckboxState(localStorageItemString)
        window.location.reload()
      error: (response) ->
        modalTitle('Internal Error')
        hideModalElementsExceptFor('internal-error')
        toggleButtons(hide = ['submit-ig-page'], show = ['cancel-ig-page-submit'])

    storeCheckboxState = (localStorageItemString) ->
      if (localStorageItemString == null) then return
      checkedBoxes = []
      for checkbox in $('.js_round_checkbox:checked')
        checkedBoxes.push($(checkbox).val())

      window.localStorage.setItem(localStorageItemString, checkedBoxes)