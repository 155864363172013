import React from 'react';
import {Provider} from 'react-redux'
import AdMetricsUploadStore from '../redux/AdMetricsUploadStore'
import UploadAdMetrics from './Uploadadmetrics'
import MetricsUploadTable from './Metricsuploadtable'
export default class AdMetricsUploadWrapper extends React.Component {
  render() {
    return (
      <Provider store={AdMetricsUploadStore}>
        <React.Fragment>
          <UploadAdMetrics
            campaignId={this.props.campaignId}
            proxyUrl={this.props.proxyUrl}
            multi_campaign={this.props.multi_campaign}
          />
          { !this.props.multi_campaign && <MetricsUploadTable {...this.props} /> }
        </React.Fragment>
      </Provider>
    );
  }
}

AdMetricsUploadWrapper.displayName = 'AdMetricsUploadWrapper';