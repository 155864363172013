import React from 'react';

const UserFormCheckbox = ({name, label, value, onUpdate}) => {
  return <div className="checkbox">
    <label>
      {label}
      <input id={name} type="checkbox" defaultChecked={value} value={value} onChange={onUpdate}/>
    </label>
  </div>
};

export default UserFormCheckbox;
UserFormCheckbox.displayName = 'UserForm.Checkbox';