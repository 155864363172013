import React from 'react';
import { Remote } from '../utils';
import ValidationStateCard from './ValidationStateCard'

class ValidationHistory extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      validationHistory: []
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.loadContentValidationHistory = this.loadContentValidationHistory.bind(this);
    this.renderRows = this.renderRows.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount(){
    this.loadContentValidationHistory();
  }

  loadContentValidationHistory(){
    const params = $.param({
      api_action: `/contents/${this.props.contentId}/content_validations`,
    });
    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'GET',
    }).then(response => {
      this.setState({validationHistory: response.data})
    });
  }

  renderRows(){
    const cards = this.state.validationHistory.map((content_validation) => {
      return (
        <ValidationStateCard key={content_validation.id} {...content_validation}/>
      );
    });
    return cards;
  }

  render() {
    const displayValidationHistory = this.state.validationHistory.length > 0
    return(
      <div>
        {displayValidationHistory &&
          <div className='validation-history--section'>
            <h4>Task History:</h4>
              <div className="validation-history--table">
                <div className="row table-header">
                  <div className="cell">Author</div>
                  <div className="cell">Status</div>
                  <div className="cell">Date</div>
                  <div className="cell comment-column">Comment</div>
                  <div className="cell">Rejected Requirements</div>
                </div>
                { this.renderRows() }
              </div>
          </div>
        }
      </div>
    );
  }
}
export default ValidationHistory;