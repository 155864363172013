import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from "react-redux";
import ShopApplicationsStore from '../redux/ShopApplicationsStore';;
import ShopApplicationsApply from './Apply';

export default class ShopApplicationsApplyWrapper extends React.Component {
  render() {
    return (
      <Provider store={ShopApplicationsStore}>
        <ShopApplicationsApply {...this.props} />
      </Provider>
    );
  }
};

ShopApplicationsApplyWrapper.displayName = 'ShopApplications.ApplyWrapper';
ShopApplicationsApplyWrapper.propTypes = {
  userId: PropTypes.number.isRequired,
  shopId: PropTypes.number.isRequired,
  proxyUrl: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  selectedRoundIds: PropTypes.array,
  shopApplicationId: PropTypes.number
};

