import React from 'react';
import PropTypes from 'prop-types'
import Alert from '../Alert'

export default class SubmissionFeedback extends React.Component {
  render() {
    const {type, message, timeStamp} = this.props;
    const messageTitle = type === 'error' ? 'Review content feedback before making any changes.' : 'Review final content feedback.';

    if (message && message.length > 0) {
      return (
        <div className={`submission-feedback ${type}`}>
          <span className='feedback-title'>{messageTitle}</span>
          <Alert type={type} message={message}/>
          { timeStamp && timeStamp.length > 0 && <span className='feedback-timestamp'>{timeStamp}</span>}
        </div>
      );
    } else {
      return null;
    }
  }
}

SubmissionFeedback.displayName = 'Submission.Feedback'
SubmissionFeedback.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  timeStamp: PropTypes.string
};
