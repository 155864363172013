import React from 'react';
import Alert from './Alert'
import { Remote } from './utils';

export default class SystemMessagesAlert extends React.Component {
  constructor(props) {
    super(props);

    this.state ={
      ...props
    };

    this.onCallback = this.onCallback.bind(this);
    this.render = this.render.bind(this);
  }

  onCallback(id) {
    const { alerts } = this.state;

    const params = $.param({
      api_action: `notifications/mark_as_seen/${id}`
    });
    Remote().request({
      url: '/notifications/api_proxy' + '?' + params,
      method: 'POST',
    })
    this.setState({
      alerts: alerts.filter( (a) => a.id !== id )
    })
  }

  render() {
    const { alerts } = this.state;
    return(
      <div className='container'>
        { alerts && alerts.map( ({id, title, body}) => {
          return (
            <Alert key={id} type='primary' callback={() => this.onCallback(id)}>
              <h2>{title}</h2>
              <p className='mr-10' dangerouslySetInnerHTML={{ __html: body }}></p>
            </Alert>
          )
        } )}
      </div>
    )
  }
}
SystemMessagesAlert.displayName='SystemMessagesAlert'