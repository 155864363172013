import React from 'react';
var FacebookPageAuthButton = createReactClass({

  render: function () {

    return (
      <span className='promo-auth'>
        <div className='btn btn-default authorize t-transform-none'
             id='connect-fb-page'
             data-toggle='tooltip'
             data-placement='left'
             title='You may need to disable pop-up blockers to connect'>
          <i className='fab fa-facebook fa-fw' />
          Connect to Facebook
        </div>
        <span>
          Minimum {this.props.follower_requirements} followers
        </span>
      </span>
    );
  }
});
