
import { createStore } from 'redux';

  const initialState = {
    meta: {},
    round: {},
    roundLoading: true,
    contentTypes: [],
    campaignTemplates: [],
    displayPage: '',
    templateTypes: [],
    notifications: {
      saving: false,
      saveType: null,
      saveErrors: []
    },
    generalTemplates: [],
    generalModalOpen: false,
    palette: {}
  };

  const reducer = (state = initialState, action) => {
    switch(action.type) {
    case 'SET_ROUND':
      return {
        ...state,
        round: action.value
      };
    case 'EDIT_ROUND':
      return {
        ...state,
        round: {
            ...action.value.data
          }
      };
    case 'SET_TEMPLATES':
      return {
        ...state,
        campaignTemplates: action.value
      };
    case 'SET_ROUND_LOADING':
      return {
        ...state,
        roundLoading: action.value
      };
    case 'ADD_ROUND':
      return {
        ...state,
        round: [...state.round, action.value]
      };
    case 'ADD_TASK':
      return {
        ...state,
        round: {
          ...state.round,
          round_tasks: [...state.round.round_tasks, action.value.roundTask]
        }
      };
    case 'EDIT_TASK':
      return {
        ...state,
        round: {
          ...state.round,
          round_tasks: state.round.round_tasks.map(task => {
            if(task.id != action.value.taskId) {
              return task;
            };

            return {
              ...task,
              [action.value.field]: action.value.value
            };
          })
        }
      };
    case 'DELETE_TASK':
      return {
        ...state,
        round: {
          ...state.round,
          round_tasks: state.round.round_tasks.filter(task => task.id != action.value.taskId)
        }
      };
    case 'ADD_REQUIREMENT':
      return {
        ...state,
        round: {
          ...state.round,
          round_tasks: state.round.round_tasks.map(task => {
            if(task.id != action.value.taskId) {
              return task;
            }

            return {
              ...task,
              round_task_requirements: [...task.round_task_requirements, action.value.value]
            };
          })
        }
      };
    case 'EDIT_REQUIREMENT_BY_ID':
      return {
        ...state,
        round: {
          ...state.round,
          round_tasks: state.round.round_tasks.map(task => {
            if(task.id != action.value.taskId) {
              return task;
            }

            return {
              ...task,
              round_task_requirements: task.round_task_requirements.map(req => {
                if(req.id != action.value.id) {
                  return req;
                }

                return {
                  ...req,
                  [action.value.field]: action.value.value
                };
              })
            };
          })
        }
      };
    case 'EDIT_REQUIREMENT':
      return {
        ...state,
        round: {
          ...state.round,
          round_tasks: state.round.round_tasks.map(task => {
            if(task.id != action.value.taskId) {
              return task;
            }

            return {
              ...task,
              round_task_requirements: task.round_task_requirements.map((req, index) => {
                if(index != action.value.index) {
                  return req;
                }

                return {
                  ...req,
                  [action.value.field]: action.value.value
                };
              })
            };
          })
        }
      };
    case 'DELETE_REQUIREMENT_BY_ID':
      return {
        ...state,
        round: {
          ...state.round,
          round_tasks: state.round.round_tasks.map(task => {
            if(task.id != action.value.taskId) {
              return task;
            }

            return {
              ...task,
              round_task_requirements: task.round_task_requirements.filter(req => req.id != action.value.id)
            };
          })
        }
      };
    case 'DELETE_REQUIREMENT':
      return {
        ...state,
        round: {
          ...state.round,
          round_tasks: state.round.round_tasks.map(task => {
            if(task.id != action.value.taskId) {
              return task;
            }

            return {
              ...task,
              round_task_requirements: task.round_task_requirements.filter((req, index) => index != action.value.index)
            };
          })
        }
      };
      case 'UPDATE_REQUIREMENT_SORT':
        {
          let nextTasks = _.cloneDeep(state.round.round_tasks);

          nextTasks.forEach((task) => {
            if(task.id === action.value.taskId){
              let nextRequirements = _.cloneDeep(task.round_task_requirements);
              nextRequirements.splice(action.value.to, 0, nextRequirements.splice(action.value.from, 1)[0]);
              task.round_task_requirements = nextRequirements
            }
          });

          return {
            ...state,
            round: {
              ...state.round,
              round_tasks: nextTasks
            }
          };
        }
    case 'SET_CONTENT_TYPES':
      return {
        ...state,
        contentTypes: action.value
      };
    case 'SET_TEMPLATES':
      return {
        ...state,
        templateTypes: action.value
      };
    case 'OPEN_NOTIFICATION':
      return {
        ...state,
        notifications: {
          ...action.value,
          saving: true
        }
      };
    case 'CLOSE_NOTIFICATION':
      return {
        ...state,
        notifications: {
          saving: false,
          saveText: null,
          saveType: null,
          saveErrors: []
        }
      };
    case 'SET_GENERAL_TEMPLATES':
      return {
        ...state,
        generalTemplates: action.value
      };
    case 'TOGGLE_GENERAL_MODAL':
      return {
        ...state,
        generalModalOpen: !state.generalModalOpen
      };
    case 'SET_META':
      return {
        ...state,
        meta: action.value
      };
    case 'SET_EDITING_TYPE':
      return {
        ...state,
        editingType: action.value
      }
    case 'SET_DISPLAY_PAGE':
      return {
        ...state,
        displayPage: action.value
      }
    case 'UPDATE_TASK_SORT':
      let nextRoundTasks = _.cloneDeep(state.round.round_tasks);
      nextRoundTasks.splice(action.value.to, 0, nextRoundTasks.splice(action.value.from, 1)[0]);

      return {
        ...state,
        round: {
          ...state.round,
          round_tasks: nextRoundTasks
        }
      };
    case 'BUILD_PALETTE': {
      const allTemplates = _.flattenDeep(
        state
          .round
          .round_tasks
          .map(task => task.round_task_requirements.map(req => req.based_on)
          .filter(x => !!x)) // Remove nulls
      );
      const templates = _.uniqBy(allTemplates, x => x.id);

      return {
        ...state,
        palette: templates.reduce((prev, template, index) => ({
          ...prev,
          [template.id]: {
            templateId: template.id,
            color: `palette-${index}`,
            name: template.name
          }
        }), {})
      }
    }
    case 'DELETE_TEMPLATE_REQUIREMENTS':
      return {
        ...state,
        round: {
          ...state.round,
          round_tasks: state.round.round_tasks.map(task => {
            if(task.id != action.value.taskId) {
              return task;
            }

            return {
              ...task,
              round_task_requirements: task.round_task_requirements.filter((req) => {
                return _.isEmpty(req.based_on) || (req.based_on.id != action.value.templateId)
              })
            };
          })
        }
      };
    default:
      return state;
    }
  };


const RoundEdit = createStore(
  reducer,
  window && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ name: 'Redux.RoundEdit' }) // Enable redux devtools middleware
);

export default RoundEdit;