import React from "react";
import SingleSelect from "../SingleSelect";

export default class GoogleDocExportFolderSelect extends SingleSelect {
  selectItem(value) {
    Promise.all([
      this.setState({
        selected: value,
        searchText: null,
      }),
      this.props.onUpdate(value),
    ]);
  }

  renderResults() {
    const { results, selected } = this.state;

    if (results.length === 0)
      return (
        <li className="no-results">
          <i className="fas fa-exclamation-triangle" /> No results
        </li>
      );

    const items = results.filter((x) => !_.includes(selected, x.value || x.id));

    if (items.length === 0)
      return (
        <li className="no-results">
          <i className="fas fa-exclamation-triangle" /> No more results
        </li>
      );

    return items.map((result) => (
      <li
        key={JSON.stringify(result.value || result.id)}
        onClick={() => this.selectItem(result.value || result.id)}
      >
        {result.name}
      </li>
    ));
  }

  render() {
    const { searchText, focused, selected, cache } = this.state;
    const { hasError, disabled, placeholder, showCaret, clearDisabled } =
      this.props;
    const selectedName = this.selectedName(selected);
    const noResults = cache.length === 0;
    const val = noResults
      ? "No subfolders found."
      : focused
      ? searchText
      : selectedName;

    return (
      <div className="single-select-component">
        <div className="select">
          <input
            type="text"
            className={hasError ? "invalid" : ""}
            value={val || ""}
            onFocus={this.onFocus}
            disabled={noResults || disabled}
            onBlur={(e) => this.onBlur(e)}
            onChange={(e) => this.searchChanged(e)}
            placeholder={placeholder || ""}
          />
          {!!showCaret && <i className="dropdown-caret fas fa-caret-down" />}
          {selectedName && !clearDisabled && (
            <span
              className="un-select"
              onClick={this.unSelect}
              disabled={disabled}
            >
              <i className="fas fa-times"></i>
            </span>
          )}
          {focused && (
            <div className="options">
              <ul> {this.renderResults()} </ul>
            </div>
          )}
        </div>
      </div>
    );
  }
}
GoogleDocExportFolderSelect.displayName = "GoogleDocExportFolderSelect";
