import React from 'react';
import { connect } from 'react-redux';
import { Remote } from '../utils';

import ProgressBar from '../ProgressBar';
import Alert from '../Alert'
import SingleSelect from '../SingleSelect'
import MultiSelect from '../MultiSelect'
import HorizontalScrollContainer from '../HorizontalScrollContainer'
import RoundCard from '../rounds/RoundCard'
import ShopApplicationsQuestion from './Question'

class ShopApplicationsApply extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.loadApplication = this.loadApplication.bind(this);
    this.initializeApplication = this.initializeApplication.bind(this);
    this.submitApplication = this.submitApplication.bind(this);
    this.editApplication = this.editApplication.bind(this);
    this.searchBlogs = this.searchBlogs.bind(this);
    this.searchRounds = this.searchRounds.bind(this);
    this.selectSocialPlatform = this.selectSocialPlatform.bind(this);
    this.updateSelectedRounds = this.updateSelectedRounds.bind(this);
    this.canSubmitCheck = this.canSubmitCheck.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    this.props.initializeField('isReadOnly', !!this.props.readOnly)

    if (this.props.shopApplicationId !== null) {
      this.loadApplication()
    } else {
      this.initializeApplication()
    }
  }

  loadApplication() {
    this.props.toggleFetching();

    const params = $.param({
      api_action: `shoppertunities/${this.props.shopId}/shop_applications/${this.props.shopApplicationId}/`
    });

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'GET',
      
    }).then((response) => {
      this.props.initializeData({
        blogs: response.data.blogs,
        questions: response.data.questions,
        selectedRounds: response.data.selected_rounds,
        shopApplication: response.data.shop_application,
        platform: response.data.platform,
        rounds: response.data.rounds,
        round_bids: response.data.round_bids || [],
        socialPlatformId: response.data.user_social_platform_id,
        initialSelectedRoundIds: response.data.selected_rounds.map(round => round.id),
        selectedRoundIds: response.data.selected_rounds.map(round => round.id)
      });

      this.props.toggleFetching();
    }).catch((error) => {
      this.props.toggleFetching();
      this.props.setErrorMessage('Failed to load your application. Please try again.');
    });
  }

  initializeApplication() {
    this.props.toggleFetching();

    const params = $.param({
      api_action: `shoppertunities/${this.props.shopId}/shop_applications/apply/`
    });

    const applicationData = {
      user_id: this.props.userId,
      selected_round_ids: this.props.selectedRoundIds
    };

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'POST',
      data: applicationData,
      
    }).then((response) => {
      this.props.initializeData({
        blogs: response.data.blogs,
        questions: response.data.questions,
        selectedRounds: response.data.selected_rounds,
        shopApplication: response.data.shop_application,
        platform: response.data.platform
      });

      if (response.data.platform === 'Blog' && response.data.blogs.length === 1) {
        this.props.setSocialPlatform(response.data.blogs[0].id)
      } else {
        this.props.setSocialPlatform(response.data.user_social_platform_id)
      }

      this.props.toggleFetching();
    }).catch((error) => {
      this.props.toggleFetching();
      this.props.setErrorMessage('Failed to initialize your application. Please try again.');
    });
  }

  submitApplication() {
    if (this.props.canSubmit) {
      this.setState({loading: true});
      this.props.toggleLoading();

      let method = 'POST'
      let endpoint = 'create'
      if (this.props.shopApplicationId !== null) {
        method = 'PATCH'
        endpoint = `${this.props.shopApplicationId}`;
      }

      const params = $.param({
        api_action: `shoppertunities/${this.props.shopId}/shop_applications/${endpoint}/`
      });

      const applicationData = {
        user_id: this.props.userId,
        shop_application: {
          user_social_platform_id: this.props.socialPlatformId,
          round_ids: this.props.selectedRoundIds,
          round_bids: this.props.round_bids,
          answers: this.props.questions.map(question => {
            return {
              value: question.answer,
              question_id: question.id,
              user_id: this.props.userId
            };
          }),
        }
      };

      Remote().request({
        url: this.props.proxyUrl + '?' + params,
        method: method,
        data: applicationData,
          
      }).then((response) => {
        this.props.toggleLoading();
        this.setState({loading: false});
        window.location.href = `${this.props.baseUrl}/application`;
      }).catch((error) => {
        this.props.toggleLoading();
        this.setState({loading: false});
        this.props.setErrorMessage('Failed to submit your application. Please submit your request again.');
      });
    }
  }

  editApplication() {
    this.props.initializeField('isReadOnly', false)
  }

  searchBlogs(query, callback) {
    const results = _.filter(
      this.props.blogs,
      value => value.name.toLowerCase().includes(query.toLowerCase())
    );
    callback(results);
  }

  selectSocialPlatform(platformId) {
    const promise = () => new Promise((resolve) => {
      resolve(this.props.setSocialPlatform(platformId));
    });

    promise().then(() => {
      this.canSubmitCheck();
    });
  }

  searchRounds(query, callback) {
    const results = _.filter(
      this.props.rounds,
      value => value.name.toLowerCase().includes(query.toLowerCase())
    );
    callback(results.map(round => {
      return {
        id: round.id,
        value: round.id,
        name:round.name,
        disabled: this.props.initialSelectedRoundIds.includes(round.id)
      }
    }));
  }

  updateSelectedRounds(rounds) {
    const promise = () => new Promise((resolve) => {
      resolve(this.props.updateSelectedRounds(rounds));
    });

    promise().then(() => {
      this.canSubmitCheck();
    });
  }

  canSubmitCheck() {
    const { questions, socialPlatformId, round_bids, selectedRounds } = this.props
    const platformIsSelected = socialPlatformId !== "";
    const openBidRounds = _.filter(selectedRounds, ['open_bid', true])
    const hasRoundBids = openBidRounds.length > 0 ? Object.keys(round_bids).length === openBidRounds.length : true

    if (questions && questions.length > 0) {
      const answers = _.filter(questions, (question) => {
        return question.answer && question.answer.length > 0;
      });

      this.props.setCanSubmitState((questions.length === answers.length) && platformIsSelected && hasRoundBids);
    } else {
      this.props.setCanSubmitState(platformIsSelected && hasRoundBids)
    }
  }

  closeAlert() {
    this.props.setErrorMessage('');
  }

  render() {
    if (this.props.fetching) {
      return <ProgressBar />;
    } else {
      return (
        <div className='container main-container shop-application-container'>

        { this.props.error &&
          this.props.errorMessage.length > 0 &&
          <Alert type='error fixed-top' message={this.props.errorMessage} callback={this.closeAlert}/>
        }

        { this.props.selectedRounds &&
          this.props.selectedRounds.length > 0 &&
          <div className='round-card-container'>

            { this.props.rounds &&
              !this.props.isReadOnly &&
              <div className='round-select-container'>
                <label>Please select all rounds to be considered for.</label>
                <MultiSelect
                  placeholder='Select rounds'
                  search={this.searchRounds}
                  selected={this.props.selectedRoundIds}
                  onUpdate={(rounds) => this.updateSelectedRounds(rounds)}
                  preload={this.props.rounds.map(round => {
                    return {
                      id: round.id,
                      value: round.id,
                      name:round.name,
                      disabled: this.props.initialSelectedRoundIds.includes(round.id)
                    }
                  })}/>
                <Alert type='primary' message='You can only add rounds to your application. Once added, rounds cannot be removed.'/>
              </div>
            }

            <HorizontalScrollContainer children={<React.Fragment>{_.sortBy(this.props.selectedRounds, 'end_date').map((round, key) => {
              return <RoundCard key={key} {...round} canSubmitCheck={this.canSubmitCheck}/>
            })}</React.Fragment> }/>
          </div>
        }

          <div className='shop-application-form'>
            { this.props.platform === 'Blog' &&
              <div className='blog-selection-container'>
                <label>Which blog will you use for this opportunity?</label>
                <SingleSelect
                  search={this.searchBlogs}
                  selected={this.props.socialPlatformId}
                  onUpdate={(item) => this.selectSocialPlatform(item)}
                  placeholder='Select Blog'
                  disabled={this.props.isReadOnly}
                  />
              </div>
            }

            <div className='questions-container'>
              { this.props.questions &&
                this.props.questions.length > 0 &&
                this.props.questions.map((question, key) => {
                  return <ShopApplicationsQuestion key={key} canSubmitCheck={this.canSubmitCheck} {...question}/>
                })
              }
            </div>
          </div>

          { this.props.isReadOnly &&
            <div className='fixed-button-container'>
              <button className='primary' onClick={this.editApplication}>Edit</button>
            </div>
          }

          { !this.props.isReadOnly &&
            <div className='fixed-button-container'>
              { this.props.loading && <ProgressBar classNames='absolute-top' /> }
              <a className='btn secondary' href={this.props.baseUrl}>Cancel</a>
              <button className='primary' disabled={!this.props.canSubmit || !!this.state.loading} onClick={this.submitApplication}>Submit Application</button>
            </div>
          }

        </div>
      );
    }
  }

  static mapStateToProps(state, myProps) {
    return {
      ...state,
      ...myProps,
    };
  };

  static mapDispatchToProps(dispatch) {
    return {
      toggleLoading: () => dispatch({ type: 'TOGGLE_LOADING' }),
      toggleFetching: () => dispatch({ type: 'TOGGLE_FETCHING' }),
      initializeData: data => dispatch({ type: 'INITIALIZE_DATA', value: data }),
      initializeField: (field, data) => dispatch({ type: 'INITIALIZE_FIELD', value: { field: field, data: data } }),
      setSocialPlatform: (platformId) => dispatch({ type: 'SET_SOCIAL_PLATFORM', value: platformId }),
      updateSelectedRounds: (roundIds) => dispatch({ type: 'UPDATE_SELECTED_ROUNDS', value: roundIds }),
      setCanSubmitState: state => dispatch({ type: 'SET_CAN_SUBMIT_STATE', value: state }),
      setErrorMessage: errorMessage => dispatch({ type: 'SET_ERROR_MESSAGE', value: errorMessage})
    };
  };
};

ShopApplicationsApply.displayName = 'ShopApplications.Apply';
export default connect(
  ShopApplicationsApply.mapStateToProps,
  ShopApplicationsApply.mapDispatchToProps
)(ShopApplicationsApply);
