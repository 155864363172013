import React from 'react';
import PropTypes from 'prop-types'


const InfluencerDashboardEarningsSummaryCard = ({card_title, sum_pay, final_pay, sum_text, final_text}) => {
  return (
    <div className='card earnings-summary-card'>
      <div className='top-row'>
        <div>
          { card_title }
        </div>
      </div>
      <div className='mid-row'>
        <div className='amount text-color-green text-left'>
          { sum_pay }
        </div>
        <div className='amount text-color-gray text-right'>
          { final_pay }
        </div>
      </div>
      <div className='bottom-row'>
        <div className='descript text-left'>
          { sum_text }
        </div>
        <div className='descript text-right'>
          { final_text }
        </div>
      </div>
    </div>
  )
}
InfluencerDashboardEarningsSummaryCard.displayName = 'InfluencerDashboard.EarningsSummaryCard';
InfluencerDashboardEarningsSummaryCard.propTypes = {
  card_title: PropTypes.string.isRequired,
  sum_pay: PropTypes.string.isRequired,
  final_pay: PropTypes.string.isRequired,
  sum_text: PropTypes.string.isRequired,
  final_text: PropTypes.string.isRequired
};
export default InfluencerDashboardEarningsSummaryCard;