import React from 'react';

export default class CampaignsDeliverables extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.renderRow = this.renderRow.bind(this);
  }

  renderRow(deliverable){
    return (
      <tr key={deliverable.name}>
          <td>{deliverable.name}</td>
          <td>{deliverable.total_sold}</td>
          <td>{deliverable.total_rounds}</td>
          <td>{deliverable.total_remaining}</td>
      </tr>
        );
    }

  render() {
    return (
      <div>
        <legend>Deliverables</legend>
        <table className="table table-bordered table-striped">
            <thead>
            <tr>
                <th>Name</th>
                <th>Total Sold</th>
                <th>Total in Rounds</th>
                <th>Total Remaining</th>
            </tr>
            </thead>
            <tbody>
                {this.props.deliverables.map((deliverable) => this.renderRow(deliverable))}
            </tbody>
        </table>
      </div>
    );
  }
}

CampaignsDeliverables.displayName = 'Campaigns.Deliverables';
