import { createStore } from 'redux';

const initialState = {
  sortSelected: "bestMatch",
  sortSet: "bestMatch",
  filterSelected: null,
  filterPlatforms: [],
  filterCategories: [],
  filterRetailers: [],
  filterClients: []
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
  case 'SELECT_SORT':
    return {
      ...state,
      sortSelected: action.value
    };
  case 'SET_SORT':
    return {
      ...state,
      sortSet: action.value
    };
  case 'SELECT_FILTER':
    return {
      ...state,
      filterSelected: action.value
    };
  case 'SET_PLATFORM_FILTER':
    return {
      ...state,
      filterPlatforms: action.value
    }
  case 'SET_CATEGORY_FILTER':
    return {
      ...state,
      filterCategories: action.value
    }
  case 'SET_RETAILER_FILTER':
    return {
      ...state,
      filterRetailers: action.value
    }
  case 'SET_CLIENT_FILTER':
    return {
      ...state,
      filterClients: action.value
    }
  case 'ADD_PLATFORM_FILTER':
    return {
      ...state,
      filterPlatforms: [...state.filterPlatforms, action.value]
    }
  case 'ADD_CATEGORY_FILTER':
    return {
      ...state,
      filterCategories: [...state.filterCategories, action.value]
    }
  case 'ADD_RETAILER_FILTER':
    return {
      ...state,
      filterRetailers: [...state.filterRetailers, action.value]
    }
  case 'ADD_CLIENT_FILTER':
    return {
      ...state,
      filterClients: [...state.filterClients, action.value]
    }
  case 'REMOVE_PLATFORM_FILTER':
    return {
      ...state,
      filterPlatforms: state.filterPlatforms.filter(platform => platform != action.value)
    }
  case 'REMOVE_CATEGORY_FILTER':
    return {
      ...state,
      filterCategories: state.filterCategories.filter(category => category != action.value)
    }
  case 'REMOVE_RETAILER_FILTER':
    return {
      ...state,
      filterRetailers: state.filterRetailers.filter(retailer => retailer != action.value)
    }
  case 'REMOVE_CLIENT_FILTER':
    return {
      ...state,
      filterClients: state.filterClients.filter(client => client != action.value)
    }
  case 'RESET_PLATFORM_FILTER':
    return {
      ...state,
      filterPlatforms: []
    }
  case 'RESET_CATEGORY_FILTER':
    return {
      ...state,
      filterCategories: []
    }
  case 'RESET_RETAILER_FILTER':
    return {
      ...state,
      filterRetailers: []
    }
  case 'RESET_CLIENT_FILTER':
    return {
      ...state,
      filterClients: []
    }
  case 'RESET_ALL_FILTERS':
    return {
      ...state,
      filterPlatforms: [],
      filterCategories: [],
      filterRetailers: [],
      filterClients: []
    }
  default:
    return state;
  }
};

const ShoppertunitiesStore = createStore(
  reducer,
  window && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ name: 'Redux.ShoppertunitiesStore' })
);

export default ShoppertunitiesStore;