import React from 'react';

export default class SystemMessage extends React.Component {
  render(){
    const {sender_id, sender_name, user_id, message} = this.props.notification;
    return (
      <React.Fragment>
        {sender_id === user_id && <b>You </b>}
        {sender_id !== user_id && <b>{sender_name} </b>}
        sent an <b>import community message.</b>
        <div>{message}</div>
      </React.Fragment>
    )
  }
}
SystemMessage.displayName = 'Notification.Templates.SystemMessage'