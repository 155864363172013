import React from 'react';
import PropTypes from 'prop-types'

export default class FileInputGroup extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.inputRef = React.createRef();
  }

  handleClick(){
    this.inputRef.current.click();
  }

  render() {
    const { iconClass, value, addonPlacement, placeholder, disabled, fileTypes } = this.props;
    const placement = addonPlacement || 'left';
    const addon = <span className={`input-group-addon ${placement}`} onClick={this.handleClick}>
                  {iconClass ? <i className={this.props.iconClass}/> : 'Browse'}
                </span>;

    const formValue = _.isNull(value) || _.isUndefined(value) ? '' : value;
    const labelValue = value || placeholder

    return (
      <div className='input-group custom-file-input'>
        {placement == 'left' && addon}
        <label className='form-control'>
        {labelValue}
          <input
            type='file'
            ref={this.inputRef}
            value={formValue}
            onChange={this.props.onChange}
            placeholder={placeholder}
            disabled={disabled}
            accept={fileTypes}
          />
        </label>
        {placement == 'right' && addon}
      </div>
    );
  };
}
FileInputGroup.displayName = 'FileInputGroup';

FileInputGroup.propTypes = {
  iconClass: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  addonPlacement: PropTypes.string,
  placeholder: PropTypes.string,
  fileTypes: PropTypes.string,
  disabled: PropTypes.bool
};
