class window.FormUtils

  @form_bindings: ->
    @bind_country_selector()
    @add_nested()
    @remove_nested()
    @bind_number_inputs()
    @select_search()
    @unhide_timelines_form()

    # $('.js-datepicker').datetimepicker
    #   minView: 2,
    #   format: 'MM dd, yyyy'

  @select_search: ->
    #changing to opt-in class from opt-out - JW rails upgrade work
    $('.select2_init').each (i, s) ->
      FormUtils.select_init(s)

  @select_init: (select, options={}) ->
    $select = $(select)
    max = $select.data('max') || -1 # -1 = infinity
    min_results = $select.data('min-results') || 10
    close = $select.data('close') || true

    $select.select2(
      $.extend(
        allowClear: true
        minimumResultsForSearch: min_results
        maximumSelectionSize: max
        closeOnSelect: close
        placeholder: 'Select Option(s)'
        options
        )
      )

  @bind_country_selector: ->
    $('select#country-selector').change (event) ->
      select_wrapper = $('#state_select_wrapper')
      $('select', select_wrapper).attr('disabled', true)
      country = $(this).val()
      url = "/locations/state_options?parent_region=#{country}"
      select_wrapper.load url, ->
        FormUtils.select_init('#profile_state', {placeholder: 'Please select a state or region'})

  @remove_nested: ->
    $('body').on 'click', 'form .remove_fields', (event) ->
      event.preventDefault()
      trigger = $(this)

      msg = trigger.data('confirm')
      return if msg && !confirm msg

      target = if trigger.hasClass('remove-task') then $('.js-task-row') else $('fieldset')
      trigger.prev('input[type=hidden]').val('1')
      trigger.parent('a').siblings('input[type=hidden]').val("true")
      trigger.closest(target).addClass('is-hidden')
      trigger.closest(target).find('input').trigger('change')
      trigger.closest('.child_hide').addClass('is-hidden')

  @add_nested: ->
    that = this
    $('body').on 'click', 'form .add_fields', (event) ->
      event.preventDefault()
      trigger = $(this)
      time = new Date().getTime()
      regexp = new RegExp(trigger.data('id'), 'g')
      new_fields = trigger.data('fields').replace(regexp, time)
      if trigger.hasClass('add-retailer')
        trigger.closest('.retailer-fields').find('.retailer-form').append( new_fields )
      else if trigger.hasClass('add-client')
        $('.conflict-list').append( new_fields )
      else if trigger.hasClass('add-postal-code')
        trigger.closest('.postal-code-fields').append( new_fields )
      else if trigger.hasClass('add-round')
        $('.round-planner').prepend( new_fields )
      else if trigger.hasClass('new-task')
        target = trigger.parents('.round-row').find('.js-tasks-list')
        target.append( new_fields )
        new_row = target.children('.js-task-row').last()
        set_new_row_info( new_row )
      else if trigger.hasClass('js-add-timeline')
        target = $('.js-form-timeline').find('tbody')
        target.append( new_fields )
        activate_datepicker( target.children('tr').last() )
      else
        trigger.after(new_fields)
      setTimeout( that.bind_datepicker, 200 )

    set_new_row_info = ( new_row ) ->
      count = $('.js-task-row').size() + 1
      data_value = 'task-' + count
      link = new_row.find('.js-link-view-task-description')
      target_modal = new_row.find('.js-modal-task-description')

      link.attr('data-target', '#' + data_value)
      new_row.attr('data-input', data_value)
      target_modal.attr('id', data_value)

      activate_datepicker( new_row )

    activate_datepicker = ( new_row ) ->
      new_row.find('.js-datepicker').datetimepicker
        minView: 2
        format: 'MM dd, yyyy'

  @social_network_links: ->
    $('body').on 'click', '.social-network-row a.btn.authorize', (event) ->
      authorize_network($(this))

    $('body').on 'click', 'a.btn.deauthorize', (event) ->
      $(this).prop('disabled', true)
      $(this).children('.fa-spinner').removeClass('is-hidden')
      deauthorize_network($(this))

    authorize_network = (object, provider=null) ->
      url = object.data('url')
      win = window.open(url)
      parent = object.closest('.social-network-row')
      callback = object.data('callback')

      load_button = (provider) ->
        if win.closed
          parent.load callback
        else
          setTimeout (->
            load_button(provider)
          ), 500

      setTimeout (->
        load_button(provider)
      ), 500

    deauthorize_network = (object, provider) ->
      url = object.data('url')
      callback = object.data('callback')
      parent = object.closest('.social-network-row')

      $.get url, ->
        parent.load callback

    finish_auth = (form, provider) ->
      form.on 'ajax:complete', (event, xhr, settings) ->
        location.reload()
        $("##{provider}_modal").modal('hide')
        replacement = "<a href='#' class='btn btn-default deauthorize' data-url='/auth/#{provider}/deauthorize' data-callback='/auth/check?provider=#{provider}&label=#{provider}'>Disconnect</a>"
        $("##{provider}_url").html(replacement)

    finish_auth($('form#pinterest-form'), 'pinterest')
    finish_auth($('form#youtube-form'), 'youtube')

  @unhide_timelines_form: ->
    $('body').one 'click', '.js-add-timeline', (event) ->
      $('.js-no-timelines-msg').addClass('is-hidden')
      $('.js-form-timeline').removeClass('is-hidden')

  @multiselect: ->
      $('form').find('select[multiple="multiple"]').multiSelect(
        selectableHeader: "<div class='ms-select-header'>Select all that apply</div>"
        selectionHeader: "<div class='ms-select-header'>Selected</div>"
      )

  @format_inputs: (inputs) ->
    that = this
    inputs.each ->
      newVal = that.numberWithCommas($(this).val())
      $(this).val(newVal)

  @remove_commas: (inputs) ->
    inputs.each ->
      newVal = $(this).val().replace(/,/g, "")
      $(this).val(newVal)

  @bind_number_inputs: ->
    that = this
    $(document).on 'keyup', '.number-with-commas', (event) ->
      event.preventDefault() if event.which >= 37 and event.which <= 40
      $(this).val (index, value) ->
        that.numberWithCommas(value)

  @numberWithCommas: (value) ->
    value.toString().replace(/\D/g, "").replace /\B(?=(\d{3})+(?!\d))/g, ","

  @preview_image: (file_input, preview_img)->
    $('body').on 'change', file_input, (e) ->
      input = $(this)
      file = input[0].files[0]
      reader = new FileReader()
      reader.onload = (e) ->
        image_base64 = e.target.result
        $(preview_img).attr "src", image_base64
        return

      reader.readAsDataURL file

  @preview_remote_image: (file_input, preview_img)->
    $('body').on 'change', file_input, (e) ->
      input = $(this)
      $(preview_img).attr "src", input.val()

  @filestyle_settings: ->
    $(':file').filestyle
      size: 'sm'
      buttonBefore: true
      iconName: 'fas fa-folder-open'

  @spinner: (e) ->
    $('body').on 'click', e, ->
      icon = $('<i class="fas fa-spinner fa-spin"></i>')
      $(this).addClass('disabled')
      $(this).html(icon)

# export default FormUtils