import React from 'react';
import { Provider } from "react-redux"
import InstagramStoryContentContainer from './InstagramStoryContentContainer'
import AdminContentStore from '../redux/AdminContentStore'

class InstagramStoryContentWrapper extends React.Component {
  render() {
    return <Provider store={AdminContentStore}>
      <InstagramStoryContentContainer {...this.props} />
    </Provider>;
  }
};

InstagramStoryContentWrapper.displayName = 'Contents.InstagramStoryContentWrapper';
export default InstagramStoryContentWrapper
