import React from 'react';
import { connect } from 'react-redux';
import SubmissionStoryPreview from './StoryPreview'
import SubmissionBlogPreview from './BlogPreview'
import SubmissionManualAnalyticsPreview from './ManualAnalyticsPreview'
import InstagramOembed from '../InstagramOembed';
import YoutubeEmbed from '../YoutubeEmbed';

class SubmissionPreview extends React.Component {
  loadTiktokEmbed() {
    const { tiktok_oembed_js } = this.props;
    if (!tiktok_oembed_js) return;

    try {
      const tiktok_script = document.createElement("script");
      tiktok_script.async = true;
      tiktok_script.src = tiktok_oembed_js;
      document.head.appendChild(tiktok_script);
    } catch (err) {
      console.log("An error while loading oembed js:");
      console.log(err);
    }
  }

  previewContent() {
    const { preview, task, contentType } = this.props;
    if (!preview) return "";
    if (preview.type === "html") return preview.html;
    else if (preview.type === "failed" && contentType === "TiktokVideoContent")
      return task.content.description;
  }

  displayEmbed() {
    let { preview, contentType } = this.props;

    if (
      !!preview &&
      ["InstagramContent", "InstagramReelContent"].includes(contentType.name)
    ) {
      return <InstagramOembed oembed_html={this.previewContent()} />;
    } else {
      return (
        <div
          className="submission preview-html"
          dangerouslySetInnerHTML={{ __html: this.previewContent() }}
        />
      );
    }
  }

  render() {
    const {preview, contentType, submission, manualAttachments} = this.props;
    const youtubeContentTypes = ['YoutubeShortContent', 'YoutubeContent', 'GoogleYoutubeContent']

    if(!preview || !contentType) {
      if (
          manualAttachments === undefined ||
          manualAttachments.length == 0
        ) {
        return null;
      }
    }

    // Special case: stories render seperately and can't be previewed normally
    if(
        contentType.name == 'InstagramStoryContent' ||
        contentType.name == 'InstagramBusinessStoryContent'
      ) {
      return (
        <SubmissionStoryPreview
          manualAttachments={manualAttachments}
        />
      );
    }

    if (youtubeContentTypes.includes(contentType.name)) {
      return (
        <YoutubeEmbed
          video_url={submission.url}
        />
      );
    }

    switch(preview.type) {
    case 'blog':
      return <SubmissionBlogPreview />;
    case 'html':
      return (
        <React.Fragment>
          {this.displayEmbed()}
          <SubmissionManualAnalyticsPreview />
        </React.Fragment>
      );
    default:
      return <span className='text-muted'>
        No preview available
      </span>;
    }
  }

  componentDidUpdate() {
    const { contentType } = this.props;
    if (!contentType) return null;

    const { name } = contentType;
    switch (name) {
      case "PinterestContent":
        window.pinterestWidgetBuild();
        break;
      case "FacebookContent":
      case "FacebookVideoContent":
        window.FB.XFBML.parse();
        break;
      case "TweetContent":
        window.twttr.widgets.load();
        break;
      case "TiktokVideoContent":
        this.loadTiktokEmbed();
        break;
      default:
        break;
    }
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      preview: state.preview,
      submission: state.submission,
      contentType: state.contentType || {
        name: (state.bloggerShopTask.content || {}).type
      },
      readOnly: state.readOnly
    };
  }
}

SubmissionPreview.displayName = "Submission.Preview";
export default connect(
  SubmissionPreview.mapStateToProps,
  {}
)(SubmissionPreview);
