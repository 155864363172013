import React from 'react';
import { connect } from 'react-redux'
import { FileUploader } from '../utils';

import RichTextEditor from '../RichTextEditor'
import PostingImageEditor from './ImageEditor'
import SaveIndicator from '../SaveIndicator';
import SaveError from '../SaveError';

class PostingEdit extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      image: null,
      preview_url: props.preview_url,
      full_description: props.full_description || '',
      preview_description: props.preview_description || '',
      saving: false,
    }
    this.setValue = this.setValue.bind(this);
  }

  setValue(key, value, callBack = () => {}) {
    this.setState({[key]: value}, () => {
      if (typeof callBack === 'function') {
        callBack();
      }
    });
  }

  save() {
    this.setState({saving: true});
    const { image, full_description, preview_description } = this.state;
    let formData = new FormData();
    if (!!image) {
      formData.append('image', image);
    }
    formData.append('full_description', full_description);
    formData.append('preview_description', preview_description);
    FileUploader().request({
      url: this.props.update_url,
      method: 'PATCH',
      data: formData,
    }).then((response, status, jXHR) => {
      this.setState({saving: false});
      this.props.openNotification('Posting saved!', 'success','')
      setTimeout(this.props.closeNotification, 5000);
    }).catch((jXHR, status, error) => {
      this.props.openNotification('Failed to save posting.','failure',error.response.data.message)
      setTimeout(this.props.closeNotification, 5000);
    })
  }

  render() {
    const {preview_url, full_description, preview_description, saving} = this.state;
    return (
      <div id="new-posting-tab" className="container">
        <PostingImageEditor {...{preview_url}} onChange={newFile => this.setValue('image', newFile)} />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <label className="text optional">
                Description
              </label>
              <RichTextEditor
                value={full_description}
                onUpdate={html => this.setValue('full_description', html)}
                placeholder="Enter a Description for shop"
              />
              <div className="form-group text optional shoppertunity_preview_description">
                <label className="text optional control-label">Short Description</label>
                <textarea maxLength={PostingEdit.TITLE_LIMIT} className="text optional form-control" rows="2" value={preview_description} onChange={(e) => {this.setValue('preview_description', e.target.value)}}></textarea>
                <div className="col-sm-12 text-right">
                  {(PostingEdit.TITLE_LIMIT - preview_description.length)} characters remaining
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <button className="btn primary" onClick={this.save.bind(this)} disabled={saving}>
                Save Changes&nbsp;{saving && <i className="fas fa-circle-notch fa-spin"></i>}
              </button>
            </div>
            {
              !!this.props.notifications.saving &&
              <SaveIndicator type={this.props.notifications.saveType}>
                {this.props.notifications.saveText}
                {this.props.notifications.saveErrors && <SaveError errors={this.props.notifications.saveErrors}/>}
              </SaveIndicator>
            }
          </div>
        </div>
      </div>
    )
  }

  static mapStateToProps(state, myProps) {
    return {
      ...state,
      ...myProps,
    };
  };

  static mapDispatchToProps(dispatch) {
    return {
      setLoading: (bool) => dispatch({ type: 'UPDATE_FIELD', value: { field: 'loading', data: bool } }),
      openNotification: (saveText, saveType, saveError) => dispatch({type: 'OPEN_NOTIFICATION', value: {saveText, saveType, saveError}}),
      closeNotification: () => dispatch({ type: 'CLOSE_NOTIFICATION' })
    };
  };
}

PostingEdit.TITLE_LIMIT = 140;
PostingEdit.displayName = 'Posting.Edit';

export default connect(
  PostingEdit.mapStateToProps,
  PostingEdit.mapDispatchToProps
) (PostingEdit);