import React from 'react';
import { Remote } from '../../utils';
import { connect } from 'react-redux'

import InfluencerDashboardPaymentCard from './PaymentCard'

class InfluencerDashboardPaymentList extends React.Component {
  constructor(props) {
    super(props);

    this.fetch = this.fetch.bind(this);
    this.render = this.render.bind(this);
    this.showMore = this.showMore.bind(this);
    this.updateSort = this.updateSort.bind(this);
  }

  render() {
    const {
      payments,
      sort
    } = this.props;

    return <div className='influencer-dashboard payment-list'>
      <div className='filter-bar'>
        <h2 className='mb-10 pl-10'>Payments</h2>

        <div className='filter-options'>
          <select className='form-control' value={sort.sort} onChange={this.updateSort}>
            <option value='date'>Recent</option>
            <option value='state'>Status</option>
            <option value='amount'>Amount</option>
          </select>

          <i className='fas fa-caret-down' />
        </div>
      </div>

      {payments.map(p => <InfluencerDashboardPaymentCard key={p.id} payment={p} />)}

      {
        !sort.complete &&
        <div className='text-center'>
          <button disabled={!!sort.fetching} className='btn btn-outline' onClick={this.showMore}>
            Show More
            {sort.fetching && <i className='fas fa-circle-notch fa-spin'/>}
          </button>
        </div>
      }
    </div>;
  }

  fetch() {
    const { sort, proxyUrl } = this.props;
    const params = {
      api_action: '/influencer_dashboard/earnings',
      sort: sort.sort,
      page: sort.page
    };

    return Remote().request({
      url: proxyUrl,
      method: 'GET',
      params: params
    });
  }

  showMore(e) {
    const { sort } = this.props;
    e.preventDefault();

    // Using a promise here to ensure that we have the latest sort params when we fetch
    new Promise((resolve, _reject) => {
      this.props.updateSort({
        fetching: true,
        page: sort.page + 1
      });
      resolve();
    }).then(() => {
      this.fetch().then(resp => {
        this.props.addPayments(resp.data.earnings.payments);
        this.props.updateSort({
          fetching: false,
          complete: resp.data.earnings.payments.length === 0
        });
      });
    });
  }

  updateSort(e) {
    e.preventDefault();

    // Using a promise here to ensure that we have the latest sort params when we fetch
    new Promise((resolve, _reject) => {
      this.props.updateSort({ sort: e.target.value });
      resolve();
    }).then(() => {
      this.props.setFetching();
      this.fetch().then(resp => this.props.updateView(resp.data));
    });
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      sort: _.get(state, 'earnings.sort', {}),
      payments: _.get(state, 'earnings.payments', []),
      proxyUrl: state.proxyUrl
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateSort: change => dispatch({ type: 'UPDATE_EARNING_SORT', value: change }),
      updateView: value => dispatch({
        type: 'UPDATE_VIEW',
        value: {
          view: value.view,
          data: value
        }
      }),
      setFetching: () => dispatch({
        type: 'FETCHING_VIEW',
        value: {
          view: 'earnings',
          bool: true
        }
      }),
      addPayments: payments => dispatch({ type: 'ADD_PAYMENTS', value: payments })
    };
  }
};

InfluencerDashboardPaymentList.displayName = 'InfluencerDashboard.PaymentList';
export default connect(
  InfluencerDashboardPaymentList.mapStateToProps,
  InfluencerDashboardPaymentList.mapDispatchToProps
)(InfluencerDashboardPaymentList);
