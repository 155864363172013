import React from 'react';
export default class DatatablePagination extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      totalPages: 0,
      totalShown: 0,
      totalRecords: 0,
      displayStart: 1
    }

    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.updateTableData = this.updateTableData.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.updateTableData();
    }
  }

  componentDidMount() {
    this.updateTableData();
  }

  updateTableData() {
    const { tableData, displayLength, currentPage } = this.props;
    const differenceInShown = tableData.iTotalDisplayRecords / displayLength;
    const totalShown = displayLength > tableData.aaData.length ? displayLength : tableData.aaData.length;
    const totalPages = Math.ceil(differenceInShown);

    this.setState({
      currentPage: currentPage,
      totalPages: totalPages,
      totalShown: totalShown,
      totalRecords: tableData.iTotalDisplayRecords,
    }, () => this.calculateDisplayNum())
  }

  render() {
    return (
      <div className='pagination'>
        {this.renderPageAmount()}
        {this.renderPageLinks()}
      </div>
    );
  }

  renderPageAmount() {
    const { displayStart, displayEnd, totalRecords } = this.state;

    return (
      <div className='amounts'>Showing {displayStart} to {displayEnd} of {totalRecords}</div>
    )
  }

  renderPageLinks() {
    const { currentPage, totalPages } = this.state;

    if (totalPages > 1) {
      let backwardButton = currentPage !== 1;
      let forwardButton = currentPage < totalPages;


      let pageButtons = Array(totalPages).fill().map((_, i) => {
        let pageNum = i + 1; 
        let pageLink = <li key={pageNum} data-currentpage={currentPage === pageNum} onClick={() => this.handlePageClick(pageNum)}>{pageNum}</li>;

        // if pageNum is not first or last page
        if ( pageNum !== 1 && pageNum !== totalPages  ) {

          // Show first 4 pages after first page ex: [first page, 2, 3, 4, 5,]
          if ( currentPage <= 4 && pageNum <= 5) {
            return pageLink

          // Show last 4 pages before the last page ex: [ 6, 7, 8, 9, last page]
          } else if ( currentPage >= 5 && currentPage > totalPages - 4 && pageNum + 4 >= totalPages) {
            return pageLink

          // Show current page and pages before and after if past first five pages ex: [ 6, current page, 7 ]
          } else if ( currentPage >= 5 && (pageNum === currentPage - 1 || pageNum === currentPage + 1 || pageNum === currentPage)) {
            return pageLink
          }
        }
      });

      return (
        <ul className='buttons'>
          <li className='arrow' data-disabled={!backwardButton} onClick={() => backwardButton ? this.handlePageClick(1) : ''}>{'<<'}</li>
          <li className='arrow' data-disabled={!backwardButton} onClick={() => backwardButton ? this.handlePageClick(currentPage - 1) : ''}>{'<'}</li>
          <li data-currentpage={currentPage === 1} onClick={() => backwardButton ? this.handlePageClick(1) : ''}>1</li>
          {currentPage > 4 && <li data-disabled='true'>{'...'}</li>}
          {pageButtons}
          {totalPages > 5 && currentPage + 4 <= totalPages && <li data-disabled='true'>{'...'}</li>}
          <li data-currentpage={currentPage === totalPages} onClick={() => forwardButton ? this.handlePageClick(totalPages) : ''}>{totalPages}</li>
          <li className='arrow' data-disabled={!forwardButton} onClick={() => forwardButton ? this.handlePageClick(currentPage + 1) : ''}>{'>'}</li>
          <li className='arrow' data-disabled={!forwardButton} onClick={() => forwardButton ? this.handlePageClick(totalPages) : ''}>{'>>'}</li>
        </ul>
      )
    }
  }

  calculateDisplayNum(callBack=false) {
    const { currentPage, totalPages, totalShown, totalRecords} = this.state;
    let displayStart = currentPage === 1 ? 1 : totalShown * (currentPage - 1) + 1;

    this.setState({
      displayStart: displayStart,
      displayEnd: currentPage === totalPages ? totalRecords : totalShown * currentPage
    }, () => {
      if ( callBack ) {
        this.props.handlePagination(currentPage, displayStart)
      }
    })
  }

  handlePageClick(pageNum) {
    this.setState({ currentPage: pageNum },() => {
      this.calculateDisplayNum(true)
    })
  }
}
