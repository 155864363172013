import React from 'react';

import LoadingSpinner from './LoadingSpinner'
import MonetaryValue from '../MonetaryValue'
import GoalInfo from './GoalInfo'

export default class PaidMediaGoals extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mediaGoals: null,
      loading: true,

      storyHover: false,
      photoHover: false,
      blogHover: false,
      videoHover: false
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    this.loadPaidMediaGoals = this.loadPaidMediaGoals.bind(this);
    this.formatNumber = this.formatNumber.bind(this);
    this.displayGoal = this.displayGoal.bind(this);
  }

  componentDidMount() {
    this.loadPaidMediaGoals();
  }

  componentWillReceiveProps() {
    this.loadPaidMediaGoals();
  }

  render() {
    const goals = this.state.mediaGoals;
    const { photoHover, storyHover, blogHover,  videoHover } = this.state;

    if(this.state.loading) {
      return <LoadingSpinner loading={true}/>
    }

    return (
      <div>
        <div className='flex-row' style={{justifyContent: 'space-evenly'}}>
          <div className='flex-item text-center'>
            <h4>Impressions</h4>
            <p>
              {this.formatNumber(goals.total_impressions)} / {this.formatNumber(goals.guaranteed_total_impressions)}
            </p>
          </div>
          {
            this.displayGoal(goals.guaranteed_blog_content_views_total) &&
            <div
              className='flex-item text-center'
              onMouseEnter={() => this.setState({ blogHover: true })}
              onMouseLeave={() => this.setState({ blogHover: false })}>
              <h4><i className="fas fa-rss"/> Blog Views</h4>
              <p>
                {this.formatNumber(goals.total_organic_blog_views + goals.total_paid_blog_views)}
                &nbsp;/&nbsp;
                {this.formatNumber(goals.guaranteed_blog_content_views_total)}
              </p>

              <GoalInfo goalName='Blog'
                                    organic={goals.total_organic_blog_views}
                                    organicDate={goals.organic_instagram_photo_views_calculated_at}
                                    paid={goals.total_paid_blog_views}
                                    paidDate={goals.total_paid_blog_views_calculated_at}
                                    hovered={blogHover}/>
            </div>
          }
          {
            this.displayGoal(goals.guaranteed_instagram_views_total) &&
            <div
              onMouseEnter={() => this.setState({ photoHover: true })}
              onMouseLeave={() => this.setState({ photoHover: false })}
              className='flex-item text-center'>
              <h4><i className="fab fa-instagram"/> Photo Views</h4>
              <p>
                {this.formatNumber(goals.organic_instagram_photo_views + goals.paid_instagram_photo_views)}
                &nbsp;/&nbsp;
                {this.formatNumber(goals.guaranteed_instagram_views_total)}
              </p>

              <GoalInfo goalName='Instagram Photo'
                                    organic={goals.organic_instagram_photo_views}
                                    organicDate={goals.organic_instagram_photo_views_calculated_at}
                                    paid={goals.paid_instagram_photo_views}
                                    paidDate={goals.paid_instagram_photo_views_calculated_at}
                                    hovered={photoHover}/>
            </div>
          }
          {
            this.displayGoal(goals.guaranteed_instagram_video_views_total) &&
            <div
              onMouseEnter={() => this.setState({ videoHover: true })}
              onMouseLeave={() => this.setState({ videoHover: false })}
              className='flex-item text-center'>
              <h4><i className="fab fa-instagram"/> Video Views</h4>
              <p>
                {this.formatNumber(goals.organic_instagram_video_views + goals.paid_instagram_video_views)}
                &nbsp;/&nbsp;
                {this.formatNumber(goals.guaranteed_instagram_video_views_total)}
              </p>
              <GoalInfo goalName='Instagram Video'
                                    organic={goals.organic_instagram_video_views}
                                    organicDate={goals.organic_instagram_video_views_calculated_at}
                                    paid={goals.paid_instagram_video_views}
                                    paidDate={goals.paid_instagram_video_views_calculated_at}
                                    hovered={videoHover}/>
            </div>
          }
          {
            this.displayGoal(goals.guaranteed_instagram_story_views_total) &&
            <div
              onMouseEnter={() => this.setState({ storyHover: true })}
              onMouseLeave={() => this.setState({ storyHover: false })}
              className='flex-item text-center'>
              <h4><i className="fab fa-instagram"/> Story Views</h4>
              <p>
                {this.formatNumber(goals.organic_instagram_story_views + goals.paid_instagram_story_views)}
                &nbsp;/&nbsp;
                {this.formatNumber(goals.guaranteed_instagram_story_views_total)}
              </p>
              <GoalInfo goalName='Instagram Story'
                                    organic={goals.organic_instagram_story_views}
                                    organicDate={goals.organic_instagram_story_views_calculated_at}
                                    paid={goals.paid_instagram_story_views}
                                    paidDate={goals.paid_instagram_story_views_calculated_at}
                                    hovered={storyHover}/>
            </div>
          }
          {
            this.displayGoal(goals.guaranteed_youtube_content_views_total) &&
            <div className='flex-item text-center'>
              <h4><i className="fas fa-youtube"/> Youtube Views</h4>
              <p>
                {this.formatNumber(goals.total_youtube_views)} / {this.formatNumber(goals.guaranteed_youtube_content_views_total)}
              </p>
            </div>
          }
          <div className='flex-item text-center'>
            <h4>Engagements</h4>
            <p>
              {this.formatNumber(goals.total_engagements)} / {this.formatNumber(goals.guaranteed_engagements)}
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='guardrails col-sm-6'>
            <h4>SCA Guardrails</h4>
            <p>{goals.paid_media_goals_guardrails}</p>
          </div>
        </div>
        <div className='row'>
          <div className='sca-targeting-notes col-sm-6'>
            <h4>SCA Targeting Notes</h4>
            <p>{goals.sca_targeting_notes}</p>
          </div>
        </div>
        <div className='row'>
          <div className='retargeting-audience col-sm-6'>
            <h4>Retargeting Audiences</h4>
            <p>{goals.retargeting_audiences}</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6'>
            <h4>Test Product</h4>
            <p>Spend: <MonetaryValue value={goals.test_product_spend}/></p>
            <p>Notes: {goals.test_product_notes}</p>
          </div>
        </div>
      </div>
    );
  }

  loadPaidMediaGoals() {
    $.get(`/admin/campaigns/${this.props.campaignId}/promoters_v2/api_proxy/paid_media_lists?resource=goals&id=${this.props.campaignId}`).done((response) => {
      this.setState({
        mediaGoals: response,
        loading: false
      });
    });
  }

  displayGoal(value){
    return value > 0;
  }

  formatNumber(value) {
    if (value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }else{
      return 0;
    }
  }
};

PaidMediaGoals.displayName = 'FacebookAds.PaidMediaGoals';
