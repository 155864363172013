import React from 'react';
import { UserSignUpSocialInfoFields } from './fields/SocialInfoFields';
import UserSignUpValidatedForm from './ValidatedForm';
import UserSignUpInfoAccordion from './InfoAccordion';
import UserSignUpAuthModal from './AuthModal';

const AUTH_URLS = {
  instagram_auth: '/auth/facebook_instagram_business?external_sign_up=true',
  facebook_auth: '/auth/facebook_page?external_sign_up=true',
  pinterest_auth: '/auth/pinterest',
  twitter_auth: '/auth/twitter?external_sign_up=true',
  youtube_auth: '/auth/google_youtube?external_sign_up=true'
}

const TARGET_AMOUNT = 1000

export default class UserSignUpSocialInfo extends React.Component {
  constructor(props){
    super(props);

    UserSignUpSocialInfoFields.blogCategory.options = this.props.category;
    UserSignUpSocialInfoFields.blogPlatform.options = this.props.platform;
    UserSignUpSocialInfoFields.blogLanguage.options = this.props.language;

    this.form = new UserSignUpValidatedForm(this, UserSignUpSocialInfoFields);

    this.state = {
      authModal: false,
      authType: null,
      instagramFollowers: this.props.instagram_auth.followers !== undefined ? this.props.instagram_auth.followers : -1,
      facebookFollowers: this.props.facebook_auth.followers !== undefined ?  this.props.facebook_auth.followers : -1,
      pinterestFollowers: this.props.pinterest_auth.followers !== undefined ? this.props.pinterest_auth.followers : -1,
      youtubeFollowers: this.props.youtube_auth.followers !== undefined ? this.props.youtube_auth.followers : -1,
      followerCount: ( this.props.instagram_auth.followers || 0 ) +
                     ( this.props.facebook_auth.followers || 0 ) +
                     ( this.props.pinterest_auth.followers || 0 ) +
                     ( this.props.youtube_auth.followers || 0 ),
      profilePage: false,
      target_amount: this.props.validate_followers ? TARGET_AMOUNT : 1
    };

    this.doBind();
  }

  componentDidMount(){
    if(!this.isEmptyObject(this.props.instagram_auth) && this.props.instagram_auth.followers === undefined){
      this.showAuthModal('Instagram')
    }
  }

  componentWillMount(){
    this.form.mount();
  }

  isEmptyObject(obj){
    return Object.values(obj).length === 0
  }

  validFollowerCount(){
    const state = this.state
    let followerItems = Object.keys(state).filter(item => /Followers/.test(item) )
    let totalCount = Object.values(followerItems).reduce((total, key) => total + (state[key] === -1 ? 0 : state[key]), 0)

    this.setState({followerCount: totalCount})
    return totalCount >= this.state.target_amount
  }

  validBlogInformation(){
    const { values } = this.props
    const { fields } = this.state
    if (!values || !fields) return false
    let blogItems = Object.keys(fields).filter(item => /blog/i.test(item))
    // All blog fields must be entered or none at all
    let allHaveValues = blogItems.every((key) => { return !!values[key] })
    let allAreBlank = blogItems.every((key) => { return !values[key] })
    return (allHaveValues || allAreBlank)
  }

  componentWillReceiveProps(nextProps) {
    const authKeys = ['instagram_auth', 'facebook_auth', 'pinterest_auth', 'youtube_auth']
    let authHasChanges = authKeys.some(auth => nextProps[auth].followers !== this.props[auth].followers)

    if(!this.isEmptyObject(nextProps.instagram_auth) && nextProps.instagram_auth.followers === undefined){
      this.showAuthModal('Instagram')
    }

    if(authHasChanges){
      this.setState({
        instagramFollowers: nextProps.instagram_auth.followers !== undefined ? nextProps.instagram_auth.followers : -1,
        facebookFollowers: nextProps.facebook_auth.followers !== undefined ?  nextProps.facebook_auth.followers : -1,
        pinterestFollowers: nextProps.pinterest_auth.followers !== undefined ? nextProps.pinterest_auth.followers : -1,
        youtubeFollowers: nextProps.youtube_auth.followers !== undefined ? nextProps.youtube_auth.followers : -1,
      },() => {
        this.props.disableContinue(!(this.validFollowerCount() && this.validBlogInformation()))
      });
    }
  }

  componentDidUpdate(prevProps){
    const propValues = Object.values(this.props.values || {})
    const prevPropsValues = Object.values(prevProps.values || {})

    if (propValues.every( e => prevPropsValues.includes(e)))
      return

    this.props.disableContinue(!(this.validBlogInformation() && this.validFollowerCount()))
  }

  handleAuth(event){
    event.preventDefault();
    const authType = event.currentTarget.id
    window.localStorage.setItem(authType, null)
    window.addEventListener('storage', () => this.props.authListener(authType))
    window.open(AUTH_URLS[authType], '_blank')
  }

  showAuthModal(authType) {
    this.setState({
      authModal: true,
      authType: authType})
  }

  closeAuthModal() {
    this.setState({authModal: false})
  }

  render(){
    const { heading, instruction, basic_requirement, requirement, blog_info, accordion, follower_warning } = this.props

    let instagramFollowers = this.state.instagramFollowers !== -1 ? this.state.instagramFollowers : null
    let facebookFollowers = this.state.facebookFollowers !== -1 ?  this.state.facebookFollowers : null
    let pinterestFollowers = this.state.pinterestFollowers !== -1 ? this.state.pinterestFollowers : null
    let twitterFollowers = this.state.twitterFollowers !== -1 ? this.state.twitterFollowers : null
    let youtubeFollowers = this.state.youtubeFollowers !== -1 ? this.state.youtubeFollowers : null
    let totalFollowers = this.state.followerCount

    let hasAuth = instagramFollowers !== null || youtubeFollowers !== null
    let followerWarning = (instagramFollowers >= 10000 || youtubeFollowers >= 10000)
    let requirementInfo = this.props.validate_followers ? requirement : basic_requirement

    return (
      <div className='sign-up-social-info'>
        <div className='column'>
          <h4>{heading}</h4>
          <p>{instruction}</p>
          <p>{requirementInfo}</p>
          <div className='external-auth'>
            <a href='#' id='instagram_auth' className='btn btn-social' onClick={(e) => this.handleAuth(e)} disabled={instagramFollowers !== null}>
              <i className='fab fa-instagram'></i>
              <span>{instagramFollowers === null ? 'Instagram' : <b>{instagramFollowers.toLocaleString()}</b> }</span>
            </a>
            <a href='#' id='facebook_auth' className='btn btn-social' onClick={(e) => this.handleAuth(e)} disabled={facebookFollowers !== null}>
              <i className='fab fa-facebook'></i>
              <span>{facebookFollowers === null ? 'Facebook' : <b>{facebookFollowers.toLocaleString()}</b> }</span>
            </a>
            <a href='#' id='pinterst_auth' className='btn btn-social' onClick={() => this.showAuthModal('Pinterest')} disabled={pinterestFollowers !== null}>
              <i className='fab fa-pinterest-p'></i>
              <span>{pinterestFollowers === null ? 'Pinterest' : <b>{pinterestFollowers.toLocaleString()}</b> }</span>
            </a>
            { this.state.profilePage &&
              <a href='#' id='twitter_auth' className='btn btn-social' onClick={(e) => this.handleAuth(e)} disabled={twitterFollowers != null}>
                <i className='fab fa-twitter'></i>
                <span>Twitter</span>
              </a>
            }
            <a href='#' id='youtube_auth' className='btn btn-social' onClick={(e) => this.handleAuth(e)} disabled={youtubeFollowers !== null}>
              <i className='fab fa-youtube'></i>
              <span>{youtubeFollowers === null ? 'Youtube' : <b>{youtubeFollowers.toLocaleString()}</b> }</span>
            </a>
          </div>
          { this.props.validate_followers && hasAuth && !followerWarning &&
            <h5 className='sign-up-follower-warning'>
              <i className='fas fa-exclamation-triangle' />&nbsp;
              {follower_warning}
            </h5>
          }
          <h3>
            Total Followers:&nbsp;
            { totalFollowers >= this.state.target_amount ?
              <span className='over_limit'>{totalFollowers.toLocaleString()}</span> :
              <span className='below_limit'>{totalFollowers.toLocaleString()}/{this.state.target_amount.toLocaleString()}</span>
            }
          </h3>
          <div className='sign-up-blog-info'>
          {blog_info}
          <div className='form'>
            <div className='column'>
              { this.form.renderField('blogName') }
              { this.form.renderField('blogUrl') }
              { this.form.renderField('blogCategory') }
            </div>

            <div className='column'>
              { this.form.renderField('blogPlatform') }
              { this.form.renderField('blogLanguage') }
            </div>
          </div>
        </div>
        </div>
        <div className='column'>
          { Object.keys(accordion).map((key) => {
            return <UserSignUpInfoAccordion key={key} title={accordion[key].title} body={accordion[key].body} />
          })}
        </div>
        {this.state.authModal &&
          <UserSignUpAuthModal
            isOpen={this.state.authModal}
            closeModal={this.closeAuthModal}
            updateAuth={this.props.updateAuth}
            authType={this.state.authType}
            instagramAuth={this.props.instagram_auth}
            form={this.form}
          />
        }
      </div>
    );
  }

  doBind(){
    this.render = this.render.bind(this);
    this.handleAuth = this.handleAuth.bind(this);
    this.showAuthModal = this.showAuthModal.bind(this);
    this.closeAuthModal = this.closeAuthModal.bind(this);
    this.validFollowerCount = this.validFollowerCount.bind(this)
    this.validBlogInformation = this.validBlogInformation.bind(this)
    this.isEmptyObject = this.isEmptyObject.bind(this);
  }
}