import React from 'react';
import { connect } from 'react-redux'
import { Remote } from '../utils';

import BackgroundFade from '../BackgroundFade'
import LoadingSpinner from '../LoadingSpinner'
import Modal from '../Modal'
import SearchList from '../SearchList'
import RoundTaskSelection from './RoundTaskSelection'

class RoundTasksAddFromGeneralModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addType: 'all',
      searching: false,
      selectedTemplate: null,
      selectedTaskIds: [],
      loading: false
    };

    this.disableSubmit = this.disableSubmit.bind(this);
    this.render = this.render.bind(this);
    this.renderSearchButton = this.renderSearchButton.bind(this);
    this.renderTypeSelection = this.renderTypeSelection.bind(this);
    this.submit = this.submit.bind(this);
    this.updateRoundTasks = this.updateRoundTasks.bind(this);
  }

  render() {
    return <React.Fragment>
      <BackgroundFade zIndex={100} onClick={() => this.props.toggleModal()}/>
      <Modal size='md'>
        <div className='modal-header'>
          Add General Requirements
          <i className='pull-right fas fa-times' onClick={() => this.props.toggleModal()} />
        </div>
        <div className='modal-body round-tasks add-from-general-modal'>
          <div className='selection-container'>
            <div className='left'>
              General Requirement:
            </div>
            <div className='right'>
              {this.renderSearchButton()}
            </div>
          </div>
          {this.renderTypeSelection()}
          {
            this.state.selectedTemplate &&
            this.state.addType === 'some' &&
            <RoundTaskSelection
              selected={this.state.selectedTaskIds}
              onSelect={selected => this.setState({ selectedTaskIds: selected })}
              options={this.props.roundTasks}
              />
          }
          { this.state.loading &&  <LoadingSpinner /> }
        </div>
        <div className='modal-footer text-right'>
          <button className='btn btn-primary' disabled={this.disableSubmit()} onClick={() => this.submit()}>
            Submit
          </button>
        </div>
      </Modal>
    </React.Fragment>;
  }

  renderSearchButton() {
    if(this.state.searching) {
      return <SearchList
        placeholder='Search ...'
        onSelect={item => this.setState({ selectedTemplate: item })}
        elements={this.props.generalTemplates}
        startFocus={true}
        onBlur={() => this.setState({ searching: false })}
        style={{ height: '32px', width: '100%' }}
        loading={this.props.loadingTemplates}
        />;
    }

    return <div className='search-btn' onClick={() => this.setState({ searching: true })}>
      {_.get(this.state.selectedTemplate, 'name', 'Search ...')}
      <i className='fas fa-caret-down'/>
    </div>;
  }

  renderTypeSelection() {
    if(!this.state.selectedTemplate) {
      return null;
    }

    const handleSelection = e => this.setState({ addType: e.target.value });

    return <div className='form-group'>
      <div className='radio'>
        <label>
          <input type='radio' name='addType' value='all' checked={this.state.addType === 'all'} onChange={handleSelection} />
          Add to all Tasks
        </label>
      </div>
      <div className='radio'>
        <label>
          <input type='radio' name='addType' value='some' checked={this.state.addType === 'some'} onChange={handleSelection} />
          Add to some Tasks
        </label>
      </div>
    </div>;
  }

  disableSubmit() {
    if(this.state.selectedTemplate && !this.state.loading) {
      switch(this.state.addType) {
      case 'all':
        return false;
      case 'some':
        return this.state.selectedTaskIds.length == 0;
      default:
        return true;
      }
    }

    return true;
  }

  submit() {
    if(this.disableSubmit()) {
      return;
    }

    this.setState({ loading: true });

    const params = $.param({
      api_action: `/round_tasks/${this.state.selectedTemplate.id}/copy`
    });

    let ids = this.state.selectedTaskIds;
    if(this.state.addType === 'all') {
      ids = this.props.roundTasks.map(task => task.id);
    }

    return Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'POST',
      data: {
        round_task_ids: ids
      }
    }).then(response => {
      this.setState({ loading: false }, () => {
        this.updateRoundTasks(response.data);
        this.props.toggleModal();
        this.props.buildPalette();
      });
    }).catch(error => {
      console.log(error);
    });
  }

  updateRoundTasks(roundTasks) {
    roundTasks.forEach(task => {
      const clientReqs = this.props.roundTasks.find(t => t.id === task.id).round_task_requirements;
      const newReqs = task.round_task_requirements.filter(newReq => !_.includes(clientReqs.map(x => x.id), newReq.id));

      // Append new requirements
      newReqs.forEach(newReq => this.props.addRequirement(task.id, newReq));

      // Update all existing sorts
      task.round_task_requirements.forEach(req => {
        this.props.editRequirement(task.id, req.id, 'sort', req.sort);
      });

      // Force the sort order on the round task (otherwise the component assumes the sort is fine automatically)
      this.props.editTask(task.id, 'round_task_requirements', _.sortBy(task.round_task_requirements, x => x.sort));
    });
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      generalTemplates: state.generalTemplates,
      roundTasks: _.get(state.round, 'round_tasks', []),
      proxyUrl: state.meta.proxyUrl
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      toggleModal: () => dispatch({ type: 'TOGGLE_GENERAL_MODAL' }),
      addRequirement: (taskId, req) => dispatch({
        type: 'ADD_REQUIREMENT',
        value: {
          taskId: taskId,
          value: req
        }
      }),
      buildPalette: () => dispatch({ type: 'BUILD_PALETTE' }),
      editRequirement: (taskId, id, field, value) => dispatch({
        type: 'EDIT_REQUIREMENT_BY_ID',
        value: {
          taskId,
          id,
          field,
          value
        }
      }),
      editTask: (taskId, field, value) => dispatch({ type: 'EDIT_TASK', value: { taskId, field, value }})
    };
  }
};

RoundTasksAddFromGeneralModal.displayName = 'RoundTasks.AddFromGeneralModal';

export default connect(
  RoundTasksAddFromGeneralModal.mapStateToProps,
  RoundTasksAddFromGeneralModal.mapDispatchToProps
)(RoundTasksAddFromGeneralModal);
