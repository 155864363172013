import React from 'react';
import NavigationTabsWrapper from '../navigation_tabs/Wrapper'

export default class ShoppertunitiesShowNav extends React.Component {
  constructor(props) {
    super(props);

    this.state ={
      tabs: this.tabs(),
      links: this.links(),
      order: this.order(),
      activeTab: this.activeTab()
    };
  }

  order() {
    const {
      application_access,
      instructions_tab_access,
      tasks_tab_access,
      details_access,
      bid_tab_access,
    } = this.props;

    const show_details = application_access || instructions_tab_access || tasks_tab_access || details_access;

    let tabOrder = [];

    if (show_details)
      tabOrder.push('details');
    if (application_access)
      tabOrder.push('application');
    if (application_access && bid_tab_access)
      tabOrder.push('bids');
    if (instructions_tab_access)
      tabOrder.push('instructions');
    if (tasks_tab_access)
      tabOrder.push('tasks');
    return tabOrder;
  }

  tabs() {
    return {
      details: 'Details',
      application: 'Application',
      bids: 'Bid Negotiations',
      instructions: 'Instructions',
      tasks: 'Tasks',
    };
  }

  links() {
    const { shop_id } = this.props;
    return {
      details: `/opportunities/${shop_id}`,
      application: `/opportunities/${shop_id}/application`,
      bids: `/opportunities/${this.props.shop_id}/bids`,
      instructions: `/opportunities/${this.props.shop_id}/instructions`,
      tasks: `/opportunities/${this.props.shop_id}/tasks`,
    }
  }

  activeTab(){
    const selectedTab = (page) => {
      switch (page) {
        case 'application':
        case 'tasks':
        case 'bids':
        case 'instructions':
          return page;
        default:
          return 'details';
      }
    }
    return selectedTab(this.props.currentPage.toLowerCase());
  }

  render() {
    return <NavigationTabsWrapper { ...this.state } menuName='Oppertunity Menu'/>
  }
}
ShoppertunitiesShowNav.displayName = 'Shoppertunities.ShowNav';