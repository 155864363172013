import { createStore } from 'redux';

const initialState = {
  loading: false,
  submitting: false,
  baseUrl: null,
  proxyUrl: null,
  error: false,
  errorText: '',
  currentUserId: null,
  taskId: null,
  roundTask: {},
  communities: [],
  editingType: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'START_LOADING':
      return {
        ...state,
        loading: true
      };
    case 'STOP_LOADING':
      return {
        ...state,
        loading: false
      };
    case 'START_SUBMITTING':
      return {
        ...state,
        submitting: true
      };
    case 'STOP_SUBMITTING':
      return {
        ...state,
        submitting: false
      };

    // Form Fields
    case 'UPDATE_FIELD':
      const { field, value } = action.value;
      let task = _.cloneDeep(state.roundTask);
      _.set(task, field, value);
      return {
        ...state,
        roundTask: task
      };

    // Task Requirements
    case 'ADD_REQUIREMENT': {
      const value = {
        id: null,
        description: '',
        verification_text: '',
        frequency: null,
        verification_type: [{'id': 'manual', 'name': 'Manual'}],
        requirement_type: 'standard',
        repeat_type: null,
        repeat_count: null,
        _destroy: false,
        validations: {}
      }
      return {
        ...state,
        roundTask: {
          ...state.roundTask,
          round_task_requirements: [...(state.roundTask.round_task_requirements || []), value]
        }
      };
    }
    case 'REMOVE_REQUIREMENT':
      let requirements = [...state.roundTask.round_task_requirements];
      if(requirements.length == 1) {
        requirements = [];
      } else {
        requirements.splice(action.value, 1);
      }

      return {
        ...state,
        roundTask: {
          ...state.roundTask,
          round_task_requirements: requirements
        }
      };
    case 'UPDATE_REQUIREMENT': {
      const { field, value, index } = action.value;
      let nextRequirements = _.cloneDeep(state.roundTask.round_task_requirements);
      _.set(nextRequirements[index], field, value);

      return {
        ...state,
        roundTask: {
          ...state.roundTask,
          round_task_requirements: nextRequirements
        }
      };
    }
    case 'UPDATE_REQUIREMENT_SORT':
      let nextRequirements = _.cloneDeep(state.roundTask.round_task_requirements);
      nextRequirements.splice(action.value.to, 0, nextRequirements.splice(action.value.from, 1)[0]);

      return {
        ...state,
        roundTask: {
          ...state.roundTask,
          round_task_requirements: nextRequirements
        }
      };
    // Special case -- just interpolate all data in
    // Should only be used for initialization purposes
    case 'UPDATE_META': {
      return {
        ...state,
        ...action.value
      };
    }
    case 'SET_TASK':
      return {
        ...state,
        roundTask: action.value
      };
    case 'SET_COMMUNITIES':
      return {
        ...state,
        communities: action.value
      };
    case 'SET_EDITING_TYPE':
      return {
        ...state,
        editingType: action.value
      };
    default: {
      return state;
    }

  }
};

const RoundTasksFormStore = createStore(
  reducer,
  window && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ name: 'Redux.RoundTasksFormStore' }) // Enable redux devtools middleware
);

export default RoundTasksFormStore;