import React from 'react';
import PropTypes from 'prop-types'
import TableActions from './Actions'

export default class TableRow extends React.Component {
  render() {
    return (
      <tr>
        {this.props.columns.map((col, i) => <th key={i}>{this.props.record[col]}</th>)}
        <td className='align-right'>
          <TableActions record={this.props.record} baseUrl={this.props.baseUrl}/>
        </td>
      </tr>
    );
  }
};

TableRow.displayName = 'Table.Row';
TableRow.propTypes = {
  record: PropTypes.any.isRequired
};

