import React from 'react';
import { Remote } from '../utils';

import LoadingSpinner from './LoadingSpinner'
import ReviewEditor from './ReviewEditor'

export default class AdReview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      adList: [],
      networkRequests: [],
      editing: false,
      selectedAdId: '',
      selectedAdIndex: 0
    };

    this.render = this.render.bind(this);
    this.renderTableRows = this.renderTableRows.bind(this);
    this.removeAd = this.removeAd.bind(this);
    this.editAd = this.editAd.bind(this);
    this.finishEditing = this.finishEditing.bind(this);
    this.generateAdSet = this.generateAdSet.bind(this);
    this.addRequest = this.addRequest.bind(this);
    this.updateCampaignNames = this.updateCampaignNames.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });

    const xhr = $.get(this.props.adsEndpoint)
                 .done((data) => this.setState({ adList: data, loading: false }));

    this.addRequest(xhr);
  }

  render() {
    if(this.state.loading) {
      return <LoadingSpinner loading={true} />;
    }

    if(this.state.editing) {
      const selectedAd = this.state.adList[this.state.selectedAdIndex];
      return  <ReviewEditor adId={this.state.selectedAdId}
                                        selectedAdIndex={this.state.selectedAdIndex}
                                        adEndpoint={this.props.adEndpoint}
                                        previewEndpoint={this.props.previewEndpoint}
                                        close={this.finishEditing}
                                        wizardState={this.props.wizardState}
                                        adData={selectedAd.ad_data_copy}
                                        imageHash={selectedAd.image_hash}/>;
    }

    return (
      <div className='modal-inner-content'>
        <div className='ad-review'>
          <div className='table-wrapper'>
            <div className='table-scroll'>
              <table className='table table-striped table-bordered'>
                <thead>
                  <tr>
                    <th><span>Image</span></th>
                    <th><span>Headline</span></th>
                    <th><span>Text</span></th>
                    <th><span>Description</span></th>
                    <th><span>Ad Type</span></th>
                    <th><span>Edit</span></th>
                    <th><span>Delete</span></th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderTableRows()}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='modal-next'>
          <button className='btn btn-primary' onClick={this.generateAdSet}>
            Finish
          </button>
        </div>
      </div>
    );
  }

  renderTableRows() {
    const ad_list = this.state.adList;

    return ad_list.map((ad, index) => {
      return (
        <tr key={ad['id']}>
            <td className='col-image'><div style={{backgroundImage: `url(${ad['image']})`}}></div></td>
            <td className='col-headline'>{ad['headline']}</td>
            <td className='col-text'>{ad['text']} </td>
            <td className='col-description'>{ad['description']}</td>
            <td className='col-type'>{ad['ad_type']['name']}</td>
            <td className='col-edit'><a onClick={() => this.editAd(ad['id'], index)}><i className='fas fa-pencil-alt' /></a></td>
            <td className='col-delete'><a onClick={() => this.removeAd(ad['id'])}><i className='fas fa-trash' /></a></td>
        </tr>
      );
    });
  }

  removeAd(id) {
    const component = this;

    Remote().request({
      url: this.props.adEndpoint(id),
      method: 'DELETE'
    }).then((response) => this.setState({
      adList: this.state.adList.filter(x => x.id != id)
    }));
  }

  editAd(id, index) {
    this.setState({
      selectedAdId: id,
      selectedAdIndex: index,
      editing: true
    })
  }

  finishEditing(){
    this.setState({
      editing: false,
      loading: true
    });

    const xhr = $.get(this.props.adsEndpoint)
                 .done((data) => this.setState({ adList: data, loading: false }));

    this.addRequest(xhr);
  }

  generateAdSet() {
    const component = this;
    this.props.refresh();
    this.props.closeModal();
  }

  addRequest(xhr) {
    this.setState({
      networkRequests: this.state.networkRequests.concat(xhr)
    });
  }

  updateCampaignNames() {
    $.get(this.props.stateEndpoint).done((data) => {
      const campaigns = data.ad_campaigns;
      campaigns.forEach(campaign => $(`.ad-campaign-title[data-ad-campaign-id=${campaign.id}]`).text(campaign.name));
    });
  }
};
