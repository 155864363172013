import React from 'react';
import SubmissionStateIndicator from './content_types/StateIndicator'

export default class SubmissionInstagramMediaAttachment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: false,
      showRejection: true
    }

    this.video = React.createRef();
    this.renderVideoPlayer = this.renderVideoPlayer.bind(this);
    this.setPlayingState = this.setPlayingState.bind(this);
    this.render = this.render.bind(this);
    this.isVideo = this.isVideo.bind(this);
    this.toggleRejectionComment = this.toggleRejectionComment.bind(this);
    this.validationState = this.validationState.bind(this);
  }

  isVideo(fileType){
    return fileType.includes("VIDEO")
  }

  toggleRejectionComment() {
    this.setState({ showRejection: !this.state.showRejection})
  }

  validationState() {
    const { playing } = this.state;

    if (playing) {
      return 'playing';
    } else {
      return 'passed';
    }
  }

  renderVideoPlayer() {
    return (
      <video ref={this.video}
        className='video'
        onPause={(e) => this.setPlayingState(e, false)}
        src={this.props.media.media_url}
        preload='none'
        controls></video>
    )
  }

  setPlayingState(e, state) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      playing: state
    }, () => {
      if (state) {
        this.video.current.play();
      } else {
        this.video.current.pause();
      }
    });
  }

  render() {
    const { media } = this.props;
    const isVideo = this.isVideo(media.media_type);
    const rejected = media.state == 'rejected' || media.rejected;
    const background = isVideo ? { backgroundImage: `url(${media.thumbnail_url})`} : {backgroundImage: `url(${media.media_url})`}
    let rejectionIcon = this.state.showRejection ? 'fa-times' : 'fa-exclamation'
    const state = this.validationState();

    if(rejected && !this.props.readOnly ){
      return null;
    } else {
      return (
        <div className={`submission grid-item instagram ${this.state.playing ? 'playing' : 'selected'}` }>
          <div className='item-image' style={background}>
            <SubmissionStateIndicator state={state} isSelected={!rejected} isVideo={isVideo} setPlayingState={this.setPlayingState}/>
          </div>
          {!this.props.readOnly && <div className='delete-item' onClick={()=> this.props.deleteMedia(media)}><i className="fas fa-times" /></div>}
          {!rejected && this.props.readOnly &&
            <div className='approved-item' onClick={()=> this.toggleRejectionComment() }>
              <i className={`fas fa-check`} />
            </div>
          }
          {rejected &&
            <React.Fragment>
              <div className='rejected-item' onClick={()=> this.toggleRejectionComment() }>
                <i className={`fas ${rejectionIcon}`} />
              </div>
              {this.state.showRejection &&
                <div className='rejected-item--description'>
                  <div className='rejected-item--body'>
                    <label>Rejection Note</label>
                    <p className='rejection-comment' >{media.reject_reason}</p>
                  </div>
                </div>
              }
            </React.Fragment>
          }
          { isVideo && this.renderVideoPlayer(media) }
        </div>
      )
    }
  }
};

SubmissionInstagramMediaAttachment.displayName = 'Submission.InstagramMediaAttachment';