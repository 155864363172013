import React from 'react';
import UserSignUpValidatedField from './ValidatedField'

export default class UserSignUpValidatedForm {

  constructor(parent, fields){
    this.h = parent;
    this.fields = fields;
    this.mounted = false;
    this.mount = this.mount.bind(this);
    this.validForm = this.validForm.bind(this);
    this.renderField = this.renderField.bind(this);
    this.fieldValidationCallback = this.fieldValidationCallback.bind(this);
  }

  mount() {
    this.h.setState({
      fields: this.fields
    });
    this.h.props.disableContinue(!this.validForm())
    this.mounted = true;
    Object.keys(this.fields).forEach((fieldName) =>{
      let value = '';

      if (this.h.props.values) {
        value = this.h.props.values[fieldName];
      } else if (this.h.props[fieldName]) {
        value = this.h.props[fieldName];
      }

      this.validate(fieldName, value, true);
    })
  }

  renderField(key, value=null) {
    if (!this.mounted) return null;
    const { fields } = this.h.state

    // If this happens, parent tried to a field that does not exist on the form fields.
    if (!fields[key]) return <h4>404! {key} FIELD NOT FOUND!!!</h4>

    const { valueName, parentField } = fields[key]

    let disabled = false;
    if ( parentField ){
      const parentValue = this.h.props.values ? this.h.props.values[parentField] : ''
      disabled = !parentValue
    }

    let fieldDefaultValue = '';

    if (value || this.h.props.values) {
      fieldDefaultValue = value;
    }

    return <UserSignUpValidatedField
            fieldName={ valueName || key } { ...fields[key]}
            value={ fieldDefaultValue }
            error={ fields[key].error }
            disabled={ disabled }
            validationCallback={(value) => this.validate(key, value)}
            />
  }

  validate(fieldName, value, suppressErrors=false){
    const { required, regex, regexMessage, resetFields, valueName, linkedField, ageRequirement, ageRequirementMessage } = this.fields[fieldName];
    let error = null;
    let valid = true

    // Simple case, just display a message that the field is required
    if (!error && required && (!value || value.length === 0)) {
      valid = false
      if (!suppressErrors)
        error = 'This field is required';
    }

    // More complex case -- try and match a regex, and if it fails display a custom message
    // perform regex only when field is required and value is present
    if (!error && regex && !!value) {
      if(!(value || '').match(regex)) {
        valid = false
        if (!suppressErrors)
          error = regexMessage || 'Missing regex message!';
      }
      // When not valid, validated against linkedField's regex. value may belong to linked field
      // Only on page load when suppressErrors is true
      if ( suppressErrors && !valid && linkedField && fieldName !== linkedField) {
        const { regex } = this.fields[linkedField]
        if((value || '').match(regex)) {
          valid = true
          error = null
        }
      }

      // When age needs to be validated against an age requirement
      if (!error && ageRequirement && !!value) {
        const dob = new Date(value).getTime();
        const today = new Date();
        const ageMinimum = today.setFullYear(today.getFullYear() - ageRequirement);

        if ((ageMinimum - dob) < 0) {
          valid = false
          if (!suppressErrors)
            error = ageRequirementMessage || 'Missing age requirement message!';
        }
      }
    }

    // Using validation from state.fields to assign errors
    this.fieldValidationCallback(fieldName, error, valid)

    // when linkedField is set we also need to validate that field
    // linkedField must match an existing fieldName in fields
    if ( linkedField && fieldName !== linkedField)
      this.fieldValidationCallback(linkedField, error, valid)

    // when resetFields is present, invalidate fields specified in resetFields
    if ( resetFields ){
      resetFields.forEach((fieldName) =>{
        this.validate(fieldName, '', false);
      })
    }

    // There is a slight delay before setState takes effect
    //  need a delay before validating the form.
    setTimeout(() => {
      this.h.props.fieldCallback(valueName || fieldName, value);
    }, 1);
  }

  fieldValidationCallback(fieldName, error, valid) {
    if (!this.mounted) return null;
    this.h.setState(prevState => ({
      fields: Object.keys(prevState.fields).map((field) => {
        return {
          [field]: field === fieldName ? {...prevState.fields[field], error: error, isValid: valid} : prevState.fields[field]
        }
      }).reduce((newState, currentField) => Object.assign({},newState, currentField), {})
    }), () => {
      this.h.props.disableContinue(!this.validForm());
    });
  }

  validForm(){
    if (!this.h.state || !this.mounted) return false
    const { fields } = this.h.state;
    return Object.keys(fields).every((key) => {
      if (fields[key].required) {
        return fields[key].isValid
      } else {
        return true
      }
    })
  }
}