import React from 'react';
class RecommendationQuestions extends React.Component {
  constructor(props) {
      super(props);
      this.state = {}
  }

  render(){
    const { questions } = this.props;
    const alignCenter = {textAlign: 'center'};
    const optionWidth = { width: '6%' };
    return (
        <div className ='table-responsive container-fluid' style={alignCenter}>
            <table className='table table-bordered table-striped'>
                <thead>
                <tr>
                    <th></th>
                    <th style={{...alignCenter, ...optionWidth}}>1 <br />Not Likely</th>
                    <th style={{...alignCenter, ...optionWidth}}>2</th>
                    <th style={{...alignCenter, ...optionWidth}}>3</th>
                    <th style={{...alignCenter, ...optionWidth}}>4</th>
                    <th style={{...alignCenter, ...optionWidth}}>5</th>
                    <th style={{...alignCenter, ...optionWidth}}>6</th>
                    <th style={{...alignCenter, ...optionWidth}}>7</th>
                    <th style={{...alignCenter, ...optionWidth}}>8</th>
                    <th style={{...alignCenter, ...optionWidth}}>9</th>
                    <th style={{...alignCenter, ...optionWidth}}>10 <br />Extremely Likely</th>
                </tr>
                </thead>
                <tbody>
                { questions.map((question) =>
                    <tr key={ question.id }>
                        <th>{ question.description }</th>
                        <td style={{...alignCenter, ...optionWidth}}><input type="radio" value="1" name={'questions['+question.id+']'} /></td>
                        <td style={{...alignCenter, ...optionWidth}}><input type="radio" value="2" name={'questions['+question.id+']'} /></td>
                        <td style={{...alignCenter, ...optionWidth}}><input type="radio" value="3" name={'questions['+question.id+']'} /></td>
                        <td style={{...alignCenter, ...optionWidth}}><input type="radio" value="4" name={'questions['+question.id+']'} /></td>
                        <td style={{...alignCenter, ...optionWidth}}><input type="radio" value="5" name={'questions['+question.id+']'} /></td>
                        <td style={{...alignCenter, ...optionWidth}}><input type="radio" value="6" name={'questions['+question.id+']'} /></td>
                        <td style={{...alignCenter, ...optionWidth}}><input type="radio" value="7" name={'questions['+question.id+']'} /></td>
                        <td style={{...alignCenter, ...optionWidth}}><input type="radio" value="8" name={'questions['+question.id+']'} /></td>
                        <td style={{...alignCenter, ...optionWidth}}><input type="radio" value="9" name={'questions['+question.id+']'} /></td>
                        <td style={{...alignCenter, ...optionWidth}}><input type="radio" value="10" name={'questions['+question.id+']'} required /></td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    )
  }
}