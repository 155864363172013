class window.Posts

  @new: ->
    @post_editor()

  @create: ->
    @post_editor()

  @edit: ->
    @post_editor()
    $('#editor').html( $('#post_body').val() )

  @update: ->
    @edit()

  @show: ->
    $('#comment_body').redactor
      plugins: plugin_list

  @post_editor: ->
    $('#post_body').redactor
      focus: true
      plugins: plugin_list
