import React from 'react';
import { connect } from 'react-redux';
import { Remote } from '../utils';

import ConversationsMessageBubble from './MessageBubble'

class ConversationsMessageWindow extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.loadMoreMessages = this.loadMoreMessages.bind(this);
    this.container = this.container.bind(this);
  }

  loadMoreMessages() {
    const {conversation, messages_page} = this.props;
    let next_page = messages_page + 1;
    let current_offset = this.container().scrollHeight;
    this.props.toggleLoadingMessages();
    Remote().request({
      method: 'GET',
      url: `/conversations/more?conversation_id=${conversation.id}&page=${next_page}`,

    }).then((response) => {
      let messages = response.data.messages;
      this.props.updateMessages({messages});
      let latest_offset = this.container().scrollHeight;
      this.container().scrollTo(0, (latest_offset-current_offset));
    }).catch((error) => {
      this.props.toggleLoadingMessages();
    });
  }

  container() {
    return document.querySelector('#conversation-messages-container');
  }

  render() {
    const {loading, selected, load_more_messages, conversation} = this.props;

    return(
      <div id='conversation-messages-container' className="conversation-container position-relative">
        { loading &&
          <div className='conversation-loading-spinner'>
            <i className="fas fa-circle-notch fa-spin fa-3x fa-fw" />
            <span className="sr-only">Loading...</span>
          </div>
        }
        { (selected && !loading) &&
          <div className="card-body">
            {
              ( (conversation.messages ? conversation.messages.length : 0) < (conversation.messages_count || 0)) &&
              <div id="show_more_messages" className='conversation-show-more' key='show_more_messages'>
                {
                  !load_more_messages &&
                  <button id="show_more_button" className="btn btn-default" onClick={this.loadMoreMessages}>
                    Load More
                  </button>
                }
                { load_more_messages && <i id='show_more_spinner' className='fas fa-spinner fa-spin'></i> }
              </div>
            }
            { (conversation.messages || []).map((message, index) =>
              (
                <ConversationsMessageBubble key={message.id}
                                            id={message.id}
                                            body={message.body}
                                            author={message.author}
                                            sent_at={message.sent_at}
                                            message_type={message.message_type || 'text'}
                                            created_at={message.created_at}
                                            alignment={_.get(message, 'author.current_user') ? 'right' : 'left'}
                                            index={index}
                />
              )
            )}
          </div>
        }
        { (!selected && !loading) &&
          <div className='conversation-new-message'>
            <a role="button" onClick={() => this.props.newConversation()}>
              <i className='fas fa-paper-plane fa-3x message-plane'></i>
            </a>
            <h2>Your Messages</h2>
            <h4 className='new-message-text'>Send Messages to others</h4>
            <a className='btn primary'
               type='button'
               role='button'
               onClick={() => this.props.newConversation()}
            >
              Send Message
            </a>
          </div>
        }
      </div>
    );
  }

  static mapStateToProps(state, myProps) {
    return {
      loading: state.loading,
      conversation: state.conversation,
      selected: state.selected,
      load_more_messages: state.load_more_messages,
      messages_page: state.messages_page,
      new_conversation: state.new_conversation,
      ...myProps
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      toggleLoadingMessages: () => dispatch({type: 'TOGGLE_LOADING_MESSAGES'}),
      updateMessages: data => dispatch({type: 'UPDATE_MESSAGES', value: data}),
      newConversation: () => dispatch({type: 'SET_NEW_CONVERSATION'})
    };
  };
};

ConversationsMessageWindow.displayName = 'Conversations.MessageWindow';
export default connect(
  ConversationsMessageWindow.mapStateToProps,
  ConversationsMessageWindow.mapDispatchToProps
)(ConversationsMessageWindow);
