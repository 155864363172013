import React from 'react';
import Modal from '../Modal';
import { Remote } from '../utils'
import BackgroundFade from "../BackgroundFade";
import GoogleDocExportFolder from "./GoogleDocExportFolder";
import _ from "lodash";

export default class ContentsGoogleDocExportModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      folderList: [],
      selectedFolders: [],
      selectedDrafts: [],
      submitting: false,
    };

    this.handleFolderChange = this.handleFolderChange.bind(this);
    this.searchOptions = this.searchOptions.bind(this);
    this.render = this.render.bind(this);
    this.uploadToGoogle = this.uploadToGoogle.bind(this);
    this.renderDraftCheckBoxes = this.renderDraftCheckBoxes.bind(this);
    this.toggleDraftCheck = this.toggleDraftCheck.bind(this);
  }

  handleFolderChange(id, index) {
    // Remove current selection if blank
    const folders = _.filter(
      [...this.state.selectedFolders.slice(0, index), id],
      (v) => !!v
    );
    this.setState({ selectedFolders: folders });
  }

  uploadToGoogle() {
    const toggleModal = this.props.toggleModal;
    this.setState({ submitting: true });
    Remote()
      .request({
        method: "POST",
        url: `${this.props.proxyUrl}?api_action=/google_drive/upload`,
        data: {
          file_locations: [
            this.state.selectedFolders[this.state.selectedFolders.length - 1],
          ],
          content_ids: this.state.selectedDrafts,
        },
      })
      .then((_response) => {
        toggleModal();
        this.setState({ submitting: false });
      });
  }

  searchOptions(query, callback) {
    const { folderList } = this.state;
    if (query && query.length > 0) {
      callback(
        _.filter(folderList, (value) =>
          value.name.toLowerCase().includes(query.toLowerCase())
        )
      );
    } else callback(folderList);
  }

  toggleDraftCheck(content_id) {
    var selectedValues = this.state.selectedDrafts;
    var index = selectedValues.indexOf(content_id);
    if (index !== -1) {
      selectedValues.splice(index, 1);
    } else {
      selectedValues.push(content_id);
    }
    this.setState({ selectedDrafts: selectedValues });
  }

  renderDraftCheckBoxes() {
    return (
      <div style={{ padding: "10px 0" }}>
        {this.props.draftOptions.map((draft) => (
          <div key={draft.content_id}>
            <label>
              <input
                type="checkbox"
                checked={_.includes(
                  this.state.selectedDrafts,
                  draft.content_id
                )}
                onChange={(_e) => this.toggleDraftCheck(draft.content_id)}
                style={{ display: "inline-block" }}
              />
              <span style={{ padding: "5px 5px" }}>{draft.task_name}</span>
            </label>
          </div>
        ))}
      </div>
    );
  }

  renderContent() {
    const { selectedFolders } = this.state;
    return (
      <React.Fragment>
        <h3>Which drafts do you want to export?</h3>
        {this.renderDraftCheckBoxes()}
        <h3>Where would you like to export?</h3>
        {_.times(selectedFolders.length + 1, (index) =>
          this.folderSelectOption(index, selectedFolders[index - 1])
        )}
      </React.Fragment>
    );
  }

  folderSelectOption(index, folder) {
    const sub = index > 0;
    const ph = sub
      ? "(Optional) Select a subfolder"
      : "Select the folder for the drafts to go into";
    const lb = sub ? "Subfolder" : "Folder";
    return (
      <GoogleDocExportFolder
        key={`${index}-${folder}`}
        placeholder={ph}
        parent={folder}
        callback={(id) => this.handleFolderChange(id, index)}
        proxyUrl={this.props.proxyUrl}
        label={lb}
      />
    );
  }

  render() {
    const { selectedFolders, selectedDrafts, submitting } = this.state;
    const disableExport =
      _.isEmpty(selectedFolders) || _.isEmpty(selectedDrafts) || submitting;
    const { toggleModal } = this.props;

    return (
      <React.Fragment>
        <BackgroundFade
          styleOverrides={{ opacity: "50%" }}
          zIndex={1000}
          onClick={toggleModal}
        />
        <Modal size="md">
          <div className="modal-header">
            Google Doc Export
            <i className="pull-right fas fa-times" onClick={toggleModal} />
          </div>
          <div className="modal-body">{this.renderContent()}</div>
          <div className="modal-footer text-right">
            <button
              className="btn btn-primary"
              disabled={disableExport}
              onClick={this.uploadToGoogle}
            >
              Export
              {submitting && <i className="fas fa-circle-notch fa-spin"></i>}
            </button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

ContentsGoogleDocExportModal.displayName = 'Contents.GoogleDocExportModal'
