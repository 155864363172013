class window.ShopApplications

  @apply: ->
    @payment_amount_by_tier()
    @app_submitted()
    Shoppertunities.smooth_scroll_to()

  @invited: ->
    @apply()

  @edit: ->
    @payment_amount_by_tier()
    @app_submitted()
    Shoppertunities.smooth_scroll_to()


    all_rounds = $('#round-data').data('all')
    selected_rounds = $('#round-data').data('selected')

    $('.ajax-select').select2
      multiple: true
      query: (query) ->
        data = {results: []}
        $.each all_rounds, ->
          if(query.term.length == 0 || this.text.toUpperCase().indexOf(query.term.toUpperCase()) >= 0 )
            data.results.push({id: this.id, text: this.text });

        query.callback(data)

    $('.ajax-select').select2('data', selected_rounds )

  @update: ->
    @edit()

  $(document).ready ->
    $('.js_user_bid_input').on 'keyup', ->
      prepayment = $(this).closest('.js_round_bid_input').data('prepayment')
      additional_payment = $(this).closest('.js_round_bid_input').data('additional-payment')
      $(this).closest('.js_round_card').find('.js_open_bid_total_payment').html("$#{parseInt(this.value || 0) + parseInt(prepayment) + parseInt(additional_payment)}")
      if this.value == ''
        $(this).closest('.js_round_card').find('.js_open_bid_total_payment').addClass('missing_bid').removeClass('with_bid')
      else
        $(this).closest('.js_round_card').find('.js_open_bid_total_payment').addClass('with_bid').removeClass('missing_bid')

    $('.js_user_pageview_input').on 'keyup mouseup', ->
      paymentText = "--"
      if this.value > 0
        estimate = $(this).closest('.js_user_pageview_input')
        { pvvLower, pvvUpper, lowerMin, upperMin } = estimate.data()
        minPayment = Math.max(parseInt(this.value * pvvLower), lowerMin)
        maxPayment = Math.max(parseInt(this.value * pvvUpper), upperMin)
        paymentText = "$#{minPayment} - $#{maxPayment}"
      $(this).closest('.js_round_card').find('.js_bid_payment_per_view').html(paymentText)

    $('#shop_application_user_social_platform_id').on 'change', ->
      selected_blog_id = $('#shop_application_user_social_platform_id option:selected').val()
      pageviews = $('.js_user_pageview_input').data("blog-#{selected_blog_id}")
      $('.js_user_pageview_input').val(pageviews).trigger('keyup')
      if pageviews == 0 || pageviews == undefined
        $('.js_no_recommended_bid').show()
        $('.js_recommended_bid').hide()
      else
        $('.js_no_recommended_bid').hide()
        $('.js_recommended_bid').show()

  @payment_amount_by_tier: ->
    $('#shop_application_user_social_platform_id').on 'change', ->

      blog_options  = $('#shop_application_user_social_platform_id option')
      selected_blog = blog_options.filter(':selected')
      tier_payment  = parseFloat(selected_blog[0].getAttribute('data-tier'))

      $('.js_round_payment').each ->
        if !isNaN(tier_payment) && blog_options.length > 2
          variable_pay   = this.getAttribute('data-variable')
          tiered_payment = if variable_pay == 'true' then tier_payment else 0
          round = $(this)
          round_payment = parseFloat(this.getAttribute('data-payment'))
          pre_payment   = parseFloat(this.getAttribute('data-prepayment'))
          additional_payment   = parseFloat(this.getAttribute('data-additional-payment'))
          final_payment = tiered_payment + round_payment + additional_payment
          total_payment = (pre_payment + final_payment).toFixed(0)

          round.find('.js_round_payment_details').attr('data-original-title', "Prepayment: $#{pre_payment}<br/>Final Payment: $#{final_payment.toFixed(0)}")
          round.find('.js_total_payment').css('display','none').html("$#{total_payment} ").fadeIn()

      if selected_blog.val()
        $(this).valid()

  @app_submitted: ->
    form = $('form.application-form')

    $('#submit-btn').on 'click', (e) ->
      if form.valid()
        $('#submit-btn').addClass('disabled')
        ga('send', 'event', 'ShopApplication', 'ApplicationSubmit', 'ApplicationSubmissionComplete', 1)
      else
        e.preventDefault()
        $('#error-area').show()

    $('#save-btn').on 'click', (e) ->
      $('#save-btn').addClass('disabled')
      form.validate().resetForm()
      $('#error-area').hide()
