import React from 'react';
import { connect } from 'react-redux';

class ShopApplicationsQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
  }

  updateAnswer(e) {
    const promise = () => new Promise((resolve) => {
      resolve(this.props.setQuestionAnswer(this.props.id, e.target.value));
    });

    promise().then(() => {
      this.props.canSubmitCheck();
    });
  }

  render() {
    return (
      <div className='card-plus question-card' disabled={this.props.isReadOnly}>
        <div className='card-header'>
          <p dangerouslySetInnerHTML={{__html: this.props.value}}></p>
        </div>
        <textarea value={this.props.answer ? this.props.answer : ''} onChange={e => this.updateAnswer(e)} disabled={this.props.isReadOnly}></textarea>
      </div>
    );
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      questions: state.questions,
      isReadOnly: state.isReadOnly
    };
  };

  static mapDispatchToProps(dispatch) {
    return {
     setQuestionAnswer: (questionId, answer) => dispatch({ type: 'SET_QUESTION_ANSWER', value: { questionId: questionId, answer: answer } }),
    };
  };
};

ShopApplicationsQuestion.displayName = 'ShopApplications.Question';
export default connect(
  ShopApplicationsQuestion.mapStateToProps,
  ShopApplicationsQuestion.mapDispatchToProps
)(ShopApplicationsQuestion);