import React from 'react';
import { Remote } from '../utils';

import UserSignUpLoadingSpinner from './LoadingSpinner';

export default class UserSignUpProcessingInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autoSubmit: true,
      loading: true,
    };

    this.doBind();
  }

  componentDidMount(){
    this.props.disableNavigation(true);
    const { autoSubmit } = this.state;
    if (autoSubmit){
      this.setState({autoSubmit: false});
      this.submitData();
    }

  }

  submitData(){
    this.reset();
    Remote().request({
      url: '/sign_up.json',
      method: 'POST',
      data: {
        new_user: this.props.values,
        community_id: this.props.communityId,
        referrer_url: this.props.referrerUrl
      }
    }).then((response) => {
      const { success, errors, redirect_to } = response.data;
      this.handleResponse(success, errors);
      if (!!redirect_to){
        window.localStorage.removeItem('instagram_auth')
        window.localStorage.removeItem('facebook_auth')
        window.localStorage.removeItem('youtube_auth')
        window.location.href = redirect_to;
      }
    }).catch((error) => {
      const {message, status} = error.response.data;
      this.handleResponse(false, {[status]: [ message ]});
    })
  }

  reset(){
    this.props.fieldCallback('success', '');
    this.props.fieldCallback('errors', '');
    this.setState({ loading: true });
    this.props.disableNavigation(true);
  }

  handleResponse(success, errors, ){
    this.props.fieldCallback('success', success);
    this.props.fieldCallback('errors', errors);
    this.setState({ loading: false });
    this.props.disableNavigation(success);
  }

  renderErrors(){
    const { payment_error } = this.props.header
    const { errors, success } = this.props.values;
    if ( success ) {
      return <p>{payment_error}</p>
    } else {
      return (
          <div>
            <div className="form">
              <ul className="list-group list-group-flush">
                {
                  Object.keys(errors).map((error) => {
                    return errors[error].map((message, index) => {
                      // TODO: Display the validated field and error messages under it
                      return (
                          <li key={`${error}-${index}`} className="list-group-item list-group-item-danger">
                            {error} {message}
                          </li>
                      )
                    })
                  })
                }
              </ul>
            </div>

          </div>
      )
    }
  }

  renderResubmit() {
    return (
      <div className="sign-up-footer">
        <button className='btn btn-primary' onClick={this.submitData} >
          Resubmit
        </button>
      </div>
    )
  }

  renderStatusIcon() {
    const { header, values } = this.props;
    const { success } = values;


    let iconName = !!success ? 'fa-check-circle' : 'fa-exclamation-triangle danger'

    return <UserSignUpLoadingSpinner text={success ? header.success : header.error}
                                      icon={iconName}
                                      spin={false}
                                      loading={true} />
  }

  render() {
    const { loading } = this.state;
    const { header, values } = this.props;
    const { errors, success } = values;

    if (loading)
      return (
        <div className="sign-up-finish-info">
          <UserSignUpLoadingSpinner text={header.loading} loading={loading} />
        </div>
      )

    return (
      <div className="sign-up-finish-info">
        <div className='column'>
          { this.renderStatusIcon() }
        </div>

          { !!errors && Object.keys(errors).length > 0 && (
              <div className='column'>
                {this.renderErrors()}
              </div>
          )}
        { !!errors && Object.keys(errors).length > 0 && !success && this.renderResubmit() }
      </div>
    )
  }

  doBind(){
    this.render = this.render.bind(this);
    this.submitData = this.submitData.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
  }
};