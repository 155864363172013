class window.AdminRoundTasks

  @add_new: ->
    @form()

    $('body').on 'click', '#submit-btn', ->
      values = []
      $('input[type=number]').each ->
        values.push $(this).val()
      max = Math.max.apply(Math, values)
      if max < 11
        icon = $('<i class="fas fa-spinner fa-spin"></i>')
        $(this).addClass('disabled').html(icon)

  @new: ->
    @form()

  @edit: ->
    @form()

  @update: ->
    @form()

  @create: ->
    @form()

  @form: ->
    ShopDashboard.setup()

    $('#round_task_description').redactor
      plugins: plugin_list

    new ValidateDate.DateField( '#taskbtn', ['round_task_due_date'] ).bind_values()