class window.AdminPlatforms

  @edit: ->
    @form_validation()

  @update: ->
    @form_validation()

  @form_validation: ->
    $("form.platforms").validate
      validClass: "success"

    jQuery.validator.addClassRules
      cpm_input:
        greaterThan: 0

    jQuery.validator.addMethod 'greaterThan', ((value, element) ->
      @optional(element) or value > 0
    ), ("Number must be greater than 0")

    $('form.platforms').on 'submit', (e) ->
      unless $(this).valid()
        e.preventDefault()

    jQuery.validator.addMethod 'validateTotal', ((value, element, params) ->
      sum = 0
      class_name = params[0]
      $.each $('input.' + class_name), ->
        val = parseFloat $(this).val()
        if !isNaN(val) then sum += val
      if sum == 100 then true else false
    ), jQuery.validator.format("Combined {1} sum must equal 100%")

    jQuery.validator.addMethod 'validateRange', ((value, element, params) ->
      tier = parseFloat $(".#{params[0]}-percentile").val()
      next_tier = parseFloat $(".#{params[1]}-percentile").val()
      tier < next_tier
    ), jQuery.validator.format("{0} percentile value must be less than {1} percentile value.")

    jQuery.validator.addClassRules 'sum-campaign',
      validateTotal: [
        'sum-campaign',
        'Campaign'
      ]
      number: true

    tiers = $('#platform_form').data('blog-tiers').map((tier) ->
      tier.name
    )

    for tier, i in tiers
      next_tier_index = i+1
      next_tier = tiers[next_tier_index]
      if next_tier_index < tiers.length
        jQuery.validator.addClassRules "#{tier}-percentile",
          validateRange: [
            tier,
            next_tier
          ]
          number: true

    $('#platform_form').validate(
      errorElement: 'label'
      errorPlacement: (error, element) ->
        $('.showerrors').empty()
        error.appendTo '.showerrors'
        $('.showerrors').css 'display', 'block'
    ).form()

    return