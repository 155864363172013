import React from 'react';
import { Remote } from '../utils';

var TwitterPromoInstructionsSubmit = createReactClass({


  getInitialState: function () {
    return {
      tweet_url: '',
    };
  },

  componentDidMount: function () {
    this.validateForm('#twitter_url_form');
  },

  validateForm: function (form) {
    var _this = this;
    var twitterHandle = _this.props.promotion.handle;
    $(form).validate({
      rules: {
        twitter_url: {
          required: true,
          twitter_url_check: true,
          twitter_handle_match: true,
        },
      },
    });

    jQuery.validator.addMethod('twitter_url_check', (function (value, element) {
      return this.optional(element) || /(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)\/\/?status\/([0-9_]+)/.test(value);
    }), 'Enter a valid Twitter status url');

    jQuery.validator.addMethod('twitter_handle_match', (function (value, element) {
      return this.optional(element) || value.split('/')[3].toLowerCase() === twitterHandle.toLowerCase();
    }), 'This tweet URL does not belong to the account with which you claimed the Promotion (' + twitterHandle + ').');
  },

  handleTweetUrlChange: function (e) {
    this.setState({ tweet_url: e.target.value });
  },

  submitTweet: function (e) {
    e.preventDefault();
    if ( $('#twitter_url_form').valid() ) {
      document.getElementById('submit_tweet_button').setAttribute('disabled', 'disabled');
      Remote().request({
        url: 'user_promo_posts/' + this.props.promotion.id,
        method: 'PUT',
        data: {
          tweet_url: this.state.tweet_url,
          username: this.props.promotion.handle,
        },
      })
    } else {
      e.stopPropagation();
    }
  },

  render: function () {
    var promotion = this.props.promotion;

    return (
      <div className="promo_instructions_modal--step clearfix">
        <div className="promo_instructions_modal--step_inner center-block text-center" style={{ paddingBottom: '35px' }}>
          <h4 className="promo_instructions_modal--step_heading mt-0" />
          <div className="mb-15 text-left-sm">
            Paste the Tweet URL in the input below, press submit and get paid!
          </div>
          <form onSubmit={this.submitTweet} id="twitter_url_form">
            <div className="form-group">
              <input
                className="form-control mb-5"
                placeholder={promotion.user_link + '/status/12345678'}
                name="twitter_url"
                value={this.state.tweet_url}
                onChange={this.handleTweetUrlChange}
              />
            </div>
            <button type="submit" className="btn btn-primary ml-10" id="submit_tweet_button">
              Submit Tweet URL
            </button>
          </form>
          <a className="btn btn-link btn-cancel" data-dismiss="modal" style={{ position: 'absolute', bottom: 0, left: 0 }}>
            <i className="fas fa-angle-left fa-fw" />
            cancel
          </a>
          <div className="text-center text-left-sm mt-10 mb-10">
            You have until <b>{promotion.submit_by}</b> to submit your link.
          </div>
        </div>
      </div>
    );
  },
});
