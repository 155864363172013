import React from 'react';
var ActivePromotionCard = createReactClass({

  getInitialState: function () {
    var reservedPromo = parseInt(this.props.reserved_promo, 10);
    return {
      displayInstructions: reservedPromo === this.props.posted_card.promo_post_id,
    };
  },

  showInstructionsModal: function () {
    this.setState({ displayInstructions: true });
  },

  hideInstructionsModal: function () {
    this.setState({ displayInstructions: false });
  },

  render: function () {
    var promotion = this.props.posted_card;
    var promoImageStyle = { backgroundImage: 'url(' + promotion.thumbnail_image + ')' };
    var content = promotion.content;
    var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    content = '<div>' + content.replace(urlRegex, "<a target='_blank' rel='noopener noreferrer' href='$1'>$1</a>") + '</div>';

    return (
      <div className="col-xs-12">
        <div className="promotion-wrapper clearfix">
          <div className="promo-img pull-left" style={promoImageStyle} />
          <div className="promo_card--right pull-left">
            <div className="col-sm-7 col-xs-12 mb-10">
              <div className="promo_card--header mb-10">
                <i className={'promo-social-icon fab fa-fw ' + promotion.social_icon} />
                <h4 className="mt-0">{promotion.name}</h4>
              </div>
              <div className="promo-text" dangerouslySetInnerHTML={{ __html: content }} />
            </div>
            <div className="col-sm-5 col-xs-12 pd-0">
              <div className="row">
                <div className="promo--max col-xs-6 col-sm-5 text-right">
                  <span>Payment</span>
                  <div className="promo--value mg-0">${promotion.payment}</div>
                </div>
                <div className="promo--max col-xs-6 col-sm-7 text-right text-center-sm">
                  <a className={'btn btn-primary promo--btn'} style={{ marginTop: '8px' }} onClick={this.showInstructionsModal}>
                    Submit {promotion.action_label}
                  </a>
                </div>
                <div className="promo--max col-xs-12 text-right" style={{ marginTop: '25px' }}>
                  <span>Submit by </span>
                  <p className="d-inline">{promotion.submit_by}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          this.state.displayInstructions &&
            <PromoInstructionsModal
              promotion={promotion}
              handleHideModal={this.hideInstructionsModal}
            />
        }
      </div>
    );
  },
});
