import React from 'react';
import PagePlaceholder from '../../PagePlaceholder';
import CampaignsRequirementsGeneralRequirementButton from '../requirements/general_requirements/GeneralRequirementButton';
import CampaignsRequirementsAddFromTemplatesButton from '../requirements/AddFromTemplatesButton';
import CampaignsRequirementsTaskContentRequirementButton from '../requirements/task_content_requirements/TaskContentRequirementButton';
import CampaignsRequirementsInstructionNotesButton from '../requirements/instruction_notes/InstructionNotesButton';

export default class CampaignsRequirementsInitialDisplay extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  render(){
    const { type, children } = this.props;
    return (
      <div className='initial_display'>
        <PagePlaceholder description={this.props.I18n.initial_description[type]}/>
        <div className='button_container' >
          <div className='display_buttons'>
            { type === 'general_requirement' && !children &&
              <React.Fragment>
                <CampaignsRequirementsGeneralRequirementButton {...this.props} />
                <CampaignsRequirementsAddFromTemplatesButton proxyUrl={this.props.proxyUrl}
                                                               campaign_id={this.props.campaign_id}
                                                               templateType='campaign_general'
                                                               loadingTemplates={this.props.loadingTemplates}/>
              </React.Fragment>
            }
            { type === 'task_content_requirement' && !children  &&
              <div className='add_content_task_buttons' style={{display: 'inherit'}}>
                <CampaignsRequirementsTaskContentRequirementButton {...this.props} />
                <CampaignsRequirementsAddFromTemplatesButton proxyUrl={this.props.proxyUrl}
                                                               campaign_id={this.props.campaign_id}
                                                               templateType='campaign'
                                                               loadingTemplates={this.props.loadingTemplates}/>
              </div>
            }
            {
              this.props.children
            }
          </div>
        </div>
      </div>
    );
  }
}

CampaignsRequirementsInitialDisplay.displayName = 'Campaigns.Requirements.InitialDisplay';