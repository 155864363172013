import React, { lazy, Suspense } from 'react';

const renderLoader = () => <p>Loading</p>;

export default class HeaderNotificationsContent extends React.Component {
  constructor(props) {
    super(props);
  }

  template(notification){
    const RenderingTemplate = lazy(() => import(`../notification_templates/${notification.traceable_type}`));
    return <RenderingTemplate notification={notification} />
  }


  render() {
    const {notifications} = this.props;
    if (notifications.length !== 0) {
      return(
        notifications.map((notification) => {
            return(
              <div className="js-notification notification_wrapper" key={notification.id}>
                <a className={notification.notification_style} href={`/notifications/${notification.id}`}>
                  <div className="notif_pic" style={{backgroundImage: notification.image_url}} />
                  <div className="notification_body_wrapper">
                    <div className="notification_body">
                      <Suspense fallback={renderLoader()}>
                        {this.template(notification)}
                      </Suspense>
                    </div>
                    <div className="notification_date">
                      <i className="fa" />
                      <span style={notification.notification_date}>
                        {notification.created_at}
                      </span>
                    </div>
                  </div>
                </a>
                <button className="destroy_notif_btn"
                        onClick={() => {this.props.destroyNotification(notification)}} type="button">
                  <span>&times;</span>
                </button>
              </div>
            )
          }
        )
      )
    } else {
        return (
          <div className="alert alert-info">
            You do not have any notifications.
          </div>
        )
      }
    }
}

HeaderNotificationsContent.displayName = 'Header.NotificationsContent';



