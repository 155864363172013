import React from 'react';
import { connect } from 'react-redux';
import { Remote } from '../utils';
import defaultProfileImg from '../../images/fallback/thumb_profile_pic.jpg'

class ConversationCard extends React.Component {

  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.lastParticipant = this.lastParticipant.bind(this);
    this.showConversation = this.showConversation.bind(this);
  }

  showConversation(id){
    this.props.setSelected({selected: true});
    this.props.toggleLoading();
    Remote().request({
      method: 'GET',
      url: `/conversations/${id}.json`
    }).then((response) => {
      let conversation = response.data.value.conversation;
      this.props.setConversation({conversation: conversation});
      const messagesCount = conversation.messages.length;
      setTimeout(() => {
        const node = document.querySelector(`#message-${conversation.messages[messagesCount -1].id}`)
        if(!!node){
          node.scrollIntoView();
        }
      }, 300 )}).catch((error) => {
        this.props.toggleLoading();
      });
  }

  lastParticipant(conversation){
    const participants = Object.values(conversation.participants).filter(participant => participant.current_user == false);
    if (participants.length == 0){
      const deleted_participant = {'avatar_url': defaultProfileImg, 'full_name': 'Deleted User'};
      return deleted_participant;
    } else if(participants.length == 1){
      return participants[0];
    } else if(participants.length > 1) {
      conversation.messages.map((message) => {
        if (!!message.author && message.author.current_user == false) {
          return conversation.messages[0].author
        }
      });
      return participants[0];
    }
  }

  render(){
    const {conversation} = this.props;
    const participant_count = Object.values(conversation.participants).length;
    const last_participant = this.lastParticipant(conversation);
    const messages_count = conversation.messages.length;
    return (
      <div className="conversation-card" key={conversation.id} onClick={() => (this.showConversation(conversation.id))}>
        <div className="conversation-avatar" style={{backgroundImage: `url(${last_participant.avatar_url})`}} />
        <div className="conversation-info">
          <div className='sender-names'>
            <h4 className='sender-name'>{last_participant.full_name}</h4>
            <h4 className='sender-count'>{ participant_count > 2 && ` +${participant_count - 2}` }</h4>
          </div>
          <span className='pull-right updated-at-text'>
              {conversation.relative_time}
            </span>
          <span className='conversation-summary-text'>
            {conversation.messages[messages_count-1].body}
          </span>
        </div>
      </div>
    )
  }

  static mapStateToProps(state, myProps) {
    return {
      conversations: state.conversations,
      loading: state.loading,
      conversation: state.conversation,
      selected: state.selected,
      ...myProps
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      toggleLoading: () => dispatch({type: 'TOGGLE_LOADING'}),
      setSelected: data => dispatch({type: 'SET_SELECTED', value: data}),
      setConversation: data => dispatch({type: 'SET_CONVERSATION', value: data})
    };
  }
}

ConversationCard.displayName = 'Conversations.ConversationCard';

export default connect(
  ConversationCard.mapStateToProps,
  ConversationCard.mapDispatchToProps
)(ConversationCard);