import { createStore } from 'redux';

const initialState = {
  loading: true,
  view: undefined,
  views: [],
  alert:{
    hide: true
  }
};

const reducer = (state = initialState, action) => {
  const { type, value } = action;
  switch(type) {
    case 'UPDATE':
      return {
        ...state,
        ...value,
        views: _.get(value, 'navigation.order')
      };
    case 'UPDATE_VIEW':
      let viewData = { }
      _.map(state.views, (view) => {
        viewData[view] = {
          ...state[view]
        }
      });

      return {
        ...state,
        ...value.data,
        ...viewData,
        [value.view]: {
          ...state[value.view],
          ...value.data[value.view],
          fetching: false
        },
        loading: false
      };
    case 'FETCHING_VIEW':
      return {
        ...state,
        [value.view]: {
          ...state[value.view],
          fetching: value.bool
        }
      };
    case 'UPDATE_FIELD':
      return {
        ...state,
        [value.field]: value.data
      };
    case 'SET_CURRENT_VIEW':
      if (value && value.route)
        window.history.pushState({}, document.title, value.route);
      return {
        ...state,
        view: value.view || initialState.view,
        loading: !!value.view
      };
    case 'HANDLE_ERROR':
      return {
        ...state,
        alert: {
          message: value || _.get(state['i18n'], 'ajax_failure'),
          hide: false
        },
        [state.view]: {
          fetching: false
        },
        loading: false
      };
    case 'CLEAR_ERROR':
      return {
        ...state,
        alert: {
          ...state['alert'],
          hide: true
        }
      };
    case 'UPDATE_EARNING_SORT':
      return {
        ...state,
        earnings: {
          ...state.earnings,
          sort: {
            ..._.get(state.earnings, 'sort', {}),
            ...action.value
          }
        }
      };
    case 'ADD_PAYMENTS':
      return {
        ...state,
        earnings: {
          ...state.earnings,
          payments: [
            ...state.earnings.payments,
            ...action.value
          ]
        }
      };
      case 'UPDATE_CONTENTS_SORT':
        return {
          ...state,
          content: {
            ...state.content,
            sort: {
              ..._.get(state.content, 'sort', {}),
              ...action.value
            }
          }
        };
      case 'ADD_CONTENT':
        return {
          ...state,
          content: {
            ...state.content,
            content: [
              ...state.content.content,
              ...action.value
            ]
          }
        };
      case 'UPDATE_SORT':
        return {
          ...state,
          [value.view]: {
            ...state[value.view],
            [value.list]: {
              ..._.get(state[value.view], value.list, {}),
              sort: {
                ..._.get(state[value.view], `${value.list}.sort`, {}),
                ...value.data
              }
            }

          }
        };
      case 'ADD_TO_LIST':
        const listKey = `${value.section}.${value.list}`;
        const newValues = _.get(value.data,listKey, []);
        console.log()
        return {
          ...state,
          [value.view]: {
            ...state[value.view],
            [value.section]: {
              ..._.get(state[value.view], value.section, {}),
              [value.list]: [
                ...(_.get(state[value.view], listKey, [])),
                ...newValues
              ],
              sort: {
                ..._.get(state[value.view], `${value.section}.sort`, {}),
                complete: newValues.length == 0
              }
            }
          }
        };
    default:
      return state;
  };
};

const devTools = process.env.RAILS_ENV != 'production' && window && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__({ name: 'Redux.InfluencerDashboardStore' }) : f => f

const store = createStore(
  reducer,
  devTools
);
export default store;
