import React from 'react';
import { Provider } from "react-redux"
import ConversationsStore from '../redux/ConversationsStore';
import ConversationsContainer from './ConversationsContainer'

export default class ConversationsIndexWrapper extends React.Component {
  render() {
    return (
      <Provider store={ConversationsStore}>
        <ConversationsContainer initialProps={this.props} />
      </Provider>
    );
  }
};

ConversationsIndexWrapper.displayName = 'Conversations.IndexWrapper';