import React from 'react';

export default class LivingStyleGuideTypography extends React.Component {

  render() {
    return (
      <div id='typography' className='style-guide-section'>
        <h1 className='section-header'>Typography</h1>
        <div className='row'>
          <div className='sub-section column'>
            <h3 className='sub-section-header'>Typeface</h3>
            <hr/>
            <p className='text-size-xxx-large'>
              <span className='text-weight-light'>Lato </span>
              <span className='text-weight-black'>Aa</span>
            </p>
            <p>
              A B C D E F G H I J K L M N O P Q R S T U V W X Y Z
              a b c d e f g h i j k l m n o p q r s t u v w x y z
              0 1 2 3 4 5 6 7 8 9 ( . , ! @ $ % & )
            </p>
          </div>
          <div className='sub-section column'>
            <h3 className='sub-section-header'>Weights</h3>
            <hr/>
            <p>
              We use
              <span className='text-weight-light'> "Light"</span>,
              <span className='text-weight-regular'> "Regular"</span>,
              <span className='text-weight-bold'> "Bold"</span> and
              <span className='text-weight-black'> "Black"</span>
            </p>
          </div>
          <div className='sub-section column text-color'>
            <h3 className='sub-section-header'>Text Color</h3>
            <hr/>
            <p>Standard text color</p>
            <p className='text-color-subdued'>Subdued text color</p>
            <p className='text-weight-bold text-color-blue'>Title text color</p>
            <p className='text-color-red'>Danger text color</p>
            <p className='text-color-white background-grad-blue-purple'>White text color</p>
            <a href='#'>Link text color</a>
          </div>
        </div>
        <div className='section-description text-sizing'>
          <h2 className='sub-header'>Text Sizing</h2>
          <p className='text-size-x-small'>
            Small Jinxed wizards pluck ivy from the big quilt.
            <span> ( .75em / 12px )</span>
          </p>
          <p className='text-size-small'>
            Waltz, bad nymph, for quick jigs vex.
            <span> ( .8em / 14px )</span>
          </p>
          <p className='text-size-regular'>
            Pack my box with five dozen liquor jugs.
            <span> ( 1em / 16px )</span>
          </p>
          <p className='text-size-large'>
            How vexingly quick daft zebras jump!
            <span> ( 1.125em / 18px )</span>
          </p>
          <p className='text-size-x-large'>
            Two driven jocks help fax my big quiz.
            <span> ( 1.25em / 20px )</span>
          </p>
          <p className='text-size-xx-large'>
            Pack my box with five dozen liquor jugs.
            <span> ( 1.5em / 25px )</span>
          </p>
          <p className='text-size-xxx-large'>
            Sphinx of black quartz, judge my vow.
            <span> ( 1.6em / 28px )</span>
          </p>
        </div>
        <div className='section-description icons'>
          <h2 className='sub-header'>Icons</h2>
          <p>
            When we need to include icons or symbols into Social Fabric, we utilize the
            <a href='https://fontawesome.com/v4.7.0/icons/'> FontAwesome </a>
            library.
          </p>
          <i className='fas fa-exclamation-triangle' />
          <i className='fas fa-question-circle fa-2x' />
          <i className="fas fa-circle-notch fa-spin" />
          <i className='fas fa-plus text-color-green' />
          <div className='code-example' dangerouslySetInnerHTML={{__html:
            `<script type="text/plain" class="language-jsx">
              <div className='icon-group'>
                <i className='fas fa-exclamation-triangle'/>
                <i className='fas fa-question-circle fa-2x'/>
                <i className="fas fa-circle-notch fa-spin" />
                <i className='fas fa-plus text-color-green'/>
              </div>
            <\/script>`
          }} />
        </div>
        <div className='section-description hierarchy'>
          <h2 className='sub-header'>Emphasis & Hierarchy</h2>
          <p>
            To communicate content hierarchy, use the core typographic scale first. Once the main hierarchy is
            established, if two pieces of text of the same style have different positions in the content hierarchy, the Subdued style may be used to de-emphasize one of them. Subdued content isn’t unimportant or superfluous, but rather makes room for the eye to orient itself and focus on core content.
          </p>
        </div>
        <div className='hierarchy-example'>
          <div className='card'>
            <div className='hierarchy-row'>
              <h1 className='text-size-x-large'>Opportunity Title</h1>
              <span className="divider" />
              <p className='text-color-blue text-size-small'>
                <span className='hierarchy-row-color background-color-gray'/>
                #3D425D, 20px, Lato-Black
              </p>
            </div>
            <div className='hierarchy-row'>
              <h2 className='text-color-subdued text-size-regular'>Opportunity Subtitle</h2>
              <span className="divider" />
              <p className='text-color-blue text-size-small'>
                <span className='hierarchy-row-color background-color-subdued'/>
                #8D8EA5, 16px, Lato-Regular
              </p>
            </div>
            <div className='hierarchy-row'>
              <p> Some description text to build on <br/> the opportunity title...</p>
              <span className="divider" />
              <p className='text-color-blue text-size-small'>
                <span className='hierarchy-row-color background-color-gray'/>
                #3D425D, 16px, Lato-Regular
              </p>
            </div>
            <br/>
            <div className='hierarchy-row'>
              <span className='text-color-subdued text-size-small'>Due: 12/31/2020</span>
              <span className="divider" />
              <p className='text-color-blue text-size-small'>
                <span className='hierarchy-row-color background-color-subdued'/>
                #8D8EA5, 14px, Lato-Regular
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
LivingStyleGuideTypography.displayName = 'LivingStyleGuide.Typography';