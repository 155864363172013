import React from 'react';
var PostedPromotionCard = createReactClass({

    render: function () {

      var promotion = this.props.posted_card;
      var media = promotion.media;
      var promoImageStyle = { backgroundImage: 'url(' + promotion.thumbnail_image + ')' };
      var content = promotion.content;
      var promo_payment = (promotion.status == 'failed' ? '0.00' : promotion.payment);

      var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
      content = "<div>" + content.replace(urlRegex, "<a target='_blank' href='$1'>$1</a>") + "</div>";

      var promo_status = (function(status) {
                          switch(status) {
                            case "pending":
                              return <strong style={{color: 'orange'}}>In Review</strong>;
                            case "passed":
                              return <strong className="cb-green">Accepted</strong>;
                            case "failed":
                              return <strong className="text-red">Rejected</strong>;
                          }
                        })(promotion.status);

      return (
        <div className="col-xs-12">
          <div className="container promotion-wrapper">
            <div className="promo-img pull-left" style={promoImageStyle}></div>
            <div className="promo_card--right pull-left">
              <div className="col-sm-7 col-xs-12 mb-10">
                <div className="promo_card--header mb-10">
                  <i className={'promo-social-icon fab fa-fw ' + promotion.social_icon} />
                  <h4 className="mt-0">{promotion.name}</h4>
                </div>
                <p className="promo-text" dangerouslySetInnerHTML={{__html: content }} />
              </div>
              <div className="col-sm-5 col-xs-12 pd-0">
                <div className="row">
                  <div className="promo--max col-xs-7 text-center">
                    <span>Date Posted</span>
                    <p>{promotion.date_posted}</p>
                  </div>
                  <div className="promo--max col-xs-5 text-center">
                    <span>Earned</span>
                    <div className="promo--value">${promo_payment}</div>
                  </div>
                  <div className="promo--max col-xs-12 text-right mt-10">
                    <a target="_blank" href={promotion.posted_url} className={'btn btn-primary promo--btn mt-0'}>
                      View {promotion.action_label}</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-7 col-xs-12">
              Status: {promo_status}
            </div>
          </div>
        </div>
      );
    }
});
