import React from 'react';
import { Remote } from '../utils';

import AdImage from './AdImage'
import LoadingSpinner from './LoadingSpinner'
import ImageUploader from './ImageUploader'
import Cropper from './Cropper'

export default class ImageSelection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [],
      editingImage: null,
      selectedImages: this.props.wizardState && this.props.wizardState.images || [],
      loading: false,
      netWorkRequest: null
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.createSelectionListener = this.createSelectionListener.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.reloadImages = this.reloadImages.bind(this);
    this.render = this.render.bind(this);
    this.renderImageList = this.renderImageList.bind(this);
    this.saveImages = this.saveImages.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.addNewUrl = this.addNewUrl.bind(this);
    this.editClicked = this.editClicked.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
  }

  render() {
    if(this.state.loading) {
      return <LoadingSpinner loading={true} />;
    }

    const disabled = this.state.selectedImages.length < 1;
    const btnClass = `btn btn-primary${disabled ? ' disabled' : ''}`;
    return (
      <div className='modal-inner-content'>
        <div className='ad-images'>
          <div className='image-selection section left'>
            <div className='section-header'>
              <label>Ad Images</label>
            </div>
            <div className='image-list section-body overflow-scroll'>
              {this.renderImageList()}
            </div>
            <ImageUploader
              wizardStateId={this.props.wizardState.id}
              addNewUrl={this.addNewUrl}
              uploadEndpoint={this.props.uploadEndpoint}
              />
          </div>
          <Cropper
            imageUrl={this.state.editingImage}
            unsetImage={this.cancelEdit}
            uploadBlob={this.uploadBlob}
            uploadEndpoint={this.props.uploadEndpoint}
            wizardStateId={this.props.wizardState.id}
            addNewUrl={this.addNewUrl}
            />
        </div>
        <div className='modal-next'>
        <button className={btnClass} disabled={disabled} onClick={this.saveImages}>
            Continue <i className='fas fa-chevron-right' />
          </button>
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    this.state.netWorkRequest.abort();
  }

  componentDidMount() {
    this.reloadImages();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.wizardState.id === nextProps.wizardState.id) {
      return;
    }

    this.setState({
      selectedImages: nextProps.wizardState.images
    }, this.reloadImages);
  }

  renderImageList() {
    const images = this.state.images

    return images.map((image, i) => {
      const listener = this.createSelectionListener(image);
      return (
        <AdImage
          isSelected={this.isSelected(image)}
          image={image}
          onClick={listener}
          onEditClicked={this.editClicked}
          key={i}
        />
      );
    });
  }

  createSelectionListener(image) {
    return () => {
      if(this.isSelected(image)) {
        this.setState({ selectedImages: this.state.selectedImages.filter((x) => x !== image) })
      } else {
        this.setState({ selectedImages: this.state.selectedImages.concat(image) });
      }
    };
  }

  isSelected(image) {
    return this.state.selectedImages.some((x) => x === image);
  }

  reloadImages() {
    const xhr = $.get(this.props.reloadEndpoint)
                 .done((data) => this.setState({ images: data, loading: false }));

    this.setState({ netWorkRequest: xhr });
  }

  saveImages() {
    Remote().request({
      url: this.props.saveEndpoint,
      method: 'POST',
      data: {
        selected_images: this.state.selectedImages
      }
    }).then(this.props.proceed);
  }

  addNewUrl(url) {
    this.setState({
      selectedImages: [url].concat(this.state.selectedImages),
      images: [url].concat(this.state.images)
    });
  }

  editClicked(image) {
    this.setState({ editingImage: image });
  }

  cancelEdit(unselect) {
    const newState = {
      editingImage: null
    };

    if(unselect) {
      newState.selectedImages = this.state.selectedImages.filter((x) => x != this.state.editingImage),
      newState.images = this.state.images.filter((x) => x != this.state.editingImage)
    }

    this.setState(newState);
  }

};
