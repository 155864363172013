import React from 'react';
import RoundTasksTextArea from './inputs/TextArea'
import RoundTasksSelect from './inputs/Select'
import RoundsValidation from '../rounds/Validation'
import RoundTasksNumber from './inputs/Number'
import RoundTasksDelete from './inputs/Delete'

export default class RoundTasksRequirementsRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dragging: false,
      dragPosition: null
    };

    this.verificationTypeHandler = this.verificationTypeHandler.bind(this);
    this.renderGrip = this.renderGrip.bind(this);
    this.render = this.render.bind(this);
  }

  verificationTypeHandler(field, value, index) {
    this.props.updateRequirement(field, value, index);

    if(this.isManual(value)) {
      this.props.updateRequirement('verification_text', null, index);
      this.props.updateRequirement('repeat_type', [], index);
      this.props.updateRequirement('repeat_count', null, index);
    }
  }

  isManual(verification_type) {
    return verification_type && verification_type.length && verification_type[0].name.toLowerCase() === 'manual';
  }

  dragStart(e) {
    const row = e.target.offsetParent;
    this.setState({ dragging: true });
    this.props.onDragStart(this.props.index);
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', row);
    e.dataTransfer.setDragImage(row, 15, 20);
  }

  dragOver(e) {
    e.preventDefault();
    const bounding = e.target.getBoundingClientRect();
    const offset = bounding.y + (bounding.height/2);
    const position = (e.clientY - offset > 0) ? 'above' : 'below';

    if (position !== !this.state.dragPosition) {
      this.setState({ dragPosition: position });
    }

    switch(this.props.draggedOver) {
      case true:
        e.stopPropagation();
        if (position === 'above')
          this.props.onDragOver(this.props.index - 1)
        else if (position === 'below')
          this.props.onDragOver(this.props.index + 1)
        break;
      case false:
        this.props.onDragOver(this.props.index)
        break;
    }
  }

  dragEnd(e) {
    e.preventDefault();
    this.setState({ dragging: false })
    this.props.onDragEnd();
  }

  renderGrip() {
    return (
      <div className='requirements_row_grip'
        draggable
        onDragStart={(e) => this.dragStart(e)}
        onDragEnd={(e) => this.dragEnd(e)} >
        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="grip-vertical" className="svg-inline--fa fa-grip-vertical fa-w-10" role="img" viewBox="0 0 320 512"><path fill="currentColor" d="M96 32H32C14.33 32 0 46.33 0 64v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160H32c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zM288 32h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32zm0 160h-64c-17.67 0-32 14.33-32 32v64c0 17.67 14.33 32 32 32h64c17.67 0 32-14.33 32-32v-64c0-17.67-14.33-32-32-32z"/></svg>
      </div>
    );
  }

  render() {
    const { dragging, dragPosition } = this.state;
    const { palette, draggedOver, dragOverIndex } = this.props;
    const req = this.props.requirement;
    const isManual = this.isManual(req.verification_type);
    const verificationPlaceholder = !isManual ? 'Enter Exact Word or Phrase' : '';
    const draggingClass = dragging ? 'dragging' : '';
    const draggedOverClass = draggedOver ? 'dragged-over' : '';
    const hideDraggingClass = dragging && dragOverIndex !== null ? 'hide-dragging' : ''
    const paletteColor = req.based_on && _.get(palette, `[${req.based_on.id}].color`, null);
    const borderClass = paletteColor ? `keyed-color ${paletteColor}` : '';

    return (
      <React.Fragment>
        { (draggedOver && dragPosition === 'above') && <div className='placeholder_row'></div> }
        <div className={`requirements_row ${draggingClass} ${draggedOverClass} ${hideDraggingClass} ${borderClass}`}
          onDragOver={(e) => this.dragOver(e)}>
          {this.renderGrip()}
          <div className='requirements_field col-sm-3'>
            <RoundsValidation
              field='description'
              validations={req.validations}
              elementType='requirement-input'
            />
            <RoundTasksTextArea
              value={req.description || ''}
              name='description'
              required={false}
              placeholder='Enter Description'
              disabled={this.props.instructionApproved || false}
              index={this.props.index}
              updateField={this.props.updateRequirement}
              error={req.validations.description}
              />
          </div>
          <div className='requirements_field col-sm-2'>
            <RoundsValidation
              field='verification_type'
              validations={req.validations}
              elementType='requirement-input'
            />
            <RoundTasksSelect
              value={req.verification_type || ''}
              name='verification_type'
              required={false}
              placeholder='Select Verification'
              disabled={false}
              index={this.props.index}
              updateField={this.verificationTypeHandler}
              options={this.props.roundTask.verification_types}
              error={req.validations.verification_type}
              />
          </div>
          <div className='requirements_field col-sm-3'>
            <RoundsValidation
              field='verification_text'
              validations={req.validations}
              elementType='requirement-input'
            />
            <RoundTasksTextArea
              value={req.verification_text || ''}
              name='verification_text'
              required={false}
              placeholder={verificationPlaceholder}
              disabled={isManual}
              index={this.props.index}
              updateField={this.props.updateRequirement}
              error={req.validations.verification_text}
              />
          </div>
          <div className='requirements_field col-sm-2'>
            <RoundsValidation
              field='repeat_type'
              validations={req.validations}
              elementType='requirement-input'
            />
            <RoundTasksSelect
              value={req.repeat_type || ''}
              name='repeat_type'
              required={false}
              placeholder='Select Frequency'
              disabled={isManual}
              index={this.props.index}
              updateField={this.props.updateRequirement}
              options={this.props.roundTask.repeat_types}
              error={req.validations.repeat_type}
              />
          </div>
          <div className='requirements_field col-sm-1'>
            <RoundsValidation
              field='repeat_count'
              validations={req.validations}
              elementType='requirement-input'
              required={true}
            />
            <RoundTasksNumber
              value={(req.repeat_count === null && !isManual) ? '0' : req.repeat_count}
              name='repeat_count'
              required={false}
              disabled={isManual}
              index={this.props.index}
              updateField={this.props.updateRequirement}
              error={req.validations.repeat_count}
              />
          </div>
          <div className='requirements_row_actions'>
            <RoundTasksDelete
              requirement={req}
              index={this.props.index}
              delete={this.props.deleteRequirement}
              deleteById={this.props.deleteRequirementById}
              />
          </div>
        </div>
        { (draggedOver && dragPosition === 'below') && <div className='placeholder_row'></div> }
      </React.Fragment>
    );
  }
}

RoundTasksRequirementsRow.displayName = 'RoundTask.RequirementsRow';
