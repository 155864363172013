import React from 'react';
class ContentGrading extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return(
      <div className="content_grading">
          <h3>Grading</h3>
          <hr/>
            {
              this.props.acceptanceRules.map((rule) => {
                var currentRule = ContentGrading.rulesOrder[this.props.rulesAttributes[rule.id] -1];
                return (
                  <div className="content_grading-dropdown row" key={rule['id']}>
                    <div className="col-md-1" />
                    <div className="col-md-2 content_grading-dropdown-title" >
                      <h4 className="align-middle">{rule['section']}</h4>
                    </div>
                    <div className="content_grading-dropdown-select col-md-8" >
                      <button className="btn btn-default dropdown-toggle"
                              type="button"
                              id="content_grading-dropdown-menu"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false">
                        {currentRule} - {rule[currentRule]}
                        <i className="fas fa-caret-down" />
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="content_grading-dropdown-menu">
                        <li className={`dropdown-item ${currentRule === 'below' ? 'active' : ''}`}
                            onClick={() => this.props.updateAcceptanceRules(rule['id'], 'below')}>
                          Below - {rule['below']}
                        </li>
                        <li className={`dropdown-item ${currentRule === 'meets' ? 'active' : ''}`}
                            onClick={() =>this.props.updateAcceptanceRules(rule['id'], 'meets')}>
                          Meets - {rule['meets']}
                        </li>
                        <li className={`dropdown-item ${currentRule === 'exceeds' ? 'active' : ''}`}
                            onClick={() => this.props.updateAcceptanceRules(rule['id'], 'exceeds')}>
                          Exceeds - {rule['exceeds']}
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-1" />
                  </div>
                )
              })
            }
        </div>
    );
  }
}

ContentGrading.rulesOrder = ['below', 'meets', 'exceeds'];