import React from 'react';

const SkeletonCard = (props) => {
  return <div className='card clickable skeleton skeleton-card'>
    <div className='top-row'>
      <div className='left'>
        <div className='big-title' />
        <div className='little-title' />
      </div>
      <div className='right'>
        <div className='big-metrics' />
      </div>
    </div>

    <div className='bottom-row'>
      <div className='little-text' />
      <div className='little-text' />
      <div className='fake-badge' />
    </div>
  </div>;
};

export default SkeletonCard;

SkeletonCard.displayName = 'Skeleton.Card';
