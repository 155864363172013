import React from 'react';
import DynamicForm from './DynamicForm';


export default class PaymentsTaxSetup extends DynamicForm {
  getFormFields() {
    return [
      {
        displayName: 'Tax ID Number',
        fieldName: 'taxId',
        regex: /^(\d\d\d-\d\d-\d\d\d\d|\d\d-\d\d\d\d\d\d\d)$/,
        regexMessage: 'Tax ID must be in the form XXX-XX-XXXX or XX-XXXXXXX',
        required: true
      },
      {
        displayName: '1099 Name',
        fieldName: 'name1099',
        placeholder: 'Legal Name Associated With SSN or EIN',
        required: true
      },
      {
        displayName: 'Federal Tax Classification',
        fieldName: 'taxClassification',
        required: true,
        dropdown: [
          { name: 'Individual/sole proprieter or single-member LLC', value: 'Individual/sole proprieter or single-member LLC' },
          { name: 'C Corporation', value: 'C Corporation' },
          { name: 'S Corporation', value: 'S Corporation' },
          { name: 'Partnership', value: 'Partnership' },
          { name: 'Limited liability company and C corporation', value: 'Limited liability company and C corporation' },
          { name: 'Limited liability company and S corporation', value: 'Limited liability company and S corporation' },
          { name: 'Limited liability company and partnership', value: 'Limited liability company and partnership' }
        ]
      },
      {
        displayName: 'Send automatic payment notification?',
        fieldName: 'autoPayment',
        checkBox: true
      }
    ];
  }
}
