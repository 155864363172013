import React from 'react';
import { connect } from 'react-redux';
import { Remote } from '../../../utils';

import LoadingSpinner from '../../../LoadingSpinner'
import GeneralRequirementsForm from '../Form'
import RoundTasksRequirements from '../../../round_tasks/Requirements'
import RoundTasksFormActions from '../../../round_tasks/FormActions'

class GeneralRequirementsEdit extends React.Component {
  constructor(props) {
    super(props);

    this.addRequirement = this.addRequirement.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.deleteRequirement = this.deleteRequirement.bind(this);
    this.deleteRequirementById = this.deleteRequirementById.bind(this);
    this.loadCommunities = this.loadCommunities.bind(this);
    this.loadTask = this.loadTask.bind(this);
    this.render = this.render.bind(this);
    this.submit = this.submit.bind(this);
    this.updateFieldValue = this.updateFieldValue.bind(this);
  }

  componentDidMount() {
    this.props.updateMeta({
      taskId: this.props.taskId,
      baseUrl: this.props.baseUrl,
      proxyUrl: this.props.proxyUrl,
      currentUserId: this.props.currentUserId
    });
    this.props.setEditingType('community_general');
    this.loadTask();
    this.loadCommunities();
  }

  loadTask() {
    this.props.startLoading();
    const params = $.param({ api_action: `/round_tasks/${this.props.taskId}` });

    Remote().request({
        url: this.props.proxyUrl + '?' + params,
        method: 'GET',
    }).then(response => {
      this.props.setTask(response.data);
      this.props.stopLoading();
    });
  }

  loadCommunities() {
    this.props.startLoading();
    const params = $.param({ api_action: `/users/${this.props.currentUserId}/communities` });

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'GET',
    }).then(response => {
      this.props.setCommunities(response.data);
      this.props.stopLoading();
    });
  }

  updateFieldValue(field, value, index=null) {
    if (index !== null) {
      this.props.updateRequirement(field, value, index)
    } else {
      this.props.updateField(field, value)
    }
  }

  addRequirement(req) {
    return Promise.resolve(this.props.addRequirement(req));
  }

  deleteRequirementById(id) {
    const index = _.findIndex(this.props.roundTask.round_task_requirements, x => x.id === id);
    const params = $.param({ api_action: `/round_task_requirements/${id}`});

    Remote().request({
      method: 'DELETE',
      url: this.props.proxyUrl + '?' + params,
    }).then((response) => {
      if (response.data) {
        this.props.removeRequirement(index);
      }
    });
  }

  deleteRequirement(index) {
    if (this.props.roundTask.round_task_requirements[index].id === null) {
      this.props.removeRequirement(index);
    } else {
      const requirementId = this.props.roundTask.round_task_requirements[index].id;
      const params = $.param({ api_action: `/round_task_requirements/${requirementId}`});

      Remote().request({
        method: 'DELETE',
        url: this.props.proxyUrl + '?' + params,
      }).then((response) => {
        if (response.data) {
          this.props.removeRequirement(index);
        }
      })
    }
  }

  submit(data) {
    const params = $.param({ api_action: `/round_tasks/${this.props.taskId}` });
    Remote().request({
      method: 'PATCH',
      url: this.props.proxyUrl + '?' + params,
      data: data,
    }).then(() => {
      window.location.href = this.props.baseUrl;
    }).catch((error) => {
      this.props.stopSubmitting();
      const json = JSON.parse(error.responseText);
      this.props.updateField('validations', json.validations);
    });
  }

  render() {
    const cardStyle = {'position': 'relative'};

    return (
      <div>
        <div className='full-width-page-title--wrapper'>
          <h1 className='full-width-page-title--heading text-center'>Edit Community General Template</h1>
        </div>
        <div className='container main-container'>
          <div className='card-wrapper--white-bg' style={cardStyle}>
            <GeneralRequirementsForm {...this.props} updateField={this.updateFieldValue}/>
            <RoundTasksRequirements
              {...this.props}
              isOpen={true}
              requirements={this.props.roundTask.round_task_requirements || []}
              addRequirement={this.addRequirement}
              deleteRequirement={this.deleteRequirement}
              deleteRequirementById={this.deleteRequirementById}
              updateField={this.updateFieldValue}
              updateRequirementSort={this.props.updateRequirementSort}/>
            <RoundTasksFormActions {...this.props} submit={this.submit}/>
            {this.props.error && <div className='text-center text-danger'>{this.props.errorText}</div>}
            {this.props.loading && <LoadingSpinner />}
          </div>
        </div>
      </div>
    );
  }
  static mapStateToProps(state, myProps) {
    return {
      ...state,
      ...myProps,
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      setTask: data => dispatch({ type: 'SET_TASK', value: data }),
      setCommunities: data => dispatch({ type: 'SET_COMMUNITIES', value: data }),
      updateMeta: data => dispatch({ type: 'UPDATE_META', value: data }),
      startLoading: () => dispatch({ type: 'START_LOADING' }),
      stopLoading: () => dispatch({ type: 'STOP_LOADING' }),
      startSubmitting: () => dispatch({ type: 'START_SUBMITTING'}),
      stopSubmitting: () => dispatch({ type: 'STOP_SUBMITTING'}),
      updateField: (field, value) => dispatch({ type: 'UPDATE_FIELD', value: {field: field, value: value } }),
      addRequirement: requirement => dispatch({type: 'ADD_REQUIREMENT', value: requirement }),
      removeRequirement: index => dispatch({type: 'REMOVE_REQUIREMENT', value: index }),
      setEditingType: type => dispatch({type: 'SET_EDITING_TYPE', value: type }),
      updateRequirement: (field, value, index) => dispatch({type: 'UPDATE_REQUIREMENT', value: {field: field, value: value, index: index} }),
      updateRequirementSort: (from, to) => dispatch({
        type: 'UPDATE_REQUIREMENT_SORT',
        value: {
          from: from,
          to: to
        }
      })
    };
  }
};

GeneralRequirementsEdit.displayName = 'GeneralRequirements.Edit';

export default connect(
  GeneralRequirementsEdit.mapStateToProps,
  GeneralRequirementsEdit.mapDispatchToProps
)(GeneralRequirementsEdit);
