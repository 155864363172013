import React from 'react';
import RichTextEditor from '../RichTextEditor'

class InstructionsCampaignRequirement extends React.Component {
  render() {
    const {
      requirement,
      deleteRequirement,
      updateRequirement
    } = this.props;

    return <div className='card-plus instructions--campaign-requirement'>
      <div className='card-header form-lr'>
        { 
          requirement.readonly 
          ? <span>{requirement.label || ''}</span> 
          : <div className='edit_requirement_card_label'><input value={requirement.label || ''} onChange={(e) => updateRequirement({ label: e.target.value }) }/></div>
        }
        {
          !requirement.readonly && 
          <div className='edit_requirement_card_icons'>
            <i className='fas fa-trash' onClick={deleteRequirement}/>
          </div> 
        }
      </div> 

      {
        requirement.readonly
        ? <div className='pl-10 pr-10' dangerouslySetInnerHTML={{__html: requirement.description}} />
        : <RichTextEditor value={requirement.description || ''} onUpdate={html => updateRequirement({ description: html }) }/>
      }
    </div>;
  }
};

InstructionsCampaignRequirement.displayName = 'Instructions.CampaignRequirement';
export default InstructionsCampaignRequirement