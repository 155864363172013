import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types'

import FacebookAdStore from '../redux/FacebookAds'
import CurrentWavePage from './CurrentWavePage'

// Simply acts as redux context provider for the whole page
export default class CurrentWavePageWrapper extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Provider store={FacebookAdStore}>
        <CurrentWavePage {...this.props}/>
      </Provider>
    );
  }
};

CurrentWavePageWrapper.propTypes = {
  datatable_url: PropTypes.string.isRequired,
  campaign_id: PropTypes.number.isRequired,
  shoppertunities: PropTypes.any.isRequired,
  rounds: PropTypes.any.isRequired
};
