import React from 'react';

export default class PaymentsProgressBar extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.renderStep = this.renderStep.bind(this);
    this.renderBar = this.renderBar.bind(this);
    this.renderText = this.renderText.bind(this);
    this.getIconStyle = this.getIconStyle.bind(this);
  }

  renderStep(stepName, index) {
    const passed = this.props.step > index;
    const isActive = this.props.step == index;
    const isFirst = index == 0;
    const isLast = index == this.props.steps.length - 1;

    const boxStyle = {
      display: 'flex',
      marginLeft: isFirst ? '0px' : '-36px'
    };
    const leftStyle = {
      width: '60px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'column'
    };
    const checkStyle = {
      color: 'white'
    };

    return (
      <div key={index} style={boxStyle}>
        <div style={leftStyle}>
          <div style={this.getIconStyle(index)}>
            {passed && <i style={checkStyle} className='fas fa-check' />}
          </div>
          {this.renderText(index, stepName)}
        </div>
        {!isLast && this.renderBar(index)}
      </div>
    );
  }

  renderBar(index) {
    // The bar will be grey until we're past this step
    const backgroundColor = index < this.props.step ? PaymentsProgressBar.GREEN : PaymentsProgressBar.GREY;

    const barStyle = {
      height: '3px',
      backgroundColor: backgroundColor,
      width: '100px',
      marginTop: '11px',
      marginLeft: '-20px',
      zIndex: '-1'
    }

    return <div style={barStyle}></div>;
  }

  renderText(index, text) {
    if(index == this.props.step) {
      return <strong>{text}</strong>;
    } else {
      return <span className='text-muted'>{text}</span>;
    }
  }

  getIconStyle(index) {
    // If a step isn't reached, the background is grey
    // If a step is active, the background is white
    // If a step is passed otherwise, green
    let backgroundColor = PaymentsProgressBar.GREY;
    if (index == this.props.step) {
      backgroundColor = PaymentsProgressBar.WHITE;
    } else if (index < this.props.step) {
      backgroundColor = PaymentsProgressBar.GREEN;
    }

    // The border is green if we're on or past this step, otherwise grey
    const borderColor = index <= this.props.step ? PaymentsProgressBar.GREEN : PaymentsProgressBar.GREY;

    return {
      backgroundColor: backgroundColor,
      border: '3px solid',
      borderColor: borderColor,
      height: '24px',
      margin: 'auto',
      width: '24px',
      borderRadius: '50%',
      paddingLeft: '2px'
    };
  }

  render() {
    const containerStyle = {
      display: 'flex',
      justifyContent: 'space-around',
      marginBottom: '16px'
    };

    return (
      <div style={containerStyle}>
        {this.props.steps.map(this.renderStep)}
      </div>
    );
  }
};

PaymentsProgressBar.GREEN = '#89c441';
PaymentsProgressBar.GREY = 'lightgrey';
PaymentsProgressBar.WHITE = 'white';
