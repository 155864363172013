import React from 'react';
import { connect } from 'react-redux';

class ProfilesPaypalSettings extends React.Component {
  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.descriptionText = this.descriptionText.bind(this);
    this.inputClass = this.inputClass.bind(this);
    this.render = this.render.bind(this);
    this.updateField = this.updateField.bind(this);
    this.validateField = this.validateField.bind(this);
    this.validations = this.validations.bind(this);
  }

  componentDidMount() {
    this.validateField('paypal_name');
    this.validateField('paypal_email');
  }

  render() {
    const {
      i18n,
      profile
    } = this.props;

    return <div className='card-plus paypal-settings-component'>
      <div className='card-header'>
        {i18n.navigation.payment}
      </div>

      <div className='card-body'>
        {this.descriptionText()}

        <div className='form-group'>
          <label>{i18n.labels.payments.paypal_name}</label>
          <input
            className={this.inputClass('paypal_name')}
            onChange={e => this.updateField('paypal_name', e)}
            value={profile.paypal_name || ''}
            />
          {this.validations('paypal_name')}
        </div>

        <div className='form-group'>
          <label>{i18n.labels.payments.paypal_email}</label>
          <input
            className={this.inputClass('paypal_email')}
            onChange={e => this.updateField('paypal_email', e)}
            value={profile.paypal_email || ''}
            />
          {this.validations('paypal_email')}
        </div>
      </div>
    </div>;
  }

  updateField(field, e) {
    this.validateField(field, e.target.value);
    this.props.updateProfile(field, e.target.value);
  }

  validateField(field, newValue = undefined) {
    const {
      profile,
      i18n,
      updateFrontendValidations
    } = this.props;

    let value;
    if(newValue != undefined) {
      value = newValue;
    } else {
      value = profile[field];
    }

    if(!value) {
      updateFrontendValidations({ [field]: i18n.validations.required });
      return;
    }

    if(field == 'paypal_email') {
      if(!String(value).match(/^(\w|\.|\-|\+|\d)+@(\w|\.|\-|\d)+$/)) {
        updateFrontendValidations({ [field]: i18n.validations.invalid_email });
        return;
      }
    }

    updateFrontendValidations({ [field]: undefined });
  }

  validations(field) {
    const {
      activeTab,
      frontendValidations
    } = this.props;

    const validation = _.get(frontendValidations[activeTab], field);
    if(!validation) {
      return null;
    }

    return <span className='help-text no-prefix'>
      {validation}
    </span>;
  }

  descriptionText() {
    const { i18n, profile } = this.props;

    const paymentValidations = profile.validations.payment_settings || {};
    const valid = _.every(Object.values(paymentValidations), x => !x);

    if(!valid) {
      return null;
    }

    return <p className='description-text'>
      {i18n.descriptions.update_settings}
    </p>;
  }

  inputClass(field) {
    if(this.validations(field)) {
      return 'form-control invalid';
    }

    return 'form-control';
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      profile: state.profile,
      i18n: state.meta.i18n,
      activeTab: state.activeTab,
      frontendValidations: state.frontendValidations
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateFrontendValidations: change => dispatch({ type: 'UPDATE_FRONTEND_VALIDATIONS', value: change }),
      updateProfile: (field, value) => dispatch({
        type: 'UPDATE_PROFILE',
        value: {
          field,
          value
        }
      })
    };
  }
};

ProfilesPaypalSettings.displayName = 'Profiles.PaypalSettings';
export default connect(
  ProfilesPaypalSettings.mapStateToProps,
  ProfilesPaypalSettings.mapDispatchToProps
)(ProfilesPaypalSettings);
