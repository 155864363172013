import React from 'react';

const FlexBoxContainer = (props) => {
  return (
    <div style={{
      display: props.display || 'flex',
      justifyContent: props.justifyContent || 'space-evenly',
      alignItems: props.alignItems || 'center',
      flexWrap: props.flexWrap || 'nowrap'}} >
      { props.children }
    </div>
  );
};

FlexBoxContainer.displayName = 'FlexBoxContainer';
export default FlexBoxContainer;