class window.UriUtils
  @getQueryParams: ->
    uri = window.location.href
    queryStart = uri.indexOf('?')
    return {} if queryStart < 0                   # Fast path -- no query params
    groups = uri.slice(queryStart + 1).split('&') # Get the key=value pairs into an array
    result = {}
    for group in groups
      [key, value] = group.split('=')             # Pull out the key and value individually from the grouping
      result[key] = decodeURI(value)
    result

