import React from "react"
import {connect} from "react-redux";
import axios from "axios";
import Alert from "../Alert";
import FlexBoxContainer from '../FlexBoxContainer'

class ManualMetricsInputs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showInputs: false,
      loading: false,
    };

    this.saveMetrics = this.saveMetrics.bind(this);
    this.renderMetricInputs = this.renderMetricInputs.bind(this);
    this.toggleShowInputs = this.toggleShowInputs.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.displayAlert =  this.displayAlert.bind(this);
    this.assignMetricsFields = this.assignMetricsFields.bind(this);
  }

  isDisabled() {
    return (
      this.state.loading || this.props.blogger_shop_task_state != "accepted"
    );
  }

  saveMetrics() {
    const { blogger_shop_task_id, analytics, proxyUrl, type } = this.props;
    const params = $.param({
      api_action: `/blogger_shop_tasks/${blogger_shop_task_id}/set_manual_analytics`,
    });
    const metrics = { ...analytics, type: type };

    this.setState({ loading: true });
    return axios
      .post(`${proxyUrl}?${params}`, metrics)
      .then((response) => {
        if (response.data.status && response.data.status != 200) {
          this.props.showAlert(
            "error",
            "Metrics failed to update. Please try again."
          );
        } else {
          this.toggleShowInputs();
          this.props.showAlert("success", "Metrics have updated succesfully.");
          setTimeout(() => {
            this.props.hideAlert();
          }, 5000);
        }
      })
      .catch(() => {
        this.props.showAlert(
          "error",
          "Metrics failed to update. Please try again."
        );
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  renderMetricInputs() {
    const { analytics, type } = this.props;
    const { disabled } = this.state;

    let metrics = this.assignMetricsFields(type);

    return metrics.map((metric, index) => (
      <React.Fragment key={metric}>
        {index % 2 == 0 && <div style={{ width: "100%" }} />}
        <div style={{ padding: "10px" }}>
          <label style={{ textTransform: "capitalize" }}>
            <abbr title="required">*</abbr>
            {metric}:
          </label>
          <input
            type="number"
            className="form-control"
            defaultValue={analytics ? analytics[metric] : 0}
            min="0"
            step="1"
            required={true}
            disabled={disabled}
            onChange={(e) => this.handleInputChange(e.target.value, metric)}
          />
        </div>
      </React.Fragment>
    ));
  }

  assignMetricsFields(type){
    switch (type) {
      case 'instagram_attachment':
        return ['views', 'likes', 'comments', 'shares', 'saves', 'followers'];
      case 'manual_tiktok':
        return ['views', 'likes', 'comments', 'shares', 'followers'];
      case 'youtube_short':
        return ['followers'];
      default:
        return ['views', 'likes', 'comments', 'shares', 'saves', 'followers'];
    }
  }

  handleInputChange(value, metric) {
    let { type, analytics } = this.props;
    switch (type) {
      case 'instagram_attachment':
        return this.props.setInstagramMetricCount({id: (analytics ? analytics.id : null), [metric]: value});
      case 'manual_tiktok':
        return this.props.setManualMetricCount('tiktokManualMetrics', metric, value);
      default:
        return this.props.setManualMetricCount('manualMetrics', metric, value);
    }
  }

  toggleShowInputs() {
    const { showInputs } = this.state;
    this.setState({ showInputs: !showInputs });
  }

  displayAlert() {
    const { alert, hideAlert } = this.props;
    return (
      alert && (
        <Alert type={alert.type} message={alert.message} callback={hideAlert} />
      )
    );
  }

  render() {
    const { showInputs } = this.state;
    const disabled = this.isDisabled();

    if (showInputs) {
      return (
        <div className="manual-metrics">
          <div className="manual-metrics-instructions">
            <p>{this.props.manualMetricsInstructions}</p>
          </div>
          <FlexBoxContainer justifyContent="center" flexWrap="wrap">
            {this.renderMetricInputs()}
          </FlexBoxContainer>
          {this.displayAlert()}
          <div className="manual-metrics-btn">
            <div
              className="btn btn-primary"
              disabled={disabled}
              onClick={() => !disabled && this.saveMetrics()}
            >
              Save Metrics
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="manual-metrics">
          {this.displayAlert()}
          <div className="manual-metrics-btn">
            <div
              className="btn btn-primary"
              disabled={disabled}
              onClick={() => !disabled && this.toggleShowInputs()}
            >
              Update Metrics
            </div>
          </div>
        </div>
      );
    }
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      alert: state.alert,
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      setInstagramMetricCount: (value) => dispatch({ type: 'SET_INSTAGRAM_METRIC_COUNT', value: value }),
      setManualMetricCount: (contentName, metric, value) => dispatch({ type: 'SET_MANUAL_METRIC_COUNT', value: { contentName: contentName, metric: metric, value: value } }),
      showAlert: (type, message) => dispatch({type: 'SHOW_ALERT', value: {type: type, message: message}}),
      hideAlert: () => dispatch({type: 'HIDE_ALERT'})
    };
  }
}

ManualMetricsInputs.displayName = 'ManualMetricsInputs';
export default connect(ManualMetricsInputs.mapStateToProps, ManualMetricsInputs.mapDispatchToProps)(ManualMetricsInputs);