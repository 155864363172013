import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash'
import SubmissionDraftContentAttachment from './DraftContentAttachment'

class SubmissionManualAnalyticsPreview extends React.Component {
  render() {
    if(this.props.instagramAttachments.length == 0 || !this.props.readOnly) {
      return null;
    }

    return <div className='submission manual-analytics'>
      <div className='manual-analytics-title'>
        Submitted Analytics
      </div>
      <div className='submission content-grid instagram content-count-1'>
        {
          this.props.instagramAttachments.map((attachment, index) =>
            <SubmissionDraftContentAttachment
              key={attachment.id}
              index={index}
              readOnly={true}
              attachment={attachment}
              deleteAttachment={() => {}}
              />
          )
        }
      </div>
    </div>;
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      instagramAttachments: _.get(state.bloggerShopTask, 'instagram_attachments', []),
      readOnly: state.readOnly
    };
  }
}

SubmissionManualAnalyticsPreview.displayName = 'Submission.ManualAnalyticsPreview';

export default connect(
  SubmissionManualAnalyticsPreview.mapStateToProps
)(SubmissionManualAnalyticsPreview);
