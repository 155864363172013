import React from 'react';

export default class Content extends React.Component {
  render(){
    const {round_name, draft_type, shoppertunity_name, activity} = this.props.notification;
    const P = React.Fragment;
    switch(activity){
      case 'accepted':
        if(draft_type){
          return(
            <P>
              Your blog post draft for {shoppertunity_name} has been approved!
              Copy the html or text from Social Fabric as some minor changes may have been made and paste it into your blog. Please do not make your blog post live until the blog post due date.
            </P>
          )
        }else {
          return (
            <P>
              Your content for the {shoppertunity_name} opportunity has been approved!  No further action is required at this time.
            </P>
          )
        }
      case 'pending_cm_approval':
        return(
          <P>You have a blog draft ready for your review for <b>{round_name}</b></P>
        )
      case 'rejected':
        if(draft_type){
          return(
            <P>Hello. Thanks for participating in the {shoppertunity_name} opportunity. We reviewed your content and need you to make a few adjustments before resubmitting.
              Please reach out to your campaign manager if you have any questions!
            </P>
          )
        }else {
          return (
            <P>
              Your content for the {shoppertunity_name} opportunity has been rejected.
            </P>
          )
        }
      case 'resubmit':
        return(
          <P>We're sorry, but your content for <b>{shoppertunity_name}</b> needs to be resubmitted.</P>
        )
      case 'resubmitted':
        return(
          <P><b>{display_name}</b> has resubmitted content for <b>{shoppertunity_name}</b></P>
        )
      case 'submitted':
        return(
          <P><b>{display_name}</b> has resubmitted content for <b>{shoppertunity_name}</b></P>
        )
      default:
        return null;
    }

  }
}
Content.displayName = 'Notification.Templates.Content'