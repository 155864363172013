class window.AdminShopPosting

  @show: ->
    ShopDashboard.setup()

  @form: ->
    ShopDashboard.setup()

  @edit: ->
    @form()

  @update: ->
    @form()
