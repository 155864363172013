import React from 'react';
var LivingStyleGuide = LivingStyleGuide || {};

export default class LivingStyleGuideCards extends React.Component {

  render() {
    return (
      <div id='cards' className='style-guide-section'>
        <h1 className='section-header'>Cards</h1>
        <div className='section-description'>
          <p>
            The visual language for all containers on Social Fabric is based on the idea of cards. Cards
            contain content and actions about a single subject. They should be easy to scan for relevant
            and actionable information. Elements, like text and images, should be placed on them in a
            way that clearly indicates hierarchy.
          </p>
        </div>
        <div className='row'>
          <div className='sub-section column'>
            <h3 className='sub-section-header'>Contained</h3>
            <hr/>
            <p>A card is identifiable as a single, contained unit.</p>
          </div>
          <div className='sub-section column'>
            <h3 className='sub-section-header'>Independant</h3>
            <hr/>
            <p>A card can stand alone, without relying on surrounding elements.</p>
          </div>
          <div className='sub-section column'>
            <h3 className='sub-section-header'>Individual</h3>
            <hr/>
            <p>A card cannot merge with another card, or divide into multiple cards.</p>
          </div>
        </div>
        <div className='styles-container'>
          <div className='card-style'>
            <div className='example'>
              <div className='card'>This is a standard card</div>
          </div>
            <div className='code-example'>
              <pre>
                <code className="language-markup">
                  {`
                    <div className='card'>
                      This is a standard card
                    </div>
                  `}
                </code>
              </pre>
            </div>
          </div>
          <div className='card-style'>
            <div className='example'>
              <div className='card clickable'>This is a clickable card</div>
          </div>
            <div className='code-example'>
              <pre>
                <code className="language-markup">
                  {`
                    <div className='card clickable'>
                      This is a clickable card
                    </div>
                  `}
                </code>
              </pre>
            </div>
          </div>
          <div className='card-style'>
            <div className='example'>
              <div className='card-plus'>
                <div className='card-header'>
                  This is a card-plus header.
                </div>
                <div className='card-body'>
                  This is the card-plus body.
                </div>
                <div className='card-footer'>
                  This is a card-plus footer
                </div>
              </div>
            </div>
            <div className='code-example'>
              <pre>
                <code className="language-markup">
                  {`
                    <div className='card-plus'>
                      <div className='card-header'>
                        This is a card header.
                      </div>
                      <div className='card-body'>
                        This is the card's body.
                      </div>
                      <div className='card-footer'>
                        This is a card footer
                      </div>
                    </div>
                  `}
                </code>
              </pre>
            </div>
            </div>
        </div>
      </div>
    );
  }
}
LivingStyleGuideCards.displayName = 'LivingStyleGuide.Cards';