import React from 'react';

export default class ZipSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      zipCodes: this.unwrapZipCodes(this.props.targetingValues)
    };

    this.onZipChange = this.onZipChange.bind(this);
    this.removeInvalidZips = this.removeInvalidZips.bind(this);
    this.render = this.render.bind(this);
    this.truncateZips = this.truncateZips.bind(this);
    this.unwrapZipCodes = this.unwrapZipCodes.bind(this);
    this.wrappedZipCodes = this.wrappedZipCodes.bind(this);
    this.zipLengthErrors = this.zipLengthErrors.bind(this);
    this.zipsPasted = this.zipsPasted.bind(this);
  }

  render() {
    const textAreaStyle = {
      backgroundColor: 'white'
    };

    return (
      <div className='form-group'>
        <label>Zip Codes</label>
        <textarea readOnly
                  style={textAreaStyle}
                  className='form-control'
                  onPaste={this.zipsPasted}
                  placeholder='Paste relevant zipcodes from a spreadsheet here'
                  value={this.state.zipCodes.join(',')} />
        {
          !!this.zipLengthErrors().length &&
          <div className='alert alert-danger'>
            <i className='fas fa-exclamation-triangle'/>
            The following invalid zip codes were pasted: &nbsp;
            {this.zipLengthErrors().join(', ')}

            <div className='text-right'>
              <button className='btn btn-danger' onClick={this.removeInvalidZips}>
                Remove Invalid Zip Codes
              </button>
            </div>
          </div>
        }

        {
          this.state.zipCodes.length > ZipSelector.MAX_ZIP_CODES &&
          <div className='alert alert-danger'>
            <i className='fas fa-exclamation-triangle'/> You have exceeded 2500 zip codes
            <div className='text-right'>
              <button className='btn btn-danger' onClick={this.truncateZips}>
                Trim list to 2500 zip codes
              </button>
            </div>
          </div>
        }
      </div>
    );
  }

  unwrapZipCodes(values) {
    return values.map(zip => zip.key).map(zip => zip.split(':')[1]);
  }

  wrappedZipCodes() {
    return this.state.zipCodes.map(code => ({ key: `US:${code}` }))
  }

  zipsPasted(event) {
    event.preventDefault();
    const zips = event.clipboardData.getData('text');

    this.setState({
      zipCodes: zips.split(/[\n\r][\n\r]?/).map(zip => zip.toLowerCase().trim()).filter(x => !!x)
    }, () => this.onZipChange());
  }

  zipLengthErrors() {
    return this.state.zipCodes.filter(x => x.length != 5);
  }

  removeInvalidZips() {
    this.setState({
      zipCodes: this.state.zipCodes.filter(x => x.length == 5)
    }, () => this.onZipChange());
  }

  truncateZips() {
    this.setState({
      zipCodes: this.state.zipCodes.slice(0, ZipSelector.MAX_ZIP_CODES)
    }, () => this.onZipChange());
  }

  onZipChange() {
    if(!this.zipLengthErrors().length && this.state.zipCodes.length <= ZipSelector.MAX_ZIP_CODES) {
      this.props.updateLocationData('zips', this.wrappedZipCodes())
    } else {
      this.props.invalidLocationData();
    }
  }
};

ZipSelector.MAX_ZIP_CODES = 2500;
