import React from 'react';
import { connect } from 'react-redux';
import { Remote } from '../utils';

import ConversationCard from './ConversationCard';
class ConversationsList extends React.Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.loadMoreConversations = this.loadMoreConversations.bind(this);
  }

  loadMoreConversations() {
    const {current_page, per_page} = this.props;
    this.props.toggleLoading();
    Remote().request({
      method: 'GET',
      url: `/conversations/load_conversations?current_page=${current_page+1}&per_page=${per_page}`,

    }).then((response) => {
    let conversations = response.data.conversations;
    this.props.updateConversations({conversations});
    }).catch((error) => {
      this.props.toggleLoading();
    });
  }

  render() {
    const {loading, conversations, current_page, total_pages, shop_conversation} = this.props;
    let loadConversations = (current_page < total_pages);
    return (
      <div className='col-md-4 conversation-list'>
        <div className='conversation-header'>
          <span className='text-center messages-title'>
            Messages
          </span>
          <span className='pull-right' onClick={() => this.props.newConversation()}>
            { !shop_conversation && <i className='fas fa-edit fa-new-icon'></i> }
          </span>
        </div>
        <div className='card-body conversation-cards'>
          {
            (conversations.length > 0) &&
            conversations.map((conversation) => {
              if (!!conversation.messages && conversation.messages.length > 0 &&
                    Object.values(conversation.participants).length > 1){
                return <ConversationCard conversation={conversation} key={conversation.id}/>;
              }
            })
          }
          { loadConversations &&
          <div id="conversation_show_more" className='conversation-show-more' key='show_more'>
            <button id="show_more_button" className="btn btn-default js-ga-show-more" onClick={this.loadMoreConversations}>
              Show More
            </button>
            { loading && <i id='show_more_spinner' className='fas fa-spinner fa-spin'></i> }
          </div>
          }
        </div>
      </div>
    );
  }
  static mapStateToProps(state, myProps) {
    return {
      messages_page: state.messages_page,
      load_more_messages: state.load_more_messages,
      conversations: state.conversations,
      conversation: state.conversation,
      loading: state.loading,
      selected: state.selected,
      current_page: state.current_page,
      per_page: state.per_page,
      total_pages: state.total_pages,
      new_conversation: state.new_conversation,
      ...myProps
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateConversations: data => dispatch({ type: 'UPDATE_DATA', value: data }),
      toggleLoading: () => dispatch({type: 'TOGGLE_LOADING'}),
      newConversation: () => dispatch({type: 'SET_NEW_CONVERSATION'})
    };
  };
};

ConversationsList.displayName = 'Conversations.List';

export default connect(
  ConversationsList.mapStateToProps,
  ConversationsList.mapDispatchToProps
)(ConversationsList);