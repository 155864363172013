import React from 'react';
import { connect } from 'react-redux';
import notif from "javascripts/notifIt.js"
import { Remote } from '../utils';

import PropTypes from 'prop-types'
import PaidMediaGoals from './PaidMediaGoals'
import Scheduler from './Scheduler'
import Wave from './Wave'
import SelectInput from './SelectInput'
import PromoterWizardV2 from './PromoterWizardV2'
import ContentsDatatable from './ContentsDatatable'
import FloatingBox from '../FloatingBox'

const PROMOTER_AD_TYPES = [
  {name:'Influencer Content', id: 'influencer'},
  {name: 'Custom Content', id: 'custom'}
]

class CurrentWavePage extends React.Component {
  constructor(props) {
    super(props);

    this.canPublish = this.canPublish.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.forceRefresh = this.forceRefresh.bind(this);
    this.isInvalid = this.isInvalid.bind(this);
    this.publishWave = this.publishWave.bind(this);
    this.readableErrors = this.readableErrors.bind(this);
    this.render = this.render.bind(this);
    this.selectCallback = this.selectCallback.bind(this);
  }

  componentDidMount() {
    this.props.updateState({
      rounds: this.props.rounds,
      shoppertunities: this.props.shoppertunities,
      adType: PROMOTER_AD_TYPES[0].id,
      campaignId: this.props.campaign_id,
      datatableUrl: this.props.datatable_url,
      customAdsDatatableUrl: this.props.custom_ads_datatable_url,
      advertiserAuth: this.props.advertiser_auth
    });
  }

  render() {
    if(!this.props.campaignId) {
      return null;
    }

    return (
      <div>
        <PaidMediaGoals campaignId={this.props.campaign_id}/>
        <Scheduler refresh={this.forceRefresh} editing={true}/>

        <Wave updateTime={this.props.updateTime}
                          campaignId={this.props.campaign_id}
                          waveState='current_wave'
                          refresh={this.forceRefresh}/>

        <div className='text-right'>
          <span style={{position: 'relative'}}>
            <span className='btn btn-primary'
                  onClick={this.publishWave}
                  onMouseEnter={() => this.props.setValidationVisibility(true)}
                  onMouseLeave={() => this.props.setValidationVisibility(false)}
                  disabled={this.props.publishing || !this.canPublish()}>
              Publish Current Wave
            </span>
            {this.props.validationsVisible && this.isInvalid() && <FloatingBox type="error" message={this.readableErrors()}/>}
          </span>
        </div>

        <h2>Promotable Content</h2>
        <div className='row'>
          <SelectInput fieldName='adType' placeholder={null} options={PROMOTER_AD_TYPES} selectCallback={this.selectCallback}/>
          { this.props.adType == 'influencer' &&
            <React.Fragment>
              <SelectInput fieldName='shopId' placeholder='All Opportunities' options={this.props.shoppertunities} selectCallback={this.selectCallback} />
              <SelectInput fieldName='roundId' placeholder='All Rounds' options={this.props.rounds} selectCallback={this.selectCallback}/>
            </React.Fragment>
          }
          <div className='custom-content-btn'>
            <span className='btn btn-primary' onClick={this.openCustomWizard}>
              <i className='fas fa-plus' /> Create Custom Content
            </span>
          </div>
        </div>

        <ContentsDatatable />

        {
          this.props.showWizard && <PromoterWizardV2 campaignId={this.props.campaign_id}
                                                                 contentId={this.props.contentId}
                                                                 stateId={this.props.stateId}
                                                                 contentUrl={this.props.contentUrl}
                                                                 contentUtm = {this.props.contentUtm}
                                                                 userId={this.props.userId}
                                                                 contentExternalLink={this.props.externalLink}
                                                                 closeModal={this.closeModal}
                                                                 contentType={this.props.contentType}
                                                                 forceRefresh={this.forceRefresh}/>
        }
        {
          this.props.showCustomWizard && <PromoterWizardV2 campaignId={this.props.campaign_id}
                                                                       contentId={null}
                                                                       closeModal={this.closeModal}
                                                                       contentType='CustomContent'
                                                                       forceRefresh={this.forceRefresh}/>
        }
      </div>
    );
  }

  closeModal() {
    this.props.updateState({
      showWizard: false,
      showCustomWizard: false
    });
  }

  forceRefresh() {
    this.props.updateState({
      updateTime: new Date()
    });
  }

  publishWave() {
    if(!this.canPublish()) {
      return;
    }

    this.props.updateState({
      publishing: true
    });

    Remote().request({
      url: `/admin/campaigns/${this.props.campaign_id}/promoters_v2/api_proxy/waves?resource=publish_pending`,
      method: 'POST'
    }).then(() => notif({
      msg: 'Publishing current wave',
      type: 'notice',
      timeout: 3000
    }));
  }

  canPublish() {
    return !(this.props.schedulerValidations.some(x => !!x.validation) || this.props.adsetValidations.some(x => !!x.validation));
  }

  readableErrors() {
    const validations = this.props.adsetValidations
      .concat(this.props.schedulerValidations)
      .map(x => x.validation)
      .filter(x => !!x)

    return _.uniq(validations).join(', ');
  }

  isInvalid() {
    return this.props.adsetValidations.concat(this.props.schedulerValidations).filter(x => !!x.validation).length > 0;
  }

  selectCallback(fieldName, value) {
    this.props.updateState({
      [fieldName]: value && value.length > 0 ? value[0].id : null
    });

    if (fieldName === 'shopId') {
      let rounds = [];
      if ( value && value.length > 0 ) {
        this.props.rounds.forEach((round, i) => {
          if (round.shoppertunity_id === value[0].id) {
            rounds.push(round);
          }
        });
      } else {
        rounds = this.props.rounds;
      }

      this.props.updateState({
        rounds: rounds,
        roundId: null
      });
    }
  }

  static mapStateToProps(state, myProps) {
    const ignoredKeys = [
      'rounds',
      'shoppertunities',
    ];

    // Use ignoredKeys to remove parts of the state we don't want to map
    const targetState = _.pickBy(state, (_v, key) => !_.includes(ignoredKeys, key));
    return {
      ...myProps,
      ...targetState
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      setValidationVisibility: show => dispatch({ type: 'VALIDATION_VISIBILITY', value: show }),
      updateAccess: value => dispatch({ type: 'UPDATE_ACCESS', value }),
      updateState: changes => dispatch({ type: 'UPDATE_STATE', value: changes })
    };
  }
};

CurrentWavePage.METADATA_COLUMN = 12;

CurrentWavePage.propTypes = {
  datatable_url: PropTypes.string.isRequired,
  campaign_id: PropTypes.number.isRequired,
  shoppertunities: PropTypes.any.isRequired,
  rounds: PropTypes.any.isRequired
};

export default connect(
  CurrentWavePage.mapStateToProps,
  CurrentWavePage.mapDispatchToProps
)(CurrentWavePage);
