import React from 'react';
var OpenPromotionCard = createReactClass({

  getInitialState: function () {
    return {
      postPreview: false,
    };
  },

  togglePreview: function () {
    if (this.props.promo_disabled) { return false; }
    return (
      this.setState({
        postPreview: !this.state.postPreview,
      })
    );
  },

  render: function () {
    var promo_disabled = this.props.promo_disabled;
    var promotion = this.props.open_card;
    var postPreview = this.state.postPreview;
    var promoImageStyle = { backgroundImage: 'url(' + promotion.thumbnail_image + ')' };

    var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    var content = '<div>' + promotion.content.replace(urlRegex, '<a target=\'_blank\' href=\'$1\'>$1</a>') + '</div>';

    var btnClass = (promo_disabled || postPreview) ? 'disabled' : '';

    return (
      <div className="col-xs-12">
        <div className="promotion-wrapper clearfix">
          <div className="promo-img pull-left" style={promoImageStyle} />
          <div className="promo_card--right pull-left">
            <div className="col-md-8 col-sm-7 col-xs-12 mb-10">
              <div className="promo_card--header mb-10">
                <i className={'promo-social-icon fab fa-fw ' + promotion.social_icon} />
                <h4 className="mt-0">{promotion.name}</h4>
              </div>
              <div className="promo-text" dangerouslySetInnerHTML={{ __html: content }} />
            </div>
            <div className="col-md-4 col-sm-5 col-xs-12 pd-0">
              <div className="pull-right">
                <div className="promo--max pull-left">
                  <span>Payment</span>
                  <div className="promo--value">${promotion.payment.toFixed(2)}</div>
                </div>
                <div className="promo-button pull-left ml-10">
                  <button
                    className={'btn btn-primary promo--btn ' + btnClass}
                    disabled={btnClass}
                    onClick={this.togglePreview}
                  >
                    Preview
                  </button>
                </div>
              </div>
            </div>
          </div>
          { postPreview && <PromotionCopy promotion={promotion} handleChange={this.togglePreview} /> }
        </div>
      </div>
    );
  },
});
