require('jquery-validation');
import 'datatables.net'
import 'datatables.net-dt'
import 'datatables.net-bs'
import '../plugins/datatables.buttons'
import '../plugins/buttons.colVis.min'
import '../plugins/datatables.colReorder.min.js'
import '../plugins/datatables.fixedHeader.min.js'
import '../plugins/datatables.rowReorder.min.js'
import '../plugins/datatables.fnFilterOnReturn.js'
import 'datatables.net-fixedcolumns-bs'
import notif from 'javascripts/notifIt.js'

window.location.origin ||= window.location.protocol + "//" + window.location.hostname + ((if window.location.port then ":" + window.location.port else ""))

$ ->
  namespace = window
  body_jqe = $('body')
  controller = body_jqe.data('controller')
  controller_class = Utils.to_class_case(controller)
  action = body_jqe.data('action')
  if namespace[controller_class] and typeof namespace[controller_class][action] is 'function'
    console.log("running method #{controller_class}.#{action}") if namespace.debug
    namespace[controller_class][action]();

jQuery.fn.scrollTo = (elem, speed) ->
    $(this).animate({
        scrollTop:  $(this).scrollTop() - $(this).offset().top + $(elem).offset().top
    }, speed is undefined ? 1000 : speed);
    return this

$(document).ready ->
  FormUtils.form_bindings()

  $(".link-tab a").attr("target", "_blank")

  $('[data-toggle="tooltip"]').tooltip
    delay: { show: 100, hide: 50 }
    container: 'body'
    html: true

  $('[data-toggle="tooltip_inner_link"]').tooltip(
    delay: { show: 100, hide: 50 }
    trigger: 'manual'
    container: 'body'
    html: true).on('mouseenter', ->
    _this = this
    $(this).tooltip 'show'
    $('.tooltip').on 'mouseleave', ->
      $(_this).tooltip 'hide'
  ).on 'mouseleave', ->
    _this = this
    setTimeout (->
      if !$('.tooltip:hover').length
        $(_this).tooltip 'hide'
    ), 300

  $('[data-toggle="popover"]').popover
    container: 'body'
    html: true

  $(document).on 'click', '.js_show_search_bar', (e) ->
    e.preventDefault()
    $('.js-top-search').toggleClass('is-hidden');
    container = document.querySelector('.js-top-search')
    if ! !container
      field = container.querySelector('input#q')
      if ! !field
        field.focus()

  $(document).on 'click', '.js_close_search_bar', ->
    $('.js-top-search').addClass('is-hidden');

  $(document).on 'click', 'a.search-link', ->
    $('input#search_class').val($(this).data('search'))
    $("#selected_search").removeClass( ).addClass("fas fa-#{$(this).data('fa')}")
    if $(this).data('search') == 'users'
      $(".dropdown-toggle.search_menu>i.fas").html("<span>Members</span>")
    else if $(this).data('search') == 'shoppertunities'
      $(".dropdown-toggle.search_menu>i.fas").html("<span>Shops</span>")
    else
      $(".dropdown-toggle.search_menu>i.fas").html("<span>#{$(this).data('search')}</span>")


  display_flash = ->
    $.each $('.flash'), (i, flash) ->
      notif(
        msg: $(flash).data('message')
        type: $(flash).data('type')
        position: 'right'
        autohide: true
        timeout: 3000
        top: 120
      )

  display_flash()

  $('#delete_user_account').on 'hidden.bs.modal', ->
    $('#influencer_exit_survey')[0].reset()

  $(document).on 'show.bs.modal', '#delete_user_account', ->
    $('.js-exit-response, .exit-survey-text').on 'change keyup', ->
      if $('.exit-survey-content').find(':checked').length > 0 || $('.exit-survey-text').val().length > 0
        $('.js-delete-account').attr 'disabled', false
      else
        $('.js-delete-account').attr 'disabled', true
