import React from 'react';

export default class InstructionsReadOnlyNavigation extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  render() {
    return (
      <div className='left-instructions-nav' id='left_instruct_nav' data-spy="affix" data-offset-top="285">
        { this.props.legacy && <a href="#specific_campaign">Campaign Specific</a> }
        { this.props.legacy && <a href="#standard_campaign">Standard Campaign</a> }
        { !this.props.legacy && <a href="#content_requirements">Content Requirements</a> }
        <a href="#terms_and_agreement">Terms & Agreement</a>
        { this.props.showPayment && <a href="#payment">Payment</a> }
        <a className='back_to_top' href="#back_to_top_anchor"><i className='fas fa-arrow-circle-up'></i> Back to Top</a>
      </div>
    );
  }
}

InstructionsReadOnlyNavigation.displayName = 'Instructions.ReadOnly.Navigation';
