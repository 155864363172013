import React from 'react';

const SubmissionAuthTypes = {
  FacebookVideoContent: 'facebook_auth',
  YoutubeContent: 'youtube_auth',
  GoogleYoutubeContent: 'youtube_auth',
  InstagramContent: 'instagram_auth',
  InstagramStoryContent: 'instagram_auth',
  InstagramBusinessStoryContent: 'instagram_auth',
  TiktokVideoContent: 'tiktok_auth'
};

export default SubmissionAuthTypes;
