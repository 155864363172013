import React from 'react';
import PropTypes from 'prop-types'

export default class Tooltip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dimensions: null,
      position: null
    }

    this.tooltip = React.createRef();
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    const tooltip = this.tooltip.current;

    this.setState({
      dimensions: {
        width: tooltip.offsetWidth,
        height: tooltip.offsetHeight
      },
      position: tooltip.getBoundingClientRect(),
      parent: tooltip.parentElement.getBoundingClientRect()
    })
  }

  render() {
    const placementStyle = this.state.dimensions ? this.placementStyle() : { tooltip: {}, arrow: {} };
    const { centerText, message, color, size } = this.props;

    const backgroundColor = color === 'light' ? '#E7E8EA' : '#3D425D';
    const maxWidth = size === 'small' ? '100px' : '200px';
    const propStyles = {
      color: color === 'light' ? '#000' : '#fff',
      backgroundColor,
      maxWidth
    }
    const hiddenStyle = {
      visibility: 'hidden'
    };

    const tooltipStyle = {
      zIndex: 99,
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '6px 12px',
      width: 'max-content',
      height: 'auto',
      color: '#fff',
      fontSize: '14px',
      borderRadius: '4px',
      textTransform: 'initial',
      fontFamily: '"Lato", Arial, sans-serif',
      ...propStyles
    };

    const messageStyle = {
      whiteSpace: 'normal',
      textAlign: centerText ? 'center' : 'left'
    }

    const arrowStyle = {
      position: 'absolute',
      width: '0',
      height: '0',
      borderLeft: '8px solid transparent',
      borderRight: '8px solid transparent',
      borderBottom: `8px solid ${backgroundColor}`
    };

    return (
      <div ref={this.tooltip} style={this.state.dimensions ? {...tooltipStyle, ...placementStyle.tooltip} : {...tooltipStyle, hiddenStyle}}>
        <span style={messageStyle}>{message}</span>
        <div style={{...arrowStyle, ...placementStyle.arrow}}></div>
      </div>
    );
  }

  tooltipOffset() {
    const { position } = this.state
    let offset = {};
    offset.top = position.top < 0;
    offset.left = position.left < 0;
    offset.bottom = position.bottom > document.documentElement.clientHeight;
    offset.right = position.right > document.documentElement.clientWidth;
    return offset;
  }

  placementStyle() {
    const { placement } = this.props;
    const { dimensions: { width, height }, parent  } = this.state;

    const offset = this.tooltipOffset();
    const docWidth = document.documentElement.clientWidth;
    let newPlacement = null;
    let boxOffset = null;
    let arrowOffset = null;

    // If outside doc window
    if(offset.left || ((width + 12) - parent.left) > 0) {
      newPlacement = placement === 'left' ? 'right' : placement;

      if (parent.left < ((width - parent.width) / 2)) {
        boxOffset = parent.left > 0 ? `${parent.left * -1}px` : '0px';
        arrowOffset = `${((parent.width / 2) + parent.left) - 8}px`;
      }
    } else if(offset.right || (width + 12) - (docWidth - parent.right) > 0 ) {
      newPlacement = placement === 'right' ? 'left' : placement;

      if ((docWidth - parent.right) < ((width - parent.width) / 2)) {
        boxOffset = `${(((docWidth - parent.left) - width) - 1)}px`;
        arrowOffset = `calc(100% - ${(parent.width / 2) + (docWidth - parent.right) + 8}px)`;
      }
    }

    switch(newPlacement ? newPlacement : placement) {
      case 'top':
        return {
          tooltip: {
            bottom: 'calc(100% + 12px)',
            left: boxOffset ? boxOffset :`calc(50% - ${width / 2}px)`
          },
          arrow: {
            bottom: '-8px',
            left: arrowOffset ?  arrowOffset : 'calc(50% - 8px)',
            transform: 'rotate(180deg)'
          }
        };
      case 'right':
        return {
          tooltip: {
            top: `calc(50% - ${height / 2}px)`,
            left: `calc(100% + 12px)`
          },
          arrow: {
            top: 'calc(50% - 4px)',
            left: '-12px',
            transform: 'rotate(270deg)'
          }
        };
      case 'left':
        return {
          tooltip: {
            top: `calc(50% - ${height / 2}px)`,
            right: `calc(100% + 12px)`
          },
          arrow: {
            top: 'calc(50% - 4px)',
            right: '-12px',
            transform: 'rotate(90deg)'
          }
        };
      default:
        return {
          tooltip: {
            top: 'calc(100% + 12px)',
            left: boxOffset ? boxOffset : `calc(50% - ${width / 2}px)`
          },
          arrow: {
            top: '-8px',
            left: arrowOffset ? arrowOffset  : 'calc(50% - 8px)',
          }
        };
    }
  }
};

Tooltip.propTypes = {
  message: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  centerText: PropTypes.bool,
  color: PropTypes.oneOf(['light', 'dark']),
  size: PropTypes.oneOf(['small', 'large'])
};

Tooltip.displayName = 'Tooltip';
