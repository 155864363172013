`import notif from 'javascripts/notifIt.js'`

class window.AdminPromoters

  @show: ->
    @content_datatable()
    @facebook_ad_campaign_scheduler_datatable()
    @facebook_ad_campaign_datatable()
    AdvertiserRequests.init('.advertiser-access-btn')

    $('.js-publish-ad-link').click ->
      $(this).addClass('disabled')

  @content_datatable: ->
    DataTableUtils.datatable($('#promoter-view'),
      bServerSide: true
      iDisplayLength: 20
      aaSorting: [[1, 'desc']]
      sScrollX: '100%'
      aoColumns: [
        { bSortable: false, bSearchable: true,  sWidth: '50px'  }, # thumbnail
        { bSortable: true,  bSearchable: false, sWidth: '35px'  }, # submitted at
        { bSortable: true, bSearchable: true,  sWidth: '160px' }, # influencer
        { bSortable: false, bSearchable: false, sWidth: '100px' }, # url
        { bSortable: false, bSearchable: true,  sWidth: '250px' }, # notes bip
        { bSortable: true,  bSearchable: false, sWidth: '35px'  }, # engagements
        { bSortable: true,  bSearchable: false, sWidth: '35px'  }, # engagement rate
        { bSortable: true,  bSearchable: false, sWidth: '35px'  }, # total views
        { bSortable: true,  bSearchable: false, sWidth: '35px'  }, # view rate
        { bSortable: false, bSearchable: true,  sWidth: '40px'  }, # access
        { bSortable: false, bSearchable: true,  sWidth: '40px'  }, # utm
        { bSortable: false, bSearchable: true,  sWidth: '40px'  }, # add ad
       ]
      fnDrawCallback: ->
        $('.best_in_place').best_in_place()
        $('i, div').tooltip()
        AdvertiserRequests.init('.advertiser-access-btn')
      columnDefs: [
        {
          targets: 11,
          createdCell: (td, cellData, rowData, row, col) ->
            button = $(td).find('.fas.ad-wizard-btn')
            $(button).click ->
              if $(this).hasClass("fa-plus") then $(this).removeClass("fa-plus").addClass("fa-edit")
              id = Number($(button).attr('data-content-id'))
              link = $(button).attr('data-external-link')
              userId = Number($(button).attr('data-user-id'))
              $('#promoter-wizard-user-id').val(userId)
              $('#promoter-wizard-content-id').val(id)
              $('#promoter-wizard-external-link').val(link)
              $('#promoter-wizard .modal').modal
                  keyboard: false
                  backdrop: 'static'
        }
      ]
    )

    $('body').on 'click', '#utm-url-btn', (e) ->
      e.preventDefault()
      prompt('Copy UTM link to clipboard: Command+C, Enter', $(this).data('url'))

  extract_targeting_objects = (targeting, type) ->
    categories = []
    select = $('#detailed-targeting-selected')
    $.each targeting[type], (index, value) ->
      categories.push("#{value['name']}(#{type})")
      key = "#{value['name']}(#{type})"
      select.append("<option data-fbid='#{value['id']}' data-name='#{value['name']}' data-type='#{type}' value='#{key}'>#{key}</option>")
    return categories

  categories = ['interests', 'behaviors', 'life_events', 'industries',
                  'income', 'net_worth', 'home_type', 'home_ownership',
                  'generation', 'household_composition', 'moms',
                  'office_type', 'family_statuses', 'politics', 'user_device']

  clear_targeting_details = (targeting) ->
    for value in categories
      if(targeting[value] != null)
        delete targeting[value]

  gather_targeting_details = (targeting) ->
    targeting_details = []
    for value in categories
      if(targeting[value] != null)
        targeting_details.push extract_targeting_objects(targeting,value)...
    return targeting_details

  push_detailed_targeting_to_obj = (detailed_targeting, save_obj) ->
    $.each detailed_targeting, (index, value) ->
      option_details = $('#detailed-targeting-selected').find('option[value="'+value+'"]')
      cat = option_details.data().type
      fbid = option_details.data().fbid
      name = option_details.data().name
      if(save_obj[cat] == undefined)
        save_obj[cat] = []

      if(fbid != undefined)
        save_obj[cat].push({id:parseInt( fbid, 10), name:name})
      else
        save_obj[cat].push({name:name})
    return save_obj

  format_custom_audiences_to_fb = () ->
    custom_audiences = $('#custom-audiences-dropdown').val()
    if(custom_audiences?)
      return custom_audiences.map( (value) -> { id: value })
    else
      return []

  format_custom_audiences = (custom_audiences) ->
    if(custom_audiences?)
      $('#custom-audiences-dropdown').select2('val', custom_audiences.map((s) -> s['id']))
    else
      $('#custom-audiences-dropdown').select2('val', [])

  @facebook_ad_campaign_scheduler_datatable: ->
    ad_scheduler_table = DataTableUtils.datatable($('#promoter-scheduler-view'),
      bServerSide: true
      iDisplayLength: 50
      sScrollX: '100%'
      searching: false
      ordering: false
      aoColumns: [
        { bSortable: false, bSearchable: false, sWidth: '25%', className: 'js-sched-ad-type' },
        { bSortable: false, bSearchable: false, sWidth: '8%',  className: 'js-sched-start-date js-datepick' },
        { bSortable: false, bSearchable: false, sWidth: '8%',  className: 'js-sched-end-date js-datepick' },
        { bSortable: false, bSearchable: false, sWidth: '10%', className: 'js-sched-num-adsets' },
        { bSortable: false, bSearchable: false, sWidth: '11%', className: 'js-sched-budget' },
        { bSortable: false, bSearchable: false, sWidth: '11%', className: 'js-sched-budget-per-ad' },
        { bSortable: false, bSearchable: false, sWidth: '10%', className: 'js-sched-goal' },
        { bSortable: false, bSearchable: false, sWidth: '10%', className: 'js-sched-goal-per-ad' },
        { bSortable: false, bSearchable: false, sWidth: '7%',  className: 'js-sched-targeting' }
       ]
    )

    ad_scheduler_table.on 'focus', 'td.js-datepick input', (e) ->
      $('#datepicker_campaign_id').val($(this).data().campaignId)
      $('#datepicker_schedule_id').val($(this).data().scheduleId)

      $(this).datetimepicker
        minView: 2
        format: 'mm/dd/yyyy'
        todayHighlight: true
        autoclose: true

    ad_scheduler_table.on 'change', 'tbody tr td.js-datepick input', (e) ->
      e.preventDefault()
      $('.js-select-dates').attr('disabled', true)
      campaign_id = $('#datepicker_campaign_id').val()
      schedule_id = $('#datepicker_schedule_id').val()
      field = this.id
      value = this.value

      $.ajax
        url: "/admin/campaigns/#{campaign_id}/promoter/save_facebook_ad_set_schedule?schedule_id=#{schedule_id}&#{field}=#{value}"
        method: 'POST'
        dataType: 'json',
        contentType: 'application/json'
        success: (data) ->
          $('#ui_notifIt').remove()
          $('#promoter-scheduler-view').DataTable().ajax.reload () ->
            $('.js-select-dates').removeAttr('disabled')
        error: (data) ->
          notif(
            msg: 'Date Update Failed'
            type: 'error'
            timeout: 3000
          )
          $('#promoter-scheduler-view').DataTable().ajax.reload () ->
            $('.js-select-dates').removeAttr('disabled')


    container = this
     ##Targeting Modal

    populate_targeting_modal = (data, copy) ->
      if(copy)
        $('#targeting_data').val(JSON.stringify(data))
      container.initialize_paste_areas()

      geo_location = Object.keys(data.targeting['geo_locations'])[0]
      $('#geo-location-dropdown').select2('val', geo_location)
      $('#countries-section, #geo_markets-section, #regions-section, #zips-section').addClass('is-hidden')

      if(geo_location == "zips")
        unformatted_zip_codes = []
        zip_codes = data.targeting['geo_locations'][geo_location]
        unformatted_zip_codes = zip_codes.map((s) -> s['key'].substr(3))
        $('#zips-dropdown').val(unformatted_zip_codes.join(","))
      else
        $("##{geo_location}-dropdown").select2('val', data.targeting['geo_locations'][geo_location])

      $("##{geo_location}-section").removeClass('is-hidden')
      $('#genders-dropdown').select2('val', data.targeting.genders)
      $('#age-min').val(data.targeting.age_min)
      $('#age-max').val(data.targeting.age_max)
      $('#genders-dropdown').select2('val', data.targeting.genders)
      format_custom_audiences(data.targeting.custom_audiences)
      $('#detailed-targeting-selected').html('')
      $('#detailed-targeting-selected').select2('val', gather_targeting_details(data.targeting))

      if(copy)
        $('#targeting_modal_schedule_id').val(data.scheduleId)
        $('#targeting_modal_campaign_id').val(data.campaignId)

      $('#targeting_selector_modal_error_section').addClass('is-hidden')
      $('#targeting_selector_modal').modal('show')

    $('body').on 'change', '#geo-location-dropdown', (e) ->
      geo_location = $('#geo-location-dropdown').val()
      $('#countries-section, #geo_markets-section, #regions-section, #zips-section').addClass('is-hidden')
      if(geo_location == "zips")
        $('#zips-dropdown').val('')
      else
        $("##{geo_location}-dropdown").select2("val", "")
      $("##{geo_location}-section").removeClass('is-hidden')


    $('body').on 'click', '.js-open-targeting-pick-btn', (e) ->
      e.preventDefault()
      populate_targeting_modal($(this).data(), true)

    save_targeting_modal = (close, copyTargetingToClipboard) ->
      $("#targeting_selector_modal_errors").html("")
      $('#targeting_selector_modal_error_section').addClass('is-hidden')
      save_obj = {
                  'genders':$('#genders-dropdown').val(),
                  'age_min':$('#age-min').val(),
                  'age_max':$('#age-max').val(),
                  'custom_audiences': format_custom_audiences_to_fb(),
                 }

      errors = []
      location_type = $('#geo-location-dropdown').val()
      formatted_zips = []
      if(location_type == "zips")
        zips = $('#zips-dropdown').val()
        if(zips.length > 0)
          #validate that the string is in the right format
          format = zips.match(/^\d{5}(?:,\d{5})+$/)
          if (format != null && format.length > 0)
            #validate that there is not anymore than 2500 zip codes
            if(zips.match(/\d+/g).length <= 2500)
              for zip in zips.split(',')
                if(isNaN(zip))
                  errors.push("Invalid Zip Code(s)")
                  break
                formatted_zips.push({"key":"US:#{zip}"})
                locations = formatted_zips
            else
              errors.push("Exceeds 2500 limit for Zip Code(s)")
          else
            errors.push("Invalid Format Zip Code(s) comma delimited no spaces")
        else
          locations = []
      else
        locations = $("##{location_type.toLowerCase()}-dropdown").val()

      save_obj = push_detailed_targeting_to_obj($('#detailed-targeting-selected').val(), save_obj)

      if !$.isArray(locations) && locations != null
        locations = [locations]

      save_obj['geo_locations'] = {}
      if locations != null
        save_obj['geo_locations'][location_type] = locations
      else
        save_obj['geo_locations'][location_type] = []

      if location_type == 'geo_markets' && save_obj['geo_locations'][location_type].length == 0
        errors.push("DMA(s) cannot be blank")
      else if location_type == 'regions' && save_obj['geo_locations'][location_type].length == 0
        errors.push("State(s) cannot be blank")
      else if location_type == 'countries' && save_obj['geo_locations'][location_type].length == 0
        errors.push("Country cannot be blank")
      else if location_type == 'zips' && save_obj['geo_locations'][location_type].length == 0
        errors.push("Zip Code(s) cannot be blank")

      if save_obj['genders'] == null || save_obj['genders'].length == 0
        errors.push("Gender cannot be blank")

      if save_obj['age_min'] != null && save_obj['age_min'] < 18 || save_obj['age-min'] > 65
        errors.push("Minimum age should be between 18 and 65")

      if save_obj['age_max'] != null && save_obj['age_max'] < 18 || save_obj['age_max'] > 65
        errors.push("Maximum age should be between 18 and 65")

      if errors.length == 0
        schedule_id = $('#targeting_modal_schedule_id').val()
        campaign_id = $('#targeting_modal_campaign_id').val()
        $.ajax
          url: "/admin/campaigns/#{campaign_id}/promoter/facebook_ad_targeting_edit?schedule_id=#{schedule_id}"
          data: JSON.stringify(save_obj)
          dataType: 'json',
          contentType: 'application/json',
          method: 'POST'
          success: (data) ->
            $('#targeting_data').val(JSON.stringify(data))
            if copyTargetingToClipboard
              copyToClipboard($('#targeting_data').val())
            $('#promoter-scheduler-view').DataTable().ajax.reload()
          error: (data) ->
            notif(
              msg: data.responseJSON.error
              type: 'error'
              timeout: 3000
            )
            $('#promoter-scheduler-view').DataTable().ajax.reload()
        if close
          $('#targeting_selector_modal').modal('hide')

      else
        $('#targeting_selector_modal_errors li').addClass('is-hidden')
        $.each(errors, (indx, val) ->
          new_error = "<li>#{val}</li>"
          $("#targeting_selector_modal_errors").append(new_error)
        )
        $('#targeting_selector_modal_error_section').removeClass('is-hidden')

      return errors.length > 0

    $('body').on 'click', '#apply_selected_targeting', (e) ->
      e.preventDefault()
      save_targeting_modal(true, false)

    fadeOutCheckmark = (el, action, error) ->
      button = $('#' + el)
      if( error )
        button.html("<i class='fas fa-times' style='color:red' />")
      else
        button.html("<i class='fas fa-check' style='color:green'/>")

      $('#paste_targeting').attr("disabled", "disabled")
      $('#copy_targeting').attr("disabled", "disabled")
      setTimeout (->
        button.html(action)
        $('#paste_targeting').removeAttr("disabled")
        $('#copy_targeting').removeAttr("disabled")
      ), 1000

    copyToClipboard = (str) ->
      el = document.createElement('textarea')
      el.value = str
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

    $('body').on 'click', '#copy_targeting', (e) ->
      e.preventDefault()
      errors = save_targeting_modal(false, true)
      if(errors)
        fadeOutCheckmark('copy_targeting', 'Copy', true)
      else
        fadeOutCheckmark('copy_targeting', 'Copy')

    $('body').on 'click', '#paste_targeting', (e) ->
      e.preventDefault()
      navigator.clipboard.readText().then (data) ->
        try
          targeting = JSON.parse(data)
          populate_targeting_modal(targeting, false)
          save_targeting_modal(false, false)
          fadeOutCheckmark('paste_targeting', 'Paste')
        catch
          fadeOutCheckmark('paste_targeting', 'Paste', true)

    $('body').on 'click', '#cancel_selected_targeting', (e) ->
      e.preventDefault()
      $('#targeting_selector_modal').modal('hide')

    $('body').on 'click', '#detailed-targeting-search-button', (e) ->
      e.preventDefault()
      $('#detailed-targeting-options').autocomplete
        source: ( request, response ) ->
          $.getJSON "#{window.location.pathname}/retrieve_detailed_targeting/#{request.term}", {}, response
        focus: () ->
          return true
        minLength: 1000
        select: (event, ui) ->
          select = $('#detailed-targeting-selected')
          key = "#{ui.item.name}(#{ui.item.type})"
          if(select.val() != null)
            selected_values = select.val()
            if(!selected_values.includes(key))
              selected_values.push(key)
              select.append('<option data-fbid="'+ui.item.id+'" data-name="'+ui.item.name+'" data-type="'+ui.item.type+'" value="'+key+'">'+key+'</option>')
              select.select2('val', selected_values)
          else
            select.append('<option data-fbid="'+ui.item.id+'" data-name="'+ui.item.name+'" data-type="'+ui.item.type+'" value="'+key+'">'+key+'</option>')
            select.select2('val', key)

          return false
        close: (event, ui) ->
          $('#detailed-targeting-options').autocomplete('option', 'minLength', 1000)

      $('#detailed-targeting-options').autocomplete('option', 'minLength', 0)
      $('#detailed-targeting-options').autocomplete('search')

    # FACEBOOK AD CAMPAIGN
    @create_ad_datatable = ( table_id ) ->
      return DataTableUtils.datatable($(table_id),
        bServerSide: true
        iDisplayLength: 25
        sScrollX: '100%'
        searching: false
        rowId: 'id'
        aoColumns: [
          { data: 'facebook_ad_set_id', bSortable: false, bSearchable: false, sWidth: '15%' },
          {
            className: 'js-fb-ad-notes fb-ad-notes',
            orderable: false,
            data: null,
            defaultContent: '',
            render: () -> '<i class="fas fa-plus" aria-hidden="true"></i>',
            width: '5%'
          }
          { data: 'ad_set_name', bSortable: false, bSearchable: false, sWidth: '21%' },
          {
            className: 'js-datefield',
            orderable: false,
            data: 'start_date',
            defaultContent: '',
            mRender: (data, type, full ) ->
              disabled = if full['published'] == true then 'disabled' else null
              return '<input type="text" id="startdate" value='+full['start_date']+' size=10 '+disabled+' >'
            ,
            width: '9%'
          },
          {
            className: 'js-datefield',
            orderable: false,
            data: 'end_date',
            defaultContent: '',
            mRender: (data, type, full ) ->
              disabled = if full['published'] == true then 'disabled' else null
              return '<input type="text" id="enddate" value='+full['end_date']+' size=10 '+disabled+' >'
            ,
            width: '9%'
          },
          { data: 'status', bSortable: false, bSearchable: false, sWidth: '10%' },
          { data: 'billing_event', bSortable: false, bSearchable: false, sWidth: '11%' },
          { data: 'budget', bSortable: false, bSearchable: false, sWidth: '11%' },
          { data: 'goal', bSortable: false, bSearchable: false, sWidth: '9%' },
          {
            data: 'actions',
            bSortable: false,
            bSearchable: false,
            sWidth: '5%',
            className: 'js-fb-ad-actions fb-ad-actions',
            defaultContent: '',
            mRender: (data, type, full ) ->
              if full['facebook_ad_set_id'] == null then '<i class="fas fa-trash" aria-hidden="true"/>' else null
          },
          { data: 'notes', bSortable: false, bSearchable: false, bVisible: false }
        ]
      )

  facebook_ad_notes = ( d ) ->
    if d.notes == null
      d.notes = ''
    return '<table class="js-notes-row fb-ad-notes"><tr><td><input type="text" id="'+d.id+'-notes" name="notes" value="'+d.notes+'" size="180"></td></tr></table>'

  notes_listener = (element, datatable) ->
    tr = element.closest('tr')
    tdi = tr.find("i.fas")
    row = datatable.row( tr )

    if row.child.isShown()
      # row is already shown - hide it
      row.child.hide()
      tdi.first().removeClass('fa-minus')
      tdi.first().addClass('fa-plus')
    else
      # show this row
      child = row.child(facebook_ad_notes(row.data()))
      child.show()
      tdi.first().removeClass('fa-plus')
      tdi.first().addClass('fa-minus')

  remove_ad_set_row = (element, datatable) ->
    element.removeClass('fa-trash').addClass('fa-circle-notch fa-spin')
    campaign_id = /\d+/g.exec(location.pathname)
    tr = element.closest('tr')
    id = tr[0].id
    data = {ad_set_id: id}

    $.ajax
      url: "/admin/campaigns/#{campaign_id}/promoter/delete_facebook_ad_set",
      method: 'POST',
      dataType: 'json',
      data: JSON.stringify(data),
      contentType: 'application/json'
      success: (data) ->
        if data.status != 200
          element.removeClass('fa-circle-o-notch fa-spin').addClass('fa-trash')
          notif(
            msg: data.error
            type: 'error'
            timeout: 3000
          )
        else
          $('#promoter-facebook-ad-campaign-view').DataTable().ajax.reload()
          notif(
            msg: 'Facebook Ad Set Has Been Deleted'
            type: 'notice'
            timeout: 3000
          )
      error: (data) ->
        element.removeClass('fa-circle-o-notch fa-spin').addClass('fa-trash')
        notif(
          msg: 'Facebook Ad Set Delete Failed'
          type: 'error'
          timeout: 3000
        )

  gather_save_data = (table, type) ->
    rowdata = []
    table.rows().every( () ->
      row = { "id":this.data().id, "notes":this.data().notes, "start_date":this.data().start_date, "end_date":this.data().end_date }
      rowdata.push row
    )
    return save_data = { "rowdata":rowdata, "type":type }

  @facebook_ad_campaign_datatable: ->
    ad_campaign_table = @create_ad_datatable('#promoter-facebook-ad-campaign-view')

    # event listener - show date picker - ad
    ad_campaign_table.on 'focus', 'td.js-datefield input', (e) ->
      $(this).datetimepicker
        minView: 2
        format: 'mm/dd/yyyy'

    # event listener - show / hide notes - ad
    ad_campaign_table.on 'click', 'td.js-fb-ad-notes', (e) ->
      notes_listener $(this), ad_campaign_table

    # event listener - update notes - ad
    ad_campaign_table.on 'change', '.js-notes-row td input', (e) ->
      tr = $(this.closest('.js-notes-row').closest('tr')).prev()
      row = ad_campaign_table.row(tr)
      row.data().notes = this.value

    # event listener - update dates - ad
    ad_campaign_table.on 'change', 'tbody tr td.js-datefield input', (e) ->
      tr = $(this.closest('tr'))
      row = ad_campaign_table.row(tr)
      if this.id == 'startdate'
        row.data().start_date = this.value
      else if this.id == 'enddate'
        row.data().end_date = this.value

    # event listener - delete ad set row - ad
    ad_campaign_table.on 'click', 'td.js-fb-ad-actions .fa-trash', (e) ->
      remove_ad_set_row $(this), ad_campaign_table

    save_ad_campaign = (type, e) ->
      e.preventDefault()
      $(this).addClass('disabled')
      campaign_id = /\d+/g.exec(location.pathname)
      savePath = "/admin/campaigns/#{campaign_id}/promoter/save_facebook_ad_campaign"
      saveData = gather_save_data(ad_campaign_table, type)

      $.ajax
        url: savePath,
        method: 'POST',
        dataType: 'json',
        data: JSON.stringify(saveData),
        contentType: 'application/json'
        success: (data) ->
          $('#ui_notifIt').remove()
          if type == 'ad'
            $('#promoter-facebook-ad-campaign-view').DataTable().ajax.reload()
          else if type == 'video'
            $('#promoter-facebook-video-campaign-view').DataTable().ajax.reload()
          $('#promoter_ad_notify_cs').modal('show')
          error: (data) ->
            if type == 'ad'
              message = 'Facebook Ad Campaign Save Failed'
            else if type == 'video'
              message = 'Facebook Video Ad Campaign Save Failed'
            notif(
              msg: message
              type: 'error'
            )
      $(this).removeClass('disabled')

    # event listener - click save button - ad
    $('#fb-ad-campaign-save-btn').on 'click', (e) ->
      save_ad_campaign("ad", e)

    # event listener - click save button - video
    $('#fb-video-campaign-save-btn').on 'click', (e) ->
      save_ad_campaign("video", e)

    #event listener - notify CS of changes
    $("#notify_cs_btn").on 'click', (e) ->
      e.preventDefault()
      campaign_id = /\d+/g.exec(location.pathname)
      notifyURL = "/admin/campaigns/#{campaign_id}/notify_campaign_supervisors"

      $.ajax
        url: notifyURL,
        method: 'POST'
        success: (data) ->
          notif(
            msg: 'Client Services has been notified'
            type: 'notice'
          )
        error: (data) ->
          notif(
            msg: 'An error occured while notifying CS'
            type: 'error'
          )

    # facebook_video_campaign_datatable
    video_campaign_table = @create_ad_datatable('#promoter-facebook-video-campaign-view')

    # event listener - show date picker - video
    video_campaign_table.on 'focus', 'td.js-datefield input', (e) ->
      $(this).datetimepicker
        minView: 2
        format: 'mm/dd/yyyy'

    # event listener - show / hide notes - video
    video_campaign_table.on 'click', 'td.js-fb-ad-notes', (e) ->
      notes_listener $(this), video_campaign_table

    # event listener - update notes - video
    video_campaign_table.on 'change', '.js-notes-row td input', (e) ->
      tr = $(this.closest('.js-notes-row').closest('tr')).prev()
      row = video_campaign_table.row(tr)
      row.data().notes = this.value

    # event listener - update dates - video
    video_campaign_table.on 'change', 'tbody tr td.js-datefield input', (e) ->
      tr = $(this.closest('tr'))
      row = video_campaign_table.row(tr)
      if this.id == 'startdate'
        row.data().start_date = this.value
      else if this.id == 'enddate'
        row.data().end_date = this.value

    # event listener - click generate button
    $('#fb-ad-campaign-generate-btn').on 'click', (e) ->
      e.preventDefault()
      $(this).addClass('disabled')
      campaign_id = /\d+/g.exec(location.pathname)
      theUrl = "/admin/campaigns/#{campaign_id}/promoter/generate_facebook_ad_campaign"
      $('#ui_notifIt').remove()
      $.ajax
        url: theUrl,
        method: 'POST'
        data: { facebook_ad_account_id: $('#facebook-ad-account-dropdown').val() }
        success: (data) ->
          if data.status == 510 || data.status == 520 || data.status == 406
            notif(
              msg: 'Facebook Ad Campaign Generation Failed: ' + data.error
              type: 'error'
            )
          else
            notif(
              msg: 'Facebook Ad Campaign Generated'
              type: 'notice'
            )
            # reload the datatable after campaign generated
            ad_campaign_table.ajax.reload()
            if data.ad_campaign_name
              $('#fb-ad-campaign-name').text(data.ad_campaign_name)
              $('#facebook_ad_campaign').removeClass('is-hidden')
            else
              $('#fb-ad-campaign-name').text('')
              $('#facebook_ad_campaign').addClass('is-hidden')

            video_campaign_table.ajax.reload()
            if data.video_campaign_name
              $('#fb-video-campaign-name').text(data.video_campaign_name)
              $('#facebook_video_campaign').removeClass('is-hidden')
            else
              $('#fb-video-campaign-name').text('')
              $('#facebook_video_campaign').addClass('is-hidden')

        error: (data) ->
          notif(
            msg: 'Facebook Ad Campaign Generation Failed'
            type: 'error'
          )
      $(this).removeClass('disabled')

  @initialize_paste_areas: ->
    dropdown_paste_listener = (event) ->
      # Prevent the text from printing in the field
      event.preventDefault()
      # Pull the keyboard data from the event
      rawData = event.originalEvent.clipboardData.getData('text')
      rows = rawData.split(/[\n\r][\n\r]?/).map((s) -> s.toLowerCase().trim())
      # Grab the select box and get the current state
      targetSelect = $(this).parent().find('select')
      currentOptions = $(targetSelect).val() || []
      # Pull all of the possible values out of the select
      rawOptions = targetSelect.find('option')
      possibleValues = []
      for rawOption in rawOptions
        possibleValues.push(
          text: $(rawOption).text().toLowerCase().trim()
          value: $(rawOption).val()
        )
      # Match the options that we can
      matchedOptions = []
      for row in rows
        for option in possibleValues
          matchedOptions.push(option.value) if option.text == row
      # Remove duplicates
      newOptions = _.uniq(matchedOptions.concat(currentOptions))
      # Update the value of the select and refresh the on-screen view
      $(targetSelect).val(newOptions)
      $(targetSelect).trigger('change')

    checkZip = (zip) ->
      !isNaN(zip) && zip != ""

    input_paste_listener = (event) ->
      # Prevent the text from printing in the field
      event.preventDefault()
      $('#loading').removeClass('is-hidden')
      # Pull the keyboard data from the event
      rawData = event.originalEvent.clipboardData.getData('text')
      rows = rawData.split(/[\n\r][\n\r]?/).filter(checkZip)
      rows = rows.map((s) -> s.replace(/[\n\r]/g, '') )

      targetSelect = $(this)
      setTimeout ->
        if(targetSelect.val() != "")
          currentOptions = targetSelect.val().split(',')
        else
          currentOptions = []

        currentOptions = rows.concat(currentOptions)
        # Remove duplicates
        newOptions = _.uniq(currentOptions)
        # Update the value of the select and refresh the on-screen view
        targetSelect.val(newOptions.join(","))
        $('#loading').addClass('is-hidden')

    dropdowns = $('.dropdown-can-paste')
    for dropdown in dropdowns
      $(dropdown).on('paste', dropdown_paste_listener)
    setTimeout ->
      inputs = $('.input-can-paste')
      for input in inputs
        $(input).on('paste', input_paste_listener)

  $('#paid-media-button').on 'click', (e) ->
    e.preventDefault()
    $('#paid-media-budget').toggle()
    icon = '<i class="fas fa-tasks btn--icon-left"></i>'
    shown = $('#paid-media-budget').css('display') == 'block'
    label = if shown then 'Hide' else 'Show'
    $(this).html(icon + label + ' Budget')
