import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash'
import SubmissionAnnotationRow from './AnnotationRow'

class SubmissionAnnotationDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.render = this.render.bind(this);
  }

  render() {
    const todo = this.props.annotations.filter(an => !an.done);
    const done = this.props.annotations.filter(an => an.done);

    return <div className='submission annotation-drawer'>
      {
        todo.length !== 0 &&
        <React.Fragment>
          <div className='requested-changes'>
            {todo.length} Todo
          </div>
          {todo.map(an => <SubmissionAnnotationRow annotation={an} key={an.id}/>)}
        </React.Fragment>
      }
      {
        done.length !== 0 &&
        <React.Fragment>
          <div className='requested-changes'>
            {done.length} Done
          </div>
          {done.map(an => <SubmissionAnnotationRow annotation={an} key={an.id}/>)}
        </React.Fragment>
      }
    </div>;
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      annotations: _.get(state.bloggerShopTask, 'content.draft.annotations') || []
    };
  }
};

SubmissionAnnotationDrawer.displayName = 'Submission.AnnotationDrawer';
export default connect(
  SubmissionAnnotationDrawer.mapStateToProps,
  SubmissionAnnotationDrawer.mapDispatchToProps
)(SubmissionAnnotationDrawer)
