import React from 'react';
import { Remote } from '../utils';

import SingleSelect from '../SingleSelect';
export default class UserCommunitySelection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentCommunity: this.props.currentCommunity,
      userCommunities: [],
      loading: true,
      updatedFailed: false
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleCommunityChange = this.handleCommunityChange.bind(this);
    this.loadUserCommunities = this.loadUserCommunities.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount(){
    this.loadUserCommunities()
  }

  handleCommunityChange(id){
    const value = this.state.userCommunities.find(x => x.id === id);
    const params = { api_action: `/users/${this.props.currentUserId}/communities/${value.id}/activate` };

    Remote().request({
      method: 'PATCH',
      url: this.props.proxyUrl,
      params: params,
    }).then(response => {
      this.setState({
        currentCommunity: response.data,
        updatedFailed: false
      });
      window.location.href = this.props.redirectUrl;
    }).catch(() => {
      this.setState({
        updatedFailed: true
      });
    });
  }

  loadUserCommunities(){
    const params = { api_action: `/users/${this.props.currentUserId}/communities` };

    Remote().request({
      url: this.props.proxyUrl,
      method: 'GET',
      params: params
    }).then(response => {
      this.setState({
        userCommunities: response.data,
        loading: false
      });
    }).catch(() => {
      this.setState({
        updatedFailed: true
      });
    });
  }

  render() {
    const {
      currentCommunity,
      userCommunities,
      updatedFailed
    } = this.state;

    if (updatedFailed)
      return <small>Failed to load communities.</small>

    if(this.state.loading || userCommunities.length <= 1) {
      return null;
    }
    const search = (query, callback) => callback(userCommunities);
    return <div className='user-community-selection'>
      <small>Community:</small>
      <SingleSelect
        placeholder=''
        selected={currentCommunity.id}
        search={search}
        onUpdate={this.handleCommunityChange}
        preload={userCommunities}
        clearDisabled={true}
        showCaret={true}
        />
    </div>
  }
};

UserCommunitySelection.displayName = 'Communities.UserCommunitySelection';
