class window.NoteUtils

  @noteable: ->
    that = this
    $('body').on 'click', '#note-toggle', ->
      icon = $(this).children('i')
      icon.toggleClass('fa-toggle-down fa-toggle-up')
      $('.notes-container').slideToggle()

    $('body').on 'click', 'a#new_note', (e) ->
        e.preventDefault()
        panel = $('.notes-list')
        if panel.find('form#new_note').length == 0
          note = new Note( $(this).data('note') )
          form = note.html
          form.hide()
          panel.prepend( form )
          bind_links( note )
          form.slideDown()
          note.body.focus()

    bind_links = (note) ->
      note.submit_btn.attr('disabled', true)
      note.body.keyup ->
        if empty($(this))
          note.submit_btn.attr('disabled', true)
        else
          note.submit_btn.attr('disabled', false)

      note.cancel_btn.click (e) ->
        e.preventDefault()
        note.remove()

      note.complete()

    empty = (input) ->
      if input.val() == ''
        true
      else
        false

  @refresh_notes: ( selector, n_type, n_id ) ->
    $(selector).load "#{location.origin}/notes.js?n_type=#{n_type}&n_id=#{n_id}"

  @create: (noteable_type, noteable_id, body) ->
      $.post("#{location.origin}/notes.js",
      note:
        noteable_type: noteable_type,
        noteable_id: noteable_id,
        body: body
      )

  class window.Note

    constructor: (note_form) ->
      @html          = $(note_form)
      @id            = @html.find('#note_noteable_id')
      @type          = @html.find('#note_noteable_type')
      @user_id       = @html.find('#note_user_id')
      @body          = @html.find('#note_body')
      @submit_btn    = @html.find('#save_note')
      @cancel_btn    = @html.find('#cancel_note')

      time = "Today at #{new Date().toString("h:mm tt").toLowerCase()}"
      @html.find('.note-posted-at').html(time)

    complete: ->
      that = this
      @html.on 'ajax:complete', ->
        $(this).find('.note-content').html(that.body.val())
        cur_count = parseInt($('#note-count').text())
        $('#note-count').text(cur_count + 1)

    remove: ->
      @html.slideUp ->
        $(this).remove()

