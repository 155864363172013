import React from 'react';
import { connect } from 'react-redux';
import RequirementChecklist from './RequirementChecklist'

class ContentContainer extends React.Component {

  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
  };

  componentDidMount() {
    this.props.setMeta( _.pickBy(this.props, (_val, key) => !_.includes(['meta', 'bloggerShopTask'],key)));
    this.props.setDashboardVisiblity(this.props.dashboardVisible)
  }


  render() {
    const task = this.props.bloggerShopTask
    const hasTask = (Object.keys(task).length > 0)

    return (
      <div className="content-view">
        { !hasTask && <h3 className='text-center'>loading content....</h3> }
        { hasTask && <br/> }
        <div className='row'>
          { (hasTask || this.props.meta.proxyUrl) &&
            <RequirementChecklist/>
          }
        </div>
      </div>
    );
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      meta: state.meta,
      bloggerShopTask: state.bloggerShopTask
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      setMeta: meta => dispatch({ type: 'SET_META', value: meta }),
      toggleSubmitting: () => dispatch({ type: 'TOGGLE_SUBMITTING'}),
      updateRequirementValidation: requirement => dispatch({ type: 'UPDATE_REQUIREMENT_VALIDATION', value: requirement }),
      updateStory: story => dispatch({ type: 'UPDATE_STORY', value: story}),
      setDashboardVisiblity: (bool) => dispatch({ type: 'DASHBOARD_VISIBILITY', value: {bool} }),
    };
  }
};

ContentContainer.displayName = 'Contents.ContentContainer';

export default connect(
  ContentContainer.mapStateToProps,
  ContentContainer.mapDispatchToProps
)(ContentContainer);

