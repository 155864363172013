import React from 'react';

export default class HeaderBaseMenu extends React.Component {
  render(){
    const {user_id, user_name, profile_url, masquerading, admin_stop_masquerade_path} = this.props;
    return(
      <div className="header-base-menu">
        <div className="header-base-menu-navatar">
          <a href="/profile" className='icon-button'>
            <div className="navatar" style={{ backgroundImage: `url(${profile_url})`}}>
            </div>
            <div className="user_name">{user_name}</div>
          </a>
        </div>
        <div className="header-base-menu-items">
          <div className="row nav-item">
            <a href='/profile' className='icon-button'>Profile</a>
          </div>
          <div className="row nav-item">
            <a href={`/members/${user_id}/settings`} className='icon-button'>Settings</a>
          </div>
          <div className="row nav-item">
            <a href='/logout' className='icon-button'>Logout</a>
          </div>
          <div className="row nav-item">
            { masquerading &&
              <div className='mt-10 mb-10'>
                <a href={admin_stop_masquerade_path}
                   className='btn btn-danger btn-xs'
                   style={{color: 'white'}}>
                  Stop Masquerading
                </a>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

HeaderBaseMenu.displayName = 'Header.BaseMenu';
