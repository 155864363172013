import React from 'react';
import UserSignUpValidatedForm from './ValidatedForm';
import UserSignUpInfoAccordion from './InfoAccordion';
import { UserSignUpBasicInfoFields } from './fields/BasicInfo';

export default class UserSignUpBasicInfo extends React.Component {
  constructor(props) {
    super(props);

    this.form = new UserSignUpValidatedForm(this, UserSignUpBasicInfoFields);
  }

  componentWillMount(){
    this.form.mount();
  }

  render(){
    const { heading, accordion, country } = this.props
    const isCanadian = country == 'Canada'

    return (
      <div>
        <div className='column'>
          <h4>{heading}</h4>
          <div className='form'>
            <div className='column'>
              { this.form.renderField('dob') }
              { this.form.renderField('firstName') }
              { this.form.renderField('lastName') }
              { this.form.renderField('email') }
              { this.form.renderField('phoneNumber') }
            </div>
            <div className='column'>
              { this.form.renderField('address1') }
              { this.form.renderField('address2') }
              { this.form.renderField('city') }
              { this.form.renderField('country') }
              <div className='form_input_wrapper children_2'>
                { !isCanadian && this.form.renderField('state') }
                { isCanadian && this.form.renderField('province') }
                { isCanadian && this.form.renderField('caZip') }
                { !isCanadian && this.form.renderField('usZip') }
              </div>
            </div>
          </div>
        </div>
        <div className='column'>
          { Object.keys(accordion).map((key) => {
            return <UserSignUpInfoAccordion key={key} title={accordion[key].title} body={accordion[key].body} />
          })}
        </div>
      </div>
    );
  }
}