import React from 'react';
import LivingStyleGuideColors from './Colors'
import LivingStyleGuideTypography from './Typography'
import LivingStyleGuideButtons from './Buttons'
import LivingStyleGuideAlerts from './Alerts'
import LivingStyleGuideCards from './Cards'
import LivingStyleGuideForms from './Forms'
import LivingStyleGuideTooltips from './Tooltips'



export default class LivingStyleGuideIndex extends React.Component {

  render() {
    return (
      <div>
        <div className='container style-guide' >
          <LivingStyleGuideColors />
          <LivingStyleGuideTypography />
          <LivingStyleGuideButtons />
          <LivingStyleGuideAlerts />
          <LivingStyleGuideCards />
          <LivingStyleGuideForms />
          <LivingStyleGuideTooltips />
        </div>
      </div>
    );
  }
}
LivingStyleGuideIndex.displayName = 'LivingStyleGuide.Index';


