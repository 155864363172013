import React from 'react';
import Toggle from '../../../Toggle'

export default class CampaignsRequirementsInstructionNotesReorderSwitch extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }
  
  render(){
    return (
      <div className='reorder_switch'>
        <i className='fas fa-sort'></i>
        <label className='reorder_switch_label'>Reorder</label>
        <Toggle
          checked={this.props.enableReorderSwitch}
          disabled={this.props.disableReorderSwitch}
          onUpdate={() => this.props.reorderButtonToggle()}
        />
      </div>
    );
  }
}

CampaignsRequirementsInstructionNotesReorderSwitch.displayName = 'Campaigns.Requirements.InstructionNotesReorderButton';
