import React from 'react';
import { Remote } from '../utils';

import Tooltip from '../Tooltip'

export default class RoundsCustomTaskButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltip: {
        button: null,
        visible: false
      }
    }

    this.addTask = this.addTask.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.render = this.render.bind(this);
  }

  render() {
    const { tooltip: { button, visible } } = this.state;
    const disabled = this.props.disabled ? 'disabled' : ''

    return (
    <div className={`btn task_button ${disabled}`} disabled={this.props.disabled || false} onClick={this.addTask} onMouseLeave={() => this.toggleTooltip('task')} onMouseEnter={() => this.toggleTooltip('task')}>
      <i className='fas fa-plus'></i>
        &nbsp; Task
      { (visible && button === 'task') && <Tooltip centerText={true} placement='bottom' message={this.props.I18n.tasks.tooltips.taskButton}/>  }
    </div>
    );
  }

  toggleTooltip(button) {
    this.setState({
      tooltip: {
        button: button,
        visible: !this.state.tooltip.visible
      }
    })
  }

  addTask(e) {
    const params = $.param({
      api_action: '/round_tasks'
    });

    const payload = {
      round_id: this.props.round.id,
      template_type: 'instance',
      name: 'New Round Task',
      content_types: []
    };

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'POST',
      data: payload,
    }).then((response) => {
      this.props.addTask(this.props.round.id, response.data);
    }).catch((error) => {
      const data = JSON.parse(error.data.response.responseText);
      this.props.openNotification(
        'Failed to save Round change! Please try again:',
        'failure',
        data
      )
      setTimeout(this.props.closeNotification, 5000)
    });
  }
};
RoundsCustomTaskButton.displayName = 'Rounds.CustomTaskButton'



