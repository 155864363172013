import React from 'react';
import { connect } from 'react-redux';
import { Remote } from '../utils';
import _ from 'lodash'
import SubmissionPreview from './Preview'
import ProgressBar from '../ProgressBar'
import SubmissionInstagramMediaAttachment from './InstagramMediaAttachment'
import SubmissionManualContentSubmission from './ManualContentSubmission'
import SubmissionFacebookVideoContent from './content_types/FacebookVideoContent'
import SubmissionYoutubeContent from './content_types/YoutubeContent'
import SubmissionInstagramContent from './content_types/InstagramContent'
import SubmissionInstagramStoryContent from './content_types/InstagramStoryContent'
import SubmissionTiktokVideoContent from './content_types/TiktokVideoContent'
import SubmissionContentModal from './ContentModal'
import FailureCache from './FailureCache'

class SubmissionContentGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showUploadArea: false,
      loading: false,
      page: 1,
      displayModal: false,
      modalContent: null
    }

    this.validate = this.validate.bind(this);
    this.debouncedValidate = _.debounce(this.validate, 500);
    this.debouncedMediaAttachments = _.debounce(this.updateMediaAttachments, 500);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.fetchContent = this.fetchContent.bind(this);
    this.fetchMedia = this.fetchMedia.bind(this);
    this.deleteMedia = this.deleteMedia.bind(this);
    this.updateMediaAttachments = this.updateMediaAttachments.bind(this);
    this.toggleUploadArea = this.toggleUploadArea.bind(this);
    this.approvedInstagramStories = this.approvedInstagramStories.bind(this);
    this.render = this.render.bind(this);
    this.renderInstagramStoryAttachments = this.renderInstagramStoryAttachments.bind(this);
    this.showMoreContent = this.showMoreContent.bind(this);
    this.fetchContentCount = this.fetchContentCount.bind(this);
    this.contentCountApiAction = this.contentCountApiAction.bind(this);
    this.openContentModal = this.openContentModal.bind(this);
    this.closeContentModal = this.closeContentModal.bind(this);
  }

  componentDidMount() {
    const promises = [this.fetchContent(), this.fetchMedia()];

    // We need to pull a preview from the API for the beginning 'show' view
    if(this.props.bloggerShopTask.content && !!Object.keys(this.props.bloggerShopTask.content).length) {
      promises.push(this.fetchPreview());
    }

    Promise
      .all(promises)
      .catch(e => console.warn('ContentGrid network failure:', e.statusText, e.responseText))
      .finally(() => this.props.setLoading(false));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.contentType.name !== this.props.contentType.name) {
      this.fetchContent();
    }
  }

  renderInstagramStoryAttachments(stories) {
    return (
      stories.map((media, index) => (
        <SubmissionInstagramMediaAttachment key={index} media={media} deleteMedia={this.deleteMedia} readOnly={this.props.readOnly}/>)
      )
    )
  }

  render() {
    const { content, contentType, loading, instagramMediaAttachments } = this.props;
    const contentCount = (content && content.length) ? content.length : 0
    const submittedInstagramMediaAttachments = instagramMediaAttachments.filter( media => media.submitted_approved)
    const newInstagramMediaAttachments = instagramMediaAttachments.filter( media => !media.submitted_approved)
    const instagramMediaAttachmentsCount = (instagramMediaAttachments && instagramMediaAttachments.length) ? instagramMediaAttachments.length : 0;
    let type = () =>  {
      switch(contentType.name) {
      case 'FacebookVideoContent':
        return 'facebook-video';
      case 'YoutubeContent':
      case 'GoogleYoutubeContent':
        return 'youtube';
      case 'InstagramContent':
      case 'InstagramReelContent':
        return 'instagram';
      case 'InstagramStoryContent':
      case 'InstagramBusinessStoryContent':
        return 'instagram-story'
      case 'TiktokVideoContent':
        return 'tiktok-video'
      default:
        return '';
      }
    }
    if(this.props.readOnly) {
      return this.renderPreview();
    }
    if(loading) {
      return <ProgressBar />;
    } else if(this.props.readOnly && content.url) {
      return this.renderPreview();
    } else if(content && (contentCount || instagramMediaAttachmentsCount)) {
      return (
        <div>
          <div className={`submission content-grid content-count-${contentCount+instagramMediaAttachmentsCount} ${type()}`}>
            { this.renderInstagramStoryAttachments(submittedInstagramMediaAttachments) }
            { contentCount > 0 && content.map((post, index) => this.renderPostByType(post, index)) }
            { this.renderInstagramStoryAttachments(newInstagramMediaAttachments) }
          </div>
          {this.renderShowMoreButton()}
          {
            !this.props.readOnly &&
              <div className='text-center'>
                <SubmissionManualContentSubmission {...this.props} displayPreview={false}/>
              </div>
          }
          {this.state.displayModal &&
            <SubmissionContentModal
              children={this.state.modalContent}
              closeModal={this.closeContentModal}
              tiktok_oembed_js={this.props.tiktok_oembed_js}
              contentType={type()}
            />
          }
        </div>
      )
    } else {
      return (
        <div className='text-center'>
          <div>No content found. Please refresh the page and try again.</div>
          {
            !this.props.readOnly &&
              <SubmissionManualContentSubmission {...this.props}/>
          }
        </div>
      )
    }
  }

  renderPreview() {
    return <SubmissionPreview tiktok_oembed_js={this.props.tiktok_oembed_js} task={this.props.bloggerShopTask}/>;
  }

  openContentModal(content){
    this.setState({
      displayModal: true,
      modalContent: content
    });
  }

  closeContentModal(){
    this.setState({
      displayModal: false,
    });
  }

  toggleUploadArea(){
    this.setState({
      showUploadArea: !this.state.showUploadArea
    });
  }

  fetchMedia() {
    if (this.props.contentType.name !== "InstagramBusinessStoryContent") return;

    this.props.setInstagramMediaAttachments(
      _.filter(this.props.bloggerShopTask.instagram_business_story_medias, function(story) {
        return story.fallback_attachment != null && story.state !== 'rejected' }
      )
    )
    this.debouncedMediaAttachments();
  }

  deleteMedia(media) {
    const deleteMediaImagePath = `/contents/destroy_instagram_media?media_id=${media.id}`

    Remote().request({
      method: 'POST',
      url: deleteMediaImagePath,
    }).then((media) => {
      this.props.deleteInstagramMediaAttachment(media);
      this.debouncedMediaAttachments();
    });
  }

  updateMediaAttachments() {
    let ids = this.props.instagramMediaAttachments.map((media) => media.id);
    this.props.updateSubmission({ instagram_business_story_media_ids: ids });
  }

  renderPostByType(post, index) {
    switch(this.props.contentType.name) {
    case 'FacebookVideoContent':
      return <SubmissionFacebookVideoContent post={post} index={index} key={index} validate={this.debouncedValidate}/>;
    case 'YoutubeContent':
    case 'YoutubeShortContent':
    case 'GoogleYoutubeContent':
      return <SubmissionYoutubeContent post={post} index={index} key={index} validate={this.debouncedValidate}/>;
    case 'InstagramContent':
    case 'InstagramReelContent':
      return <SubmissionInstagramContent post={post} index={index} key={index} validate={this.debouncedValidate}/>;
    case 'InstagramStoryContent':
    case 'InstagramBusinessStoryContent':
      return <SubmissionInstagramStoryContent post={post} approvedPosts={this.approvedInstagramStories()}  index={index} key={index}/>;
    case 'TiktokVideoContent':
      return (
        <SubmissionTiktokVideoContent
          post={post}
          index={index}
          key={index}
          validate={this.debouncedValidate}
          openContentModal={this.openContentModal}
          disablePlaying={this.disablePlaying}
          task={this.props.bloggerShopTask}
        />
      );
    default:
      return null;
    }
  }

  approvedInstagramStories(){
    return this.props.bloggerShopTask.instagram_business_story_medias.filter( post => !post.rejected)
  }

  isInstagramStoryContent() {
    const storyTypes = ['InstagramStoryContent', 'InstagramBusinessStoryContent'];
    return storyTypes.includes(this.props.contentType.name)
  }

  renderShowMoreButton() {
    const {totalContentCount, content} = this.props;
    const hasMoreContent = totalContentCount > content.length;
    const {loading} = this.state
    const validContentTypes = ['InstagramStoryContent', 'InstagramBusinessStoryContent', 'TiktokVideoContent'];
    if (!validContentTypes.includes(this.props.contentType.name)) {
      return null;
    }
    return (
      <div className="show-more-container">
        <button className="btn primary" disabled={(loading || (!hasMoreContent))} onClick={this.showMoreContent}>
          {loading && <i className="fas fa-circle-notch fa-spin"></i>}
          Show More
        </button>
      </div>
    )
  }

  fetchContent() {
    this.props.setLoading(true);

    const params = $.param({
      api_action: `/blogger_shop_tasks/${this.props.bloggerShopTask.id}/api_contents`
    });

    const approvedInstagramStoryIds = this.approvedInstagramStories().map((media) => media.instagram_service_story_id)

    return Remote().request({
      method: 'POST',
      url: this.props.proxyUrl + '?' + params,
      data: {
        user_id: this.props.userId,
        type: this.props.contentType.name,
        submitted_content_ids: approvedInstagramStoryIds
      },
    }).then((response) => {
      if (response.data.status && response.data.status !== 200) return this.handleAuthFailure();
      if (response.data && response.data.content){
        this.props.setContent(response.data.content);
        this.fetchContentCount();
      } else {
        this.handleAuthFailure()
      }
    }).catch((error) => {
      this.handleAuthFailure()
    });
  }

  showMoreContent() {
    const { totalContentCount, loadMoreContent, bloggerShopTask, content } = this.props;
    const { page } = this.state;
    if (totalContentCount <= content.length) {
      return;
    }
    this.setState({loading: true})
    const params = $.param({
      api_action: `/blogger_shop_tasks/${bloggerShopTask.id}/api_contents`
    });

    return Remote().request({
      method: 'POST',
      url: this.props.proxyUrl + '?' + params,
      data: {
        user_id: this.props.userId,
        type: this.props.contentType.name,
        page: page
      },
    }).then((response) => {
      loadMoreContent(response.data.content);
      this.setState({loading: false, page: (page + 1) });
    }).catch(() => {
      this.setState({ loading: false });
      this.handleAuthFailure()
    });
  }

  fetchContentCount() {
    const validContentTypes = ['InstagramStoryContent', 'InstagramBusinessStoryContent', 'TiktokVideoContent'];
    if (!validContentTypes.includes(this.props.contentType.name)) {return}
    const { bloggerShopTask, setTotalContentCount } = this.props;
    setTotalContentCount(0);
    const params = $.param({
      api_action: `/blogger_shop_tasks/${bloggerShopTask.id}/${this.contentCountApiAction()}`
    });
    return Remote().request({
      method: 'POST',
      url: this.props.proxyUrl + '?' + params,
      data: {
        user_id: this.props.userId,
        type: this.props.contentType.name,
      },
    }).then((response) => {
      setTotalContentCount(response.data.count);
    }).catch(() => {
      this.handleAuthFailure()
    });
  }

  contentCountApiAction(){
    if (this.isInstagramStoryContent()){
      return 'story_count'
    } else if (this.props.contentType.name == 'TiktokVideoContent'){
      return 'tiktok_video_count'
    }
  }

  fetchPreview() {
    const params = $.param({
      api_action: `/blogger_shop_tasks/${this.props.bloggerShopTask.id}/preview`
    });

    return Remote().request({
      method: 'POST',
      url: this.props.proxyUrl + '?' + params,
      data: {
        type: this.props.contentType.name,
        id: this.props.bloggerShopTask.content.id,
        url: this.props.bloggerShopTask.content.url
      },
    }).then((response) => {
      this.props.setPreview(response.data);
    }).catch(() => {
      this.handleAuthFailure()
    }).then(() => { this.props.setLoading(false) });
  }

  handleAuthFailure() {
    const cache = new FailureCache(this.props.contentType.name);
    cache.addFailure();
    this.props.setAuthFailure(this.props.contentType.name);
  }

  validate() {
    const params = $.param({
      api_action: `/blogger_shop_tasks/${this.props.bloggerShopTask.id}/validate`
    });

    const contentData = {
      type: this.props.contentType.name,
      id: this.props.bloggerShopTask.content ? this.props.bloggerShopTask.content.id : null,
      user_id: this.props.userId,
      ...this.props.submission
    };

    Remote().request({
      method: 'POST',
      url: this.props.proxyUrl + '?' + params,
      data: contentData,
    }).then((response) => {
      this.props.setValidations(response.data);
    });
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      userId: state.meta.userId,
      bloggerShopTask: state.bloggerShopTask,
      contentType: state.contentType,
      proxyUrl: state.meta.proxyUrl,
      content: state.content || myProps.content,
      submission: state.submission,
      readOnly: state.readOnly,
      loading: state.loading,
      instagramMediaAttachments: state.instagramMediaAttachments || [],
      totalContentCount: state.totalContentCount,
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      setInstagramMediaAttachments: instagramMediaAttachments => dispatch({ type: 'SET_INSTAGRAM_MEDIA_ATTACHMENTS', value: instagramMediaAttachments}),
      addInstagramMediaAttachment: instagramMediaAttachment => dispatch({ type: 'ADD_INSTAGRAM_MEDIA_ATTACHMENT', value: instagramMediaAttachment}),
      deleteInstagramMediaAttachment: instagramMediaAttachment => dispatch({ type: 'DELETE_INSTAGRAM_MEDIA_ATTACHMENT', value: instagramMediaAttachment}),
      setContent: content => dispatch({ type: 'SET_CONTENT', value: content }),
      setValidations: validations => dispatch({ type: 'SET_VALIDATIONS', value: validations }),
      setPreview: preview => dispatch({ type: 'SET_PREVIEW', value: preview }),
      setLoading: loading => dispatch({ type: 'SET_LOADING', value: loading }),
      setContentUrl: change => dispatch({ type: 'SET_CONTENT_URL', value: change}),
      updateSubmission: change => dispatch({ type: 'UPDATE_SUBMISSION', value: change}),
      setAuthFailure: type => dispatch({ type: 'SET_AUTH_FAILURE', value: { type: type, flag: true } }),
      loadMoreContent: content => dispatch({ type: 'LOAD_MORE_CONTENT', value: content }),
      setTotalContentCount: count => dispatch({ type: 'SET_TOTAL_CONTENT_COUNT', value: count })
    };
  }
};

SubmissionContentGrid.displayName = 'Submission.ContentGrid';
export default connect(
  SubmissionContentGrid.mapStateToProps,
  SubmissionContentGrid.mapDispatchToProps
)(SubmissionContentGrid);
