import React from "react";

import MultiSelect from '../MultiSelect';

import UserFormSection from "./Section";
import UserFormCheckbox from "./Checkbox";

const UserFormDashboardAccess = ({
  accessDashboard, updateField, clientAccessOptions, clientAccess,
  campaignAccess, campaignSubaccess, communityAccess,
  communityAccessOptions, filterCampaignAccessOptions
  }) => {
  return <UserFormSection
    title="Dashboard Settings"
    disabled={!accessDashboard}
    fields={
      <div>
        <div className="mb-15">
          <label className="mb-0">Full Client Access</label>
          <small className="d-block mb-5 text-muted">
            User will have access to all data associated with the
            selected clients
          </small>
          <MultiSelect
            preload={clientAccessOptions}
            selected={clientAccess || []}
            search={(term, callback) => callback(clientAccessOptions.filter(item => item && item.name.toLowerCase().includes(term.toLowerCase())))}
            onUpdate={(value) => updateField('clientAccess', value)}
            clearInvalidInput={true}
            placeholder='Select'
          />
        </div>
        <div className="mb-5">
          <label className="mb-0">Full Campaign Access</label>
          <small className="d-block mb-5 text-muted">
            User will have access to all data associated with the
            selected campaigns
          </small>
          <MultiSelect
            preload={filterCampaignAccessOptions}
            selected={campaignAccess || []}
            search={(term, callback) => callback(filterCampaignAccessOptions.filter(item => item && item.name.toLowerCase().includes(term.toLowerCase())))}
            onUpdate={(value) => updateField('campaignAccess', value)}
            clearInvalidInput={true}
            placeholder='Select'
          />
          <UserFormCheckbox label='Allow user to filter by Opportunity'
            value={campaignSubaccess} onUpdate={() => updateField('campaignSubaccess', !campaignSubaccess)} />
        </div>
        <div className="mb-5">
          <label className="mb-0">Full Community Access</label>
          <small className="d-block mb-5 text-muted">
            User will have access to all data associated with the
            selected communities
          </small>
          <MultiSelect
            preload={communityAccessOptions}
            selected={communityAccess || []}
            search={(term, callback) => callback(communityAccessOptions.filter(item => item && item.name.toLowerCase().includes(term.toLowerCase())))}
            onUpdate={(value) => updateField('communityAccess', value)}
            clearInvalidInput={true}
            placeholder='Select'
          />
        </div>
      </div>
    }
  />;
};

export default UserFormDashboardAccess;
UserFormDashboardAccess.displayName = 'UserForm.DashboardAccess';