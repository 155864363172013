import React from 'react';

export default class Round extends React.Component {
  render(){
    const {activity, round_name, shopertunity_name} = this.props.notification;
    const P = React.Fragment;
    switch(activity) {
      case 'admin_instructions_rejected':
        return (
          <P>Instructions for <b>{round_name}</b> have been rejected.</P>
        )
      case 'admin_round_ready_to_be_verified':
        return (
          <P>All content is ready to be verified for <b>{shopertunity_name}</b> <br />
            <b>{round_name}</b></P>
        )
      case 'bid_negotiated':
        return(
          <P>Round bid for {shopertunity_name} - {round_name} needs your attention.</P>
        )
      default:
        return null;
    }
  }
}
Round.displayName = 'Notification.Templates.Round'