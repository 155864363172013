import React from 'react';
import MultiSelect from '../MultiSelect'

export default class DMASelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dmas: []
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.render = this.render.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    $.get(this.props.dmasEndpoint).then(data => {
      this.setState({
        dmas: data
      });
    });
  }

  render() {
    return (
      <div className='form-group'>
        <label>DMAs</label>
        <MultiSelect search={this.search}
                     selected={this.props.targetingValues}
                     onUpdate={(values) => this.props.updateLocationData('geo_markets', values)}/>
      </div>
    );
  }

  search(text, callback) {
    if(!text) {
      return callback(this.state.dmas);
    }

    callback(this.state.dmas.filter(x => x.name.toLowerCase().includes(text.toLowerCase())));
  }
}
