import React from 'react';

const TaskTemplateFields = {
  name: {
    name: 'name',
    label: 'Name',
    type: 'text',
    required: true,
    placeholder: 'Enter Task Name',
    error: false,
    value: null
  },
  content_type_ids: {
    name: 'content_type_ids',
    label: 'Available Content Type(s)',
    type: 'select',
    required: true,
    multiSelect: true,
    options: [],
    error: false,
    value: null
  },
  community_id: {
    name: 'community_id',
    label: 'Community',
    type: 'select',
    required: true,
    options: [],
    error: false,
    value: null
  },
  syndication: {
    name: 'syndication',
    label: 'Submission Type',
    type: 'radio',
    required: true,
    options: [
      { name: 'Primary', value: false },
      { name: 'Syndication', value: true }
    ],
    error: false,
    value: null
  },
  template: {
    name: 'template',
    type: 'hidden',
    value: true,
    error: false
  },
  template_type: {
    name: 'template_type',
    type: 'hidden',
    value: 'community',
    error: false,
  },
};

export default TaskTemplateFields;