import React from 'react';
import { Remote } from '../utils';

import SearchList from '../SearchList'

export default class RoundsAddTaskButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.addTask = this.addTask.bind(this);
    this.render = this.render.bind(this);
  }

  render() {
    const disabled = this.props.disabled ? 'disabled' : ''

    if(!this.state.open) {
      return <div className={`btn campaign_template_button ${disabled}`} disabled={this.props.disabled || false} onClick={() => this.setState({ open: true })}>
        <span className='left'>Add from Campaign</span> <i className='fas fa-caret-down'/>
      </div>;
    }

    return <SearchList
      startFocus={true}
      placeholder='Search campaign templates'
      style={{marginLeft: '15px'}}
      onSelect={this.addTask}
      onBlur={() => this.setState({ open: false })}
      elements={this.props.campaignTemplates}
      loading={this.props.loadingTemplates}
      />;
  }

  addTask(template) {
    this.setState({ open: false });

    const params = $.param({
      api_action: `/round_tasks/${template.id}/clone/round/${this.props.round.id}`
    });

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'POST',
      data: {without_prefix: true},
    }).then((response) => {
      this.props.addTask(this.props.round_id, response.data);
    }).catch((error) => {
      this.props.openNotification(
        'Failed to create task from template! Please try again.',
        'failure',
        error.response.data.responseJSON
      )
      setTimeout(this.props.closeNotification, 5000)
    });
  }
};

RoundsAddTaskButton.displayName = 'Rounds.AddTaskButton';
