import React from 'react';
import { connect } from 'react-redux';
import { Remote } from '../utils';

import Tooltip from '../Tooltip'
import ActionAlert from '../ActionAlert';

class ProfilesGoogleAnalyticsAuth extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAnalyticsTooltip: false,
      showConnectTooltip: false,
      loadingAuth: false
    }

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.gaAuthCacheKey = this.gaAuthCacheKey.bind(this);
    this.gaMeasurementIdKey = this.gaMeasurementIdKey.bind(this);
    this.authListener = this.authListener.bind(this);
    this.handleAuth = this.handleAuth.bind(this);
    this.handleAuthDisconnect = this.handleAuthDisconnect.bind(this);
    this.authButtonText = this.authButtonText.bind(this);
    this.renderGAStatus = this.renderGAStatus.bind(this)

    this.render = this.render.bind(this);
  }

  toggleTooltip(type) {
    this.setState({[`show${type}`]: !this.state[`show${type}`]})
  }

  handleAuth() {
    this.setState({loadingAuth: true})
    window.localStorage.setItem(this.gaAuthCacheKey(), null);
    window.localStorage.setItem(this.gaMeasurementIdKey(), null);
    window.addEventListener('storage', () => this.authListener());

    window.open(`${this.props.meta.gaAuthUrl}?blog_id=${this.props.blog.id}`, '_blank');
  }

  authListener() {
    const blogCacheKey = this.gaAuthCacheKey()
    if (window.localStorage.getItem(blogCacheKey) != 'null') {
      const connectedKey = window.localStorage.getItem(blogCacheKey);
      const measurementId = window.localStorage.getItem(this.gaMeasurementIdKey());
      if(!!connectedKey) {
        window.localStorage.removeItem(blogCacheKey);
        this.props.setBlogGaAuthConnection(this.props.blog.id, JSON.parse(connectedKey), measurementId)
      }
      this.setState({loadingAuth: false})
      window.location.reload(false);
    };
  }

  handleAuthDisconnect(){
    this.setState({loadingAuth: true})
    const { blog } = this.props
    return Remote().request({
      method: 'GET',
      url: blog.deauth_ga_url,

    }).then((response) => {
      this.props.setBlogGaAuthConnection(blog.id, false, null)
      this.setState({loadingAuth: false})
      window.location.reload(false);
    });
  }

  gaAuthCacheKey(){
    return `google_analytics_auth_${this.props.blog.id}`
  }

  gaMeasurementIdKey(){
    return `google_analytics_measurement_id_${this.props.blog.id}`
  }

  authButtonText(fallbackText){
    if(this.state.loadingAuth){
      return <i className='fas fa-circle-notch fa-spin fa-lg text-color-blue' />
    }else{
      return fallbackText
    }
  }

  renderGAStatus() {
    const {gaStatusMessages, blog} = this.props;
    let type = "warning";
    let icon = "warning";
    if(blog.connection_status_message === 'connected'){
      type = "success";
      icon = "check";
    }
    return( <ActionAlert text={gaStatusMessages[blog.connection_status_message]} type={type} icon={icon} /> );
  }

  render() {
    const { blog } = this.props;
    const { showAnalyticsTooltip, showConnectTooltip } = this.state;
    const ga_measurement_id = blog.ga_measurement_id || this.props.meta.noGaProperty;
    const unsavedBlog = !blog.id
    const connectDisabled = this.state.loadingAuth || unsavedBlog;

    return (
      <div className='blog-analytics'>
        <label>
          Google Analytics
          <span className='tooltip-icon-wrapper'>
            <i className="fas fa-fw fa-info-circle"
               onMouseLeave={() => this.toggleTooltip('AnalyticsTooltip')}
               onMouseEnter={() => this.toggleTooltip('AnalyticsTooltip')}/>
            {
              showAnalyticsTooltip &&
              <Tooltip centerText={false} placement='top' message={this.props.analyticsTooltip}/>
            }
          </span>
        </label>
        {
          !blog.ga_connected &&
          <div className='google_analytics_auth' >
            <div className='column'>
              <span>No Google Analytics property</span>
            </div>
            <div className='column ga_auth_connection'
                 onMouseLeave={() => unsavedBlog && this.toggleTooltip('ConnectTooltip')}
                 onMouseEnter={() => unsavedBlog && this.toggleTooltip('ConnectTooltip')}>
              <span className='tooltip-icon-wrapper'>
                <a className='btn primary'
                   disabled={connectDisabled}
                   onClick={() => this.handleAuth()}>
                  { this.authButtonText('Connect') }
                </a>
                {
                  showConnectTooltip &&
                  <Tooltip centerText={false} placement='top' message={this.props.meta.gaConnectTooltip}/>
                }
              </span>
            </div>
          </div>
        }
        {
          blog.ga_connected &&
          <div className='google_analytics_auth' >
            <div className='column'>
              <strong>{ga_measurement_id}</strong>
            </div>
            <div className='column ga_auth_connection'>
              <a className='btn disconnect-link' disabled={this.state.loadingAuth} onClick={() => this.handleAuthDisconnect()}>
                { this.authButtonText('Disconnect') }
              </a>
            </div>
          </div>
        }
        {!!this.props.showGAStatus && this.renderGAStatus()}
      </div>
    );
  }

  static mapStateToProps(state, myProps) {
    return {
      meta: state.meta,
      ...myProps,
    };
  };

  static mapDispatchToProps(dispatch) {
    return {
      setBlogGaAuthConnection: (blog_id, ga_connected, measurement_id) => dispatch({
        type: 'SET_BLOG_GA_AUTH_CONNECTION',
        value: {
          blog_id,
          ga_connected,
          measurement_id
        }
      })
    };
  }

};

ProfilesGoogleAnalyticsAuth.displayName = 'Profiles.GoogleAnalyticsAuth';

export default connect(
  ProfilesGoogleAnalyticsAuth.mapStateToProps,
  ProfilesGoogleAnalyticsAuth.mapDispatchToProps
)(ProfilesGoogleAnalyticsAuth);

