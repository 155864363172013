import React from 'react';
import PropTypes from 'prop-types'

export default class ProgressBar extends React.Component {
  render() {
    return (
      <div className={`material-progress-bar ${this.props.classNames}`}>
        <div className='bar'/>
      </div>
    );
  }
};

ProgressBar.displayName = 'ProgressBar'
ProgressBar.propTypes = {
  classNames: PropTypes.string
};
