import React from 'react';
import { Remote } from '../utils';

export default class UserSignUpAuthModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      pinterestUrl: null,
      error: null,
      submitting: false,
      selectedIGAccount: null
    };

    this.doBind();
  }

  updatePinterestURL(e){
    this.setState({pinterestUrl: e.currentTarget.value})
  }

  updateIGAcct(acct){
    this.setState({selectedIGAccount: acct})
  }

  authPinterest() {
    const component = this
    component.setState({submitting: true})

    Remote().request({
      url: '/auth/pinterest',
      method: 'GET',
      params: { url: component.state.pinterestUrl },
    }).then((response) => {
      if(response.data.name) {
        component.props.updateAuth('pinterest_auth', response.data)
        component.setState({submitting: false})
        component.props.closeModal()
      } else {
        component.setState({error: "There was an error finding your pinterest account."})
        component.setState({submitting: false})
      }
    }).catch(() => {
      component.setState({error: "There was an error finding your pinterest account."})
      component.setState({submitting: false})
    });
  }

  validRegexes(){
    return {
      Pinterest: /^((http|https):\/\/)(www\.)?pinterest\.com\/(\S+)$/i
    }
  }

  validUrl(url, type) {
    const regex = this.validRegexes()[type];
    return regex.test(url);
  }

  profileAuth(){
    const component = this
    component.setState({submitting: true})
    const authUrl = `/authentications/finish/${_.lowerCase(component.props.authType)}`
    let { currentUserId } = component.props
    const profileUrl = component.props.authType == 'Pinterest' ? component.state.pinterestUrl : ''
    Remote().request({
      url: authUrl,
      method: 'POST',
      dataType: 'json',
      data: { url: profileUrl, user_id: currentUserId }
    }).then((response) => {
        component.props.setAuthConnection(response.data.auth.provider, response.data.auth.connected)
        component.setState({submitting: false});
        component.props.closeModal();
      }).catch(() => {
        component.setState({error: "There was an error finding your account."});
        component.setState({submitting: false});
      });
  }

  authInstagram() {
    this.setState({submitting: true})
    let auth_values = {
      ...this.props.instagramAuth,
      selectedIGAccount: this.state.selectedIGAccount,
      followers: this.state.selectedIGAccount['followers_count']
    }
    this.props.updateAuth('instagram_auth', auth_values)
    this.setState({submitting: false})
    this.props.closeModal()
  }

  pinterestBody() {
    return (
      <div className='modal-body pinterest-body'>
        <div>
          Unlike Facebook and other social networks, Pinterest does not require
          authorization on your behalf to get the social metrics that we would
          like to track.  But we do need a link to your Pinterest profile.
          <br/><br/>
          If you do not know your Pinterest profile URL (website), please do the following:
        </div>
        <br/>
        <ul>
            <li>Log in to your Pinterest account</li>
            <li>In the upper right corner, click on your email address / account name</li>
            <li>You should get a drop down from Pinterest, click on the 'Your Profile & Pins' link</li>
            <li>Please copy and paste the URL that you were redirected to below</li>
        </ul>
        <div className='form'>
          <div id='pinterest_url' className='input_wrap'>
            <label htmlFor='pinterest_url'>Pinterest URL</label>
            <input name='pinterest_url' type="url" placeholder='https://www.pinterest.com/...' pattern="^((http|https):\/\/)(www\.)?pinterest\.com\/(\S+)$" onChange={(e) => this.updatePinterestURL(e)}/>
            {this.state.error && <p className='text-color-red'>{this.state.error}</p>}
          </div>
        </div>
      </div>
    );
  }

 instagramBody() {
    return (
      <div className='modal-body instagram-body'>
        <div className='input_wrap'>
          <p> Please select which instagram account you wish to use: </p>
          <ul>
          {this.props.instagramAuth.accounts &&
            this.props.instagramAuth.accounts.map((acct, i) =>{
              return(
                <li key={acct['ig_id']}>
                  <input type="radio"
                         id={acct['username']}
                         name='instagram_accounts'
                         value={acct['username']}
                         onClick={() => this.updateIGAcct(acct)}/>
                  <label htmlFor={acct['username']}>{acct['username']}</label>
                </li>
              );
            })
          }
          </ul>
        </div>
      </div>
    );
  }


  render(){
    let { authType, currentUser, closeModal } = this.props;
    let { selectedIGAccount, pinterestUrl, submitting } = this.state;
    let authSubmit, authBody, isValidUrl;

    switch(authType){
      case 'Pinterest':
        authSubmit = currentUser === undefined ? this.authPinterest : this.profileAuth;
        authBody = this.pinterestBody;
        isValidUrl = !!pinterestUrl && this.validUrl(pinterestUrl, authType);
        break;
      default:
        authSubmit = this.authInstagram;
        authBody = this.instagramBody;
        isValidUrl = selectedIGAccount !== null;
    }

    const btnDisabled = !isValidUrl || submitting;

    return (
      <div className='modal'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <a className='close' data-dismiss='modal' onClick={closeModal}>
                <li className='fas fa-times'/>
              </a>
              <h3>Social Fabric Authorization for {authType}</h3>
            </div>
            {authBody()}
            <div className='modal-footer'>
              <button className='btn btn-primary' onClick={authSubmit} disabled={btnDisabled}>
                {submitting ? <i className='fas fa-spin fa-circle-notch' /> : 'Submit'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  doBind(){
    this.render = this.render.bind(this);
    this.updatePinterestURL = this.updatePinterestURL.bind(this);
    this.updateIGAcct = this.updateIGAcct.bind(this);
    this.authPinterest = this.authPinterest.bind(this);
    this.authInstagram = this.authInstagram.bind(this);
    this.profileAuth = this.profileAuth.bind(this);
    this.pinterestBody = this.pinterestBody.bind(this);
    this.instagramBody = this.instagramBody.bind(this);
    this.validRegexes = this.validRegexes.bind(this);
    this.validUrl = this.validUrl.bind(this);
  }
}

UserSignUpAuthModal.displayName = 'UserSignUpAuthModal';
