class window.Sessions

  @show: ->
    $('#login-form').validate
      rules:
        "user[email]":
          required: true
          email: true
        "user[password]":
          required: true
      messages:
        "user[email]":
          required: 'Please enter your e-mail'
          email:    'Please enter a valid email'
        "user[password]":
          required: 'Please enter your password'