import React from 'react';
import PropTypes from 'prop-types'

export default class RoundTasksDelete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: false
    };

    this.delete = this.delete.bind(this);
    this.render = this.render.bind(this);
  }

  delete(e) {
    e.preventDefault();
    const { requirement, index } = this.props;

    if(this.props.requirement.id) {
      this.setState({ deleting: true });
      this.props.deleteById(requirement.id);
    } else {
      this.props.delete(index);
    }
  }

  render() {
    return(
      <div>
        <button className='btn btn-danger' onClick={this.delete} disabled={this.state.deleting}>
          {
            this.state.deleting
            ? <i className='fas fa-spin fa-circle-notch'/>
            : <i className='fas fa-trash'/>
          }
        </button>
      </div>
    )
  }
};

RoundTasksDelete.displayName = 'RoundTasks.Delete';
RoundTasksDelete.propTypes = {
  delete: PropTypes.func.isRequired,
  deleteById: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  requirement: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};
