import React from 'react';

import DataTable from 'datatables.net';
import 'datatables.net-dt';
import 'datatables.net-bs';
import '../plugins/datatables.buttons';
import '../plugins/buttons.colVis.min';
import 'datatables.net-fixedheader-bs';
import 'datatables.net-rowreorder-bs';
import 'datatables.net-fixedcolumns-bs';
import '../plugins/datatables.fnFilterOnReturn'

const defaultProps= {
    pretty_names:{
      'blog_platforms.tier.id':'blog tier',
      'approved_platforms.type_id':'approved platforms',
      'profile.gender': 'gender',
      'blog_platforms.categories.id': 'categories',
      'status': 'membership status',
      'profile.payment': 'payment',
      'blog_platforms.plugin':'plugin',
      'profile.marital_status': 'marital status',
      'blog_platforms.language.id': 'language',
      'profile.pets.id': 'pets',
      'profile.kids': 'kids',
      'profile.ethnicities.id': 'ethnicities',
      'profile.retailers.id': 'retailers',
      'profile.devices.id': 'devices',
      'communities': 'communities'
    },
    kids: [
      { name: 'Yes', value: 'true' },
      { name: 'No',  value: 'false' }
    ],
    reviewed: [
      { name: 'Yes', value: 'true' },
      { name: 'No',  value: 'false' }
    ],
    subscription: [
      { name: 'Subscribed',    value: 'true' },
      { name: 'Unsubscribed',  value: 'false' }
    ],
    returning_user: [
      { name: 'Yes',  value: 'true' },
      { name: 'No',  value: 'false' }
    ]
  };

class UserSearchFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialStateSetup(props);

    this.replaceUserFiltersOnState = this.replaceUserFiltersOnState.bind(this);
    this.getDefaults = this.getDefaults.bind(this);
    this.getFromStorage = this.getFromStorage.bind(this);
    this.pretty_name = this.pretty_name.bind(this);
    this.sendToStorage = this.sendToStorage.bind(this);
    this.getUserFiltersFromState = this.getUserFiltersFromState.bind(this);
    this.getUserFilterFromState = this.getUserFilterFromState.bind(this);
    this.setUserFilterOnState = this.setUserFilterOnState.bind(this);
    this.removeUserFilterFromState = this.removeUserFilterFromState.bind(this);
    this.replaceUserFiltersOnState = this.replaceUserFiltersOnState.bind(this);
    this.removeByKey = this.removeByKey.bind(this);
    this.replaceByKey = this.replaceByKey.bind(this);
    this.createDataTable = this.createDataTable.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
    this.remove_tag = this.remove_tag.bind(this);
    this.getPlaces = this.getPlaces.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.reset_select2 = this.reset_select2.bind(this);
    this.delayedSubmit = this.delayedSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.convertToDate = this.convertToDate.bind(this);
    this.buildParams = this.buildParams.bind(this);
    this.selectChangeHandler = this.selectChangeHandler.bind(this);
    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.checkboxChangeHandler = this.checkboxChangeHandler.bind(this);
    this.conflictsChangeHandler = this.conflictsChangeHandler.bind(this);
    this.render_cbox = this.render_cbox.bind(this);
    this.render_select = this.render_select.bind(this);
    this.render_conflicts_control = this.render_conflicts_control.bind(this);
    this.render_range_control = this.render_range_control.bind(this);
    this.render_criteria_tags = this.render_criteria_tags.bind(this);
    this.render_dropdowns = this.render_dropdowns.bind(this);
    this.renderLocationTags = this.renderLocationTags.bind(this);
    this.removeLocationTag = this.removeLocationTag.bind(this);
  }

  getDefaults(props) {
    if (props.storage_key === 'member_applications_filters') {
      return {
        reviewed: ['false'],
        status:["unverified"],
        suspended:["false"],
        role_platforms:["social_fabric"]
      };
    } else {
      return {
        reviewed: ['true'],
        status:["verified","unverified"],
        suspended:["false"],
        role_platforms:["social_fabric"],
        "communities": ["Social Fabric"]
      };
    }
  }

  getInitialStateSetup(props) {
    var storageKey = props.storage_key;
    var defaultState = this.getDefaults(props);
    var localState = this.getFromStorage(props);
    let newState = {}

    if(localState && localState.length === 0) {
      newState[storageKey] = defaultState;
    }else{
      newState[storageKey] = localState;
    }
    // If this.props.status exist override the default or local
    if (props.storage_key === 'member_applications_filters'){
      newState[storageKey]["status"] = [ props.status ]
    }
    return newState;
  }

  getFromStorage(props) {
    var lStorage = window.localStorage;
    var itemFromStorage = {};
    var storageKey = props.storage_key
    if ( storageKey && lStorage && lStorage.getItem(storageKey) )
      itemFromStorage = JSON.parse(lStorage.getItem(storageKey));

    return itemFromStorage;
  }

  pretty_name(index_name) {
    var pretty_name;
    if( this.props.hasOwnProperty('pretty_names') && this.props.pretty_names.hasOwnProperty(index_name) ) {
      pretty_name = this.props.pretty_names[index_name];
    }else{
      pretty_name = index_name.substr(index_name.indexOf('.')+1,index_name.length).replace(/_/g, ' ');
    }
    return pretty_name;
  }

  sendToStorage(obj) {
    var lStorage = window.localStorage;
    if(lStorage){
      lStorage.setItem(this.props.storage_key, JSON.stringify(obj));
    }
  }

  getUserFiltersFromState(){
    return this.state[this.props.storage_key];
  }

  getUserFilterFromState(filter_name){
    let user_filters = this.getUserFiltersFromState();
    if(user_filters && user_filters.hasOwnProperty(filter_name)){
      return this.state[this.props.storage_key][filter_name];
    }else{
      return null;
    }
  }

  setUserFilterOnState(filter_name,value,sendToStorage){
    var this_ = this;
    var isEmpty = (typeof(value) == "array" && value.length == 0 ? true :
      (typeof(value) == "object" && $.isEmptyObject(value) ? true :
        (value == null || value == "" ? true : false)));
    if(!isEmpty){
      var newUserFilters = this_.replaceByKey(this.getUserFiltersFromState(), filter_name, value);
      var newState = {};
      newState[this_.props.storage_key] = newUserFilters;
      this.setState(newState);
      if(sendToStorage){
        setTimeout(function(){
          this_.sendToStorage(this_.getUserFiltersFromState());
        },300);
      }
    }else{
      this.removeUserFilterFromState(filter_name,sendToStorage);
    }
  }

  removeUserFilterFromState(filter_name,sendToStorage){
    var userFilter = this.getUserFiltersFromState();
    if(filter_name.indexOf("_min") > -1 || filter_name.indexOf("_max") > -1){
      var bare_filter_name = filter_name.replace("_min","").replace("_max","");
      var newState = this.removeByKey(userFilter,bare_filter_name + "_min");
      newState = this.removeByKey(newState,bare_filter_name + "_max");
    }else{
      newState = this.removeByKey(userFilter,filter_name);
    }
    this.replaceUserFiltersOnState(newState,sendToStorage);
  }

  replaceUserFiltersOnState(newFilters,sendToStorage){
    var newState = {};
    newState[this.props.storage_key] = newFilters;
    this.setState(newState);
    if(sendToStorage){
      this.sendToStorage(newFilters);
    }
  }

  removeByKey(myObj, deleteKey) {
    var new_obj = {};
    Object.keys(myObj).map(function(key) {
      if( key != deleteKey ) {
        new_obj[key] = myObj[key];
      }
    });
    return new_obj;
  }

  replaceByKey(myObj, index_name, value_obj){
    var new_obj = {};
    var found = false;
    Object.keys(myObj).map(function(key) {
      if( key == index_name ) {
        new_obj[key] = value_obj;
      }else{
        new_obj[key] = myObj[key];
      }
    });
    if(!found){
      new_obj[index_name] = value_obj;
    }
    return new_obj;
  }

  createDataTable() {
    var this_ = this;
    var isApplicationFilter = this.props.storage_key === 'member_applications_filters' ? true : false
    let dt = new DataTable('.datatable', {
      paginationType: 'full_numbers',
      processing: false,
      searchDelay: 500,
      ajaxSource: $('.datatable').data('source'),
      language:{
        search: '',
        searchPlaceholder: 'Search for something...',
        lengthMenu: '_MENU_per page',
        info: "Showing <strong>_START_ to _END_</strong> of <strong>_TOTAL_</strong>",
        paginate:{
          first: "<<",
          previous: "<",
          last: ">>",
          next: ">"
        },
      },
      bServerSide: true,
      bStateSave: true,
      bProcessing: true,
      bDestroy: true,
      sScrollX: '100%',
      oLanguage: {
        sProcessing: '',
        sSearch: ''
      },
      buttons:  {
        buttons: [
          { extend: 'colvis',
            text: '<i class="fas fa-table btn--icon-left fa-fw"></i>Select Columns',
          },
          { text: '<i class="fas fa-file-alt btn--icon-left fa-fw"></i>Export CSV',
            className: 'btn btn-primary export-csv',
            action: this_.downloadCSV
          },
        ],
        dom: {
          container: {
            className: 'dt-buttons btn-group'
          },
          button: {
            className: 'btn btn-default'
          },
          collection: {
            tag: 'ul',
            className: 'dt-button-collection dropdown-menu',
            button: {
              tag: 'li',
              className: 'dt-button'
            },
            buttonLiner: {
              tag: 'a',
              className: ''
            }
          }
        }
      },
      iDisplayLength: 50,
      sDom: '<"dt-length col-xs-2 col-sm-3"l><"dt-filter col-xs-10 col-sm-5"f><"dt-colvis col-xs-4 text-right"B>rt<"dt-info col-xs-12 col-sm-4"i><"dt-pagination col-xs-12 col-sm-8"p>',
      aoColumnDefs: [
        {
          bSortable: false,
          aTargets: [ 8, 9, 26 ]
        }, {
          aTargets: [ 0, 23, 24, 26 ],
          bVisible: isApplicationFilter
        }
      ],
      fnServerParams(aoData) {
        return aoData.push({
          name: 'user_filter',
          value: this_.buildParams()
        });
      },
      fnStateSave(oSettings, oData) {
        var lStorage = window.localStorage;
        return lStorage.setItem($('.datatable').data(this.props.storage_key), JSON.stringify(oData));
      },
      fnStateLoad(oSettings) {
        var lStorage = window.localStorage;
        return JSON.parse(lStorage.getItem($('.datatable').data(this.props.storage_key)));
      },
      fnDrawCallback() {
        $('[data-toggle="tooltip"]').tooltip({html: true})
      },
      initComplete( settings, json ) {
        $('[data-toggle=tooltip]').tooltip({html: true});
      }
    });

    dt.on('column-visibility.dt', function(e, settings, column, state) {
      $('[data-toggle=tooltip]').tooltip({html: true});
    });
    dt.on('column-reorder.dt', function(e, settings, details) {
      $('[data-toggle=tooltip]').tooltip({html: true});
    });
   }

  componentDidMount() {
    this.getPlaces();
    var this_ = this;
    $('.filter.js_is_select2').select2(
      { placeholder: 'Select Option(s)' }
    ).on('change',this_.selectChangeHandler);

    $('.competitors.js_is_select2').select2(
      { placeholder: 'Select Option(s)' }
    ).on('change',this_.conflictsChangeHandler);

    $('#filter_form .dropdown-menu').bind('click', function(e){
      $(this).closest('.dropdown').addClass('open');
      if (e.target.nodeName === 'DIV') {
        e.stopPropagation();
      }
    });
    setTimeout (
      function() {
        this.createDataTable();
      }.bind(this),
      500
    )


    if (this.props.download_link === undefined)
      $('.export-csv').hide();
  }

  downloadCSV(event) {
    var btn = event.target.parentElement;
    var orig_class = btn.className;
    var orig_html = btn.innerHTML;

    btn.className += " disabled";
    btn.innerHTML = "Downloading..";

    var xhttp = new XMLHttpRequest();
    xhttp.addEventListener("loadend", function(){
      btn.innerHTML = orig_html;
      btn.className = orig_class;
    });

    xhttp.onreadystatechange = function() {
        var a;
        if (xhttp.readyState === 4 && xhttp.status === 200) {
            // Trick for making downloadable link
            a = document.createElement('a');
            a.href = window.URL.createObjectURL(xhttp.response);
            // Give filename you wish to download
            var date = new Date(),
              year = date.getFullYear(),
              month = date.getMonth() + 1,
              day = date.getDate(),
              hour = date.getHours(),
              minutes = date.getMinutes(),
              seconds = date.getSeconds();

            a.download = "UserSearch_Export_" + day + month+ year+'-'+ hour+ minutes+ seconds +".csv";
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
        }
    };
    // Post data to URL which handles post request
    xhttp.open("POST", this.props.download_link);
    xhttp.setRequestHeader("Content-Type", "application/json");
    // You should set responseType as blob for binary responses
    xhttp.responseType = 'blob';
    xhttp.send(JSON.stringify({
       'user_filter': this.buildParams()
    }));
  }

  remove_tag(index_name) {
    this.removeUserFilterFromState(index_name, true);
    this.delayedSubmit();
  }

  getPlaces(){
    var this_ = this;

    var input = document.getElementById('pac-input');
    var autocomplete = new window.google.maps.places.Autocomplete(input);

    autocomplete.addListener('place_changed', function () {
      var locations = this_.getUserFilterFromState('locations');
      if(locations == null){
        locations = [];
      }
      var place = autocomplete.getPlace();
      var $this = $('#pac-input');
      if(place && place.types){
        var locType = place.types[0];
        if ( locType === 'locality' || locType === 'postal_code' ) { // City or Zip
          var geo = place.geometry.location;
          if ( locType === 'locality' ) {
            locations.push({ lat_lon: {lat: geo.lat(), lon: geo.lng()}, label: place.address_components[0].long_name +', '+ place.address_components[2].long_name})
          }else{
            locations.push({ lat_lon: {lat: geo.lat(), lon: geo.lng()}, label: place.address_components[0].long_name})
          }
        } else if (locType == 'administrative_area_level_1') { // State
          locations.push({ state: place.address_components[0].short_name, label: place.address_components[0].long_name +', '+ place.address_components[1].long_name})
        } else if (locType == 'country') { // Country
          locations.push({ country: place.address_components[0].short_name, label: place.address_components[0].long_name })
        }
        this_.setUserFilterOnState('locations', locations, true)
        $this.val('');
        this_.delayedSubmit();
      }
      $this.closest('.dropdown').addClass('open');
      $this.focus();
    });
  }

  handleSubmit(e) {
    var this_ = this;
    if ( e ) {
      e.preventDefault();
    }
    setTimeout( function() { this_.createDataTable(); }, 100 );
  }

  reset_select2(){
    setTimeout(function() {
      $('.js_is_select2').trigger('change.select2');
    },300);
  }

  delayedSubmit(){
    var this_ = this;
    setTimeout(function() {
      this_.handleSubmit();
    },150);
    this.reset_select2();
  }

  resetForm() {
    this.replaceUserFiltersOnState(this.getDefaults(this.props),true);
    this.reset_select2();
  }

  convertToDate(days) {
    var d = new Date();
    d.setDate(d.getDate() - days);
    return d.toISOString().substring(0, 10);
  }

  buildParams() {
    var this_ = this;
    var server_template = this.template_server_params();
    var params_to_send = {};
    Object.keys(server_template).map(function(main_key) {
      var sub_template=server_template[main_key];
      switch(main_key){
        case "locations":
        case "competitors":
          var state_value = this_.getUserFilterFromState(main_key);
          if( state_value != null ) {
            params_to_send[main_key] = state_value;
          }
          break;
        case "ranges":
          Object.keys(sub_template).map(function(sub_key) {
            var min_key = sub_key + "_min";
            var max_key = sub_key + "_max";
            if(sub_key == ("last_activity_at" || "last_shop_submission")){
              min_key = sub_key + "_max";
              max_key = sub_key + "_min";
            } else if(sub_key.includes('latest_metrics')){
              sub_key = sub_key + ".metric"
            }

            var min_state_value = this_.getUserFilterFromState(min_key);
            var max_state_value = this_.getUserFilterFromState(max_key);
            if( min_state_value != null && max_state_value != null ) {
              if(!params_to_send.hasOwnProperty(main_key)){
                params_to_send[main_key] = {}
              }
              if(sub_key == ("last_activity_at" || "last_shop_submission")){
                params_to_send[main_key][sub_key] = {
                  gte:this_.convertToDate(min_state_value),
                  lte:this_.convertToDate(max_state_value)
                }
              } else {
                params_to_send[main_key][sub_key] = {gte:min_state_value,lte:max_state_value}
              }
            }
          });
          break;
        case "filter":
          Object.keys(sub_template).map(function(sub_key) {
            let state_value = this_.getUserFilterFromState(sub_key);
            if( state_value != null ) {
              if(!params_to_send.hasOwnProperty(main_key)){
                params_to_send[main_key] = {}
              }
              params_to_send[main_key][sub_key] = state_value
            }
          });
          break;
      }
    });
    return params_to_send;
  }

  selectChangeHandler  (event) {
    var element_id = $.trim(event.target.id)
    var selector = $.trim("#"+element_id.replace( /(:|\.|\[|\])/g, "\\$1" ));
    var element_value = $(selector).val();
    this.setUserFilterOnState(element_id,element_value,true);
    this.delayedSubmit();
  }

  inputChangeHandler  (event) {
    var element_id = $.trim(event.target.id)
    var element_value = $.trim(event.target.value)
    this.setUserFilterOnState(element_id,element_value,true);
    this.delayedSubmit();
  }

  checkboxChangeHandler  (event) {
    var element_id = $.trim(event.target.id)
    var selector = $.trim("#"+element_id.replace( /(:|\.|\[|\])/g, "\\$1" )+":checked");
    var element_value = $(selector).map(function(){
      return $(this).val();
    }).get();
    this.setUserFilterOnState(element_id,element_value,true);
    this.delayedSubmit();
  }

  conflictsChangeHandler  (event) {
    var element_id = $.trim(event.target.id)
    var selector = $.trim("#"+element_id.replace( /(:|\.|\[|\])/g, "\\$1" ));
    var $element = $(selector);
    var $parent = $element.closest(".conflict_control");
    var dropdown_values = $parent.find("select").val();
    var day_value = $parent.find("input.input--three-digits").val();
    if(!day_value){
      day_value = 100;
    }
    this.setUserFilterOnState("competitors",{client_id:dropdown_values,days:day_value},true);
    this.delayedSubmit();
  }

  render_cbox(props_element,state_name) {
    var this_ = this;
    if(typeof(props_element) === "undefined"){
      return (
        <h6> There Was An Issue With {state_name} </h6>
      );
    }
    return props_element.map(function(the_element) {
      var value = the_element;
      var label = the_element;
      if ( typeof(the_element ) === "object" ) {
        if ( the_element.hasOwnProperty("id") ) {
         value = String(the_element.id);
        }else{
         value = String(the_element.value);
        }
        label = the_element.name;
      }
      var checked = false;
      var user_filter = this_.getUserFilterFromState(state_name);
      if (user_filter != null && user_filter.indexOf(value) > -1) {
        checked = true;
      }

      return (
        <div className="checkbox" key={state_name + "-" + label}>
          <label>
            <input type="checkbox" className='filter' onChange={this_.checkboxChangeHandler} id={state_name} name={state_name} checked={checked} defaultValue={value} />
            {label}
          </label>
        </div>
      )
    });
  }

  render_select(props_element, state_name) {
    var this_ = this;
    if ( typeof(props_element) === "undefined" ) {
      return (
        <h6> There Was An Issue With {state_name} </h6>
      );
    }
    var options = props_element.map(function(the_element) {
      var value = the_element;
      var label = the_element;
      if ( typeof(the_element) === "object" ) {
        if ( the_element.hasOwnProperty("id") ) {
         value = String(the_element.id);
        }else{
         value = String(the_element.value);
        }
        label = the_element.name;
      }
      return <option key={label + "-" + value} value={value}>{label}</option>
    });
    var value = [];
    var user_filter = this_.getUserFilterFromState(state_name);
    if(user_filter != null){
      value = user_filter;
    }
    return (
      <select id={state_name} onChange={this_.selectChangeHandler} className="js_is_select2 filter form-control" name={state_name} value={value}  multiple="multiple">
        {options}
      </select>
    )
  }

  render_conflicts_control(props_element,state_name){
    var this_ = this;
    if ( typeof(props_element) === "undefined" ) {
      return (
        <h6> There Was An Issue With {state_name} </h6>
      );
    }
    var options = props_element.map(function(the_element) {
      var value = the_element;
      var label = the_element;
      if(typeof(the_element) === "object"){
        if(the_element.hasOwnProperty("id")){
         value = String(the_element.id);
        }else{
         value = String(the_element.value);
        }
        label = the_element.name;
      }
      return <option key={label + "-" + value} value={value}>{label}</option>
    });

    var value = {client_id:[],days:null};
    var user_filter = this_.getUserFilterFromState(state_name);
    if(user_filter != null){
      value = user_filter;
    }

    return (
      <div className="conflict_control">
        <small>Hasn't worked for</small>
        <div>
          <select className="js_is_select2 competitors form-control" multiple="multiple" onChange={this_.conflictsChangeHandler} id={state_name + "_client_id"} value={value.client_id}>
            {options}
          </select>
        </div>
        <small>within</small>
        <div>
          <input type="number" step="10" className="form-control competitors input--three-digits" onChange={this_.conflictsChangeHandler} id={state_name + "_days"} defaultValue={value.days}/>
        </div>
        <small>days</small>
      </div>
    )
  }

  render_range_control(the_element,prefix){
    var this_ = this;
    var value = the_element;
    var label = the_element;
    if ( typeof(the_element) === "object" ) {
      if ( the_element.hasOwnProperty("id") ) {
       value = String(the_element.id);
      }else{
       value = String(the_element.value);
      }
      label = the_element.name;
    }
    var range_name = value;
    if ( prefix && prefix != "" ) {
        range_name = prefix + "." + range_name;
    }
    var min_name = range_name + "_min";
    var max_name = range_name + "_max";

    var min_value = null;
    var max_value = null;

    var user_filter = this_.getUserFilterFromState(min_name);
    if(user_filter != null){
      min_value = user_filter;
    }
    var user_filter = this_.getUserFilterFromState(max_name);
    if(user_filter != null){
      max_value = user_filter;
    }
    var min_style = { "marginRight": "5px"};
    var max_style = { "marginLeft": "5px"};
    if(min_value == null && max_value != null){
      min_style["borderColor"]="red";
    }
    if(max_value == null && min_value != null){
      max_style["borderColor"]="red";
    }
    return (
      <div className="range clearfix" data-range-name={range_name} key={label + "-" + value}>
        <label className={"filter-form--label " + "label--" + value}>{label}</label>
        <input className="form-control input--three-digits" style={min_style} type="number" step="10" onChange={this.inputChangeHandler} placeholder="min" defaultValue={min_value} id={min_name} name={min_name}/>
        <span className="pull-left" style={{"lineHeight": "30px"}}>-</span>
        <input className="form-control input--three-digits" style={max_style} type="number" step="10" onChange={this.inputChangeHandler} placeholder="max" defaultValue={max_value} id={max_name} name={max_name}/>
      </div>
    )
  }

  render_criteria_tags(){
    var this_ = this
    let filters = this.getUserFiltersFromState();
    let pretty_key = '';
    let count = '';
    let filter_tags = filters && Object.keys(filters).map(function(key) {
      if( key.indexOf("_min") < 0){
        if( key.indexOf("_max") > -1 ){
          pretty_key = key.replace("_min","").replace("_max","");
          count="";
        }else if(key == "competitors"){
          pretty_key = key;
          count="";
        }else{
          pretty_key = key;
          count = (<span className="filter-count">({filters[key].length})</span>)
        }
        var pretty_name = this_.pretty_name(pretty_key);
        return (<li key={"critieria_key_"+key} className="filter-form--tags" onClick={this_.remove_tag.bind(this_,key)}>{pretty_name} {count}<i className="fas fa-times fa-fw"></i></li>)
      }
    });
    return (
      <ul className="no-list-style selector_tags">
        { filter_tags }
      </ul>
    )
  }

  render_dropdowns(label, var_name, submit_button) {
    if(submit_button){
      var submit_button = '<button onSubmit={this.handleSubmit}>Apply</button>'
    }
    return (
      <div className="dropdown filter-form--dropdown">
        <button className="btn btn-default" data-toggle="dropdown">
          {label}
          <i className="fas fa-caret-down fa-fw"></i>
        </button>
        <div className="dropdown-menu">
          <div>{var_name}</div>
        </div>
        {submit_button}
      </div>
    )
  }

  renderLocationTags(){
    var this_ = this,
        locations = this.getUserFilterFromState('locations'),
        index = -1;
    if ( locations != null ) {
      var location_tags = locations.map( function(location) {
        index++
        return <li className="filter-form--tags" onClick={this_.removeLocationTag.bind(this_,index)}>{location.label}<i className="fas fa-times fa-fw"></i></li>
      });
      return (
        <ul className="no-list-style" style={{'marginTop': '5px'}} >
          { location_tags }
        </ul>
      )
    }
  }

  removeLocationTag(index) {
    var locations = this.getUserFilterFromState('locations');
    locations.splice(index, 1);
    this.setUserFilterOnState('locations', locations, true);
    var $this = $('#pac-input');
    $this.closest('.dropdown').addClass('open');
    $this.focus();
    this.delayedSubmit();
  }

  render() {
    const this_ = this;
    const p = this.props;
    const formParams = this.state.formParams;
    const genders            = this.render_cbox( p.genders,'profile.gender' );
    const marital_statuses   = this.render_cbox( p.marital_statuses,'profile.marital_status' );
    const pets               = this.render_cbox( p.pets,'profile.pets.id' );
    const kids               = this.render_cbox( p.kids,'profile.kids' );
    const languages          = this.render_cbox( p.languages,'blog_platforms.language.id' );
    const blog_tiers         = this.render_cbox( p.blog_tiers,'blog_platforms.tier.id' );
    const membership_status  = this.render_cbox( p.membership_status,'status' );
    const suspended          = this.render_cbox( p.suspended,'suspended' );
    const reviewed           = this.render_cbox( p.reviewed, 'reviewed' );
    const payment_status     = this.render_cbox( p.payment_status,'profile.payment' );
    const approved_platforms = this.render_cbox( p.approved_platforms,'approved_platforms.type_id' );
    const strike_counts      = this.render_cbox( p.strike_counts,'strikes' );
    const plugin_status      = this.render_cbox( p.plugin_status,'blog_platforms.plugin' );
    const ethnicities        = this.render_cbox( p.ethnicities,'profile.ethnicities.id','filter' );
    const communities        = this.render_cbox( p.communities, 'communities')
    const subscription       = this.render_cbox( p.subscription, 'subscription')
    const returning_user     = this.render_cbox( p.returning_user, 'returning_user')

    const blog_categories    = this.render_select( p.categories,'blog_platforms.categories.id' );
    const retailers          = this.render_select( p.retailers,'profile.retailers.id' );
    const devices            = this.render_select( p.devices,'profile.devices.id' );

    const conflict_clients   = this.render_conflicts_control(p.competitors,'competitors');

    const age                = this.render_range_control("age", "profile" );
    const reach_list         = p.reach_list && p.reach_list.map( function(platform) {
      return this_.render_range_control( platform,"latest_metrics" );
    });


    return (

      <div className="row mb-15">
        <div className="col-xs-12">
          <form id="filter_form" className="filter-form" onSubmit={this.handleSubmit}>
            <div>
              {this.render_dropdowns('Approved Platforms', approved_platforms)}
              {this.render_dropdowns('Blog Tier', blog_tiers)}
              {this.render_dropdowns('Social Reach', reach_list)}
              {this.render_dropdowns('Gender', genders)}
              {this.render_dropdowns('Ethnicity', ethnicities)}
              {this.render_dropdowns('Age', age)}
              {this.render_dropdowns('Communities', communities)}
              {this.render_dropdowns('Returning User', returning_user)}
              <div className="dropdown filter-form--dropdown more-filters-dropdown" id="filter_dropdown">
                <button className="btn btn-default" data-toggle="dropdown">
                  <i className="fas fa-filter fa-fw"></i>
                  More Filters
                  <i className="fas fa-caret-down fa-fw"></i>
                </button>
                <div className="dropdown-menu">
                  <i className="fas fa-times-circle text-danger" id="close_btn"></i>
                  <div className="flex-row">
                    <div className="flex-item" style={{"width": "30%"}}>
                      <div className="filter-form--label mb-15">Location</div>
                      <div className="filter-form--search-input">
                        <input type="text" className="form-control" id="pac-input" placeholder="City, State or Zip" />
                        {this.renderLocationTags()}
                      </div>
                    </div>
                    <div className="flex-item" style={{"width": "50%"}}>
                      <div className="filter-form--label mb-15">Category</div>
                      {blog_categories}
                    </div>
                    <div className="flex-item">
                      <div className="filter-form--label">Blog Plugin Status</div>
                      {plugin_status}
                    </div>
                  </div>
                  <hr/>
                  <div className="flex-row">
                    <div className="flex-item" style={{"width": "15%"}}>
                      <div className="filter-form--label mb-15">Last Activity (days)</div>
                      {this.render_range_control("last_activity_at", this.state.formParams)}
                    </div>
                    <div className="flex-item" style={{"width": "15%"}}>
                      <div className="filter-form--label mb-15">Last Opportunity (days)</div>
                      {this.render_range_control("last_shop_submission", this.state.formParams)}
                    </div>
                    <div className="flex-item" style={{"width": "15%"}}>
                      <div className="filter-form--label mb-15">Shops Completed</div>
                      {this.render_range_control("total_shops", this.state.formParams)}
                    </div>
                    <div className="flex-item" style={{"width": "15%"}}>
                      <div className="filter-form--label">Marital Status</div>
                      {marital_statuses}
                    </div>
                    <div className="flex-item" style={{"width": "15%"}}>
                      <div className="filter-form--label">Kids</div>
                      {kids}
                    </div>
                    <div className="flex-item" style={{"width": "40%"}}>
                      <div className="filter-form--label mb-15">Preferred Retailers</div>
                      {retailers}
                    </div>
                  </div>
                  <hr/>
                  <div className="flex-row">
                    <div className="flex-item" style={{"width": "15%"}}>
                      <div className="filter-form--label">Membership Status</div>
                      <span style={{'textTransform':'capitalize'}}>{membership_status}</span>
                    </div>
                    <div className="flex-item" style={{"width": "15%"}}>
                      <div className="filter-form--label">Reviewed</div>
                      {reviewed}
                    </div>
                    <div className="flex-item" style={{"width": "15%"}}>
                      <div className="filter-form--label">Suspended</div>
                      {suspended}
                    </div>
                    <div className="flex-item" style={{"width": "15%"}}>
                      <div className="filter-form--label">Pets</div>
                      {pets}
                    </div>
                    <div className="flex-item" style={{"width": "15%"}}>
                      <div className="filter-form--label">Payment Status</div>
                      {payment_status}
                    </div>
                    <div className="flex-item" style={{"width": "40%"}}>
                      <div className="filter-form--label">Competitive Work</div>
                     {conflict_clients}
                    </div>
                  </div>
                  <hr/>
                  <div className="flex-row">
                    <div className="flex-item" style={{"width": "50%"}}>
                      <div className="filter-form--label mb-15">Devices</div>
                      {devices}
                    </div>
                    <div className="flex-item">
                      <div className="filter-form--label">Language</div>
                      {languages}
                    </div>
                    <div className="flex-item">
                      <div className="filter-form--label">Strike Count</div>
                      {strike_counts}
                    </div>
                    <div className="flex-item">
                      <div className="filter-form--label">General Email Subscription</div>
                      {subscription}
                    </div>
                  </div>
                </div>
              </div>
              <button onClick={this.resetForm} className="btn btn-link btn-xs"><i className="fas fa-undo fa-fw"></i>Reset All</button>
            </div>
          </form>
        </div>
        <div className="col-xs-12 tags mt-10">
          {this.render_criteria_tags()}
        </div>
      </div>
    );
  }
  template_server_params(){
    return {
      "filter" : {
                      "approved_platforms.type_id" : [1],
                          "blog_platforms.tier.id" : [2],
                                 "profile.pets.id" : [2],
                                  "profile.gender" : ["Male"],
                    "blog_platforms.categories.id" : [1],
                                          "status" : ["Verified"],
                                       "suspended" : [false],
                                 "profile.payment" : ["Active"],
                           "blog_platforms.plugin" : [true],
                          "profile.marital_status" : ["Married"],
                      "blog_platforms.language.id" : [4],
                                    "profile.kids" : [true],
                          "profile.ethnicities.id" : [3],
                            "profile.retailers.id" : [125],
                              "profile.devices.id" : [1],
                                         "strikes" : [1],
                                         "reviewed": [true],
                                     "subscription": [true],
                                   "returning_user": [true],
                                   "role_platforms": ["social_fabric"],
                                     "communities" : ["Social Fabric"]
                  },
       "ranges" : {
                                     "profile.age" : { "gte":22, "lte":29 },
                        "latest_metrics.instagram" : { "gte":12000, "lte":25000 },
                          "latest_metrics.youtube" : { "gte":12000, "lte":25000 },
                          "latest_metrics.twitter" : { "gte":12000, "lte":25000 },
                         "latest_metrics.facebook" : { "gte":12000, "lte":25000 },
                        "latest_metrics.pinterest" : { "gte":12000, "lte":25000 },
                                     "total_shops" : { "gte":10, "lte":100 },
                            "last_shop_submission" : { "gte":365, "lte":30 },
                                "last_activity_at" : { "gte":365, "lte":30 }
                  },
      "locations" : [ { "location" : {"near" :{"lat":36.3320196,"lon":-94.11853659999997}, "within": "100mi"}} ],
      "competitors" : {"client_id":[8], "days":300}
    }
  }
}
export { UserSearchFilter as default };