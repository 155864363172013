import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Remote } from '../utils';

import NavigationTabsWrapper from '../navigation_tabs/Wrapper'
import CommunitiesLoginSettings from './LoginSettings'
import CommunitiesCustomization from './Customization'
import CommunitiesGeneralSettings from './GeneralSettings'


class CommunitiesEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}

    this.componentDidMount = this.componentDidMount.bind(this);
    this.loadCommunity = this.loadCommunity.bind(this);
    this.render = this.render.bind(this);
    this.currentView = this.currentView.bind(this);
    this.fetching = this.fetching.bind(this);
    this.updateView = this.updateView.bind(this);
    this.getRoute = this.getRoute.bind(this);
  }

  componentDidMount() {
    this.loadCommunity();
    this.props.updateStore(this.props.init);
  }

  getRoute(view){
    if (view == 'settings' || !view)
      return '/'
    else
      return `/${view}`
  }

  updateView(newView) {
    let { community, view } = this.props;
    this.props.setLoading(true);
    if (view != newView) {
      const path = this.getRoute(newView);
      const oldPath = window.location.pathname
      const newRoute = String(window.location).replace(oldPath, `/communities/${community.id}${path}`);
      this.props.setView(newView, newRoute);
    }
  }


  fetching(view=this.props.view) {
    return !!(this.props[view] && this.props[view]['fetching'])
  }

  currentView() {
    const { view, community } = this.props;

    switch(view){
      case 'landing_page':
        return <CommunitiesLoginSettings {...this.props}/>
      case 'customization':
        return <CommunitiesCustomization {...this.props} />
      }
    return <CommunitiesGeneralSettings {...this.props} />
  }

  loadCommunity() {
    const params = $.param({
      api_action: `/communities/${this.props.communityId}`
    });

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'GET',

    }).then((response) => {
      this.props.setCommunity(response.data);
    }).catch((error) => {
      this.props.openNotification(
        'Failed to load Community settings.',
        'failure',
        error
      )
      setTimeout(this.props.closeNotification, 5000);
    });
  }

  render() {
    let { community, view, navigation } = this.props;

    return <div className='communities--show-wrapper'>
      <NavigationTabsWrapper onClickCallback={this.updateView} activeTab={view || 'settings' } {...navigation} />
      <div className='container main-container admin-communities-container'>
          <div className='row'>
            { this.currentView() }
          </div>
        </div>
    </div>;
  }

  static mapStateToProps(state, myProps) {
    return {
      ...state,
      view: state.view || myProps.view,
      init: myProps
    };
  };

  static mapDispatchToProps(dispatch) {
    return {
      setCommunity: community => dispatch({ type: 'SET_COMUNITY', value: community }),
      updateStore: (data) => dispatch({ type: 'UPDATE', value: data }),
      setLoading: (bool) => dispatch({ type: 'UPDATE_FIELD', value: { field: 'loading', data: bool } }),
      setView: (newView, route) => dispatch({ type: 'SET_CURRENT_VIEW', value: { view: newView, route: route}}),
      openNotification: (saveText, saveType, saveError) => dispatch({type: 'OPEN_NOTIFICATION', value: {saveText, saveType, saveError}}),
      closeNotification: data => dispatch({ type: 'CLOSE_NOTIFICATION'})
    };
  };
};

CommunitiesEdit.displayName = 'Communities.Edit';
CommunitiesEdit.propTypes = {
  communityId: PropTypes.number.isRequired,
  proxyUrl: PropTypes.string.isRequired
};

export default connect(
CommunitiesEdit.mapStateToProps,
CommunitiesEdit.mapDispatchToProps
)(CommunitiesEdit);

