
class window.DataTableUtils

  @datatable: (table, options) ->
    dTable = table.DataTable(
      $.extend(
          paginationType: 'full_numbers'
          processing: false
          searchDelay: 500
          ajaxSource: table.data('source')
          language:
            search: ''
            searchPlaceholder: 'Search for something...'
            lengthMenu: '_MENU_per page'
            info: "Showing <strong>_START_ to _END_</strong> of <strong>_TOTAL_</strong>"
            paginate:
              first: "<<"
              previous: "<"
              last: ">>"
              next: ">"
          sDom: '<"dt-length col-xs-2 col-sm-3"l><"dt-filter col-xs-6 col-sm-6"f>rt<"dt-info col-xs-12 col-sm-4"i><"dt-pagination col-xs-12 col-sm-8"p>'
          options
        )
      )
