import React from 'react';
import { connect } from 'react-redux'

class RequirementRow extends React.Component {
  constructor(props) {
    super(props);

    this.descriptionText = this.descriptionText.bind(this);
    this.handleRequirementAction = this.handleRequirementAction.bind(this);
    this.render = this.render.bind(this);
    this.statusIcon = this.statusIcon.bind(this);
  }

  statusIcon(req){
    switch(req.validation){
    case 'failed':
      return <i className='fas fa-lg fa-check-circle' />
    case 'passed':
      return <i className='fas fa-lg fa-exclamation-circle' />
    default:
      return <i className='far fa-lg fa-clock' />
    }
  }

  descriptionText(req) {
    if(req.requirement_type === 'standard') {
      return req.description;
    }

    return <div className='form-lr'>
      <span>
        {_.get(req, 'instruction_note_template.label')}
      </span>

      <a href={this.instructionsUrl()}>
        View
      </a>
    </div>;
  }

  handleRequirementAction(newStatus){
    let newRequirement = {
      ...this.props.requirement,
      latest_validation_state: {
        ...this.props.requirement.latest_validation_state,
        verification_status: newStatus,
      }
    }
    this.props.updateRequirementValidation(newRequirement);
  }

  render() {
    const req = this.props.requirement;
    const status = req.latest_validation_state ? req.latest_validation_state.verification_status : 'pending';

    return(
      <div key={req.id} className='requirement_row'>
        <div className={`requirement_row--info ${status}`}>
          <div className='requirement_row--description'>
            {this.descriptionText(req)}
          </div>
          <div className='requirement_row--humanized' dangerouslySetInnerHTML={{__html: req.humanized_requirement}}></div>
        </div>
        <div className={`requirement_row--approval ${status}`}>
          <i className='fas fa-2x fa-check-circle' onClick={() => this.handleRequirementAction('passed')}/>
          <i className='fas fa-2x fa-times-circle' onClick={() => this.handleRequirementAction('failed')}/>
        </div>
      </div>
    );
  }

  instructionsUrl() {
    const {
      bloggerShopTask,
      requirement
    } = this.props;

    const shopId = _.get(bloggerShopTask, 'shoppertunity_id');
    const roundId = _.get(bloggerShopTask, 'round_task.round_id');
    const noteId = _.get(requirement, 'instruction_note_template.id');

    return `/admin/opportunities/${shopId}/instructions?round=${roundId}#instruction_note_${noteId}`;
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      bloggerShopTask: state.bloggerShopTask
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateRequirementValidation: requirement => dispatch({ type: 'UPDATE_REQUIREMENT_VALIDATION', value: requirement }),
    };
  }
};

RequirementRow.displayName = 'Contents.RequirementRow';

export default connect(
  RequirementRow.mapStateToProps,
  RequirementRow.mapDispatchToProps
)(RequirementRow);
