import React from 'react';
import Prism from 'prismjs';
import 'prismjs/components/prism-jsx'
import 'prismjs/themes/prism-okaidia.css'
import SingleSelect from '../SingleSelect'
import MultiSelect from '../MultiSelect'
import InputGroup from '../InputGroup'
import FileInputGroup from '../FileInputGroup'
import Toggle from '../Toggle'


const selectOptions=[{id: 1, name: 'Option 1', value: 1},
                     {id: 2, name: 'Option 2', value: 2},
                     {id: 3, name: 'Option 3', value: 3}];

export default class LivingStyleGuideForms extends React.Component {

  constructor(props) {
    super(props);

    this.state={
      firstAddonInput: '',
      secondAddonInput: '',
      switchToggle: false,
      singleSelectId: null,
      multiSelectIds: [],
    }

    this.selectSearch = this.selectSearch.bind(this);
  }

  componentDidMount(){
    Prism.highlightAll()
  }

  selectSearch(text, callback) {
    if(!text) {
      callback(selectOptions);
    }
    callback(selectOptions.filter(x => x.name.toLowerCase().includes(text.toLowerCase())));
  }

  render() {
    return (
      <div id='forms' className='style-guide-section'>
        <h1 className='section-header'>Forms</h1>
        <div className='section-description'>

        </div>
        <div className='styles-container'>
          <div className='form-styles'>
            <div className='form-examples'>
              <h2 className='sub-header'>Input Labels</h2>
              <div className='form-input-labels'>
                <div className='examples'>
                  <div>
                    <label>Stacked</label>
                    <input type='text' placeholder='placeholder' />
                  </div>
                  <div>
                    <label className='horizontal-input-label'>Horizontal</label>
                    <input type='text' placeholder='placeholder' />
                  </div>
                  <div className="magic-label">
                    <input type="text" placeholder="Magic Label" required />
                    <label>Magic label</label>
                  </div>
                </div>
                <div className='code-example'>
                  <pre>
                    <code className="language-markup">
                      {`
                        <div className='input-group'>
                          <div>
                            <label>Stacked</label>
                            <input type='text' placeholder='placeholder' />
                          <div>
                          <div>
                            <label className='horizontal-input-label'>Horizontal</label>
                            <input type='text' placeholder='placeholder' />
                          </div>
                          <div className="magic-label">
                            <input type="text" placeholder="Magic Label" required />
                            <label>Magic label</label>
                          </div>
                        </div>
                      `}
                    </code>
                  </pre>
                </div>
              </div>
              <h2 className='sub-header'>Input States</h2>
              <div className='form-input-states'>
                <div className='examples'>
                  <div>
                    <label>Default</label>
                    <input type="text" placeholder="placeholder"/>
                    <span className="help-text no-prefix">Your password must be 8-20 characters.</span>
                  </div>
                  <div>
                    <label>Success</label>
                    <input type="text" className='valid' placeholder="placeholder"/>
                    <span className="help-text no-prefix">Success!</span>
                  </div>
                  <div>
                    <label>Error</label>
                    <input type="text" className='invalid' placeholder="placeholder" required/>
                    <span className="help-text"></span>
                  </div>
                  <div>
                    <label>Disabled</label>
                    <input type="text" placeholder="placeholder" disabled/>
                    <span className="help-text no-prefix">You cannot change this field.</span>
                  </div>
                </div>
                <div className='code-example'>
                  <pre>
                    <code className="language-markup">
                      {`
                        <div className='input-group'>
                          <div>
                            <label>Default</label>
                            <input type="text" placeholder="placeholder"/>
                            <span className="help-text no-prefix">Your password must be 8-20 characters.</span>
                          </div>
                          <div>
                            <label>Success</label>
                            <input type="text" className='valid' placeholder="placeholder"/>
                            <span className="help-text no-prefix">Success!</span>
                          </div>
                          <div>
                            <label>Error</label>
                            <input type="text" className='invalid' placeholder="placeholder" required/>
                            <span className="help-text"></span>
                          </div>
                          <div>
                            <label>Disabled</label>
                            <input type="text" placeholder="placeholder" disabled/>
                            <span className="help-text no-prefix">You cannot change this field.</span>
                          </div>
                        </div>
                      `}
                    </code>
                  </pre>
                </div>
              </div>
              <h2 className='sub-header'>Input Addons</h2>
              <div className='form-addon-inputs'>
                <div className='examples'>
                  <div className='input_wrap'>
                    <label>Left addon</label>
                    <InputGroup
                      type='number'
                      iconClass='fas fa-dollar-sign'
                      onChange={value => console.log(value)}
                      placeholder='Example amount'
                      disabled={false}
                      />
                  </div>
                  <div className='input_wrap'>
                    <label>Custom File Input</label>
                    <FileInputGroup
                      value={this.state.uploadFile}
                      onChange={value => console.log(value)}
                      placeholder='Select a report...'
                      addonPlacement='right'
                      fileTypes='.csv'
                      disabled={false}
                    />
                  </div>
                </div>
                <p>We have the <code>input_group.js.jsx</code> component built for the implementation of any input that requires an addon feature.</p>
                <div className='code-example'>
                  <pre>
                    <code className="language-jsx">
                      {`
                        render(){
                          return(
                            <div className='addon-input-group'>
                              <div className='input_wrap'>
                                <label>Left addon</label>
                                <InputGroup
                                  type='number'
                                  value='1000'
                                  iconClass='fas fa-dollar-sign'
                                  onChange={value => updateInput(value)}
                                  placeholder='Example amount'
                                  disabled={false}
                                  />
                              </div>
                              <div className='input_wrap'>
                                <label>Custom File Input</label>
                                <FileInputGroup
                                  value={filename.csv}
                                  onChange={value => updateInput(value)}
                                  placeholder='Select a report...'
                                  addonPlacement='right'
                                  fileTypes='.csv'
                                  disabled={false}
                                />
                              </div>
                            </div>
                          );
                        }
                      `}
                    </code>
                  </pre>
                </div>
              </div>
              <h2 className='sub-header'>Text Area</h2>
              <div className='form-textarea'>
                <div className='examples'>
                  <div>
                    <label>Textarea label</label>
                    <textarea rows='3' placeholder='Write your message here.' />
                  </div>
                  <div>
                    <label>Textarea disabled</label>
                    <textarea rows='3' placeholder="You can't write your message here." disabled/>
                  </div>
                </div>
                <div className='code-example'>
                  <pre>
                    <code className="language-markup">
                      {`
                        <div>
                          <label for='text-area'>Text Area Label</label>
                          <textarea name='text-area' rows='3' placeholder='Write your message here.' />
                        </div>
                      `}
                    </code>
                  </pre>
                </div>
              </div>
              <h2 className='sub-header'>Select Dropdown Inputs</h2>
              <div className='form-select-inputs'>
                <div className='examples'>
                  <div className="form-group">
                    <label>Single-select</label>
                    <SingleSelect
                      preload={selectOptions}
                      selected={this.state.singleSelectId || ''}
                      search={this.selectSearch}
                      onUpdate={selected => this.setState({singleSelectId: selected })}
                      placeholder={'single-select input'}
                    />
                  </div>
                  <div className="form-group">
                    <label>Multi-select</label>
                    <MultiSelect placeholder={'multi-select input'}
                                selected={this.state.multiSelectIds}
                                preload={selectOptions.map(x => x.name)}
                                search={this.selectSearch}
                                onUpdate={selected => this.setState({multiSelectIds: selected })}
                                disabled={false}/>
                  </div>
                </div>
                <p>We have the <code>single_select.js.jsx</code> and the <code>multi_select.js.jsx</code>
                  components built for the implementation of a single-select and multi-select input.</p>
                <div className='code-example'>
                  <pre>
                    <code className="language-jsx">
                      {`
                        const selectOptions=[{id: 1, name: 'Option 1', value: 1},
                        {id: 2, name: 'Option 2', value: 2},
                        {id: 3, name: 'Option 3', value: 3}];

                        selectSearch(text, callback) {
                          if(!text) {
                            callback(selectOptions);
                          }
                          callback(selectOptions.filter(x => x.name.toLowerCase().includes(text.toLowerCase())));
                        }

                        render(){
                          return(
                            <div className="form-group">
                              <label>Single-select example</label>
                              <SingleSelect placeholder={'single-select input'}
                                            preload={selectOptions}
                                            selected={this.state.singleSelectId || ''}
                                            search={this.selectSearch}
                                            onUpdate={selected => this.setState({singleSelectId: selected })}
                                            disabled={false}/>
                            </div>
                            <div className="form-group">
                              <label>Multi-select example</label>
                              <MultiSelect placeholder={'multi-select input'}
                                            selected={this.state.multiSelectIds}
                                            preload={selectOptions.map(x => x.name)}
                                            search={this.selectSearch}
                                            onUpdate={selected => this.setState({multiSelectIds: selected })}
                                            disabled={false}/>
                            </div>
                          );
                        }
                      `}
                    </code>
                  </pre>
                </div>
              </div>
              <h2 className='sub-header'>Checkboxes Inputs</h2>
              <div className='form-checkbox'>
                <div className='examples'>
                  <div className="checkbox">
                    <label>
                      Checkbox Example
                      <input type="checkbox" value="1" onChange={console.log("Checkbox clicked")}/>
                    </label>
                  </div>
                  <div className="checkbox">
                    <label>
                      Checkbox Disabled
                      <input type="checkbox" value="3" disabled readOnly/>
                    </label>
                  </div>
                </div>
                <div className='code-example'>
                  <pre>
                    <code className="language-markup">
                      {`
                        <div className='checkbox-group'>
                          <div className="checkbox">
                            <label>
                              Checkbox
                              <input type="checkbox" value="1" />
                            </label>
                          </div>
                          <div className="checkbox">
                            <label>
                              Checkbox Disabled
                              <input type="checkbox"  value="3" disabled/>
                            </label>
                          </div>
                        </div>
                      `}
                    </code>
                  </pre>
                </div>
              </div>
              <h2 className='sub-header'>Radio Inputs</h2>
              <div className='form-radio'>
                <div className='examples'>
                  <div className='radio'>
                    <label>
                      Radio Option 1
                      <input type="radio" name="radio-example" value="1" />
                    </label>
                  </div>
                  <div className='radio'>
                    <label>
                      Radio Option 2
                      <input type="radio" name="radio-example" value="2" onChange={console.log('radio button changes')} checked/>
                    </label>
                  </div>
                  <div className='radio'>
                    <label>
                      Radio Disabled</label>
                      <input type="radio" name="radio-example" value="3" disabled/>
                  </div>
                </div>
                <div className='code-example'>
                  <pre>
                    <code className="language-markup">
                      {`
                        <div className='radio-group'>
                          <div className='radio'>
                            <label>
                              Radio Option 1
                              <input type="radio" name="radio-example" value="1" />
                            </label>
                          </div>
                          <div className='radio'>
                            <label>
                              Radio Option 2
                              <input type="radio" name="radio-example" value="2" checked/>
                            </label>
                          </div>
                          <div className='radio'>
                            <label>
                              Radio Disabled
                              <input type="radio" name="radio-example" value="3" disabled/>
                            </label>
                          </div>
                        </div>
                      `}
                    </code>
                  </pre>
                </div>
              </div>
              <h2 className='sub-header'>Switch Inputs</h2>
              <div className='form-switch'>
                <div className='examples'>
                  <div>
                    <label>Switch example</label>
                    <Toggle
                      checked={this.state.switchToggle}
                      onUpdate={checked => this.setState({switchToggle: checked})}
                    />
                  </div>
                </div>
                <p>We have the <code>toggle.js.jsx</code> component built for the implementation of our switch toggle input.</p>
                <div className='code-example'>
                  <pre>
                    <code className="language-jsx">
                      {`
                        render(){
                          return(
                            <div>
                              <label>Switch Example</label>
                              <Toggle
                                checked={this.state.switchToggle}
                                onUpdate={checked => this.setState({switchToggle: checked})}
                              />
                            </div>
                          );
                        }
                      `}
                    </code>
                  </pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
LivingStyleGuideForms.displayName = 'LivingStyleGuide.Forms';
