import React from 'react';

export default class InfluencerDashboardContentSummaryTopPerformersRow extends React.Component {
  render() {
    const {
      metric,
      shopId,
      taskName,
      shopName,
      value,
    } = this.props

    var color = (metric == 'Views') ? 'background-color-blue' : 'background-color-purple'
    var badgeStyle = `badge content-badge text-size-small ${color}`;

    return (
      <div>
        <div className={badgeStyle}>{`Most ${metric}`}</div>
        <div className='flex-container'>
          <a className='mr-5' href={`/opportunities/${shopId}`} target='_blank' title='View Opportunity'>
            <div>{taskName}</div>
            <span className='text-color-subdued'>{shopName}</span>
          </a>
          <div className='align-right'>
            <div className='metric-value'>{value}</div>
            <span className='text-size-x-small'>{metric}</span>
          </div>
        </div>
      </div>
    );
  }
};

InfluencerDashboardContentSummaryTopPerformersRow.displayName = 'InfluencerDashboard.ContentSummary.TopPerformersRow';