import React from 'react'
import { last } from 'lodash';

export default class YoutubeEmbed extends React.Component {
  constructor(props) {
    super(props);

    this.getVideoId = this.getVideoId.bind(this);
  }

  getVideoId(){
    const {video_url} = this.props;

    //remove anything after a ? in the url, assuming the video ID is last part of URL
    return last(video_url.replace(/\?\S*/i, '').split('/'))
  }


  render() {
    const video_id = this.getVideoId() || null;

    return (
      <div className='youtube-embed-container'>
        {video_id &&
          <iframe
            width="100%"
            height="450"
            src={`https://www.youtube.com/embed/${video_id}?enablejsapi=1`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        }
      </div>
    )
  }
}
YoutubeEmbed.displayName = 'YoutubeEmbed';
