import React from 'react';

export default class Field extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: props.value
    }
    this.changeHandler = this.changeHandler.bind(this)
  }

  renderText(){
    const { value } = this.state
    const { fieldName, fieldType, required, id, disabled, placeholder } = this.props;

    return(
      <input  id={id || fieldName}
              name={fieldName}
              type={fieldType || 'text'}
              required={required}
              onChange={this.changeHandler}
              disabled={disabled}
              placeholder={placeholder}
              value={value || ''}/>
    )
  }

  render() {
    const { className, fieldName, label, required } = this.props;

    return (
      <div className={className}>
        { required && <abbr title="required">*</abbr> }
        <label htmlFor={fieldName}>
          { label }
        </label>
        { this.renderText() }
        { this.renderValidations() }
      </div>
    )
  }

  renderValidations() {
    const { error } = this.props;

    if (error === null) return null;
    return (
      <span className='text-danger'>
        {error}
      </span>
    )
  }

  changeHandler(event) {
    let value = event.target.value;
    this.setState({
      value: value
    }, () => this.props.onUpdate(value))
  }
}

Field.displayName = 'UserForm.Field';