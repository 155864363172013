class window.AdminCampaignQuestionnaires

  @show: ->
    $('#questionnaire-submit-btn').on 'click', ->
      $(this).toggleClass 'is-hidden'
      $('#questionnaire-loading').toggleClass 'is-hidden'

  @edit: ->
    $('.delete-question-checkbox').each ->
      $(this).addClass('is-hidden').after '<i class="delete-question-checkbox-icon mt-10 fas fa-lg fa-trash" />'

    $('.delete-question-checkbox-icon').on 'click', ->
      $(this).toggleClass 'selected'

    $('.questionnaire-save-btn').on 'click', ->
      $.validator.addClassRules({
        'question-text-input': { required: true },
        'question-format-input': { required: true }
      });

      if $('#campaign-questionnaire-form').valid()
        $('.questionnaire-save-btn, .questionnaire-saving-msg').toggleClass 'is-hidden'
