import React from 'react';
import { Provider } from "react-redux"
import AdminContentStore from '../redux/AdminContentStore'
import AdminInstagramPostPreview from './AdminInstagramPostPreview'

class AdminInstagramPostPreviewWrapper extends React.Component {
  render() {

    return <Provider store={AdminContentStore}>
      <AdminInstagramPostPreview/>
    </Provider>;
  }
};

AdminInstagramPostPreviewWrapper.displayName = 'Contents.AdminInstagramPostPreviewWrapper';
export default AdminInstagramPostPreviewWrapper