import React from 'react';
import InstructionsCampaignRequirement from './CampaignRequirement'

export default class InstructionsCampaignRequirements extends React.Component {
  render() {
    const {
      instructionNotes,
      updateRequirement,
      deleteRequirement
    } = this.props;

    if(!instructionNotes) {
      return null;
    }

    return <div>
      <h3 className='mb-20'>Campaign Requirements</h3>

      {
        instructionNotes.map(req =>
          <InstructionsCampaignRequirement
            key={req.id}
            requirement={req}
            updateRequirement={(change) => updateRequirement(req.id, change)}
            deleteRequirement={() => deleteRequirement(req.id)}
            />
        )
      }
    </div>
  }
};

InstructionsCampaignRequirements.displayName = 'Instructions.CampaignRequirements';
