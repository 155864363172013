import React from 'react';

export default class LivingStyleGuideButtons extends React.Component {

  componentDidMount(){
    Prism.highlightAll()
  }

  render() {
    return (
      <div id='buttons' className='style-guide-section'>
        <h1 className='section-header'>Buttons</h1>
        <div className='section-description'>
          <p>
            Solid colors are used on clickable elements to differentiate hierarchy and importance. Primary should
            always be used on the most important button on the page. Secondary buttons should use the link
            button style. Red is only used for an irreversable action, and only as a confirmation.
          </p>
          <p>
            White buttons with a drop shadow are used for buttons outside of a card.
          </p>
        </div>
        <div className='styles-container'>
          <div className='button-styles'>
            <div className='examples'>
              <button className='btn primary'>Primary</button>
              <button className='btn secondary'>Secondary</button>
              <button className='btn outline primary'>Outlined</button>
              <button className='btn floating'>Floating</button>
              <button className='btn success'>Success</button>
              <button className='btn danger'>Danger</button>
              <button className='btn disabled'>Disabled</button>
            </div>
            <div className='code-example'>
              <pre>
                <code className="language-markup">
                  {`
                    <div className='btn-group'>
                      <button className='btn primary'>Primary</button>
                      <button className='btn secondary'>Secondary</button>
                      <button className='btn outline primary'>Outlined</button>
                      <button className='btn floating'>Floating</button>
                      <button className='btn success'>Success</button>
                      <button className='btn danger'>Danger</button>
                      <button className='btn disabled'>Disabled</button>
                    </div>
                  `}
                </code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
LivingStyleGuideButtons.displayName = 'LivingStyleGuide.Buttons';