import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Remote } from '../utils'
import LoadingSpinner from '../LoadingSpinner'
import RoundTaskCommunitySelector from '../round_tasks/CommunitySelector'
import TableDeleteConfirmation from './DeleteConfirmation'
import TableRow from './Row'

class TableIndex extends React.Component {
  constructor(props) {
    super(props);

    this.loadCommunities = this.loadCommunities.bind(this);
    this.loadData = this.loadData.bind(this);
    this.displayRows = this.displayRows.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
  }

  componentDidMount() {
    this.loadCommunities();
    this.loadData();

    this.props.updateField('selectedCommunityId', this.props.selectedCommunityId);
  }

  componentDidUpdate(prevProps) {
    if(this.props.selectedCommunityId != prevProps.selectedCommunityId) {
      this.loadData();
    }
  }

  render() {
    return (
      <div>
        <RoundTaskCommunitySelector type={this.props.type}/>
        <table className='table table-striped'>
          <thead>
            <tr>
              {Object.values(this.props.columnLabels).map((col, i) => <th key={i}>{col}</th>)}
              <th className='align-right'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {!this.props.loading && this.displayRows()}
          </tbody>
        </table>
        {this.props.loading && <LoadingSpinner />}
        <TableDeleteConfirmation deletionUrl={this.props.deletionUrl}/>
      </div>
    );
  }

  loadCommunities() {
    this.props.updateField('communitiesLoading', true)
    const params = $.param({
      api_action: `/users/${this.props.currentUserId}/communities`
    });

    Remote().request({
      method: "GET",
      url: this.props.proxyUrl + '?' + params
    }).then(response => {
      this.props.updateField('communities', response.data)
      this.props.updateField('communitiesLoading', false)
    });
  }

  loadData() {
    this.props.startLoading();
    const params = $.param({...this.props.dataParams, community_id: [this.props.selectedCommunityId]});

    Remote().request({
      method: "GET",
      url: this.props.dataUrl + '?' + params
    }).then(response => {
      const results = response.data.active || response.data;
      this.props.updateField('data', results);
      this.props.stopLoading();
    });
  }

  displayRows(){
    if(this.props.data.length > 0){
      return this.props.data.map(d => <TableRow key={d.id} record={d} columns={Object.keys(this.props.columnLabels)} baseUrl={this.props.baseUrl}/>)
    }else{
      return <tr className='text-center'><td colSpan='100%'>No records found.</td></tr>
    }

  }

  static mapStateToProps(state, myProps) {
    return {
      ...state,
      ...myProps,

      // Prefer the selected community over the default passed in
      selectedCommunityId: state.selectedCommunityId || myProps.selectedCommunityId
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateField: (field, value) => dispatch({ type: 'UPDATE_FIELD', value: {field: field, value: value } }),
      startLoading: () => dispatch({ type: 'START_LOADING' }),
      stopLoading: () => dispatch({ type: 'STOP_LOADING' }),
    };
  }
};

TableIndex.displayName = 'Table.Index';
TableIndex.propTypes = {
  dataUrl: PropTypes.string.isRequired,
};

export default connect(
  TableIndex.mapStateToProps,
  TableIndex.mapDispatchToProps
)(TableIndex);
