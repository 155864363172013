import React from 'react';
import { connect } from 'react-redux';
import SubmissionLinkForm from './LinkForm'
import Upload from '../Upload'
import SubmissionDraftContentAttachment from './DraftContentAttachment'

class SubmissionManualInstagramSubmission extends React.Component {
  constructor(props) {
    super(props);

    this.addAnalytics = this.addAnalytics.bind(this);
    this.canFetch = this.canFetch.bind(this);
    this.render = this.render.bind(this);
    this.toggleManual = this.toggleManual.bind(this);
  }

  render() {
    if(!this.props.manual) {
      return <a className='submission manual-instagram-submission prompt' onClick={this.toggleManual}>
        Don't see your post? Click here to submit a link manually.
      </a>;
    }

    return <div className='submission manual-instagram-submission'>
      <SubmissionLinkForm canFetch={this.canFetch}>
      {
        <React.Fragment>
          <div className='manual-description'>
            In addition to your post link, please upload a screen shot displaying the number of views
            this Instagram post received.  The number of views MUST be visible to receive payment for
            this opportunity.  If you have any questions please message your CM!
          </div>

          <div className='manual-uploader'>
            {
              this.props.instagramAttachments.length == 0 &&
              <Upload
                uploadPath='/contents/create_attachment'
                fileParam='instagram_attachment'
                params={{
                  taskId: this.props.bloggerShopTask.id,
                  attachmentType: 'analytics',
                  type: 'instagram_attachment'
                }}
                fileTypes={'.jpg, .png'}
                callback={this.addAnalytics}
                />
            }
            {
              this.props.instagramAttachments.length != 0 &&
              <div className='submission content-grid content-count-1 instagram'>
                {
                  this.props.instagramAttachments.map((attachment, index) =>
                    <SubmissionDraftContentAttachment
                      key={attachment.id}
                      index={index}
                      readOnly={this.props.readOnly}
                      attachment={attachment}
                      deleteAttachment={() => this.props.deleteInstagramAttachment(attachment)}
                      />
                  )
                }
              </div>
            }
          </div>
        </React.Fragment>
      }
      </SubmissionLinkForm>
    </div>;
  }

  toggleManual(e) {
    e.preventDefault();
    this.props.setManual(!this.props.manual);
  }

  addAnalytics(image) {
    this.props.addInstagramAttachment(image);
  }

  canFetch() {
    return this.props.submission.url && this.props.instagramAttachments.length != 0;
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      bloggerShopTask: state.bloggerShopTask,
      manual: state.manual,
      submission: state.submission,
      instagramAttachments: state.instagramAttachments,
      readOnly: state.readOnly
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      setManual: flag => dispatch({ type: 'SET_MANUAL', value: flag }),
      deleteInstagramAttachment: attachment => dispatch({ type: 'DELETE_INSTAGRAM_ATTACHMENT', value: { attachment }}),
      addInstagramAttachment: attachments => dispatch({ type: 'ADD_INSTAGRAM_ATTACHMENT', value: attachments })
    };
  }
};

SubmissionManualInstagramSubmission.displayName = 'Submission.ManualInstagramSubmission';

export default connect(
  SubmissionManualInstagramSubmission.mapStateToProps,
  SubmissionManualInstagramSubmission.mapDispatchToProps
)(SubmissionManualInstagramSubmission);
