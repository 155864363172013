import React from 'react';
export default class LoadingSpinner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: this.props.text || ''
    };

    this.render = this.render.bind(this);
  }

  render() {
    if(!this.props.loading) {
      return;
    }

    return (
      <div>
        <i className='fas fa-circle-notch fa-spin' /> {this.state.text}
      </div>
    );
  }
};