import React from 'react';
import { Remote } from '../utils';
import notif from "javascripts/notifIt.js"
import LoadingSpinner from './LoadingSpinner'
import FacebookAdSetRow from './FacebookAdSetRow'
import DatatablePagination from '../DatatablePagination'

export default class FacebookAdCampaignTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      failed: false,
      currentPage: 1,
      perPage: 10,
      totalRecords: 0,
      displayStart: 0,
      adSets: []
    }
    this.render = this.render.bind(this);
    this.constructTableDataProp = this.constructTableDataProp.bind(this);
    this.loadAdSets = this.loadAdSets.bind(this);
    this.renderPagination = this.renderPagination.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
  }

  componentWillMount() {
    this.loadAdSets(this.state.currentPage, this.state.displayStart);
  }

  render() {
    const { loading, failed } = this.state;
    const campaign = this.props.facebookAdCampaign;

    if (loading || failed) {
      let iconName = loading ? null : 'fa-exclamation-triangle text-color-red'
      let text = failed ? 'Error' : null
      return <LoadingSpinner loading={true} icon={iconName} text={text} />;
    }

    return (
      <div>
        <h3>{campaign.name}</h3>

        <table className='table table-striped'>
          <thead>
            <tr>
              <th>Ad Set ID</th>
              <th>Notes</th>
              <th>Ad Set Name</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
              <th>Budget</th>
              <th>Goal</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.adSets.map((adSet) =>
                <FacebookAdSetRow key={adSet.id}
                                              adSet={adSet}
                                              delete={() => this.deleteAdSet(adSet.id)}
                                              campaignId={campaign.id}
                                              updateNotes={(text) => this.updateNotes(adSet.id, text)}
                                              waveState={this.props.waveState}/>
              )
            }
          </tbody>
        </table>
        {this.renderPagination()}
      </div>
    );
  }

  renderPagination() {
    return (
      <DatatablePagination
         currentPage={this.state.currentPage}
         handlePagination={this.loadAdSets}
         displayLength={this.state.perPage}
         tableData={this.constructTableDataProp()}
       />
    );
  }

  constructTableDataProp() {
    return (
      {
        iTotalDisplayRecords: this.state.totalRecords,
        aaData: this.state.adSets
      }
    );
  }

  loadAdSets(currentPage,displayStart) {
    let params = {
      resource: 'ad_sets',
      id: this.props.facebookAdCampaign.id,
      paginate: true,
      page: currentPage,
      per_page: this.state.perPage
    };

    Remote().request({
      url: `/admin/campaigns/${params['id']}/promoters_v2/api_proxy/facebook_ad_campaigns`,
      method: 'GET',
      data: params
    }).then((response) => {
      this.setState({
        adSets: response.data.ad_sets,
        totalRecords: response.data.meta.pagination.total_count,
        currentPage: currentPage,
        displayStart: displayStart,
        loading: false,
        failed: false
      });
    }).catch((error) => {
      this.setState({
        loading: false,
        failed: true
      });
    });
  }

  updateNotes(id, value) {
    Remote().request({
      url: `/admin/campaigns/${this.props.facebookAdCampaign.id}/promoters_v2/api_proxy/facebook_ad_sets?id=${id}`,
      method: 'PATCH',
      data: { notes: value }
    }).then(() => notif({
      msg: 'Notes updated',
      type: 'notice',
      timeout: 1000
    }));
  }
}
FacebookAdCampaignTable.displayName = 'FacebookAds.FacebookAdCampaignTable';
