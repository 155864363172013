class window.Profiles

  @settings: ->
    @date()
    Utils.character_limit('#profile_bio', 425)
    FormUtils.preview_image('#profile_user_attributes_profile_picture', '#profile-picture-preview')
    FormUtils.preview_remote_image('#profile_user_attributes_remote_profile_picture_url', '#profile-picture-preview')

  @settings_update: ->
    @date()

  @retailers_update: ->
    @retailers()

  @retailers: ->

    update_total = (that) ->
      sum = 0
      that.closest('.retailer-fields').find('.sum-fields:visible').each ->
        sum += parseInt $(this).find('.frequency-partial').val()
      that.closest('.retailer-fields').find('.frequency-total').val sum

    $('body').on 'change', '.frequency-partial', ->
      update_total( $(this) )

    $('body').on 'click', '.remove_fields', ->
      update_total( $(this) )

    $('body').on 'click', '.add_fields', ->
      setTimeout FormUtils.select_search, 1

  @blogs: ->
    FormUtils.format_inputs( $('input.number-with-commas') )

    $('a.add_fields').click ->
      setTimeout( FormUtils.select_search, 1 )

    $('form').on 'submit', (e) ->
      unless $('form.profile-form').valid()
        e.preventDefault()
      else
        FormUtils.remove_commas( $('input.number-with-commas') )

  @blogs_update: ->
    @blogs()

  @children: ->
    $('a.add_fields').click ->
      setTimeout( FormUtils.select_search, 1 )

  @children_update: ->
    @children()

  @social_media: ->
    FormUtils.social_network_links()

  @social_media_update: ->
    @social_media()

  @date: ->
    new ValidateDate.DateField( '.profile-save', ['profile_birthdate'] ).bind_values()

  @public: ->
    Utils.popover_overlay()
    @analytics_requests()
    FormUtils.social_network_links()

  @show: ->
    Utils.popover_overlay()
    @analytics_requests()
    FormUtils.social_network_links()

  @shops: ->
    DataTableUtils.datatable($('.datatable'),
      sDom: '<"dt-length col-xs-2 col-sm-3"l><"dt-filter col-xs-10 col-sm-5"f>rt<"dt-info col-xs-12 col-sm-4"i><"dt-pagination col-xs-12 col-sm-8"p>'
      aaSorting: []
      sAjaxSource: false
    )

  @analytics_requests: ->
    $(".refresh-analytics, .request-analytics-code").click ->
      blog_well = $(this).closest('.plugin-status')
      refresh_status_msg = $('.refresh-analytics').data('msg')
      request_property_error = $('.request-analytics-code').data('msg')
      $(this).addClass('disabled').html('<i class="fas fa-sync fa-spin fa-fw btn--icon-left"></i> Processing...')

      $.get($(this).data('url'), (data) =>
        blog_well.find("#analytics-status").html(data.analytics_status_message)
        blog_well.find("#raw-status").html(data.raw_status)

        if $(this).hasClass('request-analytics-code')
          blog_well.find("#analytics-code").html(data.analytics_code)
        else if $(this).hasClass('refresh-analytics')
          $(this).remove()
          blog_well.append(refresh_status_msg)
        ).fail => (
          if $(this).hasClass('request-analytics-code')
            blog_well.find("#analytics-status").html(request_property_error))

    $(".remove-orphaned-analytics-code").click ->
      r = confirm("**Warning, only do this if you have confirmed that the Google Analytics Property referenced is nonexistent")
      if (r == true)
        $(this).addClass('disabled').html('<i class="fas fa-sync fa-spin fa-fw btn--icon-left"></i> Processing...')
        $.get($(this).data('url'), (data) =>
            alert(data.message)
            document.location.reload(true)
          ).fail => (
            alert("There was an issue removing the analytics code.")
            )