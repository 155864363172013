import React from 'react';
import axios from 'axios';
import UserTableDashboardFilter from './DashboardFilter'
import UserTableDashboardTable from './DashboardTable'
import UserTablePagination from './Pagination'
import LoadingSpinner from '../LoadingSpinner'
class UserTableDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.updateSearch = this.updateSearch.bind(this)
    this.updateUrlParam = this.updateUrlParam.bind(this);
    this.refreshUsers = this.refreshUsers.bind(this);
    this.debouncedSearch = _.debounce(this.refreshUsers, 500);
  }

  componentDidMount() {
    this.refreshUsers();
  }

  updateSearch(newSearch) {
    this.props.setSearch(newSearch);
    this.debouncedSearch();
  }

  refreshUsers() {
    const { search, perPage, offset, proxyUrl } = this.props

    const params = `api_action=users&tab=dashboard&search=${search}&per_page=${perPage}&offset=${offset}`;
    axios.get(`${proxyUrl}?${params}`).then((response) => {
      this.props.updateStore({
        dashboard: response.data,
        loading_error: null,
        loading: false
      })
    }).catch((response) => {
      this.props.updateStore({
        loading_error: 'An error occurred.',
        loading: false
      })
    });
  }

  newRoute(param, value) {
    const oldPath = window.location.search || '?';
    const regex = new RegExp('('+param+'=)[^\&]+');
    let newPath = oldPath.replace(regex, '$1' + value)
    let newRoute = String(window.location).replace("#",'')
    if (!!window.location.search)
      newRoute = newRoute.replace(oldPath, newPath);
    else
      newRoute += `?${param}=${value}`
    return newRoute
  }

  updateUrlParam(param, value) {
    window.history.pushState({}, document.title, this.newRoute(param, value));
    this.props.startLoading();
    this.props.updateField(param, value)
    this.debouncedSearch();
  }

  render() {
    return (
      <div className='container main-container'>
        <UserTableDashboardFilter
          updatePerPage={(value) => this.updateUrlParam('perPage', value)}
          updateSearch={(value) => this.updateSearch(value)}
          {...this.props}
        />
        <div className='row'>
          <UserTableDashboardTable {...this.props} />
        </div>
        { this.props.loading && <LoadingSpinner/>}
        <UserTablePagination
          updatePage={(value) => this.updateUrlParam('offset', value)}
          {...this.props}
        />
      </div>
    )
  }
}
UserTableDashboard.displayName = 'UserTable.Dashboard';
export default UserTableDashboard;