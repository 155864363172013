import React from 'react';
import { connect } from 'react-redux';
import { Remote } from '../utils';

import SingleSelect from '../SingleSelect'
import MultiSelect from '../MultiSelect'
import ProfilesGoogleAnalyticsAuth from './GoogleAnalyticsAuth'

class ProfilesBlogForms extends React.Component {
  constructor(props) {
    super(props);

    this.renderBlogForm = this.renderBlogForm.bind(this);
    this.loadBlogOptions = this.loadBlogOptions.bind(this);
    this.updateField = this.updateField.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.validation = this.validation.bind(this);
    this.searchOptions = this.searchOptions.bind(this);
    this.inputHasError = this.inputHasError.bind(this);
    this.renderTextInput = this.renderTextInput.bind(this);
    this.addBlog = this.addBlog.bind(this);
    this.removeBlog = this.removeBlog.bind(this);
    this.renderSingleSelect = this.renderSingleSelect.bind(this);
    this.renderMultiSelect = this.renderMultiSelect.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    this.loadBlogOptions()
    this.validateFields()
  }

  loadBlogOptions() {
    const params = $.param({
      api_action: '/user_social_platforms/blog_options/'
    });

    return Remote().request({
      method: 'GET',
      url: this.props.proxyUrl + '?' + params,
      
    }).then((response) => {
      this.props.setBlogOptions(response.data);
    });
  }

  validateFields() {
    const { blogs } = this.props;
    let validations = {};
    let validatedCount = 0;
    const requiredFields = {
      name: {
        name: 'Name'
      },
      url: {
        name: 'URL'
      },
      blogging_platform_id: {
        name: 'Blog Platform'
      },
      language_id: {
        name: 'Language'
      },
      category_ids: {
        name: 'Category'
      }
    };

    const promise = () => new Promise((resolve) => {
      Object.keys(requiredFields).forEach((key) => {
        validatedCount++;
        const field = requiredFields[key];
        blogs.forEach((blog, index) => {
          const value = blog[key];
          if (!value || value.length === 0) {
            validations[index] = {
              ...validations[index],
              [key]: `${field.name} cannot be left blank.`
            };
          }
        });

        if (validatedCount === Object.keys(requiredFields).length) {
          resolve();
        }
      });
    });
    promise().then(() => {
      this.props.updateFrontendValidations({ 'Blogs': validations })
    });
  }

  addBlog() {
    const promise = () => new Promise((resolve) => {
      resolve(this.props.addBlog({}));
    });

    promise().then(() => {
      this.validateFields();
    });
  }

  removeBlog(index) {
    const promise = () => new Promise((resolve) => {
      resolve(this.props.removeBlog(index));
    });

    promise().then(() => {
      this.validateFields();
    });
  }

  validation(index, field) {
    const activeTabValidations = this.props.frontendValidations[this.props.activeTab] || {};
    const blogValidations = activeTabValidations['Blogs'] || {};
    const fieldValidations = blogValidations[index] ? blogValidations[index][field] : null;

    if(!fieldValidations) {
      return null;
    }

    return <span className='help-text no-prefix invalid'>
      {fieldValidations}
    </span>;
  }


  updateField(index, field, value) {

    const promise = () => new Promise((resolve) => {
      resolve(this.props.updateBlogField(index, field, value));
    });

    promise().then(() => {
      this.validateFields();
    });
  }

  searchOptions(field, query, callback) {
    const results = _.filter(
      this.props[field],
      value => value.name.toLowerCase().includes(query.toLowerCase())
    );
    callback(results);
  }

  inputHasError(index, field) {
    if(this.validation(index, field)) {
      return true;
    }

    return false;
  }


  renderSingleSelect(index, label, field, options=field) {
    return(
      <div className='input-wrap'>
        <label>{label}</label>
        <SingleSelect
          hasError={this.inputHasError(field)}
          search={(query, callback) => this.searchOptions(options, query, callback)}
          selected={this.props.blogs[index][field] || ''}
          onUpdate={(item) => this.updateField(index, field, item)}
          placeholder={`Select ${label}`}
        />
        {this.validation(index, field)}
      </div>
    )
  }

  renderMultiSelect(index, label, field, options=field) {
    return (
      <div className='input-wrap'>
        <label>{label}</label>
        <MultiSelect
          hasError={this.inputHasError(field)}
          limit={2}
          search={(query, callback) => this.searchOptions(options, query, callback)}
          selected={this.props.blogs[index][field] || []}
          onUpdate={(item) => this.updateField(index, field, item)}
          placeholder={`Select ${label}`}
        />
        {this.validation(index, field)}
      </div>
    )
  }

  renderTextInput(index, label, field, value) {
    return(
      <div className='input-wrap'>
        <label>{label}</label>
        <input
          type='text'
          placeholder={label}
          value={value}
          onChange={e => this.updateField(index, field, e.target.value)}
        ></input>
        {this.validation(index, field)}
      </div>
    )
  }


  renderBlogForm(blog, index) {
    return (
      <div className='blog-form' key={index}>
        {this.renderTextInput(index, 'Name', 'name', blog.name)}
        {this.renderTextInput(index, 'URL', 'url', blog.url)}
        {this.renderSingleSelect(index, 'Platform', 'blogging_platform_id', 'bloggingPlatforms')}
        {this.renderSingleSelect(index, 'Language', 'language_id', 'languages')}
        {this.renderMultiSelect(index, 'Category (choose 1 or 2)', 'category_ids', 'categories')}
        <ProfilesGoogleAnalyticsAuth blog={blog} analyticsTooltip={this.props.analyticsTooltip}/>
        <div className='remove-button-wrapper'>
          <div className='btn btn-danger' onClick={()=> this.removeBlog(index)} >
            Remove Blog
          </div>
        </div>
        <hr/>
      </div>
    );
  }

  render() {
    const blogs = this.props.blogs || []
    const hasBlogs = blogs.length > 0

    return (
      <React.Fragment>
        <div className='card-plus connected-networks-form-card'>
          <div className='card-header'>Connected Blogs</div>
          {
            hasBlogs &&
            <div className='card-body'>
              {this.props.blogs.map((blog, index) => {
                  return this.renderBlogForm(blog, index)
              })}
            </div>
          }
          <div className='card-footer'>
            <div className='btn primary' onClick={()=> this.addBlog()} >
              Add Blog
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      activeTab: state.activeTab,
      frontendValidations: state.frontendValidations,
      proxyUrl: state.meta.proxyUrl,
      blogs: state.blogs,
      bloggingPlatforms: state.bloggingPlatforms,
      languages: state.languages,
      categories: state.categories
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateFrontendValidations: validations => dispatch({ type: 'UPDATE_FRONTEND_VALIDATIONS', value: validations }),
      addBlog: value => dispatch({ type: 'ADD_BLOG', value: value }),
      removeBlog: value => dispatch({ type: 'REMOVE_BLOG', value: value }),
      setBlogOptions: value => dispatch({ type: 'SET_BLOG_OPTIONS', value: value }),
      toggleFetching: () => dispatch({ type: 'TOGGLE_FETCHING' }),
      setErrorMessage: error => dispatch({ type: 'SET_ERROR_MESSAGE', value: error }),
      updateBlogField: (index, field, value) => dispatch({ type: 'UPDATE_BLOG_FIELD', value: {index: index, field: field, value: value}})
    }
  }
};



ProfilesBlogForms.displayName = 'Profiles.BlogForms';
export default connect(
  ProfilesBlogForms.mapStateToProps,
  ProfilesBlogForms.mapDispatchToProps
)(ProfilesBlogForms);

