import React from 'react';

export default class SelectInput extends React.Component {
  constructor(props) {
    super(props);

    let value =[]
    this.props.placeholder === null ? value.push(this.props.options[0]) : value

    this.state = {
      options: this.props.options ? this.props.options : [],
      showOptions: false,
      value: value
    }

    this.offClickHandler = this.offClickHandler.bind(this);
  }

  componentDidUpdate(prevProps) {
    if(this.props.options !== prevProps.options){
      this.setState({
        options: this.props.options,
        value: []
      })
    }
  }

  render() {

    return (
      <div className='input_wrap'>
        { this.renderSelect() }
      </div>
    )
  }

  renderSelect() {
    const { fieldName, placeholder} = this.props;
    const { options, showOptions, value } = this.state;
    const renderPlaceHolder = <div className='placeholder' onClick={() => this.toggleOptions()}>{placeholder}</div>;
    const renderSelected = this.renderOptions(value, 'selected');
    const renderOptions = this.renderOptions(options, 'options');

    return (
      <div className={`form-control select ${showOptions ? 'focus' : ''}`} >
        <input type='hidden'
               name={fieldName}
               value={value}/>
        {value.length > 0 ? renderSelected : renderPlaceHolder}
        {showOptions && renderOptions}
      </div>
    )
  }

  renderOptions(options, type) {
    let placeholder = this.props.placeholder !== null ? <li className='list_item' onClick={() => this.selectHandler(null)} >{this.props.placeholder}</li> : null


    if ( options.length > 0 ) {
      return (
        <div  className={type} onClick={() => type === 'selected' ? this.toggleOptions() : '' }>
          <ul>
            {type === 'options' && placeholder}
            {
              options.map((item, key) =>
                <li className='list_item' key={key} onClick={() => type === 'options' ? this.selectHandler(item) : ''}>
                  {item.name}
                </li>
              )
            }
          </ul>
        </div>
      )
    }
  }

  selectHandler(option) {
    let value = [];

    if (option !== null) {
      value.push(option);
    }

    this.toggleOptions();
    this.changeHandler(value);
  }

  toggleOptions() {
    this.setState({showOptions: !this.state.showOptions});
    document.addEventListener('click', this.offClickHandler);
  }

  offClickHandler(e) {
    const isListItem = this.state.options.indexOf(e.target.innerText) !== -1;

    if (!isListItem  && this.state.showOptions) {
      this.setState({ showOptions: false})
    }

    document.removeEventListener('click', this.offClickHandler);
  }

  changeHandler(value) {
    this.setState({value: value}, () => {
      this.props.selectCallback(this.props.fieldName, value);
    });
  }
}