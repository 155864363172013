import React from 'react';

export default class Group extends React.Component {
  render(){
    const {group_name} = this.props.notification
    return(
      <React.Fragment>The <b>{group_name}</b> group has been archived.</React.Fragment>
    )
  }
}
Group.displayName = 'Notification.Templates.Group'