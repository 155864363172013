import React from 'react';
import { connect } from 'react-redux';

const defaultSort = "bestMatch";

class SortSelect extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      popupOpen: false
    }

    this.popup = this.popup.bind(this);
    this.cancel = this.cancel.bind(this);
    this.reset = this.reset.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.sortOpts = this.sortOpts.bind(this);
    this.sorts = this.sorts.bind(this);
    this.render = this.render.bind(this);
    this.radioChecked = this.radioChecked.bind(this);
  }

  sortOpts(){
    return {
              bestMatch: {
                label: "Best Match",
                descriptionText: '',
                sort: [ ['fit_score'], ['desc']]
              },
              newestDate: {
                label: "Date Listed",
                descriptionText: 'Newest First',
                sort: [['created_at'], ['desc']]
              },
              lowestDare: {
                label: "Date Listed",
                descriptionText: 'Oldest First',
                sort: [['created_at'], ['asc']]
              },
              lowestTime: {
                label: "Time",
                descriptionText: "Lowest First",
                sort: [['cut_off_date', 'id'], ['asc', 'asc']]
              },
              highestTime: {
                label: "Time",
                descriptionText: "Highest First",
                sort: [['cut_off_date', 'id'], ['desc', 'desc'] ]
              },
              lowestPay: {
                label: "Pay",
                descriptionText: "Lowest First",
                sort: [['minimum_payment'], ['asc']],
              },
              highestPay: {
                label: "Pay",
                descriptionText: "Highest First",
                sort: [['maximum_payemnt'], ['desc']]
              }
            };
  }

  cancel(){
    this.togglePopup();
  }

  togglePopup(){
    this.setState({ popupOpen: !this.state.popupOpen }, () => {
      if ( document.body.clientWidth < 575 ) {
        document.body.style.overflow = this.state.popupOpen ? 'hidden' : 'auto';
      }
    })
  }

  reset(){
    this.props.selectSort(defaultSort);
    this.handleChange(defaultSort)
  }

  handleChange(term){
    this.props.setSort(term);
    this.props.onChangeCallback({
      sortBy: this.sortOpts()[term].sort,
      sortLabel: this.sortOpts()[term].label
    });
    this.togglePopup();
  }

  radioChecked(term){
    if(term == this.props.sortSelected){
      return 'checked'
    }else{
      return ''
    };
  }


  sorts(){
    return _.map(this.sortOpts(), (value, term) => {
      return(
        <div className="sort" key={term} onClick={()=> this.props.selectSort(term)}>
          <div className="name">
            { value.label }
            { value.descriptionText && <span className="label-description"> &#8213; {value.descriptionText}</span> }
          </div>
          <div className='radio' key={term}>
            <input type='radio'
                   name='sort'
                   value={term}
                   checked={this.radioChecked(term)}
                   onClick={()=> this.props.selectSort(term)}/>
          </div>
        </div>
      );
    });
  }

  popup(){
    return( <React.Fragment>
      <div className="shoppertunities-sort--overlay"></div>
      <div className="card popup shoppertunities-sort--card">
        <div className="card-header">
          <div><a onClick={()=> this.cancel()}>Cancel</a></div>
          <div><h2>Sorts</h2></div>
          <div><a onClick={()=> this.reset()}>Reset</a></div>
        </div>
        <div className="card-body">
          {this.sorts()}
        </div>
        <div className="card-footer">
          <div className="btn primary" onClick={()=> this.handleChange(this.props.sortSelected)} >
            See Opportunities
          </div>
        </div>
      </div>
      </React.Fragment>
      );
  }

  render(){
    return(
      <div>
        <button onClick={()=> this.togglePopup()} className="pull-right btn floating" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
          <div className="shoppertunities-sort--button">
            {this.sortOpts()[this.props.sortSet].label}
            <i className="fas fa-caret-down"></i>
          </div>
        </button>
        {this.state.popupOpen && this.popup()}
      </div>
    )
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      sortSelected: state.sortSelected,
      sortSet: state.sortSet
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      selectSort: sort => dispatch({ type: 'SELECT_SORT', value: sort }),
      setSort: sort => dispatch({ type: 'SET_SORT', value: sort })
    };
  }
};

SortSelect.displayName = 'SortSelect';

export default connect(
  SortSelect.mapStateToProps,
  SortSelect.mapDispatchToProps
)(SortSelect);

