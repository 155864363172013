import React from 'react';
import { Remote } from '../utils';
import notif from "javascripts/notifIt.js"
import moment from 'moment';

class SchedulerRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment(props.scheduler.start_date).format('YYYY-MM-DD'),
      endDate: moment(props.scheduler.end_date).format('YYYY-MM-DD'),
      showError: false,
      budget: props.scheduler.budget,
      budgetPerAdSet: props.scheduler.budget_per_ad_set,
      goalPerAdSet: props.scheduler.goal_per_ad_set,
      goal: props.scheduler.goal,
      totalGoal: props.scheduler.total_goal
    };

    this.debouncedSync = _.debounce(this.syncWithServer.bind(this), 1000);

    this.buttonClass = this.buttonClass.bind(this);
    this.buttonText = this.buttonText.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    this.dateOrFallback = this.dateOrFallback.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.isCustom = this.isCustom.bind(this);
    this.notifyParents = this.notifyParents.bind(this);
    this.render = this.render.bind(this);
    this.renderBudget = this.renderBudget.bind(this);
    this.renderValidations = this.renderValidations.bind(this);
    this.syncWithServer = this.syncWithServer.bind(this);
    this.updateBudget = this.updateBudget.bind(this);
    this.updateDate = this.updateDate.bind(this);
    this.dateColumn = this.dateColumn.bind(this);
    this.validate = this.validate.bind(this);
    this.cleanNumber = this.cleanNumber.bind(this);
  }

  componentDidMount() {
    this.notifyParents();
  }

  componentWillReceiveProps(nextProps) {
    const currentScheduler = this.props.scheduler;
    const newScheduler = nextProps.scheduler;

    if(
      currentScheduler.budget_per_ad_set == newScheduler.budget_per_ad_set &&
      currentScheduler.goal == newScheduler.goal &&
      currentScheduler.goal_per_ad_set == newScheduler.goal_per_ad_set
    ) {
      return;
    }

    this.setState({
      budgetPerAdSet: newScheduler.budget_per_ad_set,
      goal: newScheduler.goal,
      goalPerAdSet: newScheduler.goal_per_ad_set
    }, () => this.notifyParents());
  }

  dateColumn(columnName){
    dateValue = this.state[columnName]
    if(this.props.editing){
      return <input className='form-control'
                    type='date'
                    value={this.dateOrFallback(dateValue)}
                    onChange={(e) => this.updateDate(columnName, e.target.value)}/>
    }else{
      return dateValue
    };
  }

  render() {
    const sched = this.props.scheduler;

    return (
      <tr>
        <td>
          {sched.promoter_ad_scheduler_type.name}
        </td>
        <td>
          {this.dateColumn('startDate')}
        </td>
        <td>
          {this.dateColumn('endDate')}
        </td>
        <td>
          {sched.number_of_ad_sets}
        </td>
        <td>
          {this.renderBudget()}
        </td>
        <td>
          <MonetaryValue value={this.state.budgetPerAdSet}/>
        </td>
        <td>
          <HumanValue value={this.state.goalPerAdSet}/>
        </td>
        <td>
          <HumanValue value={this.state.goal}/>
        </td>
        <td>
          <HumanValue value={this.state.totalGoal}/>
        </td>
        <td>
          <button className={this.buttonClass()} onClick={this.props.openTargeting}>
            {this.buttonText()} <i className='fas fa-edit'/>
          </button>
        </td>
        {
          this.props.editing &&
          <td className='text-center'>
            {this.renderValidations()}
          </td>
        }
      </tr>
    );
  }

  buttonText() {
    return this.isCustom() ? 'Custom' : 'Default';
  }

  buttonClass() {
    return this.isCustom() ? 'btn btn-success btn-sm' : 'btn btn-default btn-sm';
  }

  isCustom() {
    const checkedKeys = [
      'geo_targeting',
      'genders',
      'custom_audiences',
      'excluded_publisher_categories',
      'age_min',
      'age_max'
    ];
    const targeting = this.props.scheduler.targeting;

    return checkedKeys.some(key => !_.isEqual(targeting[key], SchedulerRow.DEFAULT_TARGETING[key]));
  }

  updateDate(key, value) {
    this.setState({
      [key]: value
    }, () => this.debouncedSync());
  }

  syncWithServer() {
    Remote().request({
      url: this.props.schedulerEndpoint,
      method: 'PATCH',
      data: {
        start_date: this.formatDate(this.state.startDate),
        end_date: this.formatDate(this.state.endDate),
        budget: this.state.budget || 0
      },
    }).then(response => {
      notif({
        msg: 'Scheduler updated',
        type: 'notice',
        timeout: 1000
      });

      this.setState({
        budgetPerAdSet: response.data.budget_per_ad_set
      }, () => this.notifyParents());

      this.props.refresh();
    });
  }

  formatDate(date) {
    return moment(date, 'YYYY-MM-DD').toDate();
  }

  // Account for the 'invalid date' string that sometimes make it into the form
  dateOrFallback(string) {
    if(!string || string == 'Invalid date') {
      return '';
    }

    return string;
  }

  renderValidations() {
    const renderError = (message) => (
      <span onMouseEnter={() => this.setState({ showError: true })}
            onMouseLeave={() => this.setState({ showError: false })}
            style={{ position: 'relative' }}>
        <i style={{fontSize: '12pt'}} className='fas fa-exclamation-triangle text-danger'/>

        {this.state.showError &&
          <FloatingBox type='error' message={message}/>
        }
      </span>
    );

    const validation = this.validate();
    if(validation) {
      return renderError(validation);
    }

    return null;
  }

  validate() {
    const start = this.formatDate(this.state.startDate);
    const end = this.formatDate(this.state.endDate);

    if(Number(this.state.budget) > Number(this.props.scheduler.available_budget)) {
      return 'Budget cannot exceed the available budget';
    }

    if(this.props.scheduler.number_of_ad_sets > 0) {
      if(!start || !end || start == 'Invalid Date' || end == 'Invalid Date') {
        return 'Schedulers must have a start and end date';
      }

      if(start >= end) {
        return 'Schedulers must have a start date at least 24 hours before the end date';
      }

      if(end < new Date()) {
        return 'Schedulers must have an end date in the future';
      }
    }

    return null;
  }

  renderBudget() {
    if(this.props.editing){
      return(
        <div className='input-group' style={{width: '200px'}}>
          <div className='input-group-addon'>$</div>
          <input className='form-control' value={this.state.budget} onChange={this.updateBudget}/>
          <div className='input-group-addon'>
            / <MonetaryValue value={this.props.scheduler.available_budget}/>
          </div>
        </div>
      );
    }else{
      return(
        <div>
          <MonetaryValue value={this.state.budget}/> / <MonetaryValue value={this.props.scheduler.available_budget}/>
        </div>
      );
    };
  }

  // Notify parents of validations through the redux store
  notifyParents() {
    this.props.dispatch({
      type: 'UPDATE_SCHEDULER_VALIDATIONS',
      value: {
        id: this.props.scheduler.id,
        validation: this.validate()
      }
    });
  }

  updateBudget(e) {
    this.setState({
      budget: this.cleanNumber(e.target.value)
    }, () => this.debouncedSync());
  }

  cleanNumber(str) {
    if(str == '') {
      return '';
    }

    return Number(String(str).split('').filter(x => x.match(/[0-9\.]/)).join(''));
  }
};

// This is the default targeting setup to compare against, ripped
// directly out of a JSON response from the ad automation service
SchedulerRow.DEFAULT_TARGETING = {
  "genders": ["1","2"],
  "age_min":18,
  "age_max":65,
  "geo_locations": {
    "countries":["US"]
  },
  "custom_audiences": null,
  "excluded_publisher_categories": [
    "dating",
    "debated_social_issues",
    "gambling",
    "tragedy_and_conflict",
    "mature_audiences"
  ]
};

export default connect(null, null)(SchedulerRow);

