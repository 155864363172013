import React from 'react';
import InstructionsReadOnlyRevisionNotice from './RevisionNotice'
import InstructionsReadOnlyCard from './Card'
import InstructionsReadOnlyRequirementCard from './RequirementCard'
import InstructionsIndependentContractorAgreement from '../IndependentContractorAgreement'
import Guardrails   from '../../Guardrails'
import GuardCaption from '../../GuardCaption'

export default class InstructionsReadOnlyContainer extends React.Component {
  constructor(props) {
    super(props);

    this.renderContent = this.renderContent.bind(this);
    this.render = this.render.bind(this);
    this.titleCase = this.titleCase.bind(this);
  }

  renderContent(html) {
    const contentStyle = {
      padding: '10px 15px'
    };

    return(
      <div style={contentStyle} dangerouslySetInnerHTML={{__html: html}}/>
    )
  }

  renderGuardCaption = (configProps) => {
    if (configProps.guardCaptions.length) {
      return (
        <GuardCaption
          tagClassName={configProps.tagClassName}
          placeHolder={configProps.placeHolder}
          instructionId={this.props.instruction_id}
          shoppertunityId={this.props.shoppertunity_id}
          proxy_url={this.props.proxy_url}
          operator={configProps.check_operator}
          guardCaptions={configProps.guardCaptions}
          readOnly={true}
        />
      );
    } else {
      return (
        <div className="text-color-subdued info-box">No captions have been added</div>
      );
    }
  }

 titleCase(str) {
  return str.toLowerCase().split(' ').map(function (word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
}

  render() {
    const showRevisionNotes = (this.props.state == 'pending_update');
    let saved_guardrails = this.props.saved_guardrails;

    const contentCaptionConfigProps = [
      {
        key: 'dos',
        title: "Content Captions - Do's",
        tagClassName: 'tag-item-success',
        placeHolder: "Add Captions - Do's",
        check_operator: true,
        guardCaptions: this.props.guard_caption_dos
      },
      {
        key: 'donts',
        title: "Content Captions - Dont's",
        tagClassName: 'tag-item-danger',
        placeHolder: "Add Captions - Don'ts",
        check_operator: false,
        guardCaptions: this.props.guard_caption_donts
      }
    ]

    return (
      <div id='instructionsReadOnly'>
        {
          this.props.showRevisionNotice && <div id='revision_notes'>
            <InstructionsReadOnlyRevisionNotice
            revisionNotes={this.props.revisionNotes}
            updateAt={this.props.updateAt}/>
          </div>
        }
        <div id='header'>
          <InstructionsReadOnlyCard title={'EXHIBIT A (Instructions)'} showTitle={this.props.showHeaderTitle}>
            {this.renderContent(this.props.header)}
          </InstructionsReadOnlyCard>
        </div>
        <div className='campaign_requirement'>
          <div className='campaign_requirement_title'>
            <h3>Campaign Requirements</h3>
          </div>
          {
            this.props.instructionNotes.map(note =>
              <InstructionsReadOnlyCard key={note.id} title={note.label}>
                <p className='mt-10 col-xs-12' id={`instruction_note_${note.id}`} dangerouslySetInnerHTML={{__html: note.description}}/>
              </InstructionsReadOnlyCard>
            )
          }
        </div>
        { saved_guardrails.length ? 
          <InstructionsReadOnlyCard title='Additional Guardrails'>
            {this.renderContent("<strong>Please ensure that your content does not include these following:</strong>")}
            <div className="radio">
            <table>
              <tbody>
                <tr>
                  <Guardrails guardrails={saved_guardrails} readOnly={true}
                    instructionId={this.props.instruction_id} proxy_url={this.props.proxy_url}> 
                  </Guardrails>
                </tr>
              </tbody>
            </table>
            </div>
          </InstructionsReadOnlyCard>
         : '' }

        {this.props.display_guard_captions && contentCaptionConfigProps.map((configProps) => (
          <InstructionsReadOnlyCard key={configProps.key} title={configProps.title}>
            {this.renderGuardCaption(configProps)}
          </InstructionsReadOnlyCard>
        ))}

        <div id='content_requirements'>
          <h3>Content Requirements</h3>
          {this.props.requirements.map((requirement, index) =>
            <InstructionsReadOnlyRequirementCard
              requirement={requirement}
              key={requirement.id}
              hideVerification={true}
            />
          )}
        </div>
        <div id='terms_and_agreement'>
          <InstructionsReadOnlyCard title={'Accept or Decline this Opportunity'} showTitle={this.props.showFooterTitle}>
            {this.renderContent(this.props.footer)}
          </InstructionsReadOnlyCard>
        </div>
        <InstructionsIndependentContractorAgreement text={this.props.ica} height={this.props.height || 300} />
        {
          showRevisionNotes &&
          <div id='revision_notes'>
            <InstructionsReadOnlyCard title={'Instruction Updates'}>
              {this.renderContent(this.props.revisionNotes)}
            </InstructionsReadOnlyCard>
          </div>
        }
      </div>
    );
  }
}

InstructionsReadOnlyContainer.displayName = 'Instructions.ReadOnly.Container';
