import React from 'react';
import { Remote } from '../utils';

export default class CampaignsTimeline extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.timeline.id,
      name: this.props.timeline.name,
      description: this.props.timeline.description,
      dueDate: this.props.timeline.due_date,
    }

    this.debouncedUpdate = _.debounce(this.updateTimeline, 500);

    this.render = this.render.bind(this);
    this.onChange = this.onChange.bind(this);
    this.updateTimeline = this.updateTimeline.bind(this);
    this.getFormFields = this.getFormFields.bind(this);
    this.renderField = this.renderField.bind(this);
    this.formClass = this.formClass.bind(this);
    this.renderTextArea = this.renderTextArea.bind(this);
    this.renderTextBox = this.renderTextBox.bind(this);
    this.renderDateBox = this.renderDateBox.bind(this);
    this.renderRemoveButton = this.renderRemoveButton.bind(this);
    this.validate = this.validate.bind(this);
    this.renderValidations = this.renderValidations.bind(this);
  }

  getFormFields(){
    return(
    [
      {
        displayName: 'Name',
        fieldName: 'name',
        fieldType: 'text',
        required: true
      },
      {
        displayName: 'Description',
        fieldName: 'description',
        fieldType: 'textarea'
      },
      {
        displayName: 'Due Date',
        fieldName: 'dueDate',
        fieldType: 'datepicker',
        required: true
      },
      {
        fieldType: 'removeButton'
      },
    ]);
  }

  renderField(field) {
    switch(field.fieldType) {
      case 'text':
        return this.renderTextBox(field);
      case 'textarea':
        return this.renderTextArea(field);
      case 'datepicker':
        return this.renderDateBox(field);
      case 'removeButton':
        return this.renderRemoveButton();
    }
  }

  formClass(field) {
    const hasError = !!this.renderValidations(field);
    return hasError ? 'has-error' : '';
  }

  renderTextArea(field){
    return(
      <td className={this.formClass(field)}>
        <div>
          <textarea className="form-control" key={field.fieldName} onChange={(e) => this.onChange(e, field.fieldName)} value={this.state[field.fieldName]}/>
          {this.renderValidations(field)}
        </div>
      </td>
    );
  }

  renderTextBox(field){
    return(
    <td className={this.formClass(field)}>
      <div>
        <input className="form-control" key={field.fieldName} type="text" onChange={(e) => this.onChange(e, field.fieldName)} value={this.state[field.fieldName]}/>
        {this.renderValidations(field)}
      </div>
    </td>
    );
  }

  renderDateBox(field){
    return(
      <td className={this.formClass(field)}>
        <div>
          <input className="form-control" key={field.fieldName} type="date" onChange={(e) => this.onChange(e, field.fieldName)} value={this.state[field.fieldName]}/>
          {this.renderValidations(field)}
        </div>
      </td>
    )
  }

  renderRemoveButton(){
    return(
      <td>
        <div>
          <a className="icon--remove remove_fields remove-task" onClick={() => this.props.removeTimeline(this.state.id)}>
          <i className="fas fa-minus-circle fa-fw fa-lg text-danger"></i></a>
        </div>
      </td>
    );
  }

  onChange(event, fieldName) {
    let result = {};
    result[fieldName] = event.target.value;
    this.setState(result, () => {
      result.valid = this.validate();
      if(result.valid){
        this.debouncedUpdate();
      }
    });
  }

  updateTimeline(){
    this.props.saveIndicatorState(true, 'info', 'Saving timeline...', null);

    const _this = this;
    const updateTimelinePath = `/admin/campaigns/${_this.props.campaignId}/update_timeline`
    Remote().request({
      url: updateTimelinePath,
      method: 'POST',
      data: {
        timeline_id: _this.state.id,
        name: _this.state.name,
        description: _this.state.description,
        due_date: _this.state.dueDate
      },
      contentType: 'application/json',

    }).then((response) => {
      if(this.state.id == null){
        _this.setState({id: response.data.id})
      }
      this.props.flash({
        saveText: 'Successfully saved timeline.',
        saveType: 'success'
      }, 3000);
    }).catch((error) => {
      this.props.flash({
        saveText: 'Failed to save timeline! Please correct these errors:',
        saveType: 'failure',
        saveErrors: error
      }, 5000);
    });
  }

  validate() {
    // If we don't render anything for a field validation, it is valid
    return this.getFormFields().every((field) => !this.renderValidations(field));
  }

  renderValidations(field) {
    if(field.fieldType == 'removeButton'){
      return;
    }

    const fieldValue = this.state[field.fieldName];

    // Simple case, just display a message that the field is required
    if (field.required && !fieldValue) {
      return (
        <span className='text-danger'>
          This field is required
        </span>
      );
    }

    // More complex case -- try and match a regex, and if it fails display a custom message
    if (field.regex && field.regexMessage) {
      if(!(fieldValue || '').match(field.regex)) {
        return (
          <span className='text-danger'>
           {field.regexMessage}
          </span>
        );
      }
    }
  }

  flash(newState, duration) {
    this.setState(newState, () => setTimeout(() => this.setState({
      saving: false
    }), duration));
  }

  render() {
    return (
        <tr className="js-task-row">
            {this.getFormFields().map((field) => this.renderField(field))}
        </tr>
    );
  }
}
