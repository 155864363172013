import React from 'react';
import Previewer from './Previewer'

export default class CopyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: props.adData,
      loadingPreview: true
    };
    this.props.validations.form = this
    this.doBind();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      fields: nextProps.adData
    })
  }

  render() {
    const format = this.props.adFormat;
    const validations = this.props.validations;

    return (
      <div className='modal-inner-content'>
        <div className='wizard-copy-editor'>
          <Previewer
              wizardState={this.props.wizardState}
              url={this.state.fields.utm}
              text={this.state.fields.text}
              headline={this.fieldArray('headline')} // always an array
              description={this.fieldArray('description')} // always an array
              imageHash={this.isCarousel() ? this.fieldArray('image_hash') : this.props.imageHash}
              adFormat={format}
              previewEndpoint={this.props.previewEndpoint}
              finishCallback={() => this.setState({loadingPreview: false})}
          />
          <div className='copy-form section right'>
            <div className='section-header'>
              <label>Ad Copy</label>
              {
                this.props.toggleAdType !== undefined && 
                this.props.wizardState.ad_type_formats.length > 1 &&
                <button className='btn btn-default ad-format-type' 
                        disabled={this.state.loadingPreview} 
                        onClick={this.changeAdCopyFormat } 
                        style={{flex: 0}}>
                  { format }
                </button>
              }
            </div>
            <div className='form-group section-body overflow-scroll'>
              { this.validatedFormField('Text', 'text') } 
              { this.isCarousel() && this.renderCarouselFields() }
              { this.isImage() && this.validatedFormField('Headline', 'headline') }
              { this.isImage() && this.validatedFormField('Description', 'description') }
            </div>
            <div className='section-footer'>
              <button className='btn btn-default' 
                      onClick={ this.save } 
                      disabled={ !validations.isValid() }>
                { this.props.saveText }
              </button>
            </div>
          </div>
        </div>
        <div className='modal-next'>
          <button className='btn btn-primary' 
                  onClick={ this.submit} 
                  disabled={ !validations.isValid() }>
            { this.props.submitText}
          </button>
        </div>
      </div>
    )
  }

  changeAdCopyFormat() {
    this.save();
    this.props.toggleAdType();
  }

  renderCarouselFields() {
    const images = this.props.adData.images;

    const containerStyle = {
      display: 'flex'
    }

    const containerImage ={
        margin: '10px 10px 0 0',
        width: '100px',
        objectFit: 'contain',
        alignSelf: 'flex-start'
    }

    return (
      <div style={{overflow: 'auto', maxHeight: '324px'}}>
      { 
        Object.keys(images).map((fbHash, index) => (
          <div style={containerStyle} key={index}>  
            <img style={containerImage} src={images[fbHash]}/> 
            <div style={{flex: '1 1 auto'}} >
              { this.validatedFormField('Headline', `headline-${fbHash}`) }  
              { this.validatedFormField('Description', `description-${fbHash}`) }
            </div>
          </div>
        ))
      }
    </div>
    )
  }

  formField(displayName, fieldName) {
    const fieldStyle = 'ad-' + fieldName.split('-')[0].replace(/[^A-z]/g, '');
    let fieldInput = <input  type='text' defaultValue={this.state.fields[fieldName]} 
                            className={`form-control ${fieldStyle}`}
                            onChange={(e) => this.updateFormField(e, fieldName)}/>
    if (fieldStyle === 'ad-description' || fieldStyle === 'ad-text' )
      fieldInput = <textarea rows={3} defaultValue={this.state.fields[fieldName]}
                              className={`form-control ${fieldStyle}`}
                              onChange={validations.createUpdateListener(fieldName)}/>
    return [
      <label key={`label-${fieldName}`}>{ displayName }</label>,
      <div key={`input-${fieldName}`}>{ fieldInput }</div>
    ]
  }

// updates unvalidated form fields
  updateFormField(event, field){
    this.setState({
      fields: {
        ...this.state.fields,
        [field]: event.target.value
      }
    })
  }

  validatedFormField(displayName, fieldName) {
    const validations = this.props.validations;
    const fieldStyle = 'ad-' + fieldName.split('-')[0].replace(/[^A-z]/g, '');
    let fieldInput = <input  type='text' value={this.state.fields[fieldName]} 
                            className={`form-control ${fieldStyle}`}
                            onChange={validations.createUpdateListener(fieldName)}/>
    if (fieldStyle === 'ad-description' || fieldStyle === 'ad-text' )
      fieldInput = <textarea rows={3} value={this.state.fields[fieldName]}
                              className={`form-control ${fieldStyle}`}
                              onChange={validations.createUpdateListener(fieldName)} />
    return [
      <label key={`label-${fieldName}`}>{ displayName }</label>,
      <div key={`input-${fieldName}`}>{ fieldInput }</div>,
      <div key={`validation-${fieldName}`}>{ validations.renderValidations(fieldName) }</div>
    ]
  }

  payload() {
    let data = {images: {}};
    const {fields} = this.state
    if (this.isCarousel()){
      Object.keys(fields.images).forEach((key) => {
        data.images[key] = {};
      });
      Object.keys(fields).forEach((key) => {
        fieldKey = key.split('-');
        fieldName = fieldKey[0];
        if (/(headline|description)/i.test(fieldKey[0])){
          if (fieldKey[1] === undefined)
            data.images[fieldKey[0]] = fields[key];
          else
            data.images[fieldKey[1]][fieldKey[0]] = fields[key] || '';
        }
      })
      data['carousel_text'] = fields.text;
      if (this.props.showUtm)
        data['utm'] = fields.utm;
    } else {
      data = fields;
    }
    return data
  }

  save() {
    this.props.saveCallback({
      id: this.props.wizardState.id,
      ...this.payload()
    });
  }

  submit() {
    this.props.submitCallback({
        id: this.props.wizardState.id,
        ...this.payload()
    });
  }

  fieldArray(fieldName){
    let data = []
    const img_hashes = Object.keys(this.state.fields.images).sort();

    if (fieldName === 'image_hash') {
      data = img_hashes;
    } else {
      img_hashes.forEach((hash) => {
        data.push(this.state.fields[`${fieldName}-${hash}`] || this.state.fields[fieldName] || '')
      })
    }
    return data
  }

  isCarousel(){
    return this.props.adFormat === 'carousel';
  }

  isImage(){
    return !this.isCarousel()
  }

  doBind(){
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    this.render = this.render.bind(this);
    this.fieldArray = this.fieldArray.bind(this);
    this.isCarousel = this.isCarousel.bind(this);
    this.isImage = this.isImage.bind(this);
    this.save = this.save.bind(this);
    this.submit = this.submit.bind(this);
    this.payload = this.payload.bind(this);
    this.formField = this.formField.bind(this);
    this.renderCarouselFields = this.renderCarouselFields.bind(this);
    this.changeAdCopyFormat = this.changeAdCopyFormat.bind(this);
    this.updateFormField = this.updateFormField.bind(this);
  }
}
