import React from 'react';
import SubmissionRequirements from './Requirements'
import SubmissionSubmitButton from './SubmitButton'

export default class SubmissionBottomBar extends React.Component {
  render() {
    return (
      <div className='submission bottom-bar'>
        <div className='fixed'>
          <SubmissionRequirements />
          <div className='submit'>
            <SubmissionSubmitButton />
          </div>
        </div>
        <div className='relative'></div>
      </div>
    )
  }
};

SubmissionBottomBar.displayName = 'Submission.BottomBar';


