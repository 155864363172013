import React from 'react';
import { connect } from 'react-redux';
import UsersCategoryChip from './CategoryChip'

class UsersCategorySettings extends React.Component {
  constructor(props) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);
    this.render = this.render.bind(this);
  }

  render() {
    const {
      availableCategories,
      categories,
      removeCategory,
      i18n
    } = this.props;

    const usedIds = categories.map(cat => cat.id);
    const unusedCategories = availableCategories.filter(cat => !_.includes(usedIds, cat.id));

    return <div className='card-plus account-settings category-settings'>
      <div className='card-header'>{i18n.labels.category_settings}</div>

      <div className='card-body'>
        <p className='category-description'>
          {i18n.descriptions.categories}
        </p>

        <select className='form-control' value={''} onChange={this.handleSelect}>
          <option value='' disabled>
            {i18n.labels.default_category}
          </option>
          {
            unusedCategories.map(cat => <option key={cat.id} value={cat.id}>{cat.name}</option>)
          }
        </select>

        <div className='selected-categories'>
          {
            categories.map(cat =>
              <UsersCategoryChip key={cat.id} category={cat} onClick={removeCategory} />
            )
          }
        </div>
      </div>
    </div>
  }

  handleSelect(e) {
    e.preventDefault();
    const id = Number(e.target.value);
    const newValue = this.props.availableCategories.find(cat => cat.id == id);
    this.props.addCategory(newValue);
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      availableCategories: state.availableCategories,
      categories: state.settings.notification_setting.categories || [],
      i18n: state.meta.i18n
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      addCategory: value => dispatch({ type: 'ADD_CATEGORY', value }),
      removeCategory: value => dispatch({ type: 'REMOVE_CATEGORY', value })
    };
  }
};

UsersCategorySettings.displayName = 'Users.CategorySettings';
export default connect(
  UsersCategorySettings.mapStateToProps,
  UsersCategorySettings.mapDispatchToProps
)(UsersCategorySettings);

