import React from 'react';
import { connect } from 'react-redux';
import { Remote } from '../utils';

import NavigationTabsWrapper from '../navigation_tabs/Wrapper'
import ProgressBar from '../ProgressBar'
import Alert from '../Alert'
import ProfilesSaveBar from './SaveBar'
import ProfilesConnectedNetworks from './ConnectedNetworks'
import ProfilesContactInfo from './ContactInfo'
import ProfilesDemographics from './Demographics'
import ProfilesPaymentWrapper from './PaymentWrapper'
import ProfilesBasicInfo from './BasicInfo'

class ProfilesEdit extends React.Component {
  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.getTabs = this.getTabs.bind(this);
    this.loadProfile = this.loadProfile.bind(this);
    this.loadDemographicOptions = this.loadDemographicOptions.bind(this);
    this.render = this.render.bind(this);
    this.renderActiveTab = this.renderActiveTab.bind(this);
 }

  componentDidMount() {
    this.props.setMeta({
      proxyUrl: this.props.proxyUrl,
      username: this.props.username,
      i18n: this.props.i18n,
      accountSettingsUrl: this.props.accountSettingsUrl,
      updatePaymentUrl: this.props.updatePaymentUrl,
      profileUrl: this.props.profileUrl,
      advertiserAuth: this.props.advertiserAuth,
      gaAuthUrl: this.props.gaAuthUrl,
      gaConnectTooltip: this.props.gaConnectTooltip,
      noGaProperty: this.props.noGaProperty,
    });
    this.props.setActiveTab(this.props.activeTab);

    Promise.all([
      this.loadProfile(),
      this.loadCountries(),
      this.loadDemographicOptions()
    ]).then(result => {
      this.props.toggleFetching();
    }).catch(error => {
      this.props.showError(this.props.i18n.errors.ajax_failure);
    });
  }

  render() {
    const {
      i18n,
      loading,
      fetching,
      activeTab,
      alert,
      profile
    } = this.props;

    return <div>
      <NavigationTabsWrapper
        menuName={i18n.labels.page_title}
        tabs={this.getTabs()}
        counts={{}}
        errors={ProfilesEdit.tabValidations(i18n, profile)}
        order={Object.values(this.getTabs())}
        activeTab={activeTab}
        onClickCallback={tab => this.props.setActiveTab(tab)}
        />
      {(loading || fetching) && <ProgressBar />}
      {
        !loading && !fetching &&
        <div className='profiles edit-container'>
          {this.renderActiveTab()}
          <ProfilesSaveBar/>
        </div>
      }
      {
        alert.show &&
        <Alert type={`${alert.type} fixed-top`} callback={this.props.closeError}>
          {alert.message}
        </Alert>
      }
    </div>;
  }

  renderActiveTab() {
    const { i18n, activeTab } = this.props;

    switch(activeTab) {
    case i18n.navigation.connected_networks:
      return <ProfilesConnectedNetworks { ...this.props }/>;
    case i18n.navigation.contact:
      return <ProfilesContactInfo />;
    case i18n.navigation.demographics:
      return <ProfilesDemographics />;
    case i18n.navigation.payment:
      return <ProfilesPaymentWrapper />;
    case i18n.navigation.basic:
    default:
      return <ProfilesBasicInfo />;
    }
  }

  loadProfile() {
    const params = $.param({
      api_action: `/profile/${this.props.username}`
    });

    return Remote().request({
      method: 'GET',
      url: this.props.proxyUrl + '?' + params,

    }).then((response) => {
      this.props.initializeData({
        ...response.data,
        socialAccounts: response.data.social_accounts,
        authConnectUrls: response.data.auth_connect_urls,
        deauthUrls: response.data.deauth_urls
      });
    });
  }

  loadCountries() {
    const params = $.param({
      api_action: '/countries/'
    });

    return Remote().request({
      method: 'GET',
      url: this.props.proxyUrl + '?' + params,

    }).then((response) => {
      this.props.setCountries(response.data);
    });
  }

  loadDemographicOptions() {
    const params = $.param({
      api_action: '/demographics/'
    });

    return Remote().request({
      method: 'GET',
      url: this.props.proxyUrl + '?' + params,

    }).then((response) => {
      this.props.initializeData(response.data);
    });
  }

  getTabs() {
    const { i18n, profile } = this.props;
    let tabs = [i18n.navigation.basic];
    if(!profile.agent_role){
      tabs = [
        ...tabs,
        i18n.navigation.connected_networks,
        i18n.navigation.contact,
        i18n.navigation.demographics
      ]
    }

    if(!profile.no_payment) {
      tabs = [...tabs, i18n.navigation.payment];
    }

    return tabs.reduce((prev, tab) => {
      prev[tab] = tab;
      return prev;
    }, {});
  }

  // This method is static so that we can do the same rollup in other parts of this section
  // of the application.
  static tabValidations(i18n, profile) {
    if(!_.get(profile, 'validations')) {
      return {};
    }

    let demographicData = {
      ...profile.validations.retailers,
      ...profile.validations.interests,
      ...profile.validations.demographics
    };
    if(Object.keys(demographicData).length == 0) {
      demographicData = undefined;
    }

    return {
      [i18n.navigation.basic]: profile.validations.settings,
      [i18n.navigation.connected_networks]: profile.validations.social_media,
      [i18n.navigation.contact]: profile.validations.contact,
      [i18n.navigation.demographics]: demographicData,
      [i18n.navigation.payment]: profile.validations.payment_settings
    };
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      profile: state.profile,
      loading: state.loading,
      fetching: state.fetching,
      alert: state.alert,
      activeTab: state.activeTab || myProps.activeTab
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      initializeData: data => dispatch({ type: 'INITIALIZE_DATA', value: data }),
      toggleLoading: () => dispatch({ type: 'TOGGLE_LOADING' }),
      toggleFetching: () => dispatch({ type: 'TOGGLE_FETCHING' }),
      setErrorMessage: error => dispatch({ type: 'SET_ERROR_MESSAGE', value: error }),
      setActiveTab: tab => dispatch({ type: 'SET_ACTIVE_TAB', value: tab }),
      showError: message => dispatch({ type: 'UPDATE_ALERT', value: { show: true, message, type: 'error' } }),
      closeError: () => dispatch({ type: 'UPDATE_ALERT', value: { show: false } }),
      setMeta: meta => dispatch({ type: 'SET_META', value: meta }),
      setCountries: countries => dispatch({ type: 'SET_COUNTRIES', value: countries })
    };
  }
};

ProfilesEdit.displayName = 'Profiles.Edit';
export default connect(
  ProfilesEdit.mapStateToProps,
  ProfilesEdit.mapDispatchToProps
)(ProfilesEdit);
