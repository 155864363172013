import React from 'react';
import { connect } from 'react-redux';
import Tooltip from '../Tooltip';

class ShoppertunitiesRoundsSelected extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showSelectRoundTooltip: false}

    this.roundsNotSelected = this.roundsNotSelected.bind(this);
    this.roundsSelected = this.roundsSelected.bind(this);
    this.roundsSelectedMessage = this.roundsSelectedMessage.bind(this);
    this.submitButton = this.submitButton.bind(this);
    this.toggleSelectRoundTooltip = this.toggleSelectRoundTooltip.bind(this);
    this.apply = this.apply.bind(this);
    this.render = this.render.bind(this);
  }

  selectMoreRoundsMessage(){
    const { meta } = this.props;
    if(this.props.selectedRounds.length != this.props.shoppertunity.rounds.length){
    return <span>{meta.I18n.shop_posting.select_another}</span>
    }
  }

  roundsSelectedMessage(){
    const { meta, selectedRounds, shoppertunity } = this.props;
    if(shoppertunity.can_apply &&
       shoppertunity.campaign.draft_dashboard_display == 'draft_review' &&
       shoppertunity.rounds.length == 1)
    {
      return <div>
        <div dangerouslySetInnerHTML={{ __html: meta.I18n.shop_posting.brand_managed }}/>
      </div>
    }
    else
    {
      return <div>
        <i className="fas fa-thumbs-up fa-fw"/> You selected {selectedRounds.length} of {shoppertunity.rounds.length} round{shoppertunity.rounds.length > 1 ? 's' : ''}. {this.selectMoreRoundsMessage()}
      </div>
    }
  }

  toggleSelectRoundTooltip(){
    this.setState({ showSelectRoundTooltip: !this.state.showSelectRoundTooltip })
  }

  roundsNotSelected(){
    const { meta, shoppertunity } = this.props;
    return <div className="round_not_selected">
      <span className="hidden-sm hidden-xs">
        <i className="fas fa-info-circle fa-fw" />
        {shoppertunity.require_applications ? meta.I18n.shop_posting.apply : meta.I18n.shop_posting.join}
      </span>
      <button className="btn default hidden-md hidden-lg" onClick={()=> history.go(-1)}>Cancel</button>
      <a className="btn btn-default position-relative"
        onMouseLeave={() => this.toggleSelectRoundTooltip()}
        onMouseEnter={() => this.toggleSelectRoundTooltip()}>
        {meta.I18n.shop_posting.continue}
        {this.state.showSelectRoundTooltip && <Tooltip centerText={true} placement='top' message={meta.I18n.shop_posting.help_text.select_round}/>}
      </a>
    </div>
  }

  apply(){
    const roundIds = Array.prototype.map.call(this.props.selectedRounds, s => s.id).toString();
    const url = `/opportunities/${this.props.meta.shoppertunityId}/apply?selected_rounds=${roundIds}`;
    window.location.href = url;
  }

  submitButton(){
    const { meta, shoppertunity } = this.props;
    let disabled = '';
    if(shoppertunity.platform.name == 'Instagram'){
      if(!shoppertunity.instagram_requirements_met){
        disabled = 'disabled opacity-65';
      }
    }
    const css = `btn btn-default ${disabled}`
    if(shoppertunity.require_applications){
      return <button onClick={()=>this.apply()} disabled={disabled} className={css}>{meta.I18n.shop_posting.continue_and_apply}<i className="fas fa-arrow-circle-right"/></button>
    }else{
      return <button onClick={()=>this.apply()} disabled={disabled} className={css}>{meta.I18n.shop_posting.join_this_round}<i className="fas fa-arrow-circle-right"/></button>
    }
  }

  roundsSelected(){
    return <div className="round_is_selected">
        {this.roundsSelectedMessage()}
        {this.submitButton()}
      </div>
  }

  render() {
    const { selectedRounds } = this.props;
    return <div>
      {selectedRounds.length > 0 && this.roundsSelected()}
      {selectedRounds.length == 0 && this.roundsNotSelected()}
    </div>
  }

  static mapStateToProps(state, myProps){
    return {
      ...myProps,
      meta: state.meta,
      shoppertunity: state.shoppertunity,
      selectedRounds: state.selectedRounds
    };
  }
};

ShoppertunitiesRoundsSelected.displayName = 'Shoppertunities.RoundsSelected';
ShoppertunitiesRoundsSelected.propTypes = {
};

export default connect(
  ShoppertunitiesRoundsSelected.mapStateToProps
)(ShoppertunitiesRoundsSelected);
