import { createStore } from 'redux';

  const initialState = {
    adsetValidations: [],
    schedulerValidations: [],
    advertiserAccess: [],
    validationsVisible: false,
    loading: false,
    contentId: null,
    stateId: null,
    contentUrl: null,
    contentUtm: null,
    contentType: null,
    userId: null,
    externalLink: null,
    showWizard: false,
    showCustomWizard: false,
    shopId: null,
    roundId: null,
    rounds: [],
    shoppertunities: [],
    publishing: false,
    adType: null,
    editingLookup: {},
    updateTime: new Date(),
    campaignId: null,
    datatableUrl: '',
    customAdsDatatableUrl: ''
  };

  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case 'UPDATE_SCHEDULER_VALIDATIONS':
        return {
          ...state,
          schedulerValidations: _.uniqBy([action.value].concat(state.schedulerValidations), x => x.id)
        };
      case 'UPDATE_ADSET_VALIDATIONS':
        return {
          ...state,
          adsetValidations: _.uniqBy([action.value].concat(state.adsetValidations), x => x.id)
        };
      case 'VALIDATION_VISIBILITY':
        return {
          ...state,
          validationsVisible: action.value
        };
      case 'REMOVE_ADSET':
        return {
          ...state,
          adsetValidations: state.adsetValidations.filter(x => x.id != action.id)
        };
      case 'UPDATE_ACCESS':
        return {
          ...state,
          advertiserAccess: _.uniqBy([
            ...action.value,
            ...state.advertiserAccess
          ], x => x.sofab_user_id)
        };
      case 'UPDATE_STATE':
        return {
          ...state,
          ...action.value
        };
      case 'UPDATE_AUTH_BY_ID':
        // If there aren't any matching elements in the list, append directly to the list
        if(!_.includes(state.advertiserAccess.map(x => x.sofab_user_id), action.value.id)) {
          return {
            ...state,
            advertiserAccess: [
              ...state.advertiserAccess,
              {
                sofab_user_id: action.value.id,
                ...action.value.data
              }
            ]
          };
        }

        return {
          ...state,
          advertiserAccess: state.advertiserAccess.map(a => {
            if(a.sofab_user_id != action.value.id) {
              return a;
            }

            return {
              ...a,
              ...action.value.data
            };
          })
        };
      default:
        return state;
    }
  };

  const FacebookAdStore = createStore(
    reducer,
    window && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ name: 'Redux.FacebookAdStore' }) // Enable redux devtools middleware
  );

  export default FacebookAdStore;