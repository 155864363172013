import React from 'react';
import { FileUploader } from './utils';

class UploadStoryMedia extends React.Component {
  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.preventDefaults = this.preventDefaults.bind(this);
    this.toggleDragOver = this.toggleDragOver.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
    this.dropFiles = this.dropFiles.bind(this);
    this.chooseFiles = this.chooseFiles.bind(this);
    this.renderDefault = this.renderDefault.bind(this);
    this.renderUploading = this.renderUploading.bind(this);
    this.renderSuccess = this.renderSuccess.bind(this);
    this.renderFailure = this.renderFailure.bind(this);
    this.render = this.render.bind(this);
    this.uploadArea = this.uploadArea.bind(this);
    this.deleteMedia = this.deleteMedia.bind(this);
    this.mountDragEvents = this.mountDragEvents.bind(this);

    this.state = {
      uploading: false,
      dragOver: false,
      success: false,
      failure: false,
      errors: []
    }
  }

  componentDidMount() {
    this.mountDragEvents();
  }

  componentDidUpdate() {
    this.mountDragEvents();
  }

  mountDragEvents() {
    let uploadArea = document.getElementById('uploadArea');
    if(!uploadArea) { return; }

    ['drag', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(eventName => {
      uploadArea.addEventListener(eventName, this.preventDefaults, false);
    });

    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      uploadArea.addEventListener(eventName, this.toggleDragOver, false);
    });

    uploadArea.addEventListener('drop', this.dropFiles, false);
  }

  preventDefaults() {
    event.preventDefault();
    event.stopPropagation();
  }

  toggleDragOver() {
    this.setState({ dragOver: event.type === 'dragenter' || event.type === 'dragover' ? true : false});
  }

  uploadFile(file, resolve) {
    let formData = new FormData();
    let files = this.props.uploadedFiles;
    formData.append('instagram_attachment', file);
    formData.append('task_id', this.props.task.id)

    FileUploader().request({
      url: '/contents/create_instagram_media',
      method: 'POST',
      data:  formData,
      contentType: false,
      processData: false
    })
    .then((response) => {
      resolve({status: 'success', file: file.name});
      files.push(response.data.media);
      this.props.updateUploadedFiles(files);
    })
    .catch(() => {
      resolve({status: 'failure', file: file.name});
    });
  }

  handleFiles(files) {
    this.setState({
      success: false,
      failure: false,
      errors: []
    });

    if (files.length > 0) {
      this.setState({ uploading: true });

      let uploads = ([...files]).map((file) => {
        return new Promise((resolve) => {
          this.uploadFile(file, resolve);
        });
      })

      Promise.all(uploads).then((results) => {
        errors = [];

        results.forEach((arr) => {
          if (arr.status === 'failure') {
            errors.push(arr.file);
          }
        });

        if (errors.length > 0) {
          this.setState({
            failure: true,
            uploading: false,
            errors: errors
          });
        } else {
          this.setState({
            success: true,
            uploading: false
          });

          setTimeout(() => {
            this.setState({ success: false });
          }, 3000);
        }
      });
    }
  }

  dropFiles() {
    this.handleFiles(event.dataTransfer.files);
  }

  chooseFiles() {
    document.getElementById('chooseFile').click();
  }

  renderDefault() {
    return (
      <div className='upload_message upload_message--default '>
        <i className='fas fa-cloud-upload-alt' aria-hidden='true'></i>
        <span className='text'>Drop files here to upload or <a>choose files</a></span>
      </div>
    );
  }

  renderUploading() {
    return (
      <div className='upload_message upload_message--uploading'>
        <i className='fas fa-circle-notch fa-spin' aria-hidden='true'></i>
        <span className='text'>Uploading&hellip;</span>
      </div>
    );
  }

  renderSuccess() {
    return (
      <div className='upload_message upload_message--success'>
        <i className='fas fa-check-circle' aria-hidden='true'></i>
        <span className='text'>Upload Successful!</span>
      </div>
    );
  }

  renderFailure() {
    return (
      <div className='upload_message upload_message--failure'>
        <i className='fas fa-times-circle' aria-hidden='true'></i>
        <div className='errors'>
          {this.state.errors.map((error, i) => <span key={i}>{error}</span>)}
        </div>
        <span className='text'>Failed to upload.</span>
      </div>
    );
  }

  displayUploadedFiles(){
    let flex_container = {
      paddingTop: '10px',
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'center'
    }

    return(
      <div className="uploads-container" style={flex_container}>
        {this.props.uploadedFiles.map((media, i) => <UploadStoryMediaCard key={i} media={media} deleteMedia={this.deleteMedia}/>)}
      </div>
    );
  }

  deleteMedia(id){
    let _this = this;
    let formData = new FormData();
    formData.append('media_id', id);

    FileUploader().request({
      url: '/contents/destroy_instagram_media',
      method: 'POST',
      data:  formData,
      contentType: false,
      processData: false
    })
    .then(() => {
      let files = _this.props.uploadedFiles.filter(story => story.id !== id)
      this.props.updateUploadedFiles(files);
    });
  }

  renderState(){
    let renderState = this.renderDefault();

    if(this.state.uploading){
      renderState = this.renderUploading();
    }else if(this.state.success){
      renderState = this.renderSuccess();
    }else if(this.state.failure){
      renderState = this.renderFailure();
    }
    return renderState;
  }

  uploadArea(){
    let dragOverClass = this.state.dragOver ? 'hover' : '';

    let paragraph_style = {
      padding: '15px 0',
      fontSize: '16px',
      lineHeight: '20px'
    }

    return(
      <div>
        <div>
            <h4 style={paragraph_style}>Please upload all images and videos as listed in <a href={this.props.shopInstructions}>opportunity instructions.</a> All shares must include a screenshot displaying the number of views + the raw video or image file. Supported file types are .jpg, .png, .mov, and .mp4. Before you click submit, please ensure that all files are uploaded and displaying on the screen. When choosing files, use the ctrl or command button to select multiple.</h4>
          </div>
          <form id='uploadArea'
                className={`upload_area ${dragOverClass}`}
                onClick={() => this.chooseFiles()}>
            {this.renderState()}
            <input id='chooseFile'
                  className='upload_file'
                  type='file'
                  onChange={(e) => this.handleFiles(e.target.files)}
                  accept='.jpg, .jpeg, .png, .mov, .mp4, .gif'
                  style={{'display': 'none'}}
                  multiple />
          </form>
          <br />
          <label>Required Disclosure:</label> <i className='fas fa-question-circle d-inline-block'
                                                 data-toggle='tooltip'
                                                 data-placement='bottom'
                                                 title={this.props.adPopupText} />
          <br />
          <ul>
            <li>ad</li>
          </ul>
          {this.displayUploadedFiles()}
        </div>
    );
  }

  render() {
    const drop_down_style = {
      color: '#187bd1',
      textAlign: 'center'
    };

    if(this.props.instagramConnected){
      return (
        <div>
          <div onClick={() => this.props.toggleManualSubmission() } style={drop_down_style}><h4>Don't see your stories for this opportunity? Click here to submit manually <i className={`fas ${this.props.hideManualSubmissionIcon}`} /></h4> </div>
          {
            this.props.hideManualSubmission ? "" : this.uploadArea()
          }
          <br />
        </div>
      );
    }
    return null
  }
}