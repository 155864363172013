import React from 'react';
import { FileUploader } from '../utils';

export default class Cropper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cropper: null,
      aspectRatio: 1.0,
    };

    this.render = this.render.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.initCropper = this.initCropper.bind(this);
    this.tearDownCropper = this.tearDownCropper.bind(this);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    this.rotateImage = this.rotateImage.bind(this);
    this.cancel = this.cancel.bind(this);
    this.zoomImage = this.zoomImage.bind(this);
    this.renderToolbar = this.renderToolbar.bind(this);
    this.saveImage = this.saveImage.bind(this);
    this.getProxiedImageUrl = this.getProxiedImageUrl.bind(this);
  }

  componentDidMount() {
    this.initCropper();
  }

  componentWillReceiveProps(nextProps) {
    this.tearDownCropper();
    setTimeout(() => this.initCropper());
  }

  changeAspectRatio(aspect = 1.0) {
    this.setState({
        aspectRatio: aspect
    }, () => { this.state.cropper.setAspectRatio(aspect) })

  }
  render() {
    return (
      <div className='image-editor section right'>
        <div className='cropper-target section-body'>
          {this.renderContent()}
        </div>

        {this.renderToolbar()}
      </div>
    );
  }

  renderContent() {
    if (!this.props.imageUrl) {
      return (
        <div className='text-muted placeholder-text'>
          Select an image to start editing
        </div>
      );
    }

    return <img className='image' src={this.getProxiedImageUrl()} />;
  }

  renderToolbar() {
    if(!this.props.imageUrl) {
      return(
        <div className='section-footer toolbar blank'></div>
      );
    }
    const { aspectRatio } = this.state;
    return (
      <div>
        <div className='section-footer toolbar'>
          <div className='controls'>
            <button className={ `btn btn-${(aspectRatio===1.0 ? 'primary' : 'default')}`} onClick={() => this.changeAspectRatio(1.0)} >
              Square (1:1)
            </button>
            <button className={ `btn btn-${(aspectRatio===1.91 ? 'primary' : 'default')}`} onClick={() => this.changeAspectRatio(1.91)} >
              Horizontal (1.91:1)
            </button>
          </div>
        </div>
        <div className='section-footer toolbar'>
          <button className='btn btn-default' onClick={this.saveImage}>
            <i className='fas fa-save' /> Save Edits
          </button>
          <div className='controls'>
            <i className='fas fa-expand' onClick={() => this.zoomImage(0.1)} />
            <i className='fas fa-compress' onClick={() => this.zoomImage(-0.1)} />
            <i className='fas fa-undo' onClick={() => this.rotateImage(-90)} />
            <i className='fas fa-undo fa-flip-horizontal' onClick={() => this.rotateImage(90)} />
            <i className='fas fa-times' onClick={() => this.cancel(false)} />
          </div>
        </div>
      </div>
    );
  }

  initCropper() {
    if (!this.props.imageUrl) {
      return;
    }
    const element = ReactDOM.findDOMNode(this);
    const editor = element.getElementsByClassName('image')[0];
    const aspect = this.state.aspectRatio || Cropper.ASPECT_RATIO
    this.setState({
      cropper: new Cropper(editor, {
        aspectRatio: aspect,
        checkCrossOrigin: false
      })
    });
  }

  tearDownCropper() {
    if (!this.state.cropper || !this.props.imageUrl) {
      return;
    }

    this.state.cropper.destroy();
    this.setState({
      cropper: null
    });
  }

  rotateImage(degrees) {
    this.state.cropper.rotate(degrees);
  }

  zoomImage(ratio) {
    this.state.cropper.zoom(ratio);
  }

  saveImage() {
    const canvas = this.state.cropper.getCroppedCanvas();
    const base64 = canvas.toDataURL('image/png');

    // Newer browser method -- an AJAX call that returns a promise
    fetch(base64).then((response) => response.blob())
      .then((blob) => this.uploadBlob(blob));
  }

  cancel(unselect) {
    this.props.unsetImage(unselect);
  }

  uploadBlob(blob) {
    const formData = new FormData();
    formData.append('uploaded_image', blob);
    formData.append('promoter_ad_state_id', this.props.wizardStateId);
    FileUploader().request({
      url: this.props.uploadEndpoint,
      method: 'POST',
      contentType: false,
      processData: false,
      data: formData
    }).then((response) => {
      this.props.addNewUrl(response.data.url);
      this.cancel(true);
    });
  }

  getProxiedImageUrl() {
    const encodedUrl = btoa(this.props.imageUrl);

    return `/admin/promoter_ad_states/proxy/${encodedUrl}`;
  }
};

Cropper.ASPECT_RATIO = 1.0;
