import axios from "axios";

export const isAscii = (url) => {
  try {
    return /^[\x00-\x7F]*$/.test(decodeURIComponent(url));
  } catch(e) {
    console.log(e);
    return false;
  }
}

export const QueryString = (params) => Object.keys(params).map((key) => {
  return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
}).join('&');

export const CsrfToken = () => {
  const node = document.querySelector('meta[name=csrf-token]');
  return node.attributes.content.value;
}

export const Csrf = () => {
  return { authenticity_token: CsrfToken() };
}

export const Remote = (contentType = 'application/json') => {
  const defaultOptions = {
    headers: {
      'content-type': contentType,
      'accept': 'application/json'
    },
    transformRequest: [(data, _headers) => {
      // Do whatever you want to transform the data
      return { ...data, ...Csrf() };
    }, ...axios.defaults.transformRequest ]

  }
  return axios.create(defaultOptions)
}

export const FileUploader = () => {
  const defaultOptions = {}
  return axios.create(defaultOptions)
}
export const TitleCase = (str) => {
  return str.toLowerCase().split(' ').map(function (word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
}
