import React from 'react';

export default class DraftContent extends React.Component {
  render(){
    const {activity, user_name, shoppertunity_name} = this.props.notification;
    const P = React.Fragment;
    switch(activity) {
      case 'accepted':
        return (
          <P>
            Your blog post draft for {shoppertunity_name} has been approved!
            Copy the html or text from Social Fabric as some minor changes may have been made and paste it into your blog. Please do not make your blog post live until the blog post due date.
          </P>
        )
      case 'client_accepted':
        return (
          <P><b>{user_name}</b>'s Blog Draft for <b>{shoppertunity_name}</b> has been client approved!</P>
        )
      case 'rejected':
        return (
          <P>Your Blog Draft for <b>{shoppertunity_name}</b> has been rejected.</P>
        )
      default:
        return null;
    }
  }
}
DraftContent.displayName= 'Notification.Templates.DraftContent'