import { createStore } from 'redux';
import _ from 'lodash'

const intialState = {
  loading: true,
  data: [],
  deletionId: null,
  communitiesLoading: true,
  communities: [],
  selectedCommunityId: null,
};

const reducer = (state = intialState, action) => {
  const { type, value } = action
  switch(type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [value.field]: value.value
      };
    case 'DELETE_DATA_RECORD':
      return {
        ...state,
        data: state.data.filter(x => x.id != state.deletionId)
      };
    case 'SET_DELETION_ID':
        return {
          ...state,
          deletionId: action.value
        };
    case 'START_LOADING':
      return {
        ...state,
        loading: true
      };
    case 'STOP_LOADING':
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  };
};

const TableWrapperStore = createStore(
  reducer,
  window && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ name: 'Redux.TableWrapperStore' })
);

export default TableWrapperStore;
