import React from 'react';
import { connect } from 'react-redux'
import notif from "javascripts/notifIt.js"
import { Remote } from '../utils';

import LoadingSpinner from './LoadingSpinner'
import FacebookAdSetRow from './FacebookAdSetRow';

class Wave extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      wave: null,
      updateTime: null
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    this.deleteAdSet = this.deleteAdSet.bind(this);
    this.loadWave = this.loadWave.bind(this);
    this.render = this.render.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
  }

  componentDidMount() {
    setTimeout(this.loadWave, 100);
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.updateTime == this.state.updateTime) {
      return;
    }

    this.setState({
      updateTime: nextProps.updateTime
    }, () => this.loadWave());
  }

  render() {
    if(!this.state.wave) {
      return <LoadingSpinner loading={true}/>;
    }

    return (
      <div style={{ overflowX: 'scroll' }}>
        <h2>Wave #{this.state.wave.wave_number} &mdash; Adset Queue</h2>
        <table className='table table-striped'>
          <thead>
            <tr>
              <th>Ad Set ID</th>
              <th>Notes</th>
              <th>Ad Set Name</th>
              <th>Campaign Name</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
              <th>Type</th>
              <th>Budget</th>
              <th>Goal</th>
              <th>Actions</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.wave.facebook_ad_sets.map(adSet =>
                <FacebookAdSetRow key={adSet.id}
                                              adSet={adSet}
                                              delete={() => this.deleteAdSet(adSet.id)}
                                              campaignId={this.props.campaignId}
                                              updateNotes={(text) => this.updateNotes(adSet.id, text)}
                                              waveState={this.state.wave.state}
                                              />
              )
            }
          </tbody>
        </table>
      </div>
    );
  }

  loadWave() {
    $.get(`/admin/campaigns/${this.props.campaignId}/promoters_v2/${this.props.waveState}`).then((data) => {
      this.setState({
        wave: data
      });
    });
  }

  deleteAdSet(id) {
    Remote().request({
      url: `/admin/campaigns/${this.props.campaignId}/promoters_v2/api_proxy/facebook_ad_sets?id=${id}`,
      method: 'DELETE'
    }).then(() => this.props.refresh());
  }

  updateNotes(id, value) {
    Remote().request({
      url: `/admin/campaigns/${this.props.campaignId}/promoters_v2/api_proxy/facebook_ad_sets?id=${id}`,
      method: 'PATCH',
      contentType: 'application/json',
      data: { notes: value }
    }).then(() => notif({
      msg: 'Notes updated',
      type: 'notice',
      timeout: 1000
    }));
  }
};

Wave.displayName = 'FacebookAds.Wave';
export default connect(
  Wave.mapStateToProps,
  Wave.mapDispatchToProps
)(Wave)
