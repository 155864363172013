import React from 'react';
import PropTypes from 'prop-types'
export default class Toggle extends React.Component {
  render() {
    const { onUpdate, checked, disabled } = this.props;

    const disabledClass = disabled ? 'disabled' : '';

    // Note the onClick on the label -- React doesn't handle onChange for custom
    // check boxes, so we have to manually update the state.
    return <React.Fragment>
      <input type='checkbox' className={`toggle ${disabledClass}`} checked={checked} onChange={_.noop} disabled={disabled}/>
      <label className={`toggle-btn ${disabledClass}`} onClick={e => !disabled && onUpdate(!checked)} />
    </React.Fragment>;
  }
}

Toggle.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired
}
