import React from 'react';
import { connect } from 'react-redux';
import { Remote } from '../utils';

import PhoneNumberInput from '../PhoneNumberInput'

class ProfilesContactInfo extends React.Component {
  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.findCountryId = this.findCountryId.bind(this);
    this.inputClass = this.inputClass.bind(this);
    this.loadStates = this.loadStates.bind(this);
    this.render = this.render.bind(this);
    this.renderStates = this.renderStates.bind(this);
    this.updateField = this.updateField.bind(this);
    this.validateField = this.validateField.bind(this);
    this.validation = this.validation.bind(this);
  }

  componentDidMount() {
    ['phone', 'country', 'address1', 'address2', 'city', 'state', 'zip'].forEach(field => {
      this.validateField(field);
    });
    this.loadStates(_.get(this.props, 'profile.country', 'US'));
  }

  render() {
    const { profile, i18n, accountSettingsUrl } = this.props;

    return <React.Fragment>
      <div className='card-plus'>
        <div className='card-header'>
          {i18n.navigation.contact}
        </div>
        <div className='card-body'>
          <div className='form-group'>
            <label>{i18n.labels.email}</label>
            <input
              className='form-control'
              disabled={true}
              onChange={_.noop}
              value={profile.email}
              />
            <span className='help-text no-prefix'>
              <a href={accountSettingsUrl}>
                {i18n.buttons.profile_settings_link}
              </a>
            </span>
          </div>

          <div className='form-group'>
            <label>{i18n.labels.phone}</label>
            <PhoneNumberInput
              extraClassNames={this.inputClass('phone')}
              onChange={e => this.updateField('phone', e)}
              value={profile.phone || ''}
              />
            {this.validation('phone')}
          </div>

          <div className='form-group'>
            <label>{i18n.labels.country}</label>
            <select className={this.inputClass('country')} value={this.props.profile.country} onChange={e => this.updateField('country', e)}>
              <option value={''}>Select</option>
              {
                this.props.countries.map(c => <option key={c.abbreviation} value={c.abbreviation}>{c.name}</option>)
              }
            </select>
            {this.validation('country')}
          </div>

          <div className='form-group'>
            <label>{i18n.labels.state}</label>
            {this.renderStates()}
            {this.validation('state')}
          </div>

          <div className='form-group'>
            <label>{i18n.labels.city}</label>
            <input
              className={this.inputClass('city')}
              onChange={e => this.updateField('city', e)}
              value={profile.city}
              />
            {this.validation('city')}
          </div>

          <div className='form-group'>
            <label>{i18n.labels.address1}</label>
            <input
              className={this.inputClass('address1')}
              onChange={e => this.updateField('address1', e)}
              value={profile.address1}
              />
            {this.validation('address1')}
          </div>

          <div className='form-group'>
            <label>{i18n.labels.address2}</label>
            <input
              className='form-control'
              onChange={e => this.updateField('address2', e)}
              value={profile.address2}
              />
          </div>

          <div className='form-group'>
            <label>{i18n.labels.zip}</label>
            <input
              className={this.inputClass('zip')}
              onChange={e => this.updateField('zip', e)}
              value={profile.zip}
              />
            {this.validation('zip')}
          </div>
        </div>
      </div>
    </React.Fragment>;
  }

  updateField(field, e) {
    this.props.updateProfile(field, e.target.value);
    this.validateField(field, e.target.value);

    if(field == 'country' && e.target.value != this.props.profile.country) {
      this.props.updateProfile('state', '');
      this.validateField('state', '');
      this.loadStates(e.target.value);
    }
  }

  findCountryId(abbreviation) {
    const countries = _.get(this.props, 'countries', []);
    return _.get(countries.find(c => c.abbreviation == abbreviation), 'id', 0) || 0;
  }

  loadStates(countryAbbr) {
    const countryId = this.findCountryId(countryAbbr);
    const params = $.param({
      api_action: `/countries/${countryId}/states`
    });

    this.props.toggleLoading();
    Remote().request({
      method: 'GET',
      url: this.props.proxyUrl + '?' + params,

    }).then((response) => {
      this.props.setStates(response.data);
    }).catch((error) => {
      this.props.showError(this.props.i18n.errors.ajax_failure);
    });

    this.props.toggleLoading();
  }

  renderStates() {
    if(this.props.loading) {
      return <input className={this.inputClass('state')} disabled={true} value='' onChange={_.noop} />;
    }

    return <select className={this.inputClass('state')} value={this.props.profile.state} onChange={e => this.updateField('state', e)}>
      <option value={''}>Select a state</option>
      { this.props.states &&
        this.props.states.map(state => <option key={state.abbreviation} value={state.abbreviation}>{state.name}</option>)
      }
    </select>;
  }

  validateField(field, newValue=undefined) {
    const {
      i18n,
      profile,
      activeTab,
      frontendValidations,
      updateFrontendValidations
    } = this.props;

    let value = _.isUndefined(newValue) ? profile[field] : newValue;
    let validations = frontendValidations[activeTab] || {};

    if(!value && field !== 'address2') {
      updateFrontendValidations({ [field]: i18n.validations.required });
      return;
    }

    if(field === 'phone') {
      // This is a pretty nasty regex I wrote -- to inspect it go to this link:
      // regexr.com/57clb
      if(!String(value).match(/^\+?1?\s*\(?\d{3}\)?-?\s?\d{3}-?\d{4}$/)) {
        updateFrontendValidations({ [field]: i18n.validations.invalid_phone });
        return;
      }
    }

    // These regexes were pulled from the influencer sign-up workflow
    if(field === 'zip') {
      let regex;
      if(profile.country == 'US') {
        regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/g;
      } else {
        regex = /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][ -][0-9][ABCEGHJ-NPRSTV-Z][0-9]$/i;
      }

      if(!String(value).match(regex)) {
        updateFrontendValidations({ [field]: i18n.validations.invalid_zip });
        return;
      }
    }

    updateFrontendValidations({ [field]: undefined });
  }

  validation(field) {
    const validations = this.props.frontendValidations[this.props.activeTab] || {};
    const fieldValidations = validations[field];

    if(!fieldValidations) {
      return null;
    }

    return <span className='help-text no-prefix'>
      {fieldValidations}
    </span>;
  }

  inputClass(field) {
    if(this.validation(field)) {
      return 'form-control invalid';
    }

    return 'form-control';
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      proxyUrl: state.meta.proxyUrl,
      i18n: state.meta.i18n,
      profile: state.profile,
      countries: state.countries,
      states: state.states,
      loading: state.loading,
      activeTab: state.activeTab,
      frontendValidations: state.frontendValidations,
      accountSettingsUrl: state.meta.accountSettingsUrl
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      setStates: states => dispatch({ type: 'SET_STATES', value: states }),
      toggleLoading: () => dispatch({ type: 'TOGGLE_LOADING' }),
      updateFrontendValidations: validations => dispatch({
        type: 'UPDATE_FRONTEND_VALIDATIONS',
        value: validations
      }),
      updateProfile: (field, value) => dispatch({
        type: 'UPDATE_PROFILE',
        value: {
          field,
          value
        }
      }),
      showError: message => dispatch({
        type: 'UPDATE_ALERT',
        value: {
          showing: true,
          message: message,
          type: 'error'
        }
      })
    };
  }
};

ProfilesContactInfo.displayName = 'Profiles.ContactInfo';
export default connect(
  ProfilesContactInfo.mapStateToProps,
  ProfilesContactInfo.mapDispatchToProps
)(ProfilesContactInfo);
