import React from 'react';
export default class FeedbackQuestions extends React.Component{
  render(){
    const {questions} = this.props;
    return(
      <div className="container-fluid">
          {
            questions.map((question) =>
              <div className="form-group" key={ question.id }>
                <label htmlFor="question-feedback" style={{ fontSize: '14px' }}>{question.description}</label>
                <textarea className="form-control" id={ question.id } style={{ height: '75px' }} name={'questions['+question.id+']'} required />
              </div>
            )
          }
      </div>
    );
  }
}