import React from 'react';
import PropTypes from 'prop-types'
export default class Alert extends React.Component {
  render() {
    let alertSection;
    if(this.props.message) {
      alertSection = <p dangerouslySetInnerHTML={{ __html: this.props.message }}></p>;
    } else {
      alertSection = <div>{this.props.children}</div>;
    }

    if (this.props.callback) {
      return (
        <div className={`alert-component ${this.props.type}`}>
          {alertSection}
          <div className='close' onClick={this.props.callback}>
            <i className='fas fa-times'></i>
          </div>
        </div>
      );
    } else {
      return (
        <div className={`alert-component ${this.props.type}`}>
          {alertSection}
        </div>
      );
    }

  }
}

Alert.displayName = 'Alert'
Alert.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string,
  callback: PropTypes.func
};
