import React from 'react';
var PromotionIndex = createReactClass({

  getInitialState: function () {
    return {
      alerts: this.props.card_props.alerts,
      currentTab: this.props.current_tab,
      openCardsToShow: 4,
      postedCardsToShow: 4,
      activeCardsToShow: 4,
      openTabClass: (this.props.current_tab == 'open' ? 'active' : ''),
      activeTabClass: (this.props.current_tab == 'active' ? 'active' : ''),
      postedTabClass: (this.props.current_tab == 'posted' ? 'active' : ''),
      twitter_promos_disabled: this.props.card_props.twitter_promos_disabled,
      facebook_promos_disabled: this.props.card_props.facebook_promos_disabled,
      reservedPromo: this.props.reserved_promo,
    };
  },

  componentDidMount: function () {
    $('.alert_holder').append($('.alert_box'));

    $('#connect-fb-page').click(function(){
      Weave.AuthenticationUtils.connect_facebook_page();
    });

    $('#submit-fb-page').click(function(){
      Weave.AuthenticationUtils.submit_facebook_page(null);
    });
  },

  showOpen: function () {
    this.setState({
      currentTab: 'open',
      openTabClass: 'active',
      activeTabClass: '',
      postedTabClass: '',
    });
  },

  showActive: function () {
    this.setState({
      currentTab: 'active',
      postedTabClass: '',
      activeTabClass: 'active',
      openTabClass: '',
      reservedPromo: false,
    });
  },

  showPosted: function () {
    this.setState({
      currentTab: 'posted',
      postedTabClass: 'active',
      activeTabClass: '',
      openTabClass: '',
    });
  },

  loadMoreOpen: function () {
    this.setState({
      openCardsToShow: this.state.openCardsToShow + 4,
    });
  },

  loadMoreActive: function () {
    this.setState({
      activeCardsToShow: this.state.activeCardsToShow + 4,
    });
  },

  loadMorePosted: function () {
    this.setState({
      postedCardsToShow: this.state.postedCardsToShow + 4,
    });
  },

  render: function () {
    var globalAlerts = this.state.alerts;
    var open_promotions_count = this.props.card_props.open_count;
    var active_promotions_count = this.props.card_props.active_count;
    var posted_promotions_count = this.props.card_props.posted_count;
    var open_cards = this.props.card_props.open_cards;
    var active_cards = this.props.card_props.active_cards;
    var posted_cards = this.props.card_props.posted_cards;
    var promo_auth_url = this.props.card_props.promo_auth_url;
    var twitter_follower_requirements = this.props.card_props.twitter_follower_requirements;
    var facebook_follower_requirements = this.props.card_props.facebook_follower_requirements;
    var openCardsToShow = this.state.openCardsToShow;
    var activeCardsToShow = this.state.activeCardsToShow;
    var postedCardsToShow = this.state.postedCardsToShow;
    var currentTab = this.state.currentTab;
    var twitter_promos_disabled = this.props.card_props.twitter_promos_disabled
    var facebook_promos_disabled = this.props.card_props.facebook_promos_disabled
    var twitter_connection_required = this.props.card_props.twitter_connection_required;
    var facebook_connection_required = this.props.card_props.facebook_connection_required;
    var postedTabClass = this.state.postedTabClass;
    var activeTabClass = this.state.activeTabClass;
    var openTabClass = this.state.openTabClass;
    var reserved_promo = this.state.reservedPromo;
    var cards;
    var noCardsAlert;
    var showMore;
    var notice_message;
    var twitter_disabled = twitter_promos_disabled || twitter_connection_required;
    var facebook_disabled = facebook_promos_disabled || facebook_connection_required;

    if (currentTab == 'open') {
      notice_message = 'Promotions are a first-come-first-served, easy way to post short-form sponsored content and get paid!';

      cards = open_cards.map(function (open_card, cardIndex) {
        if (cardIndex < openCardsToShow) {
          var disabled = (open_card.platform == 'Twitter') ? twitter_disabled : facebook_disabled;
          return (<OpenPromotionCard open_card={open_card} key={open_card.promo_post_id} promo_disabled={disabled} />);
        }
      });
    } else if (currentTab == 'active') {
      notice_message = 'These Promotions have been successfully claimed, but must be submitted before their expiration date in order to be paid.';

      cards = active_cards.map(function (active_card, cardIndex) {
        if (cardIndex < activeCardsToShow) {
          return (<ActivePromotionCard posted_card={active_card} reserved_promo={reserved_promo} key={active_card.promo_post_id} />);
        }
      });
    } else {
      notice_message = 'These Promotions have been submitted, and the status indicates where it is in the approval process.';

      cards = posted_cards.map(function (posted_card, cardIndex) {
        if (cardIndex < postedCardsToShow) {
          return (<PostedPromotionCard posted_card={posted_card} key={posted_card.promo_post_id} />);
        }
      });
    }

    if (currentTab == 'open' && open_promotions_count == 0) {
      noCardsAlert = (<div className="alert alert-info no-shops-alert">
        <div className="fas fa-exclamation-triangle">&nbsp;</div>
                        There are no open Promotions currently available.
                      </div>);
    } else if (currentTab == 'active' && active_promotions_count == 0) {
      noCardsAlert = (<div className="alert alert-info no-shops-alert">
        <div className="fas fa-exclamation-triangle">&nbsp;</div>
                        You currently have no active promotions
                      </div>);
    } else if (currentTab == 'posted' && posted_promotions_count == 0) {
      noCardsAlert = (<div className="alert alert-info no-shops-alert">
        <div className="fas fa-exclamation-triangle">&nbsp;</div>
                        You have not posted any Promotions.
                      </div>);
    }

    if (currentTab == 'open' && open_promotions_count > openCardsToShow) {
      showMore = (<div id="show-more">
        <button id="open_show_more_button" className="btn btn-default" onClick={this.loadMoreOpen}>Show More</button>
      </div>);
    } else if (currentTab == 'active' && active_promotions_count > activeCardsToShow) {
      showMore = (<div id="show-more">
        <button id="active_show_more_button" className="btn btn-default" onClick={this.loadMoreActive}>Show More</button>
      </div>);
    } else if (currentTab == 'posted' && posted_promotions_count > postedCardsToShow) {
      showMore = (<div id="show-more">
        <button id="posted_show_more_button" className="btn btn-default" onClick={this.loadMorePosted}>Show More</button>
      </div>);
    }

    return (
      <div>
        <div className="full-width-page-title--wrapper">
          <div className="container">
            <div className="row">
              <h1 className="full-width-page-title--heading col-xs-5 col-sm-4">Promotions</h1>
              <div className="col-xs-7 col-sm-7 col-sm-push-1 pr-0">
                <div className="btn-group sofab-tabs--btn-group" style={{ marginTop: 0 }}>
                  <div className={'btn btn-default sofab-tabs--btn ' + openTabClass} onClick={this.showOpen} >
                    <span>Open</span>
                    <span className="sofab-tabs--count">{ open_promotions_count }</span>
                  </div>
                  <div className={'btn btn-default sofab-tabs--btn ' + activeTabClass} onClick={this.showActive}>
                    <span>Active</span>
                    <span className="sofab-tabs--count">{ active_promotions_count }</span>
                  </div>
                  <div className={'btn btn-default sofab-tabs--btn ' + postedTabClass} onClick={this.showPosted} >
                    <span>Posted</span>
                    <span className="sofab-tabs--count">{ posted_promotions_count }</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div dangerouslySetInnerHTML={{ __html: globalAlerts }} />
        <div className="alert alert-white alert-no-radius mb-0">
          <div className="container text-center" style={{ maxWidth: '840px' }}>
            <div>
              <i className="fas fa-info-circle text-muted fa-fw" />
              {notice_message}
              <a href="https://cbias-kb.socialfabric.us/" target="_blank"> Read the FAQ</a>
            </div>
          </div>
        </div>
        { (twitter_connection_required || facebook_connection_required) &&
          <PromotionAuth url={promo_auth_url}
                     twitter_connection_required={twitter_connection_required}
                     facebook_connection_required={facebook_connection_required}
                     twitter_follower_requirements={twitter_follower_requirements}
                     facebook_follower_requirements={facebook_follower_requirements}
          /> }
        <div className="container main-container">
          <div className="alert_holder">{noCardsAlert}</div>
          <div className="row">{cards}</div>
          {showMore}
        </div>
      </div>
    );
  },
});
