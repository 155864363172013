import React from 'react';
import { Provider } from "react-redux"
import PostingStore from '../redux/PostingStore';
import PostingEdit from './Edit'

export default class PostingWrapper extends React.Component {
  render() {
    return (
      <Provider store={PostingStore}>
        <PostingEdit {...this.props} />
      </Provider>
    );
  }
};

PostingWrapper.displayName = 'Posting.Wrapper';

