import { createStore } from 'redux';

const initialState = {
  loading: false,
  generalRequirements: [],
  taskContentRequirements: [],
  deletedGeneralRequirements: [],
  deletedTaskContentRequirements: [],
  generalTemplates: [],
  instructionNotes: [],
  fields: {},
  contentTypes: [],
  templateTypes: [],
  notifications: {
    saving: false,
    saveType: '',
    saveText: '',
    saveErrors: ''
  },
  editingType: ''
};

const taskType = (type) => {
  switch(type){
    case 'general_requirement':
    case 'campaign_general':
      return 'generalRequirements'
    case 'task_content_requirement':
    case 'campaign':
      return 'taskContentRequirements'
    case 'instruction_notes':
      return 'instructionNotes'
  }
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'INITIALIZE':
      return {
        ...state,
        [action.value.field]: action.value.data
      };
    case 'START_LOADING':
      return {
        ...state,
        loading: true
      };
    case 'STOP_LOADING':
      return {
        ...state,
        loading: false
      };
    case 'OPEN_NOTIFICATION':
      {
        const {saveType, saveText, saveErrors} = action.value;
        return {
          ...state,
          notifications: {
            saving: true,
            saveType: saveType,
            saveText: saveText,
            saveErrors: saveErrors
          }
        };
      }
    case 'CLOSE_NOTIFICATION':
      return {
        ...state,
        notifications: {
          saving: false,
          saveType: '',
          saveText: '',
          saveErrors: ''
        }
      };

    case 'ADD_TASK':
      const value = _.cloneDeep(action.value);
      const hashName = taskType(value.type);
      return {
        ...state, [hashName]: [...state[hashName], value]
      };

    case 'SET_TASKS':
      const generalTasks = _.filter(action.value.active, (task) => task.type == 'campaign_general')
      const contentTasks = _.filter(action.value.active, (task) => task.type == 'campaign')
      const deletedGeneralTasks = _.filter(action.value.deleted, (task) => task.type == 'campaign_general')
      const deletedContentTasks = _.filter(action.value.deleted, (task) => task.type == 'campaign')

      return {
        ...state,
        generalRequirements: generalTasks,
        taskContentRequirements: contentTasks,
        deletedGeneralRequirements: deletedGeneralTasks,
        deletedTaskContentRequirements: deletedContentTasks
      };

    case 'UPDATE_TASK_FIELD':
      {
        const hashName = taskType(action.value.type)
        let nextTask = _.cloneDeep(state[hashName]);

        nextTask.forEach((task) => {
          if(task.id == action.value.id){
            _.set(task, action.value.field, action.value.value);
          }
        })

        return {
          ...state,
          [hashName]: nextTask
        }
      }

    case 'ADD_INSTRUCTION_NOTES':
      return {
        ...state,
        instructionNotes: state.instructionNotes.concat(action.data)
      };
    case 'REMOVE_INSTRUCTION_NOTES':
      {
        let instructionNotes = [...state.instructionNotes];
        let notesIndex = instructionNotes.findIndex(i => i.id === action.id)
        instructionNotes.splice(notesIndex, 1)

        return {
          ...state,
          instructionNotes: instructionNotes
        };
      }
    case 'REMOVE_TASK_CONTENT_REQUIREMENT':
      {
        let taskContentRequirements = [...state.taskContentRequirements]
        let taskIndex = taskContentRequirements.findIndex(i => i.id === action.id)
        taskContentRequirements.splice(taskIndex, 1)

        return {
          ...state,
          taskContentRequirements: taskContentRequirements
        };
      }
    case 'REMOVE_GENERAL_REQUIREMENT':
      {
        let generalRequirements = [...state.generalRequirements]
        let generalIndex = generalRequirements.findIndex(i => i.id === action.id)
        generalRequirements.splice(generalIndex, 1)

        return {
          ...state,
          generalRequirements: generalRequirements
        };
      }
    case 'EDIT_INSTRUCTION_NOTES':
      return {
        ...state,
        instructionNotes: state.instructionNotes.map((req) => {
          if(action.data.id == req.id) {
            return action.data;
          } else {
            return req;
          }
        })
      };
    case 'UPDATE_INSTRUCTION_SORT':
      let modifiedInstructionNotes = _.cloneDeep(state.instructionNotes);
      modifiedInstructionNotes.splice(action.value.to, 0, modifiedInstructionNotes.splice(action.value.from, 1)[0]);

      return {
        ...state,
        instructionNotes: modifiedInstructionNotes
      };
    // Form Fields
    case 'UPDATE_META':
      return {
        ...state,
        ...action.value
      };
    // Task Requirements
    case 'ADD_REQUIREMENT':
      {
        const typeObject = taskType(action.value.type);
        let nextTask = _.cloneDeep(state[typeObject]);

        return {
          ...state,
          [typeObject]: nextTask.map((req) => {
            if(req.id === action.value.taskId){
              return {...req, round_task_requirements: [...req.round_task_requirements, action.value.requirement]}
            }else{
              return req
            }
          })
        };
      }

    case 'DELETE_REQUIREMENT_BY_ID':
      {
        const typeObject = taskType(action.value.type);
        let nextTask = _.cloneDeep(state[typeObject]);

        nextTask.forEach((task) => {
          if(task.id === action.value.taskId){
            task.round_task_requirements.splice(action.value.index, 1);
          }
        })

        return {
          ...state,
          [typeObject]: state[typeObject].map(task => {
            if(task.id !== action.value.taskId) {
              return task;
            }

            return {
              ...task,
              round_task_requirements: task.round_task_requirements.filter(req => req.id !== action.value.id)
            };
          })
        };
      }

    case 'DELETE_REQUIREMENT':
      {
        const typeObject = taskType(action.value.type);
        let nextTask = _.cloneDeep(state[typeObject]);

        nextTask.forEach((task) => {
          if(task.id === action.value.taskId){
            task.round_task_requirements.splice(action.value.index, 1);
          }
        })

        return {
          ...state,
          [typeObject]: nextTask
        };
      }

    case 'UPDATE_REQUIREMENT_FIELD':
      {
        const typeObject = taskType(action.value.type);
        let nextTask = _.cloneDeep(state[typeObject]);

        nextTask.forEach((task) => {
          if(task.id === action.value.taskId){
            _.set(task.round_task_requirements[action.value.index], action.value.field, action.value.value);
          }
        });

        return {
          ...state,
          [typeObject]: nextTask
        };
      }

    case 'UPDATE_REQUIREMENT_SORT':
      {
        const typeObject = taskType(state.editingType);
        let nextTask = _.cloneDeep(state[typeObject]);

        nextTask.forEach((task) => {
          if(task.id === action.value.taskId){
            let nextRequirements = _.cloneDeep(task.round_task_requirements);
            nextRequirements.splice(action.value.to, 0, nextRequirements.splice(action.value.from, 1)[0]);
            task.round_task_requirements = nextRequirements
          }
        });
        return {
          ...state,
          [typeObject]: nextTask
        };
      }
    case 'SET_CONTENT_TYPES':
      return {
        ...state,
        contentTypes: action.value
      };

    case 'SET_TEMPLATES':
      return {
        ...state,
        templateTypes: action.value
      };

    case 'SET_COMMUNITY_TEMPLATES':
      return {
        ...state,
        communityTemplates: action.value
      };

    case 'SET_GENERAL_TEMPLATES':
      return {
        ...state,
        generalTemplates: action.value
      };
    case 'SET_EDITING_TYPE':
      return {
        ...state,
        editingType: action.value
      };
    default:
      return state;
    }
  };

const devTools = process.env.RAILS_ENV != 'production' && window && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ name: 'Redux.CampaignsRequirementsStore' })

const CampaignsRequirementsStore = createStore(
  reducer,
  devTools
);
export default CampaignsRequirementsStore;
