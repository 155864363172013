import React from 'react';
import { connect } from 'react-redux';

class FilterSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popupOpen: false,
      filterPlatforms: [],
      filterCategories: [],
      filterRetailers: [],
      filterClients: []
    }

    this.popup = this.popup.bind(this);
    this.cancel = this.cancel.bind(this);
    this.cancelBack = this.cancelBack.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.reset = this.reset.bind(this);
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.filter = this.filter.bind(this);
    this.filters = this.filters.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.openPopup = this.openPopup.bind(this);
    this.selectFilter = this.selectFilter.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.seeOpportunties = this.seeOpportunties.bind(this);
    this.render = this.render.bind(this);
  }

  cancel(){
    this.props.setPlatformFilter(this.state.filterPlatforms);
    this.props.setCategoryFilter(this.state.filterCategories);
    this.props.setRetailerFilter(this.state.filterRetailers);
    this.props.setClientFilter(this.state.filterClients);
    this.togglePopup();
  }

  cancelBack(){
    if(this.props.filterSelected){
      return <div><a onClick={()=> this.back()}><i className="fas fa-angle-left fa-2x"/></a></div>
    }
    return <div><a onClick={()=> this.cancel()}>Cancel</a></div>
  }

  back(){
    this.props.selectFilter(null);
  }

  add(name){
    switch (this.props.filterSelected) {
      case 'Platform':
        this.props.addPlatformFilter(name);
        break;
      case 'Category':
        this.props.addCategoryFilter(name);
        break;
      case 'Retailer':
        this.props.addRetailerFilter(name);
        break;
      case 'Client':
        this.props.addClientFilter(name);
        break;
    };
  }

  remove(name){
    switch (this.props.filterSelected) {
      case 'Platform':
        this.props.removePlatformFilter(name);
        break;
      case 'Category':
        this.props.removeCategoryFilter(name);
        break;
      case 'Retailer':
        this.props.removeRetailerFilter(name);
        break;
      case 'Client':
        this.props.removeClientFilter(name);
        break;
    };
  }

  resetFilters(){
    if(this.props.filterSelected){
      switch (this.props.filterSelected) {
        case 'Platform':
          this.props.resetPlatformFilter();
          break;
        case 'Category':
          this.props.resetCategoryFilter();
          break;
        case 'Retailer':
          this.props.resetRetailerFilter();
          break;
        case 'Client':
          this.props.resetClientFilter();
          break;
      };
    }else{
      this.props.resetAllFilters();
      this.togglePopup();
    }
  }


  reset(){

    const updatePromise = () => new Promise((resolve) => {
      resolve(this.resetFilters());
    })

    updatePromise().then(() => {
      if(!this.props.filterSelected){
        this.props.onChangeCallback({
          retailer: this.props.filterRetailers,
          client: this.props.filterClients,
          category: this.props.filterCategories,
          platform: this.props.filterPlatforms
        });
        this.setState({
          filterPlatforms: this.props.filterPlatforms,
          filterCategories: this.props.filterCategories,
          filterRetailers: this.props.filterRetailers,
          filterClients: this.props.filterClients,
        })
      }
    })

  }

  seeOpportunties(){
    this.props.onChangeCallback({
      retailer: this.props.filterRetailers,
      client: this.props.filterClients,
      category: this.props.filterCategories,
      platform: this.props.filterPlatforms
    });
    this.setState({
      filterPlatforms: this.props.filterPlatforms,
      filterCategories: this.props.filterCategories,
      filterRetailers: this.props.filterRetailers,
      filterClients: this.props.filterClients,
    })
    this.togglePopup();
  }

  openPopup(){
    this.setState({ popupOpen: true }, () => {
      if ( document.body.clientWidth < 575 ) {
        document.body.style.overflow = 'hidden';
      }
    })
  }

  togglePopup(){
    this.props.selectFilter(null);
    this.setState({ popupOpen: !this.state.popupOpen }, () => {
      if ( document.body.clientWidth < 575 ) {
        document.body.style.overflow = this.state.popupOpen ? 'hidden' : 'auto';
      }
    })
  }

  selectFilter(name){
    this.props.selectFilter(name)
  }

  filter(name){
    const selectedFilters = (name) => {
      switch (name) {
        case 'Platform':
          if(this.props.filterPlatforms.length > 0){
            return this.props.filterPlatforms.join(', ');
          }
          break;
        case 'Category':
          if(this.props.filterCategories.length > 0){
            return this.props.filterCategories.join(', ');
          }
          break;
        case 'Retailer':
          if(this.props.filterRetailers.length > 0){
            return this.props.filterRetailers.join(', ');
          }
          break;
        case 'Client':
          if(this.props.filterClients.length > 0){
            return this.props.filterClients.join(', ');
          }
          break;
      };
      return 'Any';
    }

    return <div className="filter" onClick={()=> this.selectFilter(name)}>
      <div className="name-selected-container">
        <div className="name">{name}</div>
        <div className="selected">{selectedFilters(name)}</div>
      </div>
      <div>
        <i className="fas fa-angle-right fa-2x"/>
      </div>
    </div>
  }

  handleClick(checked, option){
    if(checked){
      this.remove(option);
    }else{
      this.add(option);
    }
  }

  filters(){
    const options = () => {
      switch (this.props.filterSelected) {
        case 'Platform':
          return this.props.platforms;
        case 'Category':
          return this.props.categories;
        case 'Retailer':
          return this.props.retailers;
        case 'Client':
          return this.props.clients;
        default:
          return false;
      };
    }

    const checked = (option) => {
      switch (this.props.filterSelected) {
        case 'Platform':
          return _.some(this.props.filterPlatforms, x => x == option)
        case 'Category':
          return _.some(this.props.filterCategories, x => x == option)
        case 'Retailer':
          return _.some(this.props.filterRetailers, x => x == option)
        case 'Client':
          return _.some(this.props.filterClients, x => x == option)
        default:
          return false;
      };
    }

    if(this.props.filterSelected){
      return <div className='filter-option'>
        {options().map((option,index)=>
        <div key={index} className='checkbox' onClick={()=> this.handleClick(checked(option), option)}>
          <label>
            {option}
            <input type="checkbox" value={checked(option)} checked={checked(option)}/>
          </label>
        </div>
        )}
      </div>
    }

    return <div className="filter-container">
      {this.filter('Platform')}
      {this.filter('Category')}
      {this.filter('Retailer')}
      {this.filter('Client')}
    </div>
  }

  popup(){
    return <React.Fragment>
      <div className="shoppertunities-filter--overlay"></div>
      <div className="card popup shoppertunities-filter--card">
        <div className="card-header">
          {this.cancelBack()}
          <div><h2>{this.props.filterSelected || 'Filters'}</h2></div>
          <div><a onClick={()=> this.reset()}>Reset</a></div>
        </div>
        <div className="card-body">
          {this.filters()}
        </div>
        <div className="card-footer">
          <button className="btn primary" onClick={()=> this.seeOpportunties()}>See Opportunities</button>
        </div>
      </div>
    </React.Fragment>
  }

  render(){
    const filterCount = this.props.filterPlatforms.length +
                        this.props.filterCategories.length +
                        this.props.filterRetailers.length +
                        this.props.filterClients.length;
    return <div>
      <button key="filter-button" onClick={()=> this.openPopup()} className="pull-left btn floating" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
        <div className="shoppertunities-filter--button">
          {filterCount > 0 && <div className="count-bubble">{filterCount}</div>} Filters
          <i className="fas fa-sliders-h"></i>
        </div>
      </button>
      {this.state.popupOpen && this.popup()}
    </div>
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      filterSelected: state.filterSelected,
      filterPlatforms: state.filterPlatforms,
      filterCategories: state.filterCategories,
      filterRetailers: state.filterRetailers,
      filterClients: state.filterClients
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      selectFilter: filter => dispatch({ type: 'SELECT_FILTER', value: filter }),
      setPlatformFilter: filter => dispatch({ type: 'SET_PLATFORM_FILTER', value: filter}),
      setCategoryFilter: filter => dispatch({ type: 'SET_CATEGORY_FILTER', value: filter}),
      setRetailerFilter: filter => dispatch({ type: 'SET_RETAILER_FILTER', value: filter}),
      setClientFilter: filter => dispatch({ type: 'SET_CLIENT_FILTER', value: filter}),
      addPlatformFilter: filter => dispatch({ type: 'ADD_PLATFORM_FILTER', value: filter}),
      addCategoryFilter: filter => dispatch({ type: 'ADD_CATEGORY_FILTER', value: filter}),
      addRetailerFilter: filter => dispatch({ type: 'ADD_RETAILER_FILTER', value: filter}),
      addClientFilter: filter => dispatch({ type: 'ADD_CLIENT_FILTER', value: filter}),
      removePlatformFilter: filter => dispatch({ type: 'REMOVE_PLATFORM_FILTER', value: filter}),
      removeCategoryFilter: filter => dispatch({ type: 'REMOVE_CATEGORY_FILTER', value: filter}),
      removeRetailerFilter: filter => dispatch({ type: 'REMOVE_RETAILER_FILTER', value: filter}),
      removeClientFilter: filter => dispatch({ type: 'REMOVE_CLIENT_FILTER', value: filter}),
      resetPlatformFilter: () => dispatch({ type: 'RESET_PLATFORM_FILTER'}),
      resetCategoryFilter: () => dispatch({ type: 'RESET_CATEGORY_FILTER'}),
      resetRetailerFilter: () => dispatch({ type: 'RESET_RETAILER_FILTER'}),
      resetClientFilter: () => dispatch({ type: 'RESET_CLIENT_FILTER'}),
      resetAllFilters: () => dispatch({ type: 'RESET_ALL_FILTERS'})
    };
  }
}
FilterSelect.displayName = 'FilterSelect';

export default connect(
  FilterSelect.mapStateToProps,
  FilterSelect.mapDispatchToProps
)(FilterSelect);
