import React from 'react';
import PropTypes from 'prop-types'

export default class SearchList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      focused: props.startFocus
    };

    this.formRef = React.createRef();

    this.clickHandler = this.clickHandler.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.delayBlur = this.delayBlur.bind(this);
    this.render = this.render.bind(this);
    this.renderSearchResults = this.renderSearchResults.bind(this);
    this.searchResults = this.searchResults.bind(this);
  }

  componentDidMount() {
    if(this.state.focused && !this.props.loading) {
      this.formRef.current.focus();
    }
  }

  componentWillUnmount() {
    if(this.blurTimeout) {
      clearTimeout(this.blurTimeout);
    }
  }

  searchResults() {
    if(!this.state.searchText) {
      return this.props.elements;
    }

    return this.props.elements.filter(item => {
      const name = item.name || item.label || '';
      const term = this.state.searchText || '';
      return name.toLowerCase().indexOf(term.toLowerCase()) !== -1
    });
  }

  // Wait a short time before closing so that we can still trigger click
  // events.
  delayBlur(e) {
    // Store the timeout to prevent setState'ing the unmounted component
    this.blurTimeout = setTimeout(() => {
      this.setState({ focused: false });
      this.props.onBlur && this.props.onBlur();
    }, 500);
  }

  clickHandler(e, result) {
    this.props.onSelect(result);
  }

  renderSearchResults() {
    if (!this.props.loading && typeof(this.props.elements) === 'object' && this.props.elements.length === 0)
      return (
        <div className='search-results'>
          <p>There are no templates.</p>
        </div>
      )

    if(!this.props.loading && this.state.focused) {
      return (
        <div className='search-results'>
          {
            this.searchResults().slice(0, 20).map(result =>
              <div className='search-result' key={result.id} onClick={e => this.clickHandler(e, result)}>
                {result.name || result.label}
              </div>
            )
          }
        </div>
      )
    }
  }

  render() {
    const placeholderText = this.props.loading ? 'loading...' : this.props.placeholder;
    const iconClass = this.props.loading ? 'fa-circle-notch fa-spin' : 'fa-search';

    return <div className={`search-list ${this.props.className || ''} ${this.props.loading ? 'loading' : ''}`} style={this.props.style}>
      <i className={`fas search-icon ${iconClass}`} />
      <input
        className='search-text'
        type='text'
        value={this.state.searchText}
        onChange={e => this.setState({ searchText: e.target.value })}
        placeholder={placeholderText}
        ref={this.formRef}
        onFocus={() => this.setState({ focused: true })}
        onBlur={this.delayBlur}
        />
      { this.renderSearchResults() }
    </div>;
  }
}

SearchList.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  elements: PropTypes.arrayOf(PropTypes.any).isRequired,
  className: PropTypes.string,
  style: PropTypes.any,
  startFocus: PropTypes.bool.isRequired,
  onBlur: PropTypes.func,
  loading: PropTypes.bool
}
