import React from 'react';
import { Provider } from "react-redux"
import ShoppertunityPosting from '../redux/ShoppertunityPosting';
import ShoppertunitiesShow from './Show';

export default class ShoppertunityContainer extends React.Component {
  render() {
    return (
      <Provider store={ShoppertunityPosting}>
        <ShoppertunitiesShow {...this.props} />
      </Provider>
    );
  }
};

ShoppertunityContainer.displayName = 'ShoppertunityContainer';
