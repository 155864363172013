import React from 'react';

export default class RoundTasksRadio extends React.Component {
  changeHandler(event, value=null) {
    const { index, name } = this.props;

    if (!value) {
      value = event.target.value;
    }

    this.props.updateField(name, value.value, index);
  }

  renderInput(name, field) {
    const { value } = this.props;
    const isChecked = typeof(value) == 'Array' ? _.some(value, field) : value == field.value;
    return(
      <div className='radio' key={field.name}>
        <label htmlFor={field.name}>
          <input name={field.name}
                 type='radio'
                 value={field}
                 checked={isChecked ? 'checked' : ''}
                 onChange={(e) => this.changeHandler(e, field)}/>
          {field.name}
        </label>
      </div>
    )
  }

  render() {
    const { name, options, error } = this.props;

    return(
      <div>
        <div style={{'display': 'flex'}}>
          {options.map((item) => this.renderInput(name, item))}
        </div>
        {error && error.length > 0 && <span className='error text-danger'>{error}</span>}
      </div>
    )
  }
};

RoundTasksRadio.displayName = 'RoundTasks.Radio';
