import React from 'react';
import { Provider } from 'react-redux';
import InfluencerDashboardStore from '../redux/InfluencerDashboardStore'
import InfluencerDashboardContainer from './Container'

export default class Wrapper extends React.Component {
  render() {
    return (
      <Provider store={InfluencerDashboardStore}>
        <InfluencerDashboardContainer {...this.props} />
      </Provider>
    );
  }
};
Wrapper.displayName = 'InfluencerDashboard.Wrapper';