import React from 'react';

export default class PrivacyPolicyIndex extends React.Component {
  render() {
    let policy_text = this.props.privacy_policy.text
    return (
      <div>
        <div className='container main-container'>
          <div dangerouslySetInnerHTML={{ __html: policy_text }} />
        </div>
      </div>
    );
  }
}

PrivacyPolicyIndex.displayName = 'PrivacyPolicy.Index';