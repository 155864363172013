import React from 'react';

class InstagramContentSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      posts: this.props.posts,
      hasRefreshed: false,
      selectedUrl: null
    };

    this.render = this.render.bind(this);
    this.visibleCards = this.visibleCards.bind(this);
    this.incrementPage = this.incrementPage.bind(this);
    this.decrementPage = this.decrementPage.bind(this);
    this.maxPage = this.maxPage.bind(this);
    this.padCards = this.padCards.bind(this);
    this.getUpdatedPosts = this.getUpdatedPosts.bind(this);
    this.updatePosts = this.updatePosts.bind(this);
    this.updateSelectedUrl = this.updateSelectedUrl.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  render() {
    const cards = this
          .visibleCards()
          .map((post) => <InstagramContentCard selected={this.isSelected(post)} key={post.graph_id} updateSelectedUrl={this.updateSelectedUrl} post={post} />);
    this.padCards(cards);

    return (
      <div>
        <h3>Select Your Post</h3>
        <a href={this.props.manual_url}>
          Or input a URL
        </a>
        <div className="ig-content-selector">
          <div className="button-bar">
            <button className="btn btn-default" disabled={this.state.hasRefreshed} onClick={(event) => this.getUpdatedPosts(event)}>
              <i className="fas fa-repeat" /> Refresh Posts
            </button>
          </div>
          <div className="arrow" onClick={() => this.decrementPage()}>
            <i className="fas fa-chevron-left" />
          </div>
          {cards}
          <div className="arrow" onClick={() => this.incrementPage()}>
            <i className="fas fa-chevron-right" />
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const url = $(InstagramContentSelector.FORM_SELECTOR).val();
    if (!url) {
      return;
    }

    this.updateSelectedUrl(url);
  }

  visibleCards() {
    const page = this.state.page;
    const perPage = InstagramContentSelector.POSTS_PER_PAGE;
    return this.state.posts.slice(page * perPage, (page + 1) * perPage);
  }

  incrementPage() {
    const perPage = InstagramContentSelector.POSTS_PER_PAGE;
    const page = this.state.page + 1;
    if (page * perPage >= this.state.posts.length) {
      this.setState({ page: 0 });
    } else {
      this.setState({ page: page });
    }
  }

  decrementPage() {
    const page = this.state.page - 1;
    if (page < 0) {
      this.setState({ page: this.maxPage() });
    } else {
      this.setState({ page: page });
    }
  }

  maxPage() {
    const maxPageEstimate = this.state.posts.length / InstagramContentSelector.POSTS_PER_PAGE;
    return Math.floor(maxPageEstimate);
  }

  padCards(cards) {
    const perPage = InstagramContentSelector.POSTS_PER_PAGE;
    const numMissing = perPage - cards.length;

    if (cards.length < perPage) {
      for (let i = 0; i < numMissing; i++) {
        cards.push(<div className="padding-card" />);
      }
    }
  }

  getUpdatedPosts(event) {
    event.preventDefault();

    if (this.state.hasRefreshed) {
      return;
    }

    this.setState({ hasRefreshed: true });
    $.get(InstagramContentSelector.REFRESHED_POSTS_ROUTE, this.updatePosts.bind(this));
  }

  updatePosts(data) {
    this.setState({
      page: 0,
      posts: data
    });
  }

  updateSelectedUrl(url) {
    this.setState({
      selectedUrl: url
    });
    $(InstagramContentSelector.FORM_SELECTOR).val(url);
  }

  isSelected(post) {
    if (!this.state.selectedUrl) {
      return false;
    }

    return this.state.selectedUrl.indexOf(post.shortcode) > -1;
  }
}

InstagramContentSelector.POSTS_PER_PAGE = 3;
InstagramContentSelector.REFRESHED_POSTS_ROUTE = '/contents/latest_instagram_posts';
InstagramContentSelector.FORM_SELECTOR = '#content_url';
