import React from 'react';

import MultiSelect from '../MultiSelect'

export default class RegionSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      regions: []
    }
    
    this.componentDidMount = this.componentDidMount.bind(this);
    this.render = this.render.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    $.get(this.props.regionsEndpoint).then(data => {
      const formatted = data.map(x => ({ name: x.region, value: x.formatted_key }));

      this.setState({
        regions: formatted
      });
    });
  }

  render() {
    return (
      <div className='form-group'>
        <label>States</label>
        <MultiSelect selected={this.props.targetingValues}
                     onUpdate={(values) => this.props.updateLocationData('regions', values)}
                     preload={this.state.regions}
                     search={this.search} />
      </div>
    );
  }

  search(text, callback) {
    if(!text) {
      callback(this.state.regions);
    }

    callback(this.state.regions.filter(x => x.name.toLowerCase().includes(text.toLowerCase())));
  }
}
