import React from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from './LoadingSpinner'
import TargetingModal from './TargetingModal'

class Scheduler extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      schedulers: [],
      targetingModalOpen: false,
      currentSchedulerId: null,
      updateTime: null
    };

    this.audiencesEndpoint = this.audiencesEndpoint.bind(this);
    this.closeTargeting = this.closeTargeting.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    this.exportAdSchedulersUrl = this.exportAdSchedulersUrl.bind(this);
    this.detailSearchEndpoint = this.detailSearchEndpoint.bind(this);
    this.dmasEndpoint = this.dmasEndpoint.bind(this);
    this.loadScheduler = this.loadScheduler.bind(this);
    this.openTargeting = this.openTargeting.bind(this);
    this.regionsEndpoint = this.regionsEndpoint.bind(this);
    this.render = this.render.bind(this);
    this.schedulerEndpoint = this.schedulerEndpoint.bind(this);
    this.waveQuery = this.waveQuery.bind(this);
  }

  componentDidMount() {
    this.loadScheduler();
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.updateTime == nextProps.updateTime) {
      return;
    }

    this.setState({
      updateTime: nextProps.updateTime
    }, () => this.loadScheduler());
  }

  render() {
    if(this.state.loading) {
      return <LoadingSpinner loading={true}/>
    }

    return (
      <div style={{ overflowX: 'scroll' }}>
        <div className='row'>
          <div className='col-xs-6'>
            <h2>Scheduler</h2>
          </div>
          <div className='col-xs-6 text-right'>
            <a className="btn btn-default" href={this.exportAdSchedulersUrl()}>
              <i className="fas fa-download" style={{paddingRight: '5px'}}/>
              Export External Ad Schedule
            </a>
          </div>
        </div>

        <table className='table table-striped'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Ad Sets</th>
              <th>Budget</th>
              <th>Budget/Ad Set</th>
              <th>Goal/Ad Set</th>
              <th>Wave Goal</th>
              <th>Total Goal</th>
              <th>Targeting</th>
              {
                this.props.editing &&
                <th></th>
              }
            </tr>
          </thead>
          <tbody>
            {
              this.state.schedulers.map(
                (sched) => <SchedulerRow key={sched.id}
                                                     scheduler={sched}
                                                     openTargeting={() => this.openTargeting(sched.id)}
                                                     schedulerEndpoint={this.schedulerEndpoint(sched.id)}
                                                     campaignId={this.props.campaignId}
                                                     refresh={this.props.refresh}
                                                     editing={this.props.editing}/>
              )
            }
          </tbody>
        </table>
        { this.state.targetingModalOpen &&
          <TargetingModal schedulerId={this.state.currentSchedulerId}
                                      closeTargeting={this.closeTargeting}
                                      detailSearchEndpoint={this.detailSearchEndpoint}
                                      schedulerEndpoint={this.schedulerEndpoint}
                                      dmasEndpoint={this.dmasEndpoint()}
                                      regionsEndpoint={this.regionsEndpoint()}
                                      refresh={this.props.refresh}
                                      audiencesEndpoint={this.audiencesEndpoint()}
                                      editing={this.props.editing}/>
        }
      </div>
    );
  }

  waveQuery(){
    if(typeof this.props.waveId !== 'undefined'){
      return `?wave_id=${this.props.waveId}`;
    }else{
      return '';
    };
  }

  loadScheduler() {
    $.get(`/admin/campaigns/${this.props.campaignId}/promoters_v2/schedulers${this.waveQuery()}`).done((response) => {
      this.setState({
        loading: false,
        schedulers: response
      });
    });
  }

  exportAdSchedulersUrl(){
    return `/admin/campaigns/${this.props.campaignId}/promoters_v2/export_ad_schedules.csv${this.waveQuery()}`;
  }

  schedulerEndpoint(id) {
    return `/admin/campaigns/${this.props.campaignId}/promoters_v2/api_proxy/ad_schedulers?id=${id}`;
  }

  openTargeting(id) {
    this.setState({
      targetingModalOpen: true,
      currentSchedulerId: id
    });
  }

  closeTargeting() {
    this.setState({
      targetingModalOpen: false
    });
  }

  detailSearchEndpoint(query) {
    return `/admin/campaigns/${this.props.campaignId}/promoters_v2/api_proxy/targeting?resource=detailed&query=${query}`;
  }

  regionsEndpoint() {
    return `/admin/campaigns/${this.props.campaignId}/promoters_v2/api_proxy/regions`;
  }

  dmasEndpoint() {
    return `/admin/campaigns/${this.props.campaignId}/promoters_v2/api_proxy/dma_codes`;
  }

  audiencesEndpoint() {
    return `/admin/campaigns/${this.props.campaignId}/promoters_v2/api_proxy/custom_audiences`;
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      updateTime: state.updateTime,
      campaignId: state.campaignId
    };
  }
};

Scheduler.displayName = 'FacebookAds.Scheduler';
export default connect(
  Scheduler.mapStateToProps,
  Scheduler.mapDispatchToProps
)(Scheduler);
