import React from 'react';
import PropTypes from 'prop-types'
import { debounce } from 'lodash';

export default class SingleSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.selected || "",
      focused: false,
      searchText: "",
      results: [],
      cache: props.preload ? props.preload : [], // Result cache -- used to hold on to data for displaying in the result list
      queryCache: {}, // Query cache -- KV lookup to save potential api requests
    };

    this.selectItem = this.selectItem.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.query = this.query.bind(this);
    this.render = this.render.bind(this);
    this.renderResults = this.renderResults.bind(this);
    this.searchChanged = this.searchChanged.bind(this);
    this.unSelect = this.unSelect.bind(this);
    this.UNSAFE_componentWillReceiveProps =
      this.UNSAFE_componentWillReceiveProps.bind(this);

    // debounced function wrapper
    this.debouncedQuery = debounce(this.query, 500);
  }

  componentDidMount() {
    this.query();
  }

  //Needs to be updated to new react formatting - JW
  UNSAFE_componentWillReceiveProps(nextProps) {
    const cache = this.state.cache || [];
    const preload = nextProps.preload || [];
    const selected = nextProps.selected || "";

    this.setState(
      {
        cache: _.uniqBy(preload.concat(cache), (x) => x.value || x.id),
        selected: selected,
      },
      () => this.query()
    );
  }

  selectedName(selected) {
    const { cache } = this.state;

    if (!selected || cache.length === 0) return "";

    const foundName = cache.find((x) => _.isEqual(x.value || x.id, selected));
    if (foundName) return foundName.name;

    return "";
  }

  render() {
    const { searchText, focused, selected } = this.state;
    const { hasError, disabled, placeholder, showCaret, clearDisabled } =
      this.props;
    const selectedName = this.selectedName(selected);
    return (
      <div className="single-select-component">
        <div className="select">
          <input
            type="text"
            className={`${hasError ? "invalid" : ""}`}
            value={searchText || selectedName}
            onFocus={this.onFocus}
            disabled={disabled}
            onBlur={(e) => this.onBlur(e)}
            onChange={(e) => this.searchChanged(e)}
            placeholder={placeholder || ""}
          />
          {!!showCaret && <i className="dropdown-caret fas fa-caret-down" />}
          {selectedName && !clearDisabled && (
            <span
              className="un-select"
              onClick={this.unSelect}
              disabled={disabled}
            >
              <i className="fas fa-times"></i>
            </span>
          )}
          {focused && <div className="options">{this.renderResults()}</div>}
        </div>
      </div>
    );
  }

  unSelect() {
    if (!this.props.disabled) {
      this.setState(
        {
          selected: "",
          searchText: "",
        },
        () => this.props.onUpdate(this.state.selected)
      );
    }
  }

  selectItem(value) {
    this.setState(
      {
        selected: value,
        searchText: "",
      },
      () => this.props.onUpdate(this.state.selected)
    );
  }

  renderResults() {
    const { results, selected } = this.state;

    if (results.length === 0)
      return (
        <ul>
          <li className="no-results">
            <i className="fas fa-exclamation-triangle" /> No Results
          </li>
        </ul>
      );

    const items = results.filter((x) => !_.includes(selected, x.value || x.id));

    return (
      <ul>
        {items.map((result) => (
          <li
            key={JSON.stringify(result.value || result.id)}
            onClick={() => this.selectItem(result.value || result.id)}
          >
            {result.name}
          </li>
        ))}
      </ul>
    );
  }

  query() {
    const { searchText, queryCache, cache } = this.state;

    if (queryCache[searchText]) {
      this.setState({
        results: queryCache[searchText],
      });
    }

    this.props.search(searchText, (data) => {
      this.setState({
        cache: _.uniqBy(cache.concat(data), (x) => x.value || x.id),
        results: data,
        queryCache: {
          ...queryCache,
          [searchText]: data,
        },
      });
    });
  }

  searchChanged(e) {
    this.setState(
      {
        searchText: e.target.value,
      },
      this.debouncedQuery
    );
  }

  onFocus() {
    this.setState(
      {
        focused: true,
      },
      () => this.query()
    );
  }

  onBlur(e) {
    const { clearInvalidInput, dropDownCloseMilliseconds } = this.props;
    const searchText = clearInvalidInput ? "" : this.state.searchText;

    setTimeout(
      function () {
        this.setState({
          focused: false,
          searchText: searchText,
        });
      }.bind(this),
      dropDownCloseMilliseconds || 250
    );
  }

  componentDidCatch(error, info) {
    console.warn(error, info);
  }
}
SingleSelect.displayName = 'SingleSelect';
SingleSelect.propTypes = {
  placeholder: PropTypes.string,
  search: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  selected: PropTypes.any,
  preload: PropTypes.arrayOf(PropTypes.any),
  dropDownCloseMilliseconds: PropTypes.number,
  resultFontSize: PropTypes.string,
  clearDisabled: PropTypes.bool,
  showCaret: PropTypes.bool,
  clearInvalidInput: PropTypes.bool,
  hasError: PropTypes.bool,
};
