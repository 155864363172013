import React from 'react';

export default class Conversation extends React.Component {
  render(){
    const {sender_name, subject} = this.props.notification;
      return(
        <React.Fragment><b>{sender_name}</b> has sent you a message - {subject}</React.Fragment>
      )
  }
}
Conversation.displayName = 'Notification.Templates.Conversation';