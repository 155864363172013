import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash'
import SubmissionStateIndicator from './StateIndicator'

class SubmissionFacebookVideoContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: false,
      loaded: false
    }

    this.validationState = this.validationState.bind(this);
    this.setPlayingState = this.setPlayingState.bind(this);
    this.loadVideo = this.loadVideo.bind(this);
    this.renderVideoPlayer = this.renderVideoPlayer.bind(this);
    this.render = this.render.bind(this);
  }

  render() {
    const { post, selected, index } = this.props;
    const isSelected = selected.some(content => content.graph_id === post.graph_id)
    const state = this.validationState();

    return (
      <div id={`post-${index}`}
          className={`submission grid-item facebook-video ${state} ${isSelected ? 'selected' : ''}`}
          onClick={(e) => this.clickHandler(e, isSelected)}>
          <div className='item-image' style={{backgroundImage: `url(${post.image_url})`}}>
            <SubmissionStateIndicator state={state} isSelected={isSelected} isVideo={true} setPlayingState={this.setPlayingState}/>
          </div>
          { this.renderVideoPlayer() }
      </div>
    )
  }

  renderVideoPlayer() {
    return (
      <div className='fb-video video'
        id={`video-${this.props.index}`}
        data-href={this.props.post.link}
        data-width='auto'
        data-show-text='false'
        data-show-captions='false'
        data-allowfullscreen='true'
        data-autoplay='false'></div>
    )
  }

  validationState() {
    const { playing } = this.state;
    const { validations } = this.props;

    if ( validations && validations.length ) {
      if ( _.some(validations, {state: 'failed'}) ) {
        return 'failed';
      } else {
        return 'passed';
      }
    } else {
      if (playing) {
        return 'playing';
      } else {
        return 'pending';
      }
    }
  }

  setPlayingState(e, play) {
    e.preventDefault();
    e.stopPropagation();

    if (!this.state.player) {
      this.loadVideo();
    } else {
      if (play) {
        this.state.player.play();
      } else {
        this.state.player.pause();
      }
    }
  }

  loadVideo() {
    const { index } = this.props;

    let player;
    FB.XFBML.parse(document.getElementById(`post-${index}`));
    FB.Event.subscribe('xfbml.ready', (msg) => {
      if (msg.type === 'video' && msg.id === `video-${index}`) {
        player = msg.instance;

        player.subscribe('finishedBuffering', (e) =>{
          player.play();
          this.setState({ playing: true });
        })

        player.subscribe('startedPlaying', (e) => {
          this.setState({ playing: true });
        });

        player.subscribe('paused', (e) => {
          this.setState({ playing: false });
        });

        player.subscribe('finishedPlaying', (e) => {
          this.setState({ playing: false });
        });

        player.subscribe('error', (e) => {
          this.setState({ playing: false });
        });

        this.setState({
          player: player,
          playing: true
        }, () => {
          player.play();
        });
      }
    });
  };

  clickHandler(e, isSelected) {
    e.preventDefault();
    const { playing } = this.state;
    const { post } = this.props;

    if (playing) {
      return null;
    } else if ( isSelected ) {
      this.props.deselect(post);
      this.props.resetSubmission();
      this.props.resetValidations();
    } else {
      this.props.select(post);
      this.props.updateSubmission({ url: post.permalink });
      this.props.resetValidations();
      this.props.validate();
    }
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      selected: state.selected,
      validations: state.validations
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      select: post => dispatch({ type: 'SELECT_SINGLE_CONTENT', value: post }),
      deselect: post => dispatch({ type: 'DESELECT_CONTENT', value: post }),
      resetSubmission: () => dispatch({ type: 'RESET_SUBMISSION' }),
      updateSubmission: params => dispatch({ type: 'UPDATE_SUBMISSION', value: params }),
      resetValidations: () => dispatch({ type: 'RESET_VALIDATIONS' }),
    };
  }
};

SubmissionFacebookVideoContent.displayName = 'Submission.FacebookVideoContent';
export default connect(
  SubmissionFacebookVideoContent.mapStateToProps,
  SubmissionFacebookVideoContent.mapDispatchToProps
)(SubmissionFacebookVideoContent);


