import React from 'react';
import { Remote } from '../utils';
import SaveIndicator from '../SaveIndicator'
import SaveError from '../SaveError'

export default class InstructionNotesFormActions extends React.Component {
  constructor(props) {
    super(props);

    this.flash = this.flash.bind(this);
    this.missingRequiredFields = this.missingRequiredFields.bind(this);
    this.submit = this.submit.bind(this);
    this.render = this.render.bind(this);

    this.state = {
      saveType: 'info',
      saveText: '',
      saveErrors: null,
      timeoutId: null
    };
  }

  flash(newState, duration) {
    this.setState(newState, () => {
      const timeoutId = window.setTimeout(() => this.props.stopSubmitting(), duration);

      this.setState({ timeoutId });
    });
  }

  missingRequiredFields() {
    const { label, community_id, description } = this.props.form;
    return !label || !community_id || !description;
  }

  submit() {
    if(!!this.props.submitting) {return;}
    this.props.startSubmitting();

    if(this.state.timeoutId) {
      window.clearTimeout(this.state.timeoutId);
    }

    this.setState({
      saveType: 'info',
      saveText: 'Saving instruction notes...',
      saveErrors: null,
      timeoutId: null
    });

    Remote().request({
      url: this.props.submitUrl,
      method: this.props.submitMethod,
      data: this.props.form,
      contentType: 'application/json',
    }).then(response => {
      window.location.href = response.data.redirect_url;
    }).catch(response =>
      this.flash({
        saveText: 'Failed to save instruction note! Please correct these errors:',
        saveType: 'failure',
        saveErrors: response.data.responseJSON.errors
      }, 5000));
  }

  render() {
    return (
      <div className='text-center'>
        <button className='btn btn-primary' disabled={this.missingRequiredFields() || !!this.props.submitting} type='submit' value='Submit' onClick={ e => this.submit() }>
          { this.props.submitting ? <i className='fa fa-spin fa-circle-o-notch'></i> : 'Submit' }
        </button>
        <input className='btn btn-default' type='reset' value='Cancel' onClick={(e) => { window.location.href = this.props.baseUrl }}></input>
        {
          this.props.submitting &&
          <SaveIndicator type={this.state.saveType}>
            {this.state.saveText}
            {this.state.saveErrors && <SaveError errors={this.state.saveErrors}/>}
          </SaveIndicator>
        }
      </div>
    );
  }
}

InstructionNotesFormActions.displayName = 'InstructionNotes.FormActions';