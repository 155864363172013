class window.Comments

  @new: ->
    @editor()

  @create: ->
    @editor()

  @edit: ->
    @editor()
    $('#editor').html( $('#comment_body').val() )

  @update: ->
    @edit()

  @editor: ->
    $('#comment_body').redactor
      focus: true
      plugins: plugin_list
