import React from 'react';
class DashboardFilter extends React.Component {
  paginationControl(){
    return (
      <label>
        Show 
        <select value={this.props.perPage} className="form-control input-sm d-inline-block" onChange={(e) => this.props.updatePerPage(e.target.value)}>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select> 
        entries
      </label>
    )
  }

  searchBox(){
    return (
      <input type="search" defaultValue={this.props.search} className="form-control mb-15" onChange={(e) => this.props.updateSearch(e.target.value)} placeholder="Search dashboard users..."/>
    )
  }

  render() {
    return (
      <div>
        <div className='col-xs-2 col-sm-3 table--record-size'>
          { this.paginationControl() }
        </div>
        <div className='col-xs-10 col-sm-5'>
          <label className="search-box--label">
          { this.searchBox() }
        </label>
        </div>
      </div>
    )
  }
}
DashboardFilter.displayName = 'UserTable.DashboardFilter';
export default DashboardFilter
