import React from 'react';
import { connect } from "react-redux"
import { Remote } from '../utils';
import ManualMetricsInputs from "./ManualMetricsInputs";
import LoadingSpinner from '../LoadingSpinner'
import InstagramOembed from '../InstagramOembed';

class AdminInstagramPostPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    }

    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.fetchPreview = this.fetchPreview.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidUpdate() {
    if(this.props.bloggerShopTask.content && !this.props.preview && !this.state.loading) {
      this.setState({ loading: true }, () => {
        this.fetchPreview();
      })
    }
  }


  fetchPreview() {
    const params = $.param({
      api_action: `/blogger_shop_tasks/${this.props.bloggerShopTask.id}/preview`
    });

    return Remote().request({
      method: 'POST',
      url: this.props.proxyUrl + '?' + params,
      data: {
        id: this.props.bloggerShopTask.content.id,
        type: this.props.bloggerShopTask.content.type,
        url: this.props.bloggerShopTask.content.url
      },
    }).then(response => {
      this.props.setPreview(response.data);
      this.setState({ loading: false })
    });
  }

  render() {
    const { bloggerShopTask, preview, meta, proxyUrl} = this.props;
    const analyticsAttachment = _.filter(bloggerShopTask.instagram_attachments, (a) => a.attachment_type == 'analytics')[0]

    if(this.state.loading){
      return <LoadingSpinner/>
    }else if(bloggerShopTask){
      if(preview){
        return <div className='admin-manual-instagram-preview'>
          <div className="flex-row">
            <div className="flex-item" style={{"width": "60%"}}>
              <InstagramOembed oembed_html={preview.html} />
              <div className='submission-information'>
                <div>Content URL: <a href={bloggerShopTask.content.url}>{bloggerShopTask.content.url}</a></div>
                <div>Due: {bloggerShopTask.formatted_due_date}</div>
                <div>Submitted at: {bloggerShopTask.content.formatted_submitted_at}</div>
              </div>
            </div>
            { analyticsAttachment && analyticsAttachment.file &&
              <div className="flex-item" style={{"width": "40%"}}>
                <h5>Screenshot provided by influencer:</h5>
                <div key={analyticsAttachment.id}>
                  <img src={analyticsAttachment.file.url} className='analytics-screenshot'/>
                </div>

              </div>
            }
          </div>
          {(analyticsAttachment || preview.manual) &&
            <ManualMetricsInputs
              analytics={analyticsAttachment}
              proxyUrl={proxyUrl}
              type='instagram_attachment'
              manualMetricsInstructions={meta.manualMetricsInstructions}
              blogger_shop_task_id={bloggerShopTask.id}
              blogger_shop_task_state={bloggerShopTask.state} />
          }
        </div>
      }else{
        return null
      };
    }else{
      return null
    };
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      meta: state.meta,
      proxyUrl: state.meta.proxyUrl,
      contentType: { name: (state.bloggerShopTask.content || {}).type },
      bloggerShopTask: state.bloggerShopTask,
      preview: state.preview
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      setPreview: preview => dispatch({ type: 'SET_PREVIEW', value: preview })
    };
  }
};


AdminInstagramPostPreview.displayName = 'Contents.AdminInstagramPostPreview';

export default connect(
  AdminInstagramPostPreview.mapStateToProps,
  AdminInstagramPostPreview.mapDispatchToProps
)(AdminInstagramPostPreview);

