import React from 'react';
import { connect } from 'react-redux';
import FailureCache from './FailureCache'
import SubmissionAuthTypes from './AuthTypes'

class SubmissionAuthFailure extends React.Component {
  constructor(props) {
    super(props);

    this.authListener = this.authListener.bind(this);
    this.triggerManualSubmit = this.triggerManualSubmit.bind(this);
    this.displayName = this.displayName.bind(this);
    this.handleAuth = this.handleAuth.bind(this);
    this.render = this.render.bind(this);
  }

  displayName() {
    switch(this.props.contentType.name) {
    case 'FacebookVideoContent':
      return 'Facebook';
    case 'YoutubeContent':
    case 'GoogleYoutubeContent':
      return 'YouTube';
    case 'InstagramContent':
    case 'InstagramStoryContent':
    case 'InstagramBusinessStoryContent':
    case 'InstagramReelContent':
      return 'Instagram';
    case 'TiktokVideoContent':
      return 'TikTok';
    default:
      return 'API Content';
    }
  }

  handleAuth() {
    const urls = this.props.connectUrls;
    const authType = SubmissionAuthTypes[this.props.contentType.name]

    let authUrl = (() => {
      switch(this.props.contentType.name) {
      case 'FacebookVideoContent':
        return urls.facebook_page;
      case 'YoutubeContent':
      case 'GoogleYoutubeContent':
        return urls.google_youtube;
      case 'InstagramContent':
      case 'InstagramStoryContent':
      case 'InstagramBusinessStoryContent':
      case 'InstagramReelContent':
        return urls.facebook_instagram;
      case 'TiktokVideoContent':
        return urls.tiktok_account;
      default:
        return;
      }
    })();

    window.localStorage.setItem(authType, null);
    window.addEventListener('storage', () => this.authListener(authType));
    window.open(authUrl, '_blank');
  }

  authListener(authType) {
    // Check local storage for successful reconnect
    if (window.localStorage.getItem(authType) != 'null') {
      this.props.setAuth(authType, JSON.parse(window.localStorage.getItem(authType)))
    }
  }

  triggerManualSubmit(){
    this.props.setManual(true)
    this.props.setReadOnly(false)
  }

  render() {
    const platform = this.displayName();
    const failureCache = new FailureCache(this.props.contentType.name);
    return (
      <div className='submission-auth-failure text-center'>
        <i className='fas fa-exclamation-triangle submission-auth-warning'></i>
        <h3>Authentication Failure</h3>
        <p>We were unable to authenticate your {platform} account. Use the "Connect {platform}" option below to submit your post.</p>
        {
          failureCache.get() > 1 &&
          <button className='btn btn-primary submission-auth-button manual-submit' onClick={() => this.triggerManualSubmit()}>
            Submit Manually
          </button>
        }
        <button className='btn btn-primary submission-auth-button' onClick={this.handleAuth}>
          Connect {platform}
        </button>
      </div>
    )
  }
  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      contentType: state.contentType,
      connectUrls: state.meta.connectUrls,
      social: state.social
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      setAuth: (type, auth) => dispatch({ type: 'SET_AUTH', key: type, value: auth }),
      setManual: flag  => dispatch({ type: 'SET_MANUAL', value: flag }),
      setReadOnly: flag  => dispatch({ type: 'SET_READ_ONLY', value: flag })
    };
  }
};

SubmissionAuthFailure.displayName = 'Submission.AuthFailure';

export default connect(
  SubmissionAuthFailure.mapStateToProps,
  SubmissionAuthFailure.mapDispatchToProps
)(SubmissionAuthFailure);

