import React from 'react';
import { Provider } from "react-redux"
import ContentContainer from './ContentContainer'
import AdminContentStore from '../redux/AdminContentStore'

class RequirementsWrapper extends React.Component {
  render() {
    return <Provider store={AdminContentStore}>
      <ContentContainer {...this.props} />
    </Provider>;
  }
};

RequirementsWrapper.displayName = 'Contents.RequirementsWrapper';
export default RequirementsWrapper
