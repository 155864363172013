import React from 'react';
import PropTypes from 'prop-types'
export default class PageTitleBar extends React.Component {

  render() {
    const { title, subTitle, community_theme_settings } = this.props;
    let primary_color = null
    let text_color = null

    if (community_theme_settings && Object.keys(community_theme_settings).length != 0) {
      const { theme_primary_color, theme_text_color } = community_theme_settings
      if (!!theme_primary_color )
        primary_color = theme_primary_color
      if (!!theme_text_color )
        text_color = theme_text_color
    }

    return <div className='page-title-bar' style={{background: primary_color}}>
      <div className='container'>
        <div className='title' style={{color: text_color}}>{title}</div>
        <div className='sub-title'>{subTitle}</div>
        { this.props.children }
      </div>
    </div>
  }
};

PageTitleBar.displayName = 'PageTitleBar';
PageTitleBar.propTypes = {
  title: PropTypes.string.isRequired
};