import React from "react";
import { Remote } from "../utils";
import GoogleDocExportFolderSelect from "./GoogleDocExportFolderSelect";

export default class GoogleDocExportFolder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      list: [],
      loading: true,
      error: false,
    };
    this.loadDriveFolder = this.loadDriveFolder.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchOptions = this.searchOptions.bind(this);
  }

  componentDidMount() {
    this.loadDriveFolder(this.props.parent);
  }

  componentDidUpdate(prevProps) {
    if (this.props.parent !== prevProps.parent)
      this.setState({ loading: true }, () =>
        this.loadDriveFolder(this.props.parent)
      );
  }

  async loadDriveFolder(parent) {
    if (!this.state.loading) return;
    const query = !parent ? "" : `?parent=${parent}`;
    Remote()
      .request({
        url: `${this.props.proxyUrl}?api_action=/google_drive/folders${query}`,
        method: "GET",
      })
      .then((response) => {
        if (response.data.status !== 500)
          this.setState({ list: response.data, loading: false, error: false });
        else {
          this.setState({ list: [], error: true, loading: false });
        }
      })
      .catch((err) => {
        this.setState({ list: [], error: err, loading: false });
      });
  }

  handleChange(id) {
    this.setState({ selected: id });
    this.props.callback(id);
  }

  searchOptions(query, callback) {
    const { list } = this.state;
    if (query && query.length > 0) {
      callback(
        _.filter(list, (value) =>
          value.name.toLowerCase().includes(query.toLowerCase())
        )
      );
    } else callback(list);
  }

  render() {
    const { loading, error, selected } = this.state;
    const { placeholder, label } = this.props;

    if (loading)
      return (
        <div className="mb-10">
          <label>{label}</label>
          <p>
            <i className="fas fa-circle-notch fa-spin mr-5" />
            Loading options, please wait...
          </p>
        </div>
      );

    if (error)
      return (
        <div className="mb-10">
          <i className="fas fa-exclamation-triangle text-danger mr-5" />
          There was an error loading.
        </div>
      );

    return (
      <div className="mb-10">
        <label>{label}</label>
        <GoogleDocExportFolderSelect
          search={(query, callback) => this.searchOptions(query, callback)}
          selected={selected}
          onUpdate={this.handleChange}
          placeholder={placeholder}
        />
      </div>
    );
  }
}
GoogleDocExportFolder.displayName = "GoogleDocExportFolder";

