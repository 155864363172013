import React from 'react';
import { some } from 'lodash';
import { connect } from 'react-redux';
import Tooltip from '../Tooltip';

class ShoppertunitiesRoundCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTooltipPrePayment: false,
      showTooltipFinalPayment: false,
      showProfileCompletenessTooltip: false
    }
    this.roundChecked = this.roundChecked.bind(this);
    this.render = this.render.bind(this);
    this.selectRound = this.selectRound.bind(this);
    this.roundDollarAmount = this.roundDollarAmount.bind(this);
    this.paymentDisplay = this.paymentDisplay.bind(this);
    this.removeRound = this.removeRound.bind(this);
    this.toggleTooltipPrePayment = this.toggleTooltipPrePayment.bind(this);
    this.toggleTooltipFinalPayment = this.toggleTooltipFinalPayment.bind(this);
    this.renderSelectButton = this.renderSelectButton.bind(this);
    this.toggleProfileCompletenessTooltip = this.toggleProfileCompletenessTooltip.bind(this);

  }

  getOrdinal(d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
  }

  getMonth(d){
    const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
    return monthNames[d.getUTCMonth()];
  }

  getOrdinalEndDate(){
    const endDate = new Date(this.props.round.end_date);
    return `${this.getMonth(endDate)} ${endDate.getUTCDate()}${this.getOrdinal(endDate.getUTCDate())}`;
  }

  roundChecked(){
    return some(this.props.selectedRounds, ['id', this.props.round.id]);
  }

  selectRound(){
    let { shoppertunity, selectedRounds } = this.props;
    if(shoppertunity.require_applications || (!shoppertunity.require_applications && selectedRounds.length == 0)){
      this.props.selectRound(this.props.round);
    }
  }

  removeRound(){
    this.props.removeRound(this.props.round);
  }

  toggleTooltipPrePayment() {
    this.setState({ showTooltipPrePayment: !this.state.showTooltipPrePayment })
  }

  toggleTooltipFinalPayment() {
    this.setState({ showTooltipFinalPayment: !this.state.showTooltipFinalPayment })
  }

  toggleSelection() {
    if(this.roundChecked()) {
      this.removeRound()
    } else {
      this.selectRound()
    }
  }

  roundDollarAmount(num){
    // check if it's a float, then uses first 2 decimal places
    if(num % 1 !== 0){
      return num.toFixed(2)
    }else{
      return num
    }
  }

  toggleProfileCompletenessTooltip(){
    this.setState({ showProfileCompletenessTooltip: !this.state.showProfileCompletenessTooltip })
  }

  paymentDisplay(){
    let { meta, round, shoppertunity } = this.props;
    if(round.open_bid)
      return <div className="js_round_payment">
        <h2 className="payment-amount js_total_payment">
          <i className={`fas fa-i social-icon--${shoppertunity.platform.name.toLowerCase()}`} />
          <strong><span className="text-success">&nbsp;${this.roundDollarAmount(round.calc_final_payment + round.pre_payment)}</span></strong>
          <sup className="hidden-sm hidden-xs shop_details--supertext">
            <a className="question_tooltip js_round_payment_details shop_details--label">
              <i className="fas fa-question-circle position-relative"
                onMouseLeave={() => this.toggleTooltipPrePayment()}
                onMouseEnter={() => this.toggleTooltipPrePayment()}>
                {this.state.showTooltipPrePayment && <Tooltip centerText={false} placement='right' message={meta.I18n.shop_posting.help_text.open_bid_prepayment} />}
              </i>
            </a>
          </sup>
          <small className="badge background-color-subdued" style={{marginLeft: 10}}>
            {meta.I18n.shop_posting.open_bid}&nbsp;
            <i className="fas fa-question-circle position-relative hidden-sm hidden-xs"
              onMouseLeave={() => this.toggleTooltipFinalPayment()}
              onMouseEnter={() => this.toggleTooltipFinalPayment()}>
              {this.state.showTooltipFinalPayment && <Tooltip centerText={false} placement='right' message={meta.I18n.shop_posting.help_text.open_bid_final_payment} />}
            </i>
          </small>
        </h2>
      </div>
    else if(round.payment){
      const metricsUpdate = shoppertunity.metrics_updated ? shoppertunity.metrics_updated : '';
      const tooltip = `Prepayment: $${this.roundDollarAmount(round.pre_payment)} Final Payment: $${this.roundDollarAmount(round.calc_final_payment)}${'*' + metricsUpdate}`
      return <div className="js_round_payment">
        <h2 className="js_total_payment">
          <i className={`fas fa-i social-icon--${shoppertunity.platform.name.toLowerCase()}`} />
          <strong><span className="text-success">&nbsp;${this.roundDollarAmount(round.calc_final_payment + round.pre_payment)}</span></strong>
          <sup className="hidden-sm hidden-xs shop_details--supertext">
            <a className="question_tooltip js_round_payment_details shop_details--label">
              <i className="fas fa-question-circle position-relative"
                onMouseLeave={() => this.toggleTooltipFinalPayment()}
                onMouseEnter={() => this.toggleTooltipFinalPayment()}>
                { this.state.showTooltipFinalPayment && <Tooltip centerText={false} placement='right' message={tooltip}/>}
              </i>
            </a>
          </sup>
          </h2>
      </div>
    }
  }

  renderSelectButton() {
    let {meta, canApply} = this.props;
    if (canApply) {
      return <div>
        <button
          className={`shop_details--round_select_button btn ${this.roundChecked() ? 'success' : 'primary outline'}`}
          onClick={this.toggleSelection.bind(this)}>
          Select{this.roundChecked() ? 'ed' : ''}
        </button>
      </div>
    } else {
      return <div>
        <a className="shop_details--round_select_button btn btn-default"
          onMouseLeave={this.toggleProfileCompletenessTooltip}
          onMouseEnter={this.toggleProfileCompletenessTooltip}>
          Select
          {this.state.showProfileCompletenessTooltip && <Tooltip centerText={true} placement='left' message={meta.I18n.shop_posting.help_text.complete_profile_alert}/>}
        </a>
      </div>
    }
  }

  render() {
    const roundCardCss = `card clickable card-plus round_card js_round_card ${this.roundChecked() ? 'shop_details--round_checked' : ''}`
    let { meta, key, round } = this.props;
    const roundTasks = round.round_tasks;
    return <div key={key} className={roundCardCss}>
              <div className="round_card--row round_card--shop_name">
                <h4 className="mb-0 shop_details--label">{round.name}</h4>
              </div>

              <div className="round_card--row clearfix" style={{position: 'relative'}}>
                <div>
                  <h3 className="mt-o round_card--due_date">{meta.I18n.shop_posting.due || 'Due'}: {this.getOrdinalEndDate()}</h3>
                  {this.paymentDisplay()}
                  {this.renderSelectButton()}
                </div>
              </div>
              <div className="round_card--row">
                <div className="round_card--description js_round_description">
                  <div className="round_card--description-body" dangerouslySetInnerHTML={{ __html: round.description }} />
                  <div className="round_card-description-fade"/>
                </div>
              </div>
              <div className="round_card--row" style={{marginBottom: 10}}>
                <div className="shop_details--label">{meta.I18n.shop_posting.required_tasks}</div>
                  <div className="shop_details--round_tasks">{roundTasks && roundTasks.map((task, index) => <a key={index} className="badge background-color-subdued">{task.name}</a>)}</div>
              </div>
          </div>
  }

  static mapStateToProps(state, myProps){
    return {
      ...myProps,
      meta: state.meta,
      shoppertunity: state.shoppertunity,
      selectedRounds: state.selectedRounds
    };
  }

  static mapDispatchToProps(dispatch){
    return {
      selectRound: round => dispatch({ type: 'SELECT_ROUND', value: round}),
      removeRound: round => dispatch({ type: 'REMOVE_ROUND', value: round})
    };
  }
};

ShoppertunitiesRoundCard.displayName = 'Shoppertunities.RoundCard';

export default connect(
  ShoppertunitiesRoundCard.mapStateToProps,
  ShoppertunitiesRoundCard.mapDispatchToProps
)(ShoppertunitiesRoundCard);