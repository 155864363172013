import React from 'react';
import { FileUploader } from '../utils';

export default class ImageUploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      networkRequests: []
    };

    this.render = this.render.bind(this);
    this.proxyClickToInput = this.proxyClickToInput.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.sendToServer = this.sendToServer.bind(this);
    this.addRequest = this.addRequest.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
  }

  componentWillUnmount() {
    this.state.networkRequests.forEach((xhr) => xhr.abort());
  }

  render() {
    return (
      <div className='section-footer image-uploader'>
        <button className='btn btn-default upload-btn' onClick={this.proxyClickToInput}>
          <i className= 'fas fa-folder-o' /> Upload Images
        </button>

        <input className='upload-input hidden' type='file' accept=".jpg,.jpeg,.png,.bmp,.gif" multiple='true' onChange={this.handleUpload} />
      </div>
    );
  }

  proxyClickToInput(event) {
    event.preventDefault();
    $('.image-uploader .upload-input').trigger('click');
  }

  handleUpload(event) {
    event.preventDefault();
    const files = event.target.files;
    for(let file of files) {
      this.sendToServer(file);
    }
  }

  sendToServer(file) {
    const formData = new FormData();
    formData.append('uploaded_image', file);
    formData.append('promoter_ad_state_id', this.props.wizardStateId);
    FileUploader().request({
      url: this.props.uploadEndpoint,
      method: 'POST',
      processData: false,
      data: formData
    }).then((response) => this.props.addNewUrl(response.data.url));
  }

  addRequest(xhr) {
    this.setState({
      networkRequests: this.state.networkRequests.concat(xhr)
    });
  }
};