import React from 'react';
import { connect } from 'react-redux';
import { Remote } from '../utils';

import RequirementChecklist from './RequirementChecklist';
import InstagramStoryCard from '../InstagramStoryCard';
import Tooltip from '../Tooltip';

class InstagramStoryContentContainer extends React.Component {

  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.submitAdminStoryContent = this.submitAdminStoryContent.bind(this);
    this.refreshStoryMedia = this.refreshStoryMedia.bind(this);
  };

  componentDidMount() {
    this.props.setMeta( _.pickBy(this.props, (_val, key) => !_.includes(['meta', 'bloggerShopTask'],key)));
  }

  submitAdminStoryContent(content_state) {
    const params = $.param({
      api_action: `/contents/${this.props.bloggerShopTask.content_id}/update_instagram_business_story_media`,
      user_id: this.props.userId
    });

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      data: {
        stories: this.props.bloggerShopTask.instagram_business_story_medias,
        content_state: content_state
      },
      method: 'PATCH',
    }).then(response => {
      window.location.href = this.props.returnUrl;
    }).catch(response => {
    });
  }

  refreshStoryMedia(id, callback) {
    const params = $.param({
      api_action: '/contents/refresh_story_media',
      instagram_business_story_media_id: id
    });

    const triggerCallback = () => {
      if (typeof callback === 'function') {
        callback();
      }
    }
    if (!this.props.admin) {
      triggerCallback();
      return;
    }
    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'POST',
    }).then(response => {
      this.props.updateStory(response.data);
      triggerCallback()
    }).catch(response => {
      triggerCallback();
      console.log(response.data);
    })
  }


  render() {
    const { manualSubmission, bloggerShopTask, admin } = this.props;
    const hasTask = (Object.keys(bloggerShopTask).length > 0 && bloggerShopTask.round_task.round_task_requirements.length > 0)

    if(hasTask){
      const stories = bloggerShopTask.instagram_business_story_medias
      let rejectSelected = _.some(stories, ['state', 'rejected']);

      return (
        <div className="instagram-story-view">
          {
            stories.map((story) => (
              <InstagramStoryCard
                key={story.id}
                story={story}
                updateStory={this.props.updateStory}
                admin={admin}
                refreshStory={this.refreshStoryMedia}
              />)
            )
          }
          <br/>
          <div className="content_info" >
            {manualSubmission &&
              <div className="content_info-followers">
                <label htmlFor="fallback_followers_story_content">
                  Instagram Followers at Submission:
                </label>
                <input
                  type="text"
                  id="fallback_followers_story_content"
                  onChange={(e) => this.props.updateFallbackFollowers(e.target.value)}
                  defaultValue={stories[0].fallback_follower_count}
                  disabled={bloggerShopTask.state === 'accepted'}
                  required
                />
              </div>
            }
            <h4 className="content_info-due_date">
              Due Date:
              <span className="content_info-value"> {this.props.dueDate} </span>
            </h4>
            <h4 className="content_info-submit_date">
              Submitted at:
              <span className="content_info-value"> {this.props.submittedAt} </span>
            </h4>
          </div>
          <br/>
          <div className='row'>
            <RequirementChecklist
              submitAdminStoryContent={this.submitAdminStoryContent}
              rejectSelected={rejectSelected}
              />
          </div>
        </div>
      );
    } else {
      return (
        <div className="instagram-story-view">
          <h3 className='text-center'>loading content....</h3>
          <div className='row'>
            { this.props.meta.proxyUrl &&
              <RequirementChecklist
                rejectSelected={false}
              />
            }
          </div>
        </div>
      );
    }
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      meta: state.meta,
      bloggerShopTask: state.bloggerShopTask,
      contentState: state.contentState,
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      setMeta: meta => dispatch({ type: 'SET_META', value: meta }),
      toggleSubmitting: () => dispatch({ type: 'TOGGLE_SUBMITTING'}),
      updateRequirementValidation: requirement => dispatch({ type: 'UPDATE_REQUIREMENT_VALIDATION', value: requirement }),
      updateStory: story => dispatch({ type: 'UPDATE_STORY', value: story}),
      updateFallbackFollowers: followers => dispatch({ type: 'UPDATE_STORY_FALLBACK_FOLLOWERS', value: followers})
    };
  }
};

InstagramStoryContentContainer.displayName = 'Contents.InstagramStoryContentContainer';

export default connect(
  InstagramStoryContentContainer.mapStateToProps,
  InstagramStoryContentContainer.mapDispatchToProps
)(InstagramStoryContentContainer);

