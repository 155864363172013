import React from 'react';
import { Remote } from '../../../utils';

import Tooltip from '../../../Tooltip';

export default class CampaignsRequirementsTaskContentRequirementButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltip: {
        button: null,
        visible: false
      }
    }

    this.render = this.render.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.createRoundTask = this.createRoundTask.bind(this);
  }

  toggleTooltip(button) {
    this.setState({
      tooltip: {
        button: button,
        visible: !this.state.tooltip.visible
      }
    })
  }

  createRoundTask(){
    const params = $.param({
      api_action: `/round_tasks`,
      campaign_id: this.props.campaign_id
    });

    const task_params = {
      campaign_id: this.props.campaign_id,
      template_type: 'campaign',
      content_types: [],
      community_id: []
    };

    Remote().request({
      method: 'POST',
      url: this.props.proxyUrl + '?' + params,
      data: task_params,
    }).then((response) => {
      if (response.data) {
        this.props.addTask(response.data);
      }
    }).catch((error) => {
      this.props.openNotification(
        'Failed to create requirement! Please try again.',
        'failure',
        error
      );
      setTimeout(this.props.closeNotification, 5000);
    });
  }

  render(){
    const { tooltip: { button, visible } } = this.state;

    return (
      <div className='btn task_button' onClick={(e) => this.createRoundTask()} onMouseLeave={() => this.toggleTooltip('task')} onMouseEnter={() => this.toggleTooltip('task')}>
        <i className='fas fa-plus'></i>
          &nbsp; Task Requirements
        { (visible && button === 'task') && <Tooltip centerText={true} placement='bottom' message={this.props.I18n.tooltips.taskButton}/>  }
      </div>
    );
  }
}

CampaignsRequirementsTaskContentRequirementButton.displayName = "Campaigns.Requirements.TaskContentRequirementButton"