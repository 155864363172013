import React from 'react';

export default class UserSocialPlatform extends React.Component {
  render(){
    const {user_name, fqdn} = this.props.notification;
    return (
      <React.Fragment>
        <b>{user_name}</b> has added the blog<b>{fqdn}</b> to their profile.
          Please review the new blog and assign a tier.
      </React.Fragment>
    )
  }
}
UserSocialPlatform.displayName = 'Notification.Templates.UserSocialPlatform'