import React from 'react';
import { Provider } from "react-redux"
import TableWrapperStore from '../redux/shared/TableWrapperStore';
import TableIndex from './Index'

export default class Wrapper extends React.Component {
  render() {
    return (
      <Provider store={TableWrapperStore}>
        <TableIndex  {...this.props}/>
      </Provider>
    );
  }
};
