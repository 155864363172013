import React from 'react';

export default class UserPromoPost extends React.Component {
  render(){
    const {activity, promotion_name} = this.props.notification;
    const P = React.Fragment;
    switch(activity) {
      case 'active':
        return (
          <P> was not approved because it did not match the provided copy and/or image.
             Please correct any differences and resubmit your promotion.
          </P>
        )
      case 'passed':
        return (
          <P>
            Your content for <b>{promotion_name}</b> has been accepted. Your Payment is now processed.
          </P>
        )
      case 'failed':
        return (
          <P>
            Your content for <b>{promotion_name}</b> was not approved because it did not match the provided copy and/or image.
          </P>
        )
      default:
        return null;
    }
  }
}
UserPromoPost.displayName= 'Notification.Templates.UserPromoPost'