import React from 'react';
import { connect } from 'react-redux';
import { FileUploader } from '../utils';

import Alert from '../Alert';
import MultiSelect from '../MultiSelect';
import ConversationsMessageEntryFields from './MessageEntryFields'


class ConversationsNew extends React.Component {
  constructor(props) {
    super(props);
    this.searchUser = this.searchUser.bind(this);
    this.render = this.render.bind(this);
    this.sendConversation = this.sendConversation.bind(this);
    this.preloadRecipients = this.preloadRecipients.bind(this);
  }

  sendConversation(event){
    event.preventDefault();
    const {file, media_mode, recipients, authenticity_token, recipient_ids, shop_conversation} = this.props;
    const body = (!!media_mode ? file : this.props.body);
    const receivers = (!!shop_conversation ? recipient_ids : recipients);
    const messageType = (!!media_mode ? 'image' : 'text');
    let data= new FormData();
    if(recipients.length===0){
      this.props.toggleNoRecipients();
      return;
    }
    data.append('authenticity_token', authenticity_token);
    data.append('body', body);
    data.append('message_type', messageType);
    data.append('recipients', receivers)
    this.props.setMessageLoading({message_loading: true})
    FileUploader().request({
      method: 'POST',
      url: `/conversations/`,
      data: data,
    }).then((response) => {
      this.props.updateNewConversation({conversation: response.data});
      this.props.resetMessageState();
    }).catch((error) => {
      this.props.setMessageLoading({message_loading: false});
    });
  }

  render() {
    const {recipients, recipient_ids, no_recipients_error, shop_conversation} = this.props;
    const receivers = (!!shop_conversation ? recipient_ids : recipients);

    return(
      <form onSubmit={this.sendConversation}>
        {
          no_recipients_error && <Alert type='error' message='Must have atleast one recipient.' callback={this.props.toggleNoRecipients}/>
        }
        <div className='conversation-window-header new'>
          <span className='conversation-desktop-header' onClick={() => this.props.setSelected({selected: false})}>
            <i className='fas fa-chevron-left' />
          </span>
          <MultiSelect search={this.searchUser}
                       selected={receivers}
                       onUpdate={recipientIds => this.props.setRecipients({ recipients: recipientIds })}
                       disabled={!!shop_conversation}
                       clearInvalidInput={true}
                       placeholder='Send to...'/>
        </div>
        <div id='conversation-messages-container' className="conversation-container position-relative" />
        <ConversationsMessageEntryFields />
      </form>
    );
  }

  preloadRecipients(data) {
    const { shop_conversation, recipients} = this.props
    if (shop_conversation === true) {
      return data.concat(recipients)
    }
    return data
  }

  searchUser(text, callback) {
    if(!text) {
      callback([]);
    }
    $.get(`${location.origin}/conversations/search?c=users&q=${text}`).then(
      data => callback(this.preloadRecipients(data))
    );
  }

  static mapStateToProps(state, myProps) {
    return {
      loading: state.loading,
      selected: state.selected,
      conversation: state.conversation,
      conversations: state.conversations,
      message_loading: state.message_loading,
      media_mode: state.media_mode,
      file: state.file,
      body: state.body,
      authenticity_token: state.authenticity_token,
      recipients: state.recipients,
      recipient_ids: state.recipient_ids,
      shop_conversation: state.shop_conversation,
      no_recipients_error: state.no_recipients_error,
      ...myProps
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      toggleLoading: () => dispatch({type: 'TOGGLE_LOADING'}),
      setSelected: data => dispatch({type: 'SET_SELECTED', value: data}),
      appendMessage: data => dispatch({type: 'ADD_MESSAGE', value: data}),
      setMessageLoading: data => dispatch({type: 'SET_MESSAGE_LOADING', value: data}),
      setMessageFields: data => dispatch({type: 'SET_MESSAGE_FIELDS', value: data}),
      resetMessageState: () => dispatch({type: 'RESET_MESSAGE_STATE'}),
      setRecipients: data => dispatch({type: 'SET_RECIPIENTS', value: data}),
      setConversation: data => dispatch({type: 'SET_CONVERSATION', value: data}),
      updateNewConversation: data => dispatch({type: 'UPDATE_NEW_CONVERSATION', value: data}),
      toggleNoRecipients: () => dispatch({type: 'TOGGLE_NO_RECIPIENTS_ERROR'})
    };
  }
}

ConversationsNew.displayName = 'Conversations.New';
export default connect(
  ConversationsNew.mapStateToProps,
  ConversationsNew.mapDispatchToProps
)(ConversationsNew);
