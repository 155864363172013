import React from 'react';
import { Remote } from './utils';
import { TitleCase } from './utils';
import SaveIndicator from './SaveIndicator'
import LoadingSpinner from './LoadingSpinner'

export default class Guardrails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      instructionId: this.props.instructionId,
      proxyUrl: this.props.proxy_url,
      saving: false,
      saveType: 'info',
      saveText: '',
      saveErrors: null,
      timeoutId: null,
      legacy: false,
      loading: true,
      guardrails: this.props.guardrails
    };
    this.loadGuardrails = this.loadGuardrails.bind(this);
  }

  componentDidMount() {
    this.loadGuardrails();
  }

  saveGuardrails(e){
    if(this.state.timeoutId) {
      window.clearTimeout(this.state.timeoutId);
    }
    const params = $.param({
      api_action: `/instructions/${this.state.instructionId}/update_guardrails`
    });

    this.setState({
      saving: true,
      saveType: 'info',
      saveText: 'Saving guardrails...',
      saveErrors: null,
      timeoutId: null
    });
  
   return Remote().request({
      url: this.props.proxy_url + '?' + params,
      data: { guardrails: { [e.target.name]: e.target.checked }},
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      this.setState({
        ...this.state,
        ...response.data,
        loading: false
      })
      this.flash({
        saveText: 'Successfully saved guardrails.',
        saveType: 'success'
      }, 3000)
    }).catch((error) => {
      this.flash({
        saveText: 'Failed to save guardails!',
        saveType: 'failure',
        saveErrors: error
      }, 5000)
    });
  };

  flash(newState, duration) {
    this.setState(newState, () => {
      const timeoutId = window.setTimeout(() => this.setState({
        saving: false
      }), duration);

      this.setState({ timeoutId });
    });
  }

  render() {
    if(this.state.loading) {
      return <div className='vh-100'>
        <LoadingSpinner />
      </div>;
    }
    let { readOnly } =  this.props;
    let guardrail_labels  = ['alcohol', 'babies', 'children', 'food', 'people', 'pets'];
    const guardrails  = this.state.guardrails;
    let display_labels = readOnly ?  guardrails : guardrail_labels;
    if(readOnly && display_labels.length == 0)
    {
      return null;
    }
    else{
      return(
        <React.Fragment>
        {
          display_labels.map((key, index) => {
            if(readOnly)
            {
             return(
              <td> 
                <label>
                  <span>{TitleCase(key)}</span>
                </label>
              </td>)
            }
            else{
              return (
              <td>
                <div className="additional-guardrails">
                <label>
                <input type="checkbox" key={index} checked={readOnly || (guardrails && guardrails.includes(key))}
                  name={key} disabled={readOnly} onChange={(e) => this.saveGuardrails(e)}>
                </input>
                    {TitleCase(key)}
                </label> 
                </div>
              </td>
              ) 
            }                
          })
        }
        {
          this.state.saving &&
          <SaveIndicator type={this.state.saveType}>
            {this.state.saveText}
            {this.state.saveErrors && <SaveError errors={this.state.saveErrors}/>}
          </SaveIndicator>
        }
      </React.Fragment>
      );
    }
  }

  loadGuardrails(){
    const params = $.param({
      api_action: `/instructions/${this.state.instructionId}/guardrails`
    });

    return Remote().request({
      method: 'GET',
      url: this.props.proxy_url + '?' + params,
    }).then((response) => {
      this.setState({
        ...this.state,
        ...response.data,
        loading: false
      })
    });
  }
}
