import React from 'react';

const TypeColumnStyle = {
  width: '40px'
};

export default class Row extends React.Component {
  constructor(props) {
    super(props);

    this.from = this.from.bind(this);
    this.truncate = this.truncate.bind(this);
    this.handleConversationClick = this.handleConversationClick.bind(this);
    this.render = this.render.bind(this);
  }

  render() {
    const conversation = this.props.conversation;
    const subject = `${this.truncate(conversation.subject, 25)}`;
    const messagePreview = ` ${this.truncate(conversation.messages[0].body, 50)}`;
    const shoppertunityName = conversation.shoppertunity_name ? `${this.truncate(conversation.shoppertunity_name, 30)}` : 'none'

    return(
      <tr
        className={`message ${!conversation.unread}`}
        onClick={this.handleConversationClick}
      >
        <td>{this.from()}</td>
        <td>{shoppertunityName}</td>
        <td>{subject}<em style={{color: 'gray'}}>{messagePreview}</em></td>
        <td>{conversation.updated_at}</td>
        <td style={TypeColumnStyle}>
          {conversation.type == 'ChildConversation' &&
            <i className='fas fa-reply-all' title='This conversation originated from a bulk message'></i>
          }
          {conversation.type == 'GroupConversation' &&
            <i className='fas fa-users' title='Group Message'></i>
          }
        </td>
      </tr>
    );
  }

  from() {
    let recipients = _.filter(this.props.conversation.participants, ['current_user', false]);
    let names = _.map(recipients, recipient => {
      return `${recipient.first_name} ${recipient.last_name}`
    });

    return this.truncate(names.join(', '), 50);
  }

  truncate(text, length) {
    return text.length > length ? `${text.substr(0, length)}...` : text;
  }

  handleConversationClick() {
    window.location.assign(this.props.msgPath);
  }
}