import React from 'react';
import axios from 'axios';

const PLATFORM_OPTIONS = {
  'instagram':'Instagram',
  'youtube':"Youtube",
  'tiktok':"TikTok",
  'none':"None"
}

export default class InternalInvitation extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.userSearch = this.userSearch.bind(this);
    this.renderUserList = this.renderUserList.bind(this);
    this.renderSelectedUser = this.renderSelectedUser.bind(this);
    this.selectUser = this.selectUser.bind(this);
    this.valueChange = this.valueChange.bind(this);
    this.defaultUser = this.defaultUser.bind(this)

    this.debounceSearch = _.debounce(this.userSearch, 1000)

    this.state = {
      loadingSearch: false,
      userList: null,
      selectedUser: props.userId && this.defaultUser(props),
      platform_type: props.platformType || 'instagram',
      payment_method: props.paymentType || Object.keys(props.paymentMethods)[0]
    }
  }

  defaultUser(props) {
    const { userId, name, email, picture } = props
    return {
      id: userId,
      email: email,
      full_name: name,
      profile_picture: picture
    }
  }

  componentDidUpdate(oldProps, oldState) {
    if(oldProps.userId !== this.props.userId) {
      this.setState({
        selectedUser: this.props.userId && this.defaultUser(this.props),
        platform_type: this.props.platformType,
        payment_method: this.props.paymentType,
        payment: this.props.payment,
        message: this.props.message
      })
    }
  }

  userSearch(influencer) {
    this.setState({ loadingSearch: true });
    if ( influencer.length > 0 ) {
      axios.get(this.props.searchPath, {params: {influencer: influencer}}
      ).then((response) => {
        this.setState({
          loadingSearch: false,
          userList: response.data
        });
      });
    } else {
      this.setState({
        loadingSearch: false,
        userList: null
      });
    }
  }

  defaultUser(props) {
    const { userId, name, email, picture } = props
    return {
      id: userId,
      email: email,
      full_name: name,
      profile_picture: picture
    }
  }

  selectUser(user) {
    this.props.checkForDuplicateEmail(user.email)
    this.setState({
      selectedUser: {
        id: user._id,
        email: user.email,
        full_name: user.full_name,
        profile_picture: user.profile_picture
      }
    });
  }

  renderSelectedUser() {
    let user = this.state.selectedUser;
    const disabled = !!this.props.userId
    const callback = !disabled ? () => this.setState({ selectedUser: null }) : null
    const classes = disabled ? ' disabled' : ''
    return (
      <ul className={'form-control users selected' + classes}>
        <li className={'list-item' + classes} onClick={callback}>
          <img src={user.profile_picture}/>
          <span>{user.full_name}</span>
        </li>
      </ul>
    )
  }

  renderUserList() {
    let userList = this.state.userList;

    return (
      <ul className='form-control users list'>
        {[
          userList.map((user) => {
            return (
              <li className='list-item' key={user._id} onClick={() => this.selectUser(user)}>
                <img src={user.profile_picture} />
                <span>{user.full_name}</span>
              </li>
            )
          }),
          <li className={`${userList.length > 0 ? 'total' : 'no' }-results`} key="end">
            { userList.length > 0 ? `${userList.length} results` : 'No users found' }
          </li>
        ]}
      </ul>
    )
  }

  valueChange(e) {
    this.setState({[e.target.name]: e.target.value}, this.props.setDirty())
  }

  platformOptions(selected) {
    return Object.keys(PLATFORM_OPTIONS).map( (name) => {
      return <option key={name} value={name} selected={selected ==={name}}>
              {PLATFORM_OPTIONS[name]}
            </option>
    })
  }

  render() {
    const { vipMessage, userId, paymentMethods, roundId, rounds} = this.props
    const { platform_type, loadingSearch, userList, selectedUser, payment_method, round, message, payment } = this.state

    let defaultMessage = userId ? message : platform_type == 'none' ? vipMessage : this.props.message

    return (
      <form onSubmit={(e) => this.props.submit(e)} id='internal_form' method='POST'>
        <div className='form-group required'>
          <label className='control-label'><abbr title="required">*</abbr> Round</label>
          <select className='form-control' name='round' required onChange={this.valueChange} value={round || roundId}>
            <option value=''>Select Round</option>
            { rounds.map(({id, name}) => (
              <option key={id} value={id} selected={parseInt(id) == roundId}>
                {name} {parseInt(id)}
              </option>
              )
            )}
          </select>
        </div>
        <div className='form-group required'>
          <label className='control-label'><abbr title="required">*</abbr> Authentication Platform</label>
          <select className='form-control' name='platform_type' onChange={this.valueChange} required>
            <option value='instagram' selected={platform_type ==='instagram'}>Instagram</option>
            <option value='youtube' selected={platform_type ==='youtube'}>YouTube</option>
            <option value='tiktok' selected={platform_type ==='tiktok'}>TikTok</option>
            <option value='none' selected={platform_type ==='none'}>None</option>
          </select>
        </div>
        <div className='row'>
          <div className='form-group required col-xs-6' style={{'display': 'inline-block'}}>
            <label className='control-label'><abbr title="required">*</abbr> Influencer</label>
            <input type="hidden" id="email" name="email" value={selectedUser ? selectedUser.email : ''}/>
            <input type="hidden" id="full_name" name="full_name" value={selectedUser ? selectedUser.full_name : ''}/>
            <input
              id='influencer_search'
              className={`form-control ${loadingSearch ? 'ui-autocomplete-loading' : ''}`}
              onChange={(e) => this.debounceSearch(e.target.value)}
              type='text'
              name='influencer'
              style={selectedUser ? {'display': 'none'} : {}}
              required={!userId} autoComplete='off'
            />
            { selectedUser && this.renderSelectedUser() }
            { (!loadingSearch && !!userList) && !selectedUser && this.renderUserList() }
            <small className='help-block'>{this.props.campaignCommunitiesNote}</small>
          </div>
          <div className='form-group required col-xs-6' style={{'display': 'inline-block', 'float': 'right'}}>
            <label className='control-label'><abbr title="required">*</abbr> Payment</label>
            <div className='input-group'>
              <div className='input-group-addon'>$</div>
              <input className='form-control currency' type='number' onChange={this.valueChange} value={payment || ''} name='payment' min='0' step='1.00' required/>
              <div className='input-group-addon'>.00</div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='form-group required col-xs-6' style={{'display': 'inline-block' }}>
            <label className='control-label'><abbr title="required">*</abbr> Payment Method</label>
            <select className='form-control' name='payment_method' value={payment_method} onChange={this.valueChange} required>
              {Object.keys(paymentMethods).map((pm) => {
                return (<option key={paymentMethods[pm]} value={paymentMethods[pm]}>{pm}</option>)
              })}
            </select>
          </div>
          { payment_method == 'paypal' &&
              <div className='form-group col-xs-6' style={{'display': 'inline-block', 'float': 'right'}}>
                <label className='control-label'>Paypal ID</label>
                <input className='form-control' name='paypal_id' defaultValue={this.props.paypalEmail}/>
              </div>
          }
        </div>
        <div className='form-group'>
          <label className='control-label'>Message</label>
          <textarea className='form-control'
                    name='message'
                    rows='4'
                    key={platform_type}
                    value={message || defaultMessage} onChange={this.valueChange} />
        </div>
      </form>
    )
  }
}
InternalInvitation.displayName = 'InternalInvitation'
