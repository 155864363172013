import React from 'react';

import YoutubeCard from './Card'

class YoutubeVideoSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedVideoId: null
    };

    this.render = this.render.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.selectVideo = this.selectVideo.bind(this);
    this.getSelectedVideoUrl = this.getSelectedVideoUrl.bind(this);
  }

  componentDidMount() {
    const url = document.getElementById(Youtube.VideoSelector.CONTENT_URL_SELECTOR).value;
    if(url) {
      const id = _.find(this.props.videos, (vid) => vid.url == url).id;
      this.selectVideo(id);
    } else {
      document.getElementById(Youtube.VideoSelector.SUBMIT_BUTTON_SELECTOR).disabled = true;
    }
  }

  selectVideo(id) {
    this.setState({ selectedVideoId: id }, () => {
      document.getElementById(Youtube.VideoSelector.CONTENT_URL_SELECTOR).value = this.getSelectedVideoUrl();
      document.getElementById(Youtube.VideoSelector.SUBMIT_BUTTON_SELECTOR).disabled = false;
    });
  }

  getSelectedVideoUrl() {
    return _.find(this.props.videos, (vid) => vid.id == this.state.selectedVideoId).url;
  }

  render() {
    return (
      <div className="youtube-contents">
        {
          this.props.videos.map(
            (vid) => <YoutubeCard selectVideo={() => this.selectVideo(vid.id)} 
                                   video={vid}
                                   key={vid.id}
                                   selected={this.state.selectedVideoId == vid.id}
                                   />
          )
        }
      </div>
    );
  }
}
YoutubeVideoSelector.SUBMIT_BUTTON_SELECTOR = 'submit-btn';
YoutubeVideoSelector.CONTENT_URL_SELECTOR = 'content_url';

YoutubeVideoSelector.displayName = 'Youtube.VideoSelector';
export default YoutubeVideoSelector