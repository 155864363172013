import React from 'react';
import RichTextEditor from '../../../RichTextEditor'

export default class CampaignsRequirementsInstructionNotes extends React.Component {

  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.editInstructionNote = this.editInstructionNote.bind(this);
    this.debouncedSync = _.debounce(this.editInstructionNote, 500);
  }

  editInstructionNote(value) {
    let requirement = {
      ...this.props.requirement,
      description: value
    }

    this.props.editInstructionNotes(requirement);
    this.props.updateCampaignInstructionNotes(requirement)
  }

  openState = (open) => {
    return open ? { display: 'block' } : {display: 'none'}
  }

  render(){
    const placeholder = "Add notes..."
    let placeholderView = `<p>${placeholder}</p>`

    let requirement = this.props.requirement;
    return (
      <div className='internal-notes-description row'>
        <div className='col-md-12'>
          <div style={this.openState(this.props.isOpen)}>
            <RichTextEditor
              placeholder={placeholder}
              value={requirement.description}
              onUpdate={(html) => this.debouncedSync(html)}
            />
          </div>
          <div className='description--view' style={this.openState(!this.props.isOpen)}
            dangerouslySetInnerHTML={{__html: requirement.description || placeholderView}}
          />
        </div>
      </div>
    );
  }
}
