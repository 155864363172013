import React from 'react';
var ShopStartEndDate = createReactClass({

  render: function () {
    var shop = this.props.shop;
    var shop_dates = shop.dates;
    var shop_dates_text = 'Due: ';
    var shop_dates_class = 'due-dates';

    if( shop_dates.length == 0 ){
      shop_dates_text = '';
    } else if (shop_dates.length >= 2) {
      shop_dates = <div className="dates-span">{shop.begins_redesign} - {shop.ends_redesign}</div>;
      shop_dates_class = 'due-dates due-span';
    }

    return (
      <div className="shop-begins-ends">
        <div className={shop_dates_class}>
          <div className="dates-text">{shop_dates_text}</div>
          <div className="dates">{shop_dates}</div>
        </div>
      </div>
    );
  }
});
