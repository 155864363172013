import React from 'react';
import _ from 'lodash';
import UserSignUpNavigation from './Navigation';
import UserSignUpTosPrompt from './TosPrompt';
import UserSignUpSocialInfo from './SocialInfo';
import UserSignUpBasicInfo from './BasicInfo';
import UserSignUpPersonalInfo from './PersonalInfo';
import UserSignUpPaymentInfo from './PaymentInfo';
import UserSignUpProcessingInfo from './ProcessingInfo';
import UserSignUpLoadingSpinner from './LoadingSpinner';

export default class UserSignUpWizard extends React.Component {
  constructor(props) {
    super(props);

    let wizardSteps = this.filterWizardSteps(this.props.tabSteps, this.props.i18n.tabs);

    this.state = {
      ...{...this.props.i18n, tabs: wizardSteps},
      social: {
        ...this.props.social,
        ...this.props.i18n.social,
        instagram_auth: JSON.parse(window.localStorage.getItem('instagram_auth')) || {},
        facebook_auth: JSON.parse(window.localStorage.getItem('facebook_auth')) || {},
        pinterest_auth: JSON.parse(window.localStorage.getItem('pinterest_auth')) || {},
        twitter_auth: {},
        youtube_auth: JSON.parse(window.localStorage.getItem('youtube_auth')) || {},
      },
      personal: {
        ...this.props.personal,
        ...this.props.i18n.personal
      },
      payment: {
        ...this.props.payment,
        ...this.props.i18n.payment
      },
      step: Object.keys(wizardSteps)[0],
      latestStep: Object.keys(wizardSteps)[0],
      stepMap: Object.keys(wizardSteps),
      name: null,
      continueDisabled: false,
      navigationDisabled: false,
      targetCommunity: {
        id: window.localStorage.getItem('sf_community_id'),
        code: window.localStorage.getItem('sf_community_code')
      }
    };
    this.authListener = this.authListener.bind(this);
    this.continue = this.continue.bind(this);
    this.disableContinue = this.disableContinue.bind(this);
    this.disableNavigation = this.disableNavigation.bind(this);
    this.jumpToStep = this.jumpToStep.bind(this);
    this.render = this.render.bind(this);
    this.renderContinue = this.renderContinue.bind(this);
    this.renderName = this.renderName.bind(this);
    this.renderNavigation = this.renderNavigation.bind(this);
    this.renderStep = this.renderStep.bind(this);
    this.retrieveAllValues = this.retrieveAllValues.bind(this);
    this.retrieveInfo = this.retrieveInfo.bind(this);
    this.tabDataFromProps = this.tabDataFromProps.bind(this);
    this.updateAuth = this.updateAuth.bind(this);
    this.updateInfo = this.updateInfo.bind(this);
    this.updateLatestStep = this.updateLatestStep.bind(this);
  }

  filterWizardSteps(tabList, allSteps){
    return _.pickBy(allSteps, function(value, key) {
      return (tabList.includes(key));
    });
  }

  authListener(authType){
    if (window.localStorage.getItem(authType) != 'null') {
      this.updateAuth(authType, JSON.parse(window.localStorage.getItem(authType)))
    }
  }

  updateAuth(authType, authValues){
    this.setState({
      social: {
        ...this.state.social,
        [authType]: authValues
      }
    });
  }

  updateInfo(field, value){
    const { step } = this.state;

    this.setState(prevState => ({
      [step]: {
        ...(prevState.hasOwnProperty(step) ? prevState[step] : {} ),
        values:{
         ...(prevState.hasOwnProperty(step) && prevState[step].hasOwnProperty('values') ? prevState[step].values: {}),
         [field]: value
        }
      }
    }))
  }

  retrieveInfo(stepName, field){
    const step = this.state[stepName];
    if (!step) return '';

    const { values } = step;
    if (!values) return '';

    return values[field];
  }

  retrieveAllValues(){
    const { stepMap } = this.state
    let allValues = stepMap.map((stepName) => {
      if (!this.state[stepName] || !this.state[stepName].values)
        return {}
      else
        return this.state[stepName].values
    }).filter(values => Object.keys(values).length > 0)
      .reduce((allValues, values) => Object.assign({}, allValues, values), {});

    ['facebook', 'instagram', 'twitter', 'pinterest', 'youtube'].forEach((auth) => {
      allValues[auth] = this.state['social'][auth+'_auth']
    });

    return {
      ...allValues,
      targetCommunity: this.state.targetCommunity
    };
  }

  render() {
    return (
      <div className='sign-up-container'>
        {this.renderNavigation()}
        <div className='sign-up-title'>
          {this.renderName()}
        </div>
        <div className='sign-up-step'>
          {this.renderStep()}
        </div>
        <div className='sign-up-footer'>
          {this.renderContinue()}
        </div>
      </div>
    )
  }

  renderNavigation() {
    const { step, stepMap, latestStep, navigationDisabled } = this.state;
    return (<UserSignUpNavigation
              latestStep={latestStep}
              step={step} stepMap={stepMap}
              jumpToStep={this.jumpToStep}
              disabled={navigationDisabled}
              tabs={this.state.tabs}
              successfulSubmit={this.retrieveInfo('finish', 'success')}
            />)
  }

  renderName() {
    const { wizard, step, stepMap } = this.state;
    const { welcome } = wizard;
    const name = this.retrieveInfo('basic','firstName');

    const completed = this.retrieveInfo('finish', 'success');

    if (!name ) {
      return (
        <h1> { welcome.unknown }</h1>
      )
    } else {
      if (completed)
        return <h1>{welcome.success}</h1>

      const prefix = stepMap.indexOf(step) !== stepMap.length - 1 ? welcome.prefix : completed === false ? welcome.error : welcome.finish
      return (
        <h1>{prefix}<span> {name} </span>{welcome.suffix}</h1>
      )
    }
  }

  renderStep() {
    const { step } = this.state;
    const requiredProps = {
      ...this.state[step],
      fieldCallback: this.updateInfo,
      disableContinue: this.disableContinue,
      disableNavigation: this.disableNavigation
    }

    switch(step){
      case 'terms':
        return <UserSignUpTosPrompt
          {...requiredProps}
          termsOfService={this.props.terms_of_service}
          communityTos={this.props.community.tos}
          communityName={this.props.community.name}
          />
      case 'social':
        return <UserSignUpSocialInfo
                {...requiredProps}
                authListener={this.authListener}
                updateBlogInfo={this.updateBlogInfo}
                updateAuth={this.updateAuth}
              />
      case 'basic':
        return <UserSignUpBasicInfo {...requiredProps} country={this.retrieveInfo('basic','country')}/>
      case 'personal':
        return <UserSignUpPersonalInfo {...requiredProps} />
      case 'payment':
        return <UserSignUpPaymentInfo {...requiredProps} country={this.retrieveInfo('basic','country')} dob={this.retrieveInfo('basic','dob')}/>
      case 'finish':
        return <UserSignUpProcessingInfo
                  {...requiredProps}
                  values={this.retrieveAllValues()}
                  communityId={this.props.community.id}
                  referrerUrl={this.props.referrerUrl}
                />
      default:
        return <UserSignUpLoadingSpinner loading={true} />
    }
  }

  renderContinue() {
    const { continueDisabled, stepMap, step } = this.state

    if (stepMap.indexOf(step) === stepMap.length - 1 )
      return null

    return (
      <button className='btn btn-primary continue' onClick={this.continue} disabled={continueDisabled}>
        Continue
      </button>
    )
  }

  disableNavigation(bool) {
    this.setState({navigationDisabled: bool});
  }

  disableContinue(bool) {
    this.setState({continueDisabled: bool});
  }

  continue() {
    if (this.state.continueDisabled) return;
    const { stepMap, step } = this.state;
    const nextStep = stepMap[stepMap.indexOf(step) + 1];
    // Shouldn't happen but prevent movement to unknown states
    if (!nextStep) return
    this.setState({
      step: nextStep,
      navigationDisabled: false
    });
    this.updateLatestStep(nextStep)
  }

  updateLatestStep(stepName) {
    const { stepMap, latestStep } = this.state;
    const stepIndex = stepMap.indexOf(stepName);
    const latestIndex = stepMap.indexOf(latestStep);
    if (latestIndex <= stepIndex)
      this.setState({latestStep: stepName})
  }

  jumpToStep(stepName) {
    if (this.state.navigationDisabled) return;
    const { stepMap, step } = this.state;
    if ( step !== stepName && stepMap.indexOf(stepName) !== undefined )
      this.setState({step: stepName, continueDisabled: false});
  }

  tabDataFromProps(){
    const { wizardSteps } = this.state

    if (wizardSteps === null) return {}

    Object.values(
      Object.keys(wizardSteps.tabs).map((tabKey) => {
        return { ...wizardSteps[tabKey] }
      })
    )
  }
}

UserSignUpWizard.displayName = 'UserSignUpWizard';
