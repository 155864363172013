import { createStore } from 'redux';

const initialState = {
  loading: true,
  view: undefined,
  views: [],
  community: {},
  login_settings: {},
  theme_settings: {},
  validations: {},
  notifications: {
    saving: false,
    saveType: '',
    saveText: '',
    saveErrors: ''
  },
};

const reducer = (state = initialState, action) => {
  const { type, value } = action;
  switch(type) {
    case 'SET_COMUNITY':
      return {
        ...state,
        community: action.value,
      };
    case 'SET_COMUNITY_LOGIN_SETTINGS':
      return {
        ...state,
        login_settings: action.value,
        validations: {}
      };
      case 'SET_COMUNITY_THEME_SETTINGS':
        return {
          ...state,
          theme_settings: action.value,
          validations: {}
        };
    case 'UPDATE':
      return {
        ...state,
        ...value,
        views: _.get(value, 'navigation.order')
      };
    case 'UPDATE_FIELD':
      return {
        ...state,
        [value.field]: value.data
      };
    case 'UPDATE_COMMUNITY_FIELD':
      return {
        ...state,
        community: {
          ...state.community,
          [action.value.field]: action.value.value,
        },
        validations: {
          ...validateField(_.cloneDeep(state.validations), action.value.field, action.value.value)
        }
      };
    case 'UPDATE_LOGIN_FIELD':
      return {
        ...state,
        login_settings: {
          ...state.login_settings,
          [action.value.field]: action.value.value,
        },
        validations: {
          ...validateField(_.cloneDeep(state.validations), action.value.field, action.value.value)
        }
      };
      case 'UPDATE_THEME_FIELD':
        return {
          ...state,
          theme_settings: {
            ...state.theme_settings,
            [action.value.field]: action.value.value,
          },
          validations: {
            ...validateField(_.cloneDeep(state.validations), action.value.field, action.value.value)
          }
        };
    case 'SET_CURRENT_VIEW':
      if (value && value.route)
        window.history.pushState({}, document.title, value.route);
      return {
        ...state,
        view: value.view || initialState.view,
        loading: !!value.view
      };
    case 'OPEN_NOTIFICATION':
      const {saveType, saveText, saveErrors} = action.value;
      return {
        ...state,
        notifications: {
          saving: true,
          saveType: saveType,
          saveText: saveText,
          saveErrors: saveErrors
        }
      };
    case 'CLOSE_NOTIFICATION':
      return {
        ...state,
        notifications: {
          saving: false,
          saveType: '',
          saveText: '',
          saveErrors: ''
        }
      };
    default:
      return state;
  };
};

const validateField = (newValidations, field, value) => {
  switch(field){
    case 'title':
      if(value.length == 0){
        newValidations[field] = 'Title cannot be blank.'
      }
      else{
        delete newValidations[field]
      }
      break;
    case 'description':
      break;
    case 'community_image':
    case 'theme_image':
      break;
    case 'primary_color':
      if(!value.match(/^#+([a-fA-F0-9]{6})$/)){
        newValidations[field] = 'Please provide a valid color hex code.'
      } else {
        delete newValidations[field]
      }
      break;
    case 'theme_primary_color':
    case 'theme_text_color':
      if(!value || value === ''){
        delete newValidations[field]
      } else if(!value.match(/^#+([a-fA-F0-9]{6})$/)){
        newValidations[field] = 'Please provide a valid color hex code.'
      } else {
        delete newValidations[field]
      }
      break;
    case 'status':
      if(!value || value === ''){
        newValidations[field] = 'Field cannot be blank.'
      } else {
        delete newValidations[field]
      }
      break;
    case 'community_access':
      if(!value || value === ''){
        newValidations[field] = 'Field cannot be blank.'
      } else {
        delete newValidations[field]
      }
      break;
    case 'access_code':
      if(value.length < 6){
        newValidations[field] = 'Must have at least 6 characters.'
      }
      else{
        delete newValidations[field]
      }
      break;
    default:
      {}
  }
  return newValidations
};

const AdminCommunitiesStore = createStore(
  reducer,
  window && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ name: 'Redux.AdminCommunitiesStore' }) // Enable redux devtools middleware
);

export default AdminCommunitiesStore;