import { createStore } from 'redux';

const initialState = {
  meta: {},
  shoppertunity: {},
  authConnected: false,
  loading: true,
  selectedRounds: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_META':
      return {
        ...state,
        meta: action.value
      };
    case 'SET_SHOPPERTUNITY':
      return {
        ...state,
        shoppertunity: action.value
      }
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.value
      }
    case 'SET_SELECTED_ROUNDS':
      return {
        ...state,
        selectedRounds: action.value
      }
    case 'SELECT_ROUND':
      return {
        ...state,
        selectedRounds: [...(state.selectedRounds || []), action.value]
      }
    case 'REMOVE_ROUND':
      return {
        ...state,
        selectedRounds: state.selectedRounds.filter(x => x.id != action.value.id)
      }
    default:
      return state;
  };
};

const ShoppertunityPosting = createStore(
  reducer,
  window && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ name: 'Redux.ShoppertunityPosting' }) // Enable redux devtools middleware
);

export default ShoppertunityPosting;
