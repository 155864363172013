import React from 'react';
import { connect } from 'react-redux'
import { Remote } from '../utils';

import Toggle from '../Toggle'
import PhoneNumberInput from '../PhoneNumberInput'
import Tooltip from '../Tooltip'
import {QueryString} from '../utils'
import Modal from "../Modal";

class ProfilesPaymentSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltip: {
        button: null,
        visible: false
      }
    }

    this.renderFormInput = this.renderFormInput.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.inputClass = this.inputClass.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
    this.isRequired = this.isRequired.bind(this);
    this.loadStates = this.loadStates.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.render = this.render.bind(this);
    this.textInput = this.textInput.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.validateAll = this.validateAll.bind(this);
    this.validateField = this.validateField.bind(this);
    this.validations = this.validations.bind(this);
  }

  toggleTooltip(button) {
    this.setState({
      tooltip: {
        button: button,
        visible: !this.state.tooltip.visible
      }
    })
  }

  componentDidMount() {
    this.validateAll();

    if(this.props.states.length === 0) {
      this.loadStates();
    }
  }

  render() {
    const { i18n, paymentSettings } = this.props;
    const agent_role = this.props.profile.agent_role;

    return <React.Fragment>
      <div className='card-plus'>
        <div className='card-header'>
          {i18n.labels.payments.contact}
        </div>

        <div className='card-body'>
          {!agent_role && this.textInput('first_name')}
          {!agent_role && this.textInput('last_name')}
          {this.textInput('vendor_name', false, true)}
          {this.textInput('phone')}
          {this.textInput('email')}

          <div className='form-lr'>
            <label>{i18n.labels.payments.notification}</label>

            <Toggle
              onUpdate={bool => this.props.updatePaymentSettings({ payment_notification: bool })}
              checked={paymentSettings.payment_notification || false}
              />
          </div>
        </div>
      </div>
      <div className='card-plus'>
        <div className='card-header'>
          {i18n.labels.payments.address}
        </div>

        <div className='card-body'>
          {this.textInput('country')}
          {this.textInput('state')}
          {this.textInput('city')}
          {this.textInput('address1')}
          {this.textInput('address2')}
          {this.textInput('zip')}
        </div>
      </div>
      <div className='card-plus'>
        <div className='card-header'>
          {i18n.labels.payments.tax_info}
        </div>

        <div className='card-body'>
          {this.textInput('tax_id_number', true)}
          {this.textInput('name_1099', true)}
          {this.textInput('federal_tax_classification', true)}
        </div>
      </div>
      <div className='card-plus'>
        <div className='card-header'>
          {i18n.labels.payments.bank}
        </div>

        <div className='card-body'>
          {this.textInput('ach_bank_routing_number')}
          {this.textInput('bank_account_number')}
          {this.textInput('bank_account_type')}
          {this.textInput('bank_account_classification')}
        </div>
      </div>
    </React.Fragment>;
  }

  textInput(field, enableTooltip = false, enableTooltipAsModal = false) { /* Set to true to enable tooltip or tooltipAsModal; DEFAULT: false */

    return <div className='form-group'>

      <label>{this.props.i18n.labels.payments[field] || this.props.i18n.labels[field]}
        {!enableTooltipAsModal && enableTooltip && this.renderTooltip(field)}
        {!enableTooltip && enableTooltipAsModal && this.renderTooltipAsModal(field)}
      </label>

      {this.renderFormInput(field)}

      {this.validations(field)}

    </div>;
  }

  showModal(val){
    this.setState({showModal: val})
  }

  renderTooltipAsModal(field){
    return <span>
              &nbsp;
              <i onClick={()=>this.showModal(true)} className="fas fa-question-circle"/>
              { this.state.showModal &&
                  <Modal size='md'>
                    <div className="modal-header">
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true" onClick={()=>this.showModal(false)}>&times;</span>
                      </button>
                      <h4><strong>{this.props.i18n.labels.payments[field+'_modal_title']}</strong></h4>
                    </div>
                    <div className="modal-body" ref={el => (this.div = el)}>
                      <div className='instructions'>
                        <div dangerouslySetInnerHTML={{ __html: this.props.i18n.labels.payments[field+'_modal_info']}}/>
                        <div className='cancel-btn'>
                          <button className='btn secondary' onClick={()=>this.showModal(false)} >Cancel</button>
                        </div>
                      </div>
                    </div>
                  </Modal>
              }
          </span>
  }

  renderTooltip(field) {
    const { i18n } = this.props;
    const { tooltip: { button, visible } } = this.state;

    return <span className='tooltip-icon-wrapper'>
      <i className="fas fa-fw fa-info-circle"
        onMouseLeave={() => this.toggleTooltip(field+'_tooltip')}
        onMouseEnter={() => this.toggleTooltip(field+'_tooltip')}/>
      {
        (visible && button === field+'_tooltip') &&
        <Tooltip centerText={false} placement='top' message={i18n.labels.payments[field+'_tooltip']}/>
      }
    </span>
  }

  renderFormInput(param) { /* This method is responsible for render different forms. Modify this to create more */
    const { i18n, profile, paymentSettings } = this.props;
    switch(param) {
      case 'phone':
        return  <PhoneNumberInput
          extraClassNames={this.inputClass('phone')}
          onChange={e => this.onInputChange('phone', e)}
          value={this.props.paymentSettings.phone || ''}
        />

      case 'state':
        return <select
          className={this.inputClass('state')}
          onChange={e => this.onInputChange('state', e)}
          value={paymentSettings.state || ''}
          disabled={this.props.loading}
          >
          { this.props.states &&
            this.props.states.map(st =>
              <option value={st.abbreviation} key={st.abbreviation}>{st.name}</option>
            )
          }
        </select>

      case 'federal_tax_classification':
        return <select
          className={this.inputClass('federal_tax_classification')}
          value={paymentSettings.federal_tax_classification || ''}
          onChange={e => this.onInputChange('federal_tax_classification', e)}
          >
          <option value=''>
            Select a type
          </option>
          {
            paymentSettings.tax_classifications.map(tc =>
              <option key={tc} value={tc}>{tc}</option>
            )
          }
        </select>

      case 'bank_account_type':
        return <select
          className={this.inputClass('bank_account_type')}
          value={paymentSettings.bank_account_type || ''}
          onChange={e => this.onInputChange('bank_account_type', e)}
          >
          <option value=''>
            Select a type
          </option>
          <option value='Checking Account'>
            Checking Account
          </option>
          <option value='Savings Account'>
            Savings Account
          </option>
        </select>

      case 'bank_account_classification':
        return <select
          className={this.inputClass('bank_account_classification')}
          value={paymentSettings.bank_account_classification || ''}
          onChange={e => this.onInputChange('bank_account_classification', e)}
          >
          <option value=''>
            Select a classification
          </option>
          <option value='Personal (PPD)'>
            Personal (PPD)
          </option>
          <option value='Business (CCD)'>
            Business (CCD)
          </option>
        </select>

      default:
        return <input
          className={this.inputClass(param)}
          onChange={e => this.onInputChange(param, e)}
          disabled={this.isDisabled(param)}
          value={this.props.paymentSettings[param] || ''}
          placeholder={i18n.placeholder[param] || ''}
        />
    }
  }

  validateField(field, newValue=undefined) {
    const {
      paymentSettings,
      i18n,
      frontendValidations,
      activeTab
    } = this.props;
    const validations = frontendValidations[activeTab] || {};

    let value;
    if(_.isUndefined(newValue)) {
      value = paymentSettings[field];
    } else {
      value = newValue;
    }

    if(this.isRequired(field) && !value) {
      this.props.updateFrontendValidations({ [field]: i18n.validations.required });
      return;
    }

    if(field == 'tax_id_number') {
      // Matches XX-XXXXXXX or XXX-XX-XXXX
      if(!String(value).match(/^(\d{3}-\d{2}-\d{4})|(\d{2}-\d{7})$/)) {
        this.props.updateFrontendValidations({ [field]: i18n.validations.invalid_ssn });
        return;
      }
    }

    if(field == 'ach_bank_routing_number') {
      if(!String(value).match(/^\d{9}$/)) {
        this.props.updateFrontendValidations({ [field]: i18n.validations.invalid_routing_number });
        return;
      }
    }

    if(field == 'bank_account_number') {
      if(!String(value).match(/^\d+$/)) {
        this.props.updateFrontendValidations({ [field]: i18n.validations.invalid_bank_number });
        return;
      }
    }

    this.props.updateFrontendValidations({ [field]: undefined });
  }

  isDisabled(field) {
    const { profile } = this.props;

    switch(field) {
    case 'vendor_name':
      return !!profile.oracle_legal_name;
    case 'country':
      return true;
    default:
      return false;
    }
  }

  isRequired(field) {
    switch(field) {
    case 'address2':
      return false;
    default:
      return true;
    }
  }

  validations(field) {
    const { frontendValidations, activeTab } = this.props;
    const validations = frontendValidations[activeTab] || {};
    const validation = validations[field];

    if(!validation) {
      return null;
    }

    return <span className='help-text no-prefix'>
      {validation}
    </span>;
  }

  inputClass(field) {
    if(this.validations(field)) {
      return 'form-control invalid';
    }

    return 'form-control';
  }

  onInputChange(field, e) {
    this.validateField(field, e.target.value);
    this.props.updatePaymentSettings({ [field]: e.target.value });
  }

  validateAll() {
    const validate_fields = [
      'vendor_name',
      'phone',
      'email',
      'country',
      'state',
      'city',
      'address1',
      'address2',
      'zip',
      'tax_id_number',
      'name_1099',
      'federal_tax_classification',
      'ach_bank_routing_number',
      'bank_account_number',
      'bank_account_type',
      'bank_account_classification'
    ]
    if (!this.props.profile.agent_role)
      validate_fields.push('first_name', 'last_name',)
    validate_fields.forEach(field => {
      this.validateField(field);
    });
  }

  loadStates() {
    const {
      i18n,
      proxyUrl,
      countries,
      profile
    } = this.props;

    this.props.toggleLoading();
    const country = countries.find(x => x.abbreviation == profile.country) || countries[0];
    const params = {
      api_action: `/countries/${country.id}/states`
    };

    const _this = this;
    Remote().request({
      url: proxyUrl + '?' + QueryString(params),
      method: 'GET',
    }).then( (response) => {
      _this.props.setStates(response.data);
      _this.props.toggleLoading();
    }).catch( (e) => {
      _this.props.toggleLoading();
    });
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      i18n: state.meta.i18n,
      proxyUrl: state.meta.proxyUrl,
      paymentSettings: state.paymentSettings,
      profile: state.profile,
      frontendValidations: state.frontendValidations,
      activeTab: state.activeTab,
      states: state.states,
      countries: state.countries,
      loading: state.loading
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      updatePaymentSettings: change => dispatch({ type: 'UPDATE_PAYMENT_SETTINGS', value: change }),
      setStates: states => dispatch({ type: 'SET_STATES', value: states }),
      toggleLoading: () => dispatch({ type: 'TOGGLE_LOADING' }),
      updateFrontendValidations: change => dispatch({
        type: 'UPDATE_FRONTEND_VALIDATIONS',
        value: change
      })
    };
  }
};

ProfilesPaymentSettings.displayName = 'Profiles.PaymentSettings';
export default connect(
  ProfilesPaymentSettings.mapStateToProps,
  ProfilesPaymentSettings.mapDispatchToProps
)(ProfilesPaymentSettings);
