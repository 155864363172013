import React from 'react';
import { Remote } from '../utils';
import { connect } from 'react-redux';

import Field from './Field';
import PageTitleBar from '../PageTitleBar';
import ProgressBar from '../ProgressBar';
import Alert from '../Alert';
import FixedBar from '../FixedBar';
import SingleSelect from '../SingleSelect';
import MultiSelect from "../MultiSelect";
import {FieldOptions, Validations} from './validations'

import UserFormPlatformAccess from './PlatformAccess';
import UserFormSection from './Section';
import UserFormCheckbox from './Checkbox';
import UserFormSofabAccess from './SofabAccess';
import UserFormDashboardAccess from './DashboardAccess';

const paymentOptions = [{ name: 'Oracle', value: 'oracle' }, {name: 'Paypal', value: 'paypal'}];

class Container extends React.Component {
  constructor(props) {
    super(props);
    this.validations = Validations;
    this.fields = FieldOptions;
    this.filterCampaignAccessOptions = this.filterCampaignAccessOptions.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.toggleEmployeeStatus = this.toggleEmployeeStatus.bind(this);
    this.updateBlogTier = this.updateBlogTier.bind(this);
    this.validatedField = this.validatedField.bind(this);
    this.setDefaultCommunity = this.setDefaultCommunity.bind(this);
    this.nameFields = this.nameFields.bind(this);
    this.agentRoleSelected = this.agentRoleSelected.bind(this);
    this.roleOptions = this.roleOptions.bind(this);
    this.updateClearRoles = this.updateClearRoles.bind(this);
  }

  componentDidMount() {
    this.props.stopLoading()
    setTimeout(() => this.props.updateStore(this.props['init'] || {}), 100);
  }

  setDefaultCommunity() {
    const {updateField, communities, form} = this.props
    if (form.communities == undefined) {
      form.communities = communities;
    }
  }

  saveForm() {
    this.setDefaultCommunity();
    const { apiUrl, apiMethod, stopLoading, updateStore,
      startLoading, form, userRoleIds
    } = this.props;
    const {
      accessSocialFabric, accessDashboard, socialFabricRoles,
      clientAccess, campaignAccess, communityAccess, campaignSubaccess,
      firstName, lastName, email, employee, state, password, reviewNotes,
      legalName, communities, paymentType, w9, paypalEmail, paypalName, reviewed,
      blogPlatformsAttributes, agencyName
    } = form;

    let roles = []
    roles = roles.concat(userRoleIds)

    const changes ={
      user: {
        first_name:         firstName,
        last_name:          lastName,
        email:              email,
        employee:           employee,
        state:              state,
        password:           password,
        review_notes:       reviewNotes,
        oracle_legal_name:  legalName,
        role_ids:           roles,
        community_ids:      communities,
        reviewed:           reviewed,
        agency_name:        agencyName,
        blog_platforms_attributes: blogPlatformsAttributes, 
        profile_attributes: {
          payment_type:     paymentType,
          w9:               w9,
          paypal_email:     paypalEmail,
          paypal_name:      paypalName
        },
        dashboard_accessibility: {
          client_access: clientAccess,
          campaign_access: campaignAccess,
          campaign_subaccess: this.props.campaignSubaccess,
          community_access: communityAccess
        }
      }
    }

    Remote().request({
      url: apiUrl,
      method: apiMethod,
      data: changes,
      dataType: 'json',
    }).then(response => {
      if (response.data.errors){
        updateStore({ form_error: response.data.errors.join('<br/>'), loading: false })
        window.scrollTo(0, 0);
      }
      stopLoading();
      if (response.data.redirect_path){
        window.location.href = response.data.redirect_path
      }
    }).catch((response) => {
      updateStore({ form_error: response.data.statusText, loading: false })
      window.scrollTo(0, 0);
    });
    startLoading();
  }

  filterCampaignAccessOptions(){
    if(this.props.clientAccess == null || this.props.clientAccess == []){
      return this.props.campaignAccessOptions
    } else{
      return this.props.campaignAccessOptions.filter(item => !this.props.clientAccess.includes(item.client_id))
    }
  }

  validatedField(fieldName){
    const { updateField, form, currentUserRoles, passwordSet } = this.props
    const value = form[fieldName] || this.props[fieldName]

    let canEdit = currentUserRoles.includes('Admin') || currentUserRoles.includes('Payer')
    let password_placeholder = passwordSet ? 'Reset password...' : 'Set password...'

    return <Field value={value} error={form.errors[fieldName]}
      {...this.fields[fieldName]}
      disabled={fieldName === 'legalName' && value != '' && !canEdit}
      onUpdate={(newValue) => updateField(fieldName, newValue)}
      placeholder={fieldName === 'password' ? password_placeholder : null}
    />
  }

  toggleEmployeeStatus() {
    const { updateField, employee, form } = this.props
    const {accessSocialFabric, accessDashboard} = form;
    updateField('employee', !employee);
    updateField('socialFabricRoles', [])
  }

  updateBlogTier(selectedBlog, newTierId) {
    const { updateField } = this.props;
    let updatedBlogs = this.props.blogPlatformsAttributes;
    _.find(updatedBlogs, selectedBlog).tier_id = newTierId
    updateField('blogPlatformsAttributes', updatedBlogs);
  }

  agentRoleSelected(){
    const {form, socialFabricEmployeeRoleOptions, socialFabricNonEmployeeRoleOptions} = this.props
    const userRoleIds = form.userRoleIds || this.props.userRoleIds
    const selectableRoles = _.uniq([...socialFabricEmployeeRoleOptions, ...socialFabricNonEmployeeRoleOptions])
    return !!(userRoleIds &&
           selectableRoles.filter((role) => userRoleIds.includes(role.value) && role.name == 'Agent').length > 0)
  }

  nameFields(agentRoleSelected){
    if(agentRoleSelected){
      return(this.validatedField('agencyName'))
    }else{
      return(
        <React.Fragment>
          { this.validatedField('firstName') }
          { this.validatedField('lastName') }
        </React.Fragment>
      )
    }
  }

  updateClearRoles(field, value){
    this.props.updateField(field, value);
    this.props.updateField('socialFabricRoles', []);
  }

  roleOptions(){
    const {accessSocialFabric, accessDashboard, employee,
           socialFabricEmployeeRoleOptions, socialFabricNonEmployeeRoleOptions,
          dashboardEmployeeRoleOptions, dashboardNonEmployeeRoleOptions} = this.props
    let options = []

    if(accessSocialFabric){
      let sofabRoles = employee ? socialFabricEmployeeRoleOptions : socialFabricNonEmployeeRoleOptions;
      options = [...options, ...sofabRoles];
    }
    if(accessDashboard){
      let dashboardRoles = employee ? dashboardEmployeeRoleOptions : dashboardNonEmployeeRoleOptions
      options = [...options, ...dashboardRoles];
    }
    return options;
  }

  render() {
    const {
      loading, updateField, accessSocialFabric, accessDashboard, form,
      returnUrl, titlebar, form_error, employee, socialFabricEmployeeRoleOptions,
      socialFabricNonEmployeeRoleOptions, dashboardEmployeeRoleOptions, dashboardNonEmployeeRoleOptions,
      state, stateOptions, currentUserRoles, userRoles, userRoleIds
    } = this.props;
    const agentRoleSelected = this.agentRoleSelected()
    const disableRoleSelect = !(accessSocialFabric || accessDashboard)
    const roleOptions = this.roleOptions()
    let showPassword = currentUserRoles.includes('CB Employee') && !userRoles.includes('Admin');
    return (
      <div>
        <PageTitleBar {...titlebar} />
        {loading && <ProgressBar />}
        {form_error && <Alert type='error' message={form_error} />}
        <div className="container main-container">
          <div className="row mb-15">
            <UserFormPlatformAccess errors={form.errors}
              accessSocialFabric={accessSocialFabric}
              accessDashboard={accessDashboard}
              updateField={this.updateClearRoles}
            />
            <UserFormSection
              title="User Settings"
              fields={
                <div style={{ width: "100%" }}>
                  <div className='mb-15'>
                    <label>User Roles</label>
                    <MultiSelect
                      preload={employee ? socialFabricEmployeeRoleOptions : socialFabricNonEmployeeRoleOptions}
                      selected={userRoleIds}
                      search={(term, callback) => (
                        callback(roleOptions.filter(
                          item => item && item.name.toLowerCase().includes(term.toLowerCase())))
                      )}
                      onUpdate={(value) => updateField('userRoleIds', value)}
                      placeholder={'Select'}
                      clearInvalidInput={true}
                      hasError={!!form.errors.socialFabricRoles}
                      disabled={disableRoleSelect}
                    />
                  </div>
                  {this.nameFields(agentRoleSelected)}
                  { this.validatedField('email') }
                  <UserFormCheckbox label='This user is an Inmar Intelligence employee.'
                    value={employee} onUpdate={this.toggleEmployeeStatus} />
                  <div className="mb-15">
                    <label>State</label>
                    <SingleSelect
                      preload={stateOptions}
                      selected={state}
                      search={(term, callback) => (
                        callback(stateOptions.filter(
                          item => item && item.name.toLowerCase().includes(term.toLowerCase())
                        ))
                      )}
                      onUpdate={(value) => updateField('state', value)}
                      placeholder={'Select'}
                      clearInvalidInput={true}
                      hasError={!!form.errors.state}
                    />
                  </div>
                  { showPassword && this.validatedField('password') }
                </div>
              }
            />
            <UserFormSofabAccess {...this.props} errors={form.errors}
              validatedField={this.validatedField}
              socialFabricRoleOptions={employee ? socialFabricEmployeeRoleOptions : socialFabricNonEmployeeRoleOptions}
              paymentOptions={paymentOptions}
              updateBlogTier={this.updateBlogTier}
              agentRoleSelected={agentRoleSelected}
            />
            <UserFormDashboardAccess {...this.props} errors={form.errors}
              updateField={updateField}
              filterCampaignAccessOptions={this.filterCampaignAccessOptions()}
              dashboardRoleOptions={employee ? dashboardEmployeeRoleOptions : dashboardNonEmployeeRoleOptions}
            />
            <FixedBar style="left-right">
              <a href={returnUrl || "/admin/users"} className="btn btn-default">
                Cancel
              </a>
              <button className="btn btn-primary" disabled={!form.valid || loading}
               onClick={this.saveForm}>
                Save
              </button>
            </FixedBar>
          </div>
        </div>
      </div>
    );
  }

  // Sets a message if field and value does not match validation
  static validationCallback(field, value, validations, state) {
    const { accessSocialFabric, accessDashboard, paymentType, userText } = state;
    const { required_field, valid_email, one_platform, min_chars_8 } = userText;
    const emailRegex = new RegExp(/[^@]+@[^\.]+\..+/g);
    const presenceRegex = new RegExp(/\w/);
    const minChars = new RegExp(/^.{8,}/);

    let error = null;
    switch (field) {
      case 'accessSocialFabric':
        field = 'platformAccess'
        error = (accessDashboard || value ? null : one_platform)
        break;
      case 'accessDashboard':
        field = 'platformAccess'
        error = (accessSocialFabric || value ? null : one_platform)
        break;
      case 'email':
        error = (!!value && emailRegex.test(value) ? null : valid_email)
        break;
      case 'firstName':
      case 'lastName':
        error = (!!value && (presenceRegex).test(value) ?
                  null : required_field)
        break;
      case 'oracleLegalName':
        error = (accessSocialFabric && !!value && (presenceRegex).test(value) ?
          null : required_field)
        break;
      case 'password':
        if (value && !(minChars).test(value)) {error = min_chars_8}
        break;
      case 'paymentType':
      case 'state':
      case 'userRoleIds':
        error = ((!!value && value.length > 0) ? null : required_field) 
        break;
      case 'communities':
      case 'blogPlatformsAttributes':
        error = (accessSocialFabric && !!value && value.length > 0 ?
                  null : required_field)
        break;
      case 'paypalName':
        error = (accessSocialFabric && paymentType == 'paypal' && !!value && (presenceRegex).test(value) ?
                  null : required_field)
        break;
      case 'paypalEmail':
        error = (accessSocialFabric && paymentType == 'paypal' && !!value && emailRegex.test(value) ?
                  null : valid_email)
        break;
      default:
        break;
    }
    validations[field] = error
    // Return all validations that have a message present
    return _.reduce(
      _.filter(Object.keys(validations), (a) => {return !!validations[a] }),
      (memo, key) =>{ memo[key]=validations[key]; return memo }, {})
  }

  static mapStateToProps(state, myProps) {
    return {
      ...state,
      init: myProps,
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateStore: (data) =>
        dispatch({ type: "UPDATE", value: data }),
      startLoading: () =>
        dispatch({
          type: "UPDATE_FIELD",
          value: { field: "loading", data: true },
        }),
      stopLoading: () =>
        dispatch({
          type: "UPDATE_FIELD",
          value: { field: "loading", data: false },
        }),
      // Updates field with value and validates
      updateField: (field, data) =>
        dispatch({
          type: "UPDATE_FORM",
          value: { field: field, data: data, validate: Container.validationCallback }
        })
    };
  }
};

Container.displayName = "UserForm.Container";
export default connect(
  Container.mapStateToProps,
  Container.mapDispatchToProps
)(Container);
