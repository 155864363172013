import React from 'react';
import { connect } from 'react-redux'
import InstructionNotesForm from './Form'
import InstructionNotesFormActions from './FormActions'

class InstructionNotesEdit extends React.Component {
  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount() {
    const defaultValue = {
      label: this.props.instructionNotes.label,
      description: this.props.instructionNotes.description,
      community_id: this.props.instructionNotes.community_id,
    }

    this.props.setFormValues(defaultValue);
  }

  render() {
    const cardStyle = {'position': 'relative'};
    return (
        <div>
            <div className='full-width-page-title--wrapper'>
                <h1 className='full-width-page-title--heading text-center'>Edit Instruction Notes Template</h1>
            </div>
            <div className='container main-container' style={cardStyle}>
                <div className='card-wrapper--white-bg'>
                    <InstructionNotesForm {...this.props}/>
                    <InstructionNotesFormActions {...this.props}/>
                </div>
            </div>
        </div>
    )
  }

  static mapStateToProps(state, myProps) {
    return {
      ...state,
      ...myProps
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateField: (field, data) => dispatch({type: "UPDATE_FORM", value: { field: field, data: data }}),
      setFormValues: (attributes) => dispatch({type: "SET_FORM_VALUES", value: { attributes: attributes }}),
      startSubmitting: () => dispatch({ type: 'START_SUBMITTING'}),
      stopSubmitting: () => dispatch({ type: 'STOP_SUBMITTING'}),
    };
  }
}

InstructionNotesEdit.displayName = 'InstructionNotes.Edit';

export default connect(
  InstructionNotesEdit.mapStateToProps,
  InstructionNotesEdit.mapDispatchToProps
)(InstructionNotesEdit);
