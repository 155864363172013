import React from 'react';
import DatatableCell from './DatatableCell'

export default class DatatableRow extends React.Component {
  constructor(props) {
    super(props);

    this.getVisibleCells = this.getVisibleCells.bind(this);
    this.render = this.render.bind(this);
    this.renderCell = this.renderCell.bind(this);
  }

  render() {
    return (
      <tr>
        {this.getVisibleCells().map((cell) => this.renderCell(cell))}
      </tr>
    );
  }

  renderCell(cell) {
    return <DatatableCell key={cell.index} value={cell.value} column={cell.column}/>
  }

  getVisibleCells() {
    return this.props.row
      .map((value, idx) => ({
        index: idx,
        value: value,
        column: this.props.columns.find((col) => col.index == idx)
      }))
      .filter((group) => !!group.column)
  }
}
