`import notif from 'javascripts/notifIt.js'`

class window.AdminPayments
  ### (1) Convert from Routes (gem js-routes 1.4.1) ###
  @index: ->

    load_current_payments = ->
      DataTableUtils.datatable $('.datatable.current_payments'),
        aoColumnDefs: [
          { bVisible:    false, aTargets: [  0 ] }
          { bSortable:   false, aTargets: [  1 ] } # Disable checkbox sorting
          { bSearchable: false, aTargets: [  2 ] }
          { bSearchable: false, aTargets: [ 11 ] }
        ]
        aLengthMenu: [
          [10, 25, 50, 100, 200, -1],
          [10, 25, 50, 100, 200, 'All']
        ]
        iDisplayLength: -1
        sDom: '<"dt-length col-xs-2 col-sm-3"l><"dt-filter col-xs-10 col-sm-5"f>rt<"dt-info col-xs-12 col-sm-4"i><"dt-pagination col-xs-12 col-sm-8"p>'
        fnDrawCallback: ( oSettings )   -> set_checkbox_state(oSettings)
        fnRowCallback:  ( nRow, aData ) -> set_reverted_style(nRow, aData)
        fnRowCallback:  ( nRow, aData ) -> set_row_styles(nRow, aData)

    load_pending_payments = ->
      DataTableUtils.datatable $('.datatable.pending_payments'),
        aaSorting: [[3,'asc']]
        aoColumnDefs: [
          { bVisible:  false, aTargets: [ 0 ] }
          { bSortable: false, aTargets: [ 1 ] } # Disable checkbox sorting
        ]
        aLengthMenu: [
          [10, 25, 50, 100, 200, -1],
          [10, 25, 50, 100, 200, 'All']
        ]
        iDisplayLength: -1
        sDom: '<"dt-length col-xs-2 col-sm-3"l><"dt-filter col-xs-10 col-sm-5"f>rt<"dt-info col-xs-12 col-sm-4"i><"dt-pagination col-xs-12 col-sm-8"p>'
        fnDrawCallback: ( oSettings )   -> set_checkbox_state(oSettings)
        fnRowCallback:  ( nRow, aData ) -> set_row_styles(nRow, aData)

    load_all_payments = ->
      DataTableUtils.datatable $('.datatable.all_payments'),
        bServerSide: true
        aoColumnDefs: [
          { bVisible: false, aTargets: [ 0 ] }
        ]
        sDom: '<"dt-length col-xs-2 col-sm-3"l><"dt-filter col-xs-10 col-sm-5"f>rt<"dt-info col-xs-12 col-sm-4"i><"dt-pagination col-xs-12 col-sm-8"p>'
        fnRowCallback: ( nRow, aData ) -> set_row_styles(nRow, aData)

    set_row_styles = (nRow, aData) ->
      if aData.includes('Reverted')
        nRow.className += ' reverted'
      else if aData.includes('Inactive')
        nRow.className += ' inactive'

    set_checkbox_state = (oSettings) ->
      checked    = $(':checkbox:checked:not(".checkbox_all")')
      checkboxes = $(':checkbox:not(".checkbox_all")')

      $('.checkbox_all').prop('checked', false)
      if checkboxes.length > 0 && checked.length == checkboxes.length
        $('.checkbox_all').prop('checked', true)

    pTable =
      if      $('.datatable.current_payments').length then load_current_payments()
      else if $('.datatable.pending_payments').length then load_pending_payments()
      else if $('.datatable.all_payments').length     then load_all_payments()

    $('body').on 'click', 'table.datatable tbody tr ', (e) ->
      unless e.target.type is 'checkbox'
        aData = pTable.row( this ).data()
        if aData == undefined
          return
        if aData[aData.length-1]
          window.open( '/admin/payments/' + aData[0] + '/edit' )  # See (1)
        else
          window.open( '/admin/payments/' + aData[0] )  # See (1)

    $('body').on 'click', '.checkbox_all', ->
      active_checkboxes = if this.checked then 'tbody tr:not(.inactive) :checkbox' else 'tbody :checkbox'
      $(this).closest('table').find(active_checkboxes).prop('checked', this.checked);

    $('body').on 'click', '.export_payments', (e) ->
      msg = 'Payments are being exported. Please check your email.'
      AdminPayments.call_to_rails( e, $(this), null, false, msg )

    $('body').on 'click', '.mark_paid', (e) ->
      AdminPayments.call_to_rails( e, $(this) )

    $('body').on 'click', '.approve_payments', (e) ->
      AdminPayments.call_to_rails( e, $(this) )

    $('body').on 'click', '.not_approve_payments', (e) ->
      if reason = prompt('Are you sure? \n' +
                         'Please provide a reason for not approving payment(s). \n' +
                         'This reason will be saved as a private note on the payment.')
        AdminPayments.call_to_rails( e, $(this), reason )

  @call_to_rails: ( e, button, reason = null, reload = true, msg  ) ->
    e.preventDefault()

    @spinner( button ) if reload

    $.ajax
      url: button.data('url')
      data: { pid: AdminPayments.get_checkboxes(), msg: reason }
      dataType: 'JSON'
      method: "POST"
      success: (data) ->
        if reload
          location.reload()
        else
          notif(
            msg: msg
            type: 'notice'
            timeout: 3000
          )

  @spinner: ( button ) ->
    icon = $('<i class="fas fa-spinner fa-spin"></i>')
    button.addClass('disabled')
    button.html(icon)

  @get_checkboxes: ->
    $('td input:checked').map ->
      parseInt $(this).data('id'), 10
    .get()

  @new: ->
    @form()
    NoteUtils.noteable()

  @show: ->
    NoteUtils.noteable()
    AuditUtils.auditable()

  @create: ->
    @new()

  @edit: ->
    @form()
    NoteUtils.noteable()
    AuditUtils.auditable()

  @update: ->
    @form()

  @form: ->
    @form_utils()
    
    $('#payment_shop_id').prop('disabled', true)

    if $("#campaign_selector").val() != ""
      @set_shoppertunity_select()

    $('body').on 'change', '#campaign_selector', ->
      AdminPayments.populate_shoppertunity_select( $(this).val() )

    $('form').on 'submit', ->
      $('#payment_shoppertunity_id').val( $('#payment_shop_id').val() )

  @set_shoppertunity_select: ->
    campaign_id = $("#campaign_selector").val()
    
    $.ajax
      url: '/admin/campaigns/' + `campaign_id` + '/opportunities.json'  # See (1)
      success: (shops) ->
        select = $('#payment_shop_id')
        select.empty().append('<option></option>')

        $.each shops, (ind, shop) ->
          select.append $('<option></option>').attr('value', shop.id).text(shop.name)
        select.prop('disabled', false)

        if ( $('#payment_shoppertunity_id').val() != "" )
          $('#payment_shop_id').select2('val',  $('#payment_shoppertunity_id').val() )
      error: () ->
        select.prop('disabled', true)

  @populate_shoppertunity_select: ( campaign_id ) ->
    select = $('#payment_shop_id')
    select.select2 'val', 0

    $.ajax
      url: '/admin/campaigns/' + `campaign_id` + '/opportunities.json'  # See (1)
      success: (shops) ->
        select.empty().append('<option></option>')

        $.each shops, (ind, shop) ->
          select.append $('<option></option>').attr('value', shop.id).text(shop.name)
        select.prop('disabled', false)
      error: () ->
        select.prop('disabled', true)

  @form_utils: ->
    new ValidateCurrency.CurrencyField( '#submit-btn', ['#payment_amount'] ).bind_values()

    FormUtils.select_init '#payment_receiver_id',
      multiple: true
      ajax:
        url: '/search.json?c=users' # See (1)
        type: 'GET'
        dataType: 'json'
        data: (query, page) ->
          q: query
        results: (data, page) ->
          users = []
          $.each data.results, (i, user) ->
            users.push({id: user.id, text: (user.full_name || user.agency_name)})
          results: users
