import React from 'react';

export default class UserSignUpNavigation extends React.Component {
  constructor(props){
    super(props);

    this.render = this.render.bind(this);
    this.getPercent = this.getPercent.bind(this);
    this.renderNavItem = this.renderNavItem.bind(this);
    this.renderMobileNav = this.renderMobileNav.bind(this);
    this.canJumpTo = this.canJumpTo.bind(this);
    this.goTo = this.goTo.bind(this);
    this.renderTabs = this.renderTabs.bind(this);
    this.getStepState = this.getStepState.bind(this);
    this.getBarState = this.getBarState.bind(this)
  }

  getPercent(){
    const { stepMap, latestStep } = this.props;
    return ( stepMap.indexOf(latestStep) + 1 ) * ( 100 / stepMap.length );
  }

  getBarState(){
    const { disabled, stepMap, step, successfulSubmit } = this.props;
    const currentIndex = stepMap.indexOf(step);
    const success = successfulSubmit !== null ? successfulSubmit.toString() : '';

    if ( disabled && !success)
      return 'disabled';
    else if ( currentIndex === stepMap.length - 1 || success === 'true'){
      let className = 'indeterminate';
      if ( !!success )
        className += ` success-${success}`;
      return className;
    } else
      return 'determinate';
  }

  getStepState(targetStep){
    const { stepMap, step } = this.props;
    const currentIndex = stepMap.indexOf(step);
    const stepIndex = stepMap.indexOf(targetStep);

    if ( currentIndex === stepIndex )
      return 'active'
    else if ( currentIndex > stepIndex )
      return 'complete'
    else
      return 'disabled'
  }

  goTo(stepName){
    if (this.canJumpTo(stepName))
      this.props.jumpToStep(stepName)
  }

  canJumpTo(stepName){
    const { stepMap, latestStep } = this.props;
    const currentIndex = stepMap.indexOf(latestStep);
    const targetIndex = stepMap.indexOf(stepName);

    return currentIndex >= targetIndex
  }

  renderNavItem(stepName, displayText){
    return (
      <div key={stepName} className={`nav-item ${this.getStepState(stepName)}`}
            onClick={() => this.goTo(stepName)}>
        <label>{displayText}</label>
      </div>
    )
  }

  renderMobileNav(){
    const { stepMap, step, disabled } = this.props;
    const currentIndex = stepMap.indexOf(step);
    const prevStep = stepMap[currentIndex - 1]
    const nextStep = stepMap[currentIndex + 1]

    return (
      <div className='nav-info-mobile'>
        <div className='column'>
          <div className={`nav-item ${this.getStepState(prevStep)}`} onClick={() => this.goTo(prevStep)} >
            {(!disabled && prevStep) && <i className="fas fa-chevron-left"/>}
          </div>
        </div>
        <div className='column'>
          {`${currentIndex + 1} / ${stepMap.length}`}
        </div>
        <div className='column'>
          <div className={`nav-item ${this.getStepState(nextStep)}`} onClick={() => this.goTo(nextStep)} >
            {(!disabled && nextStep) && <i className="fas fa-chevron-right"/>}
          </div>
        </div>
      </div>
    )
  }

  renderTabs(){
    const { tabs } = this.props
    return (
      Object.keys(tabs).map((tabName) => {
        return this.renderNavItem(tabName, tabs[tabName])
      })
    )
  }

  render(){
    return (
      <div className='sign-up-nav'>
        { this.renderMobileNav() }
        <div className='nav-info'>
          { this.renderTabs() }
        </div>
        <div className="progress">
          <div className={`bar ${this.getBarState()}`} style={{width: `${this.getPercent()}%`}}/>
        </div>
      </div>
    )
  }
}