import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash'
import SubmissionStateIndicator from './StateIndicator'

class SubmissionInstagramContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: false
    }

    this.video = React.createRef();

    this.validationState = this.validationState.bind(this);
    this.renderVideoPlayer = this.renderVideoPlayer.bind(this);
    this.setPlayingState = this.setPlayingState.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
    this.render = this.render.bind(this);
  }

  render() {
    const { post, selected, key, validations } = this.props;
    const isVideo = post.media_type === 'VIDEO'
    const isSelected = selected.some(content => content.graph_id === post.graph_id)
    const state = this.validationState();

    return (
      <div key={key}
           className={`submission grid-item instagram ${state} ${isSelected ? 'selected' : ''}`}
           onClick={(e) => this.clickHandler(e, isSelected)}>
        <div className='item-image' style={{backgroundImage: `url(${post.image_url})`}}>
          <SubmissionStateIndicator state={state} isSelected={isSelected} isVideo={isVideo} setPlayingState={this.setPlayingState}/>
        </div>
        { isVideo && this.renderVideoPlayer() }
      </div>
    )
  }


  validationState() {
    const { playing } = this.state;
    const { validations } = this.props;

    if ( validations && validations.length ) {
      if ( _.some(validations, {state: 'failed'}) ) {
        return 'failed';
      } else {
        return 'passed';
      }
    } else {
      if (playing) {
        return 'playing';
      } else {
        return 'pending';
      }
    }
  }

  renderVideoPlayer() {
    return (
      <video ref={this.video}
        className='video'
        onPause={(e) => this.setPlayingState(e, false)}
        src={this.props.post.video_url}
        preload='none'
        controls></video>
    )
  }

  setPlayingState(e, state) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      playing: state
    }, () => {
      if (state) {
        this.video.current.play();
      } else {
        this.video.current.pause();
      }
    });
  }

  clickHandler(e, isSelected) {
    e.preventDefault();
    const { post } = this.props;

    if ( isSelected ) {
      this.props.deselect(post);
      this.props.resetSubmission();
      this.props.resetValidations();
    } else {
      this.props.select(post);
      this.props.updateSubmission({ url: post.post_url });
      this.props.resetValidations();
      this.props.validate();
    }
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      selected: state.selected,
      validations: state.validations
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      select: post => dispatch({ type: 'SELECT_SINGLE_CONTENT', value: post }),
      deselect: post => dispatch({ type: 'DESELECT_CONTENT', value: post }),
      resetSubmission: () => dispatch({ type: 'RESET_SUBMISSION' }),
      updateSubmission: params => dispatch({ type: 'UPDATE_SUBMISSION', value: params }),
      resetValidations: () => dispatch({ type: 'RESET_VALIDATIONS' }),
    };
  }
};

SubmissionInstagramContent.displayName = 'Submission.InstagramContent';
export default connect(
  SubmissionInstagramContent.mapStateToProps,
  SubmissionInstagramContent.mapDispatchToProps
)(SubmissionInstagramContent);
