import React from 'react';
import Tooltip from '../Tooltip'

export default class InstructionsUpdateButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showTooltip: false}

    this.render = this.render.bind(this);
  }

  toggleTooltip() {
    this.setState({ showTooltip: !this.state.showTooltip })
  }

  render() {
    const {update_url, download_instructions_url} =this.props;
    return (<div>
      <a href={update_url}
         className='btn btn-primary'
         onMouseLeave={() => this.toggleTooltip()}
         onMouseEnter={() => this.toggleTooltip()}>
         Update Instructions
         {
           this.state.showTooltip &&
             <Tooltip placement='top'
                          message={this.props.update_tooltip}/>
         }
      </a>
      <a target='_blank' href={download_instructions_url} className='btn btn-outline'>
        <i className='fas fa-download' />Download Instructions
      </a>
    </div>);
  }

}

InstructionsUpdateButton.displayName = 'Instructions.UpdateButton';
