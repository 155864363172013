import React from 'react';
export default class MultiSelectChip extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.render = this.render.bind(this);
  }

  handleClick(e) {
    if(!this.props.disabled){
      this.props.itemClicked()
    }
    e.stopPropagation()
  }

  render() {
    const {disabled} = this.props;
    return (
      <span key={this.props.name} onClick={(e) => this.handleClick(e)} disabled={disabled}>
        <i className='fas fa-times' /> {this.props.name}
      </span>
    );
  }
}
