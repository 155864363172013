import React from 'react';
import moment from 'moment';

// Abstraction around localstorage to store the number of auth failures
// and handle the expiration of items in the cache.
export default class FailureCache {
  constructor(authType) {
    this._authType = authType;
  }

  get() {
    const key = this._key();

    if(this._isExpired()) {
      window.localStorage.removeItem(key);
      window.localStorage.removeItem(this._timestampKey());
    }

    return Number(window.localStorage.getItem(key)) || 0;
  }

  addFailure() {
    const newValue = this.get() + 1;
    window.localStorage.setItem(this._key(), newValue);
    window.localStorage.setItem(this._timestampKey(), new Date());
  }

  // Private methods

  _key() {
    switch(this._authType) {
    case 'InstagramBusinessStoryContent':
    case 'InstagramContent':
    case 'InstagramReelContent':
      return 'submission_failures.instagram';
    case 'GoogleYoutubeContent':
      return 'submission_failures.youtube';
    default:
      return 'submission_failures.other';
    }
  }

  _timestampKey() {
    return `${this._key()}.timestamp`;
  }

  _isExpired() {
    const timeString = window.localStorage.getItem(this._timestampKey());
    const cacheTime = moment(new Date(timeString));
    const now = moment(new Date());

    return moment.duration(now.diff(cacheTime)).asMinutes() > 10;
  }
};
