class window.AdminContents
  @show: ->
    NoteUtils.noteable()
    $('select').on 'change', ->
      calculate_grade()

    $('body').on 'click', '#grade_content_btn', (e) ->
      if !$('#content_accepted').prop('disabled') && $('#content_accepted').prop('checked')
        confirm("Are you sure? \n\nThis will mark the content as accepted and cannot be reversed.")
      else
        true

    $("input[name='content[state]']").change ->
      if $(this).val() == 'rejected'
        $('#rejection_fields').removeClass('is-hidden')
      else
        $('#rejection_fields').addClass('is-hidden')
        $('#rejections_rejection_reason_id').select2('val', '')
        $('#rejections_comment').val('')

    Contents.draft_editor('#edit_content')
    new SidebarUtils('.main-container', '#draft-annotations-history')

    if $(".instagram-caption textarea").length != 0
      $(".instagram-caption textarea").height( $("textarea")[0].scrollHeight );

    @form_validation()

    calculate_grade = ->
      total_grade = 0
      ars = $("select[name^='acceptance_rules']")
      ars.each ->
        total_grade += Number($(this).val())

      length = Math.max(ars.length, 1)
      total = Math.max(total_grade, 1)
      $('#content-grade').html( Number(total / length).toFixed(1) )

    calculate_grade()

  @form_validation: ->
    $('#edit_content').validate
      rules:
        'content[state]':
          required: true

      messages:
        'content[state]':
          'Please accept or reject content'
        'rejections[rejection_reason_id]':
          'Please select a rejection reason'
        'rejections[comment]':
          'Please enter a rejection comment'

      errorPlacement: (error, element) ->
        if element.attr('name') == 'content[state]'
          error.insertBefore '#accept_or_reject'
          error.addClass 'h3 mt-0 col-xs-12 text-center'
        else
          error.insertAfter element

    $('#edit_content select').on 'select2-selected', ->
      $(this).valid()