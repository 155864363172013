import React from 'react';

export default class EventBubble extends React.Component {
  render() {
    const { owner, body, sent_at } = this.props
    const displayName = owner.brand_name ? `${owner.full_name} at ${owner.brand_name}` : owner.full_name;

    return (
      <div className="row user-event--row" >
        <div className="user-event--container">
          <div className="user-event">
            <div className='user-event--detail'>
              <strong>{displayName}</strong>
            </div>
            <div className='user-event--detail'>
              {body}
            </div>
            <div className='user-event--detail'>
              {sent_at}
            </div>
          </div>
        </div>
      </div>
    )
  }
}