import React from 'react';
import { Remote } from './utils';

class InstagramBusinessStorySelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: this.props.content,
      task: this.props.task,
      page: 0,
      stories: this.props.stories,
      selectedCards: [],
      taskPath: this.props.taskPath,
      errorMessage: '',
      submitting: false,
      hideManualSubmission: this.props.uploadedStories.length == 0,
      hideManualSubmissionIcon: 'fa-caret-down',
      uploadedFiles: this.props.uploadedStories || []
    };

    this.cardSelected = this.cardSelected.bind(this);
    this.decrementPage = this.decrementPage.bind(this);
    this.disableSubmit = this.disableSubmit.bind(this);
    this.doesNotMeetMiniumNumberOfStories = this.doesNotMeetMiniumNumberOfStories.bind(this);
    this.incrementPage = this.incrementPage.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.maxPage = this.maxPage.bind(this);
    this.padCards = this.padCards.bind(this);
    this.parseSelectedPosts = this.parseSelectedPosts.bind(this);
    this.render = this.render.bind(this);
    this.renderCards = this.renderCards.bind(this);
    this.renderCarrotNav = this.renderCarrotNav.bind(this);
    this.renderMessage = this.renderMessage.bind(this);
    this.renderNoCardsMessage = this.renderNoCardsMessage.bind(this);
    this.storyMinimum = this.storyMinimum.bind(this);
    this.submitButtonText = this.submitButtonText.bind(this);
    this.submitStories = this.submitStories.bind(this);
    this.toggleManualSubmission = this.toggleManualSubmission.bind(this);
    this.updateUploadedFiles = this.updateUploadedFiles.bind(this);
    this.visibleCards = this.visibleCards.bind(this);
  }

  render() {
    const errorStyle = {
      color: '#FF8888'
    };

    const minStoriesError = {
      paddingTop: '10px'
    }

    return (
      <div>
        {this.renderMessage(this.state.errorMessage, errorStyle, 'exclamation-triangle')}
        {this.renderNoCardsMessage()}
        {this.renderCarrotNav()}
        <UploadStoryMedia instagramConnected={this.props.instagramConnected}
                          hideManualSubmission={this.state.hideManualSubmission}
                          hideManualSubmissionIcon={this.state.hideManualSubmissionIcon}
                          toggleManualSubmission={this.toggleManualSubmission}
                          stories={this.state.stories}
                          content={this.props.content}
                          task={this.props.task}
                          uploadedFiles={this.state.uploadedFiles}
                          updateUploadedFiles={this.updateUploadedFiles}
                          adPopupText={this.props.adPopupText}
                          shopInstructions={this.props.shopInstructions}
        />
        <div className="text-center">
          <a className="btn btn-primary" disabled={this.disableSubmit()} onClick={() => this.submitStories()}>{this.submitButtonText()}</a>
          <a className="btn btn-default" href={this.state.taskPath}>Cancel</a>
        </div>
        {
          this.doesNotMeetMiniumNumberOfStories() && <div style={minStoriesError} className="text-center">
            <span style={errorStyle}>
              <i className="fas fa-exclamation-triangle"></i> You must select at least {this.storyMinimum()} unique {this.storyMinimum() > 1 ? 'stories' : 'story'} to submit this task
            </span>
          </div>
        }
      </div>
    );
  }

  doesNotMeetMiniumNumberOfStories(){
    return ((this.state.uploadedFiles.length + this.state.selectedCards.length) < this.storyMinimum());
  }

  storyMinimum() {
    if(this.props.task.waived_requirements) {
      return 1;
    }

    return InstagramBusinessStorySelector.MINIMUM_POSTS;
  }

  disableSubmit(){
    if(this.state.submitting){
      return true;
    }
    else if(this.doesNotMeetMiniumNumberOfStories())
    {
      return true;
    }
    return false;
  }

  updateUploadedFiles(files){
    this.setState({uploadedFiles: files});
  }

  toggleManualSubmission() {
    this.setState({
      hideManualSubmission: !this.state.hideManualSubmission,
      hideManualSubmissionIcon: this.state.hideManualSubmission ? 'fa-caret-up' : 'fa-caret-down',
    });
  }

  submitButtonText(){
    if(this.state.submitting){
      return "Submitting..."
    }
    else{
      return this.state.content['failing?'] ? "Re-Submit" : "Submit"
    }
  }

  renderCarrotNav(){
    if(this.state.stories.length > 0){
      return (
        <div>
          <h3>Select Your Stories</h3>
          <div className="ig-content-selector">
            <div className="arrow" onClick={() => this.decrementPage()}>
              <i className="fas fa-chevron-left" />
            </div>
            {this.renderCards()}
            <div className="arrow" onClick={() => this.incrementPage()}>
              <i className="fas fa-chevron-right" />
            </div>
          </div>
        </div>
      );
    }
  }

  renderCards(){
    const cards = this.visibleCards().map((story) => {
      const listener = this.cardSelected(story.graph_id)
      return (
        <InstagramBusinessStoryCard
          key={story.graph_id}
          story={story}
          isSelected={this.isSelected(story.graph_id)}
          selectCard={listener}
        />
      );
    });
    this.padCards(cards);
    return cards;
  }

  renderNoCardsMessage(){
    if(this.state.stories.length === 0){
      return (
        <div className="text-center">
          You do not have any active stories. Please post your story on Instagram and come back to submit.
        </div>
      );
    }
  }

  submitStories(){
    if(this.state.selectedCards.length == 0 && this.state.uploadedFiles.length == 0){
      this.setState({errorMessage:'You must choose at least 1 story to submit'})
    }
    else
    {
      this.setState({errorMessage:'', submitting: true})

      const requestUrl = this.state.content['failing?'] ? `/contents/update_instagram_business_story_content` : `/contents/create_instagram_business_story_content`

      Remote().request({
        method: "POST",
        url: requestUrl,
        data: { content: this.state.content, task: this.state.task, stories: this.parseSelectedPosts()},
        //dataType: 'json'
      }).then((response) => {
        if(response.data) {
          window.location.href = `${response.data['redirect_url']}?notice_submit_success=true`;
        }
      }).catch((error) => {
        this.setState({submitting: false, errorMessage: 'Failed to submit your content. Please submit your request again.'})
      });
    }
  }

  parseSelectedPosts(){
    return this.state.stories.filter(story => this.state.selectedCards.includes(story['graph_id'])).concat(this.state.uploadedFiles);
  }

  isSelected(graph_id){
    return this.state.selectedCards.some((x) => x === graph_id);
  }

  cardSelected(graph_id){
    return () => {
      if(this.isSelected(graph_id)){
        this.setState({selectedCards:this.state.selectedCards.filter((x) => x !== graph_id)});
      }else{
        this.setState({selectedCards:this.state.selectedCards.concat(graph_id)});
      }
    }
  }

  visibleCards() {
    const page = this.state.page;
    const perPage = InstagramBusinessStorySelector.POSTS_PER_PAGE;
    return this.state.stories.slice(page * perPage, (page + 1) * perPage);
  }

  incrementPage() {
    const perPage = InstagramBusinessStorySelector.POSTS_PER_PAGE;
    const page = this.state.page + 1;
    if (page * perPage >= this.state.stories.length) {
      this.setState({ page: 0 });
    } else {
      this.setState({ page: page });
    }
  }

  decrementPage() {
    const page = this.state.page - 1;
    if (page < 0) {
      this.setState({ page: this.maxPage() });
    } else {
      this.setState({ page: page });
    }
  }

  maxPage() {
    if(this.state.stories.length <= InstagramBusinessStorySelector.POSTS_PER_PAGE){
      return this.state.page;
    }
    const maxPageEstimate = this.state.stories.length / InstagramBusinessStorySelector.POSTS_PER_PAGE;
    return Math.floor(maxPageEstimate);
  }

  padCards(cards) {
    const perPage = InstagramBusinessStorySelector.POSTS_PER_PAGE;
    const numMissing = perPage - cards.length;

    if (cards.length < perPage) {
      for (let i = 0; i < numMissing; i++) {
        cards.push(<div className="padding-card" />);
      }
    }
  }

  renderMessage(message, style, icon) {
    const iconClass = `fas fa-${icon}`;
    if(message) {
      return (
        <span style={style}>
          <i className={iconClass}></i> {message}
        </span>
      );
    }
  }
}

InstagramBusinessStorySelector.POSTS_PER_PAGE = 4;
InstagramBusinessStorySelector.MINIMUM_POSTS = 4;
