import React from 'react';

export default class ExternalInvitation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      platform_type: 'instagram',
      payment_method: Object.keys(this.props.paymentMethods)[0]
    }

    this.render = this.render.bind(this);
    this.valueChange = this.valueChange.bind(this);
    this.renderMessage = this.renderMessage.bind(this);
  }

  valueChange(e) {
    this.setState({[e.target.name]: e.target.value})
  }

  renderMessage() {
    switch(this.state.platform_type) {
      case 'none':
        return this.props.vipMessage
      case 'instagram':
        return this.props.message.instagram
      case 'youtube':
        return this.props.message.youtube
    }
  }

  render() {
    let paymentMethods = this.props.paymentMethods
    return (
      <form onSubmit={(e) => this.props.submit(e)} id='external_form' className='col' method='POST'>
        <div className='form-group required'>
          <label className='control-label'><abbr title="required">*</abbr> Round</label>
          <select className='form-control' name='round' required>
            <option value=''>Select Round</option>
            {this.props.rounds.map((round) => { return (<option key={round.id} value={round.id}>{round.name}</option>) })}
          </select>
        </div>
        <div className='form-group required'>
          <label className='control-label'><abbr title="required">*</abbr> Authentication Platform</label>
          <select className='form-control' name='platform_type' onChange={this.valueChange} required>
            <option value='instagram'>Instagram</option>
            <option value='youtube'>YouTube</option>
            <option value='tiktok'>TikTok</option>
            <option value='none'>None</option>
          </select>
        </div>
        <div className='row'>
          <div className='form-group required col-xs-6' style={{'display': 'inline-block' }}>
            <label className='control-label'><abbr title="required">*</abbr> First Name</label>
            <input className='form-control' type='text' name='first_name' required/>
          </div>
          <div className='form-group required col-xs-6' style={{'display': 'inline-block'}}>
            <label className='control-label'><abbr title="required">*</abbr> Last Name</label>
            <input className='form-control' type='text' name='last_name' required/>
          </div>
        </div>
        <div className='row'>
          <div className='form-group required col-xs-6' style={{'display': 'inline-block' }}>
            <label className='control-label'><abbr title="required">*</abbr> Email</label>
            <input onKeyUp={(e) => this.props.checkForDuplicateEmail(e.target.value)} className='form-control' type='email' name='email' required/>
          </div>
          <div className='form-group required col-xs-6' style={{'display': 'inline-block'}}>
            <label className='control-label'><abbr title="required">*</abbr> Payment</label>
            <div className='input-group'>
              <div className='input-group-addon'>$</div>
              <input className='form-control currency' type='number' name='payment' min='0' step='1.00' required/>
              <div className='input-group-addon'>.00</div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='form-group required col-xs-6' style={{'display': 'inline-block' }}>
            <label className='control-label'><abbr title="required">*</abbr> Payment Method</label>
            <select className='form-control' name='payment_method' value={this.state.payment_method} onChange={(e) => this.valueChange(e)} required>
              {Object.keys(paymentMethods).map((pm) => {
                return (<option key={paymentMethods[pm]} value={paymentMethods[pm]}>{pm}</option>)
              })}
            </select>
          </div>
          { this.state.payment_method == 'paypal' &&
            <div className='form-group col-xs-6' style={{'display': 'inline-block'}}>
              <label className='control-label'>Paypal ID</label>
              <input className='form-control' name='paypal_id'/>
            </div>
          }
        </div>
        <div className='form-group'>
          <label className='control-label'>Message</label>
          <textarea className='form-control'
                    name='message'
                    rows='4'
                    key={this.state.platform_type}
                    defaultValue={this.renderMessage()} />
        </div>
      </form>
    )
  }
}