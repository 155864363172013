import React from 'react';
import { Remote } from '../utils';

import CopyForm from './CopyForm'
import LoadingSpinner from './LoadingSpinner';
import FormValidations from './FormValidations'

export default class CopyEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      requests: [],
      adTypeIndex: 0,
      adTypeFormats: this.props.wizardState.ad_type_formats,
      validations: new FormValidations({
        words: true,
        textLength: false,
        characters: true
      })
    };

    this.doBind()
  }

  componentWillUnmount() {
    this.state.requests.forEach((xhr) => xhr.abort());
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.wizardState.id == this.props.wizardState.id) {
      return;
    }

    this.setState({
      headline: nextProps.wizardState.headline,
      text: nextProps.wizardState.text,
      description: nextProps.wizardState.description,
    });
  }

  render() {
    if(this.state.loading) {
      return <LoadingSpinner loading={true} />;
    }

    const adData = this.state.adTypeFormats[this.state.adTypeIndex]

    return (
      <CopyForm
        wizardState={ this.props.wizardState }
        saveCallback={(fields) => this.saveCopy(fields) }
        saveText="Save"
        submitCallback={(fields) => this.finishCopy(fields) }
        submitText="Continue"
        validations={ this.state.validations }
        adFormat={ adData.format }
        adData={ adData }
        showUtm={ false }
        toggleAdType={ this.toggleAdType }
        previewEndpoint={this.props.previewEndpoint}
      />
    );
  }

  toggleAdType() {
    const maxIndex = this.state.adTypeFormats.length;
    let newIndex = this.state.adTypeIndex + 1;
    if ( newIndex === maxIndex )
    newIndex = 0
    this.setState({adTypeIndex: newIndex});
  }

  saveCopy(fields) {
    const component = this;
    this.setState({ loading: true });

    const xhr = Remote().request({
      url: this.props.updateEndpoint,
      method: 'PATCH',
      data: fields,
    }).then((response) => {
      component.setState({
        loading: false,
        adTypeFormats: response.data.ad_type_formats
      });
    });

    this.addRequest(xhr);
    return xhr;
  }

  finishCopy(fields) {
    const maxIndex = this.state.adTypeFormats.length;
    let newIndex = this.state.adTypeIndex + 1;
    if ( newIndex === maxIndex )
      this.proceedToReview(fields)
    else {
      this.saveCopy(fields)
      this.setState({adTypeIndex: newIndex});
    }
  }

  proceedToReview(fields) {
    this.saveCopy(fields).done(() => {
      this.setState({ loading: true });
      $.post(this.props.refreshEndpoint)
       .done(this.props.proceed);
    });
  }

  addRequest(xhr) {
    this.setState({
      requests: this.state.requests.concat(xhr)
    });
  }

  doBind(){
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.render = this.render.bind(this);
    this.saveCopy = this.saveCopy.bind(this);
    this.addRequest = this.addRequest.bind(this);
    this.proceedToReview = this.proceedToReview.bind(this);
    this.finishCopy = this.finishCopy.bind(this);
    this.toggleAdType = this.toggleAdType.bind(this);
  }
};

