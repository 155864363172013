import React from 'react';

export default class User extends React.Component {
  render(){
    const {activity, display_name, user_id, traceable_id, minimum_instagram, minimum_youtube} = this.props.notification;
    const P = React.Fragment;
    switch(activity) {
      case 'accepted_in_sofab':
        if(user_id === traceable_id){
          return <P>You were accepted into SoFab!</P>
        }else{
          return <P>{display_name} was accepted into SoFab!</P>
        }
      case 'activate':
        return <P>You have activated {display_name}</P>
      case 'auto_approved_for_instagram':
        if(user_id === traceable_id){
          return(
            <P>Congratulations! Because you have more than {minimum_instagram} followers on Instagram you now qualify to apply and participate for our High Reach Instagram Opportunities. As these particular campaigns become available they will appear within your list of Opportunities.</P>
          )
        }else{
          return(
            <P><b>{display_name}</b> has more than {minimum_instagram} followers on Instagram and has been automatically approved for High Reach Instagram Opportunities!</P>
          )
        }
      case 'auto_approved_for_youtube':
        if(user_id === traceable_id){
          return(
            <P>Congratulations! Because you have more than {minimum_youtube} subscribers on YouTube you now qualify to apply and participate for our High Reach YouTube Opportunities. As these particular campaigns become available they will appear within your list of Opportunities.</P>
          )
        }else{
          return(
            <P><b>{display_name}</b> has more than {minimum_youtube} subscribers on YouTube and has been automatically approved for High Reach YouTube Opportunities!</P>
          )
        }
      case 'decline_tos':
        return <P><b>{display_name}</b> has declined the Terms of Service</P>
      case 'disable':
        return <P>You have disabled <b>{display_name}</b></P>
      case 'remove':
        return <P>You have requested <b>{display_name}</b> be removed</P>
      default:
        return null;
    }
  }
}
User.displayName= 'Notification.Templates.User'