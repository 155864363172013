class window.AdminMarketingVehicles

  @new: ->
    @form()

  @create: ->
    @form()

  @edit: ->
    @form()

  @update: ->
    @form()

  @form: ->
    $('#marketing_vehicle_description').redactor
      focus: true
      plugins: plugin_list
