import React from 'react';

const ResponseStyles = {
  'position': 'absolute',
  'left': '0',
  'top': '25px',
  'width': '100%',
  'height': '100%'
}

export default class InfluencerInvitationResponse extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  render() {
    let response = this.props.response;
    let loading = this.props.loading;
    let iconClass, textClass, retryBtn, responseText = '';
    let requestSuccessful = response && response.code === 200 ? true : false

    switch(true) {
      case loading:
        iconClass = 'fa-spin fa-circle-notch';
        responseText = 'Loading';
        break;
      case requestSuccessful:
        iconClass = 'fa-check success';
        textClass = 'success';
        responseText = response.json ? response.json : 'Influencer invite successful!';
        break;
      case !requestSuccessful:
        iconClass = 'fa-times error';
        textClass = 'error';
        responseText = response.json ? Object.values(response.json).flat().map((err) => {
          return <p key={Math.random()}>{err}</p>
        }) : 'Influencer invite unsuccessful';
        retryBtn = <button className='btn btn-default' style={{'marginTop': '25px'}}
                     onClick={() => this.props.retry()}>
                   Retry Submission
                   </button>;
        break;
    }

    return (
      <div className='modal-loading-spinner' style={ResponseStyles}>
        <div className='icon'><i className={`fas ${iconClass}`}></i></div>
        <div className='text'><h3 className={textClass}>{responseText}</h3></div>
        {retryBtn}
      </div>
    );
  }
}