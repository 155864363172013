import React from 'react';
import { Provider } from "react-redux"
import RoundTasksFormStore from '../redux/RoundTasksForm';
import RoundTasksEdit from './Edit'

export default class RoundTasksEditWrapper extends React.Component {
  render() {
    return (
      <Provider store={RoundTasksFormStore}>
        <RoundTasksEdit {...this.props}/>
      </Provider>
    );
  }
};
