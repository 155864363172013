import React from 'react';
import Alert from '../Alert'
import Prism from 'prismjs';
import 'prismjs/components/prism-jsx'
import 'prismjs/themes/prism-okaidia.css'
import LivingStyleGuideActionAlerts from './ActionAlerts'

export default class LivingStyleGuideAlerts extends React.Component {

  constructor(props) {
    super(props);

    this.closeAlert = this.closeAlert.bind(this);
  }

  componentDidMount(){
    Prism.highlightAll()
  }

  closeAlert(){
    console.log("This would normally remove the alert, but you are in the style guide!");
  }

  render() {
    return (
      <div id='alerts' className='style-guide-section'>
        <h1 className='section-header'>Alerts</h1>
        <div className='styles-container'>
          <p>All alerts should use the Faded Colors for backgrounds.</p>
          <div className='alert-styles'>
            <div className='examples'>
              <Alert type={'primary'} message={'Primary or information alerts should use the standard Blue.'} callback={this.closeAlert}/>
              <Alert type={'success'} message={'Any Success alert should use the Success Green color.'} callback={this.closeAlert}/>
              <Alert type={'warning'} message={'Warning / Caution alerts should use the Warning Orange color.'} callback={this.closeAlert}/>
              <Alert type={'error'} message={'Error alerts should use the Danger Red color.'} callback={this.closeAlert}/>
            </div>
            <p>We have the component <code>alert.js.jsx</code> in Social Fabric that we will want to utilize while implementing Alerts.</p>
            <div className='code-example'>
              <pre>
                <code className="language-jsx">
                  {`
                    closeAlertCallback(){
                      this.setState{{show: false}}
                    }

                    render() {
                      return (
                        {this.state.show &&
                          <Alert
                            type='error'
                            message='This is an error message!'
                            callback={this.closeAlertCallback}
                          />
                        }
                      );
                    }
                  `}
                </code>
              </pre>
            </div>
          </div>
        </div>
        <LivingStyleGuideActionAlerts />
      </div>
    );
  }
}
LivingStyleGuideAlerts.displayName = 'LivingStyleGuide.Alerts';