import React from 'react';
import { connect } from 'react-redux';
import { Remote } from '../../utils';

import InfluencerDashboardContentCard from './ContentCard'

class InfluencerDashboardContentList extends React.Component {
  constructor(props) {
    super(props);

    this.fetch = this.fetch.bind(this);
    this.render = this.render.bind(this);
    this.showMore = this.showMore.bind(this);
    this.updateSort = this.updateSort.bind(this);
  }

  render() {
    const {
      content,
      sort,
      maxViewsContentId,
      maxEngagementsContentId
    } = this.props;

    return <div className='influencer-dashboard content-list'>
      <div className='filter-bar'>
        <h2 className='pl-10'>Content</h2>

        <div className='filter-options'>
          <select className='form-control' value={sort.sort} onChange={this.updateSort}>
            <option value='date'>Recent</option>
            <option value='views'>Views</option>
            <option value='engagements'>Engagements</option>
          </select>

          <i className='fas fa-caret-down' />
        </div>
      </div>

      {content.map(content =>
        <InfluencerDashboardContentCard
          key={content.id}
          content={content}
          sortBy={sort.sort}
          maxViewsContentId={maxViewsContentId}
          maxEngagementsContentId={maxEngagementsContentId}
        />
      )}

      <div className='text-center'>
        <button disabled={!!sort.fetching} className='btn btn-outline' onClick={this.showMore}>
          Show More
          {sort.fetching && <i className='fas fa-circle-notch fa-spin'/>}
        </button>
      </div>
    </div>;
  }

  fetch() {
    const { sort, proxyUrl } = this.props;
    const params = {
      api_action: '/influencer_dashboard/content',
      sort: sort.sort,
      page: sort.page
    };
    return Remote().request({
      method: 'GET',
      url: proxyUrl,
      params: params
    })
  }

  showMore(e) {
    const { sort } = this.props;
    e.preventDefault();

    // Using a promise here to ensure that we have the latest sort params when we fetch
    new Promise((resolve, reject) => {
      this.props.updateSort({
        fetching: true,
        page: sort.page + 1
      });
      resolve();
    }).then(() => {
      this.fetch().then((resp) => {
        if(resp.data) {
          this.props.addContent(resp.data.content.content);
          this.props.updateSort({
            fetching: false
          });
        }
      });
    });
  }

  updateSort(e) {
    e.preventDefault();

    // Using a promise here to ensure that we have the latest sort params when we fetch
    new Promise((resolve, reject) => {
      this.props.updateSort({ sort: e.target.value });
      resolve();
    }).then(() => {
      this.props.setFetching();
      this.fetch().then((resp) => {
        if(resp.data) this.props.updateView(resp.data)
      })
    });
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      sort: _.get(state, 'content.sort', {}),
      content: _.get(state, 'content.content', []),
      proxyUrl: state.proxyUrl,
      maxViewsContentId: _.get(state, 'content.maxViewsContentId', null),
      maxEngagementsContentId: _.get(state, 'content.maxEngagementsContentId', null)
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateSort: change => dispatch({ type: 'UPDATE_CONTENTS_SORT', value: change }),
      updateView: value => dispatch({
        type: 'UPDATE_VIEW',
        value: {
          view: value.view,
          data: value
        }
      }),
      setFetching: () => dispatch({
        type: 'FETCHING_VIEW',
        value: {
          view: 'content',
          bool: true
        }
      }),
      addContent: content => dispatch({ type: 'ADD_CONTENT', value: content })
    };
  }
};

InfluencerDashboardContentList.displayName = 'InfluencerDashboard.ContentList';
export default connect(
  InfluencerDashboardContentList.mapStateToProps,
  InfluencerDashboardContentList.mapDispatchToProps
)(InfluencerDashboardContentList);
