import React from 'react';
class UploadStoryMediaCard extends React.Component {

  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  render() {
    let item_style = {
      display: 'inline-block',
      margin: '12px',
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
      cursor: 'pointer',
      border: '3px solid transparent',
      padding: '0'
    }

    let image_style = {
      width: '100%',
      height: 'auto',
      marginTop: 'auto'
    }

    let remove_button_container_style = {
      paddingTop: '10px',
      marginTop: 'auto'
    }

    let remove_button_style = {
      color: '#B22828',
      background: '#eee'
    }

    let container_style = {
      padding: '5px',
      width: '100%',
      textAlign: 'center',
      height: '100%',
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }

    let media = this.props.media;

    return (
        <div style={item_style} id={media.id} className="flex-item">
          <div style={container_style}>
              { media.media_type.includes('IMAGE') &&
                <img style={image_style} src={media.media_url} />
              }
              { media.media_type.includes('VIDEO') &&
                <video style={image_style} src={media.media_url} controls/>
              }
              <div style={remove_button_container_style}>
                <a className="btn btn-danger" onClick={() => this.props.deleteMedia(media.id)}>Remove</a>
              </div>
          </div>
        </div>
    );
  }
}
