import React from 'react';

export default class PaymentsDynamicForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...props };

    this.render = this.render.bind(this);
    this.onChange = this.onChange.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.validate = this.validate.bind(this);
    this.renderValidations = this.renderValidations.bind(this);
    this.renderField = this.renderField.bind(this);
    this.renderCheckBox = this.renderCheckBox.bind(this);
    this.renderTextBox = this.renderTextBox.bind(this);
    this.renderDropdown = this.renderDropdown.bind(this);
    this.validate = this.validate.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    this.formClass = this.formClass.bind(this);
    this.checkBoxHandler = this.checkBoxHandler.bind(this);
  }

  componentDidMount(newProps) {
    this.props.onChange({
      valid: this.validate()
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps });
  }

  onChange(event, fieldName) {
    let result = {};
    result[fieldName] = event.target.value;

    // Validate the set state, so we can notify the parent of the validity
    this.setState(result, () => {
      result.valid = this.validate();
      this.props.onChange(result);
    });
  }

  changeHandler(fieldName) {
    return (e) => this.onChange(e, fieldName);
  }

  checkBoxHandler(fieldName) {
    return (e) => {
      // Checkboxes are funky in react -- manually toggle the data
      let change = {};
      change[fieldName] = !this.state[fieldName];

      // We can bypass validation since checkboxes shouldn't change validity
      this.setState(change, () => this.props.onChange(change));
    }
  }

  validate() {
    // If we don't render anything for a field validation, it is valid
    return this.getFormFields().every((field) => !this.renderValidations(field));
  }

  renderValidations(field) {
    const fieldValue = this.state[field.fieldName];

    // Simple case, just display a message that the field is required
    if (field.required && !fieldValue) {
      return (
        <span className='text-danger'>
          This field is required
        </span>
      );
    }

    // More complex case -- try and match a regex, and if it fails display a custom message
    if (field.regex && field.regexMessage) {
      if(!(fieldValue || '').match(field.regex)) {
        return (
          <span className='text-danger'>
           {field.regexMessage}
          </span>
        );
      }
    }
  }

  renderTextBox(field) {

    return (
      <div className={this.formClass(field)} key={field.fieldName}>
        <label>{field.displayName}</label>
        <input className="form-control" placeholder={field.placeholder} onChange={this.changeHandler(field.fieldName)} value={this.props[field.fieldName] || ''}/>
        {this.renderValidations(field)}
      </div>
    );
  }

  renderCheckBox(field) {


    return (
      <div className="checkbox" key={field.fieldName}>
        <label>
          <input type="checkbox" checked={!!this.props[field.fieldName]} onChange={this.checkBoxHandler(field.fieldName)} />
          &nbsp;{field.displayName}
        </label>
      </div>
    );
  }

  renderDropdown(field) {
    return (
      <div className={this.formClass(field)} key={field.fieldName}>
        <label>{field.displayName}</label>
        <select className='form-control' onChange={this.changeHandler(field.fieldName)} value={this.props[field.fieldName] || ''}>
          <option value=''>Select One</option>
          {
            field.dropdown.map((item) =>
              <option value={item.value} key={item.name}>
                {item.name}
              </option>
            )
          }
        </select>
        {this.renderValidations(field)}
      </div>
    );
  }

  renderField(field) {
    if(field.checkBox) {
      return this.renderCheckBox(field);
    } else if(field.dropdown) {
      return this.renderDropdown(field);
    } else {
      return this.renderTextBox(field);
    }
  }

  formClass(field) {
    const hasError = !!this.renderValidations(field);
    return hasError ? 'form-group has-error' : 'form-group';
  }

  render() {
    const fields = this.getFormFields();

    return (
      <div>
        {fields.map((field) => this.renderField(field))}
      </div>
    );
  }
};
