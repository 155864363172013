import React from 'react';
import InfluencerDashboardContentTypes from './ContentTypes'
export default class InfluencerDashboardContentCard extends React.Component {
  render() {
    const { content, sortBy, maxViewsContentId, maxEngagementsContentId } = this.props;

    return(
      <div
        key={content.id}
        className='content-card card clickable'
        onClick={() => window.open(`${window.location.origin}/contents/${content.id}`, "_blank")}
      >
        <div className='content-card-row'>
          <div className='content-info'>
            <div className='content-type'>{InfluencerDashboardContentTypes[content.type]}</div>
            <div className='content-shop-name text-color-subdued'>{content.shop_name}</div>
          </div>
          { sortBy != 'engagements' && parseInt(content.pageviews) > 0 &&
            <div className='content-views'>
              <div className='text-size-x-large content-pageviews'>{content.pageviews}</div>
              <div className='text-size-small content-pageviews-label'>Views</div>
            </div>
          }
          { sortBy == 'engagements' && parseInt(content.engagements) > 0 &&
            <div className='content-engagements'>
              <div className='text-size-x-large content-pageviews'>{content.engagements}</div>
              <div className='text-size-small content-pageviews-label'>Engagements</div>
            </div>
          }
        </div>
        <div className='content-card-row'>
          <div className='content-submitted-date text-size-small'>Submitted: {content.formatted_submitted_at}</div>
          { content.id == maxViewsContentId &&
            <span className='badge text-size-x-small background-color-blue'>Most Views</span>
          }
          { content.id == maxEngagementsContentId &&
            <span className='badge secondary text-size-x-small background-color-purple'>Most Engagements</span>
          }
        </div>
      </div>
    );
  }
};

InfluencerDashboardContentCard.displayName = 'InfluencerDashboard.ContentCard';