import React from 'react';
import { connect } from "react-redux";
import ManualMetricsInputs from "./ManualMetricsInputs";

class AdminTiktokManualMetrics extends React.Component {

  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount(){
    this.props.initializeManualMetrics(this.props.tiktokManualMetrics);
  }

  render() {
    const {tiktokManualMetrics, bloggerShopTask, proxyUrl, manualMetricsInstructions} = this.props;

    return <div style={{marginBottom: '25px'}}>
      <ManualMetricsInputs
        analytics={tiktokManualMetrics}
        proxyUrl={proxyUrl}
        type='manual_tiktok'
        manualMetricsInstructions={manualMetricsInstructions}
        blogger_shop_task_id={bloggerShopTask.id}
        blogger_shop_task_state={bloggerShopTask.state} />
    </div>
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      tiktokManualMetrics: state.tiktokManualMetrics || myProps.tiktokManualMetrics,
      bloggerShopTask: state.bloggerShopTask
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      initializeManualMetrics: (value) => dispatch({ type: 'INITIALIZE_MANUAL_METRICS', value: { name: 'tiktokManualMetrics', metrics: value} })
    };
  }
};

AdminTiktokManualMetrics.displayName = 'Contents.AdminTiktokManualMetrics';
export default connect(
  AdminTiktokManualMetrics.mapStateToProps,
  AdminTiktokManualMetrics.mapDispatchToProps
)(AdminTiktokManualMetrics);


