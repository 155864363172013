class window.SidebarUtils
  constructor: (containerSelector, previousAnnotationsSelector) ->
    @container = $(containerSelector)
    @annotations = $(previousAnnotationsSelector).data('annotations') || []
    @active_annotations = $(previousAnnotationsSelector).data('active-annotations') || []
    @bootstrap() if $(previousAnnotationsSelector).length

  bootstrap: ->
    @flexContainer()
    @addButton()
    @addSidebar()

  flexContainer: ->
    @container.addClass('sidebar-flex')

  addButton: ->
    @button = @createButton()
    @container.prepend(@button)

  addSidebar: ->
    @sidebar = @createSidebar()
    @container.prepend(@sidebar)
    @button.click =>
      @sidebar.show()
    @sidebar.find('.sidebar-close').click =>
      # Use an animation class, then hide the element
      @sidebar.addClass('slide-out')
      setTimeout =>
          @sidebar.hide()
          @sidebar.removeClass('slide-out')
        ,400
    @sidebar.append(@createListings())

  createButton: ->
    $(
      "
      <div class='sidebar-icon'>
        <div class='content'>
          History <i class='fas fa-history'></i>
        </div>
      </div>
      "
    )

  createSidebar: ->
    sidebar = "
      <div class='sidebar-popover' style='display: none'>
        <div class='sidebar-popover-controls'>
          <i class='sidebar-close text-danger fas fa-2x fa-times'></i>
        </div>
      "
    if @active_annotations.length > 0
      sidebar += "
        <h3 class='sidebar-popover-title'>
          Active Annotations
        </h3>
      "
    sidebar += "</div>"
    $(sidebar)

  createListings: ->
    # We need to display a message if there aren't any annotations
    html = ""
    if @active_annotations
     (html += @createListingHTML(annotation)) for annotation in @active_annotations
    html += "
      <div class='sidebar-popover-controls'>
        <i class='sidebar-close'></i>
      </div>
      <h3 class='sidebar-popover-title'>
        Annotations History
      </h3>
    "
    unless @annotations.length
      return html + "
        <div class='text-center'>
          <em>No prior annotations exist for this document</em>
        </div>
      "
    # Join all of the HTML from our listings together and return as an element
    (html += @createListingHTML(annotation)) for annotation in @annotations
    $(html)

  # Only spit out HTML here, since it's faster to render all elements together than piece by piece
  createListingHTML: (annotation) ->
    date = new Date(annotation.created_at)
    "
    <div class='sidebar-listing'>
      <div class='title'>
        #{annotation.author} on #{date.getMonth() + 1}/#{date.getDate()}/#{date.getFullYear()}
      </div>
      <div class='quote-container'>
        <i class='fas fa-quote-left'></i>
        <span class='quote'>#{annotation.original_text}</span>
        <i class='fas fa-quote-right'></i>
      </div>
      <h4 class='annotation-text'>
        #{@cleanAnnotation(annotation)}
      </h4>
    </div>
    "

  # Annotations get saved with a messy format, so this cleans out the extra junk and just returns the content
  cleanAnnotation: (annotation) ->
    regex = new RegExp("\\s+-\\s+#{annotation.author}\\s+on\\s+\\d+\\/\\d+\\/\\d+\\s+at\\s+\\d+:\\d+[AP]M\\s+CST$")
    annotation.text.replace(regex, '')
