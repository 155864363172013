import React from 'react';
import { connect } from 'react-redux'
import { Remote } from '../utils';

import CampaignsRequirementsCard from '../campaigns/requirements/Card'

class RoundsTasksContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sorted: false,
      dragIndex: null,
      dragOverIndex: null
    }

    this.onDragStart = this.onDragStart.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.render = this.render.bind(this);
  }

  updateRoundTasksSort() {
    const params = $.param({
      api_action: `/round_tasks/sort`
    });

    const data = {
      round_task_ids: this.props.round.round_tasks.map((task) => { return task.id })
    };

    Remote().request({
      url: this.props.meta.proxyUrl + '?' + params,
      method: 'POST',
      data: data,

    }).then((response) => {
      this.props.openNotification(
        'Successfully saved round tasks order.',
        'success',
        ''
      )
    }).catch((error) => {
      this.props.openNotification(
        'Failed to saved round tasks order.',
        'failure',
        error.response.data.responseJSON
      )
    });
    setTimeout(this.props.closeNotification, 5000)
  }

  onDragStart(index) {
    this.setState({ dragIndex: index });
  }

  onDragOver(index) {
    this.setState({ dragOverIndex: index });
  }

  onDragEnd() {
    const { dragIndex, dragOverIndex } = this.state;

    if (dragIndex !== null && dragOverIndex !== null) {
      const promise = () => new Promise((resolve) => {
        resolve(this.props.updateTaskSort(dragIndex, dragOverIndex));
      });

      promise().then(() => {
        this.updateRoundTasksSort();
        this.setState({
          sorted: true,
          dragIndex: null,
          dragOverIndex: null
        });
      });
    }
  }

  render() {
    const round = this.props.round || {};
    const roundTasks = this.state.sorted ? round.round_tasks : _.sortBy(round.round_tasks, ['sort']);

    return (
      <div className='round-tasks-container'>
        <div className='task-title'>
          <div className='left'>
            Tasks
          </div>
          <div className='right'>
            <button className='btn btn-primary' onClick={() => this.props.toggleModal()} disabled={round.instruction_approved || false}>
              Add General Requirements
            </button>
          </div>
        </div>
        {
          roundTasks.map((task, index) =>
              <CampaignsRequirementsCard
                key={task.id}
                index={index}
                type='task_content_requirement'
                requirement={task}
                instructionApproved={ round.instruction_approved || false }
                shopPosted={ round.shop_posted || false }
                contentTypes={this.props.contentTypes}
                templateTypes='campaign'
                updateTaskField={(field, value) => this.props.editTask(round, task, field, value)}
                updateRound={(roundField, value) => this.props.edit({ ...round, [roundField]: value})}
                proxyUrl={this.props.meta.proxyUrl}
                addRequirement={(taskId, data) => this.props.addRequirement(round, taskId, data)}
                updateRequirementField={(taskId, field, value, index) => this.props.editRequirement(round, taskId, index, field, value)}
                deleteRequirement={(taskId, index) => this.props.deleteRequirement(round, taskId, index)}
                deleteRequirementById={(taskId, id) => this.props.deleteRequirementById(round, taskId, id)}
                removeTaskContentRequirement={taskId => this.props.deleteTask(round, taskId)}
                addTask={task => this.props.addTask(round, task)}
                openNotification={this.props.openNotification}
                closeNotification={this.props.closeNotification}
                displayPage={this.props.displayPage}
                onDragStart={this.onDragStart}
                onDragEnd={this.onDragEnd}
                onDragOver={this.onDragOver}
                draggedOver={this.state.dragOverIndex === index}
                dragOverIndex={this.state.dragOverIndex}
                draggable={true}
                palette={this.props.palette}
                payment={round.payment || false}
              />
          )
        }
      </div>
    );
  }

  static mapStateToProps(state, myProps) {
    return {
      ...state,
      ...myProps
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      edit: (round) => dispatch({ type: 'EDIT_ROUND', value: { id: round.id, data: round } }),
      addTask: (round, task) => dispatch({ type: 'ADD_TASK', value: { roundId: round.id, roundTask: task } }),
      editTask: (round, task, field, value) => dispatch({
        type: 'EDIT_TASK',
        value: {
          roundId: round.id,
          taskId: task.id,
          field: field,
          value: value
        }
      }),
      deleteTask: (round, taskId) => dispatch({
        type: 'DELETE_TASK',
        value: {
          roundId: round.id,
          taskId: taskId
        }
      }),
      addRequirement: (round, taskId, requirement) => dispatch({
        type: 'ADD_REQUIREMENT',
        value: {
          roundId: round.id,
          taskId: taskId,
          value: requirement
        }
      }),
      editRequirement: (round, taskId, index, field, value) => dispatch({
        type: 'EDIT_REQUIREMENT',
        value: {
          roundId: round.id,
          taskId: taskId,
          index: index,
          field: field,
          value: value
        }
      }),
      deleteRequirementById: (round, taskId, id) => dispatch({
        type: 'DELETE_REQUIREMENT_BY_ID',
        value: {
          roundId: round.id,
          taskId: taskId,
          id: id
        }
      }),
      deleteRequirement: (round, taskId, index) => dispatch({
        type: 'DELETE_REQUIREMENT',
        value: {
          roundId: round.id,
          taskId: taskId,
          index: index
        }
      }),
      openNotification: (saveText, saveType, saveError) => dispatch({
        type: 'OPEN_NOTIFICATION',
        value: {
          saveText,
          saveType,
          saveErrors: saveError
        }
      }),
      closeNotification: () => dispatch({ type: 'CLOSE_NOTIFICATION'}),
      updateTaskSort: (from, to) => dispatch({
        type: 'UPDATE_TASK_SORT',
        value: {
          from: from,
          to: to
        }
      }),
      toggleModal: () => dispatch({ type: 'TOGGLE_GENERAL_MODAL' }),
    };
  }
};

RoundsTasksContainer.displayName = 'Rounds.TasksContainer';

export default connect(
  RoundsTasksContainer.mapStateToProps,
  RoundsTasksContainer.mapDispatchToProps
)(RoundsTasksContainer);
