import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash'
import Upload from '../Upload'
import SubmissionLinkForm from './LinkForm'
import SubmissionManualInstagramSubmission from './ManualInstagramSubmission'
import SubmissionPreview from './Preview'

class SubmissionManualContentSubmission extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showManualArea: false
    }

    this.debouncedMediaAttachments = _.debounce(this.updateMediaAttachments, 500);
    this.renderManualSubmit = this.renderManualSubmit.bind(this);
    this.storyUploadArea = this.storyUploadArea.bind(this);
    this.addMedia = this.addMedia.bind(this);
    this.toggleUploadArea = this.toggleUploadArea.bind(this);
    this.render = this.render.bind(this);
    this.toggleManual = this.toggleManual.bind(this);
  }

  renderManualSubmit(){
    switch(this.props.contentType.name) {
      case 'InstagramBusinessStoryContent':
        return this.storyUploadArea();
        break;
      case 'InstagramContent':
        return this.instagram_content_task && <SubmissionManualInstagramSubmission />;
      default:
        if(this.props.manual){
          return <SubmissionLinkForm tiktok_oembed_js={this.props.tiktok_oembed_js} />;
        } else {
          return <a className='submission manual-link-submission prompt' onClick={this.toggleManual}>
                     Don't see your post? Click here to submit a link manually.
                 </a>;
        }
    };
  }

  instagram_content_task(){
    return this.props.bloggerShopTask.round_task.content_types.find(type => type.name == 'InstagramContent')
  }

  toggleManual(e) {
    e.preventDefault();
    this.props.setManual(!this.props.manual);
  }


  storyUploadArea(){
    const addMediaImagePath = `/contents/create_instagram_media`

    return <div>
      {!!this.props.displayPreview && <SubmissionPreview tiktok_oembed_js={this.props.tiktok_oembed_js} manualAttachments={this.props.instagramMediaAttachments}/>}
      <a onClick={() => this.toggleUploadArea()} className="instagram_media_attachment_toggle">
        Don't see your stories? Click here to submit manually
      </a>

      {this.state.showManualArea && <div className="instagram_media_attachment_uploader">
        {!this.props.readOnly && <div style={{paddingBottom: '10px'}}>
          <div style={{paddingBottom: '10px'}}>
            Please upload all Instagram Story images and videos as listed in the task requirements.
            All stories must include a screenshot displaying the number to views + the raw video or
            image file.
          </div>
          <div>
              <Upload uploadPath={addMediaImagePath}
                      fileParam={"instagram_attachment"}
                      params={{ taskId: this.props.bloggerShopTask.id }}
                      fileTypes={'.jpg, .png, .mov, .mp4'}
                      callback={this.addMedia}/>
          </div>
        </div>}
      </div>}
    </div>
  }


  addMedia(media) {
    this.props.addInstagramMediaAttachment(media);
    this.debouncedMediaAttachments();
  }

  updateMediaAttachments() {
    let ids = this.props.instagramMediaAttachments.map((media) => media.id);
    this.props.updateSubmission({ instagram_business_story_media_ids: ids });
  }


  render(){
    return <div className="submission upload-area manual-submission">
      {this.renderManualSubmit()}
    </div>
  }
  toggleUploadArea(){
    this.setState({
      showManualArea: !this.state.showManualArea
    });
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      instagramMediaAttachments: state.instagramMediaAttachments || [],
      manual: state.manual,
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
        addInstagramMediaAttachment: instagramMediaAttachment => dispatch({ type: 'ADD_INSTAGRAM_MEDIA_ATTACHMENT', value: instagramMediaAttachment}),
        setManual: flag => dispatch({ type: 'SET_MANUAL', value: flag }),
    };
  }
};

SubmissionManualContentSubmission.displayName = 'Submission.ManualContentSubmission';
export default connect(
  SubmissionManualContentSubmission.mapStateToProps,
  SubmissionManualContentSubmission.mapDispatchToProps
)(SubmissionManualContentSubmission);
