import React from 'react';

class InfluencerDashboardPaymentCard extends React.Component {
  render() {
    const { payment } = this.props;

    return <a className='card clickable influencer-dashboard payment-card' href={payment.shop_path}>
      <div className='top-row'>
        <div className='shop'>
          {payment.shop_name || payment.payment_type}
          <div className='job-code'>
            {payment.job_code}
          </div>
        </div>
        <div className='amount'>
          {payment.formatted_amount}
        </div>
      </div>
      <div className='bottom-row'>
        <div className='type'>{payment.payment_type}</div>
        {this.paymentDate()}
        {this.paymentState()}
      </div>
    </a>;
  }

  paymentDate() {
    const { payment } = this.props;
    return payment.paid_on || `Due: ${payment.due_date}`;
  }

  paymentState() {
    const { payment } = this.props;

    if(payment.state == 'paid') {
      return <div className='badge background-color-green text-format-upcase'>
        {payment.formatted_state}
      </div>;
    }

    return <div className='badge background-color-subdued text-format-upcase'>
      {payment.formatted_state}
    </div>;
  }
};

InfluencerDashboardPaymentCard.displayName = 'InfluencerDashboard.PaymentCard';

export default InfluencerDashboardPaymentCard