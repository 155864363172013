import React from 'react';
import PropTypes from 'prop-types';

export default class HorizontalScrollContainer extends React.Component {
  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.render = this.render.bind(this);

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    if(this.props.updateRef) {
      this.props.updateRef(this.containerRef);
    }
  }

  render() {
    return (
      <div className={`horizontal-scroll-container ${this.props.className}`} ref={this.containerRef}>
        {this.props.children}
      </div>
    );
  }
};

HorizontalScrollContainer.displayName = 'HorizontalScrollContainer';
HorizontalScrollContainer.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  updateRef: PropTypes.func // Returns a reference to the DOM node we're using
};
