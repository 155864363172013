import React from 'react';
import DynamicForm from './DynamicForm';

export default class PaymentsAccountSetup extends DynamicForm {
  getFormFields() {
    return [
      { displayName: 'Address', fieldName: 'address1', required: true },
      { displayName: 'City', fieldName: 'city', required: true},
      {
        displayName: 'State',
        fieldName: 'state',
        required: true,
        dropdown: [
          { name: "AL", value: "AL" },
          { name: "AK", value: "AK" },
          { name: "AS", value: "AS" },
          { name: "AZ", value: "AZ" },
          { name: "AR", value: "AR" },
          { name: "CA", value: "CA" },
          { name: "CO", value: "CO" },
          { name: "CT", value: "CT" },
          { name: "DE", value: "DE" },
          { name: "DC", value: "DC" },
          { name: "FM", value: "FM" },
          { name: "FL", value: "FL" },
          { name: "GA", value: "GA" },
          { name: "GU", value: "GU" },
          { name: "HI", value: "HI" },
          { name: "ID", value: "ID" },
          { name: "IL", value: "IL" },
          { name: "IN", value: "IN" },
          { name: "IA", value: "IA" },
          { name: "KS", value: "KS" },
          { name: "KY", value: "KY" },
          { name: "LA", value: "LA" },
          { name: "ME", value: "ME" },
          { name: "MH", value: "MH" },
          { name: "MD", value: "MD" },
          { name: "MA", value: "MA" },
          { name: "MI", value: "MI" },
          { name: "MN", value: "MN" },
          { name: "MS", value: "MS" },
          { name: "MO", value: "MO" },
          { name: "MT", value: "MT" },
          { name: "NE", value: "NE" },
          { name: "NV", value: "NV" },
          { name: "NH", value: "NH" },
          { name: "NJ", value: "NJ" },
          { name: "NM", value: "NM" },
          { name: "NY", value: "NY" },
          { name: "NC", value: "NC" },
          { name: "ND", value: "ND" },
          { name: "MP", value: "MP" },
          { name: "OH", value: "OH" },
          { name: "OK", value: "OK" },
          { name: "OR", value: "OR" },
          { name: "PW", value: "PW" },
          { name: "PA", value: "PA" },
          { name: "PR", value: "PR" },
          { name: "RI", value: "RI" },
          { name: "SC", value: "SC" },
          { name: "SD", value: "SD" },
          { name: "TN", value: "TN" },
          { name: "TX", value: "TX" },
          { name: "UT", value: "UT" },
          { name: "VT", value: "VT" },
          { name: "VI", value: "VI" },
          { name: "VA", value: "VA" },
          { name: "WA", value: "WA" },
          { name: "WV", value: "WV" },
          { name: "WI", value: "WI" },
          { name: "WY", value: "WY" }
        ]
      },
      {
        displayName: 'Zipcode',
        fieldName: 'zipCode',
        required: true,
        regex: /^\d\d\d\d\d(\d\d\d\d)?$/,
        regexMessage: 'Please enter a valid 5 or 9 digit zip code'
      },
    ];
  }
}
