import React from 'react';
import { Remote } from './utils';
import TagInput from '../components/TagInput';
import SaveIndicator from './SaveIndicator'
import SaveError from './SaveError'

export default class GuardCaption extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      guardCaptions: this.props.guardCaptions,
      timeoutId: null,
      saving: false,
      saveType: 'info',
      saveText: '',
      saveErrors: null
    }

    this.handleTagAction = this.handleTagAction.bind(this);
    this.saveGuardCaption = this.saveGuardCaption.bind(this);
    this.handleTagRemoval = this.handleTagRemoval.bind(this);
    this.flash = this.flash.bind(this);
  }

  saveGuardCaption(caption) {
    if (this.state.timeoutId) clearTimeout(this.state.timeoutId)
    this.setState({ saving: true, saveType: 'info', saveText: 'Saving captions...', saveErrors: null });

    const params = $.param({
      api_action: '/caption_guardrails'
    });

    Remote().request({
      url: this.props.proxy_url + '?' + params,
      data: {
        guard_captions: {
          shoppertunity_id: this.props.shoppertunityId,
          check_value: caption,
          check_operator: this.props.operator
        },
        instruction_id: this.props.instructionId
      },
      method: 'POST'
    }).then((response) => {
      if (response.data && response.data.success) {
        this.setState(prevState => ({
          guardCaptions: [...prevState.guardCaptions,
            {
              name: response.data.success.check_value, id: response.data.success.id
            }
          ],
          saveErrors: null
        }))
        this.flash({
          saveText: `Successfully added ${response.data.success.check_value}`,
          saveType: 'success'
        }, 3000)
      } else {
        this.flash({
          saveText: '',
          saveType: 'failure',
          saveErrors: JSON.parse(response.data.text).errors
        }, 5000)
      }
    }).catch((error) => {
      this.flash({
        saveText: 'Failed to save guardails!',
        saveType: 'failure',
        saveErrors: error
      }, 5000)
    });
  }

  flash(newState, duration) {
    this.setState(newState, () => {
      const timeoutId = window.setTimeout(() => this.setState({
        saving: false
      }), duration);

      this.setState({ timeoutId });
    });
  }

  handleTagRemoval(guardCaptionId) {
    const params = $.param({
      api_action: `/caption_guardrails/${guardCaptionId}`
    });
    Remote().request({
      method: 'DELETE',
      url: this.props.proxy_url + '?' + params,
    }).then((response) => {
      if (response.data) {
        this.setState(prevState => ({
          guardCaptions: prevState.guardCaptions.filter(caption => caption.id !== guardCaptionId)
        }));
      }
    });
  }

  handleTagAction(caption) {
    this.saveGuardCaption(caption);
  }

  render() {
    const {tagClassName, placeHolder} = this.props;
    return (
      <div>
        <TagInput
          tagClassName={tagClassName}
          placeHolder={placeHolder}
          tags={this.state.guardCaptions}
          onTagsChange={this.handleTagAction}
          onTagDeletion={this.handleTagRemoval}
          readOnly={this.props.readOnly}
        />
        { this.state.saving &&
          <SaveIndicator type={this.state.saveType}>
            {this.state.saveText}
            {this.state.saveErrors && <SaveError errors={this.state.saveErrors}/>}
          </SaveIndicator>
        }
      </div>
    );
  }
}
