import React from 'react';
import 'prismjs';
import 'prismjs/components/prism-jsx'
import 'prismjs/themes/prism-okaidia.css'
import ActionAlert from '../ActionAlert'

const LivingStyleGuideActionAlerts = () => {
  const types = ['info', 'success', 'error', 'warning'];
  return (
    <div id="action-alerts" className="style-guide-section">
      <h2 className="sub-header">Action Alerts</h2>
      <div className='styles-container container'>
        {
          types.map((type, index) => (
            <div className="col-md-6">
              <ActionAlert
                key={`alert-${index}`}
                type={type}
                text={`This is an action alert of type ${type}`}
                actionText={`${type} action`}
                actionDestination="#action-alerts"
              />
            </div>
          ))
        }
        <h3 className="sub-header">Custom Icon, no action and modal launch</h3>
        <div className="col-md-6">
          <ActionAlert
            type="info"
            key="custom-alert-1"
            icon="smile"
            text="This is an action alert with custom icon of type info"
            actionText="custom action"
            actionDestination="#action-alerts"
          />
        </div>
        <div className="col-md-6">
          <ActionAlert
            type="error"
            key="custom-alert-2"
            icon="meh"
            text="This is an action alert with custom icon of type error"
            actionText="custom action"
            actionDestination="#action-alerts"
          />
        </div>
        <div className="col-md-6">
          <ActionAlert
            type="info"
            key="custom-alert-3"
            icon="unlink"
            text="This is an action alert with no action and custom icon"
          />
        </div>
        <div className="col-md-6">
          <ActionAlert
            type="info"
            key="custom-alert-4"
            icon="thumbs-up"
            text="This is an action alert with with modal launch"
            actionText="launch modal"
            actionDestination="#"
            data-toggle="modal"
            data-target="#action-alert-test-modal"
          />
          {/* modal start */}
          <div id="action-alert-test-modal" className="modal fade" role="dialog">
            <div className='modal-dialog modal-md' role='document'>
              <div className='modal-content'>
                <div className='modal-header'>
                  Action Alert modal
                </div>
                <div className='modal-body'>
                  action alert modal body content
                </div>
              </div>
            </div>
          </div>
          {/* modal end */}
        </div>
        <div className="col-md-12">
          <p>
            We have the <code>action_alert.js.jsx</code> component built for the implementation of our action alert.
          </p>
          <p>
            <b>Note:</b> <code>icon</code> prop value passed to the component is Font Awesome class name with out the <code>fa</code> prefix
            <br />
            <b>eg:</b> for getting <i className="fas fa-times"></i>, instead of passing <code>fa fa-times</code> simply pass <code>times</code>
          </p>
          <div className='code-example'>
            <pre>
              <code className="language-jsx">
                {`
                  render() {
                    return (
                      <div>
                        <ActionAlert
                          type="error"
                          icon="meh"
                          text="This is an action alert with custom icon of type error"
                          actionText="custom action"
                          actionDestination="#"
                        />
                        {/* pass data-toggle and data-target props to the component to launch modal*/}
                        <ActionAlert
                          type="info"
                          icon="thumbs-up"
                          text="This is an action alert with with modal launch"
                          actionText="launch modal"
                          actionDestination="#"
                          data-toggle="modal"
                          data-target="#action-alert-test-modal"
                        />
                      </div>
                    )
                  }
                `}
              </code>
            </pre>
          </div>
        </div>
      </div>

    </div>
  )
}

export default LivingStyleGuideActionAlerts;