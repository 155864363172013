import React from 'react';
import { Remote } from '../../../utils';
import Tooltip from '../../../Tooltip';

export default class CampaignsRequirementsInstructionNotesButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltip: {
        button: null,
        visible: false
      }
    }

    this.render = this.render.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.addInstructionNote = this.addInstructionNote.bind(this);
  }

  toggleTooltip(button) {
    this.setState({
      tooltip: {
        button: button,
        visible: !this.state.tooltip.visible
      }
    })
  }

  addInstructionNote() {
    const params = $.param({
      api_action: `/instruction_notes`
    });

    let newRequirement = {
      campaign_id: this.props.campaign_id,
      type: 'instruction_notes',
      label: 'New Instructions Notes',
      description: ''
    }

    Remote().request({
      method: 'POST',
      url: this.props.proxyUrl + '?' + params,
      data: { note: newRequirement }
    }).then((response) => {
        this.props.addInstructionNotes(response.data);
    }).catch((results) => {
      this.props.openNotification(
        'Failed to create instructions notes! Please try again.',
        'failure',
        [JSON.stringify(results)]
      )
      setTimeout(this.props.closeNotification, 5000)
    });
  }

  render(){
    const { tooltip: { button, visible } } = this.state;

    return (
        <div className='btn note_button' onClick={(e) => this.addInstructionNote()} onMouseLeave={() => this.toggleTooltip('note')} onMouseEnter={() => this.toggleTooltip('note')}>
          <i className='fas fa-plus'></i>
          Instructions Notes
          { (visible && button === 'note') && <Tooltip centerText={true} placement='bottom' message={this.props.I18n.tooltips.noteButton}/>  }
        </div>
    );
  }
}

CampaignsRequirementsInstructionNotesButton.displayName = 'Campaigns.Requirements.InstructionNotesButton';
