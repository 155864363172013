import React from 'react';

export const UserSignUpSocialInfoFields = {
  blogName: {
    label: 'Blog Name',
    fieldType: 'text',
    className: 'input_wrap'
  },
  blogUrl: {
    label: 'Blog URL',
    regex: /https?:\/\/[\w\d\.\/\-]+/gi,
    regexMessage: 'Must valid url starting with https:// or http://',
    fieldType: 'url',
    className: 'input_wrap',
    placeholder: 'https://...'
  },
  blogCategory: {
    label: 'Blog Category (Choose 1 or 2)',
    fieldType: 'select',
    className: 'input_wrap',
    multiSelect: true,
    limit: 2
  },
  blogPlatform: {
    label: 'Blog Platform',
    fieldType: 'select',
    className: 'input_wrap'
  },
  blogLanguage: {
    label: 'Blog Language',
    fieldType: 'select',
    className: 'input_wrap'
  }
}