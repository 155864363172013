import React from 'react';
import PropTypes from 'prop-types'
import HorizontalScrollContainer from './HorizontalScrollContainer'

/*
  Usage:
  <CarouselContainer>
    <div>child</div>
    <div>child</div>
  </CarouselContainer>
 */
export default class CarouselContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      count: 0,
      childRef: null,
      scroll: 0
    }

    this.childrenCount = this.childrenCount.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.render = this.render.bind(this);
    this.renderArrow = this.renderArrow.bind(this);
    this.renderDots = this.renderDots.bind(this);
    this.scrollView = this.scrollView.bind(this);
    this.updateActiveDot = this.updateActiveDot.bind(this);
  }

  componentDidMount() {
    this.setState({
      count: this.childrenCount()
    })

    const carousel = document.querySelector('.carousel');
    if (!!carousel)
      carousel.addEventListener('scroll', this.updateActiveDot);
  }

  componentWillUnmount() {
    const carousel = document.querySelector('.carousel');
    if (!!carousel)
      carousel.removeEventListener('scroll', this.updateActiveDot);
  }

  // Sets active to index of displayed card
  updateActiveDot() {
    const { count } = this.state;
    const carousel = document.querySelector('.carousel');

    if (!carousel || count < 1)
      return;

    const scroll = carousel.scrollLeft;
    const width = carousel.scrollWidth - carousel.clientWidth + 1;

    this.setState({
      active: Math.floor(scroll / (width / count)),
      scroll:  scroll,
      maxScroll: width,
    })
  }

  childrenCount(){
    const { children } = this.props;
    const childCount = React.Children.count(children);
    if (childCount < 1)
      return 0;

    if (childCount == 1)
      return _.get(children,'props.children.length');

    return childCount
  }

  renderDots(){
    const { active, count } = this.state;

    if(this.childrenCount() <= 1) {
      return null;
    }

    return (
      Array.from(Array(count)).map( (_, index) => {
        return <span key={index} className={`dot ${index == active ? 'active' : ''}`}/>
      })
    )
  }

  scrollView(positive) {
    const { childRef } = this.state;

    if(!childRef) {
      return;
    }

    // Calculate the (expected) width of an element so that we can scroll roughly
    // 1 new item into view
    const { width } = childRef.current.getBoundingClientRect();
    let scrollDist = width / this.childrenCount();
    if(!positive) {
      scrollDist *= -1;
    }

    childRef.current.scrollBy(scrollDist, 0);
  }

  renderArrow(direction) {
    const { desktopArrows } = this.props;
    const { scroll, maxScroll } = this.state;

    if(!desktopArrows || this.childrenCount() <= 1) {
      return null;
    }

    if(direction === 'left' && scroll < 15) {
      return null;
    }
    // The < 15 here is just for providing a handling being off by a few pixels
    if(direction === 'right' && Math.abs(scroll - maxScroll) < 15) {
      return null;
    }

    return <i className={`desktop-arrow fas fa-chevron-${direction}`} onClick={() => this.scrollView(direction === 'right')} />
  }

  render() {
    const { neverExpand, desktopArrows } = this.props;
    let baseClassName = 'carousel';
    let rootClassName = 'carousel-container-component';
    if(!!neverExpand) {
      baseClassName += ' no-expand';
    }
    if(!!desktopArrows) {
      baseClassName += ' desktop-arrows';
      rootClassName += ' desktop-arrows';
    }

    return (
      <div className={rootClassName}>
        {this.renderArrow('left')}
        <HorizontalScrollContainer className={baseClassName} updateRef={ref => this.setState({ childRef: ref })}>
          <React.Fragment>
            { this.props.children }
          </React.Fragment>
        </HorizontalScrollContainer>
        {this.renderArrow('right')}

        <div className={`${baseClassName} indicators`}>
          { this.renderDots() }
        </div>
      </div>
    )
  }
};

CarouselContainer.displayName = 'CarouselContainer';
CarouselContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  neverExpand: PropTypes.bool, // Disables the "expand" into a non-scrolled component at a certain size
  desktopArrows: PropTypes.bool
};
