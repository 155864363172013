import { createStore } from 'redux';
import _ from 'lodash';

const initialState = {
  shopApplicationId: null,
  socialPlatformId: null,
  round_bids: [],
  loading: false,
  fetching: false,
  canSubmit: false,
  isReadOnly: false,
  error: false,
  errorMessage: ''
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
  case 'INITIALIZE_FIELD':
    return {
      ...state,
      [action.value.field]: action.value.data
    };
  case 'INITIALIZE_DATA':
    return {
      ...state,
      ...action.value
    };
  case 'TOGGLE_FETCHING':
    return {
      ...state,
      fetching: !state.fetching
    };
  case 'TOGGLE_LOADING':
    return {
      ...state,
      loading: !state.loading
    };
  case 'SET_SOCIAL_PLATFORM':
    return {
      ...state,
      socialPlatformId: action.value
    }
  case 'UPDATE_SELECTED_ROUNDS':
    const newSelectedRounds = _.union(state.initialSelectedRoundIds, action.value);

    return {
      ...state,
      selectedRoundIds: newSelectedRounds,
      selectedRounds: _.filter(state.rounds, (round) => { return newSelectedRounds.includes(round.id) })
    }
  case 'SET_QUESTION_ANSWER':
    return {
      ...state,
      questions: state.questions.map(question => {
        if(question.id != action.value.questionId) {
          return question;
        }

        return {
          ...question,
          answer:action.value.answer
        };
      })
    }
  case 'SET_CAN_SUBMIT_STATE':
    return {
      ...state,
      canSubmit: action.value
    }
  case 'SET_ERROR_MESSAGE':
    return {
      ...state,
      error: action.value.length > 0,
      errorMessage: action.value
    }
  case 'UPDATE_ROUND_BID':
    return {
      ...state,
      round_bids: {
        ...state.round_bids,
        [action.value.id]: {
          amount: action.value.amount,
          created_by_id: action.value.user_id,
          updated_by_id: action.value.user_id
        }
      }
    }
  default:
    return state;
  };
};

const ShopApplicationsStore = createStore(
  reducer,
  window && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ name: 'Redux.ShopApplicationsStore' }) // Enable redux devtools middleware
);

export default ShopApplicationsStore;