class window.AdminTimelines

  @index: ->
    DataTableUtils.datatable $('.datatable'),
      bServerSide: true
      bPaginate: false
      bInfo: false
      aoColumns: [
        {bSortable: true},
        {bSortable: true},
        {bSortable: true},
        {bSortable: false}
      ]
      fnDrawCallback: ->
        $('.best_in_place').best_in_place()

    check_progress = (id) ->
      $.get Routes.check_admin_campaign_timelines_path(id, format: 'json'), (data) ->
        if data.timeline_in_progress
          setTimeout( ->
            check_progress(id)
          , 2500)
        else
          location.reload()

    if $('#in-progress-header').length > 0
      campaign = $('#in-progress-header').data('id')
      check_progress(campaign)