import React from 'react';
import PropTypes from 'prop-types'

export default class FloatingActionButton extends React.Component {
  render() {
    const {
      invertText,
      color,
      right,
      bottom,
      icon
    } = this.props;

    const containerClass = `floating-action-button ${invertText ? 'invert' : ''} ${color || ''}`;
    const iconClass = `fab-icon fas fa-${icon}`;
    const positioning = { right, bottom };

    return <div style={positioning} className={containerClass} onClick={this.props.onClick}>
      <i className={iconClass}/>
    </div>
  }
}

FloatingActionButton.propTypes = {
  icon: PropTypes.string.isRequired,
  invertText: PropTypes.bool,
  right: PropTypes.string.isRequired,
  bottom: PropTypes.string.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired
};
