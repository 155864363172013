import React from 'react';
import ShoppertunitiesIndexApplyAlert from './IndexApplyAlert';

export default class ShoppertunitiesShowApplyAlert extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.showShopClosed = this.showShopClosed.bind(this);
    this.showShopApplication = this.showShopApplication.bind(this);
    this.shopApplicationExist = this.shopApplicationExist.bind(this);
    this.showNotEligible = this.showNotEligible.bind(this);

  }

  showShopClosed(){
    const { I18n } = this.props;
    if(this.props.shopState === 'closed' || !this.props.shopOpen){
      return <div>
        <div className="alert alert-warning mt-10">
          <i className="fas fa-exclamation-triangle fa-fw"/>
          {I18n.alerts.shop_closed}
        </div>
      </div>
    }
    return null;
  }

  shopApplicationExist(){
    const { I18n } = this.props;
    const shopPath = `/opportunities/${this.props.shoppertunityId}/application`;
    const messageLink = `${this.props.newConversationPath}?recipient_ids=${this.props.shopCancellationUser ? this.props.shopCancellationUser.id : ''}`;
    const cancelerName = this.props.shopCancellationUser ? this.props.shopCancellationUser.full_name : '';

    if(this.props.shopState === 'canceled'){
      if(this.props.shopCancellationUser){
        return <div className="alert alert-info mt-10">
            <i className="fas fa-info-circle fa-fw"/>
            {I18n.alerts.shop_cancelled} by <a href={messageLink}>{cancelerName}</a>
          </div>
      }else{
        return <div className="alert alert-info mt-10">
          <i className="fas fa-info-circle fa-fw"/>
          {I18n.alerts.shop_cancelled}.
        </div>
      }
    } else {
        return <div className="alert alert-info mt-10">
          <i className="fas fa-info-circle fa-fw"/>
          {I18n.alerts.already_applied} <a href={shopPath}>{I18n.alerts.view_application}</a>
        </div>
    }
  }

  showShopApplication(){
    const { I18n } = this.props;
    if(this.props.shopState === 'draft'){
      return <div className="alert alert-info mt-10">
        <i className="fas fa-info-circle fa-fw">
          {I18n.alerts.draft_application}
          <a href={this.props.editShopPath}>{I18n.alerts.complete_application}</a>
        </i>
      </div>
    }
    else if(this.props.shopApplicationExists){
      return this.shopApplicationExist();
    }
    return null;
  }

  showNotEligible(){
    const { I18n } = this.props;
    if(!this.props.profileEligible && !this.props.noConflictingShops){
      return <div className="alert alert-info mt-10">
        <i className="fas fa-exclamation-triangle fa-fw"/>
        {I18n.alerts.sorry_limited_profiles}
        <a className="btn btn-default btn-xs" style={{marginLeft: '10px'}} href="/opportunities">{I18n.alerts.see_open_shops}</a>
      </div>
    }
    return null;
  }

  render() {
    return (
      <div className="container">
        <ShoppertunitiesIndexApplyAlert {...this.props}/>
        { this.props.action === 'show' && <div>
          {this.showShopClosed()}
          {this.showShopApplication()}
          {this.showNotEligible()}
        </div>
        }
      </div>
    );
  }
};

ShoppertunitiesShowApplyAlert.displayName = 'Shoppertunities.ShowApplyAlert';
