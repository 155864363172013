import React from 'react';
import InfluencerDashboardHomeSuggestedShops from './home/SuggestedShops'
import InfluencerDashboardHomeUpcomingTaskList from './home/UpcomingTaskList'
export default class InfluencerDashboardHome extends React.Component {
  render() {
    return <div>
      <InfluencerDashboardHomeSuggestedShops />
      <InfluencerDashboardHomeUpcomingTaskList />
    </div>;
  }
};

InfluencerDashboardHome.displayName = 'InfluencerDashboard.Home';