import React from 'react';
import { Remote } from '../utils';

import SaveIndicator from '../SaveIndicator';
import SaveError from '../SaveError';
import CampaignsTimeline from './Timeline';

export default class CampaignsTimelines extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timelines: this.props.timelines || [],
      saving: false,
      saveType: 'info',
      saveText: '',
      saveErrors: null
    }

    this.addTimeline = this.addTimeline.bind(this);
    this.removeTimeline = this.removeTimeline.bind(this);
    this.render = this.render.bind(this);
    this.saveIndicatorState = this.saveIndicatorState.bind(this);
    this.flash = this.flash.bind(this);
  }

  addTimeline(){
    this.saveIndicatorState(true, 'info', 'Adding timeline...', null);

    let newDate = new Date();
    let mm = newDate.getMonth() + 1;
    let dd = newDate.getDate();
    let yyyy = newDate.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    let today = `${yyyy}-${mm}-${dd}`;

    const _this = this;
    const removeTimelinePath = `/admin/campaigns/${_this.props.campaignId}/add_timeline`
    Remote().request({
      url: removeTimelinePath,
      method: 'POST',
      data: {
        name: 'Timeline Name',
        due_date: today,
        campaign_id: this.props.campaignId
      },
      contentType: 'application/json',

    }).then((response) => {
      let currentTimelines = this.state.timelines;
      currentTimelines.push(response.data);
      this.setState({timelines: currentTimelines});
      this.flash({
        saveText: 'Successfully added timeline.',
        saveType: 'success'
      }, 3000);
    }).catch((error) => {
      this.flash({
        saveText: 'Failed to add timeline! Please correct these errors:',
        saveType: 'failure',
        saveErrors: error
      }, 5000);
    });
  }

  removeTimeline(timelineId){
    this.saveIndicatorState(true, 'info', 'Removing timeline...', null);

    const _this = this;
    const removeTimelinePath = `/admin/campaigns/${_this.props.campaignId}/remove_timeline`
    Remote().request({
      url: removeTimelinePath,
      method: 'POST',
      data: {timeline_id: timelineId},
    }).then(() => {
      const currentTimelines = _.filter(_this.state.timelines, (timeline) => timeline.id != timelineId)
      _this.setState({timelines: currentTimelines})
      this.flash({
        saveText: 'Successfully removed timeline.',
        saveType: 'success'
      }, 3000)
    }).catch((error) => {
      this.flash({
        saveText: 'Failed to remove timeline! Please correct these errors:',
        saveType: 'failure',
        saveErrors: error
      }, 5000);
    });
  }

  saveIndicatorState(saving, saveType, saveText, saveErrors){
    this.setState({
      saving: saving,
      saveType: saveType,
      saveText: saveText,
      saveErrors: saveErrors
    });
  }

  flash(newState, duration) {
    this.setState(newState, () => setTimeout(() => this.setState({
      saving: false
    }), duration));
  }

  render() {
    const eventStyle = {
      width: '35%',
      paddingLeft: '0'
    }

    const notesStyle = {
      width: '55%',
      paddingRight: '0'
    }
    const dateStyle = {
      width: '9%'
    }
    const actionStyle = {
      width: '1%'
    }

    return (
      <div>
        <legend>Timeline</legend>
        <div className="row">
            <div className="col-xs-12">
                <a className="btn btn-xs btn-primary" onClick={() => this.addTimeline()}>+ Add Timeline Event</a>
                {!this.state.timelines && <p className="js-no-timelines-msg">There are currently no timeline events for this campaign. Click '+Add Timeline Event' to get started.</p>}
            </div>
        </div>
        <table className="js-form-timeline table table-form">
            <thead>
            <tr>
                <th style={eventStyle}>Event</th>
                <th style={notesStyle}>Notes</th>
                <th style={dateStyle}>Date</th>
                <th style={actionStyle}></th>
            </tr>
            </thead>
            <tbody>
                {this.state.timelines.map((timeline) => <CampaignsTimeline timeline={timeline}
                                                                            campaignId={this.props.campaignId}
                                                                            removeTimeline={this.removeTimeline}
                                                                            saveIndicatorState={this.saveIndicatorState}
                                                                            flash={this.flash}/>
                                                                            )}
            </tbody>
        </table>
        {
          this.state.saving &&
          <SaveIndicator type={this.state.saveType}>
            {this.state.saveText}
            {this.state.saveErrors && <SaveError errors={this.state.saveErrors}/>}
          </SaveIndicator>
        }
      </div>
    );
  }
}
