import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash'
import SubmissionStateIndicator from './StateIndicator'

class SubmissionYoutubeContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: false
    };

    this.validationState = this.validationState.bind(this);
    this.setPlayingState = this.setPlayingState.bind(this);
    this.loadVideo = this.loadVideo.bind(this);
    this.onPlayerReady = this.onPlayerReady.bind(this);
    this.onPlayerStateChange = this.onPlayerStateChange.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.renderVideoPlayer = this.renderVideoPlayer.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
  }

  render() {
    const { post, selected } = this.props;
    const isSelected = selected.some(content => content.id === post.id)
    const state = this.validationState();

    return (
      <div className={`submission grid-item instagram-story ${state} ${isSelected ? 'selected' : ''}`}
           onClick={(e) => this.clickHandler(e, isSelected)}>
        <div className='item-image' style={{backgroundImage: `url(https://img.youtube.com/vi/${post.youtube_id}/maxresdefault.jpg)`}}>
          <SubmissionStateIndicator state={state} isSelected={isSelected} isVideo={true} setPlayingState={this.setPlayingState}/>
        </div>
        { this.renderVideoPlayer() }
      </div>
    )
  }

  renderVideoPlayer() {
    return (
      <div className='video' id={`video-${this.props.post.youtube_id}`}></div>
    )
  }

  validationState() {
    const { playing } = this.state;
    const { validations } = this.props;

    if ( validations && validations.length ) {
      if ( _.some(validations, {state: 'failed'}) ) {
        return 'failed';
      } else {
        return 'passed';
      }
    } else {
      if (playing) {
        return 'playing';
      } else {
        return 'pending';
      }
    }
  }

  setPlayingState(e, play) {
    e.preventDefault();
    e.stopPropagation();

    if (!this.state.player) {
      this.loadVideo();
    } else {
      if (play) {
        this.state.player.playVideo();
      } else {
        this.state.player.stopVideo();
      }
    }
  }

  loadVideo() {
    const { youtube_id } = this.props.post;

    let player = new window.YT.Player(`video-${youtube_id}`, {
      videoId: youtube_id,
      events: {
        onReady: this.onPlayerReady,
        onStateChange: this.onPlayerStateChange,
        playerVars: {
          modestbranding: 1,
          playsinline: 0,
          rel: 0,
        }
      }
    });
  };

  onPlayerReady(event) {
    this.setState({ player: event.target });
    this.state.player.playVideo();
  };

  onPlayerStateChange(event) {
    if (event.data == YT.PlayerState.PLAYING || event.data == YT.PlayerState.BUFFERING) {
      this.setState({ playing: true });
    }

    if (event.data == YT.PlayerState.PAUSED) {
      this.setState({ playing: false });
    }
  }

  clickHandler(e, isSelected) {
    e.preventDefault();
    const { post } = this.props;

    if ( isSelected ) {
      this.props.deselect(post);
      this.props.resetSubmission();
      this.props.resetValidations();
    } else {
      this.props.select(post);
      this.props.updateSubmission({ url: post.url });
      this.props.resetValidations();
      this.props.validate();
    }
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      selected: state.selected,
      validations: state.validations
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      select: post => dispatch({ type: 'SELECT_SINGLE_CONTENT', value: post }),
      deselect: post => dispatch({ type: 'DESELECT_CONTENT', value: post }),
      resetSubmission: () => dispatch({ type: 'RESET_SUBMISSION' }),
      updateSubmission: params => dispatch({ type: 'UPDATE_SUBMISSION', value: params }),
      resetValidations: () => dispatch({ type: 'RESET_VALIDATIONS' }),
    };
  }
};

SubmissionYoutubeContent.displayName = 'Submission.YoutubeContent';
export default connect(
  SubmissionYoutubeContent.mapStateToProps,
  SubmissionYoutubeContent.mapDispatchToProps
)(SubmissionYoutubeContent);


