import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Remote } from '../utils';
import LoadingSpinner from '../LoadingSpinner'
import RoundTasksCommunitySelector from '../round_tasks/CommunitySelector'
import TableRow from '../table/Row'
import TableDeleteConfirmation from '../table/DeleteConfirmation'

class RoundTasksTable extends React.Component {
  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.loadCommunities = this.loadCommunities.bind(this);
    this.loadTasks = this.loadTasks.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    this.loadTasks();
    this.loadCommunities();

    // Initialize the state store with what we have as props
    this.props.updateMeta({
      baseUrl: this.props.baseUrl,
      proxyUrl: this.props.proxyUrl,
      currentUserId: this.props.currentUserId,
      selectedCommunityId: this.props.selectedCommunityId
    });
  }

  componentDidUpdate(prevProps) {
    if(this.props.selectedCommunityId != prevProps.selectedCommunityId) {
      this.loadTasks();
    }
  }

  render() {
    return (
      <div>
        <RoundTasksCommunitySelector type='Task'/>
        <table className='table table-striped'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Available Content Type(s)</th>
              <th>Submission Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {!this.props.loading && this.props.roundTasks.map(rt => <TableRow key={rt.id} roundTask={rt}/>)}
          </tbody>
        </table>
        {this.props.loading && <LoadingSpinner />}
        <TableDeleteConfirmation />
      </div>
    );
  }

  loadTasks() {
    this.props.startLoading();

    const params = $.param({
      api_action: `/round_tasks`,
      template_type: 'community',
      community_id: [this.props.selectedCommunityId]
    });

    Remote().request({
      method: 'GET',
      url: this.props.proxyUrl + '?' + params
    }).then(data => {
      this.props.updateTasks(data.active);
      this.props.stopLoading();
    });
  }

  loadCommunities() {
    const params = $.param({
      api_action: `/users/${this.props.currentUserId}/communities`
    });

    Remote().request({
      method: 'GET',
      url: this.props.proxyUrl + '?' + params
    }).then(data => {
      this.props.updateCommunities(data);
      this.props.stopCommunityLoading();
    });
  }

  static mapStateToProps(state, myProps) {
    return {
      ...state,
      ...myProps,

      // Prefer the selected community over the default passed in
      selectedCommunityId: state.selectedCommunityId || myProps.selectedCommunityId
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateTasks: tasks => dispatch({ type: 'UPDATE_ROUND_TASKS', value: tasks }),
      startLoading: () => dispatch({ type: 'START_LOADING' }),
      stopLoading: () => dispatch({ type: 'STOP_LOADING' }),
      stopCommunityLoading: () => dispatch({ type: 'STOP_COMMUNITIES_LOADING' }),
      updateMeta: data => dispatch({ type: 'UPDATE_META', value: data }),
      updateCommunities: communities => dispatch({ type: 'UPDATE_COMMUNITIES', value: communities })
    };
  }
};

RoundTasksTable.displayName = 'RoundTasks.Table';
RoundTasksTable.propTypes = {
  proxyUrl: PropTypes.string.isRequired,
  taskType: PropTypes.string.isRequired
};

export default connect(
  RoundTasksTable.mapStateToProps,
  RoundTasksTable.mapDispatchToProps
)(RoundTasksTable);
