import React from 'react';
import RichTextEditor from '../../RichTextEditor'
import RoundTasksSelect from '../../round_tasks/inputs/Select'

export default class GeneralRequirementsForm extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  render() {
    return (
      <form className='form'>
        <div className='input_wrap'>
          <abbr title='required'>*</abbr>
          <label>Name</label>
          <input
            type='text'
            onChange={e => this.props.updateField('name', e.target.value)}
            placeholder='Enter Name'
            className='form-control'
            value={this.props.roundTask.name || ''}
            />
        </div>
        <div className='input_wrap'>
          <label>Description</label>
          <RichTextEditor
            placeholder=''
            value={this.props.roundTask.description || ''}
            onUpdate={(html) => this.props.updateField('description', html)}/>
        </div>
        <div className='input_wrap'>
          <abbr title='required'>*</abbr>
          <label htmlFor='community'>Community</label>
          <RoundTasksSelect
            name='community_id'
            updateField={this.props.updateField}
            index={null}
            required={true}
            placeholder='Select Community'
            options={this.props.communities}
            value={this.props.roundTask.community_id || []}
            disabled={false}
            multiSelect={false}
          />
         </div>
      </form>
    );
  }
}

GeneralRequirementsForm.displayName = 'GeneralRequirements.Form';
