import React from 'react';
import Cropper from 'cropper.min.js';

export default class PostingImageEditor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      cropper: null,
      imageRef: React.createRef(),
      preview_url: props.preview_url,
      file: null,
      editing: false,
      suggest: false,
      currentAspect: 0,
      width: 0,
      height: 0,
      showDimensions: false,
    }
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.rotateClockwise = this.rotateClockwise.bind(this);
    this.roteAntiClockWise = this.roteAntiClockWise.bind(this);
    this.resetEdits = this.resetEdits.bind(this);
    this.saveImage = this.saveImage.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.toggleEditing = this.toggleEditing.bind(this);
    this.calculateAspect = this.calculateAspect.bind(this);
    this.toggleDimensionDisplay = this.toggleDimensionDisplay.bind(this);
  }

  componentDidMount() {
    const {preview_url} = this.state;
    if (!!preview_url) {
      this.calculateAspect();
    }
    const _this = this;
    window.addEventListener('resize', () => {
      if (!!_this.state.preview_url) {
        _this.calculateAspect();
      }
    })
  }

  toggleDimensionDisplay() {
    const {showDimensions} = this.state;
    this.setState({showDimensions: !showDimensions});
  }


  calculateAspect() {
    const image = this.state.imageRef.current;
    const {width, height} = image.getBoundingClientRect();
    const currentAspect = Math.round((width / (height * 1.0) * 100)) / 100.0;
    const suggest = (currentAspect > 1.7 || currentAspect < 1.6);
    this.setState({currentAspect, suggest, width, height});
  }

  cropper(element) {
    return new Cropper(element, {
      aspectRatio: PostingImageEditor.ASPECT_RATIO,
      checkCrossOrigin: false,
      responsive: true,
      autoCropArea: 1
    })
  }

  initEditor() {
    const {imageRef} = this.state;
    this.destroyPreviousEditor();
    const image = imageRef.current;
    this.setState({cropper: this.cropper(image)});
  }

  destroyPreviousEditor() {
    const {cropper} = this.state;
    if (!cropper) {
      return;
    }
    cropper.destroy();
    this.setState({cropper: null})
  }

  zoomIn() {
    this.state.cropper.zoom(PostingImageEditor.ZOOM_RATIO);
  }

  zoomOut() {
    this.state.cropper.zoom(-1 * PostingImageEditor.ZOOM_RATIO);
  }

  rotateClockwise() {
    this.state.cropper.rotate(PostingImageEditor.ROTATION_ANGLE);
  }

  roteAntiClockWise() {
    this.state.cropper.rotate(-1 * PostingImageEditor.ROTATION_ANGLE);
  }

  resetEdits() {
    this.state.cropper.reset();
  }

  saveImage() {
    const that = this;
    const canvas = this.state.cropper.getCroppedCanvas();
    canvas.toBlob((blob) => {
      const time = new Date();
      const lastModifiedDate = time
      const name = `edit-${time.getTime()}.png`
      const file = new File([blob], name, {
        lastModified: lastModifiedDate
      });
      that.onFileChange(file);
    }, 'image/png', 1.0);

  }

  onFileChange(file) {
    this.setState({file}, () => {
      this.generatePreview();
      this.props.onChange(file);
    })
  }

  generatePreview() {
    const that = this;
    const reader = new FileReader();
    reader.onload = function(event) {
      that.destroyPreviousEditor();
      const preview_url = event.target.result;
      that.setState({editing: false, preview_url});
    }
    reader.readAsDataURL(this.state.file);
  }

  startEditing() {
    this.setState({editing: true});
    this.initEditor();
  }

  toggleEditing() {
    const {editing} = this.state;
    this.setState({editing: !editing});
    if (editing) {
      this.destroyPreviousEditor();
    } else {
      this.initEditor();
    }
  }

  renderAspectSuggestion() {
    const {suggest, editing, currentAspect} = this.state;
    return (!editing && suggest && currentAspect > 0) ? (
      <React.Fragment>
        <div className="editor-aspect-suggestion">
          <button className="notice-icon" onFocus={this.toggleDimensionDisplay} onBlur={this.toggleDimensionDisplay}><i className="fas fa-info"></i></button>
          <p>
            current aspect(<strong>{currentAspect}</strong>) of the image does not match the standard(<strong>{PostingImageEditor.ASPECT_RATIO}</strong>)
          </p>
        </div>
        <hr/>
      </React.Fragment>
    ) : null
  }

  renderImageDimensions(width, height, showDimensions, currentAspect) {
    const roundedWidth = Math.round(width);
    const roundedHeight = Math.round(height);
    return (
      <React.Fragment>
        <div className="aspect-width" style={{ opacity: (showDimensions ? '1' : '0'), width }}>{roundedWidth}px</div>
        <div className="aspect-height" style={{ opacity: (showDimensions ? '1' : '0'), width: height }}>{roundedHeight}px</div>
        <div className="aspect-calculation" style={{opacity: (showDimensions ? '1' : '0')}}>
          <p><small>aspect = width / height</small></p>
          <p><small>{roundedWidth} / {(roundedHeight)} = {currentAspect}</small></p>
        </div>
      </React.Fragment>
    )
  }

  renderFileButton(preview_url) {
    const ref = React.createRef();
    const btnClick = () => {
      ref.current.click();
    }
    return (
      <button className="btn primary" onClick={btnClick}>
        <i className="fas fa-file"></i>
        {!!preview_url ? 'Change' : 'Choose a'} File
        <input type="file" ref={ref} accept="image/*" onChange={(event) => { this.onFileChange(event.target.files[0]) }} style={{display: 'none'}}/>
      </button>
    )
  }


  render() {

    const {preview_url, editing, imageRef, width, height, showDimensions, currentAspect} = this.state;
    return (
      <div className="posting-editor container">
        <div className="row">
          <div className="col-md-8" style={{display: 'flex', alignItems: 'baseline', flexDirection: 'column'}}>
            <label>{this.props.label || 'Image:'}</label>
            {this.renderFileButton(preview_url)}
            <div className="image-editor-card">
              {!!preview_url && (this.renderImageDimensions(width, height, showDimensions, currentAspect))}
              <img src={preview_url} ref={imageRef} onLoad={this.calculateAspect}/>
              {!!preview_url && <hr/>}
              {this.renderAspectSuggestion()}
              {!!preview_url && (<div className="editor-toolbar">
                {!editing && (
                  <button className="editor-toolbar-item square" onClick={this.toggleEditing} style={{padding: 5}}>
                    <i className="fas fa-edit"></i>&nbsp;Edit
                  </button>) }
                {editing && (
                  <React.Fragment>
                    <div style={{display: 'flex'}}>
                      <button className="editor-toolbar-item square" onClick={this.saveImage}>
                        <i className="fas fa-save"></i>&nbsp;Save Edits
                      </button>
                      <button className="editor-toolbar-item square" onClick={this.toggleEditing}>
                        <i className="fas fa-times"></i>&nbsp;Close Editor
                      </button>
                    </div>
                    <button className="editor-toolbar-item" title="zoom in" onClick={this.zoomIn}>
                      <i className="fas fa-search-plus"></i>
                    </button>
                    <button className="editor-toolbar-item" title="zoom out" onClick={this.zoomOut}>
                      <i className="fas fa-search-minus"></i>
                    </button>
                    <button className="editor-toolbar-item" title="rotate 90° clockwise" onClick={this.rotateClockwise}>
                      <i className="fas fa-undo"></i>
                    </button>
                    <button className="editor-toolbar-item" title="rotate 90° anti-clockwise" onClick={this.roteAntiClockWise}>
                      <i className="fas fa-redo"></i>
                    </button>
                    <button className="editor-toolbar-item" title="reset edits" onClick={this.resetEdits}>
                      <i className="fas fa-sync"></i>
                    </button>

                  </React.Fragment>
                )}
              </div>)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
PostingImageEditor.ASPECT_RATIO = 1.67;
PostingImageEditor.ZOOM_RATIO = 0.1;
PostingImageEditor.ROTATION_ANGLE = 90;
PostingImageEditor.displayName = 'Posting.ImageEditor'