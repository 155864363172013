import React from 'react';
import { Remote } from '../utils';

import PropTypes from 'prop-types'

export default class CommunityLoginsCodeEntry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      approving: false
    };

    this.checkCode = this.checkCode.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.render = this.render.bind(this);
    this.successTransition = this.successTransition.bind(this);

    this.debouncedCheckCode = _.debounce(this.checkCode, 250);
  }

  checkCode(code) {
    Remote().request({
      url: '/community_logins/check',
      method: 'POST',
      data: {
        community_id: this.props.communityId,
        code: code
      },
    }).then((response) => {
      this.successTransition(code);
    }).catch((error) => {
      this.props.setValid(false);
      window.localStorage.removeItem('sf_community_code');
      window.localStorage.removeItem('sf_community_id');
    });
  }

  successTransition(code) {
    this.setState({ approving: true });
    window.setTimeout(() => {
      this.props.setValid(true);
      window.localStorage.setItem('sf_community_code', code);
      window.localStorage.setItem('sf_community_id', this.props.communityId);
    }, 2000);
  }

  handleChange(e) {
    this.props.setCode(e.target.value);
    this.debouncedCheckCode(e.target.value);
  }

  render() {
    if(this.state.approving) {
      return <div className='community-logins code-entry code-success'>
        <span className='contents'>
          Community Unlocked <i className='fas fa-unlock-alt'/>
        </span>
      </div>
    }

    return <div className='community-logins code-entry'>
      <input
        className='code-field'
        type='text'
        onChange={this.handleChange}
        value={this.props.code || ''}
        placeholder='Enter Code'
        disabled={this.props.preview || false}
        />
    </div>;
  }
}

CommunityLoginsCodeEntry.displayName = 'CommunityLogins.CodeEntry';
CommunityLoginsCodeEntry.propTypes = {
  setCode: PropTypes.func.isRequired,
  setValid: PropTypes.func.isRequired,
  code: PropTypes.string,
  communityId: PropTypes.number.isRequired,
  preview: PropTypes.bool
};
