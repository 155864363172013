import React from 'react';
var TwitterAuthButton = createReactClass({

  render: function () {

    return (
      <span className='promo-auth'>
        <a href={this.props.url} className='btn btn-default authorize t-transform-none'>
          <i className='fab fa-twitter fa-fw' />
          Connect to Twitter
        </a>
        <span>
          Minimum {this.props.follower_requirements} followers
        </span>
      </span>
    );
  }
});
