import React from 'react';
export default class DatatableHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortDir: 'desc',
      hover: false
    }

    this.render = this.render.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip({html: true});
  }

  renderDisplayName(text, tooltip) {
    if (!tooltip)
      return text
    else 
      return (
        <a href="#" data-toggle="tooltip" data-placement="right" title={tooltip} style={{color: '#333'}}>
          {text}
        </a>
      )
  }

  render() {
    const column = this.props.column;
    const width = column.width || '100px';
    const style = {
      width: width,
    };

    return (
      <th style={style} 
          data-sortable={column.sortable} 
          data-sortdir={this.state.sortDir} 
          data-sorting={this.props.sortIndex === column.index} 
          data-tooltip={column.tooltip} 
          onClick={() => this.handleClick()}
      >
        { this.renderDisplayName(column.displayName, column.tooltip) }
      </th>
    );
  }

  handleClick() {
    const column = this.props.column;
    let newSortDir = this.state.sortDir === 'desc' ? 'asc' : 'desc';

    if (column.sortable) {
      this.setState({ sortDir: newSortDir }, () => {
        this.props.handleColumnSort(column, newSortDir)
      }) 
    }
  }
}
