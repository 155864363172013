class window.AdminSystemMessages

  @new: ->
    @form()

  @create: ->
    @form()

  @edit: ->
    @form()

  @update: ->
    @form()

  @form: ->
    $('#system_message_body').redactor
      plugins: plugin_list
