import React from 'react';

export default class GroupMembership extends React.Component {
  render(){
    const {activity, group_name, user_name} = this.props.notification;
    const P = React.Fragment;
    switch(activity) {
      case 'added':
        return (
          <P>You have been added to the <b>{group_name}</b> campaign.</P>
        )
      case 'joined':
        return (
          <P><b>{user_name}</b> joined the <b>{group_name}</b> Group.</P>
        )
      default:
        return null;
    }
  }
}
GroupMembership.displayName = 'Notification.Templates.GroupMembership'