import React from 'react';
import LivingStyleGuideColorSwatch from './ColorSwatch'


const solidColors = [
  {color: '$--sc-blue',hex: '#4E76E3', backgroundClass: 'background-color-blue'},
  {color: '$--sc-purple', hex: '#8E6BE8', backgroundClass: 'background-color-purple'},
  {color: '$--sc-teal', hex: '#A9E4FF', backgroundClass: 'background-color-teal'},
  {color: '$--sc-green', hex: '#5DC374', backgroundClass: 'background-color-green'},
  {color: '$--sc-red', hex: '#EC5A68', backgroundClass: 'background-color-red'},
  {color: '$--sc-orange', hex: '#F5B055', backgroundClass: 'background-color-orange'},
  {color: '$--sc-gray', hex: '#3D425D', backgroundClass: 'background-color-gray'},
  {color: '$--sc-sub-gray', hex: '#8D8EA5', backgroundClass: 'background-color-subdued'},
  {color: '$--sc-background', hex: '#F1F6FC', backgroundClass: 'background-color-body'},
  {color: '$--sc-white', hex: '#FFFFFF', backgroundClass: 'background-color-white'},
  {color: '$--sc-black', hex: '#000000', backgroundClass: 'background-color-black'},
  {color: '$--sc-link', hex: '#1EAEDB', backgroundClass: 'background-color-link' }
];
const fadedColors = [
  {color: '$--fc-blue',hex: '#CCE5FF', backgroundClass: 'background-color-faded-blue'},
  {color: '$--fc-purple', hex: '#DDD1FD', backgroundClass: 'background-color-faded-purple'},
  {color: '$--fc-teal', hex: '#D1ECF1', backgroundClass: 'background-color-faded-teal'},
  {color: '$--fc-green', hex: '#DEFFEF', backgroundClass: 'background-color-faded-green'},
  {color: '$--fc-red', hex: '#F8D7DA', backgroundClass: 'background-color-faded-red'},
  {color: '$--fc-orange', hex: '#FFF3CD', backgroundClass: 'background-color-faded-orange'},
  {color: '$--fc-gray', hex: '#D7D8DD', backgroundClass: 'background-color-faded-gray'},
  {color: '$--fc-sub-gray', hex: '#E7E8EA', backgroundClass: 'background-color-faded-subdued'},
  {color: '$--fc-background', hex: '#FCFEFF', backgroundClass: 'background-color-faded-body'},
]
const gradientColors = [
  { color: '$--gradient-blue-purple', backgroundClass: 'background-grad-blue-purple'},
  { color: '$--gradient-green-teal', backgroundClass: 'background-grad-green-teal'},
  { color: '$--gradient-orange-red', backgroundClass: 'background-grad-orange-red'},
  { color: '$--gradient-grey', backgroundClass: 'background-grad-gray'}
]

export default class LivingStyleGuideColors extends React.Component {

  render() {
    return (
      <div id='colors' className='style-guide-section'>
        <h1 className='section-header'>Colors</h1>
        <div className='section-description'>
          <p>These guidelines are the framework upon which we have built our system for how color
          is used in the Social Fabric product.</p>
        </div>
        <div className='row'>
          <div className='sub-section column'>
            <h3 className='sub-section-header'>Communication Over Decoration</h3>
            <hr/>
            <p>Although we value an aesthetically pleasing use of color, we place a higher value on clear communication. Our use of color should be purposeful, rational, and should serve to support the purpose of the content.</p>
          </div>
          <div className='sub-section column'>
            <h3 className='sub-section-header'>Color Should Be Accessible</h3>
            <hr/>
            <p>When choosing colors, consider merchants who are color blind or who have low vision. Never use color alone to convey information.</p>
          </div>
          <div className='sub-section column'>
            <h3 className='sub-section-header'>Color Should Focus Attention</h3>
            <hr/>
            <p>The use of color should help bring attention to what matters most. Color should support the hierarchy of the page. Use restraint.</p>
          </div>
        </div>
        <div className='section-description'>
          <h2 className='sub-header'>Solid Colors</h2>
          <div className='color-swatches-container'>
            {solidColors.map((swatch, i) => (
              <LivingStyleGuideColorSwatch key={i} swatch={swatch} isGradient={false}/>
            ))}
          </div>
        </div>
        <div className='section-description'>
          <h2 className='sub-header'>Faded Colors</h2>
          <div className='color-swatches-container'>
            {fadedColors.map((swatch, i) => (
              <LivingStyleGuideColorSwatch key={i} swatch={swatch} isGradient={false}/>
            ))}
          </div>
        </div>
        <div className='section-description'>
          <h2 className='sub-header'>Gradient Colors</h2>
          <p>
            Gradients are currently limited to use in the subnav and are used to differentiate between audience.
            For example Influencers will see the Blue-Purple gradient, Where a Community Manager would see
            the Orange-Red Gradient.
          </p>
          <div className='color-swatches-container'>
            {gradientColors.map((swatch, i) => (
              <LivingStyleGuideColorSwatch key={i} swatch={swatch} isGradient={true}/>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
LivingStyleGuideColors.displayName = 'LivingStyleGuide.Colors';