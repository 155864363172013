import React from 'react';
import { Remote } from '../utils';
import { connect } from 'react-redux';

import ProgressBar from '../ProgressBar'
import SkeletonCard from '../skeleton/Card'
import NavigationTabsWrapper from '../navigation_tabs/Container'
import SystemAlerts from '../SystemAlerts'
import SystemMessagesAlert from '../SystemMessagesAlert'
import Alert from '../Alert'

import InfluencerDashboardHome from './HomeView'
import InfluencerDashboardEarnings from './EarningsView'
import InfluencerDashboardContent from './ContentView'

class Container extends React.Component {
  constructor(props) {
    super(props);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.render = this.render.bind(this);
    this.currentView = this.currentView.bind(this);
    this.fetchView = this.fetchView.bind(this);
    this.fetching = this.fetching.bind(this);
    this.updateView = this.updateView.bind(this);
    this.getRoute = this.getRoute.bind(this);
  }

  componentDidMount() {
    this.props.updateStore(this.props.init);
    this.fetchView(this.props.view);
  }

  getRoute(view){
    if (view == 'index')
      return '/'
    else
      return `/${view}`
  }

  updateView(view) {
    this.props.setLoading(true);
    if (this.props.view != view) {
      const path = this.getRoute(view);
      const oldPath = window.location.pathname
      const newRoute = String(window.location).replace(oldPath, `/dashboard${path}`);
      this.props.setView(view, newRoute);
    }
    this.fetchView(view);
  }

  fetchView(view) {
    if (!view) return

    this.props.fetchingView(view);

    const params = $.param({
      api_action: `influencer_dashboard/${view}`,
      shop_ids: this.props.eligibleShopIds
    });

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'GET',

    }).then((resp) => {
      const data  = resp.data
      if (!data || !data['view']){
        let message = null;
        try {
            message = _.get(JSON.parse(data.responseText), 'message')
        } catch(e) {
            console.log('Failed to parse response:'+JSON.stringify(data));
        }
        this.props.handleError(message || "Unknown View")
      } else {
        this.props.updateView(data.view, data);
      }
    });
  }

  fetching(view=this.props.view) {
    return !!(this.props[view] && this.props[view]['fetching'])
  }

  currentView() {
    const { view } = this.props;

    if(this.fetching(view) && Object.keys(this.props[view]).length < 2) {
      return _.range(3).map(idx => <SkeletonCard key={idx} />);
    }

    switch(view){
      case 'content':
        return <InfluencerDashboardContent {...this.props} />
      case 'earnings':
        return <InfluencerDashboardEarnings {...this.props} />
      }
    return <InfluencerDashboardHome {...this.props} />
  }

  render() {
    const { navigation, title, view, loading } = this.props
    const { hide, message } = this.props.alert
    const { system } = this.props.init

    return (
      <div>
        { (loading && this.fetching(view)) && <ProgressBar /> }
        <NavigationTabsWrapper onClickCallback={this.updateView} activeTab={view || ''} {...navigation} />
        <SystemAlerts alerts={_.get(system, 'alerts', [])} />
        <SystemMessagesAlert alerts={_.get(system, 'messages', [])} />
        {
          !hide && !!message &&
          <Alert type={`error fixed-top`} message={message} callback={this.props.clearError}/>
        }
        <div className='container main-container influencer-dashboard-container'>
          <div className='row'>
            { this.currentView() }
          </div>
        </div>
    </div>
    )
  };

  static mapStateToProps(state, myProps) {
    return {
      ...state,
      view: state.view || myProps.view,
      init: myProps
    };
  };

  static mapDispatchToProps(dispatch) {
    return {
      updateStore: (data) => dispatch({ type: 'UPDATE', value: data }),
      updateView: (view, data) => dispatch({ type: 'UPDATE_VIEW', value: {view: view, data: data}}),
      fetchingView: (view, bool=true) => dispatch({type: 'FETCHING_VIEW', value: { view: view, bool: bool } }),
      updateField: (field, data) => dispatch({ type: 'UPDATE_FIELD', value: { field: field, data: data } }),
      setLoading: (bool) => dispatch({ type: 'UPDATE_FIELD', value: { field: 'loading', data: bool } }),
      setView: (newView, route) => dispatch({ type: 'SET_CURRENT_VIEW', value: { view: newView, route: route}}),
      handleError: (error) => dispatch({ type: 'HANDLE_ERROR', value: error }),
      clearError: () => dispatch({ type: 'CLEAR_ERROR' })
    };
  };
};

Container.displayName = 'InfluencerDashboard.Container';

const InfluencerDashboardContainer = connect(
  Container.mapStateToProps,
  Container.mapDispatchToProps
)(Container);

export default InfluencerDashboardContainer;
