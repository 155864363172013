import React from 'react';
import PropTypes from 'prop-types';

export default class SaveError extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ul>
        {this.props.errors.map(err => <li key={err}>{err}</li>)}
      </ul>
    );
  }
}

SaveError.displayName = 'SaveError'
SaveError.propTypes = {
  errors: PropTypes.any.isRequired
};
