class window.AdminTwitterParties
  @new: ->
    @form()

  @edit: ->
    @form()

  @update: ->
    @form()

  @create: ->
    @form()

  @form: ->
    $('.js-redactor--tp').redactor
      plugins: plugin_list
      toolbarFixed: true
      toolbarFixedTarget: '.js-redactor--tp'

    today = new Date()
    start_date = new Date( today.getFullYear(), today.getMonth(), 1)
    $('.js-datetimepicker').datetimepicker
      format: 'MM dd, yyyy @ H:ii p'
      showMeridian: true
      startDate: start_date
      todayHighlight: true



