import React from 'react';
import { Provider } from "react-redux"
import AccountSettingsStore from '../redux/AccountSettings';
import AccountSettings from './AccountSettings'

export default class UsersAccountSettingsWrapper extends React.Component {
  render() {
    return <Provider store={AccountSettingsStore}>
      <AccountSettings
        userId={this.props.userId}
        proxyUrl={this.props.proxyUrl}
        i18n={this.props.i18n}
        resetPasswordUrl={this.props.resetPasswordUrl}
        />
    </Provider>
  }
};

UsersAccountSettingsWrapper.displayName = 'Users.AccountSettingsWrapper';
