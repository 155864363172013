import React from 'react';
import { connect } from 'react-redux';
import { includes } from 'lodash'
import { Remote } from '../utils';
import Modal from '../Modal'

class SubmissionRemoveButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      displayConfirmation: false
    }

    this.canRemove = this.canRemove.bind(this);
    this.confirmRemoval = this.confirmRemoval.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.removeContent = this.removeContent.bind(this);
  }

  render() {
    const {loading, displayConfirmation} = this.state;
    return (
      <React.Fragment>
        <button
          className='btn danger'
          onClick={this.confirmRemoval}
          disabled={!this.canRemove || loading || this.props.disableRemove}
        >
          Remove Content
        </button>
        { displayConfirmation &&
          <Modal size='md'>
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" onClick={this.closeModal}>&times;</span>
              </button>
            </div>
            <div className="modal-body" ref={el => (this.div = el)}>
              <div className='submission-remove-confirmation'>
                <h3>Are you sure you want to remove the content? This cannot be undone.</h3>
                <div className='submission-remove-confirmation-btns'>
                  <button className='btn secondary' onClick={this.closeModal} >Cancel</button>
                  <button className='btn danger' onClick={() => this.removeContent()}>Remove</button>
                </div>
              </div>
            </div>
          </Modal>
        }
      </React.Fragment>
    );
  }

  confirmRemoval() {
    this.setState({displayConfirmation: true})
  }

  closeModal() {
    this.setState({displayConfirmation: false})
  }

  canRemove() {
    const { content } = this.props;
    // ensure content is only in pending stage
    return (this.props.readOnly && includes(['pending', 'resubmitted'], content.state))
  }

  removeContent() {
    if(!this.canRemove()) {
      return null;
    }

    this.setState({loading: true})

    const {
      contentType,
      content,
      bloggerShopTask
    } = this.props;

    const params = $.param({
      api_action: `/blogger_shop_tasks/${bloggerShopTask.id}/remove_content`
    });

    let data = {
      type: contentType.name,
      id: content.id
    };

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'POST',
      data: data,
    }).then((response) => {
      console.log(response)
      if(response.data.status === 400){
        window.location.href = this.props.shoppertunityPath;
      }
    }).catch(failure => {
      this.setState({loading: false})
      console.log(response)
    });
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      bloggerShopTask: state.bloggerShopTask,
      content: state.bloggerShopTask.content,
      proxyUrl: state.meta.proxyUrl,
      contentType: state.contentType,
      shoppertunityPath: state.meta.shoppertunityPath,
      readOnly: state.readOnly,
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
    };
  }
};

SubmissionRemoveButton.displayName = 'Submission.RemoveButton';
export default connect(
  SubmissionRemoveButton.mapStateToProps,
  SubmissionRemoveButton.mapDispatchToProps
)(SubmissionRemoveButton);
