`import moment from 'moment';`

class window.AdvertiserRequests
  # Hook all elements with click events to act like advertiser buttons
  @init: (selector) ->
    # Format a date to X unit(s) ago
    formatDate = (date) ->
      date = moment(date)
      now = moment(new Date())
      # Get the difference in different formats
      days = now.diff(date, 'days')
      hours = now.diff(date, 'hours')
      minutes = now.diff(date, 'minutes')
      # Cascade the date to find the best format
      if days >= 1 then return "#{days} day(s) ago"
      if hours >= 1 then return "#{hours} hour(s) ago"
      "#{minutes} minute(s) ago"

    # Add a tooltip in a uniform way
    addTooltip = (el, date, prefix) ->
      prefix = prefix || 'Last requested'
      $(el).tooltip
          title: prefix + ' ' + formatDate(date)
          placement: 'left'
          trigger: 'hover'
          container: 'body'

    # Bind tooltips to already requested elements
    $("#{selector}[data-content-state=requested]").each ->
      data = $(this).data()
      addTooltip(this, data.lastRequestedAt)

    # Bind a slightly different tooltip to granted elements
    $("#{selector}[data-content-state=granted]").each ->
      data = $(this).data()
      addTooltip(this, data.lastRequestedAt, 'Granted')

    # Bind a click listener for requesting access
    $(selector).click ->
      $el = $(this)
      context = $el.data()
      # Try to send the state and update the button text
      $.post(context.url)
        .then (data) ->
          $el.text(data.display_state)
          $el.attr('class', 'btn btn-xs btn-block btn-default')
          $el.tooltip('destroy') # Clear out the existing tooltip
          callback = -> addTooltip($el, data.last_requested_at)
          setTimeout(callback, 1000) # Tooltips have an update lag
        .fail (data) ->
          console.log(data)
          $el.tooltip('destroy')
          callback = -> addTooltip($el, new Date(), 'Failed')
          setTimeout(callback, 1000)


