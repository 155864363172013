import React from 'react';

import MultiSelect from '../MultiSelect'

export default class CustomAudienceSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      audiences: []
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.render = this.render.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    $.get(this.props.audiencesEndpoint).then(data => {
      this.setState({
        audiences: data
      });
    });
  }

  render() {
    const {selected, updateAudiences, disabled} = this.props;
    return (
      <div className='form-group'>
        <label>Custom Audiences</label>
        <MultiSelect selected={selected}
                     preload={this.state.audiences.map(x => x.value)}
                     search={this.search}
                     onUpdate={updateAudiences}
                     disabled={disabled}/>
      </div>
    );
  }

  search(text, callback) {
    callback(this.state.audiences);
  }
}
