# authentications/finish_facebook
class window.Authentications
  @closepage: ->
    $("#close-page").click ->
      window.close()

  @finish_facebook: ->
    @closepage()

  @finish_facebook_page: ->
    @closepage()

  @finish_tumblr: ->
    @closepage()

  @influencer_facebook_ads: ->
    FormUtils.social_network_links()
    params = UriUtils.getQueryParams()
    # After the button is clicked, redirect to a relevant shop
    $('#authorize-access').click ->
      destination = '/opportunities'
      if params.shop_id
        destination += "/#{params.shop_id}/tasks"
      window.location.href = destination