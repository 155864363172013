import React from 'react';
export default class AgreementQuestions extends React.Component{
  render(){
    const {questions} = this.props;
    const optionWidth = { width: '12%' };
    return(
      <div className ='table-responsive container-fluid text-center'>
          <table className='table table-bordered table-striped'>
              <thead>
              <tr>
                  <th></th>
                  <th className='text-center' style={optionWidth}>Strongly Disagree</th>
                  <th className='text-center' style={optionWidth}>Disagree</th>
                  <th className='text-center' style={optionWidth}>Neutral</th>
                  <th className='text-center' style={optionWidth}>Agree</th>
                  <th className='text-center' style={optionWidth}>Strongly Agree</th>
              </tr>
              </thead>
              <tbody>
              { questions.map((question) =>
                  <tr key={ question.id }>
                      <th>{ question.description }</th>
                      <td style={optionWidth}>
                          <div className="radio">
                              <input type="radio" value="strongly_disagree" name={'questions['+question.id+']'} />
                          </div>
                      </td>
                      <td style={optionWidth}>
                          <div className="radio">
                              <input type="radio" value="disagree" name={'questions['+question.id+']'} />
                          </div>
                      </td>
                      <td style={optionWidth}>
                          <div className="radio">
                              <input type="radio" value="neutral" name={'questions['+question.id+']'} />
                          </div>
                      </td>
                      <td style={optionWidth}>
                          <div className="radio">
                              <input type="radio" value="agree" name={'questions['+question.id+']'} />
                          </div>
                      </td>
                      <td style={optionWidth}>
                          <div className="radio">
                              <input type="radio" value="strongly_agree" name={'questions['+question.id+']'} required />
                          </div>
                      </td>
                  </tr>
              )}
              </tbody>
          </table>
      </div>
    );
  }
}