import React from 'react';
import NavigationTabsLink from './Link';

export default class NavigationTabsMobile extends React.Component {
  render() {
    const {
      order,
      tabs,
      links,
      activeTab,
      menuName,
      mobileMenuOpen,
      counts,
      onClickCallback,
      errors
    } = this.props;

    const menuState = mobileMenuOpen ? 'open' : 'closed'
    const visibleTabs = order && order.map( (tab) => {
      const title = tabs[tab];
      const link = links[tab];
      const selected = activeTab === tab
      const count = counts[tab];
      const hasError = !!(errors || {})[tab];
      return (
        <li key={tab} >
          <NavigationTabsLink
            tab={tab}
            title={title}
            link={link}
            selected={selected}
            count={count}
            hasError={hasError}
            onClickCallback={onClickCallback}
            />
        </li>
      )
    });

    const formatDisplayName = (s) => {
      if (typeof s !== 'string')
        return '';

      return _.capitalize(s).replace('_', ' ')
    }

    const tabCount = (tab) => {
      const count = counts[tab];
      if (count > 0) {
        return <div className='count-bubble'>{count}</div>
      } else {
        return '';
      }
    }

    return(
      <div className='shoppertunity_nav'>
        <div className={`shoppertunity_nav--mobile-container ${menuState}`} onClick={() => this.props.toggleMenu()}>
          <div className='title'>
            <div className='tab'>
              <span>{ tabs[activeTab] || formatDisplayName(tabs['Basic Information'])}</span>
              {tabCount(activeTab)}
            </div>
            <i className="fas fa-lg fa-chevron-circle-down" />
          </div>
          { mobileMenuOpen &&
            <div className='content'>
              <ul>
                <li>
                  <a className="header">
                    <span>{ menuName }</span>
                    <i className='fas fa-lg fa-chevron-circle-down' />
                  </a>
                </li>
                { visibleTabs }
              </ul>
            </div>
          }
        </div>
      </div>
    );
  }
}
NavigationTabsMobile.displayName = 'NavigationTabs.Mobile';
