import React from 'react';
import { Remote } from '../utils';

export default class PaymentsInstructionsPaypalInformation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paypal_name: this.props.paypal_name || '',
      paypal_email: this.props.paypal_email || '',
      submitting: false,
      emailErrorMessage: '',
      errorMessage: ''
    };

    this.render = this.render.bind(this);
    this.renderTextBox = this.renderTextBox.bind(this);
    this.submitPaypalInformation = this.submitPaypalInformation.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.disableSubmit = this.disableSubmit.bind(this);
  }

  handleChange(e, field){
    this.setState({ [field]:e.target.value }, () => this.validateFields());
  }

  validateFields(){
    if(this.state.paypal_email.match(/[^@]+@[^\.]+\..+/g)){
      this.setState({emailErrorMessage: ''})
    }else{
      this.setState({emailErrorMessage: 'Please enter a valid email address for your PayPal ID.'})
    }
  }

  renderMessage(message, style, icon) {
    const iconClass = `fas fa-${icon}`;
    if(message) {
      return (
        <span style={style}>
          <i className={iconClass}></i> {message}
        </span>
      );
    }
  }

  renderTextBox(field) {
    return (
      <div className='form-group' key={field.fieldName}>
        <label>{field.displayName}</label>
        <input className="form-control" onChange={(e) => this.handleChange(e, field.fieldName)} value={this.state[field.fieldName]}/>
      </div>
    );
  }

  submitPaypalInformation(){
    this.setState({submitting: true});
    Remote().request({
      method: "POST",
      url: `/opportunities/${this.props.shoppertunity_id}/update_paypal`,
      data: {
        profile_id: this.props.profile_id,
        paypal_name: this.state.paypal_name,
        paypal_email: this.state.paypal_email
      },
    }).then((response) => {
      if(response.data) {
        window.location.href = this.props.redirect_url + '#bottom';
        location.reload();
      }
    }).catch((error) => {
      this.setState({submitting: false, errorMessage: 'Failed to submit your paypal information. Please submit your request again.'})
    });
  }

  disableSubmit(){
    return this.state.submitting ||
    this.state.emailErrorMessage ||
    this.state.paypal_email.length == 0 ||
    this.state.paypal_name.length == 0
  }

  render() {
    const buttonStyle = {
      textAlign: 'center'
    }
    const errorStyle = {
      color: '#FF8888'
    };

    return (
      <div>
        <div className='small' style={{ marginBottom: '16px' }}>
          Please include your PayPal ID to ensure payment and
          proceed with accepting Instructions.
        </div>
        <p></p>
        {this.renderTextBox({ fieldName: 'paypal_name', displayName: 'PayPal Name', required: true })}
        {this.renderTextBox({ fieldName: 'paypal_email', displayName: 'PayPal ID', required: true })}
        {this.renderMessage(this.state.emailErrorMessage, errorStyle, 'exclamation-triangle')}
        {this.renderMessage(this.state.errorMessage, errorStyle, 'exclamation-triangle')}
        <div style={buttonStyle}>
          <button className="btn btn-primary" onClick={() => this.submitPaypalInformation()} disabled={this.disableSubmit()} >Submit</button>
        </div>
      </div>
    );
  }
};

PaymentsInstructionsPaypalInformation.displayName = 'Payments.InstructionsPaypalInformation';