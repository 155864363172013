import React from 'react';
import PropTypes from 'prop-types'

export default class UsersCategoryChip extends React.Component {
  render() {
    const { onClick, category } = this.props;

    return <div className='account-settings category-chip' onClick={() => onClick(category)}>
      <i className='fas fa-times'/> {category.name}
    </div>;
  }
};

UsersCategoryChip.displayName = 'Users.CategoryChip';
UsersCategoryChip.propTypes = {
  onClick: PropTypes.func.isRequired,
  category: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};
