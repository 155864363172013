import React from 'react';

export default class CampaignsRequirementsDeletedRequirements extends React.Component {
  constructor(props) {
    super(props);
    
    this.render = this.render.bind(this);
  }

  render(){
    const requirements = this.props.requirements;

    if (requirements && requirements.length > 0) {
      return (
        <div className='deleted_requirements_container'>
          <span className='container_title'>Deleted Requirements:</span>
          <div className='requirements_list'>
            {requirements.filter(req => req.name).map(req => req.name).join(', ')}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

CampaignsRequirementsDeletedRequirements.displayName = 'Campaigns.Requirements.DeletedRequirements';
