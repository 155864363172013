import React from 'react';
import { Remote } from '../utils';
import notif from "javascripts/notifIt.js"
import LoadingSpinner from './LoadingSpinner'
import MultiSelect from '../MultiSelect'

import CustomAudienceSelector from './CustomAudienceSelector'
import DMASelector from './DmaSelector'
import ZipSelector from './ZipSelector'
import RegionSelector from './RegionSelector'
import CountrySelector from './CountrySelector'

export default class TargetingModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      scheduler: {},
      ageMin: 0,
      ageMax: 0,
      genders: [],
      detailedTargeting: {},
      geoTargeting: {},
      locationType: 'countries',
      audiences: [],
      invalidLocationData: false
    }

    this.componentDidMount = this.componentDidMount.bind(this);
    this.copyTargeting = this.copyTargeting.bind(this);
    this.formatDetailedTargeting = this.formatDetailedTargeting.bind(this);
    this.formattedOutput = this.formattedOutput.bind(this);
    this.invalidLocationData = this.invalidLocationData.bind(this);
    this.loadSchedulerData = this.loadSchedulerData.bind(this);
    this.pasteTargeting = this.pasteTargeting.bind(this);
    this.render = this.render.bind(this);
    this.renderLocationEditor = this.renderLocationEditor.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.searchDetailedTargeting = this.searchDetailedTargeting.bind(this);
    this.searchGender = this.searchGender.bind(this);
    this.targetingValues = this.targetingValues.bind(this);
    this.unwrapDetailedTargeting = this.unwrapDetailedTargeting.bind(this);
    this.updateDetailedTargeting = this.updateDetailedTargeting.bind(this);
    this.updateLocationData = this.updateLocationData.bind(this);
    this.validations = this.validations.bind(this);
  }

  componentDidMount() {
    Remote().request({
      url: this.props.schedulerEndpoint(this.props.schedulerId),
      method: 'GET',
    }).then((response) => this.loadSchedulerData(response.data));
  }

  render() {
    const footerStyle = {
      display: 'float',
      justifyContent: 'space-between',
      flexDirection: 'row-reverse'
    };

    if(this.state.loading) {
      return (
        <div className='modal fade in' style={{display: 'block', paddingLeft: '0'}} tabIndex='-1' role='dialog'>
          <div className='modal-dialog modal-lg' role='document'>
            <div className='modal-content wizard-header'>
              <div className='modal-header text-center'>
                <h4 className='pull-left'>Targeting Info</h4>
                <i style={{ cursor: 'pointer' }} className='fas fa-times fa-2x pull-right' onClick={this.props.closeTargeting}/>
              </div>
              <div className='modal-body'>
                <LoadingSpinner loading={true}/>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='modal fade in' style={{overflowY: 'scroll', display: 'block', paddingLeft: '0'}} tabIndex='-1' role='dialog'>
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content wizard-header'>
            <div className='modal-header text-center'>
              <h4 className='pull-left'>Targeting Info</h4>
              <i style={{ cursor: 'pointer' }} className='fas fa-times fa-2x pull-right' onClick={this.props.closeTargeting}/>
            </div>
            <div className='modal-body'>
              {
                !!this.validations().length &&
                <div className='alert alert-danger'>
                  <ul className='list-unstyled'>
                    {this.validations().map((err) => <li key={err}>{err}</li>)}
                  </ul>
                </div>
              }
              {
                !this.validations().length &&
                <div className='alert alert-success'>
                  <i className='fas fa-check'/> This schedule is valid
                </div>
              }

              <div className='form-group'>
                <label>Location Type</label>
                <select className='form-control' value={this.state.locationType} disabled={!this.props.editing} onChange={(e) => this.setState({ invalidLocationData: false, locationType: e.target.value, geoTargeting: { [e.target.value]: [] }})}>
                  <option value='countries'>Country</option>
                  <option value='geo_markets'>DMA</option>
                  <option value='regions'>State</option>
                  <option value='zips'>Zip Code</option>
                </select>
              </div>

              {this.renderLocationEditor()}

              <div className='form-group'>
                <label>Gender</label>
                <MultiSelect search={this.searchGender}
                             selected={this.state.genders}
                             onUpdate={(genders) => this.setState({ genders: genders })}
                             disabled={!this.props.editing}/>
              </div>
              <div className='form-group'>
                <label>Age Range</label>
                <div className='form-inline'>
                  <div className='form-group'>
                    <input className='form-control'
                           value={this.state.ageMin}
                           onChange={(e) => this.setState({ ageMin: e.target.value })}
                           disabled={!this.props.editing}/>
                  </div>
                  &nbsp;to&nbsp;
                  <div className='form-group'>
                    <input className='form-control'
                           value={this.state.ageMax}
                           onChange={(e) => this.setState({ ageMax: e.target.value })}
                           disabled={!this.props.editing}/>
                  </div>
                </div>

                <div className='form-group'>
                  <label>Detailed Targeting</label>
                  <MultiSelect search={this.searchDetailedTargeting}
                               onUpdate={this.updateDetailedTargeting}
                               selected={this.formatDetailedTargeting()}
                               preload={this.formatDetailedTargeting().map(x => ({ name: x.name, value: x }))}
                               disabled={!this.props.editing}/>
                </div>

                <CustomAudienceSelector selected={this.state.audiences}
                                                    updateAudiences={(audiences) => this.setState({ audiences: audiences })}
                                                    audiencesEndpoint={this.props.audiencesEndpoint}
                                                    disabled={!this.props.editing}/>
              </div>
            </div>
            <div className='modal-footer' style={footerStyle}>
              <div className='btn-group' style={{ display: 'inline' }}>
                <button className='btn btn-default' onClick={this.copyTargeting}>Copy</button>
                {
                  this.props.editing &&
                  <button className='btn btn-default' onClick={this.pasteTargeting}>Paste</button>
                }
              </div>
              {
                this.props.editing &&
                <button disabled={!!this.validations().length} className='btn btn-primary' onClick={this.saveChanges}>
                  Save Changes
                </button>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }

  searchGender(text, callback) {
    callback([
      {
        name: 'Male',
        value: '1'
      },
      {
        name: 'Female',
        value: '2'
      }
    ]);
  }

  searchDetailedTargeting(text, callback) {
    if(!text) {
      callback([]);
    }

    Remote().request({
      url: this.props.detailSearchEndpoint(text),
      method: 'GET',
    }).then(response => callback(response.data));
  }

  formatDetailedTargeting() {
    return _.flatten(Object.values(this.state.detailedTargeting)).filter(x => !!x);
  }

  updateDetailedTargeting(list) {
    const unfiltered = {};
    const { detailedTargeting } = this.state;
    this.removeEmptyCategories(list);
    this.state
        .scheduler
        .targeting_categories
        .forEach(cat => unfiltered[cat] = list.filter(value => value.type === cat));

    // this is the javascript equivalent of hash.to_a.select { |arr| arr.second.any? }.to_h
    const filtered = _.fromPairs(_.toPairs(unfiltered).filter(x => x[1].length !== 0));
    this.setState({
      detailedTargeting: filtered
    });
  }

  removeEmptyCategories(list) {
    const {targeting_categories, targeting} = this.state.scheduler;
    const selectedCategories = Array.from(new Set(list.map(k => k.type)));
    const deletes = targeting_categories.filter(k => selectedCategories.indexOf(k)<0);
    deletes.forEach(k => delete targeting[k]);
    this.setState({
      scheduler: {
        ...this.state.scheduler,
        targeting
      }
    });
  }

  unwrapDetailedTargeting(categories, targeting) {
    const result = {};
    categories.forEach(cat => result[cat] = targeting[cat]);

    return result;
  }

  renderLocationEditor() {
    switch(this.state.locationType) {
      case 'geo_markets':
        return <DMASelector targetingValues={this.targetingValues()}
                                        dmasEndpoint={this.props.dmasEndpoint}
                                        invalidLocationData={this.invalidLocationData}
                                        updateLocationData={this.updateLocationData}/>;
      case 'zips':
        return <ZipSelector targetingValues={this.targetingValues()}
                                        invalidLocationData={this.invalidLocationData}
                                        updateLocationData={this.updateLocationData}/>;
      case 'regions':
        return <RegionSelector targetingValues={this.targetingValues()}
                                           invalidLocationData={this.invalidLocationData}
                                           updateLocationData={this.updateLocationData}
                                           regionsEndpoint={this.props.regionsEndpoint}/>;
      case 'countries':
      default:
        return <CountrySelector targetingValues={this.targetingValues()}
                                            invalidLocationData={this.invalidLocationData}
                                            updateLocationData={this.updateLocationData}/>;
    }
  }

  targetingValues() {
    return Object.values(this.state.geoTargeting || {})[0];
  }

  updateLocationData(type, values) {
    this.setState({
      geoTargeting: {
        [type]: values
      },
      invalidLocationData: false
    });
  }

  saveChanges() {
    this.setState({ loading: true });
    Remote().request({
      url: this.props.schedulerEndpoint(this.props.schedulerId),
      data: { targeting: this.formattedOutput() },
      method: 'PATCH'
    }).then(() => {
      this.props.refresh();
      this.props.closeTargeting();
    });
  }

  formattedOutput() {
    return {
      ...this.state.scheduler.targeting,
      ...this.state.detailedTargeting,
      age_min: this.state.ageMin,
      age_max: this.state.ageMax,
      geo_locations: this.state.geoTargeting,
      genders: this.state.genders,
      custom_audiences: this.state.audiences
    }
  }

  copyTargeting() {
    const output = JSON.stringify(this.formattedOutput());
    localStorage.setItem(TargetingModal.STORAGE_KEY, output);

    notif({
      msg: 'Copied targeting to clipboard',
      timeout: 3000,
      type: 'notice'
    });
  }

  pasteTargeting() {
    try {
      const requiredKeys = ['age_min', 'age_max', 'geo_locations', 'genders'];
      const input = localStorage.getItem(TargetingModal.STORAGE_KEY);
      const targeting = JSON.parse(input);

      if(requiredKeys.some(key => !Object.keys(targeting).includes(key))) {
        throw 'Invalid targeting object';
      }

      this.loadSchedulerData(this.state.scheduler, targeting);
      notif({
        msg: 'Succesfully pasted targeting data',
        timeout: 3000,
        type: 'notice'
      });
    } catch(ex) {
      notif({
        msg: 'It looks like there is no valid targeting data in the clipboard. Make sure you copy from a recent scheduler to ensure validity.',
        timeout: 3000,
        type: 'error'
      });
    }
  }

  loadSchedulerData(data, targeting) {
    const target = targeting || data.targeting;

    this.setState({
      scheduler: data,
      loading: false,
      ageMin: target.age_min,
      ageMax: target.age_max,
      genders: target.genders,
      audiences: target.custom_audiences,
      detailedTargeting: this.unwrapDetailedTargeting(data.targeting_categories, target),
      geoTargeting: target.geo_locations,
      locationType: Object.keys(target.geo_locations || {})[0]
    });
  }

  invalidLocationData() {
    this.setState({
      invalidLocationData: true
    });
  }

  validations() {
    const errors = [];

    if(this.state.invalidLocationData) {
      errors.push('Invalid location data.');
    }

    if(!this.state.geoTargeting[this.state.locationType] || !this.state.geoTargeting[this.state.locationType].length) {
      errors.push('Geo targeting is required.');
    }

    if(!this.state.genders.length) {
      errors.push('Please select at least one gender.');
    }

    if(!this.state.ageMin || !this.state.ageMax) {
      errors.push('Age min and max are required');
    }

    if(Number(this.state.ageMin) < 18) {
      errors.push('The minimum age must be at least 18.');
    }

    if(Number(this.state.ageMin) >= Number(this.state.ageMax)) {
      errors.push('Maximum age must be greater than the minimum age.');
    }

    return errors;
  }
}

TargetingModal.STORAGE_KEY = 'promoters-custom-targeting';

