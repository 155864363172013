class window.AdminFeeds

  @index: ->
    DataTableUtils.datatable $('.datatable'),
      order: []
      columnDefs: [ {
        targets: [ 0, 4 ] # Don't sort on 'URI' or 'Actions' column
        orderable: false
      }]
      bServerSide: true
      sDom: '<"dt-length col-xs-2 col-sm-3"l><"dt-filter col-xs-10 col-sm-5"f>rt<"dt-info col-xs-12 col-sm-4"i><"dt-pagination col-xs-12 col-sm-8"p>'

  @new: ->
    @form()

  @create: ->
    @form()

  @edit: ->
    @form()

  @update: ->
    @form()

  @form: ->

    @set_checkboxes = (that, checked) ->
      campaign = $(that).attr('child_selector')
      checkbox = $(".#{campaign}")
      checkbox.attr('disabled', checked).prop('checked', !checked)

    $('#search_campaign').select2(
      minimumInputLength: 1
      multiple: true
      ajax:
        url: '/admin/feeds/campaigns.json'
        type: 'GET'
        dataType: 'json'
        data: (query, page) ->
          q: query
        results: (data, page) ->
          results = []
          $.each data.campaigns, (i, c) ->
            results.push({id: c.id, text: c.name})
          results: results
      initSelection: (element, callback) ->
        campaigns = []
        $('.campaign-label').each ->
          campaigns.push({id: $(this).attr('value'), text: $(this).text()})
        if campaigns.length == 0
          $('#shop-list-container').hide()
        callback campaigns
        return
    ).select2 'val', []

    $('body').on 'change', '#search_campaign', ->
      val = $(this).val()
      if val
        list_shops(val)
      else
        $('#shop-list-container').empty()

    list_shops = (campaign_id) ->
      $('#shop-list-container').hide()
      $('#loading').show()
      $('#shop-list-container').load "#{location.origin}/admin/feeds/opportunities/#{campaign_id}", ->
        setTimeout hide_loading, 1000

     hide_loading = ->
      $('#loading').hide()
      $('#shop-list-container').show()

    $('body').on 'click', '#select-all', ->
      checked = $(this).is ':checked'
      campaigns = $('.select-campaign')
      campaigns.each ->
        campaigns.prop('checked', checked)
        AdminFeeds.set_checkboxes( this, checked )

    $('body').on 'change', '.select-campaign', ->
      checked  = $(this).is ':checked'
      AdminFeeds.set_checkboxes( this, checked )