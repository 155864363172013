import React from 'react';

export default class UserSignUpValidatedField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showOptions: false,
      selectedOptions: [],
      selectedAmount: props.value ? props.value.length : 0,
      value: props.value || ''
    }

    this.offClickHandler = this.offClickHandler.bind(this);
  }

  renderOptions(options, type) {
    const {disabled} = this.props;

    return (
      <div className={type} onClick={() => (!disabled && type === 'selected') ? this.toggleOptions() : '' } disabled={disabled}>
        <ul>
          {
            Array.isArray(options) &&
            options.map((item) =>
              <li className='list_item' key={item} onClick={() => (!disabled && type !== 'limit') ? this.selectHandler(item) : ''}>
                {item}
              </li>
            )
          }
        </ul>
      </div>
    )
  }

  renderSelect() {
    const { fieldName, label, required, options, value, id, disabled, multiSelect, limit } = this.props;
    const { showOptions, selectedOptions, selectedAmount } = this.state;
    const limited = limit && selectedAmount >= limit;
    const optionList = multiSelect ? options.filter((option) => !selectedOptions.includes(option)) : options;

    const renderPlaceHolder = <div className='placeholder' onClick={(e) => this.toggleOptions()} disabled={disabled}>Select {label}</div>;
    const renderSelected = this.renderOptions(value || selectedOptions, 'selected');
    const selectInput = selectedAmount <= 0 ? renderPlaceHolder : renderSelected;

    const renderLimited = this.renderOptions([`You can only select ${limit} items`], 'limit');
    const renderOptions = this.renderOptions(optionList, 'options');
    const selectOptions = limited ? renderLimited : renderOptions;

    return (
      <div className={`form-control ${multiSelect ? 'multi-select' : 'select'} ${showOptions ? 'focus' : ''}`} disabled={disabled}>
        <input id={id || fieldName}
               type='hidden'
               name={fieldName}
               required={required}
               value={value || selectedOptions}
               disabled={disabled}/>
        {selectInput}
        {showOptions && selectOptions}
      </div>
    )
  }

  renderText(){
    const { value } = this.state
    const { fieldName, fieldType, required, id, disabled, placeholder } = this.props;

    return(
      <input  id={id || fieldName}
              name={fieldName}
              type={fieldType || 'text'}
              required={required}
              onChange={(e) => this.changeHandler(e)}
              disabled={disabled}
              placeholder={placeholder}
              value={value || ''}/>
    )
  }

  renderField(fieldType){
    switch(fieldType) {
      case 'select':
        return this.renderSelect();
      default:
        return this.renderText();
    }
  }

  render() {
    const { className, fieldName, label, fieldType, required } = this.props;

    return (
      <div className={className}>
        { required && <abbr title="required">*</abbr> }
        <label htmlFor={fieldName}>
          { label }
        </label>
        { this.renderField(fieldType) }
        { this.renderValidations() }
      </div>
    )
  }

  renderValidations() {
    const { error } = this.props;

    if (error === null) return null;
    return (
      <span className='text-danger'>
        {error}
      </span>
    )
  }

  toggleOptions() {
    this.setState({showOptions: !this.state.showOptions});
    document.addEventListener('click', this.offClickHandler);
  }

  offClickHandler(e) {
    const isListItem = this.props.options.indexOf(e.target.innerText) !== -1;

    if (!isListItem  && this.state.showOptions) {
      this.setState({ showOptions: false})
      this.props.validationCallback(this.state.selectedOptions);
    }

    document.removeEventListener('click', this.offClickHandler);
  }

  selectHandler(option) {
    const { multiSelect } = this.props;
    let { selectedOptions } = this.state;
    const optionIndex = selectedOptions.length > 0 ? selectedOptions.indexOf(option) : -1;

    if (optionIndex !== -1) {
      selectedOptions.splice(optionIndex, 1);
    } else if (multiSelect) {
      selectedOptions.push(option);
    } else {
      selectedOptions = [option];
    }

    this.setState({
      selectedOptions: selectedOptions,
      selectedAmount: selectedOptions.length,
    });
    this.toggleOptions();
    this.changeHandler(null, selectedOptions);
  }

  changeHandler(event, value=null) {
    if (!value)
      value = event.target.value;

    this.setState({value: value});
    this.props.validationCallback(value);
  }
}