import React from 'react';
import { connect } from 'react-redux';
import UserSearchFilter from '../UserSearchFilter'
import UserTableDashboard from './Dashboard'
import ProgressBar from '../ProgressBar'
import NavigationTabsWrapper from '../navigation_tabs/Wrapper'

class UserTableContainer extends React.Component {
  constructor(props){
    super(props);
    this.updateTabView = this.updateTabView.bind(this);
  }

  componentDidMount() {
    this.props.stopLoading();
  }

  updateTabView(view) {
    if (this.props.view != view) {
      const oldPath = window.location.search;
      let newRoute = '';
      if (!!oldPath) {
        const newPath = oldPath.replace(/(tab=)[^\&]+/, `tab=${view}`);
        newRoute = String(window.location)
        newRoute.replace(oldPath, newPath);
      } else
        newRoute = `${window.location}?tab=${view}`
      window.history.pushState({tab: view}, document.title, newRoute);
      setTimeout(() => {
        this.props.setView(view);
        if (view != 'social_fabric')
          this.props.startLoading();
      }, 500);
    }
  }

  renderView(){
    switch(this.props.view) {
      case 'social_fabric':
        return (
          <div>
            <UserSearchFilter {...this.props.filter } />
            <div className='container main-container'>
              <div className='row'>
                <span dangerouslySetInnerHTML={{__html: this.props.socialFabricUserTable }} />
              </div>
            </div>
          </div>
        )
      default:
        return <UserTableDashboard {...this.props}/>
    }
  }

  render() {
    const { loading, navigation, view } = this.props;
    return (
      <div>
        <NavigationTabsWrapper onClickCallback={this.updateTabView} activeTab={view || ''} {...navigation} />
        { loading && <ProgressBar />}
        { this.renderView() }
      </div>
    )
  }

  static mapStateToProps(state, myProps) {
    let urlProps = {}
    window.location.search.replace('?','').split('&').map((item) =>  urlProps[item.split('=')[0]] = item.split('=')[1])
    return {
      ...state,
      view: state.view || urlProps.tab || myProps.initialView,
      search: state.search || urlProps.search || '',
      perPage: state.perPage || urlProps.per_page,
      offset: state.offset || urlProps.offset,
      urlProps: state.urlProps || urlProps,
      init: myProps
    };
  };

  static mapDispatchToProps(dispatch) {
    return {
      updateStore: (data) => dispatch({ type: 'UPDATE', value: data }),
      startLoading: () => dispatch({ type: 'UPDATE_FIELD', value: { field: 'loading', data: true } }),
      stopLoading: () => dispatch({ type: 'UPDATE_FIELD', value: { field: 'loading', data: false } }),
      setSearch: (data) => dispatch({ type: 'UPDATE_FIELD', value: { field: 'search', data: data } }),
      updateField: (field, data) => dispatch({ type: 'UPDATE_FIELD', value: { field: field, data: data } }),
      setView: (newView) => dispatch({ type: 'UPDATE_FIELD', value: { field: 'view', data: newView}}),
    };
  };
};

UserTableContainer.displayName = 'UserTable.Container';
export default connect(
  UserTableContainer.mapStateToProps,
  UserTableContainer.mapDispatchToProps
)(UserTableContainer);
