import React from 'react';
import { connect } from 'react-redux'
import { Remote } from '../utils';

import Alert from '../Alert'

class MetricsUploadTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: {
        message: null,
        visible: false
      }
    };

    this.loadUploads = this.loadUploads.bind(this);
    this.displayError = this.displayError.bind(this);
    this.badgeColor = this.badgeColor.bind(this);
    this.render = this.render.bind(this);
  }

  componentWillMount() {
    this.loadUploads();
    if (this.props.downloadError)
      this.displayError(this.props.downloadError);
  }

  render() {
    return(
      <div>
        <table className='table'>
          <thead>
            <tr>
              <th>File</th>
              <th>Template</th>
              <th>User</th>
              <th>Time</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {this.renderTableBody()}
          </tbody>
        </table>
        {
          this.state.error.visible &&
          <Alert type={'error'} message={this.props.downloadError} callback={() => this.setState({error: { visible: false}})}/>
        }
      </div>
    )
  }

  loadUploads() {
    Remote().request({
      method: 'GET',
      url: `${this.props.proxyUrl}?api_action=/csv_uploads`
    }).then( response => {
      this.setState({
        loading: false
      })
      this.props.updateUploads(response.data);
    });
  }

  displayError(message) {
    this.setState({
      error: {
        visible: true
      }
    });
    setTimeout( () => this.setState({error: { visible: false}}), 5000);
  }

  renderTableBody() {
    if (this.state.loading) {
         return <tr><td className='align-center' colSpan='5'><i className='fas fa-circle-notch fa-spin mg-10' /></td></tr>
    } else if (this.props.uploads.length > 0) {
      return this.props.uploads.map(file => {
        let badgeColor = this.badgeColor(file.status);
        let type = file.template.split('-')[0]
        return  <tr key={file.id}>
                  <td><a href={`${this.props.downloadUrl}?s3_path=${file.file_url}`}>{file.file_name}</a></td>
                  <td>{type.charAt(0).toUpperCase() + type.slice(1)}</td>
                  <td>{file.created_by}</td>
                  <td>{file.created_at}</td>
                  <td><span className={`badge ${badgeColor} text-size-x-small text-format-upcase`}>{file.status}</span></td>
                </tr>
      });
    } else
        return <tr>
                 <td colSpan='5'>
                   <div>
                     No uploads to display
                   </div>
                 </td>
               </tr>
  }

  badgeColor(status) {
    switch(status) {
      case 'live':
        return 'background-color-green';
      case 'replaced':
        return 'background-color-gray'
      case 'failed':
        return 'background-color-red';
      default:
        return 'background-color-blue';
    }
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      uploads: state.uploads
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateUploads: uploads => dispatch({ type: 'REFRESH_UPLOADS', value: uploads })
    };
  }
};

MetricsUploadTable.displayName = 'MetricsUploadTable';
export default connect(
  MetricsUploadTable.mapStateToProps,
  MetricsUploadTable.mapDispatchToProps
) (MetricsUploadTable);
