import React from 'react';

export default class LoadingSpinner extends React.Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
  }

  render() {
    const { loading } = this.props
    let { icon, spin, text } = this.props

    if (!loading)
      return;

    if (!text)
      text = 'Loading'

    if (!icon) {
      icon = 'fa-circle-notch'
      spin = true
    }

    let className = `fas ${icon}`
    if (!!spin)
      className += ' fa-spin'

    return (
      <div className='wizard-loading-spinner'>
        <div className='icon'>
          <i className={className} />
        </div>
        <div className='text'>
          <h3>
            {text}
          </h3>
        </div>
      </div>
    );
  }
};
LoadingSpinner.displayName = 'FacebookAds.LoadingSpinner';