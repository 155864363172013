import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

class TableActions extends React.Component {
  render() {
    const editUrl = `${this.props.baseUrl}/${this.props.record.id}/edit`;
    return (
      <span>
        <a className='btn btn-primary' href={editUrl}>
          <i className='fas fa-edit'/>
        </a>
        <button className='btn btn-danger' data-toggle='modal' onClick={() => this.props.setDeletionId(this.props.record.id)} data-target={'#delete-roundtask-modal'}>
          <i className='fas fa-trash'/>
        </button>
      </span>
    );
  }

  static mapStateToProps(state, myProps) {
    return {
      ...state,
      ...myProps
    };
  }

  static mapDispatchToProps(dispatch){
    return {
      setDeletionId: id => dispatch({type: 'SET_DELETION_ID', value: id})
    }
  }

};

TableActions.displayName = 'Table.Actions';
TableActions.propTypes = {
  record: PropTypes.any.isRequired
};

export default connect(
  TableActions.mapStateToProps,
  TableActions.mapDispatchToProps
)(TableActions);

