export const regexTiktokManual = /https:\/\/www.tiktok.com\/@+[^\/?#&]+\/video\/\d+/i
export const regexFacebookVideo = /facebook.com\/(.+?)\/videos\/(.+?)/
export const regexFacebookVideoAlt = /facebook\.com\/(([\w\.\-]+\/videos(\/vb\.\d+)?\/)|(watch\/?\?v=))/i
export const regexInstagramReel = /https:\/\/www.instagram.com\/reel\/|\/p\/(.+?)/
export const regexInstagramReelAlt = /instagram.com\/reel\/[\w\-]{5,13}/i
export const regexInstagramPost = /instagram.com\/p\/[\w\-]{5,13}/i
export const regexInstagramStory = /instagram.com\/[\w\-]+/i
export const regexYoutubeVideoRegex = /(youtube\.com\/watch\?v=)|(youtu\.be)\/[\w\-]{5,14}/i
export const regexYoutubeShort = /youtube.com\/shorts\/(\S+)/i
export const regexNoSocialMedia = /^((?!((facebook|instagram|youtube|twitter|pinterest)\.com)).)*$/i
export const regexPinterestManual = /https:\/\/(www\.)?(pinterest\.com\/pin)|(pin\.it)\/(.+?)/

export const phTiktokVideo = 'https://www.tiktok.com/tiktok_profile/video/xxxxx'
export const phTiktookVideoAlt = 'https://www.tiktok.com/:username/video/:video_id'
export const phInstagramReel = 'https://www.instagram.com/reel/:shortcode/'
export const phInstagramReelAny = 'https://www.instagram.com/reel/xxxxxxxxx or https://www.instagram.com/p/xxxxxxxxx'
export const phInstagramReelAlt = 'https://www.instagram.com/reel/…'
export const phFacebookVideo = 'https://www.facebook.com/facebook_page/videos/XXXXXXXXXXXXXXXX'
export const phFacebookVideoAlt = 'https://www.facebook.com/:FBPageName/videos/:video_id/'
export const phInstagramStory = 'https://www.instagram.com/:username'
export const phInstagramPost = 'https://www.instagram.com/p/:shortcode/'
export const phYoutubeVideo = 'https://www.youtube.com/watch?v=abcde-fghij'
export const phYoutubeShort = 'https://www.youtube.com/shorts/XXXXXXXXXXX'
export const phPinterestPost = 'https://www.pinterest.com/pin/XXXXXXXXXXX'

