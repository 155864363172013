import React from 'react';
import InstructionsReadOnlyCard from './Card'
import AutoCreateAnchorTags from '../../AutoCreateAnchorTags'

export default class InstructionsReadOnlyRequirementCard extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.renderContentTypes = this.renderContentTypes.bind(this);
  }

  renderContentTypes(contentTypes){
    return contentTypes
      .filter(ct => !!ct)
      .map(contentType => contentType.display_name)
      .join(', ')
  }

  render() {
    const { requirement } = this.props;
    const [dueYear, dueMonth, dueDay] = requirement.due_date.split('-')

    return <InstructionsReadOnlyCard title={'Task Content Requirement'} showTitle={true}>
      <div className='instructions read-only requirement_card'>
        <div className='task-container'>
          <div>
            <label>Task</label>
            <div>{requirement.name}</div>
          </div>
          <div>
            <label>Content Type</label>
            <div>{this.renderContentTypes(requirement.content_types)}</div>
          </div>
          <div className='due-date'>
            <label>Due</label>
            <div>{`${dueMonth}/${dueDay}/${dueYear}`}</div>
          </div>
        </div>
        <div className='task-description'>
          <label>Description</label>
          <div dangerouslySetInnerHTML={{__html: requirement.description}} />
        </div>
        <div className='requirements-container'>
          <table className='requirements-table'>
            <thead>
              <tr>
                {
                  this.props.hideVerification &&
                  <React.Fragment>
                    <th className='checkbox-column'></th>
                    <th>Content Requirements</th>
                  </React.Fragment>
                }
                {
                  !this.props.hideVerification &&
                  <React.Fragment>
                    <th className='checkbox-column'></th>
                    <th>Content Requirement</th>
                    <th>Verification</th>
                    <th>Verification Value</th>
                    <th>Occurance</th>
                    <th>#</th>
                  </React.Fragment>
                }
              </tr>
            </thead>
            <tbody>
              {
                this.props.requirement.round_task_requirements.map(req => {
                  if(req.requirement_type !== 'standard') {
                    return null;
                  }

                  return <tr key={req.id}>
                    <td className='checkbox-column'>
                      <i className='fas fa-square'/>
                    </td>
                    <td><AutoCreateAnchorTags text={req.description} /></td>
                    {
                      !this.props.hideVerification &&
                      <React.Fragment>
                        <td>{req.verification_type}</td>
                        <td>{req.verification_text}</td>
                        <td>{req.repeat_type}</td>
                        <td>{req.repeat_count}</td>
                      </React.Fragment>
                    }
                  </tr>
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </InstructionsReadOnlyCard>;
  }
}

InstructionsReadOnlyRequirementCard.displayName = 'Instructions.ReadOnly.RequirementCard';
