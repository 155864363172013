import React from 'react';
import { Remote } from '../utils';

import LoadingSpinner from './LoadingSpinner'
import CopyForm from './CopyForm'
import FormValidations from './FormValidations'

export default class ReviewEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      id: this.props.adId,
      requests: [],
      validations: new FormValidations({
        words: true,
        textLength: false,
        characters: true
      })
    };
  }

  componentDidMount() {
    $('.ad-nav-link').addClass("disabled");
    this.setState({loading: false});
  }

  componentWillUnmount() {
    $('.ad-nav-link').removeClass("disabled");
  }

  render() {
    if(this.state.loading) {
      return <LoadingSpinner loading={true} />;
    }

    return (
      <CopyForm
        wizardState={this.props.wizardState}
        saveCallback={(fields) => this.saveAdReview(fields)}
        saveText="Save & Update"
        submitCallback={(fields) => this.closeReview(fields)}
        submitText="Return to Review"
        validations={this.state.validations}
        adFormat={this.props.adData.format}
        adData={ this.props.adData }
        imageHash={ this.props.imageHash }
        showUtm={ true }
        previewEndpoint={this.props.previewEndpoint}
      />
    );
  }

  saveAdReview(fields) {
    const component = this;
    this.setState({ loading: true });

    const xhr = Remote().request({
      url: this.props.adEndpoint(this.state.id),
      method: 'PATCH',
      data: fields,
    }).then(() => component.setState({ loading: false}));

    this.addRequest(xhr);
    return xhr;
  }

  closeReview(fields) {
    this.saveAdReview(fields).done(() => this.props.close())
  }

  addRequest(xhr) {
    this.setState({
      requests: this.state.requests.concat(xhr)
    });
  }

  doBind(){
    this.componentDidMount = this.componentDidMount.bind(this);
    this.render = this.render.bind(this);
    this.saveAdReview = this.saveAdReview.bind(this);
    this.closeReview = this.closeReview.bind(this);
    this.addRequest = this.addRequest.bind(this);
  }
};
