import React from 'react';
class InstagramTextPreview extends React.Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.getFormattedText = this.getFormattedText.bind(this);
    this.colorizeString = this.colorizeString.bind(this);
    this.highlightHashtags = this.highlightHashtags.bind(this);
    this.highlightUsernames = this.highlightUsernames.bind(this);
  }

  render() {
    return <span dangerouslySetInnerHTML={this.getFormattedText()} />;
  }

  getFormattedText() {
    return {
      __html: this.highlightUsernames(this.highlightHashtags(this.props.caption))
    };
  }

  highlightHashtags(input) {
    return this.colorizeString(input, /#[\w\d_\-]+/g, InstagramTextPreview.HASHTAG_COLOR);
  }

  highlightUsernames(input) {
    return this.colorizeString(input, /@[\w\d_\-]+/g, InstagramTextPreview.USERNAME_COLOR);
  }

  colorizeString(input, regex, color) {
    return input.replace(regex, match => `<span style="color: ${color}">${match}</span>`);
  }
}

InstagramTextPreview.HASHTAG_COLOR = '#003569';
InstagramTextPreview.USERNAME_COLOR = '#003569';
