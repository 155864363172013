import React from 'react'
import { connect } from 'react-redux'
import RichTextEditor from '../../../RichTextEditor'
import RoundTasksRequirements from '../../../round_tasks/Requirements'
import CampaignsRequirementsTaskRequirementFields from './TaskRequirementFields'

class CampaignsRequirementsGeneralRequirementInputs extends React.Component {

  constructor(props) {
    super(props);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.debouncedSync = _.debounce(this.props.updateRoundTask, 500)
    this.updateRequirementSort = this.updateRequirementSort.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    this.props.updateMeta({
      fields: CampaignsRequirementsTaskRequirementFields  // NOTE: May cause some issue after react upgrade
    });
    this.props.setEditingType(this.props.type);
  }

  updateRequirementSort(from, to) {
    this.props.updateRequirementSort(this.props.requirement.id, from, to);
  }

  render(){
    const inputDescriptionStyle = this.props.isOpen ? { display: 'block' } : {display: 'none'}
    const viewDescriptionStyle = this.props.isOpen ? {display: 'none'} : {display: 'block'}

    let textAreaPlaceholder = "Add context for internal teams..."
    let textAreaPlaceholderView = `<p>${textAreaPlaceholder}</p>`


    const requirement = this.props.requirement
    if(requirement){
      return (
        <div>
          <div className='general-requirements-description description_area row'>
            <div className='col-md-12'>
              <div style={inputDescriptionStyle}>
                <RichTextEditor
                  placeholder={textAreaPlaceholder}
                  value={requirement.description}
                  onUpdate={(html) => this.props.editField('description', html)}/>
              </div>
              <div className='description--view' style={viewDescriptionStyle}
                dangerouslySetInnerHTML={{__html: requirement.description || textAreaPlaceholderView}}
              />
            </div>
          </div>
          <RoundTasksRequirements
            isOpen = {this.props.isOpen}
            updateRequirementField={this.props.updateRequirementField}
            updateRequirement={(name, value, idx) => this.props.updateRequirementField(name, value, idx)}
            roundTask={requirement}
            requirements={requirement.round_task_requirements}
            addRequirement={this.props.addRequirement}
            updateField={this.props.updateRequirementField}
            deleteRequirement={this.props.deleteRequirement}
            deleteRequirementById={this.props.deleteRequirementById}
            resetRequirement={(index) => this.props.updateRequirementState(this.props.resetRequirement, index)}
            disableRequirement={(index) => this.props.updateRequirementState(this.props.disableRequirement, index)}
            updateRequirementSort={this.updateRequirementSort}
            setRequirementSort={this.props.setRequirementSort}
            inCard={true}/>
        </div>
      );
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      setEditingType: type => dispatch({ type: 'SET_EDITING_TYPE', value: type }),
      updateRequirementSort: (taskId, from, to) => dispatch({
        type: 'UPDATE_REQUIREMENT_SORT',
        value: {
          taskId: taskId,
          from: from,
          to: to
        }
      })
    };
  }
}

CampaignsRequirementsGeneralRequirementInputs.displayName = 'Campaigns.Requirements.GeneralRequirementInputs';

export default connect(
  null,
  CampaignsRequirementsGeneralRequirementInputs.mapDispatchToProps
)(CampaignsRequirementsGeneralRequirementInputs);
