import React from 'react';
import DynamicForm from './DynamicForm';


export default class PaymentsBankSetup extends DynamicForm {
  getFormFields() {
    return [
      {
        displayName: 'Bank Routing Number',
        fieldName: 'routingNumber',
        regex: /^[0-3]\d\d\d\d\d\d\d\d$/,
        regexMessage: 'Must 9 digits and start with a number between 0 and 3',
        required: true
      },
      {
        displayName: 'Bank Account Number',
        fieldName: 'accountNumber',
        regex: /^\d\d?\d?\d?\d?\d?\d?\d?\d?\d?\d?\d?\d?\d?\d?\d?\d?$/,
        regexMessage: 'Must be 1-17 digits long',
        required: true
      },
      {
        displayName: 'Account Type',
        fieldName: 'accountType',
        required: true,
        dropdown: [
          { name: 'Checking Account', value: 'Checking Account' },
          { name: 'Savings Account', value: 'Savings Account' }
        ]
      },
      {
        displayName: 'Account Class',
        fieldName: 'accountClass',
        required: true,
        dropdown: [
          { name: 'Personal (PPD)', value: 'Personal (PPD)' },
          { name: 'Business (CCD)', value: 'Business (CCD)' },
        ]
      }
    ];
  }
}
