import React from 'react';
export default class NewBadge extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: 'New'
    }

    this.render = this.render.bind(this)
  }

  render() {
    var text = this.props.text || this.state.text

    return (
      <div>
        <div className="card-flag">
          {text}
        </div>
      </div>
    )
  }
}
