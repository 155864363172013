class window.AdminRoundBloggers

  @pending: ->
    ShopDashboard.setup()

    $('body').on 'click', '.activate-all', ->
      $('#pending-influencers').hide()
      $('#loading').removeClass('hidden')

    $('.rb-action').on 'ajax:send', (e, xhr) ->
      $(this).addClass('disabled').html('<i class="fas fa-spinner fa-spin"></i> Activating...')
    .on 'ajax:complete', ->
      $(this).text('Instructions Sent')

    $('.rb-action-invite').on 'ajax:send', (e, xhr) ->
      $(this).addClass('disabled').html('<i class="fas fa-spinner fa-spin"></i> Resending...')
    .on 'ajax:complete', ->
      $(this).text('Invite Resent')

  @active: ->
    ShopDashboard.setup()
    AdminShopApplications.client_modal()

    $('.rb-action-reactivate').on 'ajax:send', (e, xhr) ->
      $(this).addClass('disabled').html('<i class="fas fa-spinner fa-spin"></i> Resending Updated instructions...')
    .on 'ajax:complete', ->
      $(this).text('Instructions Updated')
      location.reload()

  @show: ->
    ShopDashboard.setup()

  @admin: ->
    ShopDashboard.setup()

    $('body').on 'change', 'select#round_users', (e) ->
      select_val = $(this).val()
      $('#user').val(select_val)

      if select_val == ''
        $('#withdraw_blogger_btn').removeAttr('data-blogger')
                                  .addClass('disabled')
      else
        $('#withdraw_blogger_btn').attr('data-blogger', select_val)
                                  .removeClass('disabled')


    $('body').on 'click', '#withdraw_blogger_btn', (e) ->
      if reason = prompt("Are you sure? \n\nPlease provide " +
                         "a reason for withdrawing this influencer." +
                         "\nThis will only be seen by admins.")
        $('#message').val(reason)
        $('#withdraw-form').submit()
      else
        false
