import React from 'react';
import PropTypes from 'prop-types'
import AutoCreateAnchorTags from '../AutoCreateAnchorTags'

export default class SubmissionRequirementListItem extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  render() {
    const {
      state,
      requirement
    } = this.props;

    return <div className={`list-item ${state}`} key={requirement.id}>
      <div className='item-title'>
        {this.renderTitle()}
      </div>
      <div className='item-body' dangerouslySetInnerHTML={{__html: requirement.humanized_requirement}}></div>
    </div>;
  }

  renderTitle() {
    const { requirement } = this.props;

    if(requirement.instruction_note_template) {
      return <div className='form-lr'>
        <div>
          {requirement.instruction_note_template.label}
        </div>

        <a href={this.instructionUrl()}>View</a>
      </div>;
    }

    return <AutoCreateAnchorTags text={requirement.description} />;
  }

  instructionUrl() {
    const {
      shoppertunityId,
      requirement: {
        instruction_note_template
      }
    } = this.props;

    return `/shoppertunities/${shoppertunityId}/instructions#instruction_note_${instruction_note_template.id}`
  }
};

SubmissionRequirementListItem.displayName = 'Submission.RequirementListItem';
SubmissionRequirementListItem.propTypes = {
  state: PropTypes.string.isRequired,
  shoppertunityId: PropTypes.number.isRequired,
  requirement: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    humanized_requirement: PropTypes.string.isRequired,
    instruction_note_template: PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired
    })
  }).isRequired
}
