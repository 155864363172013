import React from 'react';
import { connect } from 'react-redux';
import CampaignsRequirementsGeneralRequirementsContainer from '../requirements/general_requirements/GeneralRequirementsContainer';
import CampaignsRequirementsTaskContentRequirementsContainer from '../requirements/task_content_requirements/TaskContentRequirementsContainer';
import CampaignsRequirementsInstructionNotesContainer from '../requirements/instruction_notes/InstructionNotesContainer';
import SaveIndicator from '../../SaveIndicator';
import SaveError from '../../SaveError';

class CampaignsRequirementsRequirementsContainer extends React.Component {
  constructor(props) {
    super(props);
    
    this.render = this.render.bind(this);
  }

  render(){
    return (
      <div className='requirements_container'>
        <React.Fragment>
          { this.props.type === 'general_requirement' &&
            <CampaignsRequirementsGeneralRequirementsContainer {...this.props} />
          }
          { this.props.type === 'task_content_requirement' &&
            <CampaignsRequirementsTaskContentRequirementsContainer {...this.props} />
          }
          { this.props.type === 'instruction_notes' &&
            <CampaignsRequirementsInstructionNotesContainer {...this.props} />
          }
        </React.Fragment>
        {
          this.props.notifications && this.props.notifications.saving &&
          <SaveIndicator type={this.props.notifications.saveType}>
            {this.props.notifications.saveText}
            {this.props.notifications.saveErrors && <SaveError errors={this.props.notifications.saveErrors}/>}
          </SaveIndicator>
        }
      </div>
    );
  }

  static mapStateToProps(state, myProps) {
    return {
      ...state,
      ...myProps,
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      initialize: (field, data) => dispatch({ type: 'INITIALIZE', value: { field: field, data: data }}),
      startLoading: () => dispatch({ type: 'START_LOADING' }),
      stopLoading: () => dispatch({ type: 'STOP_LOADING' }),
      updateMeta: data => dispatch({ type: 'UPDATE_META', value: data }),
      setTasks: data => dispatch({ type: 'SET_TASKS', value: data }),
      setContentTypes: data => dispatch({ type: 'SET_CONTENT_TYPES', value: data }),
      setTemplates: data => dispatch({ type: 'SET_TEMPLATES', value: data }),
      addTask: data => dispatch({ type: 'ADD_TASK', value: data }),
      updateTaskField: (field, value, id, type) => dispatch({ type: 'UPDATE_TASK_FIELD', value: {field: field, value: value, id: id, type: type} }),
      addInstructionNotes: data => dispatch({ type: 'ADD_INSTRUCTION_NOTES', data: data }),
      removeInstructionNotes: data => dispatch({ type: 'REMOVE_INSTRUCTION_NOTES', id: data }),
      removeTaskContentRequirement: data => dispatch({ type: 'REMOVE_TASK_CONTENT_REQUIREMENT', id: data }),
      removeGeneralRequirement: data => dispatch({ type: 'REMOVE_GENERAL_REQUIREMENT', id: data }),
      editInstructionNotes: data => dispatch({ type: 'EDIT_INSTRUCTION_NOTES', data: data }),
      addRequirement: (taskId, requirement, type) => dispatch({type: 'ADD_REQUIREMENT', value: { taskId: taskId, requirement: requirement, type: type }}),
      deleteRequirement: (taskId, index, type) => dispatch({type: 'DELETE_REQUIREMENT', value: {taskId: taskId, index: index, type: type }}),
      deleteRequirementById: (taskId, id, type) => dispatch({type: 'DELETE_REQUIREMENT_BY_ID', value: {taskId: taskId, id: id, type: type }}),
      updateRequirementField: (taskId, field, value, index, type) => dispatch({type: 'UPDATE_REQUIREMENT_FIELD', value: {taskId: taskId, field: field, value: value, index: index, type: type }}),
      openNotification: (saveText, saveType, saveErrors) => dispatch({type: 'OPEN_NOTIFICATION', value: {saveText, saveType, saveErrors}}),
      closeNotification: data => dispatch({ type: 'CLOSE_NOTIFICATION'}),
    };
  }
};

CampaignsRequirementsRequirementsContainer.displayName = 'Campaigns.Requirements.RequirementsContainer';
export default connect(
  CampaignsRequirementsRequirementsContainer.mapStateToProps,
  CampaignsRequirementsRequirementsContainer.mapDispatchToProps
)(CampaignsRequirementsRequirementsContainer);
