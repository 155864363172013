import React from 'react';

import CarouselContainer from '../../CarouselContainer'
import InfluencerDashboardContentSummaryContent from './SummaryContent'
import InfluencerDashboardContentSummaryTopPerformers from './SummaryTopPerformers'

export default class InfluencerDashboardContentSummary extends React.Component {
  render() {
    const {
      total_count,
      summary_graph,
      most_views,
      most_engagements
    } = this.props;

    return (
      <div className='content-summary mb-15'>
        <h2 className='mb-10 pl-10'>Summary</h2>
        <div id='js_content_summary'>
          <CarouselContainer>
            <InfluencerDashboardContentSummaryContent
              key={'content'}
              totalCount={total_count}
              graphData={summary_graph} />
            <InfluencerDashboardContentSummaryTopPerformers
              key={'top_performers'}
              views={most_views}
              engagements={most_engagements} />
          </CarouselContainer>
        </div>
      </div>
    );
  }
};

InfluencerDashboardContentSummary.displayName = 'InfluencerDashboard.ContentSummary';