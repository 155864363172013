import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash'
import { Remote } from '../../utils';
import Upload from '../../Upload'
import ContentsDraftComment from '../../contents/DraftComment'
import SubmissionDraftContentAttachment from '../DraftContentAttachment'

class SubmissionInstagramDraftContent extends React.Component {
  constructor(props) {
    super(props);

    this.debouncedValidate = _.debounce(this.validate, 500);
    this.debouncedAttachments = _.debounce(this.updateAttachments, 500);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.render = this.render.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.updateCaption = this.updateCaption.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
    this.addAttachment = this.addAttachment.bind(this);
    this.instagramDraftComments = this.instagramDraftComments.bind(this);
  }

  componentDidMount() {
    this.props.setInstagramAttachments(this.props.bloggerShopTask.instagram_attachments);
    this.debouncedAttachments();
  }

  updateCaption(e) {
    this.props.updateSubmission({ description2: e.target.value });
    this.debouncedValidate();
  }

  updateAttachments() {
    let ids = this.props.instagramAttachments.map((attachment) => attachment.id);
    this.props.updateSubmission({ instagram_attachment_ids: ids });
    this.debouncedValidate();
  }

  validate() {
    const params = $.param({
      api_action: `/blogger_shop_tasks/${this.props.bloggerShopTask.id}/validate`
    });

    Remote().request({
      method: 'POST',
      url: this.props.proxyUrl + '?' + params,
      data: {
        type: this.props.contentType.name,
        user_id: this.props.userId,
        description2: this.props.description2
      }
    }).then((response) => {
      this.props.setValidations(response.data);
    });
  }

  instagramDraftComments(){
    if(this.props.bloggerShopTask.content && this.props.bloggerShopTask.campaign.draft_dashboard_display === 'draft_review'){
      let comments = _.orderBy(this.props.bloggerShopTask.content.instagram_draft_comments, (value) => new Date(value.created_at), ['desc']);
      return <div>
        <h4>Draft Comment History</h4>
        { this.props.bloggerShopTask.content.instagram_draft_comments && comments.map((comment) =>
          <ContentsDraftComment key={comment.id} comment={comment}/>)
        }
      </div>
    }
    return null;
  }

  renderForm(){
    const { instagramAttachments } = this.props;
    const contentCount = instagramAttachments ? instagramAttachments.length : 0
    const addMediaImagePath = `/contents/create_attachment`

    return (
      <div className='submission draft'>
          {
            contentCount == 0 && <div style={{paddingTop: '50px', paddingBottom: '50px'}}>
              <div className="text-center">Add media files to get started.</div>
              <div className="text-center">Accepted formats include: .jpg, .png, .mov, and .mp4</div>
          </div>
          }

          {!this.props.readOnly && <div style={{paddingBottom: '10px'}}>
              <div>
                  <Upload uploadPath={addMediaImagePath}
                              fileParam={"instagram_attachment"}
                              params={{ taskId: this.props.bloggerShopTask.id, type: 'instagram_attachment' }}
                              fileTypes={'.jpg, .png, .mov, .mp4'}
                              callback={this.addAttachment}/>
              </div>
          </div>}

          <div className='form-group'>
              <label>Post caption:</label>
              <textarea className="form-control" disabled={this.props.readOnly} value={this.props.description2 || ''} onChange={this.updateCaption} style={{width: '100%', height: '150px'}}></textarea>
          </div>

          {this.instagramDraftComments()}
      </div>
    );
  }

  isVideo(fileType){
    return fileType.includes("video")
  }

  render() {
    const { instagramAttachments, contentType, loading } = this.props;
    const contentCount = instagramAttachments ? instagramAttachments.length : 0

    let type = () =>  {
      switch(contentType.name) {
      case 'InstagramDraftContent':
        return 'instagram';
      default:
        return '';
      }
    }

    if (loading) {
      return null;
    } else if(instagramAttachments && contentCount) {
      return (
        <div>
          <div className={`submission content-grid content-count-${contentCount} ${type()}`}>
            { instagramAttachments.map((attachment, index) => <SubmissionDraftContentAttachment key={index} index={index} attachment={attachment} deleteAttachment={this.deleteAttachment} readOnly={this.props.readOnly}/>) }
          </div>
          {this.renderForm()}
        </div>
      )
    } else {
      return (
        this.renderForm()
      )
    }
  }

  addAttachment(attachment) {
    this.props.addInstagramAttachment(attachment);
    this.debouncedAttachments();
  }

  deleteAttachment(attachment) {
    const deleteMediaImagePath = `/contents/destroy_attachment?attachment_id=${attachment.id}`

    Remote().request({
      method: 'POST',
      url: deleteMediaImagePath
    }).then((response) => {
      this.props.deleteInstagramAttachment(response.data);
      this.debouncedAttachments();
    });
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      userId: state.meta.userId,
      bloggerShopTask: state.bloggerShopTask,
      contentType: state.contentType,
      proxyUrl: state.meta.proxyUrl,
      instagramAttachments: state.instagramAttachments,
      description2: state.submission.description2,
      readOnly: state.readOnly,
      loading: state.loading
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      addInstagramAttachment: attachment => dispatch({ type: 'ADD_INSTAGRAM_ATTACHMENT', value: attachment}),
      deleteInstagramAttachment: attachment => dispatch({ type: 'DELETE_INSTAGRAM_ATTACHMENT', value: attachment}),
      setInstagramAttachments: attachments => dispatch({ type: 'SET_INSTAGRAM_ATTACHMENTS', value: attachments}),
      setValidations: validations => dispatch({ type: 'SET_VALIDATIONS', value: validations }),
      updateSubmission: change => dispatch({ type: 'UPDATE_SUBMISSION', value: change}),
      setLoading: loading => dispatch({ type: 'SET_LOADING', value: loading })
    };
  }
};

SubmissionInstagramDraftContent.displayName = 'Submission.InstagramDraftContent';
export default connect(
  SubmissionInstagramDraftContent.mapStateToProps,
  SubmissionInstagramDraftContent.mapDispatchToProps
)(SubmissionInstagramDraftContent);
