class window.Resources
  @ticket: ->
    FormUtils.filestyle_settings()
    $('body').on 'click', '#submit-btn', (e) ->
      ticket_subject = $('#ticket_subject').val()
      ticket_description = $('#ticket_description').val()
      ticket_email = $('#ticket_email').val()
      if ticket_subject.length == 0 || ticket_description.length == 0 || ticket_email.length == 0
        # required: true on form elements directs users to missing fields
      else
        icon = $('<i class="fas fa-spinner fa-spin"></i>')
        $(this).html(icon)
        $(this).addClass('disabled')

  @faq: ->
    collapse_all = ->
      $('.faq-collapse').collapse('hide')

    collapse_all()

    hash = window.location.hash
    if hash != ''
      $('.faq').hide()
      $("#{hash}").show()

    $('body').on 'click', '.faq-link', (e) ->
      e.preventDefault()
      click = $(this).data('name')
      $('.faq').hide()
      $("##{click}").show()

    $('body').on 'click', '.link-collapse', (e) ->
      e.preventDefault()
      collapse_all()

      $(this).closest('.faq-section')
             .find('.faq-collapse')
             .collapse('toggle')

    $('body').on 'click', '.bottom-nav .support-link', ->
      window.location.reload()

  @get_knowledge: ->
    collapse_all = ->
      $('.articles-collapse').collapse('hide')
    show_all = ->
      $('.articles-collapse').collapse('show')

    collapse_all()

    $('body').on 'click', '.link-collapse', (e) ->
      e.preventDefault()

      $(this).closest('.row')
        .find('.articles-collapse')
        .collapse('toggle')

      $(this).closest('.row')
        .find('.fas')
        .toggleClass('fa-plus fa-minus')

