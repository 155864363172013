import React from 'react';
import { connect } from 'react-redux';
import {isEqual} from 'lodash';
import NavigationTabsMobile from './Mobile'
import NavigationTabsDesktop from './Desktop'

class NavigationTabsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.render = this.render.bind(this);
    this.updateViewport = this.updateViewport.bind(this);
    this.errorsPresent = this.errorsPresent.bind(this);
    this.openProfileErrorsMenu = this.openProfileErrorsMenu.bind(this);
  }

  componentDidMount() {
    this.props.loadState(this.props)
    this.updateViewport();
    window.addEventListener('resize', this.updateViewport);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateViewport);
  }

  componentDidUpdate(prevProps) {
    // Errors can change after the first render, so reload them if they change
    if(!isEqual(this.props.errors, prevProps.errors)) {
      this.props.loadState(this.props.errors);
    }
  }

  updateViewport(){
    this.props.setViewport(window.innerHeight, window.innerWidth)
  }

  errorsPresent(){
    let errors = this.props.errors;
    return Object.keys(errors).some(function(k) { return errors[k] !== undefined; });
  }

  openProfileErrorsMenu(){
    return this.props.menuName == 'Profile Settings' &&
           this.props.activeTab == 'Basic Information' &&
           this.errorsPresent() &&
           this.props.showProfileTabsErrors
  }

  render() {
    if ( this.props.viewAs === 'mobile') {
        if (this.openProfileErrorsMenu()) {
            this.props.openMenu();
            this.props.hideProfileTabsErrors()
        }
        return <NavigationTabsMobile {...this.props} />
    } else {
        return <NavigationTabsDesktop {...this.props} />
    }
  };

  static mapStateToProps(state, myProps) {
    return {
      ...state,
      ...myProps
    };
  };

  static mapDispatchToProps(dispatch) {
    return {
      initialize: (field, data) => dispatch({ type: 'INITIALIZE', value: { field: field, data: data } }),
      setTabs: (data) => dispatch({ type: 'SET_TABS', data: data }),
      setActiveTab: (data) => dispatch({ type: 'SET_ACTIVE_TAB', value: data }),
      setViewport: (h, w) => dispatch({ type: 'SET_VIEWPORT', data: {height: h, width: w} }),
      openMenu: () => dispatch({ type: 'SET_MENU', value: true }),
      closeMenu: () => dispatch({ type: 'SET_MENU', value: false }),
      toggleMenu: () => dispatch({ type: 'TOGGLE_MENU'}),
      loadState: (data) => dispatch({ type: 'LOAD_STATE ', state: data }),
      hideProfileTabsErrors: () => dispatch({ type: 'HIDE_PROFILE_TABS_ERRORS' })
    };
  };
};

NavigationTabsContainer.displayName = 'NavigationTabs.Container';
export default connect(
  NavigationTabsContainer.mapStateToProps,
  NavigationTabsContainer.mapDispatchToProps
)(NavigationTabsContainer);
