import React from 'react';
import PropTypes from 'prop-types'

 export default class RoundTaskSelectionRow extends React.Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.renderCheckBox = this.renderCheckBox.bind(this);
  }

  render() {
    return <div className='round-tasks selection-row' onClick={this.props.onSelect}>
      <div className='left'>
        {this.renderCheckBox()}
      </div>
      <div className='right'>
        {this.props.roundTask.name}
      </div>
    </div>
  }

  renderCheckBox() {
    if(this.props.selected) {
      return <i className='fas fa-check-circle'/>;
    }

    return <i className='far fa-circle'/>;
  }
};

RoundTaskSelectionRow.displayName = 'RoundTasks.RoundTaskSelectionRow';

RoundTaskSelectionRow.propTypes = {
  roundTask: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired
};
