import React from 'react';

export default class InstructionsReadOnlyRevisionNotice extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  render() {
    return (
      <div className='instruction-update-notes mb-5'>
        <div className='review-notice mb-5'>Review new updates before accepting or declining instructions.</div>
        <div className='alert alert-info mb-5' dangerouslySetInnerHTML={{__html: this.props.revisionNotes}}>
        </div>
        <div className='notes-updated-at text-right mb-5'>
          {this.props.updatedAt}
        </div>
      </div>
    );
  }
}

InstructionsReadOnlyRevisionNotice.displayName = 'Instructions.ReadOnly.RevisionNotice';
