import React from 'react';
import { Remote } from '../utils';

export default class Previewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      previewType: 'MOBILE_FEED_STANDARD',
      imageIndex: 0,
      iframeData: null,
      previews: {},
      request: null
    }

    this.changeImageIndex = this.changeImageIndex.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.fetchNewPreview = this.fetchNewPreview.bind(this);
    this.imageHashes = this.imageHashes.bind(this);
    this.maxIndex = this.maxIndex.bind(this);
    this.previewAd = this.previewAd.bind(this);
    this.previewFrame = this.previewFrame.bind(this);
    this.previewTypeControl = this.previewTypeControl.bind(this);
    this.refresh = this.refresh.bind(this);
    this.render = this.render.bind(this);
    this.requestPreview = this.requestPreview.bind(this);
    this.selectedImageControl = this.selectedImageControl.bind(this);
    this.updatePreviewType = this.updatePreviewType.bind(this);
  }

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.adFormat !== this.props.adFormat)
      this.refresh();
    if (Object.keys(this.state.previews).length === 0) return
    const hasChanged = !['adFormat', 'url', 'text', 'description', 'headline', 'id', 'image_hash'].every(val => prevProps[val] === this.props[val])
    if (hasChanged){
      this.setState({ previews: {}})
    }
  }

  componentWillUnmount() {
    if (this.state.request !== null)
      this.state.request.abort()
  }

  refresh(){
    if (this.state.request !== null)
      this.state.request.abort()
    this.requestPreview(this.state.previewType, this.state.imageIndex);
  }

  requestPreview(previewType, hashIndex) {
    if (this.props.adFormat === 'carousel'){
        this.fetchNewPreview(
          previewType,
          this.props.imageHash || this.props.wizardState.image_hashes
        );
    }
    else {
      const imageHash = this.imageHashes()[hashIndex]
      this.fetchNewPreview(previewType, imageHash);
    }
  }

  fetchNewPreview(previewType, imageHash) {
    const component = this
    const xhr = Remote().request({
      url: this.props.previewEndpoint,
      method: 'POST',
      data: {
        id: this.props.wizardState.id,
        ad_type: previewType,
        image_hash: imageHash,
        url: encodeURIComponent(
          this.props.wizardState.url || this.props.url
        ),
        text: this.props.text,
        description: this.props.description,
        headline: this.props.headline
      },
    }).then((response) => {
      component.setState({
        iframeData: Object.values(response.data),
        previews: Object.assign({}, component.state.previews, response.data)
      });
      if (component.props.finishCallback) component.props.finishCallback();
    });
    if (this.state.request !== null)
      this.state.request.abort()

    this.setState({ iframeData: null, request: xhr });
  }

  previewAd() {
    const {iframeData, previewType} = this.state
    const data = iframeData ? Object.values(iframeData)[0] : null
    return (
      <div className='preview section-body'>
        <div className={`${previewType} iframe-wrapper`}>
          { this.previewFrame(data) }
        </div>
      </div>
    )
  }

  previewFrame(data) {
    if (!data)
      return <LoadingSpinner loading={true} />
    else if (data['error'])
      return <LoadingError text={data['error']} />
    else
      return <div dangerouslySetInnerHTML={{__html: data}} />
  }

  imageHashes() {
    // In Review editor we specify a single image hash to display
    // In Copy Editor we dont specify and require all image_hashes in wizard
    if (this.props.imageHash !== undefined)
      return [this.props.imageHash]
    else
      return this.props.wizardState.image_hashes
  }

  maxIndex() {
    // Carousel will always be a single preview
    if (this.props.adFormat === 'carousel')
      return 1
    else
      return this.imageHashes() && this.imageHashes().length || 1
  }

  updatePreviewType(event) {
    const previewType = event.target.value
    this.setState({
      previewType: previewType
    });
    this.requestPreview(previewType, this.state.imageIndex);
  }

  changeImageIndex(amount) {
    const maxIndex = this.maxIndex();
    let newIndex = this.state.imageIndex + amount;
    if (newIndex < 0) newIndex = 0;
    if (newIndex >= maxIndex) newIndex = maxIndex - 1;
    if (newIndex === this.state.imageIndex) return
    this.setState({
      imageIndex: newIndex
    });
    this.requestPreview(this.state.previewType, newIndex);
  }

  selectedImageControl() {
    const selectionText = (this.state.imageIndex + 1)+" / "+this.maxIndex()
    if (this.maxIndex() > 1) {
      return (
        <div className='controls'>
          <i className='fas fa-chevron-left' onClick={ () => this.changeImageIndex(-1) } style={{cursor: 'pointer'}}/>
          <span>{selectionText}</span>
          <i className='fas fa-chevron-right' onClick={ () => this.changeImageIndex(1) } style={{cursor: 'pointer'}}/>
        </div>
      )
    }
  }

  previewTypeControl() {
    return (
      <div className='select-wrapper section-body'>
        <select className='form-control' onChange={this.updatePreviewType} value={this.state.previewType}>
          <option value={ 'MOBILE_FEED_STANDARD' }>
            Mobile Feed
          </option>
          <option value={ 'DESKTOP_FEED_STANDARD' }>
            Desktop Feed
          </option>
          <option value={ 'RIGHT_COLUMN_STANDARD' }>
            Right Column
          </option>
          <option value={ 'INSTAGRAM_STANDARD' }>
            Instagram Standard
          </option>
          <option value={ 'INSTAGRAM_STORY' }>
            Instagram Story
          </option>
        </select>
      </div>
    )
  }

  render() {
    return (
      <div className='copy-preview section left'>
        <div className='section-header'>
          <label>Ad Preview</label>
          { this.previewTypeControl() }
        </div>
        { this.previewAd() }
        <div className='section-footer'>
          { this.props.adFormat !== 'carousel' && this.selectedImageControl() }
          <button className='btn btn-default' onClick={() => this.refresh()}>
            Refresh
          </button>
        </div>
      </div>
    )
  }
}
