import React from 'react';
import { connect } from 'react-redux'
import InstructionNotesForm from './Form'
import InstructionNotesFormActions from './FormActions'


class InstructionNotesNew extends React.Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
  }

  render() {
    const cardStyle = {'position': 'relative'};

    return (
        <div>
            <div className='full-width-page-title--wrapper'>
                <h1 className='full-width-page-title--heading text-center'>New Instruction Note Template</h1>
            </div>
            <div className='container main-container' style={cardStyle}>
                <div className='card-wrapper--white-bg'>
                    <InstructionNotesForm {...this.props} />
                    <InstructionNotesFormActions {...this.props}/>
                </div>
            </div>
        </div>
    );
  }

  static mapStateToProps(state, myProps) {
    return {
      ...state,
      ...myProps,
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateField: (field, data) => dispatch({type: "UPDATE_FORM", value: { field: field, data: data}}),
      startSubmitting: () => dispatch({ type: 'START_SUBMITTING'}),
      stopSubmitting: () => dispatch({ type: 'STOP_SUBMITTING'}),
    };
  };
}


InstructionNotesNew.displayName = 'InstructionNotes.New';

export default connect(
  InstructionNotesNew.mapStateToProps,
  InstructionNotesNew.mapDispatchToProps
)(InstructionNotesNew);
