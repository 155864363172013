import React from 'react';
import PropTypes from 'prop-types'
import { Remote } from '../utils';

import RequirementCard from './read_only/RequirementCard'
import RichTextEditor from '../RichTextEditor'
import SaveIndicator from '../SaveIndicator'

class InstructionsRoundTask extends React.Component {
  constructor(props) {
    super(props);
    const {task: {round_task_requirements}} = props;
    this.state = {
      round_task_requirements,
      saving: false,
      saveType: 'info',
      saveText: '',
      saveErrors: null,
      timeoutId: null,
      saveTimeOutId: null,
    };

    this.addRequirement = this.addRequirement.bind(this);
    this.render = this.render.bind(this);
    this.updateRequirement = this.updateRequirement.bind(this);
    this.updateDescription = this.updateDescription.bind(this);
    this.flash = this.flash.bind(this);
  }

  render() {
    const task = this.props.task;
    const roundStyle = {
      marginBottom: '12px',
      marginLeft: '24px',
      boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
      borderRadius: '4px',
      padding: '8px',
      borderLeft: '2px solid #187bd1'
    };

    return (
      <div style={roundStyle}>
        <h3 style={{ margin: '10px 0 10px 0' }}>{task.name}</h3>
        <RichTextEditor value={task.description} onUpdate={this.updateDescription} />

        <h4>Requirements:</h4>
        {
          this.props.task.round_task_requirements.map((requirement, index) =>
            <RequirementCard
              requirement={requirement}
              content_types={this.props.content_types}
              key={requirement.id}
              hideVerification={true}
            />
          )
        }
        <div className='text-right'>
          <button className='btn btn-primary' onClick={this.addRequirement}>
            <i className='fas fa-plus-circle' /> Add Requirement
          </button>
        </div>
        {
          this.state.saving &&
          <SaveIndicator type={this.state.saveType}>
            {this.state.saveText}
            {this.state.saveErrors && <SaveError errors={this.state.saveErrors} />}
          </SaveIndicator>
        }
      </div>
    );
  }

  updateDescription(html) {
    this.props.onUpdate({
      ...this.props.task,
      description: html
    });
  }

  updateRequirement(req, ind) {
    let { round_task_requirements, saveTimeOutId } = this.state;
    round_task_requirements[ind].description = req.description;
    clearTimeout(saveTimeOutId)
    saveTimeOutId = setTimeout(() => this.saveRequirement(req, ind), 1000);
    this.setState({ round_task_requirements, saveTimeOutId });
  }

  deleteRequirement(req, index) {
    const { round_task_requirements } = this.state;
    round_task_requirements.splice(index, 1)
    this.setState({ round_task_requirements });
    if (this.unSavedRequirement(req.id)) {return;}
    this.delete(req);
  }

  addRequirement() {
    let {round_task_requirements} = this.state;
    round_task_requirements = [...round_task_requirements, {
      id: this.tempId(),
      description: ''
    }];
    this.setState({round_task_requirements});
  }

  saveRequirement(req, ind) {
    this.notifySaving();
    if (this.unSavedRequirement(req.id)) {
      this.create(req, ind)
    } else {
      this.save(req)
    }
  }

  tempId() {
    return `local-${(new Date()).getTime()}`;
  }

  unSavedRequirement(id) {
    return !!`${id}`.match(/^local\-[\d]+/)
  }

  create(req, ind) {
    req = _.omit(req, 'id')
    this.save(req,ind)
  }

  save(req, ind) {
    req.round_task_id = this.props.task.id;
    this.flashHandle(this.xhr(JSON.stringify(req),this.saveURL()),ind);
  }

  delete(req) {
    this.notifySaving();
    this.flashHandle(this.xhr(JSON.stringify(req), this.saveURL(), 'DELETE'));
  }

  flash(newState, duration) {
    this.setState(newState, () => {
      const timeoutId = window.setTimeout(() => this.setState({
        saving: false
      }), duration);

      this.setState({ timeoutId });
    });
  }

  saveURL() { return `${this.props.dataURL}/requirement`; }

  xhr(data, url, method = 'POST') {
    return Remote().request({
      url, data, method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }

  notifySaving() {
    this.setState({
      saving: true,
      saveType: 'info',
      saveText: 'Saving instructions...',
      saveErrors: null,
      timeoutId: null
    });
  }

  flashHandle(xhrPromise, index) {
    const {round_task_requirements} = this.state;
    const idUpdate = (id) => {
      if (!!index) {
        round_task_requirements[index].id = id
        this.setState(round_task_requirements);
      }
    };
    xhrPromise.then(resp => {
      idUpdate(resp.id);
      this.flash({
        saveText: 'Successfully saved instructions.',
        saveType: 'success'
      }, 3000)
    }).fail(resp => this.flash({
      saveText: 'Failed to save instructions! Please correct these errors:',
      saveType: 'failure',
      saveErrors: resp.responseJSON
    }, 5000));
  }
}

InstructionsRoundTask.displayName = 'Instructions.RoundTask';
InstructionsRoundTask.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  task: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    round_task_requirements: PropTypes.arrayOf(PropTypes.any)
  }).isRequired,
  dataURL: PropTypes.string
};
export default InstructionsRoundTask