import React from 'react';

export default class RoundTasksSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showOptions: false,
      selectedOptions: RoundTasksSelect.fieldValue(this.props) || '',
    }

    this.offClickHandler = this.offClickHandler.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
    this.selectHandler = this.selectHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.renderPlaceHolder = this.renderPlaceHolder.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value && !_.isEqual(state.selectedOptions, props.value)) {
      return {
        selectedOptions: RoundTasksSelect.fieldValue(props),
      };
    }

    if (props.value === null) {
      return {
        selectedOptions: []
      }
    }

    return null;
  }

  static fieldValue(props) {
    const value = props.value
    if(typeof(value) == 'boolean'){
      return props.options.filter(op => op.value == value)
    }else if(value || (Array.isArray(value) && value.length > 0)){
      return value;
    }else{
      return null;
    };
  }

  toggleOptions() {
    this.setState({showOptions: !this.state.showOptions});
    document.addEventListener('click', (() => {this.offClickHandler}));
  }

  offClickHandler(e) {
    const { index, name, value } = this.props;

    const isListItem = this.props.options.indexOf(e.target.innerText) !== -1;

    if (!isListItem  && this.state.showOptions) {
      this.setState({ showOptions: false });
    } else {
      this.props.updateField(name, value, index);
    }

    document.removeEventListener('click', this.offClickHandler);
  }

  selectHandler(option) {
    const { multiSelect } = this.props;
    let { selectedOptions } = this.state;
    const existingOption = selectedOptions.find(op => op.id == option.id);

    let newOptions = {};
    if (existingOption) {
      newOptions = selectedOptions.filter(op => op.id != option.id);
    } else if (multiSelect) {
      newOptions = selectedOptions.concat(option);
    } else {
      newOptions = [option];
    }

    this.setState({ selectedOptions: newOptions });
    this.toggleOptions();
    this.changeHandler(null, newOptions);
  }

  changeHandler(event, value=null) {
    const { index, name } = this.props;

    if (!value) {
      value = event.target.value;
    }

    this.props.updateField(name, value, index);
  }

  renderPlaceHolder() {
    const {disabled, error} = this.props;
    let klass = 'placeholder';
    if (error && error.length > 0) {
      klass += ' input-error'
    }

    return (
      <div className={klass} onClick={(e) => this.toggleOptions()} disabled={disabled}>Select {this.props.label}</div>
    )
  }

  renderOptions(options, type) {
    const {disabled} = this.props;

    return (
      <div className={type} onClick={() => (!disabled && type === 'selected') ? this.toggleOptions() : '' } disabled={disabled}>
        <ul>
          {
            Array.isArray(options) && options.map((item, index) =>
              <li className={`list_item`} key={index} onClick={() => (!disabled && type !== 'limit') ? this.selectHandler(item) : ''}>
                { item.display_name || item.name }
              </li>
            )
          }
        </ul>
      </div>
    )
  }

  render() {
    const { name, required, options, multiSelect, error, disabled } = this.props;
    const { showOptions, selectedOptions } = this.state;

    let optionList = [];
    if(multiSelect && selectedOptions && selectedOptions.length > 0) {
      optionList = options.filter((option) => selectedOptions.every(x => x.id !== option.id))
    } else {
      optionList = options;
    }

    return (
      <div className={`form-control ${multiSelect ? 'multi-select' : 'select'} ${error ? 'invalid' : ''} ${showOptions ? 'focus' : ''}`} disabled={disabled}>
        <input type='hidden'
               name={name}
               required={required}
               value={selectedOptions}
               disabled={disabled}/>
        {selectedOptions && selectedOptions.length <= 0 ? this.renderPlaceHolder() : this.renderOptions(selectedOptions, 'selected')}
        {!disabled && showOptions && this.renderOptions(optionList, 'options')}
      </div>
    )
  }

  componentDidCatch(error, info) {
    console.warn(error, info);
  }
};

RoundTasksSelect.displayName = 'RoundTasks.Select';
