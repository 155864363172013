import React from 'react';
import { connect } from 'react-redux';

class SubmissionBlogPreview extends React.Component {
  render() {
    return <div className='submission blog-preview'>
      <a href={this.props.preview.url}>
        <img className='hero' src={this.props.preview.image_url} />
      </a>
      <div className='blog-description'>
        {this.props.preview.description}
      </div>
    </div>;
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      preview: state.preview
    };
  }
};

SubmissionBlogPreview.displayName = 'Submission.BlogPreview';

export default connect(
  SubmissionBlogPreview.mapStateToProps
)(SubmissionBlogPreview);
