import React from 'react';
import MultiSelect from '../MultiSelect'

export default class CountrySelector extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.search = this.search.bind(this);
  }

  render() {
    return (
      <div className='form-group'>
        <label>Country</label>
        <MultiSelect selected={this.props.targetingValues}
                     search={this.search}
                     onUpdate={(values) => this.props.updateLocationData('countries', values)} />
      </div>
    );
  }

  search(text, callback) {
    callback([
      {
        name: 'US',
        value: 'US'
      }
    ]);
  }
}
