import React from 'react';
import DraftComment from './DraftComment'
const StepAmount = 5

class DraftCommentHistory extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      comments: this.props.comments,
      displayCount: 5
    }

    this.loadMore = this.loadMore.bind(this);
  }

  loadMore() {
    let currentCount = this.state.displayCount;
    this.setState({displayCount: currentCount + StepAmount});
  }

  render() {
    const load_more_btn_styles = {
      margin: '0 auto',
      display: 'block',
      float: 'none'
    };

    return(
      <div id='draft_comment_history' className='col-xs-12' >
        { this.state.comments.map( (comment, index) =>
            index < this.state.displayCount ? <DraftComment key={comment.id} comment={comment} current_username={this.props.current_username} /> : null
          )
        }
        {
          (this.props.comments.length != 0 && this.props.comments.length > this.state.displayCount) &&
            <button id='draft_comment_history--load_more_btn' className='col-xs-2 btn btn-default' style={load_more_btn_styles} onClick={this.loadMore} >
              Load More
            </button>
        }
      </div>
    );
  }
}
export default DraftCommentHistory
