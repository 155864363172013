import React from 'react';
import { connect } from 'react-redux';
import thumbProfilePic from '../../images/fallback/thumb_profile_pic.jpg'

class ConversationsMessageBubble extends React.Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.renderBody = this.renderBody.bind(this);
    this.displayTime = this.displayTime.bind(this);
    this.avatarUrl = this.avatarUrl.bind(this);
  }

  displayTime(){
    const {created_at, index, conversation} = this.props;
    const last_message_sent_at = (index > 0) ? conversation.messages[index-1].created_at : null
    if(!last_message_sent_at){
      return true;
    }
    const t1 = new Date(created_at);
    const t2 = new Date(last_message_sent_at);
    const diff_in_hours = (t1-t2)/(3600000.0);
    return (diff_in_hours > 1.0);
  }

  renderBody(message_type, body){
    if(message_type === 'text'){
      return body;
    }else{
      return(
        <a href={body} download><embed src={body} className='conversation-message-embed' /></a>
      )
    }
  }

  avatarUrl(author){
    return (author == null) ? thumbProfilePic : author.avatar_url;
  }

  render() {
    const {author, sent_at, body, alignment, message_type, id} = this.props;
    return (
      <div id={`message-${id}`} className={`conversation-message-bubble ${alignment}`}>
        {
          this.displayTime() &&
          <div className='conversation-date'>
            {sent_at}
          </div>
        }
        <div className={`conversation-${alignment}-message`}>
          <div className="conversation-avatar" style={{backgroundImage: `url(${this.avatarUrl(author)})`}} />
          <div className='conversation-message-card'>
            { this.renderBody(message_type, body) }
          </div>
        </div>
      </div>
    );
  }

  static mapStateToProps(state, myProps) {
    return {
      loading: state.loading,
      selected: state.selected,
      conversation: state.conversation,
      message_loading: state.message_loading,
      ...myProps
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      toggleLoading: () => dispatch({type: 'TOGGLE_LOADING'}),
      setSelected: data => dispatch({type: 'SET_SELECTED', value: data}),
      appendMessage: data => dispatch({type: 'ADD_MESSAGE', value: data}),
      setMessageLoading: data => dispatch({type: 'SET_MESSAGE_LOADING', value: data})
    };
  }
}

ConversationsMessageBubble.displayName = 'Conversations.MessageBubble';

export default connect(
  ConversationsMessageBubble.mapStateToProps,
  ConversationsMessageBubble.mapDispatchToProps
)(ConversationsMessageBubble);
