`import Dropzone from 'dropzone'`
`import notif from 'javascripts/notifIt.js'`
import '../plugins/redactor-plugins.js'
import '../plugins/redactor.min.js'


class window.Contents
  @show: ->
    NoteUtils.noteable()
    @clipboard_tool()
    annotations = $("#draft-annotations-body").data('annotations') || []
    if annotations.length
      annotation = $("#draft-text").annotator(readOnly: true)
      annotation.annotator('loadAnnotations', annotations)

  @new: ->
    @form()

  @create: ->
    @form()

  @edit: ->
    @form()
    new SidebarUtils('.main-container', '#draft-annotations-history')

  @update: ->
    @form()

  $(document).on "ready", (e) ->
    caption = $('.instagram-caption textarea');
    if( $(caption) != undefined )
      $(caption).css( "height", $(caption)[0] && $(caption)[0].scrollHeight );

  $ ->
    $('.js_ig_draft_checkbox').on 'change', ->
      $(this).prop(disabled: true)
      checked = $(this).is(':checked')
      url = $(this).data('url')
      attachment_id = $(this).data('attachment-id')
      $.ajax
        type: 'POST'
        url: url
        data: { attachment_id: attachment_id, selected: checked }
        success: (data) ->
          $('.js_ig_draft_checkbox').prop(disabled: false)
          notif msg: data.message, type: data.notice_type, timeout: 3000

  $(document).on "input propertychange",  ".instagram-caption textarea", (e) ->
    $(this).css( "height", $(this)[0] && $(this)[0].scrollHeight );

  $(document).on "click", ".play", ->
    if($(this).siblings("video").get(0).paused)
      $(this).siblings("video").get(0).play();
      $(this).addClass('is-hidden')

  $(document).on "click", ".pause", ->
    if(!$(this).siblings("video").get(0).paused)
      $(this).siblings("video").get(0).pause();
      $(this).addClass('is-hidden')
      $(this).siblings(".play").removeClass('is-hidden')

  $(document).on "mouseover", ".target-video", (e) ->
    if(!$(this).get(0).paused)
      $(this).siblings(".pause").removeClass('is-hidden')

  $(document).on "mouseover", ".pause", (e) ->
    if(!$(this).siblings("video").get(0).paused)
      $(this).removeClass('is-hidden')

  $(document).on "mouseleave", ".target-video", (e) ->
    if(!$(this).get(0).paused)
      $(this).siblings(".pause").addClass('is-hidden')

  $(document).on "click", ".carousel-control.left", ->
    pause_all_videos()

  $(document).on "click", ".carousel-control.right", ->
    pause_all_videos()

  pause_all_videos = ->
    $('.target-video').each ->
      if(!$(this).get(0).paused)
        $(this).get(0).pause();
        $(this).siblings(".play").removeClass('is-hidden')
        $(this).siblings(".pause").addClass('is-hidden')

  @form: ->
    $('#VidCarousel').carousel
      interval: false
    $('.item:first,#indicator:first').addClass('active');
    $('.picker').click ->
      $('.picker-border').removeClass('selected');
      $('.fb-video-url').val( $(this).data('contentUrl') )
      $(this).parent().addClass('selected')

    $('.fb-video-url').click ->
      $('.picker-border').removeClass('selected');

    $('.js-url-link').click ->
      $('.fb-video-url').toggle();

    @draft_editor('.content_form')

    Dropzone.options.theDropzone =
      url: '/contents/create_attachment'
      uploadMultiple: false
      parallelUploads: 10
      addRemoveLinks: true
      dictDefaultMessage: "Drag and drop or click here to upload your content."
      dictFileTooBig: "Your file must be less than 512MB"
      maxFiles: 20
      maxFilesize: 512
      timeout: 480000
      params: {'task_id': document.getElementById('task_id').value}
      paramName: 'instagram_attachment'

      # Constructor
      init: ->
        dropzoneContext = this
        existingImages = JSON.parse($("#InstagramAttachments").text()) || []
        # Coerce the images into the right format for dropzone
        list = existingImages.map (image) ->
          name: image.file.url
          size: 1024
          fileID: image.id
        # Manually trigger the events to add the files
        list.forEach (file) ->
          dropzoneContext.emit('addedfile', file)
          dropzoneContext.emit('thumbnail', file, file.name)
          dropzoneContext.emit('complete', file)
          dropzoneContext.files.push(file)
          # Resize the images so they fit in the element
          $(dropzoneContext.element).find('.dz-image img').attr('style', 'width: 100%')
          # find(selector) returns all the child elements that matches selector
          # selecting last to add attribute fileID to only last file i.e., the current file attachment
          $(dropzoneContext.element).find('.dz-remove').last().attr('fileID', file.fileID)
      success: (file, response) ->
        $(file.previewTemplate).find('.dz-remove').attr('fileID', response.fileID)
        $(file.previewElement).addClass 'dz-success'
      removedfile: (file) ->
        id = $(file.previewTemplate).find('.dz-remove').attr('fileID')
        $.ajax
          type: 'POST'
          url: '/contents/destroy_attachment'
          data: { attachment_id: id }
          success: (data) ->
            $(file.previewElement).remove()

    show_fields = ->
      type = $('#content_type').val()
      $('.content-input').hide()
      $(".#{type}").show()

    show_fields()

    input_url = $('#content_url')
    validate_fqdn = input_url.data('validate-fqdn')
    blog_content = input_url.data('blog')
    fqdn_error = input_url.data('fqdn-error')

    if blog_content
      button = $('#submit-btn, #retrieve_blog_data')
    else
      button = $('#submit-btn')

    $('.content_form').validate
      validClass: 'success',
      ignore: [],
      rules:
        'content[url]':
          maxlength: 255
          validateFqdn: validate_fqdn,
        'content[image_url]':
          maxlength: 255

      highlight: (element) ->
        if validate_fqdn && $(element).attr('name') == 'content[url]'
          $('.js-validation-icon').addClass('fa-times text-danger').removeClass('fa-check text-success')
        button.prop('disabled', true)

      unhighlight: (element) ->
        if validate_fqdn && $(element).attr('name') == 'content[url]'
          $('.js-validation-icon').addClass('fa-check text-success').removeClass('fa-times text-danger')
        button.prop('disabled', false)

    jQuery.validator.addMethod 'validateFqdn', ((value, element) ->
      blog_domain = $(element).data('fqdn')
      input_domain = ''


      if value.indexOf('://') > -1
        input_domain = value.split('/')[2]
      else
        input_domain = value.split('/')[0]

      if input_domain.indexOf('www.') > -1
        input_domain = input_domain.substring(input_domain.indexOf('.') + 1)

      input_domain.toLowerCase() == blog_domain
      ), fqdn_error

    # keep form from being submitted with enter for when form has single input
    unless $('#content_type').val() == 'DraftContent'
      $(window).keydown (e) ->
        if e.keyCode == 13
          e.preventDefault()

    $('body').on 'click', '#retrieve_blog_data', (e) ->
      e.preventDefault()
      if $('#content_url').val()
        icon = '<i class="fas fa-spinner fa-spin"></i>'
        retrieve_btn = $(this)
        retrieve_btn.addClass('disabled').html(icon)
        $('.js_fetched_post_data').removeClass('is-hidden')
        $.ajax
          type: 'POST'
          url:  Routes.retrieve_blog_post_data_contents_path( format: 'json')
          data: { blog_url: $('#content_url').val(), task_id: document.getElementById('task_id').value }
          success: (response) ->
            blog = response.blog_data_response
            $('#error_message').addClass('is-hidden')
            $('#warning_message').addClass('is-hidden')
            retrieve_btn.removeClass('disabled').html('Update')
            $('#content_title').val(blog.blog_title)
            $('#content_description2').val(blog.blog_description)
            $('#content_image_url').val(blog.blog_image)
            $('#post-image-preview').attr('src', blog.blog_image)

            hashtag_validations = response.hashtag_validation.hashtags
            els = $('[data-hashtag-id]')
            for el in els
              $el = $(el)
              id = $el.attr('data-hashtag-id')
              found = $(el).find('span.hashtag_found')
              missing = $(el).find('span.hashtag_missing')
              hyperlinked = $(el).find('span.hashtag_hyperlinked')
              if hashtag_validations[id].found
                found.removeClass('is-hidden')
                missing.addClass('is-hidden')
              else
                found.addClass('is-hidden')
                missing.removeClass('is-hidden')
              if hashtag_validations[id].hyperlinked
                hyperlinked.removeClass('is-hidden')
              else
                hyperlinked.addClass('is-hidden')

            link_validations = response.link_validation.links
            els = $('[data-required-link-id]')
            for el in els
              $el = $(el)
              id = $el.attr('data-required-link-id')
              found = $(el).find('span.link_found')
              missing = $(el).find('span.link_missing')
              nofollow = $(el).find('span.link_nofollow')
              if link_validations[id].found
                found.removeClass('is-hidden')
                missing.addClass('is-hidden')
              else
                found.addClass('is-hidden')
                missing.removeClass('is-hidden')
              if link_validations[id].nofollow
                nofollow.addClass('is-hidden')
              else
                nofollow.removeClass('is-hidden')

            found = $('.disclosure_found')
            missing = $('.disclosure_missing')
            at_top_of_post = $('.disclosure_at_top_of_post')
            if response.disclosure_validation.found
              $(found).removeClass('is-hidden')
              $(missing).addClass('is-hidden')
            else
              $(found).addClass('is-hidden')
              $(missing).removeClass('is-hidden')
            if response.disclosure_validation.at_top_of_post
              $(at_top_of_post).addClass('is-hidden')
            else
              $(at_top_of_post).removeClass('is-hidden')

            if(response.messages && response.messages.error)
              $('#error_message').removeClass('is-hidden').text(response.messages.error)
              $('#submit-btn').addClass('disabled')
            else
              $('#error_message').addClass('is-hidden')
              $('#submit-btn').removeClass('disabled')

            if(response.messages && response.messages.warning)
              $('#warning_message').removeClass('is-hidden').text(response.messages.warning)
            else
              $('#warning_message').addClass('is-hidden')

          error: (response) ->
            msg = if (response && response.messages && response.messages.error) then response.messages.error else "Sorry, we were unable to retrieve your blog post. Please check the URL and try again or manually enter the info below."
            retrieve_btn.removeClass('disabled').html('Try Again')
            $('#content_title, #content_description2, #content_image_url').val('')
            $('#post-image-preview').attr('src', '')
            $('#warning_message').addClass('is-hidden')
            $('#error_message').removeClass('is-hidden').text(msg)

          complete: -> description_char_count()

    $('.char-count').keyup ->
      description_char_count()

    $('#content_image_url').keyup ->
      $('#post-image-preview').attr('src', $(this).val())

    description_char_count = ->
      char_count_input = $('.char-count')
      char_count_msg   = char_count_input.siblings('.num-chars')
      len = char_count_input.val().replace(/\n/g, "\n\r").length
      text_max = 200
      rem = text_max - len
      msg_color = if rem < 0 then 'red' else 'inherit'
      char_count_msg.css('color', msg_color).text(rem + ' characters remaining')

  @clipboard_tool: ->
    @draft_clipboard '#clipboard-btn', 'Draft blog body copied to your clipboard!'
    @draft_clipboard '#caption-copy-btn', 'Caption copied to your clipboard'
    $format_btn = $('#format-btn')
    $clip_btn = $('#clipboard-btn')
    $draft_text = $('#draft-html')

    $format_btn.click ->
      draft = $draft_text
      $(this).toggleClass('html_view')
      if $(this).hasClass('html_view') then draft.html( draft.text() ) else draft.text( draft.html() )

      $clip_btn.removeClass 'disabled'
      $format_btn.text if $format_btn.text() == "Switch to HTML Mode" then "Switch to Visual Mode" else "Switch to HTML Mode"

  @draft_editor: (form_selector) ->
    HTML_MODE = 'Switch to HTML Mode'
    VISUAL_MODE = 'Switch to Visual Mode'
    $content_form = $( form_selector )
    $post_contents = $content_form.find('#content_draft_attributes_body')
    $html_button = $content_form.find('#html-btn')
    html_state = $html_button.text() == VISUAL_MODE

    editorInit = ->
      $post_contents.redactor
        buttonSource: true
        plugins: plugin_list
        buttonsHide: ['html']
        replaceTags: false
        replaceDivs: false
        linebreaks: false
        enterKey: true
        linkNofollow: true

    editorDestroy = ->
      $post_contents.redactor('core.destroy')

    editorSwitch = ->
      $html_button.text if $html_button.text() == VISUAL_MODE then HTML_MODE else VISUAL_MODE
      if html_state
        editorInit($post_contents)
      else
        editorDestroy($post_contents)
      html_state = !html_state

    $html_button.click (e) ->
      e.preventDefault()
      editorSwitch()

    editorInit() unless html_state
    new AnnotatedEditor('#edit_content', '.hidden[data-annotations]')

  @setTooltip: (message, targetElement) ->
    targetElement.addClass('disabled').attr('data-content', message).popover 'toggle'

  @hideTooltip: (targetElement) ->
    setTimeout ( ->
      targetElement.removeClass('disabled').popover 'hide'
    ), 3000

  @draft_clipboard: (button_selector, success_msg) ->
    setTooltip = @setTooltip
    hideTooltip = @hideTooltip
    $clip_btn = $(button_selector)
    clip = new Clipboard(button_selector)
    clip.on 'success', ->
      setTooltip(success_msg, $clip_btn)
      hideTooltip($clip_btn)
    clip.on 'error', ->
      setTooltip('Press "Ctrl + C" to copy selected text.', $clip_btn)
      hideTooltip($clip_btn)
