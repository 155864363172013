import React from 'react';
import { Remote } from '../utils';

import ActionAlert from '../ActionAlert'
import SaveIndicator from '../SaveIndicator'
import SaveError from '../SaveError'
import ProgressBar from '../ProgressBar'
import ShoppertunitiesSurveyModal from './SurveyModal'

export default class ShoppertunitiesTasks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      tasks: [],
      notifications: {
        loading: false,
        loadType: '',
        loadText: '',
        loadErrors: ''
      },
      loading: true
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.openNotification = this.openNotification.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.loadTasks = this.loadTasks.bind(this);
    this.roundTaskCard = this.roundTaskCard.bind(this);
    this.requiresGAConnection = this.requiresGAConnection.bind(this);
    this.shopTaskContainer = this.shopTaskContainer.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    this.loadTasks();
  }

  openNotification(loadText, loadType, loadErrors){
    this.setState({
      ...this.state,
      notifications: {
        loading: true,
        loadType: loadType,
        loadText: loadText,
        loadErrors: loadErrors
      }
    })
  }

  closeNotification(){
    this.setState({
      ...this.state,
      notifications: {
        loading: false,
        loadType: '',
        loadText: '',
        loadErrors: ''
      }
    });
  }

  loadTasks() {
    const params = $.param({
      api_action: `/blogger_shop_tasks`,
      shoppertunity_id: this.props.shop_id,
      user_id: this.props.user_id
    });

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'GET',
      
    }).then((response) => {
      this.setState({
        tasks: response.data,
        loading: false
      });
    }).catch((error) => {
      this.openNotification(
        'Failed to load opportunity tasks! Please try again.',
        'failure',
        null
      );
      this.setState({loading: false});
      setTimeout(this.closeNotification, 5000);
    });
  }

  requiresGAConnection(){
    this.state.tasks.some((rt) => rt.needs_ga_auth );
  }

  roundTaskCard(task){
    const statusClass = task.task_status.split(' ').join('_').toLowerCase()
    const cardUrl = `/submissions/${task.id}`
    return (
      <a key={task.id} href={cardUrl} className='shop_tasks--card-link'>
        <div className={`shop_tasks--card ${statusClass}`}>
          <div className='shop_tasks--card_details'>
            <div className='shop_tasks--card_name'>
              <b>{task.name}</b>
            </div>
            <div className='shop_tasks--card_due_date'>
              {task.task_status == 'Complete' ? task.due_date : task.humanized_due_date}
            </div>
          </div>
          <div className='shop_tasks--card_status'>
            {task.content_action}
          </div>
        </div>
      </a>
    )
  }

  // TODO: Remove this once all no-content tasks are done
  roundTaskBypassCard(task) {
    const onClick = () => {
      if(task.complete) {
        return;
      }

      const params = $.param({
        api_action: `/blogger_shop_tasks/${task.id}/submit_now`
      });

      Remote().request({
        url: this.props.proxyUrl + '?' + params,
        method: 'POST',
          
      }).then((response) => {
        // Replace the current task with the updated task
        this.setState({
          tasks: this.state.tasks.map(task => {
            if(task.id !== response.data.id) {
              return task;
            }

            return response.data;
          })
        })
      });
    };

    const statusClass = task.task_status.split(' ').join('_').toLowerCase()
    return (
      <a key={task.id} href='javascript:void(0);' className='shop_tasks--card-link' onClick={onClick}>
        <div className={`shop_tasks--card ${statusClass}`}>
          <div className='shop_tasks--card_details'>
            <div className='shop_tasks--card_name'>
              <b>{task.name}</b>
            </div>
            <div className='shop_tasks--card_due_date'>
              {task.task_status == 'Complete' ? task.due_date : task.humanized_due_date}
            </div>
          </div>
          <div className='shop_tasks--card_status'>
            {task.task_status == 'Complete' ? 'Done' : task.content_action}
          </div>
        </div>
      </a>
    )
  }

  shopTaskContainer() {
    return (
      <div className='shop_tasks--container'>
        {
          this.requiresGAConnection() &&
          <div className="action-alert-area">
            <ActionAlert
              text={this.props.gaAlert}
              type="error"
              icon="plug"
              actionText={this.props.gaAlertLinkText}
              actionDestination={this.props.connectBlogsLink}
            />
          </div>
        }
        <h4>Please complete the following tasks for {this.props.shop_name}:</h4>
        <div className='shop_tasks--cards-container'>
          { this.state.tasks.map( (task) =>
              task.round_task.requires_content
              ? this.roundTaskCard(task)
              : this.roundTaskBypassCard(task)
            )
          }
        </div>
        {
          this.state.notifications && this.state.notifications.loading &&
          <SaveIndicator type={this.state.notifications.loadType}>
            {this.state.notifications.loadText}
            {this.state.notifications.loadErrors &&
              <SaveError errors={this.state.notifications.loadErrors}/>
            }
          </SaveIndicator>
        }
      </div>
    )
  }

  render() {
    const { loading } = this.state;
    const { shop_survey_eligible, questions, shoppertunity_id, user_id, save_response_url, authenticity_token } = this.props;
    return (
      <React.Fragment>
        <div className={`container main-container ${loading ? 'no-padding full-width' : ''}`}>
          {loading && <ProgressBar />}
          {!loading && this.shopTaskContainer()}
          { shop_survey_eligible &&
            <ShoppertunitiesSurveyModal questions= {questions}
                         shoppertunity_id= {shoppertunity_id}
                         user_id = {user_id}
                         save_response_url = {save_response_url}
                         authenticity_token = {authenticity_token}
            />
          }
        </div>
      </React.Fragment>
    );
  }
};

ShoppertunitiesTasks.displayName = 'Shoppertunities.Tasks';
