import React from 'react';
import PropTypes from 'prop-types'
import Tooltip from '../Tooltip'

export default class ProfilesSocialAccountRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hover: false
    }

    this.buttonClass = this.buttonClass.bind(this);
    this.buttonText = this.buttonText.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleModalClick = this.handleModalClick.bind(this);
    this.render = this.render.bind(this);
    this.rowClass = this.rowClass.bind(this);
    this.useModal = this.useModal.bind(this);
  }

  showTooltip() {
    const { disabled } = this.props;
    const { hover } = this.state;

    return disabled && hover;
  }

  buttonText() {
    if(!this.props.platformAccount) {
      return 'Connect';
    }

    if(this.props.platformAccount.connected) {
      return 'Disconnect';
    }

    return 'Reconnect';
  }

  buttonClass() {
    const { platformAccount } = this.props;

    if(!platformAccount) {
      return 'btn primary';
    }

    if(platformAccount.connected) {
      return 'btn btn-default disconnect-link';
    }

    return 'btn danger';
  }

  useModal() {
    const { platformName, platformAccount } = this.props;

    if (platformName != 'pinterest') {
      return false;
    }

    if (platformName === 'pinterest' && !platformAccount?.connected) {
      return true;
    }

    return !platformAccount;
  }

  handleClick(e) {
    const {
      auth,
      deAuth,
      platformAccount,
      platform,
      disabled
    } = this.props;

    e.preventDefault();
    if(disabled) {
      return;
    }

    if(_.get(platformAccount, 'connected')) {
      deAuth(platform);
      return;
    }

    auth(platform);
  }

  handleModalClick(e) {
    const {
      platformName,
      disabled,
      openModal
    } = this.props;

    e.preventDefault();
    if(disabled) {
      return;
    }

    openModal( _.capitalize(platformName));
  }

  rowClass() {
    const { platformAccount, disabled } = this.props;

    if(disabled) {
      return 'social-account-row network disabled';
    }

    if(!platformAccount) {
      return 'social-account-row network';
    }

    if(platformAccount.connected) {
      return 'social-account-row network authenticated connected';
    }

    return 'social-account-row network authenticated reconnect';
  }

  render() {
    const {
      platform,
      platformAccount,
      platformName,
      platformLabel,
      disabled,
      auth,
      deAuth
    } = this.props;

    const toggleHover = hovering => this.setState({ hover: hovering });

    return <div className={this.rowClass()} onMouseEnter={() => toggleHover(true)} onMouseLeave={() => toggleHover(false)}>
      <i className={`fab social-icon--${platformName}`}></i>
      <a className='platform' href={_.get(platformAccount, 'link') || '#'} target="_blank">
        {_.get(platformAccount, 'username') || platformLabel}
      </a>
      <div className='centered-button'>
        {
          this.useModal() &&
            <a className={this.buttonClass()} href='#connect' onClick={this.handleModalClick}>
              {this.buttonText()}
            </a>
        }
        {
          !this.useModal() &&
          <span className='tooltip-icon-wrapper'>
          <a disabled={disabled} className={this.buttonClass()} onClick={this.handleClick}>
            {this.buttonText()}
          </a>
          {
            this.showTooltip() &&
            <Tooltip message='Must first authenticate Instagram' placement='top' centerText={false}/>
          }
          </span>
        }
      </div>
    </div>;
  }
};

ProfilesSocialAccountRow.displayName = 'Profiles.SocialAccountRow';
ProfilesSocialAccountRow.propTypes = {
  platform: PropTypes.string.isRequired,
  platformAccount: PropTypes.shape({
    name: PropTypes.string,
    connected: PropTypes.bool,
    provider: PropTypes.string.isRequired
  }),
  openModal: PropTypes.func,
  platformName: PropTypes.string.isRequired,
  platformLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  auth: PropTypes.func.isRequired,
  deAuth: PropTypes.func.isRequired
};
