import React from 'react';
var NewBadgeHelper = (function() {
  var intervalID = null;
  var minutes_to_expire = 30;

  var expireNewnessName = "shoppertunities_expire_newness";
  var lastActivityName = "shoppertunities_last_activity";
  var shopsSeenName = "shoppertunities_shops_seen";
  //Changed to be date this was released to production
  var defaultLastActivity = new Date("9/7/2016");

  function load() {
    if (typeof localStorage !== 'undefined') {
      checkForExpiredNewness();
    }
  }

  function checkForExpiredNewness() {
    var lastActivity = getLastActivity();
    var expireNewness = getExpireNewness();
    var now = new Date();
    if(now.getTime() > expireNewness.getTime()){
      setLastActivity(expireNewness);
      setExpireNewness();
      setShopSeen(null);
    }
  }

  function showBadge(shop,current_phase){
    if (typeof localStorage === 'undefined') {
      return false;
    }
    if(shop != null && !shopSeen(shop.id)){
      var shop = shop;
      var opened_at = new Date(shop.opened_at);
      var last_on_shops_page = getLastActivity();

      if(opened_at instanceof Date
          && last_on_shops_page instanceof Date
          && opened_at.getTime() > last_on_shops_page.getTime()
          && current_phase == "open"){
        return true;
      }
    }
    return false;
  }

  function getExpireNewness() {
    var expireNewness = getFromStorage(expireNewnessName);
    if (expireNewness == null) {
      expireNewness = setExpireNewness();
    }
    return new Date(expireNewness);
  }

  function setExpireNewness() {
    var now = new Date();
    var expireNewness = new Date();
    expireNewness.setMinutes(now.getMinutes() + minutes_to_expire);
    sendToStorage(expireNewnessName, expireNewness);
    return expireNewness;
  }

  function setShopSeen(shopID) {
    var shops = getFromStorage(shopsSeenName);
    if(shopID != null){
      if(!isNaN(shopID)){
        shopID = Number(shopID);
      }
      if (shops != null && shops.indexOf(shopID) == -1) {
        shops.push(shopID);
      } else if (shops == null) {
        shops = [shopID];
      }
    }else{
      shops = null;
    }
    sendToStorage(shopsSeenName, shops);
  }

  function shopSeen(shopID) {
    var shops = getFromStorage(shopsSeenName);
    if(!isNaN(shopID)){
      shopID = Number(shopID);
    }
    if (shops != null && shops.indexOf(shopID) > -1) {
      return true;
    }
    return false;
  }

  function getLastActivity() {
    var lastActivity = getFromStorage(lastActivityName);
    if (lastActivity == null) {
      lastActivity = defaultLastActivity;
      setLastActivity(lastActivity);
    }
    return new Date(lastActivity);
  }

  function setLastActivity(value) {
    sendToStorage(lastActivityName, value);
  }

  function getFromStorage(name) {
    var itemFromStorage = null;
    var lStorage = window.localStorage;
    if (lStorage && lStorage.getItem(name) != null) {
      itemFromStorage = JSON.parse(lStorage.getItem(name));
    }
    return itemFromStorage;
  }

  function sendToStorage(name, obj) {
    var lStorage = window.localStorage;
    if (lStorage) {
      if(obj == null){
        lStorage.removeItem(name);
      }else{
        lStorage.setItem(name, JSON.stringify(obj));
      }
    }
  }

  function checkForShopSeen(){
    if(window && window.location){
      var url = window.location;
      if(url.pathname.indexOf("/opportunities/") > -1){
        let shopID = url.pathname.replace(/\D/g, "");
        setShopSeen(shopID);
      }
    }
  }

  checkForShopSeen();

  return {
    load: load,
    showBadge:showBadge,
    getLastActivity: getLastActivity,
    shopSeen: shopSeen
  }

}());

export default NewBadgeHelper;
