import React from 'react';
import UserSignUpValidatedForm from './ValidatedForm';
import UserSignUpInfoAccordion from './InfoAccordion';
import { UserSignUpPaymentFields } from './fields/PaymentInfo';

export default class UserSignUpPaymentInfo extends React.Component {
  constructor(props){
    super(props);
    this.form = new UserSignUpValidatedForm(this, UserSignUpPaymentFields[this.props.forcePaypal ? 'Canada' : this.props.country]);
  }

  componentWillMount(){
    this.form.mount();
  }

  render(){
    const { heading, fields_required, accordion, country, disclaimer, forcePaypal } = this.props
    const showPaypal = (country == 'Canada' || forcePaypal)

    return (
      <div className="sign-up-payment-info">
        <div className='column'>
          <h4>{heading}</h4>
          <p>{fields_required}</p>
          <div className='form'>
            <div className='column'>
              { !showPaypal && this.form.renderField('dob', this.props.dob) }
              { !showPaypal && this.form.renderField('tin') }
              { !showPaypal && this.form.renderField('name1099') }
              { !showPaypal && this.form.renderField('taxClassification') }
              {  showPaypal && this.form.renderField('paypalName')}
            </div>
            <div className='column'>
              { !showPaypal && this.form.renderField('routingNumber') }
              { !showPaypal && this.form.renderField('accountNumber') }
              { !showPaypal && this.form.renderField('accountType') }
              { !showPaypal && this.form.renderField('accountClass') }
              {  showPaypal && this.form.renderField('paypalEmail')}
            </div>
          </div>
          <p>{!showPaypal && disclaimer}</p>
        </div>
        <div className='column'>

          { Object.keys(accordion).map((key) => {
            return <UserSignUpInfoAccordion key={key} title={accordion[key].title} body={accordion[key].body} />
          })}
        </div>
      </div>
    )
  }
}