import React from 'react';

export default class Post extends React.Component {
  render(){
    const {group_name, user_name, user_id, message, notification_user_id, title} = this.props.notification;
    return (
      <React.Fragment>
        {notification_user_id === user_id && <b>You </b>}
        {notification_user_id !== user_id && <b>{user_name} </b>}
        posted <b>{title}</b> in the <b>{group_name}</b> group.
        <div>{message}</div>
      </React.Fragment>
    )
  }
}
Post.displayName = 'Notification.Templates.Post'