import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from "react-redux"
import NavigationStore from '../redux/NavigationStore.jsx';
import NavigationTabsContainer from './Container.jsx';

const NavigationTabsWrapper = (props) => {
  return (
    <Provider store={NavigationStore}>
      <NavigationTabsContainer {...props} />
    </Provider>
  );
};

NavigationTabsWrapper.displayName = 'NavigationTabs.Wrapper';
NavigationTabsWrapper.propTypes = {
  onClickCallback: PropTypes.func,
  activeTab: PropTypes.string.isRequired,
  menuName: PropTypes.string.isRequired,
  tabs: PropTypes.object.isRequired,
  order: PropTypes.arrayOf(PropTypes.string),
  links: PropTypes.object,
  counts: PropTypes.object
}

export default NavigationTabsWrapper;