class window.AdminRounds

  @index: ->
    ShopDashboard.setup()

    $('tr.round').on 'click', ->
      window.location = $(this).data('url')

  @new: ->
    @form()

    $('#start-fresh').on 'click', (e) ->
      $('#round-clone-select').remove()
      $('.round-form').removeClass('hidden')

      false

  @edit: ->
    @form()

  @update: ->
    @form()

  @create: ->
    @form()

  @clone: ->
    @form()

  @form: ->
    ShopDashboard.setup()
    new ValidateDate.DateField( '#save-round', ['round_end_date'] ).bind_values()
    new ValidateNumber.PositiveField( '#save-round', ['#round_total_bloggers', '#round_product_reimbursement', '#round_final_payment'] ).bind_values()
    $('#round-datepicker').datetimepicker
      minView: 2,
      format: 'MM dd, yyyy'

  @show: ->
    $('.sortable').sortable(
      update: ->
        $.post($(this).data('updateurl'), $(this).sortable('serialize'))
    )