import { createStore } from 'redux';

const initialState = {
  loading: true,
  alert: {
    showing: false,
    message: []
  },
  meta: {
    proxyUrl: '',
    resetPasswordUrl: '',
    deleteAccountUrl: '',
    userId: null
  },
  settings: {
    newEmail: '',
    confirmEmail: '',
    notification_setting: {
      content_emails: true,
      invitation_emails: true,
      frequency: 'daily',
      categories: []
    }
  },
  validations: {
    email: true
  },
  availableCategories: []
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
  case 'SET_LOADING':
    return {
      ...state,
      loading: action.value
    };
  case 'UPDATE_META':
    return {
      ...state,
      meta: {
        ...state.meta,
        ...action.value
      }
    };
  case 'UPDATE_ALERT':
    return {
      ...state,
      alert: {
        ...state.alert,
        ...action.value
      }
    };
  case 'UPDATE_SETTINGS':
    return {
      ...state,
      settings: {
        ...state.settings,
        ...action.value
      }
    };
  case 'SET_AVAILABLE_CATEGORIES':
    return {
      ...state,
      availableCategories: action.value
    };
  case 'UPDATE_VALIDATIONS':
    return {
      ...state,
      validations: {
        ...state.validations,
        ...action.value
      }
    };
  case 'ADD_CATEGORY':
    return {
      ...state,
      settings: {
        ...state.settings,
        notification_setting: {
          ...state.settings.notification_setting,
          categories: [
            // Add fallback array here, since categories can be undefined from the server
            ...(state.settings.notification_setting.categories || []),
            action.value
          ]
        }
      }
    };
  case 'REMOVE_CATEGORY':
    return {
      ...state,
      settings: {
        ...state.settings,
        notification_setting: {
          ...state.settings.notification_setting,
          categories: state
            .settings
            .notification_setting
            .categories
            .filter(cat => cat.id != action.value.id)
        }
      }
    };
  case 'UPDATE_NOTIFICATIONS':
    return {
      ...state,
      settings: {
        ...state.settings,
        notification_setting: {
          ...state.settings.notification_setting,
          ...action.value
        }
      }
    };
  default:
    return state;
  }
};

const AccountSettingsStore = createStore(
  reducer,
  window && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ name: 'Redux.AccountSettingsStore' }) // Enable redux devtools middleware
);

export default AccountSettingsStore;