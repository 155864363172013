import React from 'react';
import PropTypes from 'prop-types'
import { FileUploader, Csrf } from './utils';

export default class Upload extends React.Component {
  constructor(props) {
    super(props);

    this.chooseFiles = this.chooseFiles.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.dropFiles = this.dropFiles.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
    this.preventDefaults = this.preventDefaults.bind(this);
    this.render = this.render.bind(this);
    this.renderDefault = this.renderDefault.bind(this);
    this.renderFailure = this.renderFailure.bind(this);
    this.renderSuccess = this.renderSuccess.bind(this);
    this.renderUploading = this.renderUploading.bind(this);
    this.toggleDragOver = this.toggleDragOver.bind(this);
    this.uploadFile = this.uploadFile.bind(this);

    this.state = {
      uploading: false,
      dragOver: false,
      success: false,
      failure: false,
      fileTypes: this.props.fileTypes || 'image/*, .xlsx, .xls, , .doc, .docx, .ppt, .pptx, .txt, .pdf, .csv',
      errors: []
    }
  }

  componentDidMount() {
    let uploadArea = document.getElementById('uploadArea');

    ['drag', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(eventName => {
      uploadArea.addEventListener(eventName, this.preventDefaults, false);
    });

    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      uploadArea.addEventListener(eventName, this.toggleDragOver, false);
    });

    uploadArea.addEventListener('drop', this.dropFiles, false);
  }

  preventDefaults() {
    event.preventDefault();
    event.stopPropagation();
  }

  toggleDragOver() {
    this.setState({ dragOver: event.type === 'dragenter' || event.type === 'dragover' ? true : false});
  }

  uploadFile(file, resolve) {
    let formData = new FormData();
    const fileParam = this.props.fileParam || 'file';
    formData.append(fileParam, file);

    const additionalParams = this.props.params || {};
    Object.keys(additionalParams).forEach(key => {
      formData.append(_.snakeCase(key), additionalParams[key]);
    });

    formData.append('authenticity_token', Csrf()['authenticity_token']);

    FileUploader().request({
      url: this.props.uploadPath,
      method: 'POST',
      data: formData,
      processData: false,
      contentType: 'multipart/form-data'
    }).then((response) => {
      if(this.props.callback){
        this.props.callback(response.data)
      }
      resolve({status: 'success', file: file.name});
    }).catch((e) => {
      resolve({status: 'failure', file: file.name});
    });
  }

  handleFiles(files) {
    this.setState({
      success: false,
      failure: false,
      errors: []
    });

    if (files.length > 0) {
      this.setState({ uploading: true });

      let uploads = ([...files]).map((file) => {
        return new Promise((resolve) => {
          this.uploadFile(file, resolve);
        });
      })

      Promise.all(uploads).then(results => {
        // Return early to avoid post-unmount state changes
        if(this.unmounting) {
          return results;
        }

        var errors = [];

        results.forEach((arr) => {
          if (arr.status === 'failure') {
            errors.push(arr.file);
          }
        });

        if (errors.length > 0) {
          this.setState({
            failure: true,
            uploading: false,
            errors: errors
          });
        } else {
          this.setState({
            success: true,
            uploading: false
          });

          this.timeoutHandle = setTimeout(() => {
            this.setState({ success: false });
          }, 3000);
        }

        if (this.props.dataTable) {
          $(this.props.dataTable).DataTable().ajax.reload();
        }
      });
    }
  }

  componentWillUnmount() {
    this.unmounting = true;
  }

  dropFiles() {
    this.handleFiles(event.dataTransfer.files);
  }

  chooseFiles() {
    document.getElementById('chooseFile').click();
  }

  renderDefault() {
    return (
      <div className='upload_message upload_message--default '>
        <i className='fas fa-cloud-upload-alt' aria-hidden='true'></i>
        <span className='text'>Drop files here to upload or <a>choose files</a></span>
      </div>
    );
  }

  renderUploading() {
    return (
      <div className='upload_message upload_message--uploading'>
        <i className='fas fa-circle-notch fa-spin' aria-hidden='true'></i>
        <span className='text'>Uploading&hellip;</span>
      </div>
    );
  }

  renderSuccess() {
    return (
      <div className='upload_message upload_message--success'>
        <i className='fas fa-check-circle' aria-hidden='true'></i>
        <span className='text'>Upload Successful!</span>
      </div>
    );
  }

  renderFailure() {
    return (
      <div className='upload_message upload_message--failure'>
        <i className='fas fa-times-circle' aria-hidden='true'></i>
        <div className='errors'>
          {this.state.errors.map((error, i) => <span key={i}>{error}</span>)}
        </div>
        <span className='text'>Failed to upload.</span>
      </div>
    );
  }

  render() {
    let dragOverClass = this.state.dragOver ? 'hover' : '';
    let renderState = this.renderDefault();

    switch(true){
      case this.state.uploading:
      renderState = this.renderUploading();
      break;
      case this.state.success:
      renderState = this.renderSuccess();
      break;
      case this.state.failure:
      renderState = this.renderFailure();
      break;
    }

    return (
      <form id='uploadArea'
            className={`upload_area ${dragOverClass}`}
            onClick={() => this.chooseFiles()}>
        {renderState}
        <input id='chooseFile'
               className='upload_file'
               type='file'
               onChange={(e) => this.handleFiles(e.target.files)}
               accept={this.props.fileTypes}
               style={{'display': 'none'}}
               multiple />
      </form>
    );
  }
}

Upload.propTypes = {
  uploadPath: PropTypes.string.isRequired,
  fileParam: PropTypes.string,
  callback: PropTypes.func.isRequired,
  params: PropTypes.any
};
