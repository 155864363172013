import React from 'react';
import DynamicForm from './DynamicForm';

export default class PaymentsNameSetup extends DynamicForm {
  getFormFields() {
    return [
      { displayName: 'First Name', fieldName: 'firstName', required: true },
      { displayName: 'Last Name', fieldName: 'lastName', required: true },
      {
        displayName: 'Name on Bank Account',
        fieldName: 'accountName',
        required: true
      },
      {
        displayName: 'Contact Phone Number',
        fieldName: 'phone',
        required: true,
        regex: /^\d?\d\d\d-?\d\d\d\-?\d\d\d\d$/,
        regexMessage: 'Please enter your phone number in the format XXX-XXX-XXXX or XXXXXXXXXX'
      }
    ];
  }
}
