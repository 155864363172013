class window.AdminGroups

  @index: ->
    DataTableUtils.datatable $('.datatable'),
      bServerSide: true
      sDom: '<"dt-length col-xs-2 col-sm-3"l><"dt-filter col-xs-10 col-sm-5"f>rt<"dt-info col-xs-12 col-sm-4"i><"dt-pagination col-xs-12 col-sm-8"p>'

  @show: ->
    group_id = $("#new_user_area").data('groupid')

    $("#user-group-search").submit (e) ->
      e.preventDefault()

      $("#new_user_area").html("")

      $.ajax(
        url: location.origin + "/search.json"
        data: $("#user-group-search").serialize()
        method: 'GET'
        success: (data) ->
          $.each data.results, ( i, user ) ->
            result = "<div class='row'>
                        <div class='col-md-12'>#{user.full_name}</div>
                      </div>
                      <div class='row'>
                        <div class='col-md-6'>
                          <img src=#{user.profile_picture.thumb.url}>
                        </div>
                        <div class='col-md-6'>
                          <ul>
                            <li><a data-method='patch' href='/admin/groups/#{group_id}/update_user?level=admin;user_id=#{user.id}' rel='nofollow'>Add as Admin</a></li>
                            <li><a data-method='patch' href='/admin/groups/#{group_id}/update_user?level=manager;user_id=#{user.id}' rel='nofollow'>Add as Manager</a></li>
                            <li><a data-method='patch' href='/admin/groups/#{group_id}/update_user?level=member;user_id=#{user.id}' rel='nofollow'>Add as Member</a></li>
                          </ul>
                        </div>
                      </div>"
            $("#new_user_area").append(result)
        )

  @new: ->
    @form()

  @create: ->
    @form()

  @edit: ->
    @form()

  @update: ->
    @form()

  @form: ->
    $('#group_description').redactor
      plugins: plugin_list
    FormUtils.filestyle_settings()
