import React from 'react';

export default class RoundTasksNumber extends React.Component {
  changeHandler(event, value=null) {
    const { index, name } = this.props;

    if (!value) {
      value = event.target.value;
    }

    this.props.updateField(name, value, index);
  }

  render() {
    const { name, type, required, min, error, value, disabled } = this.props;
    const errorClass = error && error.length > 0 ? 'input-error' : '';

    return(
      <div>
        <input name={name}
               type={type || 'number'}
               required={required}
               onChange={(e) => this.changeHandler(e)}
               value={value  === undefined || value === null  ? '' : value}
               min={min || 0}
               disabled={disabled}
               className={`form-control ${errorClass}`}
               />
      </div>
    )
  }
};

RoundTasksNumber.displayName = 'RoundTasks.Number';
