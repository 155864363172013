import React from 'react';
import { Provider } from 'react-redux';
import UserTableContainer from './Container.jsx'
import BasicWrapperStore from '../redux/shared/BasicWrapperStore';

class UserTableWrapper extends React.Component {
  render() {
    return (
      <Provider store={BasicWrapperStore}>
        <UserTableContainer {...this.props} />
      </Provider>
    );
  }
};

UserTableWrapper.displayName = 'UserTable.Wrapper';
export default UserTableWrapper;