import React from 'react';
import { connect } from 'react-redux';
import ConversationsMessageRecipients from './MessageRecipients'
import thumbProfilePic from '../../images/fallback/thumb_profile_pic.jpg'

class ConversationsMessageNavBar extends React.Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.showConversationList = this.showConversationList.bind(this);
    this.lastParticipant = this.lastParticipant.bind(this);
    this.renderMessageNavBar = this.renderMessageNavBar.bind(this);
  }

  showConversationList(){
    this.props.setSelected({selected: false});
  }

  lastParticipant(participants){
    if (participants.length == 0){
      const deleted_participant = {'avatar_url': thumbProfilePic, 'full_name': 'Deleted User'};
      return deleted_participant;
    } else if(participants.length == 1){
      return participants[0];
    } else if(participants.length > 1) {
      this.props.conversation.messages.map((message) => {
        if (!!message.author && message.author.current_user == false) {
          return this.props.conversation.messages[0].author
        }
      });
      return participants[0];
    }
  }

  renderMessageNavBar(){
    const conversation = this.props.conversation || {};
    const {show_recipients} = this.props;
    const all_participants = Object.keys(conversation).length == 0 ? {}: conversation.participants;
    const participants = Object.values(all_participants).filter(participant => participant.current_user == false) || [];
    const participant_count = participants.length;
    const last_participant = this.lastParticipant(participants);
    if(show_recipients){
      return <ConversationsMessageRecipients participants={participants}/>
    }else {
      return (
        <div className='conversation-window-header'>
          <div className="participants">
            <span className='conversation-desktop-header' onClick={() => (this.showConversationList())}>
              <i className='fas fa-chevron-left'/>
            </span>
            <div className="conversation-avatar" style={{backgroundImage: `url(${last_participant.avatar_url})`}} />
            <div className='conversation-message-info'>
              <div className='sender-names'>
                <h4 className='sender-name'>{last_participant.full_name}</h4>
                <h4 className='sender-count'>{ participant_count > 1 && ` +${participant_count - 1}` }</h4>
              </div>
              <span className='updated-at-text'>
                  {conversation.relative_time}
              </span>
            </div>
            <span className='message-participants-info'>
              {participant_count > 1 &&
              <a className='show-recipients-button' type='button'
                      onClick={() => this.props.toggleShowRecipients()}>
                <i className='fas fa-info-circle fa-2x'></i>
              </a>
              }
            </span>
          </div>
        </div>
      )
    }
  }

  render(){
    const {selected, loading} = this.props;
    if(selected && !loading){
      return this.renderMessageNavBar();
    }else{
      return null;
    }
  }

  static mapStateToProps(state, myProps) {
    return {
      selected: state.selected,
      loading: state.loading,
      conversation: state.conversation,
      recipients: state.recipients,
      show_recipients: state.show_recipients,
      ...myProps
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      setSelected: data => dispatch({type: 'SET_SELECTED', value: data}),
      setRecipients: data => dispatch({type: 'SET_RECIPIENTS', value: data}),
      toggleShowRecipients: () => dispatch({type: 'SHOW_RECIPIENTS'})
    };
  }
}

ConversationsMessageNavBar.displayName = 'Conversations.MessageNavBar';

export default connect(
  ConversationsMessageNavBar.mapStateToProps,
  ConversationsMessageNavBar.mapDispatchToProps
)(ConversationsMessageNavBar);