import React from 'react';
import PropTypes from 'prop-types'

// Like AUTH_URLS, but for pre-existing users
const LOGIN_URLS = {
  facebook_auth: '/auth/facebook_page?external_login=true&scope=public_profile',
  youtube_auth: '/auth/google_youtube?external_login=true',
  instagram_auth: '/auth/facebook_instagram_business?external_login=true'
};

  // Depends on AUTH_URLS defined in new_member_landing.js.jsx
export default class CommunityLoginsEntryButtons extends React.Component {
  constructor(props) {
    super(props);

    this.authListener = this.authListener.bind(this);
    this.createHandler = this.createHandler.bind(this);
    this.handleAuth = this.handleAuth.bind(this);
    this.render = this.render.bind(this);
  }

  handleAuth(authType) {
    window.localStorage.setItem(authType, null)
    window.addEventListener('storage', () => this.authListener(authType))
    window.open(AUTH_URLS[authType], '_blank')
  }

  // Generate a handler for the click action
  // - if regular login, redirect to the oauth flow
  // - if new user, handle the new user signup process in a new tab
  createHandler(authType) {
    if(this.props.newUser) {
      return (e) => this.handleAuth(authType);
    }

    return (e) => {
      window.location.href = `${LOGIN_URLS[authType]}&community_id=${this.props.communityId}`;
    };
  }

  authListener(authType) {
    if (window.localStorage.getItem(authType) != 'null') {
      if(!window.localStorage.getItem('sf_community_id')) {
        window.localStorage.setItem('sf_community_id', this.props.communityId);
      }

      window.location.replace(`/sign_up?community_id=${this.props.communityId}`)
    }
  }

  render() {
    const disabled = this.props.preview || false;
    return <div className='community-logins entry-buttons'>
      <button className='btn facebook' onClick={this.createHandler('facebook_auth')} disabled={disabled}>
        <i className='fab fa-facebook'/> Facebook
      </button>
      <button className='btn google' onClick={this.createHandler('youtube_auth')} disabled={disabled}>
        <i className='fab fa-youtube'/> YouTube
      </button>
      <button className='btn instagram' onClick={this.createHandler('instagram_auth')} disabled={disabled}>
        <i className='fab fa-instagram'/> Instagram
      </button>
    </div>;
  }
}

CommunityLoginsEntryButtons.displayName = 'CommunityLogins.EntryButtons';
CommunityLoginsEntryButtons.propTypes = {
  newUser: PropTypes.bool.isRequired,
  communityId: PropTypes.number.isRequired
};
