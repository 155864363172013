import React from 'react';
import { Remote } from '../../../utils';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../LoadingSpinner';
import CampaignsRequirementsCard from '../Card';
import CampaignsRequirementsGeneralRequirementButton from '../general_requirements/GeneralRequirementButton';
import CampaignsRequirementsAddFromTemplatesButton from '../AddFromTemplatesButton';
import CampaignsRequirementsDeletedRequirements from '../DeletedRequirements';
import CampaignsRequirementsInitialDisplay from '../InitialDisplay';

class CampaignsRequirementsGeneralRequirementsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingTemplates: true
    }

    this.componentDidMount = this.componentDidMount.bind(this);
    this.loadGeneralTemplates = this.loadGeneralTemplates.bind(this);
    this.loadRoundTasks = this.loadRoundTasks.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    this.loadRoundTasks();
    this.loadGeneralTemplates();
  }

  loadRoundTasks(){
    this.props.startLoading()
    const params = $.param({
      api_action: `/round_tasks`,
      campaign_id: this.props.campaign_id
    });

    return Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'GET'
    }).then((response) => {
      this.props.setTasks(response.data);
      this.props.stopLoading();
    }).catch((error) => {
      console.log(error.response);
    });
  }

  loadGeneralTemplates() {
    const params = $.param({
      api_action: `/round_tasks`,
      community_id: this.props.communityIds,
      template_type: 'community_general'
    });

    return Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'GET'
    }).then((response) => {
      this.props.setGeneralTemplates(response.data.active);
      this.setState({ loadingTemplates: false });
    }).catch((error) => {
      console.log(error.response);
    });
  }

  render(){
    let requirementCount = this.props.generalRequirements ? this.props.generalRequirements.length : 0;
    if(this.props.loading) {
      return <LoadingSpinner />;
    } else if (requirementCount > 0) {
      return (
        <div className='instruction_notes_container' >
          {
            this.props.generalRequirements.map((requirement, index) =>
              <CampaignsRequirementsCard
                key={index}
                {...this.props}
                requirement={requirement}
              />
            )
          }

          <div className='display_buttons'>
            <CampaignsRequirementsGeneralRequirementButton {...this.props} />
            <CampaignsRequirementsAddFromTemplatesButton proxyUrl={this.props.proxyUrl}
                                                           campaign_id={this.props.campaign_id}
                                                           templateType='campaign_general'
                                                           loadingTemplates={this.state.loadingTemplates} />
          </div>
          <CampaignsRequirementsDeletedRequirements requirements={this.props.deletedGeneralRequirements}/>
        </div>
      );
    } else {
      return <CampaignsRequirementsInitialDisplay {...this.props} loadingTemplates={this.state.loadingTemplates}/>;
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      setGeneralTemplates: (templates) => dispatch({ type: 'SET_GENERAL_TEMPLATES', value: templates })
    };
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      generalTemplates: state.generalTemplates
    };
  }
}

CampaignsRequirementsGeneralRequirementsContainer.displayName = 'Campaigns.Requirements.GeneralRequirementsContainer';
export default connect(
  CampaignsRequirementsGeneralRequirementsContainer.mapStateToProps,
  CampaignsRequirementsGeneralRequirementsContainer.mapDispatchToProps
)(CampaignsRequirementsGeneralRequirementsContainer);
