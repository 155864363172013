import React from 'react';

export default class LivingStyleGuideColorSwatch extends React.Component {

  constructor(props) {
    super(props);

    this.getContrastTextColor = this.getContrastTextColor.bind(this);
  }

  getContrastTextColor(hexcolor){
    hexcolor = hexcolor.replace("#", "");
    var r = parseInt(hexcolor.substr(0,2),16);
    var g = parseInt(hexcolor.substr(2,2),16);
    var b = parseInt(hexcolor.substr(4,2),16);
    var brightness = ((r*299)+(g*587)+(b*114))/1000;
    return (brightness >= 145) ? '#3D425D' : '#FFFFFF';
}

  render() {
    const { swatch, isGradient } = this.props
    const hexTextColor = swatch.hex ? this.getContrastTextColor(swatch.hex) : '#3D425D'

    return (
      <div className={`swatch ${isGradient ? 'gradient' : ''}`}>
        <div className={`color ${swatch.backgroundClass}`}>
          { swatch.hex &&
            <span className='color-hex' style={{color: hexTextColor}}>
              {swatch.hex}
            </span>
          }
        </div>
        <code>{swatch.color}</code>
      </div>
    );
  }
}
LivingStyleGuideColorSwatch.displayName = 'LivingStyleGuide.ColorSwatch';