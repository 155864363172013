import React from 'react';
import { Provider } from "react-redux"
import RoundEdit from '../redux/RoundEdit';
import RoundsShow from './Show'

export default class RoundContainer extends React.Component {
  render() {
    return (
      <Provider store={RoundEdit}>
        <RoundsShow {...this.props} />
      </Provider>
    );
  }
};