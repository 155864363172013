import React from 'react';
import { connect } from 'react-redux';

class ConversationsMessageRecipients extends React.Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
  }

  render() {
    const {participants} = this.props;
    return (
      <div className='conversation-window-header'>
        <div className="participants">
          <div className='conversation-participants'>
            {
              participants.map(participant => (
                <div className="conversation-participant-card" key={participant.id}>
                  <div className="conversation-avatar"
                       key={participant.id}
                       style={{backgroundImage: `url(${participant.avatar_url})`}}
                  />
                  <h4>{participant.full_name}</h4>
                </div>
              ))}
          </div>
          <div className='recipients-close-button' onClick={() => this.props.toggleShowRecipients()}>
            <i className='fas fa-times fa-2x'></i>
          </div>
        </div>
      </div>
    )
  }

  static mapStateToProps(state, myProps) {
    return {
      conversation: state.conversation,
      show_recipients: state.show_recipients,
      ...myProps
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      toggleShowRecipients: () => dispatch({type: 'SHOW_RECIPIENTS'})
    };
  }
}

ConversationsMessageRecipients.displayName = 'Conversations.MessageRecipients';
export default connect(
  ConversationsMessageRecipients.mapStateToProps,
  ConversationsMessageRecipients.mapDispatchToProps
)(ConversationsMessageRecipients);
