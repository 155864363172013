import React from 'react';
import { Provider } from "react-redux"
import AdminCommunitiesStore from '../redux/AdminCommunitiesStore';
import CommunitiesEdit from './Edit'

export default class CommunitiesWrapper extends React.Component {
  render() {
    return (
      <Provider store={AdminCommunitiesStore}>
        <CommunitiesEdit {...this.props} />
      </Provider>
    );
  }
};

CommunitiesWrapper.displayName = 'Communities.Wrapper';

