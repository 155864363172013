import React from 'react';
import { Remote } from '../utils';

import RichTextEditor from '../RichTextEditor';
import SaveError from '../SaveError';
import SaveIndicator from '../SaveIndicator';
import MultiSelect from '../MultiSelect';
import SingleSelect from '../SingleSelect';
import CampaignsDeliverables from '../campaigns/Deliverables';
import CampaignsTimelines from '../campaigns/Timelines';

export default class CampaignsEdit extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        name: this.props.campaignAttributes.name || '',
        displayName: this.props.campaignAttributes.display_name || '',
        group: this.props.campaignAttributes.group || '',
        accountingCode: this.props.campaignAttributes.accounting_code || '',
        communities: this.props.campaignAttributes.communities || [],
        client: this.props.campaignAttributes.client || '',
        campaignOracleClientId: this.props.campaignAttributes.campaign_oracle_client_id || '',
        clients: this.props.campaignAttributes.clients || [],
        retailers: this.props.campaignAttributes.retailers || [],
        productCategoryId: this.props.campaignAttributes.product_category_id || '',
        productCategoryName: this.props.campaignAttributes.product_category_name || '',
        overview: this.props.campaignAttributes.overview,
        hashtag: this.props.campaignAttributes.hashtag,
        startDate: this.props.campaignAttributes.start_date || '',
        contentLiveDate: this.props.campaignAttributes.content_live_date || '',
        endDate: this.props.campaignAttributes.end_date || '',
        proxyUrl: this.props.campaignAttributes.proxy_url || '',
        draft_approval_url: this.props.campaignAttributes.draft_approval_url || '',

        casters: this.props.campaignAttributes.casters || [],
        managers: this.props.campaignAttributes.managers || [],
        executives: this.props.campaignAttributes.executives || [],
        salesSupport: this.props.campaignAttributes.sales_support || [],
        analysts: this.props.campaignAttributes.analysts || [],
        mediaOpsManagers: this.props.campaignAttributes.media_ops_managers || [],
        adStrategists: this.props.campaignAttributes.ad_strategists || [],
        optimizationLeads: this.props.campaignAttributes.optimization_leads || [],
        influencerApprovals: this.props.campaignAttributes.influencer_approvals || [],

        location: this.props.campaignAttributes.location || '',
        department: this.props.campaignAttributes.department || '',
        expenseAccount: this.props.campaignAttributes.expense_account || '',

        influencerDashboardDisplay: this.props.campaignAttributes.influencer_dashboard_display || '',
        draftDashboardDisplay: this.props.campaignAttributes.draft_dashboard_display || '',

        clientModalName: '',
        clientModalOracleId: '',
        clientModalMode: '',

        clientOptions: this.props.campaignAttributes.client_options || [],
        additionalClientOptions: this.props.campaignAttributes.additional_client_options || [],

        saving: false,
        saveType: 'info',
        saveText: '',
        saveErrors: null,
        draftApprovers: [],
        draftApprovalAccess: this.props.campaignAttributes.draft_approval_ids || []
      }

      this.debouncedUpdate = _.debounce(this.saveCampaign, 500);

      this.filterDropdown = this.filterDropdown.bind(this);
      this.getFormFields = this.getFormFields.bind(this);
      this.renderField = this.renderField.bind(this);
      this.formClass = this.formClass.bind(this);
      this.onChange = this.onChange.bind(this);
      this.changeHandler = this.changeHandler.bind(this);
      this.renderValidations = this.renderValidations.bind(this);
      this.validate = this.validate.bind(this);
      this.renderTextBox = this.renderTextBox.bind(this);
      this.renderMultiSelect = this.renderMultiSelect.bind(this);
      this.updateState = this.updateState.bind(this);
      this.renderSingleSelect = this.renderSingleSelect.bind(this);
      this.render = this.render.bind(this);
      this.renderRedactorBox = this.renderRedactorBox.bind(this);
      this.renderDateBox = this.renderDateBox.bind(this);
      this.salesforceIdPresent = this.salesforceIdPresent.bind(this);
      this.renderModal = this.renderModal.bind(this);
      this.saveClient = this.saveClient.bind(this);
      this.updateClients = this.updateClients.bind(this);
      this.setClientModalMode = this.setClientModalMode.bind(this);
      this.flash = this.flash.bind(this);
      this.renderLabel = this.renderLabel.bind(this);
      this.hasError = this.hasError.bind(this);
      this.confirmClose = this.confirmClose.bind(this);
      this.fetchClientInfo = this.fetchClientInfo.bind(this);
      this.draftApprovals = this.draftApprovals.bind(this);

      this.fetchClientInfo();
    }

    draftApprovals(){
      const {draftDashboardDisplay, draftApprovers, draftApprovalAccess} = this.state;
      if(draftDashboardDisplay === 'draft_approval'){
        return draftApprovers.filter(approver => draftApprovalAccess.includes(approver.id));
      }else{
        this.setState({draftApprovalAccess: []});
        return [];
      }
    }

    filterDropdown(items, text, callback){
      if(!text){
        callback(items);
      }

      callback(items.filter(x => x.name?.toLowerCase().includes(text.toLowerCase())));
    }

    salesforceIdPresent(){
      return this.props.campaignAttributes.salesforce_id !== null;
    }

    fetchClientInfo(){
      let {client, clients} = this.state;
      this.setState({draftApprovalAccess: []});

      let clientParams = {client_ids: clients.concat(client).filter(Number)};
      Remote().request({
        url: this.state.draft_approval_url,
        method: 'GET',
        data: clientParams,
      }).then((response) => {
        this.setState({draftApprovers: response.data});
      }).catch((error) => {
        console.log(error);
      })
    }

    confirmClose(event) {
      if (!this.validate()) {
        event.preventDefault();
        alert("Please enter all the required fields to prevent opportunity errors");
      }
    }

    getFormFields(){
      const campaignAttributes = this.props.campaignAttributes;
      return {
        overview: [
          {
            displayName: 'Name',
            fieldName: 'name',
            fieldType: 'text',
            disabled: () => { return this.salesforceIdPresent() },
            show: true
          },
          {
            displayName: 'Display Name',
            fieldName: 'displayName',
            fieldType: 'text',
            required: 'true',
            show: true
          },
          {
            displayName: 'Group',
            fieldName: 'group',
            fieldType: 'select',
            searchDropdown: (text,callback) => {
              this.filterDropdown(campaignAttributes.group_options, text, callback);
            },
            show: true
          },
          {
            displayName: 'Accounting Code',
            fieldName: 'accountingCode',
            fieldType: 'text',
            disabled: () => { return this.salesforceIdPresent() },
            show: true
          },
          {
            displayName: 'Dashboard Client',
            fieldName: 'client',
            required: true,
            fieldType: 'select',
            searchDropdown: (text,callback) => {
              this.filterDropdown(this.state.clientOptions, text, callback);
            },
            addButton: true,
            addButtonText: '+ New Client',
            show: true
          },
          {
            displayName: 'Oracle Client ID',
            fieldName: 'campaignOracleClientId',
            required: false,
            fieldType: 'text',
            disabled: () => { return true },
            showWarning: () => { return this.state.campaignOracleClientId == ''},
            warningMessage: "You must assign an Oracle Client ID in Salesforce before influencers can be activated and a pre-payment can be triggered.",
            show: true
          },
          {
            displayName: 'Additional Clients',
            fieldName: 'clients',
            fieldType: 'select',
            searchDropdown: (text,callback) => {
              this.filterDropdown(this.state.additionalClientOptions, text, callback);
            },
            multiSelect: true,
            addButton: true,
            addButtonText: '+ New Client',
            show: true
          },
          {
            displayName: 'Communities',
            fieldName: 'communities',
            required: true,
            fieldType: 'select',
            searchDropdown: (text,callback) => {
              this.filterDropdown(campaignAttributes.community_options, text, callback);
            },
            multiSelect: true,
            show: true
          },
          {
            displayName: 'Influencer Dashboard Display',
            fieldName: 'influencerDashboardDisplay',
            required: true,
            fieldType: 'select',
            searchDropdown: (text,callback) => {
              this.filterDropdown(campaignAttributes.influencer_dashboard_display_options, text, callback);
            },
            show: true
          },
          {
            displayName: 'Draft Dashboard Display',
            fieldName: 'draftDashboardDisplay',
            required: true,
            fieldType: 'select',
            searchDropdown: (text,callback) => {
              this.filterDropdown(campaignAttributes.draft_dashboard_display_options, text, callback);
            },
            show: true
          },
          {
            displayName: 'Draft Approval Access',
            fieldName: 'draftApprovalAccess',
            fieldType: 'select',
            show: (this.state.draftDashboardDisplay === 'draft_approval'),
            searchDropdown: (text,callback) => {
              this.filterDropdown(this.state.draftApprovers, text, callback);
            },
            multiSelect: true
          },
          {
            displayName: 'Retailers',
            fieldName: 'retailers',
            fieldType: 'select',
            searchDropdown: (text,callback) => {
              this.filterDropdown(campaignAttributes.retailer_options, text, callback);
            },
            multiSelect: true,
            show: true
          },
          {
            displayName: 'Product Category',
            fieldName: 'productCategoryId',
            fieldType: 'select',
            searchDropdown: (text,callback) => {
              this.filterDropdown(campaignAttributes.product_category_options, text, callback);
            },
            required: true,
            show: true
          },
          {
            displayName: 'Overview',
            fieldName: 'overview',
            fieldType: 'textarea',
            show: true
          },
          {
            displayName: 'Hashtag',
            fieldName: 'hashtag',
            fieldType: 'text',
            disabled: () => { return this.salesforceIdPresent() },
            show: true
          },
          {
            displayName: 'Kickoff Date',
            fieldName: 'startDate',
            fieldType: 'datepicker',
            required: true,
            show: true
          },
          {
            displayName: 'Content Live Date',
            fieldName: 'contentLiveDate',
            fieldType: 'datepicker',
            show: campaignAttributes.content_live_date,
            required: !!campaignAttributes.content_live_date
          },
          {
            displayName: 'Wrap Up Date',
            fieldName: 'endDate',
            fieldType: 'datepicker',
            required: true,
            show: true
          }
        ],
        roles:[
          {
            displayName: 'Influencer Casting',
            fieldName: 'casters',
            fieldType: 'select',
            searchDropdown: (text,callback) => {
              this.filterDropdown(campaignAttributes.caster_options, text, callback);
            },
            multiSelect: true,
            show: true
          },
          {
            displayName: 'Community Managers',
            fieldName: 'managers',
            fieldType: 'select',
            searchDropdown: (text,callback) => {
              this.filterDropdown(campaignAttributes.manager_options, text, callback);
            },
            multiSelect: true,
            show: true
          },
          {
            displayName: 'Client Services',
            fieldName: 'executives',
            fieldType: 'select',
            searchDropdown: (text,callback) => {
              this.filterDropdown(campaignAttributes.executive_options, text, callback);
            },
            multiSelect: true,
            show: true
          },
          {
            displayName: 'Sales Support',
            fieldName: 'salesSupport',
            fieldType: 'select',
            searchDropdown: (text,callback) => {
              this.filterDropdown(campaignAttributes.sales_support_options, text, callback);
            },
            multiSelect: true,
            show: true
          },
          {
            displayName: 'Analysts',
            fieldName: 'analysts',
            fieldType: 'select',
            searchDropdown: (text,callback) => {
              this.filterDropdown(campaignAttributes.analyst_options, text, callback);
            },
            multiSelect: true,
            show: true
          },
          {
            displayName: 'Media Ops Managers',
            fieldName: 'mediaOpsManagers',
            fieldType: 'select',
            searchDropdown: (text,callback) => {
              this.filterDropdown(campaignAttributes.media_ops_manager_options, text, callback);
            },
            multiSelect: true,
            show: true
          },
          {
            displayName: 'Ad Strategists',
            fieldName: 'adStrategists',
            fieldType: 'select',
            searchDropdown: (text,callback) => {
              this.filterDropdown(campaignAttributes.ad_strategist_options, text, callback);
            },
            multiSelect: true,
            show: true
          },
          {
            displayName: 'Optimization Leads',
            fieldName: 'optimizationLeads',
            fieldType: 'select',
            searchDropdown: (text,callback) => {
              this.filterDropdown(campaignAttributes.optimization_lead_options, text, callback);
            },
            multiSelect: true,
            show: true
          },
          {
            displayName: 'Influencer Approvals',
            fieldName: 'influencerApprovals',
            fieldType: 'select',
            searchDropdown: (text,callback) => {
              this.filterDropdown(campaignAttributes.influencer_approval_options, text, callback);
            },
            multiSelect: true,
            show: true
          }
        ],
        accounting:[
          {
            displayName: 'Location',
            fieldName: 'location',
            required: true,
            searchDropdown: (text,callback) => {
              this.filterDropdown(campaignAttributes.location_options, text, callback);
            },
            fieldType: 'select',
            show: true
          },
          {
            displayName: 'Department',
            fieldName: 'department',
            searchDropdown: (text,callback) => {
              this.filterDropdown(campaignAttributes.department_options, text, callback);
            },
            fieldType: 'select',
            show: true
          },
          {
            displayName: 'Expense Account',
            fieldName: 'expenseAccount',
            searchDropdown: (text,callback) => {
              this.filterDropdown(campaignAttributes.expense_account_options, text, callback);
            },
            fieldType: 'select',
            show: true
          }
        ]
      }
    }

    renderField(field) {
      switch(field.fieldType) {
        case 'select':
          if(field.multiSelect){
            return field.show ? this.renderMultiSelect(field) : null;
          }else{
            return field.show ? this.renderSingleSelect(field) : null;
          }
        case 'text':
          return field.show ? this.renderTextBox(field) : null;
        case 'textarea':
          return field.show ? this.renderRedactorBox(field) : null;
        case 'datepicker':
          return field.show ? this.renderDateBox(field) : null;
      }
    }

    formClass(field) {
      const hasError = !!this.renderValidations(field);
      return hasError ? 'form-group has-error' : 'form-group';
    }

    hasError(field){
      return !!this.renderValidations(field);
    }

    onChange(event, fieldName) {
      let result = {};
      result[fieldName] = event.target.value;

      // Validate the set state, so we can notify the parent of the validity
      this.setState(result, () => {
        result.valid = this.validate();
        if(result.valid){
          this.debouncedUpdate();
        }
      });
    }

    changeHandler(fieldName) {
      return (e) => this.onChange(e, fieldName);
    }

    validate() {
      // If we don't render anything for a field validation, it is valid
      const fields = this.getFormFields()['overview']
      fields.concat(this.getFormFields()['roles']);
      fields.concat(this.getFormFields()['accounting']);
      return fields.every((field) => !this.renderValidations(field));
    }

    renderValidations(field) {
      const fieldValue = this.state[field.fieldName];

      // Simple case, just display a message that the field is required
      if ((field.required && !fieldValue) || (field.required && field.fieldType == 'select' && field.multiSelect && fieldValue.length == 0)) {
        return (
          <span className='text-danger'>
            This field is required
          </span>
        );
      }

      // Validate only on frontend, because default value syncs from Salesforce
      if (field.fieldName == 'displayName' && fieldValue.length > 63) {
        return (
          <span className='text-danger'>
            Maximum characters for Display Name is 63. Current value is {fieldValue.length}
          </span>
        )
      }

      // More complex case -- try and match a regex, and if it fails display a custom message
      if (field.regex && field.regexMessage) {
        if(!(fieldValue || '').match(field.regex)) {
          return (
            <span className='text-danger'>
             {field.regexMessage}
            </span>
          );
        }
      }
    }

    renderLabel(field){
      if (field.required){
        return <label className="col-sm-3 control-label"><abbr title="required">*</abbr> {field.displayName}</label>
      }else{
        return <label className="col-sm-3 control-label">{field.displayName}</label>
      }
    }

    renderDateBox(field) {
      return (
        <div className={this.formClass(field)} key={field.fieldName}>
          {this.renderLabel(field)}
          <div className="col-sm-9">
            <input className="form-control" type="date" onChange={this.changeHandler(field.fieldName)} value={this.state[field.fieldName]}/>
            {this.renderValidations(field)}
          </div>
        </div>
      );
    }

    renderTextBox(field) {
      const disabled = field.disabled && field.disabled() ? "disabled" : "";
      const showWarningMessage = field.showWarning && field.showWarning();
      return (
        <div className={this.formClass(field)} key={field.fieldName}>
          {this.renderLabel(field)}
          <div className="col-sm-9">
            <input className="form-control" disabled={disabled} onChange={this.changeHandler(field.fieldName)} value={this.state[field.fieldName]}/>
            {
              showWarningMessage && <div className="alert alert-warning mt-10">
                <i className="fas fa-exclamation-triangle fa-fw"/> {field.warningMessage}
              </div>
            }
            {this.renderValidations(field)}
          </div>
        </div>
      );
    }

    renderRedactorBox(field) {
      return (
        <div className={this.formClass(field)} key={field.fieldName}>
            {this.renderLabel(field)}
            <div className="col-sm-9">
              <RichTextEditor onUpdate={(html) => this.updateState(field, html)} value={this.state[field.fieldName]}/>
              {this.renderValidations(field)}
            </div>
        </div>
      );
    }

    renderMultiSelect(field){
      const addButtonStyle = {
        marginTop: '10px'
      }
      return (
        <div key={field.fieldName} className={this.formClass(field)}>
          {this.renderLabel(field)}
          <div className="col-sm-9">
            <MultiSelect search={field.searchDropdown}
                          selected={this.state[field.fieldName]}
                          onUpdate={(items) => this.updateState(field, items)}
                          placeholder='Select Option(s)'
                          clearInvalidInput={true}
                          hasError={this.hasError(field)}/>
            {this.renderValidations(field)}
            { field.addButton && <button onClick={() => this.setClientModalMode('clients')} className={`btn btn-xs btn-primary`}
                                          style={addButtonStyle}
                                          data-toggle='modal'
                                          data-target='#add_client_modal'>
                                          {field.addButtonText}
                                        </button>
            }
          </div>
        </div>
        );
    }

    updateState(field, value) {
      let result = {};
      result[field.fieldName] = value;
      this.setState(result, () => {
        result.valid = this.validate();
        if(_.includes(['client', 'clients'], field.fieldName)){
          this.fetchClientInfo();
        }
        if(result.valid){
          this.debouncedUpdate();
        }
      });
    }

    renderSingleSelect(field) {
      const addButtonStyle = {
        marginTop: '10px'
      }
      return (
        <div key={field.fieldName} className={this.formClass(field)}>
          {this.renderLabel(field)}
          <div className="col-sm-9" >
            <SingleSelect search={field.searchDropdown}
                          selected={this.state[field.fieldName]}
                          onUpdate={(item) => this.updateState(field, item)}
                          placeholder='Select One'
                          hasError={this.hasError(field)}/>
            {this.renderValidations(field)}
            { field.addButton && <button onClick={() => this.setClientModalMode('client')} className={`btn btn-xs btn-primary`}
                                          style={addButtonStyle}
                                          data-toggle='modal'
                                          data-target='#add_client_modal'>
                                          {field.addButtonText}
                                        </button>
            }
          </div>
        </div>
      );
    }

    updateClients(newClient){
      let allClients = this.state.clientOptions;
      allClients.push(newClient)
      this.setState({clientOptions: allClients, additionalClientOptions: allClients});
    }

    setClientModalMode(mode){
      this.setState({clientModalName: '', clientModalMode: mode});
    }

    saveClient(){
      this.setState({
        saving: true,
        saveType: 'info',
        saveText: 'Adding client...',
        saveErrors: null
      });

      const _this = this;
      const addNewClientPath = `/admin/campaigns/${this.props.campaignAttributes.id}/add_new_client`
      Remote().request({
          url: addNewClientPath,
          method: 'POST',
          data: { client_name: this.state.clientModalName },
        }).then((data) => {
          _this.updateClients(data)
            if(_this.state.clientModalMode == 'client'){
              _this.updateState({ fieldName:'client'}, data.value);
            }else if(_this.state.clientModalMode == 'clients'){
              let additionalClients = _this.state.clients;
              additionalClients.push(data.value);
              _this.updateState({ fieldName:'clients'}, additionalClients);
            }
            this.flash({
              saveText: 'Successfully added client.',
              saveType: 'success'
            }, 3000);
          }).catch((error) => {
            this.flash({
              saveText: 'Failed to add client! Please correct these errors:',
              saveType: 'failure',
              saveErrors: error
            }, 5000);
          });
    }

    flash(newState, duration) {
      this.setState(newState, () => setTimeout(() => this.setState({
        saving: false
      }), duration));
    }

    saveCampaign(){
      this.setState({
        saving: this.state.location,
        saveType: 'info',
        saveText: 'Saving campaign...',
        saveErrors: null
      });

      const _this = this;
      const saveCampaignPath =`/admin/campaigns/${this.props.campaignAttributes.id}/save_campaign`

      Remote().request({
          url: saveCampaignPath,
          method: 'POST',
          data: {
            name: this.state.name,
            display_name: this.state.displayName,
            group_id: this.state.group,
            accounting_code: this.state.accountingCode,
            community_ids: this.state.communities,
            client_id: this.state.client,
            client_ids: this.state.clients,
            retailer_ids: this.state.retailers,
            product_category_id: this.state.productCategoryId,
            product_category_name: this.state.productCategoryName,
            overview: this.state.overview,
            hashtag: this.state.hashtag,
            start_date: this.state.startDate,
            content_live_date: this.state.contentLiveDate,
            end_date: this.state.endDate,
            caster_ids: this.state.casters,
            manager_ids: this.state.managers,
            executive_ids: this.state.executives,
            sales_support_ids: this.state.salesSupport,
            analyst_ids: this.state.analysts,
            media_ops_manager_ids: this.state.mediaOpsManagers,
            ad_strategist_ids: this.state.adStrategists,
            optimization_lead_ids: this.state.optimizationLeads,
            influencer_approvals: this.state.influencerApprovals,
            location_id: this.state.location,
            department_id: this.state.department,
            expense_account_id: this.state.expenseAccount,
            influencer_dashboard_display: this.state.influencerDashboardDisplay,
            draft_dashboard_display: this.state.draftDashboardDisplay,
            draft_approvers: this.state.draftApprovalAccess,
            draft_approvals: this.draftApprovals()
          },
      }).then(response => this.flash({
        saveText: 'Successfully saved campaign.',
        saveType: 'success'
      }, 3000)).catch(error => this.flash({
        saveText: 'Failed to save campaign! Please correct these errors:',
        saveType: 'failure',
        saveErrors: error
      }, 5000)
    )};

    changeHandlerModal(field, e){
      this.setState({ [field]: e.target.value });
    }

    renderModal(){
      const buttonStyle = {
        paddingTop: '10px'
      }
      return(
      <div className="modal" id="add_client_modal">
        <div className="modal-content modal-dialog">
          <div className="modal-header">
            <button className='close' data-dismiss='modal'><i className="fas fa-times"></i></button>
            <h4>Add Client</h4>
          </div>
          <div className="modal-body">
              <label><abbr title="required">*</abbr> Name</label>
              <input className="form-control" type="text" onChange={(e) => this.changeHandlerModal('clientModalName', e)} value={this.state.clientModalName}/>

              <div className="text-center" style={buttonStyle}>
                <button className='btn btn-default' data-dismiss='modal'>Cancel</button>
                <button className="btn btn-primary" data-dismiss='modal' onClick={() => this.saveClient()}>Save</button>
              </div>
          </div>
        </div>
      </div>
      );
    }

    render() {
      const overviewFields = this.getFormFields()['overview'];
      const roleFields = this.getFormFields()['roles'];
      const accountingFields = this.getFormFields()['accounting'];
      const divStyle = {
          paddingTop: '10px'
      }
      const redirectToClose = `/admin/campaigns/${this.props.campaignAttributes.id}`
      return (
        <div className="col-sm-12" id="campaign_edit_form">
          <legend>Overview</legend>
          <fieldset className="row">
            {overviewFields.map((field) => this.renderField(field))}
          </fieldset>
          <CampaignsTimelines timelines={this.props.campaignAttributes.timelines} campaignId={this.props.campaignAttributes.id}/>
          <CampaignsDeliverables deliverables={this.props.campaignAttributes.deliverables}/>
          <legend>Roles</legend>
          <fieldset className="row">
            {roleFields.map((field) => this.renderField(field))}
          </fieldset>
          <legend>Accounting</legend>
          <fieldset className="row">
            {accountingFields.map((field) => this.renderField(field))}
          </fieldset>
          <div className="text-center" style={divStyle}><a className="btn btn-primary" href={redirectToClose} onClick={this.confirmClose}>Close</a></div>
          {this.renderModal()}
          {
            this.state.saving &&
            <SaveIndicator type={this.state.saveType}>
              {this.state.saveText}
              {this.state.saveErrors && <SaveError errors={this.state.saveErrors}/>}
            </SaveIndicator>
          }
        </div>
      );
    }
  }