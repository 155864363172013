import React from 'react';
import PropTypes from 'prop-types'
import FloatingBox from '../FloatingBox'

export default class RoundsValidation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hovered: false
    };

    this.render = this.render.bind(this);
    this.validations = this.validations.bind(this);
  }

  render() {
    const defaultPosition = {
      position: 'relative',
      paddingLeft: '4px'
    };

    const requirementInputPosition = {
      position: 'absolute',
      right: '-2px',
      top: '7px'
    };

    const containerStyle = this.props.elementType == 'requirement-input' ? requirementInputPosition : defaultPosition;

    if(!this.props.validations || !this.validations()) {
      return null;
    }

    return (
      <span style={containerStyle}>
        <i
          className='fas fa-exclamation-triangle text-danger'
          onMouseEnter={() => this.setState({ hovered: true })}
          onMouseLeave={() => this.setState({ hovered: false })}
          />

        {
          this.state.hovered &&
          <FloatingBox type='error' message='Validation failed' placement='right'>
            <ul>
              {this.validations().map(validation => <li key={validation}>{validation}</li>)}
            </ul>
          </FloatingBox>
        }
      </span>
    );
  }

  validations() {
    return this.props.validations[this.props.field];
  }
};

RoundsValidation.displayName = 'Rounds.Validation';
RoundsValidation.propTypes = {
  field: PropTypes.string.isRequired,
  validations: PropTypes.any.isRequired
};

