import React from 'react';

export default class NavigationTabsLink extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e){
    let {onClickCallback, tab} = this.props;
    onClickCallback ? onClickCallback(tab) : null
  }

  render() {
    const {
      link,
      selected,
      title,
      count,
      hasError
    } = this.props;

    let addClass = selected ? 'active' : '';
    if(hasError) {
      addClass += ' has-error';
    }

    const href = !!link ? link : "#"
    const countBubble = count && count > 0 ? <div className='count-bubble'>{count}</div> : '';
    const errorIcon = hasError ? <i className='fas fa-exclamation-triangle'/> : null;

    return (
      <a
        href={href}
        onClick={(event) => this.handleClick(event)}
        className={`shoppertunity_nav--link ${addClass}`}
        // data-turbolinks="false"
      >
        {title}
        {countBubble}
        {errorIcon}
      </a>
    );
  }
}

NavigationTabsLink.displayName = 'NavigationTabs.Link';
