import React from 'react'

const CampaignsRequirementsTaskRequirementFields = {
  id: { 
    name: 'id',
    type: 'hidden',
    value: null
  },
  template_id: {
    name: "template_id",
    placeHolder: "Select or create new...",
    type: "select",
    required: true,
    multiSelect: false,
    options: [],
    error: false,
    value: null,
  },
  name: {
    name: "name",
    placeHolder: "Round Task Name",
    type: "text",
    required: true,
    value: null,
  },
  content_type_ids: {
    name: "content_type_ids",
    placeHolder: "Click to add...",
    type: "select",
    required: true,
    multiSelect: true,
    options: [],
    error: false,
    value: null,
  },
  syndication: {
    name: "syndication",
    placeHolder: "Select",
    type: "select",
    required: true,
    multiSelect: false,
    options: [
      { name: 'Primary', id: false },
      { name: 'Syndication', id: true }
    ],
    error: false,
    value: null,
  },
  description: {
    name: "description",
    placeHolder: "Add a description for the influencer...",
    value: '',
  },
  type: { 
    name: 'type',
    type: 'hidden',
    value: null
  },
  round_task_requirements_attributes: []
  //round_task_requirements_attributes: [{
  //  
  //}]
};

export default CampaignsRequirementsTaskRequirementFields;