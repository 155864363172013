import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import LoadingSpinner from '../../../LoadingSpinner'
import RoundTasksCommunitySelector from '../../../round_tasks/CommunitySelector'
import GeneralRequirementsRow from './Row'
import TableDeleteConfirmation from '../../../table/DeleteConfirmation'

class GeneralRequirementsIndex extends React.Component {
  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.loadCommunities = this.loadCommunities.bind(this);
    this.loadTasks = this.loadTasks.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    this.loadTasks();
    this.loadCommunities();

    // Initialize the state store with what we have as props
    this.props.updateMeta({
      baseUrl: this.props.baseUrl,
      proxyUrl: this.props.proxyUrl,
      currentUserId: this.props.currentUserId,
      selectedCommunityId: this.props.selectedCommunityId
    });
  }

  componentDidUpdate(prevProps) {
    if(this.props.selectedCommunityId != prevProps.selectedCommunityId) {
      this.loadTasks();
    }
  }

  render() {
    return (
      <div>
        <RoundTasksCommunitySelector type='General' />
        <table className='table table-striped'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Count</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {!this.props.loading && this.props.roundTasks.map(rt => <GeneralRequirementsRow key={rt.id} roundTask={rt}/>)}
          </tbody>
        </table>
        {this.props.loading && <LoadingSpinner />}
        <TableDeleteConfirmation
          deletionUrl={this.props.deletionUrl}
          message='Are you sure you want to remove the selected template?'
        />
      </div>
    );
  }

  loadTasks() {
    this.props.startLoading();

    const params = $.param({
      api_action: `/round_tasks`,
      template_type: 'community_general',
      community_id: [this.props.selectedCommunityId]
    });

    $.get(this.props.proxyUrl + '?' + params).then(data => {
      this.props.updateTasks(data.active);
      this.props.stopLoading();
    });
  }

  loadCommunities() {
    const params = $.param({
      api_action: `/users/${this.props.currentUserId}/communities`
    });

    $.get(this.props.proxyUrl + '?' + params).then(data => {
      this.props.updateCommunities(data);
      this.props.stopCommunityLoading();
    });
  }

  static mapStateToProps(state, myProps) {
    return {
      ...state,
      ...myProps,

      // Prefer the selected community over the default passed in
      selectedCommunityId: state.selectedCommunityId || myProps.selectedCommunityId
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateTasks: tasks => dispatch({ type: 'UPDATE_ROUND_TASKS', value: tasks }),
      startLoading: () => dispatch({ type: 'START_LOADING' }),
      stopLoading: () => dispatch({ type: 'STOP_LOADING' }),
      stopCommunityLoading: () => dispatch({ type: 'STOP_COMMUNITIES_LOADING' }),
      updateMeta: data => dispatch({ type: 'UPDATE_META', value: data }),
      updateCommunities: communities => dispatch({ type: 'UPDATE_COMMUNITIES', value: communities })
    };
  }
};

GeneralRequirementsIndex.displayName = 'GeneralRequirements.Index';
GeneralRequirementsIndex.propTypes = {
  proxyUrl: PropTypes.string.isRequired
};

export default connect(
  GeneralRequirementsIndex.mapStateToProps,
  GeneralRequirementsIndex.mapDispatchToProps
)(GeneralRequirementsIndex);
