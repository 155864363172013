import React from 'react';
import { Remote } from './utils';
import notif from "javascripts/notifIt.js"
import BidHistory from './BidHistory'

export default class AdminRoundBidForm extends React.Component {
  constructor(props) {
    super(props);
    let bid_info = {};
    this.props.round_bid_info.map( (x)=> {
      bid_info[x.id] = {
                         round_id: x.id,
                         round_bid_id: x.current_bid_id,
                         state: x.current_bid_state,
                         note: '',
                         amount: '',
                       };
    });

    this.state = {
      selectedRound: this.props.round_bid_info[0],
      statusOptions: this.props.bid_states,
      bidValues: bid_info,
    };
    this.handleRoundChange = this.handleRoundChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  };

  handleRoundChange(e){
    var rounds = this.props.round_bid_info;
    for(let i = 0; i < rounds.length; i++) {
      if(rounds[i].id == e.target.value){
        this.setState( {selectedRound: rounds[i]} );
        break;
      }
    };
  };

  handleSubmit(params, e) {
    e.preventDefault();
    var button = e.target;
    button.setAttribute("disabled", "disabled");

    Remote().request({
      url: `/admin/applicants/${this.props.shop_app_id}/submit_round_bid`,
      method: 'POST',
      dataType: 'json',
      data: { round_bid_params: params },
    }).then((response) => {
      notif({
        msg: response.data['notice'],
        type: 'notice',
        timeout: 3000
      });
    }).catch((error) => {
      button.removeAttribute("disabled");
      notif({
        msg: error.response.data['errors'], // May broke
        type: 'error',
        timeout: 3000
      });
    });
  };

  handleInputChange(field, e){
    var value = e.target.value;
    this.setState( prevState => ({
      bidValues: {
        ...prevState.bidValues,
        [this.state.selectedRound.id]: {
          ...prevState.bidValues[this.state.selectedRound.id],
          [field]: value,
        }
      }
    }));
  };

  render() {
    var selected_round = this.state.selectedRound;
    var round_bid_values = this.state.bidValues[selected_round.id];
    var restricted_access = selected_round['bid_restricted?']
    var disable_form = (restricted_access ? true : round_bid_values.state == 'admin_pending');
    round_bid_values.prev_amount = this.state.selectedRound.current_bid_amount;
    var round_options = this.props.round_bid_info.map(function(e) {
      return <option key={e.id} value={e.id}>{e.name}</option>
    });

    if(round_bid_values.state == 'accepted'){
      this.state.statusOptions.push(['Accepted', 'accepted']);
    };
    var state_options = this.state.statusOptions.map(function(e) {
      return <option key={e} value={e[1]}>{e[0]}</option>
    });

    return (
      <div className='admin_round_negotiation_form'>
        <div className="row">
          <div className="col-xs-12">
            <h1>{this.props.applicant.first_name}'s Bid</h1>
          </div>
        </div>


        <div className="row">
          <div className="col-xs-6 form-group form-inline">
            <label className='pr-2'>Round:</label>
            <select className="js_is_select2 form-control" onChange={this.handleRoundChange}>
              {round_options}
            </select>
          </div>
        </div>

        <div className="row pb-10">
          <div className="col-sm-3">
            <label>Current Bid</label>
            <div>{selected_round.current_bid_amount}</div>
          </div>

          <div className="col-sm-3">
            <label>What We Recommended</label>
            <div>{selected_round.recommended_bid_amount}</div>
          </div>

          <div className="col-sm-3">
            <label>Legacy Tier Price</label>
            <div>{selected_round.legacy_tier_amount}</div>
          </div>

          <div className="col-sm-3">
            <label>Diff From Tier Price</label>
            <div>{selected_round.bid_diff_amount}</div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-6 form-group form-inline">
            <label>Status:</label>
            <select className="js_is_select2 form-control" onChange={(e) => this.handleInputChange('state', e)} value={round_bid_values.state} disabled={restricted_access}>
              {state_options}
            </select>
          </div>
        </div>

        <div className="row pb-10">
          <div className="col-xs-6 form-group form-inline">
            <label>Amount:</label>
            <input type='text' className='form-control' onChange={(e) => this.handleInputChange('amount', e)} value={round_bid_values.amount} disabled={disable_form}/>
          </div>
        </div>

        <div className="row pb-10">
          <div className="col-xs-12">
            <label>Notes:</label>
            <textarea rows='3' className='form-control' onChange={(e) => this.handleInputChange('note', e)} value={round_bid_values.note} disabled={disable_form}/>
          </div>
        </div>

        <div className='text-center'>
          <button className='btn btn-primary' type='button' onClick={(e) => this.handleSubmit(round_bid_values, e)} disabled={disable_form}>
            Submit to Influencer
          </button>
        </div>

        <BidHistory history={selected_round.bid_history} />
      </div>
    );
  }

}
