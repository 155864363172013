import React from 'react';
import { Remote } from '../../utils';

import Upload from '../../Upload';
import RichTextEditor from '../../RichTextEditor';
import SaveIndicator from '../../SaveIndicator';
import SaveError from '../../SaveError';
import CampaignsTimelines from '../Timelines';
import CampaignsDetailsHubRequest from './HubRequest';

export default class CampaignsDetailsEdit extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        objectives: this.props.campaignAttributes.objectives || '',
        tactics: this.props.campaignAttributes.tactics || '',
        featuredProducts: this.props.campaignAttributes.featured_products || '',
        productImages: this.props.campaignAttributes.product_images || [],
        brandTheme: this.props.campaignAttributes.brand_theme || '',
        demographicTargeting: this.props.campaignAttributes.demographic_targeting || '',
        guardrails: this.props.campaignAttributes.guardrails || '',
        competitors: this.props.campaignAttributes.competitors || '',
        storeList: this.props.campaignAttributes.store_list || '',
        relatedUrls: this.props.campaignAttributes.related_urls || '',
        hubInfo: this.props.campaignAttributes.hub_info || '',
        disclosure: this.props.campaignAttributes.disclosure || '',
        otherInfo:  this.props.campaignAttributes.other_info || '',
        internalNotes: this.props.campaignAttributes.internal_notes || '',

        saving: false,
        saveType: 'info',
        saveText: '',
        saveErrors: null
      }

      this.debouncedUpdate = _.debounce(this.updateCampaignDetails, 500);

      this.getFormFields = this.getFormFields.bind(this);
      this.updateState = this.updateState.bind(this);
      this.renderField = this.renderField.bind(this);
      this.renderRedactorBox = this.renderRedactorBox.bind(this);
      this.renderUploadField = this.renderUploadField.bind(this);
      this.addProductImage = this.addProductImage.bind(this);
      this.saveIndicatorState = this.saveIndicatorState.bind(this);
      this.flash = this.flash.bind(this);
      this.render = this.render.bind(this);
    }

    getFormFields(){
        return (
            [
                {
                    displayName: 'Objectives',
                    fieldName: 'objectives',
                    fieldType: 'textarea'
                },
                {
                    displayName: 'Strategy And Key Deliverables',
                    fieldName: 'tactics',
                    fieldType: 'textarea'
                },
                {
                    displayName: 'Featured Products',
                    fieldName: 'featuredProducts',
                    fieldType: 'textarea'
                },
                {
                    displayName: 'Product Image(s)',
                    fieldName: 'productImages',
                    fieldType: 'upload',
                    tooltip: true
                },
                {
                    displayName: 'Brand Theme',
                    fieldName: 'brandTheme',
                    fieldType: 'textarea'
                },
                {
                    displayName: 'Demographic Targeting',
                    fieldName: 'demographicTargeting',
                    fieldType: 'textarea'
                },
                {
                    displayName: 'Guardrails',
                    fieldName: 'guardrails',
                    fieldType: 'textarea'
                },
                {
                    displayName: 'Competitors',
                    fieldName: 'competitors',
                    fieldType: 'textarea'
                },
                {
                    displayName: 'Store List',
                    fieldName: 'storeList',
                    fieldType: 'textarea'
                },
                {
                    displayName: 'Urls',
                    fieldName: 'relatedUrls',
                    fieldType: 'textarea'
                },
                {
                    displayName: 'Hub Info',
                    fieldName: 'hubInfo',
                    fieldType: 'textarea'
                },
                {
                    displayName: 'Disclosure',
                    fieldName: 'disclosure',
                    fieldType: 'textarea'
                },
                {
                    displayName: 'Other Info',
                    fieldName: 'otherInfo',
                    fieldType: 'textarea'
                },
                {
                    displayName: 'Internal Notes',
                    fieldName: 'internalNotes',
                    fieldType: 'textarea'
                }
            ]
        );
    }

    updateState(fieldName, value) {
        let result = {};
        result[fieldName] = value;
        this.setState(result, () => this.debouncedUpdate());
    }

    renderField(field) {
        switch(field.fieldType) {
            case 'textarea':
                return this.renderRedactorBox(field);
            case 'upload':
                return this.renderUploadField(field);
        }
    }

    updateCampaignDetails(){
        this.saveIndicatorState(true, 'info', 'Saving campaign details...', null);
        const _this = this;
        const updateCampaignDetailsPath = `/admin/campaigns/${this.props.campaignAttributes.id}/update_campaign_details`
        Remote().request({
          url: updateCampaignDetailsPath,
          method: 'POST',
          data: {
            objectives: this.state.objectives,
            tactics: this.state.tactics,
            featured_products: this.state.featuredProducts,
            brand_theme: this.state.brandTheme,
            demographic_targeting: this.state.demographicTargeting,
            guardrails: this.state.guardrails,
            competitors: this.state.competitors,
            store_list: this.state.storeList,
            related_urls: this.state.relatedUrls,
            hub_info: this.state.hubInfo,
            disclosure: this.state.disclosure,
            other_info: this.state.otherInfo,
            internal_notes: this.state.internalNotes
          },
        }).then((response) => this.flash({
            saveText: 'Successfully saved campaign details.',
            saveType: 'success'
        }, 3000)).catch(error => this.flash({
            saveText: 'Failed to save campaign details! Please correct these errors:',
            saveType: 'failure',
            saveErrors: error
        }, 5000));
    }

    saveIndicatorState(saving, saveType, saveText, saveErrors){
        this.setState({
            saving: saving,
            saveType: saveType,
            saveText: saveText,
            saveErrors: saveErrors
        });
    }

    flash(newState, duration) {
        this.setState(newState, () => setTimeout(() => this.setState({
            saving: false
        }), duration));
    }

    addProductImage(productImage){
        let currentProductImages = this.state.productImages;
        currentProductImages.push(productImage);
        this.setState({productImage: currentProductImages});
    }

    removeProductImage(productImage){
        this.saveIndicatorState(true, 'info', 'Removing product image...', null);
        const _this = this;
        const removeProductImagePath = `/admin/campaigns/${this.props.campaignAttributes.id}/remove_product_image`
        Remote().request({
            url: removeProductImagePath,
            method: 'POST',
            data: { product_image_id: productImage.id},

        }).then((response) => {
            const currentProductImages = _.filter(this.state.productImages, (image) => image.id != productImage.id)
                _this.setState({productImages: currentProductImages})
                this.flash({
                    saveText: 'Successfully removed product image.',
                    saveType: 'success'
                }, 3000)
        }).catch((error) => {
            this.flash({
                saveText: 'Failed to remove product image! Please correct these errors:',
                saveType: 'failure',
                saveErrors: error
            }, 5000);
        })
    }

    renderUploadPreview(productImage, index){
        return (
            <div className="image-display" key={index}>
                 <i onClick={(e) => this.removeProductImage(productImage)} className="fas fa-times-circle fa-fw text-danger icon--remove remove_fields js-remove-product-image"></i>
                 <img className="product_image" src={productImage.image.url}/>
            </div>
        );
    }

    renderUploadField(field){
        const stylePadding = {
            paddingTop: '10px',
            paddingBottom: '10px'
        }
        const addProductImagePath = `/admin/campaigns/${this.props.campaignAttributes.id}/add_product_image`
        return(
            <div key={field.fieldName}>
                <div className="form-group">
                    <label className="col-sm-3 control-label">{field.displayName}</label>
                    <div className="col-sm-9">
                        <Upload uploadPath={addProductImagePath}
                                fileTypes={'.gif, .jpg, .jpeg, .png'}
                                callback={this.addProductImage}/>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-sm-3">Image Preview(s)</label>
                    <div id="product-image-previews" className="col-sm-9">
                        {this.state[field.fieldName].map((productImage, i) => this.renderUploadPreview(productImage, i))}
                    </div>
                </div>
            </div>
        );
    }

    renderRedactorBox(field){
        const value = this.state[field.fieldName];
        return (
            <div key={field.fieldName} className="form-group">
                <label className="col-sm-3 control-label">{field.displayName}</label>
                <div className="col-sm-9">
                <RichTextEditor onUpdate={(html) => this.updateState(field.fieldName, html)} value={value}/>
                </div>
            </div>
        );
    }

    render(){
        const divStyle = {
            paddingTop: '10px'
        }
        const redirectToClose = `/admin/campaigns/${this.props.campaignAttributes.id}/details`
        return (
        <div id="edit-details-form" className="col-sm-12">
            {this.getFormFields().map((field) => this.renderField(field))}
            <CampaignsTimelines timelines={this.props.campaignAttributes.timelines} campaignId={this.props.campaignAttributes.id}/>
            <CampaignsDetailsHubRequest />
            <div className="text-center" style={divStyle}><a className="btn btn-primary" href={redirectToClose}>Close</a></div>
            {
                this.state.saving &&
                <SaveIndicator type={this.state.saveType}>
                    {this.state.saveText}
                    {this.state.saveErrors && <SaveError errors={this.state.saveErrors}/>}
                </SaveIndicator>
            }
        </div>
        );
    }
}