import React from 'react';
import HeaderSearchForm from './HeaderSearchForm';
import HeaderNotificationsDropdown from './NotificationsDropdown';
import HeaderDropDownElements from './DropdownElements';
import defaultProfileImage from '../../images/fallback/profile_pic.jpg';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.notificationRef = React.createRef();
    this.updateNotificationCount=this.updateNotificationCount.bind(this);
    this.renderNotificationCount=this.renderNotificationCount.bind(this);
    this.state={
      notificationCount: props.notification_count
    };
  }

  renderNotificationCount(notificationCount){
    if(notificationCount > 99){
      return <span className='notification_badge' title={notificationCount}>99+</span>;
    }else{
      return <span className='notification_badge'>{notificationCount}</span>;
    }
  }

  updateNotificationCount(notificationCount){
    this.setState({notificationCount});
  }

  render(){
    const { thumbnail,
            logo_path,
            logo_title,
            home_path,
            message_count,
            notification_dropdown_path,
            current_community,
            user_communities } = this.props;
    const {notificationCount} = this.state;
    let community_theme_settings = current_community.community_theme_settings
    let community_theme_image = community_theme_settings ? community_theme_settings.theme_image : null;

    return (
      <header className="navbar navbar-default" id="back_to_top_anchor">
        <div className="container">
          <div className="navbar-header">
            <div className="header--home-icons">
              {community_theme_image && community_theme_image.url !== null &&
                <a className="header--community-image" href={home_path}>
                  <img src={community_theme_image.url} alt='custom_community_header' />
                </a>
              }
              { ( !community_theme_image || community_theme_image.url === null) &&
                <a className="header--home-logo navbar-brand" href={home_path}>
                  <img src={logo_path} alt={logo_title} alt='social_fabric_header'/>
                </a>
              }
            </div>
            <button className="navbar-toggle mobile-nav-toggle-btn collapsed" data-target="#sofab-main-nav"
                    data-toggle="collapse" type="button">
              <span>Toggle navigation</span>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="sofab-main-nav">
            <nav className="nav header--sofab-top-nav">
              <div className="navbar-right">
                <a className="js_show_search_bar nav-action-icon" href="#" title="Search">
                  <i className="fas fa-fw fa-search hidden-xs" />
                </a>
                <HeaderSearchForm />

                <div className="count-bubble-group">
                  <a title="Messages" className="nav-action-icon" href="/conversations">
                    <i className="fas fa-fw fa-envelope hidden-xs" />
                    {
                      message_count > 0 && this.renderNotificationCount(message_count)
                    }
                  </a>
                </div>
                <div className="count-bubble-group dropdown"
                     data-url="/notifications/mark_as_seen"
                     id="notifications_counter">
                  <a className="dropdown-toggle hidden-xs" data-toggle="dropdown" href="#"
                     id="js_notification_dropdown_toggle" title="Notifications"
                     onClick={()=> {this.notificationRef.current.focus()}}
                  >
                    <i className="fas fa-fw fa-bell hidden-xs"></i>
                    {
                      notificationCount > 0 && this.renderNotificationCount(notificationCount)
                    }
                  </a>
                 <HeaderNotificationsDropdown notificationsDropdownPath={notification_dropdown_path}
                                               updateNotificationCount={this.updateNotificationCount}
                                               notificationCount={notificationCount}
                                               clickRef={this.notificationRef} />
                </div>
                <div className="dropdown user_dropdown pull-left" style={{marginTop: '10px'}}>
                  <a className="dropdown-toggle navatar" data-toggle="dropdown" href="#"
                     style={{backgroundImage: `url(${thumbnail ? thumbnail : defaultProfileImage})`}}
                     aria-expanded="false" />
                  <div className="dropdown-menu user_dropdown--menu">
                    <HeaderDropDownElements {...this.props} renderNotificationCount={this.renderNotificationCount} />
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    )
  }
}

Header.displayName = 'Header.Header';

