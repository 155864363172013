import React from 'react';
import RichTextEditor from '../RichTextEditor'
import SingleSelect from '../SingleSelect'

export default class InstructionNotesForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleCommunityChange = this.handleCommunityChange.bind(this);
    this.render = this.render.bind(this);
  }

  handleCommunityChange(id){
    const community = this.props.communityOptions.find(x => x.id === id);
    const value = community ? community.id : null
    this.props.updateField('community_id', value)
  }

  render() {
    const communityOptions = this.props.communityOptions;
    const selectedCommunity = this.props.form.community_id || ''
    return (
      <form className='form'>
        <div className='input_wrap'>
          <abbr title='required'>*</abbr>
          <label>Label</label>
          <input
            type='text'
            onChange={e => this.props.updateField('label', e.target.value)}
            placeholder='Enter Label'
            className='form-control'
            defaultValue={this.props.form.label}
            />
        </div>
        <div className='input_wrap'>
          <abbr title='required'>*</abbr>
          <label>Community</label>
          <SingleSelect
            search={(query, callback) => callback(communityOptions)}
            selected={selectedCommunity}
            onUpdate={this.handleCommunityChange}
            preload={communityOptions}
            placeholder='Select Community'
            showCaret={selectedCommunity ? false : true}
          />
        </div>
        <div className='input_wrap'>
          <abbr title='required'>*</abbr>
          <label>Description</label>
          <RichTextEditor
            placeholder='Enter Description'
            value={this.props.form.description}
            onUpdate={(html) => this.props.updateField('description', html)}
            />
        </div>
      </form>
    );
  }
}

InstructionNotesForm.displayName = 'InstructionNotes.Form';