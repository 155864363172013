import React from 'react';

const UserFormSection = ({ disabled, title, fields }) => {
  return (
    disabled ? <div className='col-md-offset-3 col-md-6 fade-transition' />
      : <div className='col-md-offset-3 col-md-6 fade-transition show'>
          <div className='card-plus clickable'>
            <div className='card-header'>
              { title }
            </div>
            <div className='card-body'>
              { fields }
            </div>
        </div>
      </div>
  );
}

export default UserFormSection;
UserFormSection.displayName = 'UserForm.Section';