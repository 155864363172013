jQuery.fn.dataTableExt.oApi.fnFilterOnReturn = function (oSettings) {
  var _that = this;

  this.each(function (i) {
    $.fn.dataTableExt.iApiIndex = i;
    var $this = this;
    var anControl = $('input', _that.fnSettings().aanFeatures.f);
    anControl
      .unbind('keyup search input')
      .bind('keypress', function (e) {
        if (e.which == 13) {
          $.fn.dataTableExt.iApiIndex = i;
          _that.fnFilter(anControl.val());
        }
      });
    return this;
  });
  return this;
};