import React from 'react';

import DatatablePagination from '../DatatablePagination'
import Row from './Row'

export default class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = ({
      conversations: this.props.conversations,
      perPage: this.props.meta.pagination.per_page,
      currentPage: this.props.meta.pagination.current_page,
      displayStart: 0
    });

    this.renderConversations = this.renderConversations.bind(this);
    this.renderPagination = this.renderPagination.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.render = this.render.bind(this);
  }

  render() {
    let showContainer = this.state.conversations.length > 0;
    return(
      <div>
        {!showContainer && <h4>You have no messages at this time.</h4>}
        {showContainer && this.renderPagination()}
        {showContainer && this.renderConversations()}
      </div>
    );
  }

  renderConversations() {
    return (
      <table className='table'>
        <thead>
          <tr>
            <th>From</th>
            <th>Opportunity</th>
            <th>Subject</th>
            <th>Last Activity (Central Time)</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            _.map(this.state.conversations, (conversation) =>
              <Row
                key={conversation.id}
                conversation={conversation}
                msgPath={`${this.props.conversations_path}/${conversation.id}`}
              />
            )
          }
        </tbody>
      </table>
    );
  }

  renderPagination() {
    return (
      <DatatablePagination
         currentPage={this.state.currentPage}
         handlePagination={this.handlePagination}
         displayLength={this.state.perPage}
         tableData={this.constructTableDataProp()}
       />
    );
  }

  constructTableDataProp() {
    return (
      {
        iTotalDisplayRecords: this.props.meta.pagination.total_count,
        aaData: this.state.conversations
      }
    );
  }

  handlePagination(currentPage,displayStart) {
    let paginateParams = {
      paginate: true,
      page: currentPage,
      per_page: this.state.perPage
    }

    $.get(this.props.conversations_path, paginateParams, null, 'json').then(data =>
        this.setState({
          conversations: data.conversations,
          currentPage: currentPage,
          displayStart: displayStart
        })
     );
  }
}
