import React from 'react';
import { Remote } from '../utils';

var PromotionCopy = createReactClass({

  getInitialState: function () {
    return {
      content: this.props.promotion.content,
      media: this.props.promotion.media,
      payment: this.props.promotion.payment.toFixed(2),
      platform: this.props.promotion.platform,
    };
  },

  reservePromoSpot: function () {
    document.getElementById('promo-button').setAttribute('disabled', 'disabled');
    Remote().request({
      url: 'user_promo_posts',
      method: 'POST',
      data: { promo_post_id: this.props.promotion.promo_post_id }
    });
  },

  removeModal: function (e) {
    this.props.handleChange(e);
  },

  render: function () {
    var platform = this.state.platform.toLowerCase();
    var imgDisplay = this.props.promotion.has_media ? 'block' : 'none';
    if (platform == 'twitter'){
      var postText = "tweet";
    } else {
      var postText = "Facebook post";
    }

    return (
      <div className="clear">
        <div className="promo--preview_card">
          <button
            type="button"
            className="promo_instructions_modal--close_btn"
            onClick={this.removeModal}
            aria-label="Close"
            style={{
              right: '8px',
              top: '8px',
              boxShadow: '0 0 0 1px rgba(128, 128, 128, 0.3)',
              fontSize: '25px',
              height: '30px',
              width: '30px',
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 className="promo_card--type">{this.state.platform + ' Promotion Preview'}</h4>
          <div className={'promo_card--content ' +  platform}>
            <h4>{this.state.content}</h4>
            <img style={{ maxWidth: '100%', display: imgDisplay }} src={this.state.media} />
          </div>
        </div>
        <div className="text-center mt-10">
          <a className="btn btn-link btn-cancel" id="cancel-button" onClick={this.removeModal} >Cancel</a>
          <a className="btn btn-primary btn-lg authorize" id="promo-button" onClick={this.reservePromoSpot} >
            Confirm Participation
          </a>
        </div>
        <div className="text-center">
          <h3>Payment: <span className="promo--value">${this.state.payment}</span></h3>
          <small>
            Clicking confirm participation will lock in your spot in this promotion.
            You will have {this.props.promotion.hours_to_submit} to create your {postText} and report your link.
          </small>
        </div>
      </div>
    );
  },
});
