import React from 'react';
import PropTypes from 'prop-types'
import CommunityLoginsCodeEntry from './CodeEntry'
import CommunityLoginsEntryButtons from './EntryButtons'

export default class CommunityLoginPrompt extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      code: '',
      codeValid: false
    };

    this.allowNewUser = this.allowNewUser.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.loginText = this.loginText.bind(this);
    this.needsCode = this.needsCode.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    window.localStorage.removeItem('sf_community_code');
    window.localStorage.removeItem('sf_community_id');
  }

  render() {
    const {
      primary_color,
      title,
      description,
      community_image,
      community_access,
      community_id,
      error,
      preview
    } = this.props;

    return <div className='community-logins login-prompt' style={{ backgroundColor: primary_color }}>
      <div className='login-card'>
        <div className='community-logo'>
          <img src={community_image}/>
        </div>
        <div className='community-title'>{title}</div>
        <div className='community-description'>{description}</div>

        <div className='sign-up'>
          {
            this.needsCode() &&
            !this.state.codeValid &&
            <React.Fragment>
              <div className='buttons-title'>
                Sign Up
              </div>
              <CommunityLoginsCodeEntry
                  communityId={community_id}
                  setCode={code => this.setState({ code })}
                  code={this.state.code}
                  setValid={codeValid => this.setState({ codeValid })}
                  preview={preview || false}
                  />
            </React.Fragment>
          }
        </div>

        <div className='log-in'>
          <div className='buttons-title'>
            {this.loginText()}
          </div>

          <CommunityLoginsEntryButtons newUser={!!this.allowNewUser()} communityId={community_id} preview={preview || false}/>
        </div>

        {
          !!error &&
          <div className='failure-info'>
            {error}
          </div>
        }
      </div>
    </div>;
  }

  needsCode() {
    return _.includes(['private', 'code'], this.props.community_access);
  }

  // This is just a UI check, we still block proceeding to the community on the backend
  // if they don't have a valid code.
  allowNewUser() {
    return !this.needsCode() || this.state.codeValid;
  }

  loginText() {
    if(this.needsCode() && !this.state.codeValid) {
      return 'Log In';
    }

    return 'Log In or Sign Up';
  }
}

CommunityLoginPrompt.displayName = 'CommunityLogins.CommunityLoginPrompt';
CommunityLoginPrompt.propTypes = {
  community_id: PropTypes.number.isRequired,
  primary_color: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  community_image: PropTypes.string,
  community_code: PropTypes.string,
  error: PropTypes.string,
  preview: PropTypes.bool
};
