import React from 'react';
import { Remote } from '../utils';

const MIN_PASSWORD_LENGTH = 8;

export default class InfluencerInvitationsPasswordResetModal extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);

    this.state = {
      saving: false,
      accepted: false,
      password: '',
      confirmPassword: '',
      passwordError: '',
      confirmError: ''
    }
  }
  componentDidMount() {
    $("#invited-password-modal").modal({backdrop: 'static', keyboard: false});
  }

  onChange(e){
    this.setState({[e.target.name]: e.target.value});
  }

  submitForm(e) {
    e.preventDefault();
    this.setState({saving: true});

    if (this.state.password.length < MIN_PASSWORD_LENGTH){
      this.setState({
        passwordError: 'Password must be at least 8 characters long.',
        confirmError: '',
        saving: false
      });
      return;
    } else if (this.state.password !== this.state.confirmPassword){
      this.setState({
        passwordError: '',
        confirmError: 'Your passwords do not match.',
        saving: false
      });
      return;
    } else {
      Remote().request({
        url: `/invited_password_submit`,
        method: 'PATCH',
        data: {
          password: this.state.password
        },

      }).then((response) => {
        if ( response.data.status === 200 ) {
          this.setState({
            password: '',
            confirmPassword: '',
            passwordError: '',
            confirmError: '',
            saving: false,
            accepted: true
          });
          setTimeout(() => {
            $("#invited-password-modal").modal('hide');
          }, 2000);
        } else {
          this.setState({
            passwordError: 'Something went wrong. Please try again.',
            confirmError: '',
            saving: false
          });
        }
      });
    }
  }


  render() {

    return (
      <div id='invited-password-modal' className='modal fade'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-body'>
              <div className='row modal-description'>
                <div className='lock-icon'>
                  <i className='fas fa-5x fa-lock' aria-hidden="true">
                    <div className='lock-icon-dots'>&#183;&#183;&#183;</div>
                  </i>
                </div>
                <h2>Welcome to Social Fabric!</h2>
                <p>In order to secure any information you may provide moving forward, please set your password.</p>
              </div>
              <div className='row'>
                <input className='invited-password form-control'
                        name='password'
                        type='password'
                        placeholder='Password'
                        onChange={this.onChange}
                />
                { this.state.passwordError.length > 0 &&
                  <div className='password-warning' >
                    <i className='fas fa-times-circle' />&nbsp;&nbsp;
                    {this.state.passwordError}
                  </div>
                }
              </div>
              <div className='row'>
                <input className='confirm-invited-password form-control'
                        name='confirmPassword'
                        type='password'
                        placeholder='Confirm Password'
                        onChange={this.onChange}
                />
                { this.state.confirmError.length > 0 &&
                  <div className='password-warning'>
                    <i className='fas fa-times-circle' />&nbsp;&nbsp;
                    {this.state.confirmError}
                  </div>
                }
              </div>
              <button
                className={`btn btn-primary ${this.state.accepted ? 'accepted' : ''}`}
                onClick={this.submitForm}
                disabled={this.state.saving || this.state.accepted}
              >
                {(this.state.saving || this.state.accepted) ? <i className={`fas ${this.state.accepted ? 'fa-check' : 'fa-circle-notch fa-spin'}` }/> : 'Submit' }
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

InfluencerInvitationsPasswordResetModal.displayName = 'InfluencerInvitations.PasswordResetModal';
