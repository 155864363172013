import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Remote } from '../utils';

import LoadingSpinner from '../LoadingSpinner'
import SaveIndicator from '../SaveIndicator'
import SaveError from '../SaveError'
import MultiSelect from '../MultiSelect'
import FixedBar from '../FixedBar'
import RichTextEditor from '../RichTextEditor'

class CommunitiesGeneralSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false
    };

    this.render = this.render.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.searchTypes = this.searchTypes.bind(this);

    this.debouncedUpdate = _.debounce(this.props.updateCommunitySettings, 500)
  }

  render() {
    const {community} = this.props;

    if(_.isEmpty(community)) {
      return <LoadingSpinner />;
    }

    return <div className='communities--show'>
      <h2 className='mb-15'>
        {community.name} Admin
      </h2>

      <div className='card-plus'>
        <div className='card-header'>
          Community Configuration
        </div>

        <div className='card-body'>
          <div className='form-group'>
            <label>Name</label>
            <input
              className='form-control'
              placeholder='Community Name'
              onChange={e => this.debouncedUpdate('name', e.target.value)}
              defaultValue={community.name || ''}
              />
          </div>

          <div className='form-group'>
            <label>Instructions</label>
            <select
              value={community.require_instructions}
              onChange={e => this.debouncedUpdate( 'require_instructions', e.target.value )}
              className='form-control'
              >
              {
                community.instruction_types.map(t => (
                  <option key={t} value={t}>
                    {_.upperFirst(_.startCase(t))}
                  </option>
                ))
              }
            </select>
          </div>

          <div className='form-group'>
            <label>Applications</label>
            <select
              value={community.require_applications}
              onChange={e => this.debouncedUpdate( 'require_applications', e.target.value )}
              className='form-control'
              >
              {
                community.application_types.map(t => (
                  <option key={t} value={t}>
                    {_.upperFirst(_.startCase(t))}
                  </option>
                ))
              }
            </select>
          </div>

          <div className='form-group'>
            <label>Payment Types</label>

            <MultiSelect
              placeholder='Payment Types'
              onUpdate={types => this.debouncedUpdate('community_payment_types', types.map(x => ({ payment_type: x })) )}
              search={this.searchTypes}
              preload={community.payment_types}
              selected={community.community_payment_types.map(x => x.name || x.payment_type)}
              />
          </div>
        </div>
      </div>

      <div className='card-plus'>
        <div className='card-header'>
          Terms of Service
        </div>

        <RichTextEditor
          value={community.supplementary_tos}
          onUpdate={html => this.debouncedUpdate( 'supplementary_tos', html )}
          minHeight='400px'
          />
      </div>

      {
        this.props.notifications && this.props.notifications.saving &&
          <SaveIndicator type={this.props.notifications.saveType}>
            {this.props.notifications.saveText}
            {this.props.notifications.saveErrors && <SaveError errors={this.props.notifications.saveErrors}/>}
          </SaveIndicator>
      }

      <FixedBar style='left-right'>
        <a href='/admin/communities' className='btn btn-default'>
          Cancel
        </a>

        <button disabled={this.state.saving} className='btn btn-primary' onClick={this.saveChanges}>
          {
            this.state.saving
            ? <i className='fas fa-circle-notch fa-spin'/>
            : "Save Changes"
          }
        </button>
      </FixedBar>
    </div>;
  }

  saveChanges(e) {
    e.preventDefault();

    this.setState({ saving: true });
    const params = $.param({
      api_action: `/communities/${this.props.community.id}`
    });

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'PATCH',
      data: this.props.community,

    }).then((response) => {
      this.setState({ saving: false });
      this.props.openNotification(
        'Community login settings updated.',
        'success',
      )
      setTimeout(this.props.closeNotification, 5000);
    }).catch((error) => {
      this.setState({ saving: false });
      this.props.openNotification(
        'Failed to save Community login settings.',
        'failure',
        error
      )
      setTimeout(this.props.closeNotification, 5000);
    });
  }

  searchTypes(query, done) {
    const results = this.props.community.payment_types.filter(type => _.includes(_.toLower(type.name), _.toLower(query)));
    done(results);
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      community: state.community,
      validations: state.validations,
      notifications: state.notifications
    };
  };

  static mapDispatchToProps(dispatch) {
    return {
      updateCommunitySettings: (field, value) => dispatch({ type: 'UPDATE_COMMUNITY_FIELD', value: {field: field, value: value}}),
      openNotification: (saveText, saveType, saveError) => dispatch({type: 'OPEN_NOTIFICATION', value: {saveText, saveType, saveError}}),
      closeNotification: data => dispatch({ type: 'CLOSE_NOTIFICATION'})
    };
  };
};

CommunitiesGeneralSettings.displayName = 'Communities.GeneralSettings';
CommunitiesGeneralSettings.propTypes = {
  proxyUrl: PropTypes.string.isRequired
};

export default connect(
  CommunitiesGeneralSettings.mapStateToProps,
  CommunitiesGeneralSettings.mapDispatchToProps
)(CommunitiesGeneralSettings);
