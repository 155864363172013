import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

class RoundTasksCommunitySelector extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.render = this.render.bind(this);
  }

  render() {
    const textStyle = {
      color: 'black',
      fontSize: '13pt',
      fontWeight: '200',
      padding: '3px'
    };
    const formStyle = {
      paddingBottom: '10px'
    };
    const selectStyle = {
      verticalAlign: 'inherit',
      marginLeft: '2px'
    };

    if(this.props.communitiesLoading) {
      return (
        <span style={textStyle}>
          Loading your communities... <i className='fas fa-spin fa-circle-notch'/>
        </span>
      );
    }

    if(this.props.communities.length == 1) {
      const community = this.props.communities[0];
      return (
        <div style={formStyle}>
          <span style={textStyle}>{community.name} {this.props.type} Templates</span>
        </div>
      )
    }

    return (
      <div className='form-inline' style={formStyle}>
        <select defaultValue={this.props.selectedCommunityId} onChange={(event) => this.onChange(event)} className='form-control' style={selectStyle}>
          {this.props.communities.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
        </select>
        <label style={textStyle}>{this.props.type} Templates</label>
      </div>
    );
  }

  onChange(e) {
    this.props.setCommunity(Number(e.target.value));
  }

  static mapStateToProps(state, myProps) {
    return {
      ...state,
      ...myProps
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      setCommunity: (id) => dispatch({ type: 'SET_COMMUNITY', value: id })
    };
  }
};

RoundTasksCommunitySelector.displayName = 'RoundTasks.CommunitySelector';
RoundTasksCommunitySelector.propTypes = {
  selectedCommunityId: PropTypes.number,
  communities: PropTypes.any.isRequired,
  communitiesLoading: PropTypes.bool.isRequired
};

export default connect(
  RoundTasksCommunitySelector.mapStateToProps,
  RoundTasksCommunitySelector.mapDispatchToProps,
)(RoundTasksCommunitySelector);
