import React from 'react';
import AgreementQuestions from '../AgreementQuestions'
import FeedbackQuestions from '../FeedbackQuestions'

const modalTitleStyle= { padding: '20px', margin: 0}

export default class ShoppertunitiesSurveyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const {shoppertunity_id, user_id, save_response_url, authenticity_token, questions} = this.props;
        const feedback_questions = questions.filter(question => question.type === 'feedback');
        const agreement_questions = questions.filter(question => question.type === 'agreement' );
        return (
          <div className="container-fluid">
            <div className="row text-center" >
                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#shopSurveyModal">
                    Opportunity Satisfaction Survey
                </button>
            </div>
            <form id="survey_questions" action={save_response_url} method="post">
                <div className="modal fade" id="shopSurveyModal" tabIndex="-1" role="dialog" aria-labelledby="#shopSurveyModalTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content modal-lg">
                            <div className="modal-header modal-lg">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div className="modal-title modal-lg" id="ModalTitle" style={modalTitleStyle}>
                                    <h1 className="text-center">Satisfaction Survey</h1>
                                    <br />
                                    <p>Thanks for submitting your final task.
                                        Please help us make Social Fabric better by answering a few simple questions.
                                        All answers are anonymous.</p>
                                </div>
                            </div>
                            <div className="modal-body modal-lg">
                              <div className="form-group" id="questions">
                                  <p style={{padding: '15px'}}>
                                      <span className="second-word-formatting">
                                          <b>Evaluate the following statements</b>
                                      </span>
                                  </p>
                                <AgreementQuestions questions={agreement_questions} />
                                <FeedbackQuestions questions={feedback_questions} />
                                <input type="hidden" value={shoppertunity_id} name="shoppertunity_id" />
                                <input type="hidden" value={user_id} name="user_id" />
                                <input type='hidden' value={authenticity_token} name="authenticity_token" />
                              </div>
                            </div>
                            <div className="modal-footer modal-lg">
                              <button type="button" className="btn secondary" data-dismiss="modal">Close</button>
                              <button type="submit" className="btn primary">Submit Survey</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
          </div>
        )
    }

}