import React from 'react';
export default class ShopCards extends React.Component {

  getInitialState() {
    return {
        retailersData: this.props.retailers,
          clientsData: this.props.clients,
       categoriesData: this.props.categories,
        platformsData: this.props.platforms,
               pShops: this.props.shops,
              tabList: this.props.tab_list,
     no_shops_message: this.props.no_shops_message,
             cardView: true,
                query: {},
               source: this.props.source,
        paginateQuery: '',
          pageCounter: 2,
        current_phase: 'open'
    };
  }

  componentWillMount() {
    NewBadgeHelper.load();
  }

  componentDidMount() {
    $('.alert_holder').append($('.alert_area'));
  }

  show_more_display(show_more_el, display_setting){
    element = document.getElementById('show_more_' + show_more_el);
    element.style.display = display_setting;
  }

  load_more(){
    this.show_more_display('spinner', 'inline-block')
    this.show_more_display('button', 'none')
    this.appendResultsFromNextPage(this.state.pageCounter);
  }

  appendResultsFromNextPage(page) {
    this.state.paginateQuery = this.state.source + this.query() + '&page=' + page;
    this.fetchAdditionalShops();
  }

  morePages(new_data){
    this.show_more_display('spinner', 'none')
    if (new_data.shops.length % 16 === 0 && new_data.shops.length > 0) {
      this.show_more_display('button', 'inline-block');
    } else {
      this.show_more_display('button', 'none');
    }
  }

  arrayUnique(array){
    for(var i=0; i < array.length; ++i) {
      for(var j=i+1; j<array.length; ++j) {
        if(array[i].name === array[j].name)
          array.splice(j--, 1);
      }
    }
    return array;
  }

  fetchAdditionalShops(){
    $.get(this.state.paginateQuery, function (result) {
      this.setState({
        pShops: this.state.pShops.concat(result.shops),
        clientsData: this.arrayUnique( this.state.clientsData.concat(result.clients) ),
        retailersData: this.arrayUnique( this.state.retailersData.concat(result.retailers) ),
        categoriesData: this.arrayUnique( this.state.categoriesData.concat(result.categories) ),
        platformsData: this.arrayUnique( this.state.platformsData.concat(result.platforms) ),
        pageCounter: this.state.pageCounter + 1,
      });
      this.morePages(result);
    }.bind(this));
  }

  fetch_data() {
    $('.shop-row').addClass('loading-spinner');
    $.get(this.state.source, function (result) {
      if (this.isMounted()) {
        this.setState({
          pShops:           result.shops,
          pagination:       result.pagination,
          tabList:          result.tab_list,
          retailersData:    result.retailers,
          clientsData:      result.clients,
          categoriesData:   result.categories,
          platformsData:    result.platforms,
          no_shops_message: result.no_shops_message,
          current_phase:    result.current_phase || 'open'
        });
        this.morePages(result);
      }
      $('.shop-row').removeClass('loading-spinner');
    }.bind(this));
  }

  buildQueryHash(hash) {
    for (var attrname in hash) { this.state.query[attrname] = hash[attrname]; }
  }

  query() {
    q = '?'
    for (var key in this.state.query) {
      q += key + '=' + encodeURIComponent(this.state.query[key]) + '&'
    }
    return q;
  }

  resetQuery() {
    this.state.query = {};
    $('#retailers_label').text('Retailers ');
    $('#clients_label').text('Clients ');
    $('#categories_label').text('Categories ');
    $('#platforms_label').text('Platforms ');
    $('#sort_label').text('Newly Listed ');
    this.state.pageCounter = 2;
  }

  handleFilter(hash) {
    this.buildQueryHash(hash);
    this.state.source = this.props.source + this.query();
    this.state.pageCounter = 2;
    this.fetch_data();
  }

  goToPagePhase(tab) {
    this.resetQuery();
    if(tab.phase === 'hidden'){
      this.buildQueryHash({'hidden': true, 'phase': tab.phase});
    }else{
      this.buildQueryHash({'phase': tab.phase});
    }
    this.state.source = this.props.source + this.query();
    this.fetch_data();
  }

  changeLayoutCallback(cardView) {
    this.setState({ cardView: cardView });
  }

  hideShowShops(shop) {
    var hidden_tab_count_span = $('.hidden--tab .sofab-tabs--count');
    var hidden_tab_shop_count = Number(hidden_tab_count_span.text());
    var open_tab_count_span   = $('.--tab .sofab-tabs--count');
    var open_tab_shop_count   = Number(open_tab_count_span.text());
    var hidden = this.state.current_phase != 'hidden' ? true : false;

    if(this.state.current_phase === 'hidden'){
      open_tab_count_span.text(open_tab_shop_count + 1);
      hidden_tab_count_span.text(hidden_tab_shop_count - 1);
    }else{
      $('.hidden--tab .sofab-tabs--count.is-hidden').removeClass('is-hidden')

      open_tab_count_span.text(open_tab_shop_count - 1);
      hidden_tab_count_span.text(hidden_tab_shop_count + 1);
    }

    s = this.state.pShops.indexOf(shop);
    this.state.pShops.splice(s, 1);
    this.setState({ pShops: this.state.pShops });

    $.post('/opportunities/'+shop.id+'/hide?hide='+hidden);
  }

  render() {
    var currentLayout = this.state.cardView ? '' : 'shop-list-layout'
    var layoutClass = 'shop-row ' + currentLayout;

    var cards = this.state.pShops.map(function (shop) {
      return (<ShopCard shop={shop}
                        onClickHide={this.hideShowShops.bind(null, shop)}
                        current_phase={this.state.current_phase}
                        key={shop.id}
                        hide_shop_btn={true}
                        show_new_badge={true}/>);
    }.bind(this));

    return (
      <div>
        <div className='full-width-page-title--wrapper'>
          <div className='container'>
            <ShopNavigation
              goToPagePhase={this.goToPagePhase}
              pShops={this.state.pShops}
              tabList={this.state.tabList}
              cardView={this.state.cardView}
              changeLayoutCallback={this.changeLayoutCallback}/>
          </div>
        </div>
        <div className='container main-container'>
          <SortFilter onChangeCallback={this.handleFilter}
                      retailersData={this.state.retailersData}
                      clientsData={this.state.clientsData}
                      categoriesData={this.state.categoriesData}
                      platformsData={this.state.platformsData} />
          <div className='alert_holder'></div>
          <div className={layoutClass}>
            {cards}
          </div>
          <NoShopsAlert no_shops_message={this.state.no_shops_message} />
          <div id='show_more'>
            <button id='show_more_button' className='btn btn-default js-ga-show-more' onClick={this.load_more}>Show more</button>
            <i id='show_more_spinner' className='fas fa-spinner fa-spin'></i>
          </div>
        </div>
      </div>
    );
  }

};

ShopCards.displayName = 'ShopCards';
