import React from 'react';
import { connect } from 'react-redux';
import { Remote } from '../utils';
import SubmissionInstagramMediaAttachment from './InstagramMediaAttachment'

class SubmissionStoryPreview extends React.Component {
  constructor(props){
    super(props);
    this.deleteMedia = this.deleteMedia.bind(this);
  }
  deleteMedia(media) {
    const deleteMediaImagePath = `/contents/destroy_instagram_media?media_id=${media.id}`

    Remote().request({
      method: 'POST',
      url: deleteMediaImagePath,
    }).then((media) => {
      this.props.deleteInstagramMediaAttachment(media);
    });
  }

  render() {
    const medias = ((this.props.manualAttachments !== undefined) && this.props.manualAttachments.length > 0) ? this.props.manualAttachments : this.props.instagram_business_story_medias;
    const mediaCount = medias ? medias.length : 0;
    return (
      <div className='submission story-preview'>
        <div>
          <div className={`submission content-grid content-count-${mediaCount} instagram-story`}>
            { mediaCount > 0 && medias.map((media, index) =>
              <SubmissionInstagramMediaAttachment key={index} media={media} readOnly={this.props.readOnly} deleteMedia={this.deleteMedia}/>)
            }
          </div>
        </div>
      </div>
    );
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      content: state.bloggerShopTask.content,
      instagram_business_story_medias: state.bloggerShopTask.instagram_business_story_medias,
      readOnly: state.readOnly
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      deleteInstagramMediaAttachment: instagramMediaAttachment => dispatch({ type: 'DELETE_INSTAGRAM_MEDIA_ATTACHMENT', value: instagramMediaAttachment}),
    };
  }
}

SubmissionStoryPreview.displayName = 'Submission.StoryPreview';

export default connect(
  SubmissionStoryPreview.mapStateToProps,
  SubmissionStoryPreview.mapDispatchToProps
)(SubmissionStoryPreview);
