import React from 'react';
import PropTypes from 'prop-types';
import PagePlaceholder from './PagePlaceholder';

export default class FullPagePlaceholder extends React.Component {
  render() {
    return <div className='full-page-placeholder'>
      <PagePlaceholder description={this.props.description}/>
      <div className='description-subtitle'>
        {this.props.subtitle}
      </div>
    </div>
  }
};

FullPagePlaceholder.displayName = 'FullPagePlaceholder';
FullPagePlaceholder.propTypes = {
  description: PropTypes.string,
  subtitle: PropTypes.string
};
