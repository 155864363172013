import React from 'react';
import PropTypes from 'prop-types'
// Small wrapper around .input-group from bootstrap 3
export default class InputGroup extends React.Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
  }

  render() {
    const placement = this.props.addonPlacement || 'left';
    const addon = <span className={`input-group-addon ${placement}`}>
      <i className={this.props.iconClass}/>
    </span>;

    const { value } = this.props;
    const formValue = _.isNull(value) || _.isUndefined(value) ? '' : value;

    return (
      <div className='input-group'>
        {placement == 'left' && addon}
        <input
          type={this.props.type}
          className='form-control'
          value={formValue}
          onChange={e => this.props.onChange(e.target.value)}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          />
        {placement == 'right' && addon}
      </div>
    );
  };
}

InputGroup.propTypes = {
  type: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  addonPlacement: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
};
