class window.Payments

  @new: ->
    @form()

  @create: ->
    @new()

  @form: ->
    new ValidateCurrency.CurrencyField( '#submit-btn', ['#payment_amount'] ).bind_values()
    new ValidateDate.DateField( '#submit-btn', ['payment_accrual_date'] ).bind_values()
    FormUtils.filestyle_settings()
    @toggle_optional_fields()
    $('body').on 'change', '#payment_payment_type', ->
      Payments.toggle_optional_fields()

  @toggle_optional_fields: ->

    val = $("#payment_payment_type option:selected").text()

    description_types     = [ 'Professional Development Grant', 'Recruitment', 'SoFabUOTR Ambassador',
                              'Advisory Committee', 'SoFabUOTR Grant', 'Stipend', 'SoFabU Instructor',
                              'Conference Expense Reimbursement', 'Contract Employee', 'Other' ]
    receipt_types        = [ 'Conference Expense Reimbursement' ]
    campaign_types       = [ 'Social Party Host', 'Campaign Leader (CL)', 'Bonus Payment', 'Promotion Post' ]

    toggle '.auth_field', true
    if val in     receipt_types     then toggle '.receipt_field', true     else toggle '.receipt_field', false
    if val in     campaign_types    then toggle '.campaign_field', true    else toggle '.campaign_field', false
    if val in     description_types then toggle '.description_field', true else toggle '.description_field', false

  toggle = (div, e) ->
    $(div).toggle e
    disable_inputs div

  disable_inputs = (div) ->
    $("#{div} input").prop 'disabled', not $(div).is(':visible')