import React from 'react';
import { Provider } from "react-redux"
import ShoppertunitiesStore from '../redux/ShoppertunitiesStore.jsx';
import ShoppertunitiesIndex from './Index.jsx';

export default class ShoppertunitiesIndexWrapper extends React.Component {
  render() {
    return (
      <Provider store={ShoppertunitiesStore}>
        <ShoppertunitiesIndex {...this.props} />
      </Provider>
    );
  }
};

ShoppertunitiesIndexWrapper.displayName = 'Shoppertunities.IndexWrapper';