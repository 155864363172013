import React from 'react';
import ContentsGoogleDocExportModal from './GoogleDocExportModal';

export default class ContentsGoogleDocExportButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showModal: false }

    this.toggleModal = this.toggleModal.bind(this);
    this.render = this.render.bind(this);
  }

  toggleModal(){
    if(_.isEmpty(this.props.draftOptions)){
      return null
    }
    this.setState({showModal: !this.state.showModal})
  }

  render() {
    const disableModal = _.isEmpty(this.props.draftOptions)
    return (
      <React.Fragment>
        <button className='btn btn-primary' id='googleDriveBtn' onClick={this.toggleModal} disabled={disableModal}>
          Export to Google Docs
        </button>
        { this.state.showModal && <ContentsGoogleDocExportModal toggleModal={this.toggleModal} {...this.props}/>}
      </React.Fragment>
    );
  }

}

ContentsGoogleDocExportButton.displayName = 'Contents.GoogleDocExportButton'
