import React from 'react';

export default class InstagramDraftContent extends React.Component {
  render(){
    const {activity, user_name, shoppertunity_name} = this.props.notification;
    const P = React.Fragment;
    switch(activity) {
      case 'accepted':
        return (
          <P>
            Your Instagram draft for {shoppertunity_name} has been approved!
            Please copy the caption from Social Fabric as some minor changes may have been made. Please do not make your Instagram post live until the post due date.
          </P>
        )
      case 'client_accepted':
        return (
          <P><b>{user_name}</b>'s Instagram Draft for <b>{shoppertunity_name}</b> has been client approved!</P>
        )
      case 'rejected':
        return (
          <P>Your Instagram Draft for <b>{shoppertunity_name}</b> has been rejected.</P>
        )
      default:
        return null;
    }
  }
}
InstagramDraftContent.displayName = 'Notification.Templates.InstagramDraftContent'