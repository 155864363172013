import React from 'react';

class InstructionsChecklistItem extends React.Component {
  constructor(props) {
    super(props);

    this.onUpdate = this.onUpdate.bind(this);
    this.render = this.render.bind(this);
  }

  render() {
    const deleteStyle = {
      cursor: 'pointer'
    };
    const iconStyle = {
      color: '#a94442'
    };

    return (
      <div className='form-group'>
        <div className='input-group'>
          <input
            className='form-control'
            type='text'
            value={this.props.item.description}
            placeholder='Checklist Notes'
            onChange={this.onUpdate}/>
          <div className='input-group-addon' onClick={this.props.onDelete} style={deleteStyle}>
            <i className='text-danger fas fa-times' style={iconStyle}/>
          </div>
        </div>
      </div>
    );
  }

  onUpdate(evt) {
    this.props.onUpdate({ ...this.props.item, description: evt.target.value });
  }
};


InstructionsChecklistItem.displayName = 'Instructions.ChecklistItem';
export default InstructionsChecklistItem