import React from 'react';
import { Remote } from '../utils';

import ExternalInvitation from './ExternalInvitation'
import InfluencerInternalInvitation from './InternalInvitation'
import InfluencerInvitationResponse from './InvitationResponse'

export default class InvitationModal extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.retrySubmission = this.retrySubmission.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.renderModalButton = this.renderModalButton.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.checkForDuplicateEmail = this.checkForDuplicateEmail.bind(this);

    this.state = {
      loading: false,
      activeTab: 'external',
      duplicateEmailError: false,
      editing: false,
      dirty: false
    }
  }

  componentDidMount() {
    $('#influencer_invite_modal').on('shown.bs.modal', (e) => {
      let editing = $(e.relatedTarget).hasClass('edit-invite')
      const attr = (val) => $(e.relatedTarget.childNodes[0]).data(val)
      this.setState({
        // fetches invite data from tr
        editing: editing &&{ ...attr()},
        activeTab: editing ? 'internal' : 'external',
        dirty: false
      })
    });
  }

  retrySubmission() {
    this.setState({ response: null });
  }

  submitForm(event) {
    event.preventDefault();
    event.persist();
    this.setState({ loading: true });

    const externalInvite = this.state.activeTab == 'external'

    let profile_attributes = {
        payment_type: event.target.payment_method.value,
        ...(externalInvite && {paypal_name: event.target.first_name.value + ' ' + event.target.last_name.value}),
        ...(!externalInvite && {paypal_name: event.target.full_name.value}),
        ...(event.target.paypal_id && {paypal_email: event.target.paypal_id.value} )
      }

    Remote().request({
      url: this.props.influencer_invite_path,
      method: 'POST',
      data: {
        external_invitation: {
          external_user: externalInvite,
          ...(externalInvite && {first_name: event.target.first_name.value}),
          ...(externalInvite && {last_name: event.target.last_name.value}),
          email: event.target.email.value,
          payment: event.target.payment.value,
          platform_type: event.target.platform_type.value,
          message: event.target.message.value,
          round_id: event.target.round.value,
          campaign_id: this.props.campaign.id,
          shoppertunity_id: this.props.shoppertunity.id,
          profile_attributes: profile_attributes
        }
      },

    }).then((response) => {
      this.setState({
        loading: false,
        response: {
          code: response.status,
          json: response.data
        }
      });

      if ( response.status === 200 ) {
        $('#shop_app_table').DataTable().ajax.reload();
        setTimeout(() => {
          $(event.target)[0].reset();
          $('#influencer_invite_modal').modal('hide');
          this.setState({ response: null });
        }, 2000);
      }
    }).catch((err) => {
      this.setState({
        loading: false,
        response: {
          code: err.response.status,
          json: err.response.data
        }
      });
    });
  }

  renderModalButton() {
    let message = null
    if (this.props.rounds.length <= 0)
      message = this.props.noRoundsMessage;
    else if (!this.props.accepting_invitations)
      message = this.props.noSlotsMessage;

    if ( message ) {
      return (
        <span className='d-inline-block'
          tabIndex='0'
          data-toggle='tooltip'
          data-placement='bottom'
          title={message}>
          <button className='btn btn-primary disabled'
            disabled>
            Invite to Accept
          </button>
        </span>
      )
    } else {
      return (
        <button className={`btn btn-primary`}
          data-toggle='modal'
          data-target='#influencer_invite_modal'>
          Invite to Accept
        </button>
      )
    }
  }

  changeTab(tab){
    this.setState({
      activeTab: tab,
      response: null,
      duplicateEmailError: false
    })

    $('#'+tab+'_form')[0].reset();
    $('#send-invite-button').prop('disabled', false);
  }

  checkForDuplicateEmail(email) {
    let exists = _.findKey(this.props.externalInvitations, function(o) { return o.email === email; }) ? true : false;
    this.setState({ duplicateEmailError: exists})
    $('#send-invite-button').prop('disabled', exists);
  }

  tabClass(tabName) {
    return this.state.activeTab === tabName ? 'active' : ''
  }

  render() {
    const { editing, dirty } = this.state
    const showForm = this.state.loading || this.state.response ? false : true;
    const style = showForm ? {} : {'visibility':'hidden'}

    return (
      <div>
        {this.renderModalButton()}
        <div className='modal fade influencer-invite-modal' id='influencer_invite_modal'>
          <div className='modal-content modal-dialog' id='influencer_invite_form'>
            <div className='modal-header' style={{'paddingBottom': '0'}}>
             <button className='close' data-dismiss='modal'><i className="fas fa-times"></i></button>
              { !editing && <ul className='nav nav-tabs'>
                <li className={this.tabClass('external')} >
                  <a href='#external' onClick={() => {this.changeTab('external')}} data-toggle='tab'>Outside</a>
                </li>
                <li className={this.tabClass('internal')}>
                  <a href='#internal' onClick={() => {this.changeTab('internal')}} data-toggle='tab'>Social Fabric</a>
                </li>
              </ul>}
              { !!editing && <h2>Editing Invitation</h2> }
            </div>
            <div className='modal-body' style={{'position': 'relative'}}>
              <div className='tab-content' style={style}>
                { this.state.duplicateEmailError &&
                  <div className="alert alert-danger" role="alert">
                    This {this.state.activeTab == 'external' ? 'email' : 'influencer'} has already been invited to this Shoppertunity.
                  </div>
                }
                <div id='external' className={`tab-pane ${ this.tabClass('external') }`}>
                  <ExternalInvitation
                  message={this.props.externalMessage}
                  vipMessage={this.props.externalVIPMessage}
                  rounds={this.props.rounds}
                  checkForDuplicateEmail={this.checkForDuplicateEmail}
                  paymentMethods={this.props.paymentMethods}
                  submit={this.submitForm}/>
                </div>
                <div id='internal' className={`tab-pane ${ this.tabClass('internal') }`}>
                <InfluencerInternalInvitation
                    message={this.props.internalMessage}
                    { ...editing }
                    dirty={dirty}
                    setDirty={ () => this.setState({dirty: true}) }
                    vipMessage={this.props.internalVIPMessage}
                    rounds={this.props.rounds}
                    searchPath={this.props.searchPath}
                    checkForDuplicateEmail={this.checkForDuplicateEmail}
                    paymentMethods={this.props.paymentMethods}
                    submit={this.submitForm}
                    campaignCommunitiesNote={this.props.campaignCommunitiesNote}/>
                </div>
              </div>
              { !showForm ? <InfluencerInvitationResponse loading={this.state.loading} response={this.state.response} retry={this.retrySubmission}/> : ''}
            </div>
            <div className='modal-footer' style={style}>
              <button className='btn btn-default btn-sm' data-dismiss='modal'>Cancel</button>
              <input id="send-invite-button" className='btn btn-primary btn-sm' form={`${this.state.activeTab}_form`} type='submit' value='Send' disabled={editing ? !dirty : false}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
InvitationModal.displayName = 'InvitationModal'
