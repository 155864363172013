import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FileUploader, Remote } from '../utils';

import LoadingSpinner from '../LoadingSpinner'
import SaveIndicator from '../SaveIndicator'
import SaveError from '../SaveError'
import CommunitiesHeaderPreview from './HeaderPreview'

class CommunitiesCustomization extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshKey: Date.now(),
      initialSettings: {},
      loading: false
    }

    this.componentDidMount = this.componentDidMount.bind(this);
    this.loadCommunityThemeSettings = this.loadCommunityThemeSettings.bind(this);
    this.resetCommunityThemeSettings = this.resetCommunityThemeSettings.bind(this);
    this.saveCommunityThemeSettings = this.saveCommunityThemeSettings.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.handleColorPickerChange = this.handleColorPickerChange.bind(this);
    this.validationMessage = this.validationMessage.bind(this);
    this.render = this.render.bind(this);

    this.debouncedColor = _.debounce(this.handleColorPickerChange, 500)
  }

  componentDidMount() {
    this.loadCommunityThemeSettings();
  }

  loadCommunityThemeSettings(){
    let community_id = this.props.community.id ||  this.props.communityId
    const params = $.param({
      api_action: `communities/${community_id}/community_theme_settings`
    });

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'GET',

    }).then((response) => {
      this.props.setCommunityThemeSettings(response.data);
      this.setState({initialSettings: response.data}) //store initial settings value for cancel functionality
    }).catch((error) => {
      this.props.openNotification(
        'Failed to load Community theme settings.',
        'failure',
        error
      )
      setTimeout(this.props.closeNotification, 5000);
    });
  }


  resetCommunityThemeSettings(){
    this.props.setCommunityThemeSettings(this.state.initialSettings)
    this.setState({refreshKey: Date.now()})
  }

  saveCommunityThemeSettings(){
    this.setState({loading: true})
    const { theme_settings, community } = this.props;
    let community_id = community.id || this.props.communityId

    const params = $.param({
      api_action: `communities/${community_id}/community_theme_settings/${theme_settings.id}`
    });

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'PATCH',
      data: theme_settings,
    }).then((response) => {
      this.props.setCommunityThemeSettings(response.data);

      this.setState({
        initialSettings: response.data, //reset initial settings value to new settings
        loading: false
      })

      this.props.openNotification(
        'Community theme settings saved!',
        'success',
      )
      setTimeout(this.props.closeNotification, 5000);
    }).catch((error) => {
      this.setState({loading: false});
      this.props.openNotification(
        'Failed to save Community theme settings.',
        'failure',
        error
      )
      setTimeout(this.props.closeNotification, 5000);
    });
  }


  uploadFile(file) {
    const { theme_settings } = this.props
    let formData = new FormData();
    formData.append('theme_image', file)

    FileUploader().request({
      url: `community_theme_settings/${theme_settings.id}/upload_theme_image`,
      method: 'PATCH',
      data: formData,
      processData: false,
    }).then((response) => {
      this.props.updateCommunityThemeSettings('theme_image', response.data.media.url)

      this.props.openNotification(
        'Community theme image saved!',
        'success',
      )
      setTimeout(this.props.closeNotification, 5000);
    }).catch((error) => {
      this.props.openNotification(
        'Failed to upload theme image.',
        'failure',
        error
      )
      setTimeout(this.props.closeNotification, 5000);
    });
  }

  handleColorPickerChange(field_name, color) {
     this.props.updateCommunityThemeSettings(field_name, color);

      if(color.match(/^#+([a-fA-F0-9]{6})$/)){
        let className = (field_name == 'theme_primary_color') ? 'primary_color_picker' : 'text_color_picker';
        Array.from(document.getElementsByClassName(className)).map((elem) => {
            elem.value = color;
        })
    }
  }

  validationMessage(field) {
    let { theme_settings, validations } = this.props;
    if (validations.hasOwnProperty(field)){
      return <span className='error_text'>{validations[field]}</span>
    } else if (!theme_settings[field] || theme_settings[field] === ''){
      return <span className='info_text'>This will default to the Social Fabric theme.</span>
    }
  }

  render() {
    const { theme_settings, validations, community } = this.props;
    const refreshKey = this.state.refreshKey;
    let themeImageFileName = '';

    let submitDisabled = this.state.loading || Object.keys(this.props.validations).length !== 0

    if(Object.keys(theme_settings).length !== 0){
      themeImageFileName = theme_settings.theme_image ? theme_settings.theme_image.split("/").pop() : ''
    }

    if(_.isEmpty(theme_settings) || this.state.loading){
      return(
        <LoadingSpinner />
      );
    } else {
      return (
        <div className='community_theme_settings_container'>
            <div className='community_theme_settings_fields' key={refreshKey}>
              <div className={`community_theme_settings_input ${validations.hasOwnProperty('theme_image') ? 'error' : ''}`} >
                <label>Community Theme Image</label>
                <input
                  type='file'
                  id='theme_image'
                  onChange={(event) => this.uploadFile(event.target.files[0])}
                  style={{display:'none'}}
                />
                <label className='btn btn-default community-image-upload' htmlFor="theme_image">{themeImageFileName || 'Select file'}</label>
                {this.validationMessage('theme_image')}
              </div>
              <div className={`community_theme_settings_input ${validations.hasOwnProperty('theme_primary_color') ? 'error' : ''}`} >
                <label>Header Background Color</label>
                <div className='background_color_settings_inputs'>
                  <input
                    type='color'
                    className='primary_color_picker color_input'
                    defaultValue={theme_settings.theme_primary_color}
                    onChange={(event) => this.handleColorPickerChange('theme_primary_color', event.target.value)}
                  />
                  <input
                    type='text'
                    className='primary_color_picker hex_input'
                    defaultValue={theme_settings.theme_primary_color}
                    onChange={(event) => this.debouncedColor('theme_primary_color', event.target.value)}
                  />
                </div>
                {this.validationMessage('theme_primary_color')}
              </div>
              <div className={`community_theme_settings_input ${validations.hasOwnProperty('theme_text_color') ? 'error' : ''}`} >
                <label>Header Text Color</label>
                <div className='text_color_settings_inputs'>
                  <input
                    type='color'
                    className='text_color_picker color_input'
                    defaultValue={theme_settings.theme_text_color}
                    onChange={(event) => this.handleColorPickerChange('theme_text_color', event.target.value)}
                  />
                  <input
                    type='text'
                    className='text_color_picker hex_input'
                    defaultValue={theme_settings.theme_text_color}
                    onChange={(event) => this.debouncedColor('theme_text_color', event.target.value)}
                  />
                </div>
                {this.validationMessage('theme_text_color')}
              </div>
              <hr/>
              <div className='community_theme_settings_actions'>
                <button className='btn secondary' onClick={() => this.resetCommunityThemeSettings()} >Reset</button>
                <button className='btn primary' onClick={() => this.saveCommunityThemeSettings()} disabled={submitDisabled} >Submit & Publish</button>
              </div>
            </div>
            <div className='community_theme_settings_preview'>
              <CommunitiesHeaderPreview theme_settings={this.props.theme_settings}
                                          sofabLogoPath={this.props.sofabLogoPath}/>
            </div>
            {
              this.props.notifications && this.props.notifications.saving &&
                <SaveIndicator type={this.props.notifications.saveType}>
                  {this.props.notifications.saveText}
                  {this.props.notifications.saveErrors && <SaveError errors={this.props.notifications.saveErrors}/>}
                </SaveIndicator>
            }
          </div>
      );
    }
  }
  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      theme_settings: state.theme_settings,
      validations: state.validations,
      notifications: state.notifications
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      setCommunityThemeSettings: theme_settings => dispatch({ type: 'SET_COMUNITY_THEME_SETTINGS', value: theme_settings }),
      updateCommunityThemeSettings: (field, value) => dispatch({ type: 'UPDATE_THEME_FIELD', value: {field: field, value: value} }),
      openNotification: (saveText, saveType, saveError) => dispatch({type: 'OPEN_NOTIFICATION', value: {saveText, saveType, saveError}}),
      closeNotification: data => dispatch({ type: 'CLOSE_NOTIFICATION'})
    };
  }
};
CommunitiesCustomization.displayName = 'Communities.Customization';
CommunitiesCustomization.propTypes = {
  proxyUrl: PropTypes.string.isRequired
};

export default connect(
  CommunitiesCustomization.mapStateToProps,
  CommunitiesCustomization.mapDispatchToProps
)(CommunitiesCustomization);


