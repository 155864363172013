class window.AdminInstructions

  @index: ->
    ShopDashboard.setup()

    $('body').on 'click', '#reject-instructions', (e) ->
      if reason = prompt('Why are you rejecting these instructions?')
        round = $(this).data('round')
        shop = $(this).data('shop')

        message = "#{round} Instructions Rejected:\n" +
                  reason

        NoteUtils.create('Shoppertunity', shop, message)
        true
      else
        false

    $('body').on 'click', '.clone_instructions', (e) ->
      e.preventDefault()
      $('#instruction_selector_modal').modal('show')
      new_url = "/admin/instructions/" + $('select#round_id').val() + "/clone?round_id=" + $('#round_id').data('to-round')
      $('#continue_clone_instructions').attr("href", new_url)

    $('#instruction_selector_modal_form').on 'change', '#round_id', (e) ->
      new_url = Routes.clone_admin_instruction_path($(this).val(), round_id: $('#round_id').data('to-round'))
      $('#continue_clone_instructions').attr("href", new_url)

  @new: ->
    ShopDashboard.setup()
    @form()

  @create: ->
    @form()

  @edit: ->
    ShopDashboard.setup()
    @form()

  @update: ->
    @form

  @form: ->
    $('#instruction_body').redactor
      buttonSource: true
      focus: true
      plugins: plugin_list

    $('.js-instruction-redactor').redactor
      plugins: plugin_list