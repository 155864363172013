import React from 'react';

export const UserSignUpPaymentFields = {
  "Canada": {
    paypalName: {
      label: 'Paypal Name',
      fieldType: 'text',
      className: 'input_wrap',
      required: true
    },
    paypalEmail: {
      label: 'Paypal Email',
      fieldType: 'email',
      className: 'input_wrap',
      required: true,
      regex: /[^@]+@[^\.]+\..+/g,
      regexMessage: 'Must be a valid email',
    }
  },
  "United States": {
    dob: {
      label: 'Date of Birth',
      regex: /^((0|1)\d{1})\/((0|1|2|3)\d{1})\/((19|20)\d{2})/g,
      regexMessage: 'Date of birth must be in the format MM/DD/YYYY',
      placeholder: 'MM/DD/YYYY',
      required: true,
      className: 'input_wrap',
      fieldType: 'text',
      ageRequirement: 18,
      ageRequirementMessage: 'Sorry, you must be 18 or older.'
    },
    tin: {
      label: 'Tax ID Number',
      regex: /^(\d\d\d-\d\d-\d\d\d\d|\d\d-\d\d\d\d\d\d\d)$/,
      regexMessage: 'Tax ID must be in the form XXX-XX-XXXX or XX-XXXXXXX',
      required: true,
      className: 'input_wrap',
      fieldType: 'text',
      placeholder: 'XXX-XX-XXXX or XX-XXXXXXX'
    },
    name1099: {
      label: '1099 Name',
      placeholder: 'Legal Name Associated With SSN or EIN',
      required: true,
      className: 'input_wrap',
      fieldType: 'text',
    },
    taxClassification: {
      label: 'Federal Tax Classification',
      required: true,
      className: 'input_wrap',
      fieldType: 'select',
      options: [
        'Individual/sole proprieter or single-member LLC',
        'C Corporation',
        'S Corporation',
        'Partnership',
        'Limited liability company and C corporation',
        'Limited liability company and S corporation',
        'Limited liability company and partnership',
      ]
    },
    routingNumber: {
      label: 'Bank Routing Number',
      regex: /^[0-3]\d\d\d\d\d\d\d\d$/,
      regexMessage: 'Must 9 digits and start with a number between 0 and 3',
      fieldType: 'text',
      className: 'input_wrap',
      required: true
    },
    accountNumber: {
      label: 'Bank Account Number',
      regex: /^\d\d?\d?\d?\d?\d?\d?\d?\d?\d?\d?\d?\d?\d?\d?\d?\d?$/,
      regexMessage: 'Must be 1-17 digits long',
      fieldType: 'text',
      className: 'input_wrap',
      required: true
    },
    accountType: {
      label: 'Account Type',
      required: true,
      fieldType: 'select',
      className: 'input_wrap',
      options: [
        'Checking Account',
        'Savings Account',
      ]
    },
    accountClass: {
      label: 'Account Class',
      required: true,
      fieldType: 'select',
      className: 'input_wrap',
      options: [
        'Personal (PPD)',
        'Business (CCD)',
      ]
    }
  }
}