import React from 'react';
import PropTypes from 'prop-types'

export default class DatePicker extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <input
        type='date'
        className={this.props.className || ''}
        min={this.props.minDate || ''}
        max={this.props.maxDate || ''}
        value={this.props.value}
        onChange={e => this.props.onChange(e.target.value)}
        disabled={this.props.disabled}
        />
    );
  }
}

DatePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool
};
