import React from 'react';

export default class MonetaryValue extends React.Component {
  constructor(props) {
    super(props);

    this.formattedValue = this.formattedValue.bind(this);
    this.render = this.render.bind(this);
  }

  render() {
    return (
      <span>
        ${this.formattedValue()}
      </span>
    );
  }

  formattedValue() {
    const num = String(Number(this.props.value || 0).toFixed(2));
    const [wholePart, fPart] = num.split('.');
    const shouldHaveComma = (index) => ((index + 1) % 3 === 0) && (index != wholePart.length - 1)

    const commaSeparated = wholePart.split('')
                                    .reverse()
                                    .map((x, i) => shouldHaveComma(i) ? `,${x}` : x)
                                    .reverse()
                                    .join('');

    return `${commaSeparated}.${fPart}`;
  }
}
