import React from 'react';
import { connect } from 'react-redux';

import AdvertiserAccessButton from './AdvertiserAccessButton'
import Datatable from '../Datatable'
import CurrentWavePage from './CurrentWavePage'
import { Remote } from '../utils'
import notif from "javascripts/notifIt.js"
import { data } from 'jquery';

class ContentsDatatable extends React.Component {
  constructor(props) {
    super(props);

    this.customAdColumns = this.customAdColumns.bind(this);
    this.influencerColumns = this.influencerColumns.bind(this);
    this.loadAccessStates = this.loadAccessStates.bind(this);
    this.loadEditorStates = this.loadEditorStates.bind(this);
    this.onTableLoad = this.onTableLoad.bind(this);
    this.openCustomWizard = this.openCustomWizard.bind(this);
    this.render = this.render.bind(this);
    this.renderCopyButton = this.renderCopyButton.bind(this);
    this.renderEditButton = this.renderEditButton.bind(this);
    this.updateCustomAdNotes = this.updateCustomAdNotes.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
  }

  render() {
    return <React.Fragment>
      {
        this.props.adType == 'influencer' &&
        <Datatable updateTime={this.props.updateTime}
                    datatableUrl={this.props.datatableUrl}
                    columns={this.influencerColumns()}
                    shopId={this.props.shopId}
                    roundId={this.props.roundId}
                    onLoad={this.onTableLoad}/>
      }

      {
        this.props.adType == 'custom' &&
        <Datatable updateTime={this.props.updateTime}
                    datatableUrl={this.props.customAdsDatatableUrl}
                    columns={this.customAdColumns()}
                    onLoad={this.onTableLoad}
                    adType={this.props.adType}/>
      }
    </React.Fragment>
  }

  onTableLoad(data) {
    // Get the lookup metadata from every visible row in the datatable
    const metadata = data.aaData.map(row => row[CurrentWavePage.METADATA_COLUMN]);

    this.loadEditorStates(metadata);
    this.loadAccessStates(metadata);
  }

  loadAccessStates(metadata) {
    const body = {
      user_ids: metadata.map(m => m.userId)
    };

    const params = {
      resource: 'bulk_show'
    };

    return Remote().request({
      url: `/admin/campaigns/${this.props.campaignId}/promoters_v2/api_proxy/sofab_advertiser_auths`,
      method:'POST',
      params: params,
      data: body,
      responseType: 'json',
      validateStatus: (status) => {
        return status < 500; // Resolve only if the status code is less than 500
      }
    }).then( (resp) => {
      this.props.updateAccess(resp.data)
    })

  }

  loadEditorStates(metadata) {
    return Remote().request({
      url: `/admin/campaigns/${this.props.campaignId}/promoters_v2/api_proxy/promoter_wizard_states?resource=bulk_show`,
      data: {
        wizard_states: metadata
      },
      responseType: 'json',
      method: 'POST'
    }).then((resp) => {
      const result = resp.data
      const newState = {};

      // Using the data we just received, update the lookup table with the correct icon
      result && result.forEach(state => {
        const newClass = state.state == 'ad_review' ? 'text-success fas fa-check' : 'fas fa-pencil-alt';
        newState[state.content_id || state.external_link || 'custom_'+state.id] = newClass;
      });

      // Merge the old icon state and the new icon state
      this.props.updateState({
        editingLookup: {
          ...this.props.editingLookup,
          ...newState
        }
      })
    }).catch((err) => {
      console.log(err)
    });;
  }

  updateNotes(id, value) {
    Remote().request({
      url: `/admin/contents/${id}/promoter`,
      method: 'PUT',
      responseType: 'json',
      data: { blog_post_content: { promoter: value } }
    }).then((resp) => notif({
      msg: 'Notes updated',
      type: 'notice',
      timeout: 1000
    })).catch((err) => notif({
      msg: 'Error on notes update',
      type: 'error',
      timeout: 1000
    }));
  }

  updateCustomAdNotes(id, value) {
    Remote().request({
      url: `/admin/campaigns/${this.props.campaignId}/promoters_v2/api_proxy/promoter_wizard_states?id=${id}`,
      method: 'PATCH',
      contentType: 'application/json',
      data: { promoter_notes: value }
    }).then((resp) => notif({
      msg: 'Notes updated',
      type: 'notice',
      timeout: 1000
    })).catch((err) => notif({
      msg: 'Error on notes update',
      type: 'error',
      timeout: 1000
    }));
  }

  openCustomWizard() {
    this.props.updateState({
      showCustomWizard: true
    });
  }

  renderEditButton(metadata) {
    const callback = () => this.props.updateState({
      showWizard: true,
      contentId: metadata.contentId,
      contentType: metadata.contentType,
      stateId: metadata.stateId,
      contentUrl: metadata.contentUrl,
      contentUtm: metadata.contentUtm,
      externalLink: metadata.externalLink,
      userId: metadata.userId
    });
    const iconStyle = {
      cursor: 'pointer',
      fontSize: '14pt'
    };
    const disabledStyle = {
      ...iconStyle,
      cursor: 'not-allowed'
    };

    // Escape hatch -- disable instagram content since we can't edit it yet
    if(String(metadata.contentUrl).includes('instagram.com')) {
      return (
        <td style={disabledStyle}>
          <i className='text-danger fas fa-ban'/>
        </td>
      );
    }

    return (
      <td onClick={callback} style={{ width: '20px' }}>
        <i style={iconStyle} className={this.props.editingLookup[metadata.contentId || metadata.externalLink || 'custom_'+metadata.stateId] || 'fas fa-plus'} />
      </td>
    );
  }

  renderCopyButton(metadata) {
    const callback = () => {
      prompt('Copy UTM link to clipboard: Command+C, Enter', metadata);
    };

    return (
      <td>
        <button name='button' type='submit' className='btn btn-primary btn-xs' onClick={callback}>Copy</button>
      </td>
    );
  }

  influencerColumns(){
    return [
      {
        displayName: 'Content',
        index: 0,
        type: 'html',
        width: '60px',
        sortable: false
      },
      {
        displayName: 'Created Date',
        index: 1,
        type: 'string',
        width: '100px',
        sortable: true
      },
      {
        displayName: 'Influencer',
        index: 2,
        type: 'html',
        width: '100px',
        sortable: true
      },
      {
        displayName: 'Blog',
        index: 3,
        type: 'html',
        width: '100px',
        sortable: false
      },
      {
        displayName: 'Notes',
        index: 4,
        type: 'note',
        width: '100px',
        sortable: false,
        callBack: this.updateNotes
      },
      {
        displayName: 'Total Engagement',
        index: 5,
        type: 'number',
        width: '100px',
        sortable: true
      },
      {
        displayName: 'Engagement Rate',
        index: 6,
        type: 'string',
        width: '100px',
        sortable: true
      },
      {
        displayName: 'Total Views',
        index: 7,
        type: 'number',
        width: '100px',
        sortable: true
      },
      {
        displayName: 'View Rate',
        index: 8,
        type: 'string',
        width: '100px',
        sortable: true
      },
      {
        displayName: 'Bleed Rate',
        index: 9,
        type: 'html',
        width: '100px',
        sortable: true,
        tooltip: 'Percentage of clicks that do not arrive at the content'
      },
      {
        displayName: 'Access',
        index: 10,
        type: 'html',
        width: '100px',
        sortable: false,
        customRender: value => <td className='text-center'>
          <AdvertiserAccessButton
            userId={value.userId}
            isInstagram={value.isInstagram}
            name={value.name}
            email={value.email}
            shopName={value.shopName}
            replyEmail={value.replyEmail}
            advertiserAuth={this.props.advertiserAuth}
            />
        </td>
      },
      {
        displayName: 'UTM',
        index: 11,
        type: 'html',
        width: '100px',
        sortable: false,
        customRender: (value) => this.renderCopyButton(value)
      },
      {
        displayName: '',
        index: 12,
        type: 'html',
        width: '20px',
        sortable: false,
        customRender: (value) => this.renderEditButton(value)
      }
    ];
  }

  customAdColumns() {
    return [
      {
        displayName: 'Content',
        index: 0,
        type: 'html',
        width: '60px',
        sortable: false
      },
      {
        displayName: 'Date Submitted',
        index: 1,
        type: 'string',
        width: '100px',
        sortable: true
      },
      {
        displayName: 'Destination Url',
        index: 2,
        type: 'html',
        width: '100px',
        sortable: false
      },
      {
        displayName: 'Notes',
        index: 3,
        type: 'note',
        width: '100px',
        sortable: false,
        callBack: this.updateCustomAdNotes
      },
      {
        displayName: '',
        index: 4,
        type: 'html',
        width: '10px',
        sortable: false,
        customRender: (value) => this.renderEditButton(value)
      }
    ];
  }


  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      campaignId: state.campaignId,
      adType: state.adType,
      rounds: state.rounds,
      shoppertunities: state.shoppertunities,
      editingLookup: state.editingLookup,
      roundId: state.roundId,
      shopId: state.shopId,
      updateTime: state.updateTime,
      datatableUrl: state.datatableUrl,
      customAdsDatatableUrl: state.customAdsDatatableUrl,
      advertiserAuth: state.advertiserAuth
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateState: changes => dispatch({ type: 'UPDATE_STATE', value: changes }),
      updateAccess: value => dispatch({ type: 'UPDATE_ACCESS', value })
    };
  }
};

ContentsDatatable.displayName = 'FacebookAds.ContentsDatatable';
export default connect(
  ContentsDatatable.mapStateToProps,
  ContentsDatatable.mapDispatchToProps
)(ContentsDatatable);
