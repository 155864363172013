import React from 'react';
import PropTypes from 'prop-types'
import { Remote } from '../utils';

import Tooltip from '../Tooltip'
export default class RoundTasksTemplateLegendRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltip: {
        button: 'deleteTemplateRequirements',
        visible: false
      }
    }

    this.removeTemplateRequirements = this.removeTemplateRequirements.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.render = this.render.bind(this);
  }

  removeTemplateRequirements() {
    const params = $.param({api_action: `/round_task_requirements/destroy_by_template` });

    const data = {
      template_id: this.props.item.templateId,
      round_task_id: this.props.taskId
    };

    Remote().request({
      method: 'POST',
      url: this.props.proxyUrl + '?' + params,
      data: data,

    }).then((response) => {
      this.props.deleteTemplateRequirements(this.props.taskId, this.props.item.templateId)
    });
  }

  toggleTooltip(button) {
    this.setState({
      tooltip: {
        button: button,
        visible: !this.state.tooltip.visible
      }
    })
  }


  render() {
    const { tooltip: { button, visible } } = this.state;
    const { item } = this.props;
    const deleteTooltip = 'Remove associated content requirements from Task.';

    return <div className='round-tasks template-legend-row'>
      <i className={`fas fa-circle ${item.color}`}/>
      {item.name}
      {!this.props.instructionApproved &&
        <div className='template-legend-row--delete'>
          <i
            className='fas fa-times'
            onClick={this.removeTemplateRequirements}
            onMouseLeave={() => this.toggleTooltip('deleteTemplateRequirements')}
            onMouseEnter={() => this.toggleTooltip('deleteTemplateRequirements')}
          />
          { (visible && button === 'deleteTemplateRequirements') &&
            <Tooltip centerText={true} placement='top' message={deleteTooltip}/>
          }
        </div>
      }
    </div>;
  }
};

RoundTasksTemplateLegendRow.displayName = 'RoundTasks.TemplateLegendRow';
RoundTasksTemplateLegendRow.propTypes = {
  item: PropTypes.shape({
    templateId: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};
