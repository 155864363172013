import React from 'react';
import { Provider } from "react-redux"
import RoundTasksFormStore from '../../../redux/RoundTasksForm';
import GeneralRequirementsNew from './New'

export default class GeneralRequirementsNewWrapper extends React.Component {
  render() {
    return (
      <Provider store={RoundTasksFormStore}>
        <GeneralRequirementsNew {...this.props}/>
      </Provider>
    );
  }
};
