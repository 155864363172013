import React from 'react';
import '../plugins/autolink.min.js'
import '../plugins/chartjs/Chart.PieceLabel.min.js'

export default class BidHistory extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showHistory: false,
      dropdownIcon: 'fa-caret-down'
    }

    this.render = this.render.bind(this);
    this.toggleHistory = this.toggleHistory.bind(this);
  }

  toggleHistory() {
    this.setState({
      showHistory: !this.state.showHistory,
      dropdownIcon: this.state.showHistory ? 'fa-caret-down' : 'fa-caret-up',
    });
  }

  humanizeState(state){
    switch(state) {
      case 'accepted':
        return 'Accepted';
      case 'admin_declined':
        return 'Original Bid';
      case 'influencer_declined':
        return 'Declined';
      default:
        return'Pending';
    };
  }

  humanizeDate(date){
    return new Date(date).toLocaleDateString('en-US')
  }

  render() {
    const adminView = this.props.viewType != 'influencer';
    return(
      <div className='bid_history'>
        <h5 className='bid_history--dropdown' onClick={this.toggleHistory}>
          Bid History
          &nbsp;&nbsp;
          <i className={`fas ${this.state.dropdownIcon}`} />
        </h5>
        {
          this.state.showHistory &&
            <table className="table bid_history--table">
              <thead>
                <tr>
                  <th scope="col">Amount</th>
                  <th scope="col">Status</th>
                  {adminView && <th scope="col">Notes</th> }
                  {adminView && <th scope="col">Declined Reason</th>}
                  <th scope="col">Last Update</th>
                </tr>
              </thead>
              <tbody>
              {
                this.props.history.map(bid => {
                  return <tr key={bid.id}>
                           <td className='bid_history--table--amount_cell'>${bid.amount}</td>
                           <td className='bid_history--table--state_cell'>{this.humanizeState(bid.state)}</td>
                           {adminView && <td className='bid_history--table--note_cell'>{bid.note}</td>}
                           {adminView && <td className='bid_history--table--reason_cell'>{bid.declined_reason}</td>}
                           <td className='bid_history--table--updated_cell'>{this.humanizeDate(bid.updated_at)}</td>
                         </tr>
                })
              }
              </tbody>
            </table>
        }
      </div>
      );
  }
}