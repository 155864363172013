import React from 'react';
import { Remote } from '../utils';

// This differs from V1 in a few ways:
// - All calls are routed through the ad automation service proxy
// - All state is managed within react, rather than form elements
// - Refreshing the datatable is done via a prop, rather than $.datatable
export default class PromoterWizardV2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contentId: null,
      stateId: null,
      contentUrl: null,
      contentUtm: null,
      contentExternalLink: null,
      wizardState: null,
      wizardStep: null,
      loading: false,
      contentType: null
    };

    this.closeModal = this.closeModal.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    this.createProxyUrl = this.createProxyUrl.bind(this);
    this.proceed = this.proceed.bind(this);
    this.render = this.render.bind(this);
    this.renderError = this.renderError.bind(this);
    this.renderNav = this.renderNav.bind(this);
    this.renderStep = this.renderStep.bind(this);
    this.requestNewStep = this.requestNewStep.bind(this);
    this.serializeSteps = this.serializeSteps.bind(this);
    this.setWizardStep = this.setWizardStep.bind(this);
    this.syncStateWithServer = this.syncStateWithServer.bind(this);
    this.updateStep = this.updateStep.bind(this);
    this.wizardStateBack = this.wizardStateBack.bind(this);
    this.wizardStateForward = this.wizardStateForward.bind(this);
  }

  componentDidMount() {
    this.setState({
      contentId: this.props.contentId,
      stateId: this.props.stateId,
      contentUrl: this.props.contentUrl,
      contentUtm: this.props.contentUtm,
      contentExternalLink: this.props.contentExternalLink,
      userId: this.props.userId,
      contentType: this.props.contentType
    }, () => this.syncStateWithServer(this.props.forceRefresh))
  }

  componentWillReceiveProps(oldProps, newProps) {
    this.setState({
      contentId: this.props.contentId,
      stateId: this.state.stateId,
      contentUrl: this.props.contentUrl,
      contentUtm: this.props.contentUtm,
      contentExternalLink: this.props.contentExternalLink,
      userId: this.props.userId,
      contentType: this.props.contentType
    });
  }

  render() {
    return (
      <div className='modal fade in' style={{overflowY: 'scroll', display: 'block', paddingLeft: '0'}} tabIndex='-1' role='dialog'>
        <div className='modal-dialog modal-lg' role='document'>
          <div className='modal-content wizard-header'>
            <div className='modal-header text-center'>
              {this.renderNav()}
            </div>
            <div className='modal-body promoters'>
              {!this.state.loading && this.renderError()}
              {this.state.loading ? <LoadingSpinner loading={true} /> : this.renderStep()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  syncStateWithServer(callback) {
    const component = this;
    this.setState({ loading: true });
    const query = {
      id: this.state.stateId,
      content_id: this.state.contentId,
      content_url: this.state.contentUrl,
      utm: this.state.contentUtm,
      external_link: this.state.contentExternalLink,
      content_type: this.state.contentType
    };

    $.get(this.createProxyUrl('promoter_wizard_states', query), (data) => {
      component.setState({
        wizardState: data,
        stateId: data.id,
        loading: false
      }, component.setWizardStep);

      if(callback) {
	      callback();
      }
    });
  }

  wizardStateBack(step, state) {
    const currentStep = Number(this.state.wizardStep);

    if (currentStep > step) {
      this.requestNewStep(state);
    } else {
      event.preventDefault();
    }
  }

  wizardStateForward(state) {
    this.requestNewStep(state)
  }

  renderError() {
    if (!this.state.wizardState) {
      return null
    }

    return (
      <label className='error'>{this.state.wizardState.error_message}</label>
    )
  }

  renderNav() {
    if(!this.state.wizardState) {
      return 'Loading...';
    } else {
      return (
        <div>
          <i className='fas fa-times fa-2x pull-right wizard-close' onClick={this.closeModal}/>
          <a className='content-link' href={this.state.wizardState.url} target='_blank'>{this.state.wizardState.url}</a>
          <Navigation wizardStateBack={this.wizardStateBack} wizardStep={this.state.wizardStep}/>
        </div>
      );
    }

    return this.state.wizardState.url;
  }

  renderStep() {
    if (!this.state.wizardState) {
      return null;
    }
    const typesEndpoint = this.createProxyUrl('promoter_ad_types', {});
    const stateEndpoint = this.createProxyUrl('promoter_wizard_states', { id: this.state.wizardState.id });
    const reloadEndpoint = this.createProxyUrl('promoter_wizard_states', { id: this.state.wizardState.id, resource: 'promoter_ad_images' });
    const saveEndpoint = this.createProxyUrl('promoter_wizard_states', { id: this.state.wizardState.id, resource: 'promoter_ad_images/bulk_update' });
    const uploadEndpoint = `/admin/campaigns/${this.props.campaignId}/promoters_v2/upload_ad_image`;
    const refreshEndpoint = this.createProxyUrl('promoter_wizard_states', { id: this.state.wizardState.id, resource: 'facebook_ad_instances/refresh' });
    const previewEndpoint = this.createProxyUrl('promoter_wizard_states', { id: this.state.wizardState.id, resource: 'ad_preview' });
    const adsEndpoint = this.createProxyUrl('promoter_wizard_states', { id: this.state.wizardState.id, resource: 'facebook_ad_instances'});
    const adEndpoint = (id) => this.createProxyUrl('promoter_wizard_states', { id: this.state.wizardState.id, resource: `facebook_ad_instances/${id}`});
    const generateEndpoint = this.createProxyUrl('promoter_wizard_states', { id: this.state.wizardState.id, resource: 'generate_ad_sets'});

    switch (this.state.wizardState.state) {
      case 'ad_types_selection':
        return <AdSelection typesEndpoint={typesEndpoint}
                                        wizardEndpoint={stateEndpoint}
                                        proceed={this.proceed}
                                        wizardState={this.state.wizardState}
                                        campaignId={this.props.campaignId}
                                        userId={this.state.userId}
                                        contentType={this.state.contentType}/>;

      case 'image_selection':
        return <ImageSelection reloadEndpoint={reloadEndpoint}
                                           saveEndpoint={saveEndpoint}
                                           proceed={this.proceed}
                                           uploadEndpoint={uploadEndpoint}
                                           wizardState={this.state.wizardState} />

      case 'write_copy':
        return <CopyEditor updateEndpoint={stateEndpoint}
                                       refreshEndpoint={refreshEndpoint}
                                       previewEndpoint={previewEndpoint}
                                       proceed={this.proceed}
                                       wizardState={this.state.wizardState} />;
      case 'ad_review':
        return <AdReview adsEndpoint={adsEndpoint}
                                     adEndpoint={adEndpoint}
                                     generateEndpoint={generateEndpoint}
                                     stateEndpoint={stateEndpoint}
                                     proceed={this.proceed}
                                     wizardState={this.state.wizardState}
                                     previewEndpoint={previewEndpoint}
                                     refresh={this.props.forceRefresh}
                                     closeModal={this.closeModal} />;

      default:
        return <AdSelection proceed={this.proceed}
                                        wizardState={this.state.wizardState}
                                        campaignId={this.props.campaignId}
                                        contentType={this.state.contentType}/>;
    }
  }

  setWizardStep(){
    if(!this.state.wizardState) {
      return;
    }

    switch(this.state.wizardState.state){
      case 'ad_types_selection' :
        this.setState({ wizardStep: 1 });
        break;
      case 'image_selection' :
        this.setState({ wizardStep: 2 });
        break;
      case 'write_copy' :
        this.setState({ wizardStep: 3 });
        break;
      case 'ad_review' :
        this.setState({ wizardStep: 4 });
        break;
      default:
        return;
    }
  }

  proceed() {
    // Since wizardStep is 1-indexed, it will always map to the next step
    const stepMap = ['ad_types_selection', 'image_selection', 'write_copy', 'ad_review'];
    const nextStep = stepMap[this.state.wizardStep];
    this.wizardStateForward(nextStep);
  }

  serializeSteps(state) {
    return {
      id: this.state.wizardState.id,
      desired_state: state
    };
  }

  updateStep(data) {
    this.setState({
      wizardState: data
    }, this.setWizardStep);
  }

  requestNewStep(state) {
    const component = this;
    this.setState({ loading: true });

    Remote().request({
      url: this.createProxyUrl('promoter_wizard_states', { resource: 'state', id: this.state.wizardState.id }),
      method: 'PATCH',
      data: this.serializeSteps(state),
    }).then((response) => {
      component.setState({ loading: false });
      component.updateStep(response.data);
    });
  }

  closeModal() {
    this.props.closeModal();
  }

  createProxyUrl(route, params) {
    const query = $.param(params);
    return `/admin/campaigns/${this.props.campaignId}/promoters_v2/api_proxy/${route}?${query}`
  }
};
