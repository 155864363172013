import React from 'react';
import { Remote } from '../utils';

export default class HeaderSearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.state = {
      query: null,
      category: 'users',
      results: [],
      loading: false
    }
    this.search = this.search.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.clearQuery = this.clearQuery.bind(this);
  }

  handleChange(event) {
    if (!!this.timeout) {
      clearTimeout(this.timeout);
    }
    const query = event.target.value;
    this.setState({query})
    if (query.length > 2) {
      this.timeout = setTimeout(() => {this.search()}, 600);
    }
  }

  search() {
    this.setState({loading: true})
    const {query,category} = this.state;
    Remote().request({
      method: 'GET',
      url: `/search.json?q=${query}&c=${category}`,
      // data: {turbolinks: "false"}
      //dataType: 'json'
    }).then((response) => {
        const results = response.data.results;

        this.setState({ loading: false, results });
      }
    ).catch((error) => {
        console.log(error);
        this.setState({ loading: false })
      }
    )
  }

  renderResult(item) {
    return (
      <div key={item.id} className="search-result">
        <a href={`/profile/${item.username}`} className="search-result-link">
          {item.full_name || item.agency_name}
        </a>
      </div>
    )
  }

  clearQuery() {
    this.setState({query:null})
  }

  render() {
    const {results, loading, query} = this.state;
    const showResults = !!query && !!results && !!results.length
    return (
      <div className="top-search js-top-search is-hidden">
        <div className="container">
          <form className="top-search-form" id="search" action="/search" acceptCharset="UTF-8" method="get">
            <input name="utf8" type="hidden" value="✓"/>
            <input id="search_class" name="c" type="hidden" value="users"/>
            <a href="#" className="search-toggle hidden-sm hidden-md hidden-lg js_close_search_bar">
              <i className="fas fa-chevron-left"></i> Back
            </a>
            <div className="input-group">
              <input type="text" name="q" id="q" className="form-control"
                     autoFocus="autofocus"
                     placeholder="Search..."
                     onChange={this.handleChange}/>
              <div className="input-group-btn">
                <button className="btn-default btn header-search-btn" id="go"
                        type="submit">
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
            <button className="floating close_search_bar hidden-xs js_close_search_bar"
                    type="button">
              <i className="fas fa-times"></i>
            </button>
            <div className={`search-result-container ${(loading || showResults) ? '' : 'hidden'}`}>
              {
                loading && (<div className="search-loading">
                  <i className="fas fa-spin fa-circle-notch"></i>
                </div>)
              }
              {
                !loading && showResults && (<div className="search-results">
                  {
                    !!results.length && (<React.Fragment>
                      {
                        results.map((item) => (this.renderResult(item)))
                      }
                    </React.Fragment>)
                  }
                </div>)
              }
            </div>
          </form>
        </div>
      </div>
    )
  }
}

HeaderSearchForm.displayName = 'Header.HeaderSearchForm';