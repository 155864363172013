import React from 'react';

export default class Invitation extends React.Component {
  render(){
    const {activity, shoppertunity_name, recruiter_name, display_name} = this.props.notification;
    const P = React.Fragment;
    switch(activity) {
      case 'admin_invited':
        return (
          <P><b>{recruiter_name}</b> invited {display_name} to <b>{shoppertunity_name}</b></P>
        )
      case 'invited':
        return (
          <P>You have been invited to apply for <b>{shoppertunity_name}</b></P>
        )
      default:
        return null;
    }
  }
}
Invitation.displayName = 'Notification.Templates.Invitation'