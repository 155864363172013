import React from 'react';
import Toggle from '../Toggle';
import UserFormSection from './Section';

const UserFormPlatformAccess = ({accessSocialFabric, accessDashboard, updateField, errors}) => {
  return <UserFormSection title="Platform Access"
    fields={
      <div>
        <div className="row">
          <div className="col-md-12">
            <label>Social Fabric User</label>
            <p className="d-inline-block ml-15 pull-right">
              User access to login to Social Fabric
            </p>
            <Toggle
              checked={accessSocialFabric}
              onUpdate={ (chk) => updateField("accessSocialFabric", chk) }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label>Dashboard User</label>
            <p className="d-inline-block ml-15 pull-right">
              User access to login to Dashboard
            </p>
            <Toggle
              checked={accessDashboard}
              onUpdate={(chk) => updateField("accessDashboard", chk)}
            />
          </div>
        </div>
        { !!errors['platformAccess'] &&
          <span className='text-danger'>
            { errors['platformAccess'] }
          </span>
        }
      </div>
    }
  />;
};

export default UserFormPlatformAccess;
UserFormPlatformAccess.displayName = 'UserForm.PlatformAccess';