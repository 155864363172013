import React from 'react';
import PropTypes from 'prop-types';

export default class PagePlaceholder extends React.Component {
  render() {
    return (
      <div className='page_placeholder'>
        <div className='display_icon'>
          <div className='icon_layer layer_1'>
            <div className='internal_layer layer_1'></div>
            <div className='internal_layer layer_2'></div>
          </div>
          <div className='icon_layer layer_2'>
            <div className='internal_layer layer_1'></div>
            <div className='internal_layer layer_2'></div>
          </div>
        </div>
        {
          this.props.description &&
          <div className='display_description'>
            {this.props.description}
          </div>
        }
      </div>
    );
  }
};

PagePlaceholder.displayName = 'PagePlaceholder';
PagePlaceholder.propTypes = {
  description: PropTypes.string.isRequired
};
