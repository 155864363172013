import React from 'react'
import InstagramOembed from '../InstagramOembed';

export default class AuxillaryContentsInstagramPost extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      username: props.username || null,
      oembed_html: props.html || null,
      views: props.views || 0,
      likes: props.likes || 0,
      comments: props.comments || 0,
      saves: props.saves || 0,
      followers: props.followers || 0,
      image_url: props.image_url || null,
      post_type: props.post_type || '',
      loading: false,
      error: null
    }

    this.setOembedData = this.setOembedData.bind(this);
    this.saveVal = this.saveVal.bind(this);
    this.save = this.save.bind(this);

    this.debouncedUpdate = _.debounce(this.saveVal, 500);
  }

  setOembedData(data){
    this.setState({
      ...this.state,
      ...data,
      title: 'Instagram Post Content'
    });
  }

  saveVal(prop, value) {
    if(!!value && !isNaN(parseInt(value))) {
      this.setState({ [prop]: parseInt(value) })
    }
  }

  save() {
    const {title, image_url, likes, comments, views, saves, followers, username, post_type} = this.state;
    const {type, setValue, onSave} = this.props;
    this.setState({loading: true});

    setValue('contentMeta', {type, title, username, likes, views, comments, saves, followers, image_url, post_type}, onSave);
  }


  render() {
    const {oembed_html, loading, views, likes, comments, saves, followers} = this.state;

    const {proxyUrl, url} = this.props;
    const inlineLabel = {
      margin: '0 5px'
    }

    return (
      <div style={{ width: '100%', padding: '10px 0', boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)' }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h3>Instagram Post Details</h3>
              <hr/>
            </div>
            <div className="col-md-9">
            <InstagramOembed
              proxyUrl={proxyUrl}
              url={url}
              oembed_html={oembed_html}
              setOembedData={(data) => this.setOembedData(data)}
            />
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="views" style={inlineLabel}>Views</label>
                <input defaultValue={views} onChange={(event) => {this.debouncedUpdate('views', event.target.value)}} type="number" className="form-control"/>
              </div>
              <div className="form-group">
                <label htmlFor="likes" style={inlineLabel}>Likes</label>
                <input defaultValue={likes} onChange={(event) => {this.debouncedUpdate('likes', event.target.value)}} type="number" className="form-control"/>
              </div>
              <div className="form-group">
                <label htmlFor="comments" style={inlineLabel}>Comments</label>
                <input defaultValue={comments} onChange={(event) => {this.debouncedUpdate('comments', event.target.value)}} type="number" className="form-control"/>
              </div>
              <div className="form-group">
                <label htmlFor="saves" style={inlineLabel}>Saves</label>
                <input defaultValue={saves} onChange={(event) => {this.debouncedUpdate('saves', event.target.value)}} type="number" className="form-control"/>
              </div>
              <div className="form-group">
                <label htmlFor="followers" style={inlineLabel}>Followers</label>
                <input defaultValue={followers} onChange={(event) => {this.debouncedUpdate('followers', event.target.value)}} type="number" className="form-control"/>
              </div>
            </div>
            <div className="col-md-12 text-right">
              <hr />
              <button className="btn btn-primary" disabled={loading} onClick={() => this.save()}>Save</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
AuxillaryContentsInstagramPost.displayName = 'AuxillaryContent.InstagramPost';
