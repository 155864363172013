import React from 'react';
import { Remote } from '../../utils'
import { connect } from 'react-redux';
import SearchList from '../../SearchList';

class CampaignsRequirementsAddFromTemplatesButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.buttonText = this.buttonText.bind(this);
    this.insertCard = this.insertCard.bind(this);
    this.render = this.render.bind(this);
  }

  render() {
    const templateKey = CampaignsRequirementsAddFromTemplatesButton.templateKey(this.props.templateType)

    if(!this.state.open) {
      return <div className={`btn ${this.props.templateType}_template_button`} onClick={() => this.setState({ open: true })}>
        <span className='left'>{this.buttonText()}</span> <i className='fas fa-caret-down'/>
      </div>;
    }

    return <SearchList
      startFocus={true}
      placeholder='Search templates'
      style={{marginLeft: '15px'}}
      onSelect={this.insertCard}
      onBlur={() => this.setState({ open: false })}
      elements={this.props[templateKey]}
      loading={this.props.loadingTemplates}
      />;
  }

  buttonText() {
    switch(this.props.templateType) {
    case 'campaign':
    case 'campaign_general':
    case 'instruction_note':
      return 'Add from Community';
    case 'instance':
    default:
      return 'Add from Campaign';
    }
  }

  insertCard(requirement) {
    this.setState({ open: false });

    if (this.props.selectCallback)
      this.props.selectCallback(requirement)

    if (this.props.proxyUrl) {
      const params = $.param({
        api_action: `/round_tasks/${requirement.id}/clone/${this.props.templateType}/${this.props.campaign_id}`
      });

      Remote().request({
        url: this.props.proxyUrl + '?' + params,
        method: 'POST',
        data: {without_prefix: true},
      }).then((response) => {
        this.props.addTask(response.data);
      }).catch((error) => {
        this.props.openNotification('Failed to create from template', 'failure', []);
        setTimeout(this.props.closeNotification, 5000);
      });
    }
  }

  static mapStateToProps(state, myProps) {
    const templateKey = CampaignsRequirementsAddFromTemplatesButton.templateKey(myProps.templateType)
    return {
      ...myProps,
      [templateKey]: state[templateKey],
    };
  }

  static templateKey(templateType){
    switch(templateType) {
      case 'campaign':
        return 'communityTemplates';
      case 'campaign_general':
        return 'generalTemplates';
      case 'instruction_note':
        return 'instructionTemplates'
      default:
        return 'generalTemplates';
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      addTask: task => dispatch({ type: 'ADD_TASK', value: task }),
      openNotification: (saveText, saveType, saveError) => dispatch({type: 'OPEN_NOTIFICATION', value: {saveText, saveType, saveError}}),
      closeNotification: data => dispatch({ type: 'CLOSE_NOTIFICATION'})
    };
  }
};

CampaignsRequirementsAddFromTemplatesButton.displayName = 'Campaigns.Requirements.AddFromTemplatesButton';

export default connect(
  CampaignsRequirementsAddFromTemplatesButton.mapStateToProps,
  CampaignsRequirementsAddFromTemplatesButton.mapDispatchToProps
)(CampaignsRequirementsAddFromTemplatesButton);
