import React from 'react';
import RichTextEditor from '../RichTextEditor'
import RoundTasksSelect from './inputs/Select'
import RoundTasksRadio from './inputs/Radio'

export default class RoundTasksForm extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  render() {
    return (
      <form className='form'>
        <div className='input_wrap'>
          <abbr title='required'>*</abbr>
          <label>Name</label>
          <input
            type='text'
            onChange={e => this.props.updateField('name', e.target.value)}
            placeholder='Enter Task Name'
            className='form-control'
            value={this.props.roundTask.name || ''}
            />
        </div>
        <div className='input_wrap'>
          <label>Description</label>
          <RichTextEditor
            placeholder=''
            value={this.props.roundTask.description || ''}
            onUpdate={(html) => this.props.updateField('description', html)}/>
        </div>
        <div className='input_wrap'>
          <abbr title='required'>*</abbr>
          <label htmlFor='content_types'>Available Content Types</label>
          <RoundTasksSelect
            name='content_types'
            updateField={this.props.updateField}
            index={null}
            required={true}
            placeholder='Select Content Types'
            options={this.props.contentTypes}
            value={this.props.roundTask.content_types || []}
            disabled={false}
            multiSelect={true}
            />
         </div>
        <div className='input_wrap'>
          <abbr title='required'>*</abbr>
          <label htmlFor='community'>Community</label>
          <RoundTasksSelect
            name='community_id'
            updateField={this.props.updateField}
            index={null}
            required={true}
            placeholder='Select Community'
            options={this.props.communities}
            value={this.props.roundTask.community_id}
            disabled={false}
            multiSelect={false}
            />
         </div>
        <div className='input_wrap'>
          <abbr title='required'>*</abbr>
          <label htmlFor='syndication'>Submission Type</label>
          <RoundTasksRadio
            name='syndication'
            updateField={this.props.updateField}
            index={null}
            required={true}
            options={this.props.roundTask.submission_types || []}
            value={this.props.roundTask.syndication || false}
            disabled={false}
            />
         </div>
      </form>
    );
  }
}

RoundTasksForm.displayName = 'RoundTasks.Form';
