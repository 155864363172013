class window.AdminInstagramHighreachSummaries

  @new: ->
    @form()

  @edit: ->
    @form()

  @update: ->
    @edit()

  @create: ->
    @new()

  @form: ->
    $('#instagram_highreach_summary_featured_products').redactor
      toolbarFixed: true
      toolbarFixedTarget: '#instagram_highreach_summary_featured_products'
      plugins: plugin_list

    $('#instagram_highreach_summary_other_info').redactor
      toolbarFixed: true
      toolbarFixedTarget: '#instagram_highreach_summary_other_info'
      plugins: plugin_list
