import React from 'react';
import { connect } from 'react-redux';
import ShoppertunitiesConnectButton from './ConnectButton'
import ShoppertunitiesRoundsSelected from './RoundsSelected';

class ShoppertunitiesActionBar extends React.Component {
  constructor(props) {
    super(props);

    this.showInstagramNotice = this.showInstagramNotice.bind(this);
    this.roundsSelectedCss = this.roundsSelectedCss.bind(this);
    this.render = this.render.bind(this);
  }

  showInstagramNotice(){
    const { selectedRounds, shoppertunity } = this.props;
    const { platform } = shoppertunity;
    return platform.name == 'Instagram' && !shoppertunity.instagram_requirements_met && selectedRounds.length > 0
  }

  roundsSelectedCss(){
    const { selectedRounds } = this.props;
    if(selectedRounds.length > 0){
      return "round_checkbox_checked_js"
    }
    return null;
  }

  render(){
    const classNames = `shop_details--action_header text-center ${this.roundsSelectedCss()}`
    return <div id="affixed_cta_header" className={classNames}>
      {this.showInstagramNotice() && <ShoppertunitiesConnectButton/>}
      <ShoppertunitiesRoundsSelected />
    </div>
  }

  static mapStateToProps(state, myProps){
    return {
      ...myProps,
      meta: state.meta,
      shoppertunity: state.shoppertunity,
      selectedRounds: state.selectedRounds
    };
  }
};

ShoppertunitiesActionBar.displayName = 'Shoppertunities.ActionBar';

export default connect(
  ShoppertunitiesActionBar.mapStateToProps
)(ShoppertunitiesActionBar);