import React from 'react';
import { connect } from 'react-redux';

class UsersEmailSettings extends React.Component {
  constructor(props) {
    super(props);

    this.getEmailClass = this.getEmailClass.bind(this);
    this.isValidEmail = this.isValidEmail.bind(this);
    this.render = this.render.bind(this);
    this.updateHandler = this.updateHandler.bind(this);
  }

  render() {
    const { i18n } = this.props;

    return <div className='card-plus users-email-settings'>
      <div className='card-header'>
        {i18n.labels.email_settings}
      </div>

      <div className='card-body'>
        <div className='form-group'>
          <label>{i18n.labels.current_email}</label>
          <input className='form-control' disabled={true} value={this.props.currentEmail || ''} />
        </div>
        <div className='form-group'>
          <label>{i18n.labels.new_email}</label>
          <input
            className={this.getEmailClass('newEmail')}
            value={this.props.newEmail}
            onChange={e => this.updateHandler({ newEmail: e.target.value })}
            />
          {this.getHelpText('newEmail')}
        </div>
        <div className='form-group'>
          <label>{i18n.labels.confirm_email}</label>
          <input
            className={this.getEmailClass('confirmEmail')}
            value={this.props.confirmEmail}
            onChange={e => this.updateHandler({ confirmEmail: e.target.value })}
            />
          {this.getHelpText('confirmEmail')}
        </div>
      </div>
    </div>
  }

  getEmailClass(field) {
    const email = this.props[field];
    let className = 'form-control ';

    if(!this.props.newEmail && !this.props.confirmEmail) {
      // return early when there's no text entered
      return className;
    }

    if(field == 'confirmEmail' && this.props.confirmEmail !== this.props.newEmail) {
      return className + 'invalid';
    }

    if(this.isValidEmail(email)) {
      className += 'valid ';
    } else {
      className += 'invalid ';
    }

    return className;
  }

  getHelpText(field) {
    if(!this.props.newEmail && !this.props.confirmEmail) {
      return null;
    }

    const { i18n } = this.props;
    const email = this.props[field];
    if(field == 'confirmEmail' && email != this.props.newEmail) {
      return <span className='help-text no-prefix'>
        {i18n.errors.email_no_match}
      </span>;
    }

    if(!this.isValidEmail(email) && field != 'confirmEmail') {
      return <span className='help-text no-prefix'>
        {i18n.errors.email_invalid}
      </span>;
    }

    return null;
  }

  isValidEmail(email) {
    return !!String(email).match(/^(\w|\.|\-|\+|\d)+@(\w|\.|\-|\d)+$/);
  }

  updateHandler(change) {
    const newState = {
      newEmail: this.props.newEmail,
      confirmEmail: this.props.currentEmail,
      ...change
    };
    const { newEmail, confirmEmail } = newState;

    if(newEmail || confirmEmail) {
      this.props.updateValidations(this.isValidEmail(newEmail) && newEmail === confirmEmail);
    } else {
      // Both being empty will always be valid
      this.props.updateValidations(true);
    }

    this.props.updateSettings(change);
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      currentEmail: state.settings.email,
      newEmail: state.settings.newEmail,
      confirmEmail: state.settings.confirmEmail,
      i18n: state.meta.i18n
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateSettings: change => dispatch({ type: 'UPDATE_SETTINGS', value: change }),
      updateValidations: valid => dispatch({ type: 'UPDATE_VALIDATIONS', value: { email: valid } })
    };
  }
};

UsersEmailSettings.displayName = 'Users.EmailSettings';

export default connect(
  UsersEmailSettings.mapStateToProps,
  UsersEmailSettings.mapDispatchToProps
)(UsersEmailSettings);
