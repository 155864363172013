import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import RoundTasksTemplateLegendRow from './TemplateLegendRow'

class RoundTasksTemplateLegend extends React.Component {
  render() {
    const { palette, templateIds } = this.props;

    return <div className='round-tasks template-legend'>
      <div className='legend-title'>
        General Templates
      </div>
      <div className='legend-body'>
        {
          Object
            .keys(palette)
            .filter(key => _.includes(templateIds, Number(key)))
            .map(key =>
              <RoundTasksTemplateLegendRow
                key={key}
                {...this.props}
                item={palette[key]}
              />
            )
        }
      </div>
    </div>
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      palette: state.palette
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      deleteTemplateRequirements: (taskId, templateId) => dispatch({ type: 'DELETE_TEMPLATE_REQUIREMENTS', value: { taskId: taskId, templateId: templateId }})
    }
  }
};

RoundTasksTemplateLegend.displayName = 'RoundTasks.TemplateLegend';
RoundTasksTemplateLegend.propTypes = {
  templateIds: PropTypes.arrayOf(PropTypes.number).isRequired
};
export default connect(
  RoundTasksTemplateLegend.mapStateToProps,
  RoundTasksTemplateLegend.mapDispatchToProps
)(RoundTasksTemplateLegend);
