import React from 'react';
import { Provider } from "react-redux";
import CampaignsRequirementsStore from '../../redux/CampaignRequirements';
import CampaignsRequirementsRequirementsContainer from '../requirements/RequirementsContainer';

export default class CampaignsRequirementsIndex extends React.Component {
  render() {
    return <Provider store={CampaignsRequirementsStore}>
        <CampaignsRequirementsRequirementsContainer {...this.props} /> 
    </Provider>
  }
};

CampaignsRequirementsIndex.displayName = 'Campaigns.Requirements.Index'