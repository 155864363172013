import React from 'react';
import { connect } from 'react-redux';

class ShoppertunitiesPaymentCalculator extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      bidAmount: this.setBidAmount()
    }

    this.render = this.render.bind(this);
    this.handleBidInput = this.handleBidInput.bind(this);
    this.setBidAmount = this.setBidAmount.bind(this);
  }

  handleBidInput(amount){
    this.setState({bidAmount: amount})
    const promise = () => new Promise((resolve) => {
      resolve(this.props.updateBid(this.props.id, this.props.user_id, amount));
    });

    promise().then(() => {
      this.props.canSubmitCheck();
    });
  }

  setBidAmount(){
    const {round_bids} = this.props;
    if(round_bids.length === 0) {
      return 0;
    }else {
      return Object.values(this.props.round_bids).filter(x => x['round_id'] === this.props.id)[0].amount;
    }
  }


  render() {
    const {bidAmount} = this.state;
    let totalPayment = _.sum([this.props.pre_payment, this.props.additional_payment, bidAmount])
    return(
      <div className='round-bid-payment-calculator'>
        <label className='text-weight-regular'>Round Bid Calculator</label>
        <div className="round-bid-input input-group">
          <span className="input-group-addon">
            <i className="fas fa-dollar-sign"/>
          </span>
          <input
            type="number"
            className='form-control'
            placeholder='Bid Amount Required'
            value={bidAmount}
            min="0"
            step="any"
            onChange={(e) => this.handleBidInput(_.toNumber(e.target.value))}
          />
        </div>
        {this.props.pre_payment > 0 &&
          <div className='round-payment'>
            + ${this.props.pre_payment}
            <span className='round-payment-label'>Pre payments</span>
          </div>
        }
        {this.props.additional_payment > 0 &&
          <div className='round-payment'>
            + ${this.props.additional_payment}
            <span className='round-payment-label'>Additional payments</span>
          </div>
        }
        <hr />
        <div className='total-round-payment'>
          ${totalPayment}
          <span className='total-round-payment-label'>Total round payment</span>
        </div>
      </div>
      );
  }
  static mapStateToProps(state, myProps) {
    return {
      id: myProps.id,
      user_id: state.shopApplication.user_id,
      round_bids: state.round_bids,
      pre_payment: myProps.pre_payment,
      additional_payment: myProps.additional_payment,
      canSubmitCheck: myProps.canSubmitCheck
    };
  };

  static mapDispatchToProps(dispatch) {
    return {
      updateBid: (round_id, user_id, amount) => dispatch({ type: 'UPDATE_ROUND_BID', value: { id: round_id, user_id: user_id, amount: amount } }),
    };
  }
};


ShoppertunitiesPaymentCalculator.displayName = 'Shoppertunities.PaymentCalculator';

export default connect(
  ShoppertunitiesPaymentCalculator.mapStateToProps,
  ShoppertunitiesPaymentCalculator.mapDispatchToProps
)(ShoppertunitiesPaymentCalculator);

