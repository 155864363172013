import React from 'react';
import { connect } from 'react-redux';
import { Remote } from '../utils';

import SkeletonCard from '../skeleton/Card';
import ProgressBar from '../ProgressBar';
import Alert from '../Alert'
import ProfilesPublicInfo from './PublicInfo'
import ProfilesSocialAccounts from './SocialAccounts'
import ProfilesBlogs from './Blogs'
import ProfilesInfluencerDetails from './InfluencerDetails'


class ProfilesShow extends React.Component {
  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.fetchProfile = this.fetchProfile.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.propExists = this.propExists.bind(this);
    this.render = this.render.bind(this);
    this.fetchInternalProfile = this.fetchInternalProfile.bind(this);
  }

  componentDidMount() {
    this.fetchProfile()

    this.props.setMeta({
      gaAuthUrl: this.props.gaAuthUrl
    });
  }

  fetchProfile() {
    const params = $.param({
      api_action: `profile/${this.props.username}`
    });

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'GET',

    }).then((response) => {
      this.props.initializeData({
        profile: response.data.profile,
        blogs: response.data.blogs,
        socialAccounts: response.data.social_accounts,
        authConnectUrls: response.data.auth_connect_urls,
        deauthUrls: response.data.deauth_urls
      });
      this.fetchInternalProfile();
      this.props.toggleFetching();
    }).catch((error) => {
      this.props.toggleFetching();
      this.props.setErrorMessage('Failed to load profile. Please try again.');
    });
  }

  fetchInternalProfile() {
    if (!this.props.load_internal_profile)
      return false;

    const params = $.param({
      api_action: `profile/${this.props.username}/internal`
    });
    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'GET',

    }).then((response) => {
      this.props.setInternalProfile(response.data);
    }).catch((error) => {
      this.props.setInternalProfile({error: JSON.stringify(error.response.data)});
    });
  }

  closeAlert() {
    this.props.setErrorMessage('');
  }

  propExists(prop) {
    return this.props[prop] && this.props[prop].length > 0
  }

  render() {
    const {
      fetching, profile, masquerade, errorMessage, edit, socialAccounts,
      authConnectUrls, deauthUrls, authRedirectUrl, advertiserAuth, blogs,
      internal_profile, load_internal_profile, completed_shops_path,
      analyticsTooltip, gaStatusMessages, showGAStatus
    } = this.props;

    if (fetching) {
      return <ProgressBar />;
    } else {
      return (
        <div className='container main-container profile-show-container'>

          { this.propExists('errorMessage') && <Alert type='error fixed-top' message={errorMessage} callback={this.closeAlert}/>}

          <ProfilesPublicInfo profile={profile} />

          <div className='button-container'>
            { this.propExists('masquerade') && <a className='btn secondary' href={masquerade}>Masquerade</a> }
            { this.propExists('edit') && <a className='btn primary' href={edit} >Edit Profile</a> }
          </div>

          <div className='profile-cards-container'>
            { this.propExists('socialAccounts') && <ProfilesSocialAccounts accounts={socialAccounts}
                                                                            authConnectUrls={authConnectUrls}
                                                                            deauthUrls={deauthUrls}
                                                                            authRedirectUrl={authRedirectUrl}
                                                                            advertiserAuth={advertiserAuth}
                                                                            username={this.props.username}/> }
            { this.propExists('blogs') &&
              <ProfilesBlogs blogs={blogs}
                             analyticsTooltip={analyticsTooltip}
                             showGAStatus={showGAStatus}
                             gaStatusMessages={gaStatusMessages}/> }
          </div>

          { !!load_internal_profile && !internal_profile && <SkeletonCard/> }
          { !!internal_profile && <ProfilesInfluencerDetails profile={internal_profile} completed_shops_path={completed_shops_path} />}
        </div>
      );
    }
  }

  static mapStateToProps(state, myProps) {
    return {
      ...state,
      ...myProps,
    };
  };

  static mapDispatchToProps(dispatch) {
    return {
      toggleLoading: () => dispatch({ type: 'TOGGLE_LOADING' }),
      toggleFetching: () => dispatch({ type: 'TOGGLE_FETCHING' }),
      initializeData: data => dispatch({ type: 'INITIALIZE_DATA', value: data }),
      setErrorMessage: errorMessage => dispatch({ type: 'SET_ERROR_MESSAGE', value: errorMessage}),
      setInternalProfile: (data) => {
        dispatch({type: 'DELETE_VALUE', value: 'load_internal_profile' });
        dispatch({type: 'SET_VALUE', value: { internal_profile: data }});
      },
      setMeta: meta => dispatch({ type: 'SET_META', value: meta })    };
  };
};

ProfilesShow.displayName = 'Profiles.Show';
export default connect(
  ProfilesShow.mapStateToProps,
  ProfilesShow.mapDispatchToProps
)(ProfilesShow);

