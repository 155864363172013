import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash'
import { Remote } from '../../utils';
import Upload from '../../Upload'
import ContentsDraftComment from '../../contents/DraftComment'
import SubmissionDraftContentAttachment from '../DraftContentAttachment'

class SubmissionTiktokDraftContent extends React.Component {
  constructor(props) {
    super(props);

    this.debouncedValidate = _.debounce(this.validate, 500);
    this.debouncedAttachment = _.debounce(this.updateAttachment, 500);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.render = this.render.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.updateCaption = this.updateCaption.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
    this.addAttachment = this.addAttachment.bind(this);
    this.tiktokDraftComments = this.tiktokDraftComments.bind(this);
  }

  componentDidMount() {
    this.props.setAttachment(this.props.bloggerShopTask.tiktok_attachment);
    this.debouncedAttachment();
  }

  updateCaption(e) {
    this.props.updateSubmission({ description2: e.target.value });
    this.debouncedValidate();
  }

  updateAttachment() {
    let id = this.props.tiktokAttachment ? this.props.tiktokAttachment.id : undefined;
    this.props.updateSubmission({ tiktok_attachment_id: id });
    this.debouncedValidate();
  }

  validate() {
    const params = $.param({
      api_action: `/blogger_shop_tasks/${this.props.bloggerShopTask.id}/validate`
    });

    Remote().request({
      method: 'POST',
      url: this.props.proxyUrl + '?' + params,
      data: {
        type: this.props.contentType.name,
        user_id: this.props.userId,
        description2: this.props.description2
      }
    }).then((response) => {
      this.props.setValidations(response.data);
    });
  }

  tiktokDraftComments(){
    if(this.props.bloggerShopTask.content && this.props.bloggerShopTask.campaign.draft_dashboard_display === 'draft_review'){
      let comments = _.orderBy(this.props.bloggerShopTask.content.tiktok_draft_comments, (value) => new Date(value.created_at), ['desc']);
      return <div>
        <h4>Draft Comment History</h4>
        { this.props.bloggerShopTask.content.tiktok_draft_comments && comments.map((comment) =>
          <ContentsDraftComment key={comment.id} comment={comment}/>)
        }
      </div>
    }
    return null;
  }

  renderForm(){
    const { tiktokAttachment } = this.props;
    const addMediaImagePath = `/contents/create_attachment`

    return (
      <div className='submission draft'>
          {
            !tiktokAttachment && <div style={{paddingTop: '50px', paddingBottom: '50px'}}>
              <div className="text-center">Add media files to get started.</div>
              <div className="text-center">Accepted formats include: .mov and .mp4</div>
          </div>
          }

          {!this.props.readOnly && !tiktokAttachment && <div style={{paddingBottom: '10px'}}>
              <div>
                  <Upload uploadPath={addMediaImagePath}
                              fileParam={"tiktok_attachment"}
                              params={{ taskId: this.props.bloggerShopTask.id, type: 'tiktok_attachment'}}
                              fileTypes={'.mov, .mp4'}
                              callback={this.addAttachment}/>
              </div>
          </div>}

          <div className='form-group'>
              <label>Post caption:</label>
              <textarea className="form-control" disabled={this.props.readOnly} value={this.props.description2 || ''} onChange={this.updateCaption} style={{width: '100%', height: '150px'}}></textarea>
          </div>

          {this.tiktokDraftComments()}
      </div>
    );
  }

  render() {
    const { tiktokAttachment, contentType, loading } = this.props;
    let type = () =>  {
      switch(contentType.name) {
      case 'TiktokDraftContent':
        return 'tiktok';
      default:
        return '';
      }
    }

    if (loading) {
      return null;
    } else if(tiktokAttachment != null) {
      return (
        <div>
          <div className={`submission content-grid content-count-1 ${type()}`}>
            { <SubmissionDraftContentAttachment key={0} index={0} attachment={tiktokAttachment} deleteAttachment={this.deleteAttachment} readOnly={this.props.readOnly}/> }
          </div>
          {this.renderForm()}
        </div>
      )
    } else {
      return (
        this.renderForm()
      )
    }
  }

  addAttachment(attachment) {
    this.props.addTiktokAttachment(attachment);
    this.debouncedAttachment();
  }

  deleteAttachment(attachment) {
    const deleteMediaImagePath = `/contents/destroy_attachment?attachment_id=${attachment.id}`

    Remote().request({
      method: 'POST',
      url: deleteMediaImagePath
    }).then((response) => {
      this.props.deleteAttachment(response.data);
      this.debouncedAttachment();
    });
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      userId: state.meta.userId,
      bloggerShopTask: state.bloggerShopTask,
      contentType: state.contentType,
      proxyUrl: state.meta.proxyUrl,
      tiktokAttachment: state.tiktokAttachment,
      description2: state.submission.description2,
      readOnly: state.readOnly,
      loading: state.loading
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      addTiktokAttachment: attachment => dispatch({ type: 'ADD_TIKTOK_ATTACHMENT', value: attachment}),
      deleteAttachment: () => dispatch({ type: 'DELETE_TIKTOK_ATTACHMENT'}),
      setAttachment: attachment => dispatch({ type: 'SET_TIKTOK_ATTACHMENT', value: attachment}),
      setValidations: validations => dispatch({ type: 'SET_VALIDATIONS', value: validations }),
      updateSubmission: change => dispatch({ type: 'UPDATE_SUBMISSION', value: change}),
      setLoading: loading => dispatch({ type: 'SET_LOADING', value: loading })
    };
  }
};

SubmissionTiktokDraftContent.displayName = 'Submission.TiktokDraftContent';
export default connect(
    SubmissionTiktokDraftContent.mapStateToProps,
    SubmissionTiktokDraftContent.mapDispatchToProps
)(SubmissionTiktokDraftContent);
