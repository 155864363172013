class window.Notifications

  @index: ->
    url = get_url()
    if url
      $('body').on 'click', '.js-show-more-notifs', ->
        url = get_url()
        load_more(url, $(this))
    else
      $('.js-load-more-section').remove()

    notification_utils.destroy_all_notifications()
    notification_utils.mark_all_read()
    notification_utils.destroy_notification()

  load_more = (url, scope) ->
    $('.js-load-more-section .fas').addClass('fa-spin')
    $('.js-loading-text').text('Loading')
    $('.main-container .pagination').remove()

    $.ajax
      url: url
      type: 'GET'
      success: ->
        scope.parent().remove()
  get_url = () ->
    return $('.main-container .pagination .next_page a').attr('href')

