import React from 'react';

export default class AuxillaryContentsFacebookVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props
    }
    this.save = this.save.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.url && this.props.image_url !== nextProps.image_url) {
      this.getBlobImage(nextProps.image_url);
      this.setState({...nextProps});
    }
  }

  componentWillMount() {
    this.getBlobImage(this.props.image_url)
  }

  getBlobImage(url) {
    this.props.getBlob(url);
  }
  saveVal(type, value) {
    if (!!value && !isNaN(parseInt(value))) {
      this.setState({ [type]: parseInt(value) })
    }
  }

  save() {
    const {likes, description, title, image_url, comments, views, shares} = this.state;
    const {setValue, onSave} = this.props;
    setValue('contentMeta', {likes, description, title, image_url, comments, views, shares}, onSave);
  }
  render() {
    const { blobImage } = this.props;
    const {
      description, title, likes, image_url, views,
      comments, flexStyles, shares, hightlightHashTags, url
    } = this.state;
    const highlightedCaption = hightlightHashTags(description);
    const blob = !!blobImage ? `data:image/jpg;base64,${this.state.blobImage}` : image_url;
    const flexEven = flexStyles('space-evenly');
    const imageStyles = {
      width: '100%',
      height: 'auto',
      margin: 'auto 0px',
      padding: '5px',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)'
    };
    const flexCenter = flexStyles();
    return (
      <div style={{ width: '100%', paddingBottom: 15, boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)' }}>
        <div className="container-fluid">
          <div className="row" style={{...flexCenter, padding: 5}}>
            <div className="col-md-12">
              <h3>Video Data</h3>
            </div>
            <div className="col-md-3">
              <a href={url} target="_blank">
                <img src={blob} alt="Facebook video thumbnail" style={imageStyles} />
              </a>
            </div>
            {/* col-md-3 */}
            <div className="col-md-9">
              <div style={{ ...flexEven, fontSize: '24px' }}>
                <span title="Likes">{likes} <i className="fas fa-thumbs-up"></i></span>
                <span title="Comments">{comments} <i className="fas fa-comments"></i></span>
                <span title="Shares">{shares} <i className="fas fa-share"></i></span>
              </div>
              <hr/>
              <p style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                <b>Title:</b>{title}
              </p>
              <p style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                <b>Caption:</b><span dangerouslySetInnerHTML={{ __html: highlightedCaption }}></span>
              </p>
              <div className="form-group">
                <label htmlFor="views">Views</label>
                <input value={views || 0} onChange={(event) => {this.saveVal('views', event.target.value)}} type="number" className="form-control" />
              </div>
            </div>
            {/* col-md-9 */}
            <div className="col-md-12 text-right">
              <hr />
              <button className="btn btn-primary" onClick={this.save}>Save</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

AuxillaryContentsFacebookVideo.displayName = 'AuxillaryContent.FacebookVideo';