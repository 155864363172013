import React from 'react';
import { Remote } from './utils';
import Alert from './Alert'

export default class SystemAlerts extends React.Component {
  constructor(props) {
    super(props);

    this.state ={
      ...props
    };

    this.onCallback = this.onCallback.bind(this);
    this.render = this.render.bind(this);
  }

  onCallback(id) {
    const { alerts } = this.state;
    Remote().request({
      url: `/alerts/${id}/read`,
      method: 'POST',
    })
    this.setState({
      alerts: alerts.filter( (a) => a.id !== id )
    })
  }

  render() {
    const { alerts } = this.state;
    return(
      <div className='container'>
        { alerts && alerts.map( ({id, severity, message}) => {
          return <Alert key={id} type={severity} message={message} callback={() => this.onCallback(id)} />
        } )}
      </div>
    )
  }
}
SystemAlerts.displayName='SystemAlerts'