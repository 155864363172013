import React from 'react';

export default class FormValidations {
  constructor(validates) {
    this.validates = validates;
    this.isValid = this.isValid.bind(this);
    this.form = null;
    this.doBind();
  }

  renderValidations(field) {
    const wordErrors = this.validateWordLength(field);
    const textErrors = this.validateTextLength(field);
    const characterErrors = this.validateCharacters(field);

    return (
      <div className='validation-area'>
        {!!wordErrors.length && this.renderErrorList('Words', wordErrors)}
        {!!textErrors.length && this.renderErrorList('Text Length', textErrors)}
        {!!characterErrors.length && this.renderErrorList('Characters', characterErrors)}
      </div>
    );
  }

  renderErrorList(type, errors) {
    if(errors.length == 0) {
      return;
    }

    return (
      <div>
        {
          errors.map((e) => {
            return (
              <span className='text-danger validation' key={e}>
                <strong>{type}:</strong> {e}
              </span>
            );
          })
        }
      </div>
    );
  }

  textFromField(field) {
    return this.form && this.form.state.fields[field] || "";
  }

  createUpdateListener(field) {
    return (event) => {
      const state = this.form.state.fields;
      state[field] = event.target.value;
      this.form && this.form.setState({fields: state});
    };
  }

  validateWordLength(field) {
    if (!this.validates.words) return [];

    const text = this.textFromField(field);
    const words = text.split(/\s/);
    const errors = [];

    const maxLengthMatcher = (x) => x.length > FormValidations.MAX_WORD_LENGTH;
    if(words.some(maxLengthMatcher)) {
      errors.push(FormValidations.MAX_WORD_LENGTH_VALIDATION);
    }

    const minLengthMatcher = (x) => x.length == 1;
    if(words.filter(minLengthMatcher).length > FormValidations.SHORT_WORD_COUNT_MAX) {
      errors.push(MIN_WORD_LENGTH_VALIDATION);
    }

    return errors;
  }

  validateTextLength(field) {
    if (!this.validates.textLength) return [];

    const text = this.textFromField(field);
    const errors = [];

    if(text.length < 1 && field != 'text') {
      errors.push(FormValidations.MINIUMUM_LENGTH_VALIDATION);
    }

    let fieldType = field.split('-')[0]

    var message;
    var maxLength;

    switch(fieldType) {
      case 'text':
      case 'description':
        maxLength = FormValidations.MAX_TEXT_LENGTH;
        message = FormValidations.MAX_TEXT_FIELD_LENGTH_VALIDATION;
        break;

      case 'headline':
        maxLength = FormValidations.MAX_TITLE_LENGTH;
        message = FormValidations.MAX_TITLE_FIELD_LENGTH_VALIDATION;
        break;

      default:
        break;
    }

    if(text.length > maxLength) {
      errors.push(message);
    }

    return errors;
  }

  validateCharacters(field) {
    if (!this.validates.characters) return [];

    const text = this.textFromField(field);
    const errors = [];

    const specialValidation = /[\^\~\_\=\{\}\[\]\|\<\>]/g;
    text.match(specialValidation) && errors.push(FormValidations.SPECIAL_CHARACTER_VALIDATION);

    const startValidation = /^\s?[\\\-/!.?*),:;]/g;
    text.match(startValidation) && errors.push(FormValidations.START_VALIDATION);

    return errors;
  }

  isValid(){
    if ( this.form === null || this.form.state.fields === undefined )
      return false

    const validFields = ['description', 'text', 'headline'];
    let fields = Object.keys(this.form.state.fields).filter((fieldName) => 
      validFields.includes(fieldName.split('-')[0])
    )

    return fields.every((x) =>
      !(this.validateWordLength(x).length)
      && !(this.validateTextLength(x).length)
      && !(this.validateCharacters(x).length)
    );
  }

  renderRemainingCharacters(field, max) {
    if(!this.textFromField(field)) {
      return;
    }

    return (
      <div className='text-right'>
        <span className='text-muted'>
          {max - this.textFromField(field).length} characters remaining
        </span>
      </div>
    );
  }

  doBind() {
    this.createUpdateListener = this.createUpdateListener.bind(this);
    this.renderErrorList = this.renderErrorList.bind(this);
    this.renderValidations = this.renderValidations.bind(this);
    this.renderRemainingCharacters = this.renderRemainingCharacters.bind(this);
    this.validateWordLength = this.validateWordLength.bind(this);
    this.validateTextLength = this.validateTextLength.bind(this);
    this.validateCharacters = this.validateCharacters.bind(this);
  }

};

// Messages
FormValidations.MINIUMUM_LENGTH_VALIDATION = 'Field must be at least one character long';
FormValidations.MAX_TEXT_FIELD_LENGTH_VALIDATION = 'Field has a maximum length of 90 characters';
FormValidations.MAX_TITLE_FIELD_LENGTH_VALIDATION = 'Field has a maximum length of 25 characters';
FormValidations.MAX_WORD_LENGTH_VALIDATION = 'Words can only be 30 characters maximum';
FormValidations.MIN_WORD_LENGTH_VALIDATION = 'Maximum of 3 one character words';
FormValidations.SPECIAL_CHARACTER_VALIDATION = "The symbols ^~_={}[]|<> are not allowed";
FormValidations.START_VALIDATION = 'Cannot start with the following characters - \\/-!.?*,:;';

// Length constants
FormValidations.MAX_WORD_LENGTH = 30;
FormValidations.MAX_TITLE_LENGTH = 25;
FormValidations.MAX_TEXT_LENGTH = 90;
FormValidations.SHORT_WORD_COUNT_MAX = 3;
