import React from 'react';
import { Remote } from './utils'
import BidHistory from './BidHistory'

export default class InfluencerBids extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      bidState: this.props.latest_bid.state,
      submitting: false,
      displayError: false,
      errorMessage: '',
      declineReason: ''
    }

    this.render = this.render.bind(this);
    this.onAccept = this.onAccept.bind(this);
    this.onReject = this.onReject.bind(this);
    this.updateReason = this.updateReason.bind(this);
  }

  updateReason(event){
    this.setState({declineReason: event.target.value});
  }

  onAccept() {
    this.setState({submitting: true});
    const requestUrl = `/opportunities/${this.props.app.shoppertunity_id}/accept_bid_negotiation`;

    Remote().request({
      method: "POST",
      url: requestUrl,
      data: {bid_id: this.props.latest_bid.id},
      responseType: 'json'
    }).then((response) => {
        if(response.data) {
          this.setState({submitting: false,
                         bidState: 'accepted',
                         displayError: false,
                         errorMessage: ''});
        }
      })
      .catch((error) => {
        this.setState({submitting: false,
                       displayError: true,
                       errorMessage: 'It looks like there was an error submitting your response. please try again.'});
      });
  }

  onReject() {
    this.setState({submitting: true});
    const requestUrl = `/opportunities/${this.props.app.shoppertunity_id}/reject_bid_negotiation`;

    Remote().request({
      method: "POST",
      url: requestUrl,
      data: {bid_id: this.props.latest_bid.id, reason: this.state.declineReason },
      //dataType: 'json'
    }).then((response) => {
        if(response.data) {
          this.setState({submitting: false,
                         bidState: 'influencer_declined',
                         displayError: false,
                         errorMessage: ''});
        }
      })
      .catch((error) => {
        this.setState({submitting: false,
                       displayError: true,
                       errorMessage: 'It looks like there was an error submitting your response. please try again.'});
      });
  }

  render() {
    const latestBid = this.props.latest_bid;

    let latestBidState = '';
    switch(this.state.bidState) {
      case 'accepted':
        latestBidState = 'Accepted';
        break;
      case 'influencer_declined':
        latestBidState = 'Declined';
        break;
      default:
        latestBidState = 'Pending';
        break;
    };

    let disabled = (['influencer_declined', 'accepted'].includes(this.state.bidState) || this.state.submitting) ? 'disabled' : '';


    return(
      <div className='bid_negotiations-card card-wrapper--white-bg' key={latestBid.id}>
        <div className='bid_info'>
          <h4 className='bid_info--round'>{this.props.round.name}</h4>
          <div className={`bid_info--status ${latestBidState.toLowerCase()}`}>{latestBidState}</div>
          <br />
          <div className='bid_info--due_date'>
            <label>
              Due Date:
            </label>
            &nbsp;&nbsp;
            {this.props.dueDate}
          </div>
        </div>
        <div className='bid_info--tasks'>
          <label>
            Required Tasks:
          </label>
          {
            this.props.tasks.map(task => {
              return <div className='bid_info--tasks--item' key={task}>{task}</div>
            })
          }
        </div>
        <div className='bid_info--description' >
          <p>
            {this.props.bidText}
          </p>
        </div>
        <div className='bid_info--bids' >
          <div className='bid_info--original_bid'>
            <label className='bid_info--original_bid--label' >
              Current Bid:
            </label>
            <span className='bid_info--original_bid--value'>
              ${this.state.bidState == 'accepted' ? latestBid.amount : this.props.original_amount}
            </span>
          </div>
          { this.state.bidState != 'accepted' &&
            <div className='bid_info--suggested_bid'>
              <label className='bid_info--suggested_bid--label' >
                Revised Payment:
              </label>
              <span className='bid_info--suggested_bid--value'>
                ${latestBid.amount}
              </span>
            </div>
          }
        </div>
        <BidHistory history={this.props.history} viewType={'influencer'}/>
        <div className='bid_info--notes'>
          <label>
            Notes from our team:
          </label>
          <p>
            {latestBid.note}
          </p>
        </div>
        <div className='bid_info--buttons'>
          <button
            className='btn btn-primary bid_info--buttons--accept'
            disabled={disabled}
            onClick={this.onAccept}>
            Accept Revised Payment
          </button>
          <button
            className='btn btn-default bid_info--buttons--decline'
            disabled={disabled}
            data-toggle="modal"
            data-target={`#bid_info--rejection-modal-${latestBid.id}`}>
            Decline Revised Payment
          </button>
        </div>
        <div className='bid_info--error_messaging' style={{display: this.state.displayError ? '': 'none'}}>
            <i className='fas fa-exclamation-circle fa-lg' />
            <p>{this.state.errorMessage}</p>
        </div>
        <div id={`bid_info--rejection-modal-${latestBid.id}`} className='modal fade' role='dialog'>
          <div className='modal-dialog modal-md' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                Tell us why?
                <button className="bid_info--modal--close_btn" data-dismiss="modal" aria-label="Close">
                  <i className='fas fa-times fa-lg' />
                </button>
              </div>
              <div className='modal-body'>
                <textarea rows="3" onChange={this.updateReason}/>
              </div>
              <div className="modal-footer">
                <button
                  className='btn btn-primary'
                  data-dismiss="modal"
                  disabled={this.state.declineReason == '' ? 'disabled' : ''}
                  onClick={this.onReject}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}