import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash'
class SubmissionContentTypeSelector extends React.Component {
  constructor(props) {
    super(props);
  }

  selectType(type) {
    this.props.resetContent();
    this.props.setContentType(type);
  }

  render() {
    if(this.props.contentTypes.length > 1){
      return <div className='submission content-type-selector'>
        <div className='instructions'>
          <h4>Please choose the platform on which your post was origninally submitted before moving on:</h4>
        </div>
        <div className='type-options-container'>
          <div className='type-options'>
            {this.props.contentTypes.map(type =>
              <div className='type' key={type.id} onClick={() => this.selectType(type)}>
                <div>
                  {type.display_name}
                </div>
                <div><i className='fas fa-chevron-right' /></div>
              </div>
            )}
          </div>
        </div>
      </div>;
    } else {
      return null;
    }
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      contentTypes: _.get(state.bloggerShopTask, 'round_task.content_types', []),
      contentType: state.contentType,
      readOnly: state.readOnly,
      bloggerShopTask: state.bloggerShopTask,
      content: state.bloggerShopTask.content
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      setContentType: type => dispatch({ type: 'SET_CONTENT_TYPE', value: type }),
      resetContent: () => dispatch({type: 'RESET_CONTENT'})
    }
  }
};

SubmissionContentTypeSelector.displayName = 'Submission.ContentTypeSelector';
export default connect(
  SubmissionContentTypeSelector.mapStateToProps,
  SubmissionContentTypeSelector.mapDispatchToProps
)(SubmissionContentTypeSelector);
