import React from 'react';
import PropTypes from 'prop-types'

export default class PhoneNumberInput extends React.Component {
  constructor(props) {
    super(props);

    this.asEvent = this.asEvent.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.formattedPhone = this.formattedPhone.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    try {
      const formatted = this.formattedPhone(this.props.value);

      if(this.props.value !== formatted) {
        this.props.onChange(this.asEvent(formatted));
      }
    } catch(_ignored) {}
  }

  render() {
    const {
      extraClassNames,
      placeholder,
      value
    } = this.props;

    return <input
      className={`form-control ${extraClassNames}`}
      placeholder={placeholder || ''}
      value={value || ''}
      onChange={this.onUpdate}
      />
  }

  onUpdate(e) {
    const phone = this.formattedPhone(e.target.value)
    this.props.onChange(this.asEvent(phone));
  }

  asEvent(value) {
    return {
      target: {
        value
      }
    };
  }

  formattedPhone(phone) {
    phone = String(phone);
    // Basic algorithm for formatting the phone numbers
    // 1. Pull out _just_ the digits from the number
    // 2. For each segment of the number, match and format just that portion (exactly match the available digits)
    // 3. As the length of the number grows, select the next segment of the number and format that
    // 4. Finally, catch the longest possible pattern we expect
    //
    // Note: Using \d+ to catch the last segment of the number DOES NOT WORK with #replace, so this is why we interpolate
    // the expected segment length directly into a regex.

    let digits = '';
    try {
      digits = phone.match(/\d+/g).join('');
    } catch(_ignored) {}

    // Handle no digits to prevent showing empty parens
    if(digits.length == 0) {
      return '';
    }

    // Format the area code segment and start open parentheses
    // Don't close the parens here so that the user can backspace the area code!
    if(digits.length <= 3) {
      return digits.replace(new RegExp(`.*(\\d{${digits.length}}).*`, 'gi'), '($1');
    }

    // Format the 3 digit portion directly after the area code
    if(digits.length > 3 && digits.length <= 6) {
      return digits.replace(new RegExp(`.*(\\d\\d\\d).*(\\d{${digits.length - 3}})`, 'gi'), '($1) $2');
    }

    // Format the 4 digit portion of the number (include a dash)
    if(digits.length > 6 && digits.length <= 10) {
      return digits.replace(new RegExp(`.*(\\d\\d\\d).*(\\d\\d\\d).*(\\d{${digits.length - 6}})`, 'gi'), '($1) $2-$3')
    }

    // Once we reach more than 10 characters, use the first digit as the country code and apply the above rules to the rest
    // of the number.
    return digits.replace(new RegExp(`.*(\\d).*(\\d\\d\\d).*(\\d\\d\\d).*(\\d{${digits.length - 7}})`, 'gi'), '+$1 ($2) $3-$4')
  }
};

PhoneNumberInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  extraClassNames: PropTypes.string,
  placeholder: PropTypes.string
};
