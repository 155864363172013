import React from 'react';

export default class Shoppertunity extends React.Component {
  render(){
    const {shoppertunity_name, activity, due_at} = this.props.notification;
    const P = React.Fragment;
    switch(activity){
      case 'admin_approved':
        return(
          <P><b>{shoppertunity_name}</b> has been approved and is ready to be posted.</P>
        )
      case 'admin_cancelled':
        return(
          <P><b>{shoppertunity_name}</b> has been canceled.</P>
        )
      case 'admin_closed':
        return(
          <P><b>{shoppertunity_name}</b> has been closed.</P>
        )
      case 'admin_closed_for_application':
        return(
          <P><b>{shoppertunity_name}</b> has been closed for application.</P>
        )
      case 'admin_draft':
        return(
          <P><b>{shoppertunity_name}</b> setup is complete and is now ready for you to update the posting.</P>
        )
      case 'admin_launched':
        return(
          <P><b>{shoppertunity_name}</b> has been launched.</P>
        )
      case 'admin_posted':
        return(
          <P><b>{shoppertunity_name}</b> has been posted.</P>
        )
      case 'admin_posting_ready':
        return(
          <P><b>{shoppertunity_name}</b> posting is ready to be reviewed.</P>
        )
      case 'admin_posting_rejected':
        return(
          <P><b>{shoppertunity_name}</b> posting has been rejected.</P>
        )
      case 'admin_reviewed':
        return(
          <P><b>{shoppertunity_name}</b> applicants have been reviewed and approved.</P>
        )
      case 'blogger_approved_for_shop':
        return(
          <P>
            You have been approved for the <b>{shoppertunity_name}</b> opportunity.
            <div>
              Please review and confirm your participation by accepting or declining the instructions by
              <b>Noon EST {due_at}</b>
            </div>
          </P>
        )
      case 'cancelled':
        return(
          <P><b>{shoppertunity_name}</b> has been cancelled.</P>
        )
      case 'client_approved':
        return(
          <P>
            Applicants have been marked <b>{activity}</b> for <b>{shoppertunity_name}</b>.
          </P>
        )
      case 'complete_to_closed':
        return(
          <P><b>{shoppertunity_name}</b> has been closed.</P>
        )
      case 'facebook_instagram_business_authentication_failure':
        return(
          <P>
            To make sure that you’re able to submit content to {shoppertunity_name},
            you will need to authenticate your Instagram Business Account with Social Fabric.
          </P>
        )
      case 'not_selected':
        return(
          <P>
            Your application for <b>{shoppertunity_name}</b>was not selected.
            You may be reconsidered if another slot becomes available.
          </P>
        )
      case 'planning_to_draft':
        return(
          <P><b>{shoppertunity_name}</b> has been set to draft state.</P>
        )
      case 'posted':
        return(
          <P>A new opportunity <b>{shoppertunity_name}</b> has been posted!</P>
        )
      case 'results_accepted':
        return(
          <P>
            Your content for <b>{shoppertunity_name}</b> has been accepted. Your final payment will be processed 30 days from the due date.
          </P>
        )
      default:
        return(
          <P>Opportunity {shoppertunity_name} has made an update: <b>{activity}</b></P>
        )
    }
  }

}
Shoppertunity.displayName = 'Notification.Templates.Shopportunity';