import React from 'react';

export default class ShopApplication extends React.Component {
  render(){
    const {user_name, activity, shoppertunity_name} = this.props.notification;
    return (
      <React.Fragment>
        <b>{user_name}</b> has been marked <b>{activity}</b> for <b>{shoppertunity_name}</b> by the client.
      </React.Fragment>
    )
  }
}
ShopApplication.displayName= 'Notification.Templates.ShopApplication'