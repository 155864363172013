`import notif from 'javascripts/notifIt.js'`

class window.AdminDataPoints

  @index: ->
    $('body').on 'click', '#calc_blog_scores', (e) ->
      e.preventDefault()
      AdminDataPoints.spinner $(this)
      AdminDataPoints.perform_blog_score_worker $(this)

    $('body').on 'click', '.formula-toggle', ->
      name = $(this).data('name')
      $('.tab-pane.formula').hide()
      $("##{name}_form").fadeIn()

    $('body').on 'ajax:complete', 'form', (e, xhr) ->
      $('#ui_notifIt').remove()
      if xhr.responseJSON.status == 200
        t = 'info'
        id = $(this).data('id')
        formUrl = Routes.edit_admin_analytic_path( id )
        $(this).parent().load(formUrl)
      else if xhr.responseJSON.status == 422
        t = 'error'

      notif(
        msg: xhr.responseJSON.message
        type: t
        position: 'right'
        autohide: true
        timeout: 3000
        top: 120
      )

  @spinner: (e) ->
    icon = $('<i class="fas fa-spinner fa-spin"></i>')
    e.addClass('disabled').html(icon)

  @perform_blog_score_worker: (e) ->
    $.ajax
      url: e.data('url')
    .done ->
      alert 'Scores have been updated!'
    .fail ->
      alert 'Something went wrong!'
    .always ->
      location.reload()
