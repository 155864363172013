/*!
 RowReorder 1.1.2
 2015-2016 SpryMedia Ltd - datatables.net/license
*/
(function(e){"function"===typeof define&&define.amd?define(["jquery","datatables.net"],function(f){return e(f,window,document)}):"object"===typeof exports?module.exports=function(f,g){f||(f=window);if(!g||!g.fn.dataTable)g=require("datatables.net")(f,g).$;return e(g,f,f.document)}:e(jQuery,window,document)})(function(e,f,g){var j=e.fn.dataTable,i=function(b,a){if(!j.versionCheck||!j.versionCheck("1.10.8"))throw"DataTables RowReorder requires DataTables 1.10.8 or newer";this.c=e.extend(!0,{},j.defaults.rowReorder,
i.defaults,a);this.s={bodyTop:null,dt:new j.Api(b),getDataFn:j.ext.oApi._fnGetObjectDataFn(this.c.dataSrc),middles:null,scroll:{},scrollInterval:null,setDataFn:j.ext.oApi._fnSetObjectDataFn(this.c.dataSrc),start:{top:0,left:0,offsetTop:0,offsetLeft:0,nodes:[]},windowHeight:0};this.dom={clone:null,dtScroll:e("div.dataTables_scrollBody",this.s.dt.table().container())};var c=this.s.dt.settings()[0],d=c.rowreorder;if(d)return d;c.rowreorder=this;this._constructor()};e.extend(i.prototype,{_constructor:function(){var b=
this,a=this.s.dt,c=e(a.table().node());"static"===c.css("position")&&c.css("position","relative");e(a.table().container()).on("mousedown.rowReorder touchstart.rowReorder",this.c.selector,function(d){var c=e(this).closest("tr");if(a.row(c).any())return b._mouseDown(d,c),!1});a.on("destroy.rowReorder",function(){e(a.table().container()).off(".rowReorder");a.off(".rowReorder")})},_cachePositions:function(){var b=this.s.dt,a=e(b.table().node()).find("thead").outerHeight(),c=e.unique(b.rows({page:"current"}).nodes().toArray()),
d=e.map(c,function(b){return e(b).position().top-a}),c=e.map(d,function(a,c){return d.length<c-1?(a+d[c+1])/2:(a+a+e(b.row(":last-child").node()).outerHeight())/2});this.s.middles=c;this.s.bodyTop=e(b.table().body()).offset().top;this.s.windowHeight=e(f).height()},_clone:function(b){var a=e(this.s.dt.table().node().cloneNode(!1)).addClass("dt-rowReorder-float").append("<tbody/>").append(b.clone(!1)),c=b.outerWidth(),d=b.outerHeight(),h=b.children().map(function(){return e(this).width()});a.width(c).height(d).find("tr").children().each(function(a){this.style.width=
h[a]+"px"});a.appendTo("body");this.dom.clone=a},_clonePosition:function(b){var a=this.s.start,c=this._eventToPage(b,"Y")-a.top,b=this._eventToPage(b,"X")-a.left,d=this.c.snapX;this.dom.clone.css({top:c+a.offsetTop,left:!0===d?a.offsetLeft:"number"===typeof d?a.offsetLeft+d:b+a.offsetLeft})},_emitEvent:function(b,a){this.s.dt.iterator("table",function(c){e(c.nTable).triggerHandler(b+".dt",a)})},_eventToPage:function(b,a){return-1!==b.type.indexOf("touch")?b.originalEvent.touches[0]["page"+a]:b["page"+
a]},_mouseDown:function(b,a){var c=this,d=this.s.dt,h=this.s.start,k=a.offset();h.top=this._eventToPage(b,"Y");h.left=this._eventToPage(b,"X");h.offsetTop=k.top;h.offsetLeft=k.left;h.nodes=e.unique(d.rows({page:"current"}).nodes().toArray());this._cachePositions();this._clone(a);this._clonePosition(b);this.dom.target=a;a.addClass("dt-rowReorder-moving");e(g).on("mouseup.rowReorder touchend.rowReorder",function(a){c._mouseUp(a)}).on("mousemove.rowReorder touchmove.rowReorder",function(a){c._mouseMove(a)});
e(f).width()===e(g).width()&&e(g.body).addClass("dt-rowReorder-noOverflow");d=this.dom.dtScroll;this.s.scroll={windowHeight:e(f).height(),windowWidth:e(f).width(),dtTop:d.length?d.offset().top:null,dtLeft:d.length?d.offset().left:null,dtHeight:d.length?d.outerHeight():null,dtWidth:d.length?d.outerWidth():null}},_mouseMove:function(b){this._clonePosition(b);for(var a=this._eventToPage(b,"Y")-this.s.bodyTop,c=this.s.middles,d=null,h=this.s.dt,k=h.table().body(),g=0,f=c.length;g<f;g++)if(a<c[g]){d=g;
break}null===d&&(d=c.length);if(null===this.s.lastInsert||this.s.lastInsert!==d)0===d?this.dom.target.prependTo(k):(a=e.unique(h.rows({page:"current"}).nodes().toArray()),d>this.s.lastInsert?this.dom.target.insertAfter(a[d-1]):this.dom.target.insertBefore(a[d])),this._cachePositions(),this.s.lastInsert=d;this._shiftScroll(b)},_mouseUp:function(){var b=this.s.dt,a,c,d=this.c.dataSrc;this.dom.clone.remove();this.dom.clone=null;this.dom.target.removeClass("dt-rowReorder-moving");e(g).off(".rowReorder");
e(g.body).removeClass("dt-rowReorder-noOverflow");clearInterval(this.s.scrollInterval);this.s.scrollInterval=null;var h=this.s.start.nodes,k=e.unique(b.rows({page:"current"}).nodes().toArray()),f={},l=[],i=[],j=this.s.getDataFn,o=this.s.setDataFn;a=0;for(c=h.length;a<c;a++)if(h[a]!==k[a]){var m=b.row(k[a]).id(),p=b.row(k[a]).data(),n=b.row(h[a]).data();m&&(f[m]=j(n));l.push({node:k[a],oldData:j(p),newData:j(n),newPosition:a,oldPosition:e.inArray(k[a],h)});i.push(k[a])}h=[l,{dataSrc:d,nodes:i,values:f,
triggerRow:b.row(this.dom.target)}];this._emitEvent("row-reorder",h);this.c.editor&&this.c.editor.edit(i,!1,{submit:"changed"}).multiSet(d,f).submit();if(this.c.update){a=0;for(c=l.length;a<c;a++)f=b.row(l[a].node).data(),o(f,l[a].newData),b.columns().every(function(){this.dataSrc()===d&&b.cell(l[a].node,this.index()).invalidate("data")});this._emitEvent("row-reordered",h);b.draw(!1)}},_shiftScroll:function(b){var a=this,c=this.s.scroll,d=!1,e=b.pageY-g.body.scrollTop,f,i;65>e?f=-5:e>c.windowHeight-
65&&(f=5);null!==c.dtTop&&b.pageY<c.dtTop+65?i=-5:null!==c.dtTop&&b.pageY>c.dtTop+c.dtHeight-65&&(i=5);f||i?(c.windowVert=f,c.dtVert=i,d=!0):this.s.scrollInterval&&(clearInterval(this.s.scrollInterval),this.s.scrollInterval=null);!this.s.scrollInterval&&d&&(this.s.scrollInterval=setInterval(function(){if(c.windowVert)g.body.scrollTop=g.body.scrollTop+c.windowVert;if(c.dtVert){var b=a.dom.dtScroll[0];if(c.dtVert)b.scrollTop=b.scrollTop+c.dtVert}},20))}});i.defaults={dataSrc:0,editor:null,selector:"td:first-child",
snapX:!1,update:!0};i.version="1.1.2";e.fn.dataTable.RowReorder=i;e.fn.DataTable.RowReorder=i;e(g).on("init.dt.dtr",function(b,a){if("dt"===b.namespace){var c=a.oInit.rowReorder,d=j.defaults.rowReorder;if(c||d)d=e.extend({},c,d),!1!==c&&new i(a,d)}});return i});