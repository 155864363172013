import React from 'react';
import PropTypes from 'prop-types'
import NewBadge from './NewBadge.jsx';
import NewBadgeHelper from './NewBadgeHelper.jsx';
import defaultImage from '../images/fallback/default_group.png'
import { Remote } from './utils';

export default class ShopCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showLink: false
    }

    this.render = this.render.bind(this)
    this.handleLinkToggle = this.handleLinkToggle.bind(this);
    this.hideShopFromFeed = this.hideShopFromFeed.bind(this);
  }

  hideShopFromFeed(hidden_status){
    const shoppertunityId = this.props.shop.id;
    const url = `/opportunities/${shoppertunityId}/hide`
    Remote().request({
        url:  url,
        data: {hide: hidden_status},
        method: 'POST',

    }).then(function(response){
        window.location.href = '/shoppertunities';
    }).catch(function(error) {
        console.log("Something went wrong.")
    });
  }

  shopMatchText() {
    if(_.isUndefined(this.props.index)) {
      return null;
    }
    return this.props.index <= (ShopCard.TOP_MATCH_QTY - 1) ? 'Top Match' : null
  }

  handleLinkToggle() {
    if(this.props.selectedPhase == 'open'){
      this.setState({ showLink: !this.state.showLink})
    }
  }

  render() {
    var shop               = this.props.shop;
    var shopLink           = shop.phase_info['link'];
    var imgURL             = shop.image;
    var shopImageStyle     = { backgroundImage: `url(${imgURL || defaultImage})` }
    var shopName           = shop.name;
    var platformType       = shop.platform.toLowerCase();
    var phase              = this.props.selectedPhase;
    var shopPayment        = shop.open_bid ? (shop.user_bids || 'Open Bid') : shop.payment_range;
    var showNewBadge       = this.props.show_new_badge === true && NewBadgeHelper.showBadge(shop,phase) === true;
    var shopMatchText      = this.shopMatchText();
    var newBadge

    if(shopMatchText || showNewBadge){
      newBadge = <NewBadge text={shopMatchText}/>
    }

    return (
      <div className="shop-card-component shop-card-column">
        <div className='card-plus clickable shop-card' onMouseLeave={this.handleLinkToggle} onMouseEnter={this.handleLinkToggle}>
          <a onClick={() => this.hideShopFromFeed(true)} className={`card-hide-link ${this.state.showLink ? "show" : "hide"}`}>
            <i className="fa fa-solid fa-ban"></i>
          </a>
          <a href={shopLink}>
            {newBadge}
            <div className="card-body" href={shopLink} style={shopImageStyle}></div>
            <div className='card-footer'>
              <div className="shop-name" title={shopName}>
                {shopName}
              </div>
              <div className="shop-platform">
                <i className={"card-platform-icon social-icon--" + platformType} title={platformType + ' opportunity'}></i>
                <span className="shop-payment">{shopPayment}</span>
              </div>
            </div>
          </a>
        </div>
      </div>
    );
  }
}

ShopCard.TOP_MATCH_QTY = 6
ShopCard.propTypes = {
  index: PropTypes.number,
  selectedPhase: PropTypes.string.isRequired,
  show_new_badge: PropTypes.bool.isRequired,
  shop: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    phase_info: PropTypes.any.isRequired,
    image: PropTypes.string.isRequired,
    platform: PropTypes.string.isRequired,
    open_bid: PropTypes.bool.isRequired,
    user_bids: PropTypes.any,
    payment_range: PropTypes.string
  }).isRequired
};
