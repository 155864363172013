import { createStore } from 'redux';
import {get} from 'lodash'

const initialState = {
  loading: false,
  fetching: true,
  profile: {},
  paymentSettings: {}, // Payment settings are handled separately
  paymentsOpen: false,
  blogs: [],
  deletedBlogIds: [],
  authModal: false,
  authType: '',
  socialAccounts: [],
  authConnectUrls: {},
  deauthUrls: {},
  activeTab: '',
  alert: {
    show: false,
    message: '',
    type: 'error'
  },
  frontendValidations: {},
  meta: {
    proxyUrl: '',
    username: '',
    i18n: {},
    accountSettingsUrl: '',
    profileUrl: ''
  },
  countries: [],
  states: [],
  genders: [],
  marital_statuses: [],
  military_affiliations: [],
  ethnicities: [],
  educations: [],
  incomes: [],
  pets: [],
  professions: [],
  devices: [],
  categories: [],
  retailers: [],
  bloggingPlatforms: [],
  languages: [],
  saveChangesBtn: false
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
  case 'TOGGLE_LOADING':
    return {
      ...state,
      loading: !state.loading
    };
  case 'TOGGLE_FETCHING':
    return {
      ...state,
      fetching: !state.fetching
    };
  case 'INITIALIZE_DATA':
    return {
      ...state,
      ...action.value,
      paymentSettings: {
        ...action.value.profile || state.profile,
        vendor_name: _.get(action.value, 'profile.oracle_legal_name') || get(state, 'profile.oracle_legal_name'),
        payment_notification: true
      }
    };
  case 'SET_ERROR_MESSAGE':
    return {
      ...state,
      error: action.value.length > 0,
      errorMessage: action.value
    };
  case 'SET_ACTIVE_TAB':
    return {
      ...state,
      activeTab: action.value
    };
  case 'UPDATE_ALERT':
    return {
      ...state,
      alert: {
        ...state.alert,
        ...action.value
      }
    };
  case 'ADD_BLOG':
    return {
      ...state,
      blogs: state.blogs.concat(action.value)
    };
  case 'REMOVE_BLOG':
    {
      let nextBlogs = _.cloneDeep(state.blogs);
      let deletedBlog = nextBlogs.splice(action.value, 1)[0]
      let deletedBlogIds = deletedBlog.id ? state.deletedBlogIds.concat(deletedBlog.id) : state.deletedBlogIds
      return {
        ...state,
        blogs: nextBlogs,
        deletedBlogIds: deletedBlogIds
      };
    };
  case 'UPDATE_FRONTEND_VALIDATIONS':
    return {
      ...state,
      frontendValidations: {
        ...state.frontendValidations,
        [state.activeTab]: {
          ..._.get(state.frontendValidations, state.activeTab, {}),
          ...action.value
        }
      }
    };
  case 'UPDATE_PROFILE':
    return {
      ...state,
      profile: {
        ...state.profile,
        [action.value.field]: action.value.value
      }
    };
  case 'UPDATE_CHILD':
    {
      const { field, value, index } = action.value;
      let nextChildren = _.cloneDeep(state.profile.children);
      _.set(nextChildren[index], field, value);

      return {
        ...state,
        profile: {
          ...state.profile,
          children: nextChildren
        }
      }
    }
  case 'ADD_CHILD':
    return {
      ...state,
      profile: {
        ...state.profile,
        children: [
          ...state.profile.children,
          {
            age: 0,
            gender: ""
          }
        ]
      }
    }
  case 'REMOVE_CHILD':
    {
      let nextChildren = _.cloneDeep(state.profile.children);
      nextChildren.splice(action.value.index, 1)

      return {
        ...state,
        profile: {
          ...state.profile,
          children: nextChildren
        }
      }
    }
  case 'UPDATE_BLOG_FIELD':
    let blogs = _.cloneDeep(state.blogs);
    let blog = blogs.find((blog, index) => index == action.value.index);
    blog[action.value.field] = action.value.value;
    return{
      ...state,
      blogs: blogs
    }
  case 'SET_META':
    return {
      ...state,
      meta: action.value
    };
  case 'SET_COUNTRIES':
    return {
      ...state,
      countries: action.value
    };
  case 'SET_STATES':
    return {
      ...state,
      states: action.value
    };
  case 'RESET_SOCIAL_ACCOUNT':
    let accounts = _.cloneDeep(state.socialAccounts);
    let connectedAccounts = _.reject(accounts, function(account) {
      return account.provider == action.value;
    });
    return {
      ...state,
      socialAccounts: connectedAccounts
    };
  case 'SET_AUTH_CONNECTION':
    let auths = _.cloneDeep(state.socialAccounts);
    const auth = auths?.find((auth) => auth.provider == action.value.platform);

    // If the auth was never in the list, stub it out and add it to the list
    if(!auth) {
      auths = [
        ...auths,
        {
          provider: action.value.platform,
          connected: action.value.connected
        }
      ];
    } else {
      // It was already in the list, so just update the record
      auth.connected = action.value.connected;
    }

    return {
      ...state,
      socialAccounts: auths
    };
  case 'UPDATE_PAYMENT_SETTINGS':
    return {
      ...state,
      paymentSettings: {
        ...state.paymentSettings,
        ...action.value
      }
    }
  case 'SET_BLOG_OPTIONS':
    return {
      ...state,
      languages: action.value.languages,
      bloggingPlatforms: action.value.blogging_platforms,
      categories: action.value.categories
    };
  case 'SET_BLOG_GA_AUTH_CONNECTION':
    let blogPlatforms = _.cloneDeep(state.blogs);
    const blogPlatform = blogPlatforms.find((blog) => blog.id == action.value.blog_id);
    blogPlatform.ga_connected = action.value.ga_connected
    blogPlatform.ga_measurement_id = action.value.measurement_id

    return {
      ...state,
      blogs: blogPlatforms
    };
  case 'UPDATE_META':
    return {
      ...state,
      meta: {
        ...state.meta,
        ...action.value
      }
    };
  case 'UPDATE_BLOGS':
    return {
      ...state,
      blogs: action.value
    };
  case 'TOGGLE_SAVE_CHANGES':
    return {
      ...state,
      saveChangesBtn: !state.saveChangesBtn
    };
  case 'OPEN_AUTH_MODAL':
    return {
      ...state,
      authModal: true,
      authType: action.value
    };
  case 'CLOSE_AUTH_MODAL':
    return {
      ...state,
      authModal: false
    };
  case 'TOGGLE_PAYMENTS':
    return {
      ...state,
      paymentsOpen: !state.paymentsOpen
    };
  case 'SET_VALUE':
    let key = Object.keys(action.value)[0]
    return {
      ...state,
      [key]: {
        ...state[key],
        ...action.value[key]
      }
    };
  case 'DELETE_VALUE':
    return {
      ...(_.omit(state, action.value))
    }
  case 'UPDATE_BLOGS':
    return {
      ...state,
      blogs: action.value
    };
  case 'TOGGLE_SAVE_CHANGES':
    return {
      ...state,
      saveChangesBtn: !state.saveChangesBtn
    };
  default:
    return state;
  };
};

const ProfileStore = createStore(
  reducer,
  window && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ name: 'Redux.ProfileStore' }) // Enable redux devtools middleware
);

export default ProfileStore;