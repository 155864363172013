import React from 'react';
import { connect } from 'react-redux';
import notif from "javascripts/notifIt.js"
import { Remote } from '../utils';
import moment from 'moment';

import MonetaryValue from '../MonetaryValue'
import HumanValue from '../HumanValue'
import FloatingBox from '../FloatingBox'

class FacebookAdSetRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: ['publishing', 'unpublished'].includes(this.props.waveState),
      editNotes: false,
      notes: props.adSet.notes,
      startTime: moment(props.adSet.start_time).parseZone().format("YYYY-MM-DD"),
      endTime: moment(props.adSet.end_time).parseZone().format("YYYY-MM-DD"),
      showError: false
    };

    this.debouncedUpdate = _.debounce(this.props.updateNotes, 500);

    this.createProxyUrl = this.createProxyUrl.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.notifyParents = this.notifyParents.bind(this);
    this.render = this.render.bind(this);
    this.renderAdSetId = this.renderAdSetId.bind(this);
    this.renderNotes = this.renderNotes.bind(this);
    this.renderValidations = this.renderValidations.bind(this);
    this.serializeDate = this.serializeDate.bind(this);
    this.showNotificationError = this.showNotificationError.bind(this);
    this.updateDate = this.updateDate.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.dateColumn = this.dateColumn.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    this.notifyParents();
  }

  updateDate(field) {
    const component = this;
    const type = field == 'ad_set_startTime' ? "start date" : "end date";

    Remote().request({
      url: component.createProxyUrl('facebook_ad_sets', { id: component.props.adSet.id }),
      method: 'PATCH',
      data: component.serializeDate(),
    }).then(() => {
      this.showNotificationError(`New ${type} has been saved.`, 'notice');
      this.notifyParents();
    }).catch(() => {
      this.showNotificationError('Date change was not saved. Please try again.', 'error');
    });
  }

  componentWillReceiveProps(nextProps) {
    const startTime = nextProps.adSet.start_time;
    const endTime = nextProps.adSet.end_time;

    if(this.props.adSet.start_time != startTime) {
      this.setState({
        startTime: startTime ? moment(startTime).parseZone().format('YYYY-MM-DD') : null
      });
    }

    if(this.props.adSet.end_time != endTime) {
      this.setState({
        endTime: endTime ? moment(endTime).parseZone().format('YYYY-MM-DD') : null
      });
    }
  }

  dateColumn(columnName, placeholder){
    const dateValue = this.state[columnName]
    if(this.state.editing){
      return <input className='form-control'
                    id={`ad_set_${columnName}`}
                    type='date'
                    value={dateValue}
                    placeholder={placeholder}
                    onChange={this.handleChange}/>
    }else{
      return dateValue
    }
  }

  createProxyUrl(route, params) {
    const query = Object.keys(params).map((key) => key + '=' + params[key]).join('&');
    return `/admin/campaigns/${this.props.campaignId}/promoters_v2/api_proxy/${route}?${query}`;
  }

  serializeDate() {
    let request = {
      id: this.props.adSet.id,
      start_time: null,
      end_time: null
    };


    // Account for cases where we don't have an invalid date
    // Dates that are "invalid" are as a result of being null
    if(!this.state.startTime.toLowerCase().includes('invalid')) {
      request.start_time = moment(this.state.startTime).toDate();
    }
    if(!this.state.endTime.toLowerCase().includes('invalid')) {
      request.end_time = moment(this.state.endTime).toDate();
    }

    return request;
  }

  handleChange(e){
    const id = e.target.id;
    const start_time = id === 'ad_set_startTime' ? e.target.value : this.state.startTime;
    const end_time = id === 'ad_set_endTime' ? e.target.value : this.state.endTime;
    const current_date = moment().format("YYYY-MM-DD");

    this.setState({
      startTime: moment(start_time).parseZone().format("YYYY-MM-DD"),
      endTime: moment(end_time).parseZone().format("YYYY-MM-DD")
      }, () =>
        this.updateDate(id)
    );
  }

  showNotificationError(message, loglevel) {
    notif({
      msg: message,
      type: loglevel,
      timeout: 3000
    });
  }

  render() {
    const adSet = this.props.adSet;
    return (
      <tr>
        <td>{this.renderAdSetId()}</td>
        <td>
          {this.renderNotes()}
        </td>
        <td>{adSet.name}</td>
        {this.state.editing && <td>{adSet.ad_campaign_name}</td>}
        <td>{this.dateColumn('startTime', 'Start Time')}</td>
        <td>{this.dateColumn('endTime', 'End Time')}</td>
        <td>{adSet.status}</td>
        { this.state.editing && <td>{adSet.type}</td> }
        <td>
          <MonetaryValue value={adSet.budget}/>
        </td>
        <td>
          <HumanValue value={adSet.goal}/>
        </td>
        {
          this.state.editing &&
          <td>
            <button className='btn btn-danger btn-sm' onClick={() => this.props.removeAdSet()}>
              <i className='fas fa-trash'/>
            </button>
          </td>
        }
        {
          this.state.editing &&
          <td>
            {this.renderValidations()}
          </td>
        }
      </tr>
    );
  }

  renderNotes() {
    const containerStyle = {
      display: 'flex',
      border: '1px solid lightgrey',
      backgroundColor: 'white',
      padding: '2px'
    };
    const inputStyle = {
      border: 'none',
      flex: '1',
      outline: 'none'
    };

    if(!this.state.editNotes) {
      return (
        <span style={{ cursor: 'pointer' }} onClick={() => this.setState({ editNotes: true })}>
          {this.state.notes || <em>None</em>} &nbsp;<i className='fas fa-pencil-alt'/>
        </span>
      );
    }

    return (
      <span style={containerStyle}>
        <input style={inputStyle} placeholder='Notes' value={this.state.notes || ''} onChange={(e) => this.updateNotes(e.target.value)} />
        <button className='btn btn-primary btn-xs' onClick={() => this.setState({ editNotes: false })}>
          Done
        </button>
      </span>
    );
  }

  updateNotes(text) {
    this.setState({
      notes: text
    }, () => this.debouncedUpdate(text));
  }

  renderValidations() {
    const renderError = (message) => (
      <span style={{position: 'relative'}}
            onMouseEnter={() => this.setState({ showError: true })}
            onMouseLeave={() => this.setState({ showError: false })}>
        <i style={{fontSize: '12pt'}} className='fas fa-exclamation-triangle text-danger' />

        {
          this.state.showError &&
          <FloatingBox type='error' message={message} />
        }
      </span>
    );

    const validation = this.validate();

    if(!validation) {
      return null;
    }

    return renderError(validation);
  }

  validate() {
    const start = moment(this.state.startTime, 'YYYY/MM/DD').toDate();
    const end = moment(this.state.endTime, 'YYYY/MM/DD').toDate();

    if(!start || !end || start == 'Invalid Date' || end == 'Invalid Date') {
      return 'Ad sets must have a start and end date';
    }

    if(start >= end) {
      return 'Ad set start date must be at least 24 hours before the end date';
    }

    if(end < new Date()) {
      return 'Ad sets must have an end date in the future';
    }

    const dailyBudget = this.props.adSet.budget_per_day.toFixed(2);
    if(this.props.adSet.type == 'IMPRESSIONS' && dailyBudget < 1.0) {
      return `This impression ad needs a budget of more than $1 per day (currently $${dailyBudget})`;
    } else if(dailyBudget < 5.0) {
      return `This non-impression ad needs a budget of more than $5 per day (currently $${dailyBudget})`;
    }

    return null;
  }

  // Push validations up to the parent components
  notifyParents() {
    const validation = this.validate();
    this.props.notifyParents(validation);
  }

  renderAdSetId() {
    if(!this.props.adSet.facebook_graph_id) {
      return <em>None</em>;
    }

    return <a target='_blank' href={this.props.adSet.url}>{this.props.adSet.facebook_graph_id}</a>;
  }

  static mapDispatchToProps(dispatch, myProps) {
    return {
      notifyParents: (validation) => dispatch({ type: 'UPDATE_ADSET_VALIDATIONS', value: { id: myProps.adSet.id, validation: validation }}),
      removeAdSet: () => {
        dispatch({ type: 'REMOVE_ADSET', id: myProps.adSet.id });
        myProps.delete();
      }
    };
  }
};

// Connect to the redux store so that we can push validations
export default connect(
  null,
  FacebookAdSetRow.mapDispatchToProps
)(FacebookAdSetRow);
