import React from 'react';
import { Remote } from '../utils';

import PaymentsNameSetup from './NameSetup'
import PaymentsAccountSetup from './AccountSetup'
import PaymentsTaxSetup from './TaxSetup'
import PaymentsBankSetup from './BankSetup'
import PaymentsProgressBar from './ProgressBar'

export default class PaymentWizard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Metadata
      id: props.id,
      username: props.username,
      name: props.full_name,
      valid: true,
      loading: false,
      errors: [],

      // Account setup
      lastName: props.last_name,
      firstName: props.first_name,
      accountName: props.full_name,
      phone: '',

      // Address setup
      address1: props.address1,
      address2: props.address2,
      country: props.country,
      city: props.city,
      state: props.state,
      zipCode: props.zip,

      // Tax setup
      taxId: '',
      name1099: props.full_name,
      taxClassification: '',
      autoPayment: true,

      // Bank Setup
      routingNumber: '',
      accountNumber: '',
      accountType: '',
      accountClass: '',

      currentStep: 0
    };

    this.render = this.render.bind(this);
    this.renderStep = this.renderStep.bind(this);
    this.consumeChanges = this.consumeChanges.bind(this);
    this.step = this.step.bind(this);
    this.nextButtonClassName = this.nextButtonClassName.bind(this);
    this.submit = this.submit.bind(this);
    this.renderNextButton = this.renderNextButton.bind(this);
    this.renderPrevButton = this.renderPrevButton.bind(this);
    this.renderSpinner = this.renderSpinner.bind(this);
    this.renderErrors = this.renderErrors.bind(this);
  }

  consumeChanges(changes) {
    this.setState(changes);
  }

  renderStep() {
    switch(this.state.currentStep) {
      case 0:
        return <PaymentsNameSetup {...this.state} onChange={this.consumeChanges} />;
      case 1:
        return <PaymentsAccountSetup {...this.state} onChange={this.consumeChanges} />;
      case 2:
        return <PaymentsTaxSetup {...this.state} onChange={this.consumeChanges} />;
      case 3:
        return <PaymentsBankSetup {...this.state} onChange={this.consumeChanges} />;
      default:
        return <span>What</span>
    }
  }

  step(forward) {
    const increment = forward ? 1 : -1;
    this.setState({
      currentStep: this.state.currentStep + increment
    });
  }

  nextButtonClassName() {
    if(this.state.valid) {
      return 'btn btn-primary';
    } else {
      return 'btn btn-primary disabled';
    }
  }

  submit() {
    this.setState({ loading: true });

    Remote().request({
      url: `/profile/${this.props.username}/submit_payments_wizard`,
      method: 'POST',
      //dataType: 'json',
      data: {
        payment_settings_form: {
          last_name: this.state.lastName,
          first_name: this.state.firstName,
          vendor_name: this.state.accountName,
          address1: this.state.address1,
          country: this.state.country,
          city: this.state.city,
          state: this.state.state,
          zip: this.state.zipCode,
          tax_id_number: this.state.taxId,
          name_1099: this.state.name1099,
          federal_tax_classification: this.state.taxClassification,
          payment_notification: this.state.autoPayment,
          ach_bank_routing_number: this.state.routingNumber,
          bank_account_number: this.state.accountNumber,
          bank_account_type: this.state.accountType,
          bank_account_classification: this.state.accountClass,
          phone: this.state.phone,
          country: 'US'
        }
      },

    }).then(() => {
      // If we get a successful response, refresh the page to reveal the confirmation buttons
      window.location.reload();
    }).catch((error) => {
      // If we get an erroneous response, try to set the state for displaying the errors
      if(error.data.responseJSON) {
        this.setState({
          errors: error.data.responseJSON
        });
      }
    }).then(() => { this.setState({ loading: false }); });
  }

  renderNextButton() {
    if(this.state.currentStep == PaymentWizard.STEPS.length - 1) {
      return <span className={this.nextButtonClassName()} onClick={this.submit}>Submit</span>;
    }

    return <span className={this.nextButtonClassName()} onClick={() => this.step(true)}>Next</span>;
  }

  renderPrevButton() {
    if(!this.state.currentStep) {
      return;
    }

    return <span className='btn btn-primary' onClick={() => this.step(false)}>Previous</span>;
  }

  renderSpinner() {
    const containerStyle = {
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#ffffffcc',
      flexDirection: 'column',
      zIndex: '3',
      fontSize: '20pt'
    };
    const spinnerStyle = {
      fontSize: '30pt',
      color: 'rgb(135, 197, 53)'
    };

    return (
      <div style={containerStyle}>
        <i className='fas fa-spin fa-circle-notch' style={spinnerStyle} />
        Please Wait
      </div>
    );
  }

  renderErrors() {
    const listStyle = {
      listStyleType: 'disc'
    };

    return (
      <div className='alert alert-danger'>
        <strong>
          <i className='fas fa-exclamation-triangle'/> Something Went Wrong:
        </strong>
        <ul style={listStyle}>
          {this.state.errors.map((err) => <li key={err}>{err}</li>)}
        </ul>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.state.loading && this.renderSpinner()}
        <div className='small' style={{ marginBottom: '16px' }}>
          Help us make paying you easier by filling out a few
          easy payment questions below.
        </div>

        {!!this.state.errors.length && this.renderErrors()}

        <div style={{ maxWidth: "400px", margin: "auto" }}>
          <PaymentsProgressBar step={this.state.currentStep} steps={PaymentWizard.STEPS}/>
          {this.renderStep()}
        </div>

        <div className='text-center'>
          {this.renderPrevButton()}
          {this.renderNextButton()}
        </div>
      </div>
    );
  }
};

PaymentWizard.STEPS = [
  'Personal',
  'Address',
  'Tax Info',
  'Banking'
];

PaymentWizard.displayName = 'Payments.PaymentWizard';
