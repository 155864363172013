import React from 'react';

export default class CampaignsDetailsHubRequest extends React.Component {
    constructor(props) {
      super(props);

      this.render = this.render.bind(this);
    }

    render(){
        return (
            <div className="text-center">
                Click <a target="_blank" href="https://cbrequestform.typeform.com/to/TbA6Ww">here</a> to fill out a hub request.
            </div>
        );
    }
}