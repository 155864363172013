import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { FileUploader, Remote } from '../utils';

import LoadingSpinner from '../LoadingSpinner'
import SingleSelect from '../SingleSelect'
import SaveIndicator from '../SaveIndicator'
import SaveError from '../SaveError'
import CommunityLoginPrompt from '../community_logins/CommunityLoginPrompt'


const statusOptions = [{ name: 'Inactive', value: 'inactive' }, {name: 'Active', value: 'active'}];
const accessOptions = [{ name: 'Public', value: 'open' }, {name: 'Private', value: 'code'}];

class CommunitiesLoginSettings extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      refreshKey: Date.now(),
      initialSettings: {},
      loading: false
    }

    this.componentDidMount = this.componentDidMount.bind(this);
    this.loadCommunityLoginSettings = this.loadCommunityLoginSettings.bind(this);
    this.resetCommunityLoginSettings = this.resetCommunityLoginSettings.bind(this);
    this.saveCommunityLoginSettings = this.saveCommunityLoginSettings.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.handleColorPickerChange = this.handleColorPickerChange.bind(this);
    this.validationMessage = this.validationMessage.bind(this);
    this.render = this.render.bind(this);

    this.debouncedColor = _.debounce(this.handleColorPickerChange, 500)
    this.debouncedUpdate = _.debounce(this.props.updateCommunityLoginSettings, 500)
  }


  componentDidMount() {
    this.loadCommunityLoginSettings();
  }

  loadCommunityLoginSettings(){
    let community_id = this.props.community.id || this.props.communityId;

    const params = $.param({
      api_action: `communities/${community_id}/community_login_settings`
    });

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'GET',

    }).then((response) => {
      this.props.setCommunityLoginSettings(response.data);
      this.setState({initialSettings: response.data}) //store initial settings value for cancel functionality
    }).catch((error) => {
      this.props.openNotification(
        'Failed to load Community login settings.',
        'failure',
        error
      )
      setTimeout(this.props.closeNotification, 5000);
    });
  }

  resetCommunityLoginSettings(){
    this.props.setCommunityLoginSettings(this.state.initialSettings)
    this.setState({refreshKey: Date.now()})
  }

  saveCommunityLoginSettings(){
    this.setState({loading: true})
    const login_settings = this.props.login_settings
    const params = $.param({
      api_action: `communities/${this.props.communityId}/community_login_settings/${login_settings.id}`
    });

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'PATCH',
      data: login_settings,
    }).then((response) => {
      this.props.setCommunityLoginSettings(response.data);

      this.props.openNotification(
        'Community login settings saved!',
        'success',
      )
      setTimeout(this.props.closeNotification, 5000);

      this.setState({
        initialSettings: response.data, //reset initial settings value to new settings
        loading: false
      })
    }).catch((error) => {
      this.setState({loading: false});
      this.props.openNotification(
        'Failed to save Community login settings.',
        'failure',
        error
      )
      setTimeout(this.props.closeNotification, 5000);
    });
  }

  uploadFile(file) {
    const login_settings = this.props.login_settings
    let formData = new FormData();
    formData.append('community_image', file)

    FileUploader().request({
      url: `community_login_settings/${login_settings.id}/upload_community_image`,
      method: 'PATCH',
      data: formData,
      contentType: false,
      processData: false,

    }).then((response) => {
      this.props.updateCommunityLoginSettings('community_image', response.data.media.url)

      this.props.openNotification(
        'Community login image saved!',
        'success',
      )
      setTimeout(this.props.closeNotification, 5000);
    }).catch((error) => {
      this.props.openNotification(
        'Failed to upload community image.',
        'failure',
        error
      )
      setTimeout(this.props.closeNotification, 5000);
    });
  }

  handleColorPickerChange(color) {
      this.props.updateCommunityLoginSettings('primary_color', color)

      if(color.match(/^#+([a-fA-F0-9]{6})$/)){
        Array.from(document.getElementsByClassName("settings_color_picker")).map((elem) => {
            elem.value = color;
        })
    }
  }

  validationMessage(field) {
    let validations = this.props.validations
    if (validations.hasOwnProperty(field)){
      return <span className='error_text'>{validations[field]}</span>
    }
  }

  render() {
    const { login_settings, validations, community } = this.props;
    const refreshKey = this.state.refreshKey

    let submitDisabled = this.state.loading || (validations && Object.keys(validations).length !== 0)

    if(_.isEmpty(login_settings) || this.state.loading){
      return(
        <LoadingSpinner />
      );
    } else {
      const communityImageFileName = login_settings.community_image ? login_settings.community_image.split("/").pop().substr(0,15) : ''

      return (
        <div className='community_login_settings_container'>
          <div className='community_login_settings_fields' key={refreshKey}>
            <div className={`community_login_settings_input ${validations.hasOwnProperty('title') ? 'error' : ''}`} >
              <label>Page Title </label>
              <input
                type='text'
                defaultValue={login_settings.title}
                placeholder='Write your title here.'
                onChange={(event) => this.debouncedUpdate('title', event.target.value)}
              />
              {this.validationMessage('title')}
            </div>
            <div className={`community_login_settings_input ${validations.hasOwnProperty('description') ? 'error' : ''}`} >
              <label>Page Description</label>
              <textarea
                rows='3'
                defaultValue={login_settings.description}
                placeholder={`Welcome ${community.name}`}
                onChange={(event) => this.props.updateCommunityLoginSettings('description', event.target.value)}
              />
              {this.validationMessage('description')}
            </div>
            <div className={`community_login_settings_input ${validations.hasOwnProperty('community_image') ? 'error' : ''}`} >
              <label>Community Image</label>

              <input
                type='file'
                id='community_image'
                onChange={(event) => this.uploadFile(event.target.files[0])}
                style={{display:'none'}}
              />
              <label className='btn btn-default community-image-upload' htmlFor="community_image">{communityImageFileName || 'Select file'}</label>
              {this.validationMessage('community_image')}
            </div>
            <div className={`community_login_settings_input ${validations.hasOwnProperty('primary_color') ? 'error' : ''}`} >
              <label>Background Color</label>
              <div className='primary_color_settings_inputs'>
                <input
                  type='color'
                  className='settings_color_picker color_input'
                  defaultValue={login_settings.primary_color}
                  onChange={(event) => this.handleColorPickerChange(event.target.value)}
                />
                <input
                  type='text'
                  className='settings_color_picker hex_input'
                  defaultValue={login_settings.primary_color}
                  onChange={(event) => this.debouncedColor(event.target.value)}
                />
              </div>
              {this.validationMessage('primary_color')}
            </div>
            <div className={`community_login_settings_input ${validations.hasOwnProperty('status') ? 'error' : ''}`} >
              <label>Page Status</label>
              <SingleSelect
                preload={statusOptions}
                selected={login_settings.status || ''}
                search={(term, callback) => callback(statusOptions.filter(item => item.name.includes(term)))}
                onUpdate={(value) => this.props.updateCommunityLoginSettings('status', value)}
                placeholder={'Select'}
              />
              {this.validationMessage('status')}
            </div>
            <div className={`community_login_settings_input ${validations.hasOwnProperty('community_access') ? 'error' : ''}`} >
              <label>Community Access</label>
              <SingleSelect
                preload={accessOptions}
                selected={login_settings.community_access || ''}
                search={(term, callback) => callback(accessOptions.filter(item => item.name.includes(term)))}
                onUpdate={(value) => this.props.updateCommunityLoginSettings('community_access', value)}
                placeholder={'Select'}
              />
              {this.validationMessage('community_access')}
              {login_settings.community_access === 'open' &&
                <div className='community_access_warning' >
                  <i className='fas fa-unlock fa-lg'/>
                  <span>Anyone can join your community.</span>
                </div>
              }
            </div>
            {login_settings.community_access === 'code' &&
              <div className={`community_login_settings_input ${validations.hasOwnProperty('access_code') ? 'error' : ''}`} >
                <label>Community Access Code</label>
                <input
                  type='text'
                  defaultValue={login_settings.access_code}
                  placeholder='Enter Code'
                  onChange={(event) => this.debouncedUpdate('access_code', event.target.value)}
                />
                {this.validationMessage('access_code')}
                <div className='community_access_warning' >
                  <i className='fas fa-lock fa-lg'/>
                  <span>Community entry will require code.</span>
                </div>
              </div>
            }
            <hr/>
            <div className='community_login_settings_actions'>
              <button className='btn secondary' onClick={this.resetCommunityLoginSettings} >Reset</button>
              <button className='btn primary' onClick={this.saveCommunityLoginSettings} disabled={submitDisabled} >Submit & Publish</button>
            </div>
          </div>
          <div className='community_login_settings_preview'>
            <CommunityLoginPrompt {...this.props.login_settings} communityId={this.props.communityId} preview={true}/>
          </div>
          {
            this.props.notifications && this.props.notifications.saving &&
              <SaveIndicator type={this.props.notifications.saveType}>
                {this.props.notifications.saveText}
                {this.props.notifications.saveErrors && <SaveError errors={this.props.notifications.saveErrors}/>}
              </SaveIndicator>
          }
        </div>
      );
    }
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      login_settings: state.login_settings,
      validations: state.validations,
      notifications: state.notifications
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      setCommunityLoginSettings: login_settings => dispatch({ type: 'SET_COMUNITY_LOGIN_SETTINGS', value: login_settings }),
      updateCommunityLoginSettings: (field, value) => dispatch({ type: 'UPDATE_LOGIN_FIELD', value: {field: field, value: value} }),
      openNotification: (saveText, saveType, saveError) => dispatch({type: 'OPEN_NOTIFICATION', value: {saveText, saveType, saveError}}),
      closeNotification: data => dispatch({ type: 'CLOSE_NOTIFICATION'})
    };
  }
};
CommunitiesLoginSettings.displayName = 'Communities.LoginSettings';
CommunitiesLoginSettings.propTypes = {
  proxyUrl: PropTypes.string.isRequired
};

export default connect(
  CommunitiesLoginSettings.mapStateToProps,
  CommunitiesLoginSettings.mapDispatchToProps
)(CommunitiesLoginSettings);
