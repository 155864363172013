import React from 'react';
import PropTypes from 'prop-types';

class InstructionsRoundTaskRequirement extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.render = this.render.bind(this);
  }

  render() {
    return (
      <div className='form-group'>
        <div className='input-group'>
          <input
            className='form-control'
            type='text'
            value={this.props.requirement.description}
            onChange={this.onChange}
            />
          <div className='input-group-addon' style={{ cursor: 'pointer' }} onClick={this.onDelete}>
            <i className='fas fa-times' style={{ color: '#a94442' }}/>
          </div>
        </div>
      </div>
    );
  }

  onChange(e) {
    this.props.onUpdate({
      ...this.props.requirement,
      description: e.target.value
    });
  }

  onDelete() {
    this.props.onDelete();
  }
}

InstructionsRoundTaskRequirement.displayName = 'Instructions.RoundTaskRequirement';
InstructionsRoundTaskRequirement.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  requirement: PropTypes.shape({
    description: PropTypes.string.isRequired
  }).isRequired
}
export default InstructionsRoundTaskRequirement