import React from 'react';

export default class Profile extends React.Component {
  render(){
    const {activity, network} = this.props.notification;
    const P = React.Fragment;
    switch(activity) {
      case 'collection_failed':
        return (
          <P>
            <b>Sorry, but we were unable to update your social following count for {network}.</b>
            <br />
            Please disconnect and then reconnect or re-enter your URL to fix the issue.
          </P>
        )
      case 'deactivate_vendor_status':
        return (
          <P>
            <b>Sorry, but there's been a problem processing your payment.</b>
            Please update your payment information and we will try again.
            <br />
            If you have any questions, please file a support ticket.
          </P>
        )
      default:
        return null;
    }
  }
}
Profile.displayName = 'Notification.Templates.Profile'