import { createStore } from 'redux';

const initialState = {
  roundTasks: [],
  loading: true,
  communities: [],
  communitiesLoading: true,
  selectedCommunityId: null,

  // General metadata
  currentUserId: null,
  baseUrl: null,
  proxyUrl: null,
  deletionId: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_ROUND_TASKS':
      return {
        ...state,
        roundTasks: action.value
      };
    case 'START_LOADING':
      return {
        ...state,
        loading: true
      };
    case 'STOP_LOADING':
      return {
        ...state,
        loading: false
      };
    case 'UPDATE_COMMUNITIES':
      return {
        ...state,
        communities: action.value
      };
    case 'SET_COMMUNITY':
      return {
        ...state,
        selectedCommunityId: action.value
      };
    case 'START_COMMUNITIES_LOADING':
      return {
        ...state,
        communitiesLoading: true
      };
    case 'STOP_COMMUNITIES_LOADING':
      return {
        ...state,
        communitiesLoading: false
      };
    // Special case -- just interpolate all data in
    // Should only be used for initialization purposes
    case 'UPDATE_META':
      return {
        ...state,
        ...action.value
      };
    case 'SET_DELETION_ID':
      return {
        ...state,
        deletionId: action.value
      };
    case 'DELETE_DATA_RECORD':
      return {
        ...state,
        roundTasks: state.roundTasks.filter(x => x.id != state.deletionId)
      };
    default:
      return state;
  }
};

const RoundTaskStore = createStore(
  reducer,
  window && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ name: 'Redux.RoundTaskStore' }) // Enable redux devtools middleware
);

export default RoundTaskStore;