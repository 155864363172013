import React from 'react';

export default class UserApplication extends React.Component {
  render(){
    const {user_name} = this.props.notification;
    return (
      <React.Fragment><b>{user_name}</b> has applied for Social Fabric membership.</React.Fragment>
    )
  }
}
UserApplication.displayName= 'Notification.Templates.UserApplication'