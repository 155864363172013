import React from 'react';
import { connect } from 'react-redux'
import { Remote } from '../utils';
import ManualMetricsInputs from "./ManualMetricsInputs";

class AdminOtherContentManualMetrics extends React.Component {

  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.loadBloggerShopTask = this.loadBloggerShopTask.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount(){
    this.loadBloggerShopTask();
    this.props.initializeManualMetrics(this.props.manualMetrics);
  }

  loadBloggerShopTask() {
    const params = $.param({
      api_action: `/blogger_shop_tasks/${this.props.bloggerShopTaskId}`
    });
    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'GET'
    }).then(response => {
      this.props.setBloggerShopTask(response.data);
    });
  }

  render() {
    const {manualMetrics, bloggerShopTask, proxyUrl, manualMetricsInstructions} = this.props;

    return <div style={{marginBottom: '25px'}}>
      {bloggerShopTask &&
        <ManualMetricsInputs
          analytics={manualMetrics}
          proxyUrl={proxyUrl}
          type='other_content'
          manualMetricsInstructions={manualMetricsInstructions}
          blogger_shop_task_id={bloggerShopTask.id}
          blogger_shop_task_state={bloggerShopTask.state} />
      }
    </div>
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      manualMetrics: state.manualMetrics || myProps.manualMetrics,
      bloggerShopTask: state.bloggerShopTask
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      initializeManualMetrics: (value) => dispatch({ type: 'INITIALIZE_MANUAL_METRICS', value: { name: 'manualMetrics', metrics: value}}),
      setBloggerShopTask: task => dispatch({ type: 'SET_BLOGGER_SHOP_TASK', value: task }),
    };
  }
};

AdminOtherContentManualMetrics.displayName = 'Contents.AdminOtherContentManualMetrics';
export default connect(
  AdminOtherContentManualMetrics.mapStateToProps,
  AdminOtherContentManualMetrics.mapDispatchToProps
)(AdminOtherContentManualMetrics);


