import React from 'react';
import { Remote } from '../utils';

import LoadingSpinner from './LoadingSpinner'

export default class AdSelection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      adTypes: [],
      selectedIds: [],
      selectedPage: AdSelection.SOFABFOOD_PAGE_ID,
      loading: false,
      networkRequests: [],
      userPromoterPage: null,
      contentType: this.props.contentType,
      destinationUrl: this.props.wizardState.utm
    };

    this.render = this.render.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.renderCheckBox = this.renderCheckBox.bind(this);
    this.updatePage = this.updatePage.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.addRequest = this.addRequest.bind(this);
    this.renderPageSelection = this.renderPageSelection.bind(this);
  }

  componentDidMount() {
    const component = this;
    const adTypeIds = this.props.wizardState.ad_type_ids;
    const {facebook_page_id, instagram_actor_id} = this.props.wizardState;
    this.setState({ loading: true });

    let userPromoterPage = new Promise((resolve) => {
      Remote().request({
        url: `/admin/promoter_ad_states/promoter_page`,
        method: 'GET',
        data: {
          campaign_id: this.props.campaignId,
          user_id:  this.props.userId
        }
      }).then((response) => {
        resolve({userPromoterPage: response.data});
      });
    });

    userPromoterPage.then((results) => {
      let userPage = results.userPromoterPage
      let selectedPage = AdSelection.SOFABFOOD_PAGE_ID;

      if (facebook_page_id && instagram_actor_id) {
        selectedPage = JSON.stringify({facebook_page_id: facebook_page_id, instagram_actor_id: instagram_actor_id})
      } else if (userPage) {
        selectedPage = JSON.stringify({facebook_page_id: userPage.facebook_page_id, instagram_actor_id: userPage.instagram_page_id})
      }

      const defaultFilter = (types) => types.filter(
        adType => (!!this.props.wizardState.is_blog == !!adType.for_blog) || (!!this.props.wizardState.is_hub && !!adType.for_hub)
      ).map(x => x.id);

      const existingFilter = (types) => types.filter(adType =>
        (
          (!!this.props.wizardState.is_blog == !!adType.for_blog) ||
          (!!this.props.wizardState.is_hub && !!adType.for_hub)
        )
        && _.includes(adTypeIds, adType.id)
      ).map((x) => x.id);

      const adTypes = $.get(`${this.props.typesEndpoint}?campaign_id=${this.props.campaignId}`, (response) => {
        component.setState({
          loading: false,
          adTypes: response,
          selectedIds: adTypeIds.length ? existingFilter(response) : defaultFilter(response),
          selectedPage: selectedPage,
          userPromoterPage: userPage
        });
      });

      this.addRequest(adTypes);
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.wizardState.id === nextProps.wizardState.id) {
      return;
    }

    const adTypeIds = nextProps.wizardState.ad_type_ids;
    this.setState({
      selectedIds: adTypeIds.length ? adTypeIds : this.state.adTypes.map(x => x.id),
      selectedPage: nextProps.wizardState.facebook_page_id
    });
  }

  componentWillUnmount() {
    this.state.networkRequests.forEach((req) => req.abort());
  }

  render() {
    if(this.state.loading) {
      return <LoadingSpinner loading={true} />
    }

    return (
      <div className='modal-inner-content'>
        <div className='ad-selection'>

          <div className='destination-url section top'>
            <div className='section-header'>
              <label>
                Destination Url
              </label>
            </div>
            <div className='section-body'>
              <input type='text' placeholder='Destination Url' value={this.state.destinationUrl} className='form-control' onChange={(e) => this.updateDestinationUrl(e)} required/>
            </div>
          </div>

          <div className='ad-types section top'>
            <div className='section-header'>
              <label>
                Ad Types
              </label>
            </div>
            <div className='ad-type-checklist section-body overflow-scroll'>
              {this.state.adTypes.map((adType) => this.renderCheckBox(adType))}
            </div>
          </div>

          <div className='ad-page section top'>
            <div className='section-header'>
              <label>
                Promoting Page
              </label>
            </div>
            <div className='section-body'>
              {this.renderPageSelection()}
            </div>
          </div>

        </div>
        <div className='modal-next'>
          <button className='btn btn-primary' onClick={this.saveChanges}>
            Continue <i className='fas fa-chevron-right' />
          </button>
        </div>
      </div>
    );
  }

  renderPageSelection() {
    const SOFAB_FOOD_ID = AdSelection.SOFABFOOD_PAGE_ID;
    const SOFABLIFE_ID = AdSelection.SOFABLIFE_PAGE_ID;
    const COLECTIVA_ID = AdSelection.COLECTIVA_LATINA_PAGE_ID;

    let userPage = this.state.userPromoterPage;
    let userOption = '';
    if ( userPage ) {
      userOption = <option value={JSON.stringify({
        facebook_page_id: userPage.facebook_page_id,
        instagram_actor_id: userPage.instagram_page_id
      })}>{userPage.name}</option>
    }

    return (
      <div className='select-wrapper'>
        <select className='form-control' onChange={this.updatePage} value={this.state.selectedPage}>
          {userOption}
          <option value={SOFAB_FOOD_ID}>
            Sofab Food
          </option>
          <option value={SOFABLIFE_ID}>
            Sofab Life
          </option>
          <option value={COLECTIVA_ID}>
            Colectiva Latina
          </option>
        </select>
      </div>
    )
  }

  renderCheckBox(adType) {
    const selected = _.includes(this.state.selectedIds, adType.id);
    const listener = (evt) => {
      if (evt.target.checked) {
        this.setState({ selectedIds: this.state.selectedIds.concat(adType.id) });
      } else {
        this.setState({ selectedIds: this.state.selectedIds.filter(x => x !== adType.id) });
      }
    };

    let labelClass = 'checkbox';

    return (
      <label className={labelClass} key={adType.id}>
        <input type='checkbox' checked={selected} onChange={listener} />
        {adType.name}
      </label>
    );
  }

  updatePage(event) {
    this.setState({
      selectedPage: event.target.value
    });
  }

  updateDestinationUrl(event){
    this.setState({
      destinationUrl: event.target.value
    })
  }

  saveChanges() {
    const component = this;
    this.setState({ loading: true });
    selectedPage = JSON.parse(this.state.selectedPage)
    ad_payload = {
      id: this.props.wizardState.id,
      ad_type_ids: this.state.selectedIds,
      utm: this.state.destinationUrl,
      facebook_page_id: selectedPage.facebook_page_id,
      instagram_actor_id: selectedPage.instagram_actor_id
    }
    if(this.state.contentType == 'CustomContent'){
      ad_payload.content_url = this.state.destinationUrl
    };
    const xhr = Remote().request({
      url: this.props.wizardEndpoint,
      method: 'PATCH',
      data: ad_payload,
    }).then(() => {
      component.setState({ loading: false });
      component.props.proceed();
    });

    this.addRequest(xhr);
  }

  addRequest(xhr) {
    this.setState({
      networkRequests: this.state.networkRequests.concat(xhr)
    });
  }
};

// To get these values from the Graph API, go to the graph API explorer
// and GET /me/accounts as our developer user
AdSelection.COLECTIVA_LATINA_PAGE_ID = JSON.stringify({facebook_page_id: "421959694603893", instagram_actor_id: "800861780006038"});
AdSelection.SOFABFOOD_PAGE_ID = JSON.stringify({facebook_page_id:"456330121165496",instagram_actor_id:"484409028287673"});
AdSelection.SOFABLIFE_PAGE_ID = JSON.stringify({facebook_page_id:"634089373298907",instagram_actor_id:"1168959529782967"});
