import { createStore } from 'redux';
import _ from 'lodash'

const initialState = {
  conversations: {},
  per_page: 10,
  current_page: 0,
  next_page: 0,
  total_pages: 0,
  loading: false,
  message_loading: false,
  load_more_messages: false,
  new_conversation: false,
  media_mode: false,
  recipients: [],
  show_recipients: false,
  no_recipients_error: false,
  body: '',
  file: null,
  authenticity_token: null,
  selected: false,
  messages_page: 1,
  conversation: {
    messages: []
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIALIZE_DATA':
      return {
        ...state,
        ...action.value
      };
    case 'UPDATE_DATA' :
      return{
        ...state,
        conversations: _.unionBy(state.conversations, action.value.conversations, 'id'),
        loading: false,
        per_page: 10,
        current_page: state.current_page+1
      };
    case 'TOGGLE_LOADING':
      return {
        ...state,
        loading: !state.loading
      };
    case 'SET_SELECTED':
      return {
        ...state,
        selected: action.value.selected,
        new_conversation: false
      };
    case 'SET_CONVERSATION':
      return {
        ...state,
        conversation: action.value.conversation,
        loading: false
      };
    case 'ADD_MESSAGE':
      const added_messages = _.concat(state.conversation.messages, action.value)
      const excluded_conversations = _.remove(state.conversations, function(n) {
        return n.id !== state.conversation.id;
      });
      const relative_time = action.value.relative_time
      const conversation= {...state.conversation, messages: added_messages, relative_time: relative_time}
      const conversations = _.concat(conversation, excluded_conversations)
      return {
        ...state,
        conversation: conversation,
        conversations: conversations,
        message_loading: false
      };
    case 'SET_MESSAGE_LOADING':
      return{
        ...state,
        message_loading: true
      };
    case 'TOGGLE_LOADING_MESSAGES':
      return{
        ...state,
        load_more_messages: !state.load_more_messages
      };
    case 'SET_NEW_CONVERSATION':
      return{
        ...state,
        new_conversation: true,
        selected: false,
        recipients: [],
        show_recipients: false
      };
    case 'SET_MESSAGE_FIELDS':
      return{
        ...state,
        media_mode: action.value.media_mode,
        body: action.value.body,
        file: action.value.file
      };
    case 'RESET_MESSAGE_STATE':
      return{
        ...state,
        body: '',
        file: null,
        media_mode: false
      };
    case 'SET_RECIPIENTS':
      return{
        ...state,
        recipients: action.value.recipients
      };
    case 'SHOW_RECIPIENTS':
      return{
        ...state,
        show_recipients: !state.show_recipients
      }
    case 'UPDATE_NEW_CONVERSATION':
      return{
        ...state,
        new_conversation: false,
        selected: true,
        message_loading: false,
        conversation: action.value.conversation,
        conversations: _.concat(action.value.conversation, state.conversations)
      };
    case 'TOGGLE_NO_RECIPIENTS_ERROR':
      return{
        ...state,
        no_recipients_error: !state.no_recipients_error
      }
    case 'UPDATE_MESSAGES':
      const updated_messages = _.unionBy(action.value.messages, state.conversation.messages, 'id')
      return{
        ...state,
        conversation: {...state.conversation, messages: updated_messages},
        load_more_messages: false,
        messages_page: state.messages_page+1
      }
    default:
      return state;
  }
}

const ConversationsStore = createStore(
  reducer,
  window && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({name: 'Redux.ConversationsStore'}) // Enable redux devtools middleware
);

export default ConversationsStore;