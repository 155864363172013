import React from 'react';
import { connect } from 'react-redux';

import InfluencerDashboardEarningsSummaryCard from './SummaryCard'
import CarouselContainer from '../../CarouselContainer'

class InfluencerDashboardEarningsSummary extends React.Component {
  constructor(props) {
    super(props)
    this.summary = this.summary.bind(this);
    this.render = this.render.bind(this);
  }
  
  summary(kind) {
    const { i18n } = this.props;
    if (!kind || !i18n)
      return {};
    return {
      card_title: _.get(i18n, `${kind}`),
      sum_pay: _.get(this.props, `${kind}.sum`) || '$0',
      final_pay: _.get(this.props, `${kind}.avg_final_payments`) || '$0',
      sum_text: _.get(i18n, 'total'),
      final_text: _.get(i18n, 'avg_final')
    }
  }

  render() {
    const all = this.summary('all');
    const year_to_date = this.summary('year_to_date');
    return (
      <div className='influencer-dashboard mb-15'>
        <h2 className='mb-10 pl-10'>Summary</h2>
        <CarouselContainer>
          <InfluencerDashboardEarningsSummaryCard {...all } />
          <InfluencerDashboardEarningsSummaryCard {...year_to_date } />
        </CarouselContainer>
      </div>
    )
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      ...(_.get(state, 'earnings', {})),
    }
  }
}
InfluencerDashboardEarningsSummary.displayName = 'InfluencerDashboard.EarningsSummary';
export default connect(
  InfluencerDashboardEarningsSummary.mapStateToProps,
  null
)(InfluencerDashboardEarningsSummary); 
