import React from 'react';
import PropTypes from 'prop-types'
export default class FixedBar extends React.Component {
  render() {
    let body;
    if(this.props.style) {
      body = <div className={this.props.style}>
        {this.props.children}
      </div>;
    } else {
      body = this.props.children;
    }

    // Note: this specific subsection of styles is inlined for customization
    // The rest of the styling lives in components/fixed_bar.scss
    const customStyles = {
      zIndex: this.props.zIndex || 1
    };

    return <div className='fixed-bar' style={customStyles}>
      {body}
    </div>;
  }
};

FixedBar.propTypes = {
  zIndex: PropTypes.number,
  style: PropTypes.oneOf([
    'left-right'
  ])
}

