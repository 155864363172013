import React from 'react';
import { Remote } from '../utils';

class NewRoundButton extends React.Component {
  constructor(props) {
    super(props);

    this.createRound = this.createRound.bind(this);
    this.render = this.render.bind(this);
  }

  render() {
    return (
      <div className='text-center'>
        <button className='btn btn-rounded btn-primary' onClick={e => this.createRound(e)}>
          <i className='fas fa-plus'/> Add Round
        </button>
      </div>
    );
  }

  createRound(e) {
    // Prevent form submission
    e.preventDefault();

    const params = $.param({
      api_action: `/shoppertunities/${this.props.meta.shoppertunity_id}/rounds`
    });

    Remote().request({
      url: this.props.meta.proxyUrl + '?' + params,
      method: 'POST'
    }).then(response => {
      this.props.addRound(response.data);
    }).catch(error => {
      const data = JSON.parse(error.response.data.responseText);
    });
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      meta: state.meta
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      addRound: round => dispatch({ type: 'ADD_ROUND', value: round })
    };
  }
};

NewRoundButton.displayName = 'Shoppertunities.NewRoundButton';
export default connect(
  NewRoundButton.mapStateToProps,
  NewRoundButton.mapDispatchToProps
)(NewRoundButton);
