import React from 'react';

export default class UserSignUpInfoAccordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {open: false};

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(state => ({
      open: !state.open
    }));
  }

  render(){
    return (
      <div className={`accordion ${this.state.open ? 'open' : 'closed'}`}>
        <div className='title' onClick={this.handleClick}>
          <span>{this.props.title}</span>
          <i className='fas fa-chevron-down'></i>
        </div>
        <div className='body'>
          <div className='body_content' dangerouslySetInnerHTML={{__html: this.props.body}}></div>
        </div>
      </div>
    )
  }
}
