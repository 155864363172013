import React from 'react';
import NavigationTabsWrapper from '../navigation_tabs/Wrapper.jsx';

export default class ShoppertunitiesIndexNav extends React.Component {
  constructor(props) {
    super(props);
  }

  tabs(){
    const { tabList } = this.props.tabList;
    if (!!tabList)
      return {};

    const formatDisplayName = (s) => {
      if (typeof s !== 'string') return ''
      return (s.charAt(0).toUpperCase() + s.slice(1)).replace('_', ' ')
    }

    return this.props.tabList.reduce((reducer,tab) => {
      reducer[tab] = formatDisplayName(tab)
      return reducer;
    }, {});
  }

  counts() {
    const { allShops } = this.props;
    let counts = {}

    Object.keys(allShops).forEach((key) =>{
      counts[key] = allShops[key].count;
    })

    return counts
  }

  render() {
    return <NavigationTabsWrapper
            menuName='Opportunities Menu'
            tabs={this.tabs()}
            counts={this.counts()}
            order={this.props.tabList}
            activeTab={this.props.selectedPhase}
            onClickCallback={this.props.goToPagePhase}/>
  }
}
ShoppertunitiesIndexNav.displayName = 'Shoppertunities.IndexNav';