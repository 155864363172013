import React from 'react';
import PropTypes from 'prop-types'
import FloatingBox from '../FloatingBox'
import HumanValue from '../HumanValue'
import HumanDate from '../HumanDate'

export default class GoalInfo extends React.Component {
  render() {
    const {
      hovered,
      goalName,
      paid,
      paidDate,
      organic,
      organicDate
    } = this.props;

    const outerStyle = {
      position: 'relative',
      display: 'inline'
    };
    const tableStyle = {
      width: '100%'
    };
    const titleStyle = {
      paddingRight: '7px',
      textAlign: 'right',
      width: '30px'
    };
    const cellStyle = {
      border: '1px solid lightgrey',
      textAlign: 'left',
      padding: '5px'
    };

    
    if(!hovered) {
      return null;
    }

    return (
      <div style={outerStyle}>
        <FloatingBox hideIcon={true} type='info' width='350px'>
          <div>
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th></th>
                  <th>Views</th>
                  <th>Updated At</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={titleStyle}>
                    <strong>Organic</strong>
                  </td>
                  <td style={cellStyle}>
                    <HumanValue value={organic}/>
                  </td>
                  <td style={cellStyle}>
                    <HumanDate value={organicDate}/>
                  </td>
                </tr>
                <tr>
                  <td style={titleStyle}>
                    <strong>Paid</strong>
                  </td>
                  <td style={cellStyle}>
                    <HumanValue value={paid}/>
                  </td>
                  <td style={cellStyle}>
                    <HumanDate value={paidDate}/>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </FloatingBox>
      </div>
    );
  }
};

GoalInfo.displayName = 'FacebookAds.GoalInfo';
GoalInfo.propTypes = {
  goalName: PropTypes.string.isRequired,
  paid: PropTypes.number.isRequired,
  paidDate: PropTypes.string,
  organic: PropTypes.number.isRequired,
  organicDate: PropTypes.string,
  hovered: PropTypes.bool.isRequired
};
