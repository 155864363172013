import React from 'react';
import { connect } from 'react-redux';

class UsersChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      csrfToken: ''
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    try {
      const node = document.querySelector('meta[name=csrf-token]');
      this.setState({ csrfToken: node.attributes.content.value });
    } catch(e) {
      console.warn("Failed to loaded CSRF token!", e);
    }
  }

  render() {
    const {
      resetPasswordUrl,
      i18n,
      settings
    } = this.props;

    return <form method='POST' action={resetPasswordUrl} className='account-settings change-password'>
      <input type='hidden' name='authenticity_token' value={this.state.csrfToken} />
      <input type='hidden' name='user[email]' value={settings.email} />
      <button className='card clickable' type='submit'>
        <div className='form-lr'>
          <label>{i18n.update_password}</label>
          <i className='fas fa-chevron-right' />
        </div>
      </button>
    </form>;
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      i18n: state.meta.i18n,
      resetPasswordUrl: state.meta.resetPasswordUrl,
      settings: state.settings
    };
  }
};

UsersChangePassword.displayName = 'Users.ChangePassword';
export default connect(
  UsersChangePassword.mapStateToProps
)(UsersChangePassword);
