import React from 'react';
import { Provider } from "react-redux";
import AdminContentStore from '../redux/AdminContentStore'
import YoutubeEmbed from "../YoutubeEmbed";

class AdminYoutubeShortWrapper extends React.Component {
  render() {
    return <Provider store={AdminContentStore}>
      <>
        <YoutubeEmbed video_url={this.props.contentUrl} />
      </>
    </Provider>;
  }
};

AdminYoutubeShortWrapper.displayName = 'Contents.AdminYoutubeShortWrapper';
AdminYoutubeShortWrapper.propTypes = {};
export default AdminYoutubeShortWrapper;


