import React from 'react';

import MonetaryValue from './MonetaryValue'
import HumanValue from './HumanValue'
import NoteEditor from './NoteEditor'

export default class DatatableCell extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  render() {
    // Escape hatch to use a custom rendering output
    if(this.props.column.customRender) {
      return this.props.column.customRender(this.props.value);
    }

    switch(this.props.column.type) {
      case 'html':
        return <td dangerouslySetInnerHTML={{__html: this.props.value}}/>
      case 'money':
        return <MonetaryValue value={this.props.value}/>;
      case 'human':
        return <HumanValue value={this.props.value}/>;
      case 'note':
        return <NoteEditor value={this.props.value} callBack={this.props.column.callBack}/>;
      case 'string':
      case 'integer':
      default:
        return <td style={{width: this.props.column.width || ''}}>{this.props.value}</td>
    }
  }
}
