import React from 'react';
import { Provider } from "react-redux"
import BasicWrapperStore from '../redux/shared/BasicWrapperStore'
import InstructionNotesEdit from './Edit'


export default class InstructionNotesEditWrapper extends React.Component {
  render() {
    return (
      <Provider store={BasicWrapperStore}>
        <InstructionNotesEdit {...this.props}/>
      </Provider>
    );
  }
};
