import React from 'react';
export default class HumanValue extends React.Component {
  constructor(props) {
    super(props);

    this.asHumanValue = this.asHumanValue.bind(this);
    this.formatRemainder = this.formatRemainder.bind(this);
    this.render = this.render.bind(this);
  }

  render() {
    return (
      <span>{this.asHumanValue(this.props.value)}</span>
    );
  }

  asHumanValue(value) {
    const num = Number(value);

    if(num > 1000000000) {
      return this.formatRemainder(num, 1000000000, 'B');
    } else if(num > 1000000) {
      return this.formatRemainder(num, 1000000, 'M');
    } else if(num > 1000) {
      return this.formatRemainder(num, 1000, 'K');
    } else {
      return this.formatRemainder(num, 1, '');
    }
  }

  formatRemainder(numerator, denominator, suffix) {
    const value = numerator / denominator;

    return `${value.toFixed(1)}${suffix}`;
  }
}
