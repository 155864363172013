class window.AdminQuestions

  @index: ->
    ShopDashboard.setup()

    $('body').on 'click', '#save-question-btn', (e) ->
      $('.questioninput:visible').each (index, element) ->
        $(element).removeClass('alert-error')
        if $(element).val() == ""
          e.preventDefault()
          $(element).addClass('alert-error')

    $('.sortable').sortable(
      axis: 'y'
      update: ->
        $.post($(this).data('updateurl'), $(this).sortable('serialize'))
    )

  @edit: ->
    @form()

  @new: ->
    @form()

  @update: ->
    @form()

  @create: ->
    @form()

  @form: ->
    ShopDashboard.setup()

    $('#question_value').redactor
      focus: true
      linebreaks: true
      plugins: plugin_list

