import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash'
import SubmissionRequirementListItem from './RequirementListItem'

class SubmissionRequirements extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    }

    this.render = this.render.bind(this);
  }

  toggleOpenState() {
    this.setState({ open: !this.state.open }, () => {
      if ( document.body.clientWidth < 1025 ) {
        document.body.style.overflow = this.state.open ? 'hidden' : 'auto';
      }
    })
  }

  renderTitleBar(requirements) {
    const { validations, bloggerShopTask, readOnly } = this.props;
    const requirementCount = requirements.length;

    let icon = 'fa-check-circle';
    let text = `${requirementCount} Requirements`;
    let state = '';
    if(readOnly){
      let passedCount = _.countBy(bloggerShopTask.round_task.round_task_requirements, ['latest_validation_state.verification_status', 'passed' ]).true;
      let failedCount = _.countBy(bloggerShopTask.round_task.round_task_requirements, ['latest_validation_state.verification_status', 'failed' ]).true;
      if (passedCount === requirementCount || (passedCount, failedCount === 0) ) {
        state = 'passed'
        icon = 'fa-check-circle'
        text = `${passedCount} of ${requirementCount} Requirements verified.`;
      } else if ( failedCount > 0 ) {
        state = 'failed'
        icon = 'fa-times-circle'
        text = `${failedCount} of ${requirementCount} Requirements rejected.`;
      }
    } else {
      if (validations.length) {
        const passedCount = requirements.filter(requirement => _.some(validations, v => v.id == requirement.id && v.state == 'passed')).length;
        const failedCount = requirements.filter(requirement => _.some(validations, v => v.id == requirement.id && v.state == 'failed')).length;
        const manualCount = requirements.filter(requirement => _.get(requirement, 'verification_type[0].id') === 'manual').length;
        const rejectedCount = _.countBy(requirements, ['latest_validation_state.verification_status', 'failed']).true || 0;

        if ((passedCount + manualCount) === requirementCount ) {
          state = 'passed'
          icon = 'fa-check-circle'
          text = `${passedCount} of ${requirementCount} Requirements verified.`;
        } else if ( rejectedCount > 0 ) {
          state = 'failed'
          icon = 'fa-times-circle'
          text = `${rejectedCount} of ${requirementCount} Requirements rejected.`;
        } else if ( failedCount > 0 ) {
          state = 'failed'
          icon = 'fa-times-circle'
          text = `${failedCount} of ${requirementCount} Requirements not met.`;
        }
      }
    }

    return (
      <div className={`title-bar ${state}`} onClick={() => this.toggleOpenState()}>
        <div className='title-container'>
          <i className={`status fas ${icon}`}></i>
          <span className='title'>{text}</span>
          <i className='arrow fas fa-chevron-up'></i>
        </div>
      </div>
    )
  }

  renderRequirementList(requirements) {
    const { validations, readOnly, bloggerShopTask } = this.props;

    return (
      <div className='list-container'>
        <div className='list-description'>Please ensure the following requirements are met.</div>
        <div className='list-title'>Requirements:</div>
        <div className='list-times'>
          { requirements.map((requirement) => {
            let state = ''
            if(readOnly){
              if(Object.keys(requirement.latest_validation_state || {}).length){
                state = requirement.latest_validation_state.verification_status
              }
            } else {
              if (validations.length) {
                const validation = _.find(validations, v => v.id == requirement.id);
                if(validation) {
                  state = validation.state;
                }
              }
            }

            return <SubmissionRequirementListItem
              key={requirement.id}
              requirement={requirement}
              state={state}
              shoppertunityId={bloggerShopTask.shoppertunity_id}
              />;
          })
        }
        </div>
      </div>
    )
  }

  render() {
    const { open } = this.state;
    const { bloggerShopTask } = this.props;

    if ( bloggerShopTask && bloggerShopTask.round_task) {
      const { round_task_requirements } = bloggerShopTask.round_task;
      const openState =  open ? 'open' : 'closed'

      return (
        <div className={`submission requirements ${openState}`}>
          {this.renderTitleBar(round_task_requirements)}
          {this.renderRequirementList(round_task_requirements)}
        </div>
      );
    } else {
      return null;
    }
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      readOnly: state.readOnly,
      bloggerShopTask: state.bloggerShopTask,
      validations: state.validations
    };
  }
};

SubmissionRequirements.displayName = 'Submission.Requirements';
export default connect(
  SubmissionRequirements.mapStateToProps
)(SubmissionRequirements);
