import 'redactor.min.js'

class window.AdminShoppertunities
  @form: ->
    ShopDashboard.setup()
    @datefields()

    $('body').on 'change', '.round_allocation', (evt)=>
      $('.round_allocation option:selected').each ->
        conflicts = 0
        new_value = $(this).val()
        round_id = $(this).parents('.round_allocation').data('round')
        if (/instagram_total_(photo|story)_views/i).test(new_value)
          $('.round_allocation :selected').each ->
            if (/_and_/i).test($(this).val())
              conflicts++
            else if (/story/i).test(new_value) && (/photo/i.test($(this).val()))
              conflicts++
        if conflicts > 0
          $(".round_allocation_"+round_id+"--error").removeClass('hide help-block')
          $('#top-submit-btn, #submit-btn, #submit-close-btn').prop("disabled", true);
        else
          $(".round_allocation_"+round_id+"--error").addClass('hide')
          $('#top-submit-btn, #submit-btn, #submit-close-btn').prop("disabled", false);

    $('body').on 'click', '#top-submit-btn, #submit-btn', (evt)=>
      @_submit(evt, false)

    $('body').on 'click', '#submit-close-btn', (evt)=>
      @_submit(evt, true)

    $('#shoppertunity_mission').redactor
      plugins: window.plugin_list

    $('a.add_fields').click ->
      setTimeout( FormUtils.select_search, 1)

    # $('.js-datepicker').datetimepicker
    #   minView: 2,
    #   format: 'MM dd, yyyy'

    displayPayment = (row, round_id) ->
      task_id = row.find('option:selected').data('task-id')
      selected_task = $("#js-task-data > li[data-task-id='#{task_id}']")
      variable_pay = selected_task.data('task-variable-pay')
      open_bid = $(".js-open-bid-check[data-round-id=#{round_id}]").is(':checked')
      prepayment_task = ['Product Purchase', 'Event Attendance'].includes(selected_task.data('taskName'))
      if open_bid && !prepayment_task
        row.find('.js-variable-pay, .js-task-payment').addClass('is-hidden')
        row.find('.js-open-bid').removeClass('is-hidden')
        row.find('.js-task-payment input').prop('disabled', true)
      else if variable_pay
        row.find('.js-task-payment, .js-open-bid').addClass('is-hidden')
        row.find('.js-variable-pay').removeClass('is-hidden')
        row.find('.js-task-payment input').prop('disabled', true)
      else
        row.find('.js-variable-pay, .js-open-bid').addClass('is-hidden')
        row.find('.js-task-payment').removeClass('is-hidden')
        row.find('.js-task-payment input').prop('disabled', false).removeClass('disabled')

    $('.js-shop-payment-select').click ->
      if $(this).prop('value') == 'bid'
        $('.js-open-bid-view-value').prop('disabled', false)
      else
        $('.js-open-bid-view-value').prop('disabled', true)

    openBidFormChange = () ->
      $('.js-open-bid-check').on 'change', ->
        roundId = $(this).data('round-id')
        payment = $("#js-round-#{roundId}-final-payment")
        payment.html( if $(this).is(':checked') then 'Open Bid' else payment.data('paymentAmount') )
        $(".round-row[data-round-id=#{roundId}]").find('.js-task-row').each ->
          displayPayment( $(this), roundId )

    rounds = {}
    shop_names = {}

    loadInitialRounds = () ->
      if typeof $('#orig_conflicting_round_ids').val() != 'undefined'
        jQuery.parseJSON($('#orig_conflicting_round_ids').val()).forEach (round) ->
          shop_names[round.shop_id] = round.shop_title
          if !rounds.hasOwnProperty(round.shop_id)
            rounds[round.shop_id] = []
          rounds[round.shop_id].push({id: round.id, name: round.title})

    loadRounds = (shop_id) ->
      $.ajax
        url: '/admin/shoppertunities/' + shop_id + '/rounds'
        dataType: 'json'
        method: 'GET'
        success: (data) ->
          data.forEach ( round ) ->
            rounds[shop_id].push(round)
          buildRoundsList()

    buildRoundsList = () ->
      $('.rounds-list').html('')
      buildShop()
      persistRounds()

    buildShop = () ->
      for id, name of shop_names
        if rounds[id]?.length > 0
          $('.rounds-list').append(shopTitle(name))
          buildRounds(id)

    buildRounds = (shopID) ->
      rounds[shopID].forEach (v, k) ->
        $('.rounds-list').append(roundTitle(v))

    shopTitle = (title) ->
      "<h4>" + title + "</h4>"

    roundTitle = (round) ->
      "<p>" + round.name + " <a href='javascript:;' class='delete-round' data-round-id='" + round.id + "'><i class='fas fa-times-circle' aria-hidden='true'></i></a></p>"

    persistRounds = () ->
      tempArr = []
      for id, list of rounds
        for r in list
          tempArr.push(r.id)
      $('#conflicting_round_ids').val(tempArr.toString())

    $('.rounds-list').on 'click', '.delete-round', (e) ->
      rid = $(this).data('round-id')
      for id, list of rounds
        for r in list
          if r.id and r.id == rid
            list.splice list.indexOf(r), 1
            if list.length == 0
              delete rounds[id]
              delete shop_names[id]
            break
      buildRoundsList()

    platformTypeDeliverableValidation = () ->
      shopPlatform = $('#shoppertunity_platform_id')
      shopDeliverable = $('#shoppertunity_shop_deliverable_id')

      shopPlatform.change ->
        platformId = $(this).val()
        shopDeliverable.find('option').each ->
          $(this).prop('disabled', false);

        if (platformId && platformId.length > 0)
          shopDeliverable.find('option:not([platform='+platformId+'])').each ->
            $(this).prop('disabled', true);

      shopDeliverable.change ->
        shopPlatform.find('option').each ->
          $(this).prop('disabled', false);

        if ($(this).val() && $(this).val().length > 0)
          platformId = $(this).find(":selected")[0].attributes.platform.value
          shopPlatform.find('option:not([value='+platformId+'])').each ->
            $(this).prop('disabled', true);

    loadInitialRounds()
    buildRoundsList()
    openBidFormChange()
    platformTypeDeliverableValidation()



    if $('.js-task-row .date_picker').hasClass('disabled')
      $('.js-task-row .js-datepicker').datetimepicker('remove')

    # Default task description on select
    $('.js-tasks-list').on 'change', '.shoppertunity_rounds_round_tasks_name select', (e) ->
      select_box = $(@)
      row = select_box.closest('.js-task-row')
      id = row.data('input')

      task_id = select_box.find('option:selected').data('task-id')
      selected_task = $("#js-task-data > li[data-task-id='#{task_id}']")
      description = selected_task.data('task-description')
      payment = selected_task.data('task-payment')
      row.find('.js-task-payment input').val( Math.round(payment) )

      displayPayment(row, row.closest('.round-row').data('roundId'))

      target_modal = $('#' + "#{id}.js-modal-task-description")
      target_modal.find('.modal-title').html( selected_task.data('task-name') )
      target_modal.find('.modal-body').html( description )

    set_modal_data = ->
      count = 1
      $('.js-link-view-task-description').each ->
        data_value = 'task-' + count
        btn = $(@)
        target_modal = btn.closest('.js-task-description').next('.js-modal-task-description')
        row = btn.closest('.js-task-row')

        btn.attr('data-target', '#' + data_value)
        target_modal.attr('id', data_value)
        row.attr('data-input', data_value)

        count++

    set_modal_data()


  @_submit: (evt, redirect)=>
    icon = $('<i class="fas fa-spinner fa-spin"></i>')
    $(evt.target).addClass('disabled')
    $(evt.target).html(icon)
    @process_postal_codes()
    $('#shoppertunity_redirect_on_save').val(redirect)

  @edit: ->
    @form()

  @new: ->
    $('#clone_shop_form').on 'change', (e) ->
      selected_shop_id = $('#shoppertunity_id').val()
      new_url = "/admin/opportunities/" + selected_shop_id + "/clone"
      $('#clone_shop_form').attr('action', new_url)

    $('body').on 'click', '#start_fresh', (e)->
      $('#new_shop_select').addClass('hidden')
      $('#shoppertunity_form').removeClass('hidden')
    @form()

  @clone: ->
    @form()

  @update: ->
    @form()

  @create: ->
    @form()

  @index: ->
    DataTableUtils.datatable($('.datatable.planning'),
      bServerSide: true
      bStateSave: true
      iCookieDuration: 300
      sScrollX: '100%'
    )

    DataTableUtils.datatable($('.datatable.staffing'),
      bServerSide: true
      bStateSave: true
      sScrollX: '100%'
      aoColumns: [
        null,
        null,
        { sWidth: '10%' },
        { bSortable: false },
        { bSortable: false },
        { bSortable: false },
        { bSortable: false },
        { bSortable: false },
        { bSortable: false },
        { bSortable: false },
        { bSortable: false }
      ]
    )

    DataTableUtils.datatable($('.datatable.executing'),
      bServerSide: true
      bStateSave: true
      iCookieDuration: 300
      sScrollX: '100%'
    )

    DataTableUtils.datatable($('.datatable.closed'),
      bServerSide: true
      bStateSave: true
      iCookieDuration: 300
      sScrollX: '100%'
    )

  @show: ->
    ShopDashboard.setup()

  @datefields: ->
    new ValidateDate.DateField( '#submit-btn', ['shoppertunity_cut_off_date', 'shoppertunity_end_date'] ).bind_values()

  @admin_panel: ->
    ShopDashboard.setup()
    $('body').on 'click', '#cancel_shop_btn', (e) ->
      e.preventDefault()
      if reason = prompt('Are you sure? \n' +
                         'Please provide a reason for canceling this opportunity. \n' +
                         'This reason will be sent to all users involved with this opportunity.')
        $.post '/admin/opportunities/' + $(this).data('id') + '/cancel?message=' + reason, (data) ->
          if data.status == 200
            $('#cancel_shop_btn').hide()
            $('#cancel_error').text('The opportunity was successfully canceled.')
                              .show()
          else
            $('#cancel_error').text('This opportunity is unable to be canceled at this time.')
                              .show()
      false

  @process_postal_codes: ->
    d = $('input.postal-code-tokens').attr('tokens').split(' ').diff $('input.postal-code-tokens').tokenizer('get')
    a = $('input.postal-code-tokens').tokenizer('get').diff $('input.postal-code-tokens').attr('tokens').split(' ')
    $('#shoppertunity_postal_codes_delete').val(d)
    $('#shoppertunity_postal_codes_add').val(a)

$ ->

  Array.prototype.diff = (a)->
      @filter (i)->
        a.indexOf(i) < 0

  if $('input.postal-code-tokens').length

    $('input.postal-code-tokens').tokenizer
      label: ''
      separators: [',', ' ']

    $('#shoppertunity_postal_codes_all').val($('input.postal-code-tokens').attr('tokens').split(' '))

    $('input.postal-code-tokens').attr('tokens').split(' ').map (token)->
      $('input.postal-code-tokens').tokenizer('push', token)

    $('#add_zipcode_tokens').click (e) ->
      e.preventDefault()

  $('#import_panel .expand').show()
  $('#import_panel .collapse').hide()

  $('#collapse_import').on 'hidden.bs.collapse', ->
    $('#import_panel .collapse').hide()
    $('#import_panel .expand').show()
    $('#import_panel').attr('data-original-title', 'click to expand')

  $('#collapse_import').on 'shown.bs.collapse', ->
    $('#import_panel .expand').hide()
    $('#import_panel .collapse').show()
    $('#import_panel').attr('data-original-title', 'click to collapse')

  $('.tab-content').on
    mouseenter: ->
      $(this).children('div.description').fadeIn 500
    mouseleave: ->
      $(this).children('div.description').fadeOut 'fast'
  , '.shops-card .thumbnail'

  return