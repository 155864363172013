const dropDownStyles = `
  .aux-dropdown {
    position: absolute;
    width: 190px;
    opacity: 0;
    z-index: 2;
    background: #f8f8f8;
    border-top: 2px solid #0084a5;

    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 2rem;
    padding: 0.5rem;
    list-style-type: none;
    top: 20px;

    box-shadow: rgba(2, 8, 20, 0.1) 0px 0.175em 0.5em;
    transform: translateX(-40%);

    transition: all .15s ease-out;
    visibility: hidden;
  }
  .left-drop {
    transform: translateX(25%);
  }
  .contains-dropdown:focus-within .aux-dropdown {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }
  .aux-dropdown-item span {
    width: 100%;
    height: 100%;
    size: 0.7rem;
    padding-left: 10px;
    font-weight: bold;
    cursor: pointer
  }
  .topbar-item {
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background: white;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    margin-left: 10px;
  }
`;
 export default dropDownStyles;