class window.Shoppertunities

  @application: ->
    Utils.autolink('.a-val')

  @instructions: ->
    $('body').on 'click', '.instructions_btn_group .btn', ->
      $('.instructions_btn_group .btn').addClass('disabled')

    $('body').on 'click', '#decline-instructions', (e) ->
      $('.btn').addClass('disabled')
      if note = prompt('Please explain why you are declining these instructions.').trim()
        NoteUtils.create( 'Shoppertunity', $(this).data('shop'), 'Instructions Declined: ' + note )
      else
        $('.btn').removeClass('disabled')
        false

    $('#left_instruct_nav').affix
      offset:
        top: ->
          return (@top = $('#back_to_top_anchor').outerHeight(true) + $('.full-width-page-title--wrapper').outerHeight(true) + 20)
        bottom: ->
          return (@bottom = $('#footer').outerHeight(true) + 20)

    @smooth_scroll_to()

  @show: ->
    userCreds = {}
    $modal = $('#fb_page_selection.modal')
    fbReachMin = $('#fb-reach-min').val()
    shopID = $('#current-shop-id').val()
    localStorageItemString = 'shop' + shopID + 'storedcheckboxes'

    $ ->
      restoreCheckboxStates()
      if $('#round_card_selection').data('selectSingleRound')
        round_card = $('.js_round_checkbox').first()
        round_card.prop('checked', true)
        selectRound(round_card)

    $('#affixed_cta_header').affix
      offset:
        top: 112

    # this keeps layout from shifting after affixed element changes to fixed
    $('#affixed_cta_header').on 'affix.bs.affix', ->
      $('.main-container').css('padding-top', '85px')
    $('#affixed_cta_header').on 'affix-top.bs.affix', ->
      $('.main-container').css('padding-top', '0')

    $('.js_round_checkbox').change ->
      selectRound($(this))

    $('#connect-fb-page').on 'click', ->
      AuthenticationUtils.connect_facebook_page(localStorageItemString)

    $('#connect-instagram-page').on 'click', ->
      AuthenticationUtils.connect_instagram_page(localStorageItemString)

    selectRound = (round_card) ->
      $(round_card).closest('.js_round_card').toggleClass('round_card--checked_js')
      checkbox_label = if $(round_card).prop('checked') then 'Round Selected' else 'Select this round'
      round_id  = $(round_card).attr('id')
      $("label[for='#{round_id}'] .js_checkbox_label_text").text(checkbox_label)
      noticesToDisplay()

    noticesToDisplay = () ->
      if $('.js_round_checkbox:checked').size() > 0
        selected_round_count = $('.js_round_checkbox:checked').size()
        if $(".round_card--checked_js[data-instagram]").size() && $('#instagram-requirements-met').val() == 'false'
          $('.require_instagram_notice_js').removeClass('is-hidden')
          $('.require_facebook_notice_js').addClass('is-hidden')
          $('.round_not_selected').addClass('is-hidden')
          $('.round_is_selected').addClass('is-hidden')
        else if $(".round_card--checked_js[data-facebook]").size() && $('#facebook-requirements-met').val() == 'false'
          $('.require_instagram_notice_js').addClass('is-hidden')
          $('.require_facebook_notice_js').removeClass('is-hidden')
          $('.round_not_selected').addClass('is-hidden')
          $('.round_is_selected').addClass('is-hidden')
        else
          $('.round_is_selected').removeClass('is-hidden')
          $('.require_instagram_notice_js').addClass('is-hidden')
          $('.require_facebook_notice_js').addClass('is-hidden')
          $('#affixed_cta_header').addClass('round_checkbox_checked_js')
          $('.js_selected_round_count').html(selected_round_count)
          total_round_count = $('.js_total_round_count').data('total-round-count')
          if selected_round_count == total_round_count then $('.js_select_more_msg').addClass('is-hidden') else $('.js_select_more_msg').removeClass('is-hidden')
      else
        $('.require_instagram_notice_js').addClass('is-hidden')
        $('.require_facebook_notice_js').addClass('is-hidden')
        $('.round_not_selected').removeClass('is-hidden')
        $('#affixed_cta_header').removeClass('round_checkbox_checked_js')

    storeCheckboxState = () ->
      checkedBoxes = []
      for checkbox in $('.js_round_checkbox:checked')
        checkedBoxes.push($(checkbox).val())

      window.localStorage.setItem(localStorageItemString, checkedBoxes)

    restoreCheckboxStates = () ->
      if (window.localStorage.getItem(localStorageItemString) == null) then return
      for roundID in window.localStorage.getItem(localStorageItemString).split(',')
        $('#round_' + roundID).prop('checked', true).trigger('change')

      window.localStorage.removeItem(localStorageItemString)

    @smooth_scroll_to()

  @tasks: ->
    that = this

    window.localStorage.setItem('youtube_auth', false)
    window.addEventListener 'storage', ->
      if window.localStorage.getItem('youtube_auth') == 'true'
        window.location.reload()

    $('.task-desc').on "show.bs.collapse", (e) ->
      icon = $(this).siblings('a').find('i')
      change_icon_klass( icon )

    $('.task-desc').on "hide.bs.collapse", (e) ->
      icon = $(this).siblings('a').find('i')
      change_icon_klass( icon )

    change_icon_klass = ($icon) ->
      $icon.toggleClass("fa-caret-up fa-caret-down")

    $('.reason-form').on "ajax:complete", (e) ->
      $(this).find('.btn-primary').hide()
      $(this).find('.edit-reason').show()
      $(this).find('textarea').attr('disabled', true)

    $('.reason-form').on "click", ".edit-reason", (e) ->
      $form = $(e.delegateTarget)
      $form.find('.btn-primary').show()
      $form.find('.edit-reason').hide()
      $form.find('textarea').attr('disabled', false)
      false

    $('.connect-instagram-page').on 'click', ->
      AuthenticationUtils.connect_instagram_page(null)

    $('.connect-youtube').on 'click', ->
      AuthenticationUtils.connect_youtube()

    $('.state-form').on "click", "a", (e) ->
      $(e.delegateTarget).submit()

    @show_survey()

  @show_survey: ->
    survey_key = $('#shoppertunity_survey_modal').data('survey-key')
    if window.localStorage.getItem(survey_key) != 'shown' && $('#shoppertunity_survey_modal')
      $('#shoppertunity_survey_modal').modal('show')
      window.localStorage.setItem(survey_key, 'shown')

  @card_effects = ($elem) ->
    shop_hero = $elem.find('.shop-hero')
    shop_card = $elem.find('.shop-card')
    min_height = shop_hero.outerHeight() - $elem.find('.shop-preview').outerHeight()
    shop_hero.css('min-height', min_height)
             .css('height', shop_hero.height())
    shop_card.css('height', shop_card.outerHeight())
    $elem.find('.shop-details').css('position', 'absolute')

    shop_card.on 'click', ->
      window.location = $(this).data('url')


  @smooth_scroll_to: ->
    # courtesy of css-tricks | https://css-tricks.com/snippets/jquery/smooth-scrolling/
    $('a[href*="#"]:not([href="#"])').click ->
      if location.pathname.replace(/^\//, '') == @pathname.replace(/^\//, '') and location.hostname == @hostname
        target = $(@hash)
        target = if target.length then target else $('[name=' + @hash.slice(1) + ']')
        if target.length
          $('html, body').animate { scrollTop: target.offset().top }, 1000
          return false
