import React from 'react';
import PropTypes from 'prop-types'
import { Provider } from "react-redux"
import ProfileStore from '../redux/ProfileStore';
import ProfilesShow from './Show'

export default class ProfilesShowWrapper extends React.Component {
  render() {
    return (
      <Provider store={ProfileStore}>
        <ProfilesShow {...this.props} />
      </Provider>
    );
  }
};

ProfilesShowWrapper.displayName = 'Profiles.ShowWrapper';
ProfilesShowWrapper.propTypes = {
  proxyUrl: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
  masquerade: PropTypes.string
};