import React from 'react';
const RoundTasks = RoundTasks || {}

RoundTasks.RequirementTemplateFields = {
  id: {
    name: 'id',
    type: 'hidden',
    value: null,
    error: false,
    disabled: false
  },
  description: {
    name: 'description',
    label: 'Description',
    type: 'textArea',
    required: true,
    placeholder: 'Enter Description',
    colWidth: 3,
    error: false,
    value: null,
    disabled: false
  },
  verification_type: {
    name: 'verification_type',
    label: 'Verification',
    type: 'select',
    required: true,
    multiSelect: false,
    options: [
      {id: 'keyword', name: 'Keyword'},
      {id: 'manual', name: 'Manual'}
    ],
    colWidth: 2,
    error: false,
    value: null,
    disabled: false
  },
  verification_text: {
    name: 'verification_text',
    label: 'Verification Value',
    type: 'textArea',
    required: true,
    placeholder: 'Enter Value',
    colWidth: 3,
    error: false,
    value: null,
    disabled: false
  },
  repeat_type: {
    name: 'repeat_type',
    label: 'Frequency',
    type: 'select',
    required: true,
    multiSelect: false,
    options: [
      {id:'equal to', name: 'Equal to'},
      {id:'more than', name: 'More than'},
      {id:'less than', name: 'Less than'}
    ],
    colWidth: 2,
    error: false,
    value: null,
    disabled: false
  },
  repeat_count: {
    name: 'repeat_count',
    label: '#',
    type: 'number',
    required: true,
    colWidth: 1,
    min: 0,
    error: false,
    value: null,
    disabled: false
  } ,
  delete: {
    name: 'delete',
    type: 'delete',
    colWidth: 1,
    value: null,
    error: false,
    disabled: false
  },
  _destroy: {
    name: '_destroy',
    type: 'hidden',
    value: false,
    error: false,
    disabled: false
  }
};