import React from 'react';

import InfluencerDashboardContentTypes from './ContentTypes'
import InfluencerDashboardContentSummaryTopPerformersRow from './SummaryTopPerformersRow'

export default class InfluencerDashboardContentSummaryTopPerformers extends React.Component {
  render() {
    const {
      views,
      engagements
    } = this.props;

    return (
      <div className='card content-summary-card top-performers'>
        <h3>Top Performers</h3>
        { views &&
          <InfluencerDashboardContentSummaryTopPerformersRow
            metric={'Views'}
            taskName={InfluencerDashboardContentTypes[views.type]}
            shopName={views.shop_name}
            shopId={views.shop_id}
            value={views.value}
          />
        }
        { engagements &&
          <InfluencerDashboardContentSummaryTopPerformersRow
            metric={'Engagements'}
            taskName={InfluencerDashboardContentTypes[engagements.type]}
            shopName={engagements.shop_name}
            shopId={engagements.shop_id}
            value={engagements.value}
            badgeColor={'secondary'}
          />
        }
      </div>
    );
  }
};

InfluencerDashboardContentSummaryTopPerformers.displayName = 'InfluencerDashboard.ContentSummary.TopPerformers';