import React from 'react';

export const UserSignUpBasicInfoFields = {
  dob: {
    label: 'Date of Birth',
    regex: /^((0|1)\d{1})\/((0|1|2|3)\d{1})\/((19|20)\d{2})/g,
    regexMessage: 'Date of birth must be in the format MM/DD/YYYY',
    placeholder: 'MM/DD/YYYY',
    required: true,
    className: 'input_wrap',
    fieldType: 'text',
    ageRequirement: 18,
    ageRequirementMessage: 'Sorry, you must be 18 or older.'
  },
  firstName: {
    label: 'First Name',
    required: true,
    className: 'input_wrap',
    fieldType: 'text'
  },
  lastName: {
    label: 'Last Name',
    required: true,
    className: 'input_wrap',
    fieldType: 'text'
  },
  email: {
    label: 'Email',
    required: true,
    className: 'input_wrap',
    fieldType: 'email',
    regex: /[^@]+@[^\.]+\..+/g,
    regexMessage: 'Must be a valid email',
  },
  phoneNumber: {
    label: 'Phone Number',
    regex: /^\d\d\d\d\d\d\d\d\d\d$/,
    regexMessage: 'Must be a valid 10 digit phone number',
    required: true,
    className: 'input_wrap',
    fieldType: 'tel',
  },
  address1: {
    label: 'Address',
    fieldType: 'text',
    className: 'input_wrap',
    required: true
  },
  address2: {
    label: 'Address (line 2)',
    fieldType: 'text',
    className: 'input_wrap',
    required: false
  },
  city: {
    label: 'City',
    fieldType: 'text',
    className: 'input_wrap',
    required: true
  },
  country: {
    id: 'country_select',
    label: 'Country',
    required: true,
    fieldType: 'select',
    className: 'input_wrap',
    resetFields: ['state', 'province', 'usZip', 'caZip'],
    options: [
      'United States',
      'Canada'
    ]
  },
  province: {
    id: 'state_select',
    required: true,
    label: 'Province',
    parentField: 'country',
    fieldType: 'select',
    valueName: 'state',
    linkedField: 'state',
    className: 'input_wrap',
    options: [
      'Alberta',
      'British Columbia',
      'Manitoba',
      'New Brunswick',
      'Newfoundland',
      'Northwest Territories',
      'Nova Scotia',
      'Nunavut',
      'Ontario',
      'Prince Edward Island',
      'Quebec',
      'Saskatchewan',
      'Yukon Territory',
    ]
  },
  state: {
    id: 'state_select',
    required: true,
    label: 'State',
    parentField: 'country',
    valueName: 'state',
    linkedField: 'province',
    fieldType: 'select',
    className: 'input_wrap',
    options: [
      'Alabama',
      'Alaska',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'District of Columbia',
      'Florida',
      'Georgia',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Pennsylvania',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
    ]
  },
  usZip: {
    label: 'Zipcode',
    fieldType: 'text',
    className: 'input_wrap',
    valueName: 'zip',
    linkedField: 'caZip',
    parentField: 'country',
    regex: /(^\d{5}$)|(^\d{5}-\d{4}$)/g,
    regexMessage: 'Must be a valid 5 digit US zipcode',
    required: true
  },
  caZip: {
    label: 'Zipcode',
    fieldType: 'text',
    className: 'input_wrap',
    valueName: 'zip',
    linkedField: 'usZip',
    parentField: 'country',
    regex: /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][ -][0-9][ABCEGHJ-NPRSTV-Z][0-9]$/i,
    regexMessage: 'Must be a valid CA Zipcode (K1A-0B1)',
    required: true
  }
}