import React from 'react';

export default class InstructionsReadOnlyCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTitle: this.props.showTitle || true
    }

    this.render = this.render.bind(this);
  }

  render() {
    return (
      <div className='instructions read-only instruction_card'>
        <div className='col-md-12 card_title'>
          {this.state.showTitle && this.props.title}
        </div>
        {this.props.children}
      </div>
    );
  }
}

InstructionsReadOnlyCard.displayName = 'Instructions.ReadOnly.Card';
