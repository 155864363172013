import React from 'react';

class InstagramStoryCard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      initial_reject_state: this.props.story.rejected,
      fallback_total_views: this.props.story.fallback_total_views || 0,
      refreshing: false
    }

    this.render = this.render.bind(this);
    this.toggleRejected = this.toggleRejected.bind(this);
    this.updateRejectedReason = this.updateRejectedReason.bind(this);
    this.rejectButton = this.rejectButton.bind(this);
    this.fallbackTotalViews = this.fallbackTotalViews.bind(this);
    this.updateFallbackTotalViews = this.updateFallbackTotalViews.bind(this);
    this.refresh = this.refresh.bind(this);

    this.debounceUpdate = _.debounce(this.updateRejectedReason, 500);
  }

  toggleRejected() {
    var newStory = {
      ...this.props.story,
      rejected: !this.props.story.rejected,
      state: !this.props.story.rejected ? 'rejected' : 'pending'
    }
    this.props.updateStory(newStory);
  }

  updateRejectedReason(reason) {
    var newStory = {
      ...this.props.story,
      reject_reason: reason
    }
    this.props.updateStory(newStory);
  }

  updateFallbackTotalViews(views) {
      var newStory = {
        ...this.props.story,
        fallback_total_views: views
      }
      this.props.updateStory(newStory)
  }

  refresh(event) {
    event.preventDefault();
    const {id} = this.props.story;
    if (this.state.refreshing) {return;}
    this.setState({refreshing: true});
    this.props.refreshStory(id, ()=>{
      this.setState({refreshing: false})
    })
  }

  render() {
    const story = this.props.story;
    var rejectedStatus = story.rejected

    return (
      <div className="story_card">
        {this.renderRefreshButton()}
        <div className="story_content">
          { rejectedStatus &&
            <i className="fa fa-times"/>
          }
          { story.media_type.includes('IMAGE') &&
            <img src={story.media_url} />
          }
          { story.media_type.includes('VIDEO') &&
            <video src={story.media_url} controls/>
          }
        </div>
        {this.rejectButton()}
      </div>
    )
  }

  renderRefreshButton() {
    const {fallback_attachment} = this.props.story;
    const {refreshing} = this.state;
    if (!!fallback_attachment || !this.props.admin) {
      return null;
    }
    const iconClass = `refresh-icon fas fa-sync ${refreshing ? 'fa-spin' : ''}`
    return (
      <a href="#" role="button" className="refresh-button" title="Refresh" disabled={refreshing} onClick={this.refresh}>
        <i className={iconClass}></i>
      </a>
    )
  }

  fallbackTotalViews() {
    const story = this.props.story;

    let inputStyle = {
      verticalAlign: 'bottom',
      height: '34px',
      margin: '5px 5px 0px 10px',
      padding: '10px',
      textAlign: 'center'
    }

    if(this.props.admin){
      if (story.fallback_attachment){
        return (
            <div className='story_media_content_inputs'>
              <input
                type="text"
                id={`total_views_story_content_${story.id}`}
                onChange={(e) => this.updateFallbackTotalViews(e.target.value)}
                style={inputStyle}
                placeholder="View Count"
                defaultValue={story.fallback_total_views}
              />
            </div>
        );
      }
      return
    }
  }

  rejectButton() {
    const story = this.props.story;
    var rejectedStatus = story.rejected;
    var rejectedBtn = rejectedStatus ? 'rejected' : ''
    var lastUpdateDate = new Date(story.updated_at).toLocaleString();

    if(this.props.admin){
      return (
        <div>
          {story.fallback_attachment && this.fallbackTotalViews()}
          <input type="checkbox" id={`reject_story_content_${story.id}`} className='hidden' value={rejectedStatus} />
          <label htmlFor={`reject_story_content_${story.id}`}
                  className={`btn btn-default ${rejectedBtn}`}
                  onClick={this.toggleRejected} >
            Reject
          </label>
          {rejectedStatus &&
            <label htmlFor={`reject_story_content_reason_${story.id}`}
                  className="story_rejection_reason" >
              Rejection Reason:
              <textarea id={`reject_story_content_reason_${story.id}`}
                        rows='3'
                        defaultValue={story.reject_reason}
                        onChange={(e) => this.debounceUpdate(e.target.value)}/>
            </label>
          }
          { this.state.initial_reject_state &&
              <div className="story_card-reject_date" >
                <b>Rejected on:</b>&nbsp;
                {lastUpdateDate}
              </div>
          }
        </div>
      )
    } else {
      return (
        <div>
          {rejectedStatus &&
            <label htmlFor={`reject_story_content_reason_${story.id}`}
                  className="story_rejection_reason" >
              Rejection Note:
              <textarea id={`reject_story_content_reason_${story.id}`}
                        rows='3'
                        defaultValue={story.reject_reason}
                        disabled={true} />
            </label>
          }
          { this.state.initial_reject_state &&
            <div className="story_card-reject_date" >
              <b>Rejected on:</b>&nbsp;
              {lastUpdateDate}
            </div>
        }
      </div>
      )
    }
  }
}
export default InstagramStoryCard