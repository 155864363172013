import React from 'react'
import { connect } from 'react-redux';
import SubmissionStateIndicator from './StateIndicator'

class SubmissionTiktokVideoContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: false
    }

    this.video = React.createRef();

    this.validationState = this.validationState.bind(this);
    this.setPlayingState = this.setPlayingState.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
    this.render = this.render.bind(this);
  }

  render() {
    const { post, selected, key, validations } = this.props;
    const isSelected = selected.some(content => content.tiktok_id === post.tiktok_id)
    const state = this.validationState();
    return (
      <div key={key}
          className={`submission grid-item tiktok-video ${state} ${isSelected ? 'selected' : ''}`}
          onClick={(e) => this.clickHandler(e, isSelected)}>
        <div className='item-image' style={{backgroundImage: `url(${post.image_url})`}}>
          <SubmissionStateIndicator state={state} isSelected={isSelected} isVideo={true} setPlayingState={this.setPlayingState}/>
        </div>
      </div>
    )
  }


  validationState() {
    const { playing } = this.state;
    const { validations } = this.props;

    if ( validations && validations.length ) {
      if ( _.some(validations, {state: 'failed'}) ) {
        return 'failed';
      } else {
        return 'passed';
      }
    } else {
      if (playing) {
        return 'playing';
      } else {
        return 'pending';
      }
    }
  }

  setPlayingState(e) {
    e.preventDefault();
    e.stopPropagation();

    const embedContent = <div className='tiktok-content-embed' dangerouslySetInnerHTML={{__html: this.props.post.embed_html }} />
    this.props.openContentModal(embedContent)
  }

  clickHandler(e, isSelected) {
    e.preventDefault();
    const { post } = this.props;

    if ( isSelected ) {
      this.props.deselect(post);
      this.props.resetSubmission();
    } else {
      this.props.select(post);
      this.props.updateSubmission({ url: post.url, description: post.description, image_url: post.image_url });
      this.props.validate();
    }
    this.props.resetValidations();
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      selected: state.selected,
      validations: state.validations
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      select: post => dispatch({ type: 'SELECT_SINGLE_CONTENT', value: post }),
      deselect: post => dispatch({ type: 'DESELECT_CONTENT', value: post }),
      resetSubmission: () => dispatch({ type: 'RESET_SUBMISSION' }),
      updateSubmission: params => dispatch({ type: 'UPDATE_SUBMISSION', value: params }),
      resetValidations: () => dispatch({ type: 'RESET_VALIDATIONS' }),
    };
  }
};

SubmissionTiktokVideoContent.displayName = 'Submission.TiktokVideoContent';
export default connect(
  SubmissionTiktokVideoContent.mapStateToProps,
  SubmissionTiktokVideoContent.mapDispatchToProps
)(SubmissionTiktokVideoContent);
