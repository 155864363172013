class window.ShopDashboard

  @setup: ->
    that = this
    NoteUtils.noteable()

    $('body').on 'click', '.shop_state_links a', (e) ->
      if $(this).attr('id') == 'reject-link'

        if message = prompt('Please provide a reason for your rejection.')
          id = $(this).data('id')
          NoteUtils.create( 'Shoppertunity', id, 'Posting Rejected: ' + message )
          NoteUtils.refresh_notes( '#shop-notes', 'Shoppertunity', id )
        else
          false
