import React from 'react';

export default class Instruction extends React.Component {
  render(){
    const {activity, round_name} = this.props.notification;
    const P = React.Fragment;
    switch(activity) {
      case 'approve':
        return (
          <P>Instructions for <b>{round_name}</b> have been approved.</P>
        )
      case 'ready':
        return (
          <P>Instructions for <b>{round_name}</b> have been marked for review.</P>
        )
      case 'reject':
        return (
          <P>Instructions for <b>{round_name}</b> have been rejected.</P>
        )
      default:
        return null;
    }
  }
}
Instruction.displayName = 'Notification.Templates.Instruction'