import React from 'react';
import PropTypes from 'prop-types'
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';
import { plugins } from 'prismjs';
// import ChartJS from '../../ChartJS'
export default class InfluencerDashboardContentSummaryContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphHeight: ''
    };
  }

  componentDidMount() {

    Chart.register(
      ArcElement,
      LineElement,
      BarElement,
      PointElement,
      BarController,
      BubbleController,
      DoughnutController,
      LineController,
      PieController,
      PolarAreaController,
      RadarController,
      ScatterController,
      CategoryScale,
      LinearScale,
      LogarithmicScale,
      RadialLinearScale,
      TimeScale,
      TimeSeriesScale,
      Decimation,
      Filler,
      Legend,
      Title,
      Tooltip,
      SubTitle
    );

    let ctx = document.getElementById('js_content_summary_graph');
    let dataIn = this.props.graphData;
    let labels = Object.keys(dataIn);
    let values = Object.values(dataIn);
    var height = document.getElementById('js_content_summary').clientHeight;

    this.setState({
      height: height
    });

    let data = {
      labels: labels,
      datasets: [{
        data: values,
        pointRadius: 1,
        lineTension: 0.4,
        backgroundColor: '#CCE5FF',
        borderColor: '#4E76E3',
        borderWidth: 1,
        spanGaps: true
      }]
    }

    let options = {
      maintainAspectRatio: false,
      plugins:{
        legend: {
          display: false
        },
      },
      scales: {
        xAxis: {
          grid: {
            display: false
          },
          ticks: {
            callback: function(index, array) {

              return (index % 2) ? '' : labels[index];
            }
          }
        },
        yAxis: {
          grid:{
            display: false
          }
        }
      },
    }

    let myChart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: options
    });
  }

  render() {
    return (
      <div className='card content-summary-card content'>
        <div className='flex-container'>
          <h3 className='text-size-x-large'>Your Content</h3>
          <span className='total-posts'>{this.props.totalCount} Posts</span>
        </div>
        <div className='chart-container mt-10' height={this.state.height}>
          <canvas id='js_content_summary_graph' style={{height: "100%"}}></canvas>
        </div>
      </div>
    );
  }
};

InfluencerDashboardContentSummaryContent.displayName = 'InfluencerDashboard.ContentSummary.Content';
InfluencerDashboardContentSummaryContent.propTypes = {
  totalCount: PropTypes.number.isRequired,
  graphData: PropTypes.object.isRequired
};