import React from 'react';
import HeaderBaseMenu from './BaseMenu';
import UserCommunitySelection from '../communities/UserCommunitySelection';
import HeaderAdminMenu from './AdminMenu';

function HeaderDropdownItem(
  {
    leftIcon = <HeaderIcon name='bars' />,
    rightIcon = <HeaderIcon name='chevron-right' />,
    link = '/opportunities',
    children
  }) {
  return (
    <a href={link} className="header-menu-item">
      <span className="icon-button">{leftIcon}</span>
      {children}
      <span className="pull-right icon-right">{rightIcon}</span>
    </a>
    );
}

function HeaderIcon(icon) {
  return(
    <i className={`fas fa-${icon.name}`} />
  )
}

export default class HeaderDropDownElements extends React.Component{
  render(){
    const { community_admin,
            payment_access,
            user_id,
            user_communities,
            communities_proxy_url,
            community_redirect_url,
            current_community,
            support_link,
            knowledgebase_link,
            notification_count,
            messages_count,
            allow_groups } = this.props;
    return(
      <div className='container-fluid'>
        <HeaderBaseMenu {...this.props} />
        <UserCommunitySelection currentCommunity={current_community}
                                            currentUserId={user_id}
                                            proxyUrl={communities_proxy_url}
                                            redirectUrl={community_redirect_url}/>
        <div className="row full-width">
          <a className="js_show_search_bar nav-action-icon header-menu-item" href="#">
            <span className="icon-button"><i className="fas fa-fw fa-search" /></span>Search
            <span className="pull-right icon-right"><i className="fas fa-chevron-right" /></span>
          </a>

          <HeaderDropdownItem leftIcon={<HeaderIcon name='chart-bar' />}
                        rightIcon={<HeaderIcon name='chevron-right' />}
                        link='/dashboard'>
            Dashboard
          </HeaderDropdownItem>
          <HeaderDropdownItem leftIcon={<HeaderIcon name='list' />}
                        rightIcon={<HeaderIcon name='chevron-right' />}
                        link='/opportunities'>
            Opportunities
          </HeaderDropdownItem>
          <HeaderDropdownItem leftIcon={<HeaderIcon name='envelope' />}
                        rightIcon={<HeaderIcon name='chevron-right' />}
                        link='/conversations'>
            Messages
            { messages_count > 0 &&  <span className="notification_badge pull-right icon-right">{messages_count}</span>}

          </HeaderDropdownItem>
          <HeaderDropdownItem leftIcon={<HeaderIcon name='bell' />}
                        rightIcon={<HeaderIcon name='chevron-right' />}
                        link='/notifications'>
            Notifications
            {notification_count > 0 && <div className='pull-right icon-right'>{this.props.renderNotificationCount(notification_count)}</div> }
          </HeaderDropdownItem>
          {
            allow_groups &&
            <HeaderDropdownItem leftIcon={<HeaderIcon name='comments' />}
              rightIcon={<HeaderIcon name='chevron-right' />}
              link='/groups'
              >
                Groups
            </HeaderDropdownItem>
          }
          {this.props.show_knowledgebase_link &&
            <HeaderDropdownItem leftIcon={<HeaderIcon name='share-square' />}
                          rightIcon={<HeaderIcon name='chevron-right' />}
                          link={knowledgebase_link}>
              Knowledgebase
            </HeaderDropdownItem>
          }
          <HeaderDropdownItem leftIcon={<HeaderIcon name='info' />}
                        rightIcon={<HeaderIcon name='chevron-right' />}
                        link={support_link}>
            File a Support Ticket
          </HeaderDropdownItem>
        </div>
        { (community_admin || payment_access) && <HeaderAdminMenu {...this.props} /> }
      </div>
    )
  }
};

HeaderDropdownItem.displayName = 'Header.DropdownItem';
HeaderIcon.displayName = 'Header.Icon';
HeaderDropDownElements.displayName = 'Header.DropDownElements';
