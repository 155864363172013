import React from 'react';

export default class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.determineItemState = this.determineState.bind(this);
  }

  render() {

    return (
      <div className='row ad-nav'>
        <div className={'nav-item ' + this.determineItemState(1)}>
          <label>Ad Types</label>
          <a className='ad-nav-link' onClick={() => this.props.wizardStateBack(1, 'ad_types_selection')}></a>
        </div>
        <div className={'nav-line ' + this.determineItemState(2)}></div>
        <div className={'nav-item ' + this.determineItemState(2)}>
          <label>Images</label>
          <a className='ad-nav-link' onClick={() => this.props.wizardStateBack(2, 'image_selection')}></a>
        </div>
        <div className={'nav-line ' + this.determineItemState(3)}></div>
        <div className={'nav-item ' + this.determineItemState(3)}>
          <label>Copy</label>
          <a className='ad-nav-link' onClick={() => this.props.wizardStateBack(3, 'write_copy')}></a>
        </div>
        <div className={'nav-line ' + this.determineItemState(4)}></div>
        <div className={'nav-item ' + this.determineItemState(4)}>
          <label>Review</label>
          <a className='ad-nav-link' onClick={() => this.props.wizardStateBack(4, 'ad_review')}></a>
        </div>
      </div>
    );
  }

  determineState(step){
    const currentStep = Number(this.props.wizardStep);

    if ( currentStep === step ) {
      return 'active';
    } else if ( currentStep > step) {
      return 'complete';
    } else {
      return 'disabled';
    }
  }
};
