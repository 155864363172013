import React from 'react';
// Takes the following props:
// datatableUrl: (string)   url of the datatable endpoint
// onLoad:       (function) if defined, this will be called with the visible rows in the table
// columns:
//   field:       (string)   field name to pull the data from
//   displayName: (string)   column heading title
//   type:        (string)   'Integer' or 'String' -- how we'll display the value
//   customRender (function) if defined, this is how we'll render this cell
//   sortable:    (boolean)  if truthy, allow this column to be sorted
//   index:       (integer)  the column index this corresponds to
import DatatableHeader from './DatatableHeader'
import DatatableRow from './DatatableRow'
import DatatablePagination from './DatatablePagination'

export default class Datatable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: null,
      sortColumn: null,
      hiddenColumns: [],
      shopId: null,
      roundId: null,
      adType: this.props.adType,
      sortCol: null,
      sortDir: null,
      displayStart: 0,
      displayLength: 20,
      currentPage: 1
    };

    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.constructUrl = this.constructUrl.bind(this);
    this.getStyle = this.getStyle.bind(this);
    this.getVisibleColumns = this.getVisibleColumns.bind(this);
    this.getVisibleRows = this.getVisibleRows.bind(this);
    this.render = this.render.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderLoading = this.renderLoading.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.updateTableData = this.updateTableData.bind(this);
    this.handleColumnSort = this.handleColumnSort.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { shopId, roundId, adType } = this.props;

    if (shopId !== prevProps.shopId || roundId !== prevProps.roundId || adType !== prevProps.adType) {
      this.setState({
        shopId: shopId,
        roundId: roundId,
        adType: adType,
        displayStart: 0,
        currentPage: 1
      }, () => {
        this.updateTableData();
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.updateTime != this.props.updateTime) {
      this.updateTableData();
    }
  }

  componentDidMount() {
    this.updateTableData();
  }

  render() {
    if(!this.state.tableData) {
      return this.renderLoading();
    }

    return (
      <div style={this.getStyle()}>
        <table className='table table-striped'>
          <thead>
            <tr>
              {this.getVisibleColumns().map((header) => this.renderHeader(header))}
            </tr>
          </thead>
          <tbody>
            {this.getVisibleRows().map((row) => this.renderRow(row))}
          </tbody>
        </table>
        {this.renderPagination()}
      </div>
    );
  }

  renderHeader(column) {
    return <DatatableHeader key={column.index} sortIndex={this.state.sortCol} column={column} handleColumnSort={this.handleColumnSort}/>;
  }

  renderRow(row) {
    return <DatatableRow key={row[0]} columns={this.getVisibleColumns()} row={row}/>;
  }

  getVisibleColumns() {
    return _.filter(this.props.columns, (column) => !_.includes(this.state.hiddenColumns, column.field))
  }

  getVisibleRows() {
    return this.state.tableData.aaData;
  }

  renderPagination() {
    return <DatatablePagination currentPage={this.state.currentPage} handlePagination={this.handlePagination} displayLength={this.state.displayLength} tableData={this.state.tableData}/>
  }

  // Reload data from the endpoint
  updateTableData() {
    let datatableParams = {
      shoppertunity: this.state.shopId,
      round: this.state.roundId,
      iSortCol_0: this.state.sortCol,
      sSortDir_0: this.state.sortDir,
      iDisplayStart: this.state.displayStart,
      iDisplayLength: this.state.displayLength,
      adType: this.props.adType
    }

    // Authors note: replace this with a non-jquery call when we migrate to a full JS pipeline
    $.get(this.constructUrl(), datatableParams, null, 'json').then(data =>
      this.setState({
        tableData: data
      }, () => {
        // If the consumer wants to consume the loaded data, pass it up here
        if(this.props.onLoad) {
          this.props.onLoad(data);
        }
        $('[data-toggle=tooltip]').tooltip({html: true});
      })
    );
  }

  // Build a datatable URL
  constructUrl() {
    return this.props.datatableUrl;
  }

  handleColumnSort(column, sortDir) {
    this.setState({
      sortCol: column.index,
      sortDir: sortDir
    }, () => {
      this.updateTableData();
    })
  }

  handlePagination(currentPage, displayStart) {
    this.setState({
      currentPage: currentPage,
      displayStart: displayStart
    }, () => {
      this.updateTableData();
    })
  }

  getStyle() {
    return {
      width: '100%',
      overflowX: 'scroll',
      overflow: 'visible'
    };
  }

  renderLoading() {
    const loadingStyle = {
      fontSize: '20pt',
      textAlign: 'center',
      color: 'grey',
      fontWeight: '200'
    }

    return (
      <div style={loadingStyle}>
        Loading data... <i className="fas fa-circle-notch fa-spin"/>
      </div>
    );
  }
}
