import React from 'react';
import { keys } from 'lodash';
import SystemAlerts from '../SystemAlerts';
import SystemMessagesAlert from '../SystemMessagesAlert';
import FilterSelect from '../FilterSelect';
import SortSelect from '../SortSelect';
import ShopCard from '../ShopCard';
import NewBadgeHelper from '../NewBadgeHelper';
import NoOppsAlert from './NoOppsAlert';
import ShoppertunitiesIndexNav from './IndexNav';
import ShoppertunitiesIndexApplyAlert from './IndexApplyAlert';

export default class ShoppertunitiesIndex extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
            alerts: this.props.alerts,
            allShops: this.props.phases,
            tabList: this.phases(),
      selectedPhase: this.props.selected,
        cardsPerPage: this.props.page_size,
      numCardsToShow: this.props.page_size,
            cardView: true,
            retailer: [],
              client: [],
            category: [],
            platform: [],
              sortBy: [ ['fit_score'], ['desc'] ],
          sortLabel: 'Best Match',
            loading: false,
    }

    this.hideShowShops = this.hideShowShops.bind(this);
    this.goToPagePhase = this.goToPagePhase.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.showMoreCards = this.showMoreCards.bind(this)
  }

  componentDidMount(){
    $('.alert_holder').append($('.alert_area'));
    NewBadgeHelper.load();
  }

  showMoreCards() {
    this.setState({
      numCardsToShow: this.state.numCardsToShow + this.state.cardsPerPage
    });
  }

  loadMore(afterLoadCallback) {
    var that = this;
    return function() {
      var { allShops, selectedPhase, cardsPerPage, numCardsToShow } = that.state;
      var currentCount = that.selectedPhaseShops().length;
      var totalCount = that.selectedPhaseData().count;
      var callBack = function() {
        if (typeof afterLoadCallback === 'function') {
          afterLoadCallback();
        }
      };
      var page = (currentCount / cardsPerPage) + 1;
      if (currentCount < totalCount) {
        that.setState({ loading: true });
        $.get('/opportunities/opportunities_by_phase', { phase: selectedPhase, page }).done(function (data) {
          allShops[selectedPhase].results = [...allShops[selectedPhase].results, ...data];
          that.setState({ allShops });
          that.setState({ loading: false });
          callBack();
        });
      } else if (numCardsToShow < currentCount && currentCount === totalCount) {
        callBack();
      }
    }();
  }

  handleFilter(hash) {
    this.setState(hash);
  }

  goToPagePhase(tab) {
    this.setState({
       selectedPhase: tab,
      numCardsToShow: this.props.page_size,
            retailer: null,
              client: null,
            category: null,
            platform: null
    }, () => this.loadMore());
    this.resetQuery();
  }

  resetQuery() {
    this.setState({
             sortBy: [ ['fit_score'], ['desc'] ],
          sortLabel: 'Best Match'
    });
  }

  phases() {
    return keys(this.props.phases);
  }

  selectedPhaseData() {
    return this.state.allShops[ this.state.selectedPhase ]
  }

  selectedPhaseShops() {
    return this.selectedPhaseData().results || []
  }

  changeLayoutCallback(toggle) {
    this.setState({ cardView: toggle });
  }

  formatOptions(collection, attr) {
    return _.uniq(_.flatten(_.without(_.map(collection, attr), null)));
  }

  filterShops(shops) {
    shops = _.orderBy(shops, _.first(this.state.sortBy), _.last(this.state.sortBy));

    if(this.state.retailer && this.state.retailer.length > 0){
      var selectedRetailer = this.state.retailer;
      shops = _.filter(shops, function(shop) {
        return shop.retailers.some(retailer => selectedRetailer.includes(retailer));
      });
    }

    if(this.state.client && this.state.client.length > 0){
      var selectedClients = this.state.client;
      shops = _.filter(shops, function(shop) {
        return _.includes(selectedClients, shop.client);
      });    }

    if(this.state.category && this.state.category.length > 0){
      var selectedCategories = this.state.category;
      shops = _.filter(shops, function(shop) {
        return _.includes(selectedCategories, shop.category);
      });
    }

    if(this.state.platform && this.state.platform.length > 0){
      var selectedPlatforms = this.state.platform;
      shops = _.filter(shops, function(shop) {
        return _.includes(selectedPlatforms, shop.platform);
      });
    }

    return shops;
  }

  hideShowShops(shop) {
    var hidden_phase = this.state.selectedPhase === 'hidden' ? true : false;
    var allShops     = this.state.allShops;

    if(hidden_phase){
      allShops['hidden'] = _.without(allShops['hidden'], shop);
      allShops['open']   = _.concat(allShops['open'], shop);
    }else{
      allShops['open']   = _.without(allShops['open'], shop);
      allShops['hidden'] = _.concat(allShops['hidden'], shop);
    }

    this.setState({ allShops: allShops });

    $.post('/opportunities/'+shop.id+'/hide?hide='+!hidden_phase);
  }

  noOppsAlert() {
    const { i18nNoOpps } = this.props;
    const { loading, selectedPhase } = this.state;
    if (loading)
      return null;

    return (
      <NoOppsAlert
        phaseCount={this.selectedPhaseShops().length}
        phaseSelected={selectedPhase}
        i18n={i18nNoOpps}
        loading={loading}
      />
    );
  }

  render() {
    var globalAlerts = this.state.alerts;
    var currentLayout = this.state.cardView ? '' : 'shop-list-layout'
    var layoutClass = 'shop-row ' + currentLayout;

    var filteredShops = this.filterShops(this.selectedPhaseShops());
    var pendingLoad = this.selectedPhaseShops().length < this.selectedPhaseData().count;
    var showMoreBtnClass = pendingLoad ? '' : (this.state.numCardsToShow >= filteredShops.length) ? 'is-hidden' : '';

    var retailers  = this.formatOptions(this.selectedPhaseShops(), 'retailers');
    var clients    = this.formatOptions(this.selectedPhaseShops(), 'client');
    var categories = this.formatOptions(this.selectedPhaseShops(), 'category');
    var platforms  = this.formatOptions(this.selectedPhaseShops(), 'platform');

    var cards = filteredShops.map(function (shop, cardIndex) {
      if(cardIndex < this.state.numCardsToShow){
        return (<ShopCard index={cardIndex}
                          shop={shop}
                          onClickHide={this.hideShowShops.bind(null, shop)}
                          selectedPhase={this.state.selectedPhase}
                          key={shop.id}
                          show_new_badge={true} />);
      }
    }.bind(this));

    return (
      <div>
        <ShoppertunitiesIndexNav
              goToPagePhase={this.goToPagePhase}
              allShops={this.state.allShops}
              tabList={this.state.tabList}
              selectedPhase={this.state.selectedPhase}
              cardView={this.state.cardView}
              changeLayoutCallback={this.changeLayoutCallback}/>
        <SystemAlerts alerts={_.get(globalAlerts, 'alerts', [])} />
        <SystemMessagesAlert alerts={_.get(globalAlerts, 'messages', [])} />
        <div className='container main-container'>
          <div className="row filters_and_sorts">
            <div className="col-xs-6 filter">
                <FilterSelect
                  retailers={retailers}
                  clients={clients}
                  categories={categories}
                  platforms={platforms}
                  onChangeCallback={this.handleFilter}
                />
            </div>
            <div className="col-xs-6 sort_by">
              <SortSelect onChangeCallback={this.handleFilter} instagramUser={this.props.instagram_user} sortLabel={this.state.sortLabel}/>
            </div>
          </div>
          <div className='alert_holder'></div>
          <ShoppertunitiesIndexApplyAlert {...this.props}/>
          <div className={layoutClass}>
            {cards}
          </div>
          { this.noOppsAlert() }
          {this.state.loading && <div id='shops_loading'>
            <i className='fas fa-circle-notch fa-spin' />
          </div>}
          {!this.state.loading && <div id='show_more' className={showMoreBtnClass}>
            <button id='show_more_button' disabled={this.state.loading} className='btn btn-default js-ga-show-more' onClick={() => this.loadMore(this.showMoreCards)}>Show More</button>      
          </div>}
        </div>
      </div>
    );
  }
};

ShoppertunitiesIndex.displayName = 'ShoppertunitiesIndex';