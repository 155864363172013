import React from 'react';
import PropTypes from 'prop-types';

export default class SaveIndicator extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const config = this.getConfig();
    const mainStyle = {
      position: 'fixed',
      right: '10px',
      bottom: '20px',
      backgroundColor: config.color,
      padding: '20px',
      borderRadius: '4px',
      boxShadow: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      zIndex: '999'
    };
    const iconStyle = {
      flex: '0',
      fontSize: '18pt',
      marginRight: '8px'
    };

    return (
      <div style={mainStyle}>
        <div style={iconStyle}>
          <i className={config.iconClass}/>
        </div>
        <div style={{ flex: 1 }}>
          <strong>{config.text}</strong>
          <br/>
          {this.props.children}
        </div>
      </div>
    );
  }

  getConfig() {
    const configs = {
      default: {
        iconClass: 'fas fa-cloud',
        text: 'Info',
        color: '#0b5da4'
      },
      success: {
        iconClass: 'fas fa-check-circle',
        text: 'Success',
        color: '#4CAF50'

      },
      failure: {
        iconClass: 'fas fa-exclamation-triangle',
        text: 'Failure',
        color: '#cc0000'
      },
    };
    return configs[this.props.type] || configs.default;
  }
}

SaveIndicator.displayName = 'SaveIndicator';
SaveIndicator.propTypes = {
  type: PropTypes.string
};
