import React from 'react';

const InfluencerDashboardHomeTaskCard = ({url, task_name, due_in, oppertunity_name, content_action, task_status}) => {
  return (
    <a className={`card clickable influencer-dashboard task-card ${task_status}`} href={url}>
      <div className='task-row'>
        <div className='task text-size-large title'>{ task_name }</div>
      </div>
      <div className='task-row'>
        <div className='task due_date text-color-subdued'>{ due_in }</div>
      </div>
      <div className='task-row'>
        <div className='task align-right status'>{ content_action }</div>
      </div>
      <div className='task-row bottom'>
        <div className='task text-color-subdued'>{ oppertunity_name }</div>
      </div>
    </a>
  )
};

InfluencerDashboardHomeTaskCard.displayName = 'InfluencerDashboard.Home.TaskCard';
export default InfluencerDashboardHomeTaskCard;