import React from 'react';

export default class RoundTasksFormActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorCount: 0
    }

    this.render = this.render.bind(this);
    this.submitData = this.submitData.bind(this);
  }

  hasSelectedValue(value) {
    return value !== null && value.length;
  }

  submitData() {
    if(!!this.props.submitting) {return;}
    this.props.startSubmitting();
    this.props.updateMeta({ error: false, errorText: '' });
    this.setState({errorCount: 0})

    this.props.submit({
      ...this.props.roundTask,
      round_task_requirements_attributes: this.props.roundTask.round_task_requirements.map(req => ({
        ...req,
        verification_type: this.hasSelectedValue(req.verification_type) ? req.verification_type[0].id : 'manual',
        repeat_type: this.hasSelectedValue(req.repeat_type) ? req.repeat_type[0].id : null
      }))
    })
  }

  missingRequiredFields() {
    const { community_id, name } = this.props.roundTask;
    return !community_id || !name || community_id.length === 0;
  }

  render() {
    return (
      <div className='text-center'>
        <button className='btn btn-primary' disabled={this.missingRequiredFields() || !!this.props.submitting} type='submit' value='Submit' onClick={(e) => this.submitData()}>
          { this.props.submitting ? <i className='fas fa-spin fa-circle-notch'></i> : 'Submit' }
        </button>
        <input className='btn btn-default' type='reset'  value='Cancel' onClick={(e) => { window.location.href = this.props.baseUrl }}></input>
      </div>
    );
  }
};

RoundTasksFormActions.displayName = 'RoundTasks.FormActions';
