class window.AdminContests

  @new: ->
    @form()

  @edit: ->
    @form()

  @update: ->
    @edit()

  @create: ->
    @new()

  @form: ->
    $('#contest_image_cta').redactor
      toolbarFixed: true
      toolbarFixedTarget: '#contest_image_cta'
      plugins: plugin_list

    $('#contest_prizes').redactor
      toolbarFixed: true
      toolbarFixedTarget: '#contest_prizes'
      plugins: plugin_list

    $('#contest_other_info').redactor
      toolbarFixed: true
      toolbarFixedTarget: '#contest_other_info'
      plugins: plugin_list
