const presenceRegex = new RegExp(/\w/);
const emailRegex = new RegExp(/[^@]+@[^\.]+\..+/g);

export const Validations = {
  firstName: presenceRegex,
  lastName: presenceRegex,
  email: emailRegex,
  legalName: presenceRegex,
  employee: presenceRegex,
  state: presenceRegex,
  reviewNotes: presenceRegex,
  oracleLegalName: presenceRegex,
  userRoleIds: presenceRegex,
  community_ids: presenceRegex,
  blogPlatformsAttributes: presenceRegex,
}

export const FieldOptions = {
  firstName: {
    label: 'First Name',
    required: true,
    className: 'input_wrap mb-15',
    fieldType: 'text'
  },
  lastName: {
    label: 'Last Name',
    required: true,
    className: 'input_wrap mb-15',
    fieldType: 'text'
  },
  agencyName:{
    label: 'Agency Name',
    required: true,
    className: 'input_wrap mb-15',
    fieldType: 'text',
  },
  email: {
    label: 'Email',
    required: true,
    className: 'input_wrap mb-15',
    fieldType: 'email',
    errorMessage: 'Must be a valid email',
  },
  legalName: {
    label: 'Oracle Legal Name',
    required: true,
    className: 'input_wrap mb-15',
    fieldType: 'text'
  },
  paypalEmail: {
    label: 'Paypal Email',
    required: true,
    className: 'input_wrap mb-15',
    fieldType: 'email',
    regex: /[^@]+@[^\.]+\..+/g,
    errorMessage: 'Must be a valid email',
  },
  paypalName: {
    label: 'Paypal Name',
    required: true,
    className: 'input_wrap mb-15',
    fieldType: 'text'
  },
  password: {
    label: 'Password',
    required: false,
    className: 'input_wrap mb-15',
    fieldType: 'text'
  }
}