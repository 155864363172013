import React from 'react';

export default class LoginAndApplyHeader extends React.Component {

  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
  }

  render() {
    let { community } = this.props;
    let theme_settings = community ? community.community_theme_settings : null

    let community_background_color = theme_settings ? { background: '#FFFFFF' } : null;
    let img_src = theme_settings ? theme_settings.theme_image.url : this.props.sofab_image_url;

    return(
      <header className='header clearfix' style={community_background_color}>
        <div className='nav-wrap clearfix'>
          <div className='contain'>
            <div className='logo text-center'>
              <a href={this.props.root_path} >
                <img src={img_src} style={{width: "250px"}} />
              </a>
            </div>
          </div>
        </div>
      </header>
      );
  }

}

LoginAndApplyHeader.displayName = 'LoginAndApplyHeader';