# Add annotations into a form with a redactor editor inside
class window.AnnotatedEditor
  constructor: (formSelector, annotationsSelector) ->
    @form = $(formSelector)
    @annotations = $(annotationsSelector).data('annotations') || []
    console.log(@annotations)
    @form.submit =>
      @before_submit()
      true
    @dynamic_annotations = new DynamicAnnotations(annotationsSelector)
    # Rehook the hover listeners when switching between editor modes
    $('#html-btn').click(() => @redraw_annotations())

  # Use the annotations from the DOM and load them into annotatorJS
  load_draft_annotations: ->
   if @form.find('.redactor-editor').length
    annotation = @form.find('.redactor-editor').annotator(readOnly: true)
    annotation.annotator('loadAnnotations', _.cloneDeep(@annotations))
    @form.find('.redactor-editor').off('mouseover').off('hover').attr('id', 'redactor-editor')
    @redraw_annotations()


  # Use a bootstrap tooltip to display the annotations
  redraw_annotations: ->
    for a in @annotations
      @form.find('.annotator-hl[data-annotation-id=' + a.id + ']').tooltip
        container: 'body',
        title: a.text

  # Triggered when the submit button is pressed to sanitize the redactor content
  before_submit: ->
    # Switch to the visual mode before submit
    $('#html-btn').trigger('click') if $('#html-btn').text() == 'Switch to Visual Mode'
    @dynamic_annotations.sync()
    @force_cleanup()
    html = $('.redactor-box > .redactor-editor').html()
    $('#content_draft_attributes_body').redactor('core.destroy')
    $('#content_draft_attributes_body').val(html)

  # Remove all remnants of annotator and reinsert them into the DOM
  force_cleanup: ->
    @unwrap('.annotator-wrapper')
    @unwrap(el) for el in $('.annotator-hl').get()
    $('.annotator-adder, .annotator-widget').remove()

  # Remove the surrounding element around content and place inline
  unwrap: (selector) ->
    for el in $(selector).get()
      $(el).contents().insertBefore($(el))
      $(el).remove()
