import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash'
import { Remote } from '../../utils';

import SubmissionStateIndicator from './StateIndicator'

class SubmissionInstagramStoryContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: false
    }

    this.validate = this.validate.bind(this);
    this.debouncedValidate = _.debounce(this.validate, 500);

    this.video = React.createRef();

    this.validate = this.validate.bind(this);
    this.validationState = this.validationState.bind(this);
    this.renderVideoPlayer = this.renderVideoPlayer.bind(this);
    this.setPlayingState = this.setPlayingState.bind(this);
    this.selectSubmittedContent = this.selectSubmittedContent.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
    this.selectionHandler = this.selectionHandler.bind(this);
    this.render = this.render.bind(this);
  }

  render() {
    const { post, selected, key } = this.props;
    const isVideo = post.media_type === 'STORY_VIDEO'
    const isSelected = selected.some(content => content.id === post.id)
    const state = this.validationState();

    return (
      <div key={key}
           className={`submission grid-item instagram-story ${state} ${isSelected ? 'selected' : ''}`}
           onClick={(e) => this.clickHandler(e, isSelected)}>
        <div className='item-image' style={{backgroundImage: `url(${post.image_url})`}}>
          <SubmissionStateIndicator state={state} isSelected={isSelected} isVideo={isVideo} setPlayingState={this.setPlayingState}/>
        </div>
        { isVideo && this.renderVideoPlayer() }
      </div>
    )
  }

  componentDidMount() {
    this.selectSubmittedContent()
  }

  selectSubmittedContent(){
    const { post, approvedPosts } = this.props;

    approvedPosts.forEach(submitted => {
      if(submitted.instagram_service_story_id === post.id){
        this.selectionHandler(false);
      }
    });
  }

  validationState() {
    const { playing } = this.state;
    const { validations } = this.props;

    if ( validations && validations.length ) {
      if ( _.some(validations, {state: 'failed'}) ) {
        return 'failed';
      } else {
        return 'passed';
      }
    } else {
      if (playing) {
        return 'playing';
      } else {
        return 'pending';
      }
    }
  }

  renderVideoPlayer() {
    return (
      <video ref={this.video}
        className='video'
        onPause={(e) => this.setPlayingState(e, false)}
        src={this.props.post.media_url}
        preload='none'
        controls></video>
    )
  }

  setPlayingState(e, state) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      playing: state
    }, () => {
      if (state) {
        this.video.current.play();
      } else {
        this.video.current.pause();
      }
    });
  }

  clickHandler(e, isSelected) {
    e.preventDefault();
    this.selectionHandler(isSelected);
  }

  selectionHandler(isSelected){
    const { post } = this.props;

    const selectPromise = () => new Promise((resolve) => {
      this.props.resetValidations();

      if ( isSelected ) {
        resolve(this.props.deselect(post));
      } else {
        resolve(this.props.select(post));
      }
    })

    selectPromise().then(() => {
      this.debouncedValidate();
    })
  }

  validate() {
    const params = $.param({
      api_action: `/blogger_shop_tasks/${this.props.bloggerShopTask.id}/validate`
    });

    const contentData = {
      type: this.props.contentType.name,
      user_id: this.props.userId,
      stories: this.props.selected
    };

    Remote().request({
      method: 'POST',
      url: this.props.proxyUrl + '?' + params,
      data: contentData,
    }).then((response) => {
      this.props.setValidations(response.data);
    });
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      selected: state.selected,
      userId: state.meta.userId,
      bloggerShopTask: state.bloggerShopTask,
      contentType: state.contentType,
      proxyUrl: state.meta.proxyUrl,
      submission: state.submission,
      validations: state.validations
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      select: post => dispatch({ type: 'SELECT_MULTIPLE_CONTENT', value: post }),
      deselect: post => dispatch({ type: 'DESELECT_CONTENT', value: post }),
      resetValidations: () => dispatch({ type: 'RESET_VALIDATIONS' }),
      setValidations: validations => dispatch({ type: 'SET_VALIDATIONS', value: validations })
    };
  }
};

SubmissionInstagramStoryContent.displayName = 'Submission.InstagramStoryContent';
export default connect(
  SubmissionInstagramStoryContent.mapStateToProps,
  SubmissionInstagramStoryContent.mapDispatchToProps
)(SubmissionInstagramStoryContent);
