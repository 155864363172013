import React from 'react';
import PropTypes from 'prop-types'

export default class ProfilesInfluencerDetails extends React.Component {

  renderField(key, value) {
    return (
      <div key={key} className='internal-profile-card-item'>
        <label>{key}</label>
        <p>{value}</p>
      </div>
    )
  }

  renderFields() {
    const { profile: { fields, i18n} } = this.props;
    return Object.keys(fields).map( (key) => {
      return this.renderField(i18n[key], fields[key])
    });
  }

  render() {
    const { profile, completed_shops_path } = this.props;
    if (!profile)
      return null;

    return (
      <div className='profile-cards-container'>
        <div className='card-plus'>
          <div className='card-header'>
            Influencer Details - Internal Use Only
            <div className='card-action'>
              <a className='btn btn-secondary' type='button' href={completed_shops_path} >
                View Completed Shops
              </a>
            </div>
          </div>
          <div className='card-body internal-profile-card-container'>
            { this.renderFields() }
          </div>
        </div>
      </div>
    );
  }
};

ProfilesInfluencerDetails.displayName = 'Profiles.InfluencerDetails';
ProfilesInfluencerDetails.propTypes = {
  profile: PropTypes.object,
  completed_shops_path: PropTypes.string
};