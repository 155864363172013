import React from 'react';
import { Provider } from "react-redux"
import RoundTasksFormStore from '../../../redux/RoundTasksForm';
import GeneralRequirementsEdit from './Edit'

export default class GeneralRequirementsEditWrapper extends React.Component {
  render() {
    return (
      <Provider store={RoundTasksFormStore}>
        <GeneralRequirementsEdit {...this.props}/>
      </Provider>
    );
  }
};
