import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import LoadingSpinner from '../LoadingSpinner'
import FixedBar from '../FixedBar'
import Alert from '../Alert'
import UsersEmailSettings from './EmailSettings'
import UsersNotificationSettings from './NotificationSettings'
import UsersCategorySettings from './CategorySettings'
import UsersChangePassword from './ChangePassword'
import UsersDeleteAccount from './DeleteAccount'

class UsersAccountSettings extends React.Component {
  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.goBack = this.goBack.bind(this);
    this.loadCategories = this.loadCategories.bind(this);
    this.loadSettings = this.loadSettings.bind(this);
    this.render = this.render.bind(this);
    this.renderAlert = this.renderAlert.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
  }

  render() {
    const { alert, loading, i18n, validations } = this.props;

    if(loading) {
      return <LoadingSpinner />;
    }

    return <div className='account-settings'>
      {this.renderAlert()}
      <UsersEmailSettings />
      <UsersNotificationSettings />
      <UsersCategorySettings />
      <UsersChangePassword />
      <UsersDeleteAccount />

      <FixedBar style='left-right'>
        <button className='btn btn-default' onClick={this.goBack}>
          {i18n.labels.cancel}
        </button>
        <button
          className='btn btn-primary'
          disabled={_.some(Object.values(validations), v => !v)}
          onClick={this.saveChanges}
          >
          {i18n.labels.save_changes}
        </button>
      </FixedBar>
    </div>;
  };

  componentDidMount() {
    this.props.updateMeta({
      userId: this.props.userId,
      proxyUrl: this.props.proxyUrl,
      resetPasswordUrl: this.props.resetPasswordUrl,
      i18n: this.props.i18n
    });

    Promise.all([
      this.loadSettings(),
      this.loadCategories()
    ]).then(() => {
      this.props.setLoading(false);
    }).catch((e) => {
      this.props.showAlert([this.props.i18n.errors.ajax_failure], true);
    });
  }

  loadSettings() {
    const params = $.param({
      api_action: `/users/${this.props.userId}/settings`
    });

    return (
      axios.get(this.props.proxyUrl + '?' + params)
        .then((response) => {
          this.props.updateSettings(response.data);
        }).catch((response) => {
          this.props.showAlert([this.props.i18n.ajax_failure], true);
        })
    );
  }

  loadCategories() {
    const params = $.param({
      api_action: `/product_categories`
    });

    return (
      axios.get(this.props.proxyUrl + '?' + params)
        .then((response) => {
          this.props.setAvailableCategories(response.data);
        }).catch((response) => {
          this.props.showAlert([this.props.i18n.ajax_failure], true);
        })
    );
  }

  saveChanges(e) {
    e.preventDefault();

    const {
      proxyUrl,
      settings,
      newEmail,
      i18n
    } = this.props;
    const params = $.param({
      api_action: `/users/${this.props.userId}/settings`
    });

    let payload = {
      notification_setting_attributes: {
        ...settings.notification_setting
      }
    };

    // Only include email if it's being updated
    if(newEmail) {
      payload = {
        ...payload,
        email: newEmail
      };
    }

    this.props.setLoading(true);

    axios.patch(proxyUrl + '?' + params, payload)
      .then((response) => {
        this.goBack(e);
      }).catch((response) => {
        let data = {};
        try {
          data = JSON.parse(response.failure.responseText);
        } catch(_ignored) {}

        if(data.validations) {
          this.props.showAlert(data.validations);
        } else {
          this.props.showAlert([i18n.errors.ajax_failure], true);
        }

        this.props.setLoading(false);
      })
  }

  goBack(e) {
    e.preventDefault();
    this.props.setLoading(true);
    window.history.back();
  }

  renderAlert() {
    const { alert, i18n } = this.props;

    if(!alert.showing) {
      return null;
    }

    if(alert.ajax) {
      return <Alert type='error fixed-top' callback={this.props.closeAlert}>
        {alert.errors[0]}
      </Alert>;
    }

    return <Alert type='error'>
      {i18n.errors.failed_to_save}
      <ul>
        {
          alert.errors.map(error => <li key={error}>{error}</li>)
        }
      </ul>
    </Alert>;
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      alert: state.alert,
      loading: state.loading,
      validations: state.validations,
      settings: state.settings,
      newEmail: state.settings.newEmail
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateMeta: meta => dispatch({ type: 'UPDATE_META', value: meta }),
      showAlert: (errors, ajax=false) => dispatch({
        type: 'UPDATE_ALERT',
        value: {
          showing: true,
          ajax,
          errors
        }
      }),
      closeAlert: () => dispatch({ type: 'UPDATE_ALERT', value: { showing: false } }),
      updateSettings: value => dispatch({ type: 'UPDATE_SETTINGS', value }),
      setLoading: value => dispatch({ type: 'SET_LOADING', value }),
      setAvailableCategories: value => dispatch({ type: 'SET_AVAILABLE_CATEGORIES', value }),
    };
  }
};

UsersAccountSettings.displayName = 'Users.AccountSettings';
UsersAccountSettings.propTypes = {
  userId: PropTypes.number.isRequired,
  proxyUrl: PropTypes.string.isRequired
};

export default connect(
  UsersAccountSettings.mapStateToProps,
  UsersAccountSettings.mapDispatchToProps
)(UsersAccountSettings)
