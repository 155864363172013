import React from 'react';
import { connect } from 'react-redux';
import SingleSelect from '../SingleSelect'
import MultiSelect from '../MultiSelect'
import ProfilesChildren from './Children'

class ProfilesDemographics extends React.Component {
  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.updateField = this.updateField.bind(this);
    this.searchOptions = this.searchOptions.bind(this);
    this.validation = this.validation.bind(this);
    this.inputHasError = this.inputHasError.bind(this);
    this.renderSingleSelect = this.renderSingleSelect.bind(this);
    this.renderMultiSelect = this.renderMultiSelect.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    this.validateFields();
  }

  validateFields() {
    let validations = {};
    let validatedCount = 0;
    const requiredFields = {
      gender: {
        name: 'Gender'
      },
      ethnicity_ids: {
        name: 'Ethnicity'
      },
      education_ids: {
        name: 'Education'
      },
      marital_status: {
        name: 'Marital Status'
      },
      income_id: {
        name: 'Income'
      },
      device_ids: {
        name: 'Devices'
      },
      grocery_retailer_ids: {
        name: 'Grocery'
      },
      health_beauty_retailer_ids: {
        name: 'Health & Beauty'
      },
      electronic_retailer_ids: {
        name: 'Electronics & Household Appliances'
      },
      clothing_retailer_ids: {
        name: 'Clothing'
      }
    };

    const promise = () => new Promise((resolve) => {
      Object.keys(requiredFields).forEach((key) => {
        validatedCount++;
        const field = requiredFields[key];
        const value = this.props.profile[key];

        if (!value || value.length === 0) {
          validations[key] = `${field.name} cannot be left blank.`;
        } else {
          validations[key] = undefined;
        }

        if (validatedCount === Object.keys(requiredFields).length) {
          resolve();
        }
      });
    });

    promise().then(() => {
      this.props.updateFrontendValidations(validations)
    });
  }

  updateField(field, value) {
    const promise = () => new Promise((resolve) => {
      resolve(this.props.updateProfile(field, value));
    });

    promise().then(() => {
      this.validateFields();
    });
  }

  searchOptions(field, query, callback) {
    const results = _.filter(
      this.props[field],
      value => value.name.toLowerCase().includes(query.toLowerCase())
    );
    callback(results);
  }

  validation(field) {
    const validations = this.props.frontendValidations[this.props.activeTab] || {};
    const fieldValidations = validations[field];

    if(!fieldValidations) {
      return null;
    }

    return <span className='help-text no-prefix invalid'>
      {fieldValidations}
    </span>;
  }

  inputHasError(field) {
    if(this.validation(field)) {
      return true;
    }

    return false;
  }

  renderSingleSelect(label, field, options=field) {
    return(
      <div className='input-wrap'>
        <label>{label}</label>
        <SingleSelect
          hasError={this.inputHasError(field)}
          search={(query, callback) => this.searchOptions(options, query, callback)}
          selected={this.props.profile[field] || ''}
          onUpdate={(item) => this.updateField(field, item)}
          placeholder={`Select ${label}`}
        />
        {this.validation(field)}
      </div>
    )
  }

  renderMultiSelect(label, field, options=field) {
    return (
      <div className='input-wrap'>
        <label>{label}</label>
        <MultiSelect
          hasError={this.inputHasError(field)}
          search={(query, callback) => this.searchOptions(options, query, callback)}
          selected={this.props.profile[field]}
          onUpdate={(items) => this.updateField(field, items)}
          placeholder={`Select ${label}`}
        />
        {this.validation(field)}
      </div>
    )
  }

  render() {
    return (
      <div className='demographics-info-container'>

          <div className='card-plus personal-info-card'>
            <div className='card-header'>Personal Information</div>
            <div className='card-body'>
              <div className='personal-info-form'>
                {this.renderSingleSelect('Gender', 'gender', 'genders')}
                {this.renderMultiSelect('Ethnicity','ethnicity_ids', 'ethnicities')}
                {this.renderMultiSelect('Education', 'education_ids', 'educations')}
              </div>
            </div>
          </div>

          <div className='card-plus family-demographics-card'>
            <div className='card-header'>Family/ Household Demographics</div>
            <div className='card-body'>
              <div className='family-demographics-form'>
                {this.renderSingleSelect('Marital Status', 'marital_status', 'marital_statuses')}
                {this.renderSingleSelect('Income', 'income_id', 'incomes')}
                {this.renderSingleSelect('Military Affiliation', 'military_affiliation', 'military_affiliations')}
                {this.renderMultiSelect('Pets', 'pet_ids', 'pets')}
                {this.renderMultiSelect('Professions', 'profession_ids', 'professions')}
                {this.renderMultiSelect('Devices', 'device_ids', 'devices')}
                {this.renderMultiSelect('Family Interests', 'category_ids', 'categories')}
              </div>
            </div>
          </div>

          <ProfilesChildren />

          <div className='card-plus preferred-retailers-card'>
            <div className='card-header'>Preferred Retailers</div>
            <div className='card-body'>
              <div className='preferred-retailers-form'>
                {this.renderMultiSelect('Groceries', 'grocery_retailer_ids', 'retailers')}
                {this.renderMultiSelect('Health & Beauty', 'health_beauty_retailer_ids', 'retailers')}
                {this.renderMultiSelect('Electronics & Household Appliances', 'electronic_retailer_ids', 'retailers')}
                {this.renderMultiSelect('Clothing', 'clothing_retailer_ids', 'retailers')}
              </div>
            </div>
          </div>

      </div>
    );
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      activeTab: state.activeTab,
      profile: state.profile,
      frontendValidations: state.frontendValidations,
      genders: state.genders,
      marital_statuses: state.marital_statuses,
      military_affiliations: state.military_affiliations,
      ethnicities: state.ethnicities,
      educations: state.educations,
      incomes: state.incomes,
      pets: state.pets,
      professions: state.professions,
      devices: state.devices,
      categories: state.categories,
      retailers: state.retailers
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateFrontendValidations: validations => dispatch({ type: 'UPDATE_FRONTEND_VALIDATIONS', value: validations }),
      updateProfile: (field, value) => dispatch({ type: 'UPDATE_PROFILE', value: { field: field, value: value } })
    }
  }
};

ProfilesDemographics.displayName = 'Profiles.Demographics';
export default connect(
  ProfilesDemographics.mapStateToProps,
  ProfilesDemographics.mapDispatchToProps
)(ProfilesDemographics);

