import React from 'react';
class InstagramBusinessStoryCard extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.getClassName = this.getClassName.bind(this);
  }

  render() {
    const story = this.props.story;

    return (
      <div className={this.getClassName()} onClick={this.props.selectCard}>
        <div className="preview">
          { story.media_type === 'STORY_IMAGE' &&
            <img src={story.media_url} />
          }
          { story.media_type === 'STORY_VIDEO' &&
            <video src={story.media_url} controls/>
          }
          { this.props.isSelected &&
              <i className="fas fa-check" />
          }
        </div>
      </div>
    );
  }

  getClassName(){
    const selection = this.props.isSelected ? "story-selected" : "";
    return `story ${selection}`
  }

}
