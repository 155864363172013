import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { Remote, isAscii } from '../utils';
import axios from "axios";
import _ from "lodash";

import {
  phTiktokVideo,
  regexTiktokManual,
  phInstagramReel,
  phInstagramReelAlt,
  regexInstagramReel,
  phFacebookVideo,
  regexFacebookVideo,
  phYoutubeVideo,
  regexYoutubeVideoRegex,
  regexYoutubeShort,
  phYoutubeShort
} from "../lib/submission";
import Alert from "../Alert";
import SubmissionPreview from "./Preview";

class SubmissionLinkForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      failure: null,
      fetched: false,
      validTiktokUrl: null,
      disableSubmit: false,
    };

    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.fetchPost = this.fetchPost.bind(this);
    this.fetchInstagramReelPost = this.fetchInstagramReelPost.bind(this);
    this.fetchTiktokPost = this.fetchTiktokPost.bind(this);
    this.fetchValidTiktokUrl = this.fetchValidTiktokUrl.bind(this);
    this.fetchContentPost = this.fetchContentPost.bind(this);
    this.formatTiktokData = this.formatTiktokData.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
    this.render = this.render.bind(this);
    this.updateUrl = this.updateUrl.bind(this);
    this.placeholder = this.placeholder.bind(this);
    this.validateUrl = this.validateUrl.bind(this);
    this.validUrl = this.validUrl.bind(this);
    this.renderAlert = this.renderAlert.bind(this);
  }

  render() {
    return (
      <div className="submission link-form">
        {this.state.failure !== null && (
          <Alert type="error" message={this.state.failure.responseText} />
        )}

        <div className="form-group">
          <label>Post URL:</label>
          <input
            value={this.props.submission.url || ""}
            onChange={this.updateUrl}
            className="form-control"
            placeholder={this.placeholder()}
            disabled={this.props.readOnly}
          />
        </div>

        {this.props.submission.url && !this.validUrl() && this.renderAlert()}

        {this.props.children}

        <button
          className="btn btn-block btn-fetch"
          disabled={this.isDisabled()}
          onClick={this.fetchContentPost}
        >
          Fetch Post
        </button>

        <SubmissionPreview tiktok_oembed_js={this.props.tiktok_oembed_js} />
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    const contentUrl = _.get(this.props.content, "url");
    const currentUrl = _.get(this.props.submission, "url");
    const oldUrl = _.get(prevProps.submission, "url");

    // Fetch a preview if this is the first page load (submission may not be set on mount)
    if (
      contentUrl &&
      contentUrl == currentUrl &&
      currentUrl != oldUrl &&
      !this.state.fetched
    ) {
      this.fetchContentPost();
      this.setState({ fetched: true });
    }

    this.props.submission.url && this.validateUrl();
  }

  fetchContentPost() {
    if (this.props.contentType.name == "TiktokVideoContent") {
      this.fetchValidTiktokUrl();
    } else if (this.props.contentType.name == "InstagramReelContent") {
      this.fetchInstagramReelPost();
    } else {
      this.fetchPost();
    }
  }

  fetchInstagramReelPost() {
    this.props.setLoading(true);
    this.setState({ failure: null });

    const params = $.param({
      api_action: `/blogger_shop_tasks/${this.props.bloggerShopTask.id}/fetch_instagram_preview`,
    });

    Remote()
      .request({
        method: "POST",
        url: this.props.proxyUrl + "?" + params,
        data: {
          url: this.props.submission.url,
          type: this.props.contentType.name,
        },
      })
      .then((response) => {
        if (!!response.data.status && response.data.status !== 200)
          throw new Error(response.data.text);

        this.props.setPreview(response.data.preview);
        this.props.setValidations(response.data.validations);
      })
      .catch((error) => {
        this.props.setPreview(null);
        this.setState({ failure: { responseText: error.message } });
      })
      .then(() => {
        this.props.setLoading(false);
      });
  }

  fetchTiktokPost() {
    const params = $.param({
      url: this.state.validTiktokUrl,
    });

    axios
      .get("https://www.tiktok.com/oembed" + "?" + params)
      .then((response) => {
        // response always returns a 200; if tiktok doesn't give us what we want, display error
        if (response.data["html"]) {
          this.props.setPreview({ type: "html", html: response.data["html"] });
          this.props.updateSubmission(this.formatTiktokData(response.data));
        } else {
          this.props.setPreview(null);
          this.props.resetSubmission();
          this.setState({
            failure: { responseText: "This is not a valid post link." },
          });
        }
      });
    this.setState({ fetched: true });
  }

  fetchValidTiktokUrl() {
    this.props.setLoading(true);
    this.setState({ failure: null });
    const currentUrl = _.get(this.props.submission, "url");
    let shortUrl = currentUrl.match(regexTiktokManual);
    let url = shortUrl ? shortUrl[0] : currentUrl;

    if (url.match(/vm\.*/i)) {
      const params = $.param({
        api_action: `decode_short_url?url=${url}`,
      });

      Remote()
        .request({
          url: this.props.proxyUrl + "?" + params,
          method: "GET",
        })
        .then((data) => {
          this.setState({ validTiktokUrl: data["url"] }, () => {
            this.fetchTiktokPost();
          });
        })
        .catch((failure) => {
          this.props.setPreview(null);
          this.setState({ failure: { responseText: failure["error"] } });
        });
    } else {
      this.setState({ validTiktokUrl: url }, () => {
        this.fetchTiktokPost();
      });
    }
    this.props.setLoading(false);
  }

  formatTiktokData(data) {
    return {
      title: data["title"],
      author_name: data["author_name"],
      html: data["html"],
      image_url: data["thumbnail_url"],
      description: data["html"],
      manual: true,
    };
  }

  fetchPost() {
    this.props.setLoading(true);
    this.setState({ failure: null });

    const params = $.param({
      api_action: `/blogger_shop_tasks/${this.props.bloggerShopTask.id}/fetch`,
    });

    Remote()
      .request({
        method: "POST",
        url: this.props.proxyUrl + "?" + params,
        data: {
          ...this.props.submission,
          type: this.props.contentType.name,
        },
      })
      .then((response) => {
        if (!!response.data.status && response.data.status !== 200)
          throw new Error(response.data.text);
        this.props.setPreview(response.data.preview);
        this.props.setValidations(response.data.validations);
      })
      .catch((error) => {
        this.props.setPreview(null);
        this.setState({ failure: { responseText: error.message } });
      })
      .then(() => {
        this.props.setLoading(false);
      });
  }

  updateUrl(e) {
    this.setState({ fetched: true }); // Prevent auto-fetching
    this.props.updateSubmission({ url: e.target.value });
  }

  placeholder() {
    switch (this.props.contentType.name) {
      case "FacebookVideoContent":
        return phFacebookVideo;
      case "InstagramReelContent":
        return phInstagramReelAlt;
      case "TiktokVideoContent":
        return phTiktokVideo;
      case "GoogleYoutubeContent":
        return phYoutubeVideo;
      case 'YoutubeShortContent':
        return phYoutubeShort
      default:
        return "https://website.com/link/to/your/post";
    }
  }

  isDisabled() {
    if (
      this.props.contentType.name === "BlogPostContent" &&
      !isAscii(this.props.submission.url)
    ) {
      return true;
    } else if (this.props.disableSubmit) {
      return true;
    }

    if (!!this.props.canFetch) {
      return !this.props.canFetch() || this.props.readOnly;
    } else {
      return !this.props.submission.url || this.props.readOnly;
    }
  }

  validateUrl() {
    if (this.validUrl()) {
      this.props.setDisableSubmit(false);
    } else {
      this.props.setDisableSubmit(true);
    }
  }

  validUrl() {
    const { url } = this.props.submission;
    if (!url) {
      return null;
    }

    switch (this.props.contentType.name) {
      case "FacebookVideoContent":
        return url.match(regexFacebookVideo);
      case "InstagramReelContent":
        return url.match(regexInstagramReel);
      case "TiktokVideoContent":
        return url.match(regexTiktokManual);
      case "GoogleYoutubeContent":
        return url.match(regexYoutubeVideoRegex);
      case 'YoutubeShortContent':
        return url.match(regexYoutubeShort);
      default:
        return true;
    }
  }

  renderAlert() {
    switch (this.props.contentType.name) {
      case "FacebookVideoContent":
        return (
          <Alert
            type="error"
            message={`Valid Facebook Video URL format is: ${phFacebookVideo}`}
          />
        );
      case "InstagramReelContent":
        return (
          <Alert
            type="error"
            message={`Valid Instagram Reel URL formats are: ${phInstagramReel}`}
          />
        );
      case "TiktokVideoContent":
        return (
          <Alert
            type="error"
            message={`Valid Tiktok video URL format is: ${phTiktokVideo}`}
          />
        );
      case "GoogleYoutubeContent":
        return (
          <Alert
            type="error"
            message={`Valid Youtube video URL format is: ${phYoutubeVideo}`}
          />
        );
      case "YoutubeShortContent":
        return (
          <Alert
            type="error"
            message={`Valid Youtube Short URL format is: ${phYoutubeShort}`}
          />
        );
      default:
        return null;
    }
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      bloggerShopTask: state.bloggerShopTask,
      submission: state.submission,
      state: state.bloggerShopTask.state,
      proxyUrl: state.meta.proxyUrl,
      contentType: state.contentType,
      readOnly: state.readOnly,
      content: state.bloggerShopTask.content,
      disableSubmit: state.disableSubmit,
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateSubmission: (params) =>
        dispatch({ type: "UPDATE_SUBMISSION", value: params }),
      setPreview: (preview) =>
        dispatch({ type: "SET_PREVIEW", value: preview }),
      setValidations: (validations) =>
        dispatch({ type: "SET_VALIDATIONS", value: validations }),
      setLoading: (loading) =>
        dispatch({ type: "SET_LOADING", value: loading }),
      resetSubmission: () => dispatch({ type: "RESET_SUBMISSION" }),
      setDisableSubmit: (boolean) =>
        dispatch({ type: "TOGGLE_DISABLE_SUBMIT", value: boolean }),
    };
  }
};

SubmissionLinkForm.displayName = 'Submission.LinkForm';
SubmissionLinkForm.propTypes = {
  canFetch: PropTypes.func
}

export default connect(
  SubmissionLinkForm.mapStateToProps,
  SubmissionLinkForm.mapDispatchToProps
)(SubmissionLinkForm);
