import React from 'react';
import UserSignUpInfoAccordion from './InfoAccordion';
import UserSignUpValidatedForm from './ValidatedForm';
import { UserSignUpPersonalInfoFields } from './fields/PersonalInfo';

export default class UserSignUpPersonalInfo extends React.Component {
  constructor(props) {
    super(props);

    UserSignUpPersonalInfoFields.gender.options = this.props.genderList;
    UserSignUpPersonalInfoFields.ethnicity.options = this.props.ethnicityList;
    UserSignUpPersonalInfoFields.education.options = this.props.educationList;
    UserSignUpPersonalInfoFields.maritalStatus.options = this.props.maritalStatusList;
    UserSignUpPersonalInfoFields.income.options = this.props.incomeList;
    UserSignUpPersonalInfoFields.retailer.options = this.props.retailerList;

    this.form = new UserSignUpValidatedForm(this, UserSignUpPersonalInfoFields);
  }

  componentWillMount(){
    this.form.mount();
  }

  render(){
    const { heading, accordion } = this.props

    return (
      <div>
        <div className='column'>
          <h4>{heading}</h4>
          <div className='form'>
            <div className='column'>
              { this.form.renderField('gender') }
              { this.form.renderField('ethnicity') }
              { this.form.renderField('education') }
            </div>
            <div className='column'>
              { this.form.renderField('maritalStatus') }
              { this.form.renderField('income') }
              { this.form.renderField('retailer') }
            </div>
          </div>
        </div>
        <div className='column'>
        { Object.keys(accordion).map((key) => {
            return <UserSignUpInfoAccordion key={key} title={accordion[key].title} body={accordion[key].body} />
          })}
        </div>
      </div>
    );
  }
}