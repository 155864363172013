import React from 'react';

export default class AdImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editButtonActive: false
    };

    this.render = this.render.bind(this);
    this.getClassName = this.getClassName.bind(this);
    this.renderEditButton = this.renderEditButton.bind(this);
    this.toggleEditButton = this.toggleEditButton.bind(this);
    this.editClicked = this.editClicked.bind(this);
    this.getProxiedImageUrl = this.getProxiedImageUrl.bind(this);
  }

  render() {
    const style = {
      backgroundImage: `url(${this.getProxiedImageUrl()})`
    };

    return (
      <div
        style={style}
        onClick={this.props.onClick}
        onMouseEnter={this.toggleEditButton(true)}
        onMouseLeave={this.toggleEditButton(false)}
        className={this.getClassName()}
      >
        {this.renderEditButton()}
      </div>
    );
  }

  // Return a function that will toggle to toState
  toggleEditButton(toState) {
    return () => this.setState({
      editButtonActive: toState
    });
  }

  renderEditButton() {
    if(!this.state.editButtonActive || !this.props.isSelected) {
      return;
    }

    return (
      <span className='edit-btn' onClick={this.editClicked}>
        <i className='fas fa-pencil-alt' />
      </span>
    );
  }

  getClassName() {
    const selection = this.props.isSelected ? 'selected' : '';

    return `wizard-image ${selection}`;
  }

  editClicked(event) {
    event.preventDefault();
    event.stopPropagation();

    this.props.onEditClicked(this.props.image);
  }

  getProxiedImageUrl() {
    const encodedUrl = encodeURIComponent(btoa(this.props.image));

    return `/admin/promoter_ad_states/proxy/${encodedUrl}`;
  }
};
