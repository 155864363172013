import React from 'react';
import MultiSelect from './MultiSelect'
export default class ShopApplicationSearchFilter extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.searchShopAppStates = this.searchShopAppStates.bind(this);
    this.searchRounds = this.searchRounds.bind(this);
    this.searchTiers = this.searchTiers.bind(this);
    this.searchUserStatus = this.searchUserStatus.bind(this);
    this.filterShopApps = this.filterShopApps.bind(this);
    this.reset = this.reset.bind(this);
    this.renderTiersDropDown = this.renderTiersDropDown.bind(this);

    this.debouncedFilter = _.debounce(this.filterShopApps.bind(this), 1000);

    this.state = {
      rounds: this.props.selectedRounds != null ? this.props.selectedRounds.split(',') : [],
      shopAppStates: this.props.selectedStatus != null ? this.props.selectedStatus.split(',') : [],
      tiers: this.props.selectedTiers != null ? this.props.selectedTiers.split(',') : [],
      userStatus: this.props.selectedUserStatus != null ? this.props.selectedUserStatus.split(',') : []
    }
  }

  render() {
    const buttons = {
      paddingLeft: '10px',
      paddingRight: '10px'
    };
    const buttonDiv = {
      paddingTop: '10px'
    }

    return <div>
      <div className='row'>
        <div className='col-md-3'>
          <div className="form-group form-inline">
            <label htmlFor="rounds">Rounds</label>
            <MultiSelect search={this.searchRounds}
                                dropDownCloseMilliseconds={3000}
                                selected={this.state.rounds}
                                onUpdate={(rounds) => this.setState({ rounds: rounds }, this.debouncedFilter)} />

          </div>
        </div>
        <div className='col-md-3'>
          <div className="form-group">
            <label htmlFor="status">Status</label>
            <MultiSelect search={this.searchShopAppStates}
                                dropDownCloseMilliseconds={5000}
                                selected={this.state.shopAppStates}
                                onUpdate={(shopAppStates) => this.setState({ shopAppStates: shopAppStates }, this.debouncedFilter)} />
          </div>
        </div>
        {this.props.employee && this.renderTiersDropDown()}
        <div className='col-md-3'>
          <div className="form-group">
            <label htmlFor="user_status">User Status</label>
            <MultiSelect search={this.searchUserStatus}
                                selected={this.state.userStatus}
                                onUpdate={(userStatus) => this.setState({ userStatus: userStatus }, this.debouncedFilter)} />
          </div>
        </div>
      </div>
      <div style={buttonDiv} className='row'>
        <div className='col-md-12'>
          <a className="btn btn-default" style={buttons} onClick={ () => this.reset() }>Reset</a>
        </div>
      </div>
    </div>
  }

  renderTiersDropDown() {
    return <div className='col-md-3'>
      <div className="form-group">
        <label htmlFor="tiers">Tiers</label>
        <MultiSelect search={this.searchTiers}
                              dropDownCloseMilliseconds={3000}
                              selected={this.state.tiers}
                              onUpdate={(tiers) => this.setState({ tiers: tiers }, this.debouncedFilter)} />
      </div>
    </div>
  }

  reset() {
    var url = window.location.pathname + '/datatable?action=index&controller=admin%2Fshop_applications';
    this.setState({rounds:[], shopAppStates: [], tiers: [], userStatus: []})
    $('#shop_app_table').DataTable().ajax.url( url ).load();
  }

  filterShopApps() {
    var parameters = []
    var url = window.location.pathname + '/datatable?action=index&controller=admin%2Fshop_applications';
    if(this.state.rounds.length != 0 || this.state.shopAppStates.length != 0 || this.state.tiers.length != 0 || this.state.userStatus.length != 0){
      url += "&"
      if(this.state.rounds.length > 0){
        parameters.push('round=' + this.state.rounds.join());
      }
      if(this.state.shopAppStates.length > 0){
        parameters.push('status=' + this.state.shopAppStates.join());
      }
      if(this.state.tiers.length > 0){
        parameters.push('tier_id=' + this.state.tiers.join());
      }
      if(this.state.userStatus.length > 0){
        parameters.push('user_state=' + this.state.userStatus.join());
      }
      url += encodeURI(parameters.join('&'))
    }
    $('#shop_app_table').DataTable().ajax.url( url ).load();
  }

  searchShopAppStates(text, callback) {
    callback(JSON.parse(this.props.allShopAppStates));
  }

  searchRounds(text, callback) {
    callback(JSON.parse(this.props.allRounds));
  }

  searchTiers(text, callback) {
    callback(JSON.parse(this.props.allTiers));
  }

  searchUserStatus(text, callback) {
    callback(JSON.parse(this.props.allUserStatus));
  }
}