import React from 'react';
import PropTypes from 'prop-types'
export default class ActionAlert extends React.Component {

  constructor(props) {
    super(props);
    this.TypeToIconMap = {
      info: 'info-circle',
      success: 'check',
      warning: 'exclamation-triangle',
      error: 'exclamation-triangle',
      danger: 'exclamation-triangle'
    }
    this.linkRef = React.createRef();
    this.stopPropagation = this.stopPropagation.bind(this);
  }

  buildDisplayIcon(icon, type) {
    if (!icon) {
      return this.TypeToIconMap[type]
    }
    return icon;
  }


  stopPropagation(e) {
    e.stopPropagation();
  }

  render() {
    // actionProps can be used for passing additional props for links e.g: modal triggers
    const {type, icon, clickCallBack, text,actionText, actionDestination, ...actionProps} = this.props;
    const displayIcon = this.buildDisplayIcon(icon, type);
    return (
      <div className={`card clickable action-alert ${type}`} onClick={() => {this.linkRef.current.click()}}>
        <div className="action-alert-content">
          <div className="action-alert-icon">
            <i className={`fas fa-${displayIcon}`}></i>
          </div>
          <div className="action-alert-text">
            {text}
          </div>
        </div>
        {
          (!!actionText && !!actionDestination) && (
            <div className="action-alert-actions">
              <a ref={this.linkRef} onClick={this.stopPropagation} href={actionDestination} className="alert-action" {...actionProps}>
                {actionText}
              </a>
            </div>
          )
        }
      </div>
    )
  }
}

ActionAlert.displayName = 'ActionAlert';
ActionAlert.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.PropTypes.oneOf([
    'info', 'success', 'warning',
    'error', 'danger'
  ]),
  icon: PropTypes.string,
  clickCallBack: PropTypes.func,
  actionText: PropTypes.string,
  actionDestination: PropTypes.string
}

ActionAlert.defaultprops = {
  type: 'info',
  icon: 'info-circle',
  clickCallBack: () => {},
}
