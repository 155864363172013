import React from 'react';
export default class CampaignDeliverablesTable extends React.Component {
  constructor(props) {
    super(props)
    this.deliverableValues = this.deliverableValues.bind(this)
    this.render = this.render.bind(this)
  }

  deliverableValues(deliverable) {
    const budgets = this.props.deliverables.values[deliverable]
    let key = ''
    let tableCells = [];

    if (!budgets)
      return <td key='err'>No {deliverable} found.</td>

    tableCells.push(
      <td key={deliverable}>
        {deliverable.replace(/^\w/, c => c.toUpperCase())}
      </td>
    )

    for (const [budget, categories] of Object.entries(budgets)) {
      for (const [category, value] of Object.entries(categories)) {
        key = deliverable+'_'+budget+'_'+category;
        tableCells.push(
          <td className={value.raw < 0 ? 'text-danger' : ''} key={key}>
            {value.display}
          </td>
        )
      }
    }
    return tableCells;
  }

  render() {
    const shopDeliverable = this.props.deliverables.shop_deliverable
    const tableName = this.props.deliverables.table_name
    const deliverableBudget = this.props.deliverables.budget_name
    const values = this.props.deliverables.values
    const deliverables = Object.keys(values).filter(deliverable => deliverable !== 'total');


    return (
      <div>
        <h4>{tableName || 'Campaign Deliverables'}</h4>
        <div className='table-wrapper'>
          <div className='table-scroll'>
            <table className='table table-striped table-bordered'>
              <thead>
                <tr>
                  <th>Deliverable</th>
                  <th>{deliverableBudget} Goal</th>
                  <th>{deliverableBudget} Booked</th>
                  <th>{deliverableBudget} Remaining</th>
                  <th>Budget</th>
                  <th>Spent</th>
                  <th>Budget Remaining</th>
                </tr>
              </thead>
              <tbody>
                {
                  deliverables.map((deliverable, index) =>
                    <tr key={index}>
                      {this.deliverableValues(deliverable)}
                    </tr>
                  )
                }
                {
                  deliverables && deliverables.length > 1 &&
                  <tr>
                    {this.deliverableValues('total')}
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
