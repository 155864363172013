import React from 'react';

const AUTH_URLS = {
  instagram_auth: '/auth/facebook_instagram_business?external_sign_up=true',
  facebook_auth: '/auth/facebook_page?external_sign_up=true',
  youtube_auth: '/auth/google_youtube?external_sign_up=true'
}

export default class NewMemberLanding extends React.Component {

  constructor(props) {
    super(props);

    this.doBind();
  }

  handleAuth(event){
    event.preventDefault();
    const authType = event.currentTarget.id
    window.localStorage.setItem(authType, null)
    window.addEventListener('storage', () => this.authListener(authType))
    window.open(AUTH_URLS[authType], '_blank')
  }

  authListener(authType) {
    if (window.localStorage.getItem(authType) != 'null') {
      window.location.replace('/sign_up')
    }
  }

  render() {

    return (
      <div className='new-member-sign-up'>
        <h2>Join our Community!</h2>
        <hr/>
        <div className='new-member-requirements'>
          <h4>Membership Requirements</h4>
          <ul>
            <li>Currently reside in the U.S. or Canada</li>
            <li>Posts content that includes storytelling, original photos, and personal experiences</li>
            <li>Able to create content that weaves brand promotions into stories that show the product(s) fit into everyday life</li>
            <li>Able to act with professional behavior in regard to accountability and performance</li>
          </ul>
        </div>
        <div className='new-member-actions'>
          <a href='/sign_up' className='btn btn-primary get-started'>
            Get Started Now!
          </a>
          <h4 className='decorated'>
            <span>Or sign-up with</span>
          </h4>
          <div className='external-login'>
            <a href='#' id='instagram_auth' className='btn btn-social' onClick={(e) => this.handleAuth(e)}>
              <i className='fab fa-instagram'></i>
              <span>Instagram</span>
            </a>
            <a href='#' id='facebook_auth' className='btn btn-social' onClick={(e) => this.handleAuth(e)}>
              <i className='fab fa-facebook'></i>
              <span>Facebook</span>
            </a>
            <a href='#' id='youtube_auth' className='btn btn-social' onClick={(e) => this.handleAuth(e)}>
              <i className='fab fa-youtube'></i>
              <span>Youtube</span>
            </a>
          </div>
        </div>
      </div>
    )
  }

  doBind(){
    this.render = this.render.bind(this);
    this.authListener = this.authListener.bind(this);
    this.handleAuth = this.handleAuth.bind(this)
  }
}