import React from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import RoundsValidation from '../../../rounds/Validation'
import RoundTasksText from '../../../round_tasks/inputs/Text'
import RoundTasksSelect from '../../../round_tasks/inputs/Select'
import DatePicker from '../../../DatePicker'
import InputGroup from '../../../InputGroup'
import RichTextEditor from '../../../RichTextEditor'
import RoundTasksRequirements from '../../../round_tasks/Requirements'


class CampaignsRequirementsTaskRequirementInputs extends React.Component {

  constructor(props) {
    super(props);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.debouncedSync = _.debounce(this.props.updateRoundTask, 500);
    this.updateRequirementSort = this.updateRequirementSort.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    this.props.setEditingType(this.props.type);
  }

  paymentTypeHandler(value) {
    if (Array.isArray(value) && value.length === 0 )
      value = null

    this.props.editField('payment_type', value)
    this.props.editField('task_payment', null)
  }

  updateRequirementSort(from, to) {
    this.props.updateRequirementSort(this.props.requirement.id, from, to);
  }

  render(){
    const instructionApproved = this.props.instructionApproved || false;
    const requirement = this.props.requirement;
    const syndication = typeof(requirement.syndication) == 'Boolean' ? [requirement.syndication] : [false]
    const inputDescriptionStyle = this.props.isOpen && !instructionApproved ? { display: 'block' } : {display: 'none'}
    const viewDescriptionStyle = this.props.isOpen && !instructionApproved ? {display: 'none'} : {display: 'block'}
    const isDisabled = !this.props.isOpen;

    let textAreaPlaceholder = "Add descriptions..."
    let textAreaPlaceholderView = `<p>${textAreaPlaceholder}</p>`

    const containerStyle = {
      display: 'flex',
      flexFlow: 'column'
    }

    const formStyle = {
      display: 'flex',
      flexFlow: 'row wrap',
      alignItems: 'center',
      margin: '10px 5px 10px'
    }

    const formGroupStyle = {
      padding: '5px 10px',
      flexGrow: '1',
    }

    const largeFormStyle = {
      ...formGroupStyle,
      flexBasis: '40%',
      minWidth: '200px'
    }

    const mediumFormStyle = {
      ...formGroupStyle,
      flexBasis: '33%',
      minWidth: '125px',
      maxWidth: '33%'
    }

    const shortFormStyle = {
      ...formGroupStyle,
      flexBasis: '20%',
      minWidth: '125px'
    }

    if(requirement){
      return (
        <div style={containerStyle}>
          <div style={formStyle}>
            <div className='form-group' style={largeFormStyle}>
              <div className='input_wrap'>
                <label>
                  Task <RoundsValidation field='name' validations={requirement.validations} />
                </label>
                <RoundTasksText
                  name='name'
                  updateField={(name, value, idx) => this.props.editField('name', value)}
                  required={true}
                  placeholder='Name your task'
                  value={requirement.name}
                  disabled={isDisabled || instructionApproved}
                  />
              </div>
            </div>
            <div className='form-group' style={largeFormStyle}>
              <div className='input_wrap'>
                <label>
                  Content Type(s) <RoundsValidation field='content_types' validations={requirement.validations} />
                </label>
                <RoundTasksSelect
                  name='content_types'
                  updateField={(name, value, idx) => this.props.editField('content_types', value)}
                  index={null}
                  required={true}
                  placeholder='Select Content Types'
                  options={this.props.contentTypes}
                  value={this.props.requirement.content_types || []}
                  disabled={isDisabled}
                  multiSelect={true}
                  />
              </div>
            </div>
            <div className='form-group' style={shortFormStyle}>
              <div className='input_wrap'>
                <label>
                  Syndication <RoundsValidation field='syndication' validations={requirement.validations} />
                </label>
                <RoundTasksSelect
                  name='syndication'
                  updateField={(name, value, idx) => this.props.editField('syndication', value)}
                  index={null}
                  required={true}
                  options={this.props.requirement.submission_types || []}
                  value={this.props.requirement.syndication || false}
                  disabled={isDisabled}
                  />
              </div>
            </div>
            {
              // At the round level, we need to set the due date and pay
              requirement.template_type === 'instance' &&
              <React.Fragment>
                <div className='form-group' style={mediumFormStyle}>
                  <div className='input_wrap'>
                    <label>
                      Due Date
                      <RoundsValidation field='due_date' validations={requirement.validations} />
                    </label>
                    <DatePicker
                      className='form-control'
                      value={requirement.due_date || ''}
                      onChange={date => this.props.editField('due_date', date)}
                      disabled={isDisabled || instructionApproved}
                      />
                  </div>
                </div>
                {
                  this.props.payment  &&
                  <React.Fragment>
                    <div className='form-group' style={mediumFormStyle}>
                      <div className='input_wrap'>
                        <label>
                          Payment Type
                          <RoundsValidation field='payment_type' validations={requirement.validations} />
                        </label>
                        <RoundTasksSelect
                          name='payment_type'
                          updateField={(name, value, idx) => this.paymentTypeHandler(value)}
                          index={null}
                          required={true}
                          options={this.props.requirement.payment_types || []}
                          value={this.props.requirement.payment_type}
                          disabled={isDisabled || this.props.requirement.payment_locked || instructionApproved}
                          />
                      </div>
                    </div>
                    {
                      _.flatten([this.props.requirement.payment_type]).some(x => x && x.name == 'Flat Rate') &&
                      <div className='form-group' style={mediumFormStyle}>
                        <div className='input_wrap'>
                          <label>
                            Payment
                            <RoundsValidation field='task_payment' validations={requirement.validations} />
                          </label>
                          <InputGroup
                            type='number'
                            value={this.props.requirement.task_payment}
                            iconClass='fas fa-dollar-sign'
                            onChange={value => this.props.editField('task_payment', value)}
                            placeholder='Payment amount'
                            disabled={isDisabled || instructionApproved}
                            />
                        </div>
                      </div>
                    }
                  </React.Fragment>
                }
              </React.Fragment>
            }
          </div>
          <div className='description_area'>
            <div>
              <div style={inputDescriptionStyle}>
                <RichTextEditor
                  disabled={instructionApproved}
                  placeholder={textAreaPlaceholder}
                  value={requirement.description}
                  onUpdate={(html) => this.props.editField('description', html)}/>
              </div>
              <div className='description--view' style={viewDescriptionStyle}
                dangerouslySetInnerHTML={{__html: requirement.description || textAreaPlaceholderView}}
              />
            </div>
          </div>
          <RoundTasksRequirements
            instructionApproved={this.props.instructionApproved}
            isOpen={this.props.isOpen}
            proxyUrl={this.props.proxyUrl}
            editingType={this.props.editingType}
            displayPage={this.props.displayPage}
            updateRequirementField={this.props.updateRequirementField}
            updateRequirement={(name, value, idx) => this.props.updateRequirementField(name, value, idx)}
            roundTask={requirement}
            requirements={requirement.round_task_requirements}
            addRequirement={this.props.addRequirement}
            updateField={this.props.updateRequirementField}
            deleteRequirement={this.props.deleteRequirement}
            deleteRequirementById={this.props.deleteRequirementById}
            resetRequirement={(index) => this.props.updateRequirementState(this.props.resetRequirement, index)}
            disableRequirement={(index) => this.props.updateRequirementState(this.props.disableRequirement, index)}
            updateRequirementSort={this.updateRequirementSort}
            setRequirementSort={this.props.setRequirementSort}
            palette={this.props.palette}
            inCard={true}/>
        </div>
      );
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      setEditingType: type => dispatch({ type: 'SET_EDITING_TYPE', value: type }),
      updateRequirementSort: (taskId, from, to) => dispatch({
        type: 'UPDATE_REQUIREMENT_SORT',
        value: {
          taskId: taskId,
          from: from,
          to: to
        }
      })
    };
  }
}

CampaignsRequirementsTaskRequirementInputs.displayName = 'Campaigns.Requirements.TaskRequirementInputs';

CampaignsRequirementsTaskRequirementInputs.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  editingType: PropTypes.string,
  displayPage: PropTypes.string,
  updateRequirementField: PropTypes.func.isRequired,
  addRequirement: PropTypes.func.isRequired,
  deleteRequirement: PropTypes.func.isRequired,
  deleteRequirementById: PropTypes.func.isRequired,
  updateRequirementSort: PropTypes.func.isRequired,
  setRequirementSort: PropTypes.func.isRequired,
  palette: PropTypes.any,
  payment: PropTypes.bool
};

export default connect(
  null,
  CampaignsRequirementsTaskRequirementInputs.mapDispatchToProps
)(CampaignsRequirementsTaskRequirementInputs);
