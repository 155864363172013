import React from 'react';
import { connect } from 'react-redux';
import { Remote } from '../../../utils';

import LoadingSpinner from '../../../LoadingSpinner'
import GeneralRequirementsForm from '../Form'
import RoundTasksRequirements from '../../../round_tasks/Requirements'
import RoundTasksFormActions from '../../../round_tasks/FormActions'

class GeneralRequirementsNew extends React.Component {
  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.addRequirement = this.addRequirement.bind(this);
    this.deleteRequirement = this.deleteRequirement.bind(this);
    this.loadCommunities = this.loadCommunities.bind(this);
    this.loadRoundTask = this.loadRoundTask.bind(this);
    this.render = this.render.bind(this);
    this.submit = this.submit.bind(this);
    this.updateFieldValue = this.updateFieldValue.bind(this);
  }

  componentDidMount() {
    this.props.setEditingType('community_general');
    this.props.updateMeta({
      baseUrl: this.props.baseUrl,
      proxyUrl: this.props.proxyUrl,
      currentUserId: this.props.currentUserId,
    });
    this.loadRoundTask();
    this.loadCommunities();
  }

  loadRoundTask() {
    const params = $.param({
      api_action: `/round_tasks/new`,
      template_type: 'community_general'
    });

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'GET',
      responseType: 'json'
    }).then((response) => {
      this.props.setTask(response.data);
    });
  }

  loadCommunities() {
    this.props.startLoading();
    const params = $.param({ api_action: `/users/${this.props.currentUserId}/communities`});

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'GET',
    }).then(response => {
      this.props.setCommunities(response.data);
      this.props.stopLoading();
    });
  }

  addRequirement(req) {
    return Promise.resolve(this.props.addRequirement(req));
  }

  updateFieldValue(field, value, index=null) {
    if (index !== null) {
      this.props.updateRequirement(field, value, index)
    } else {
      this.props.updateField(field, value)
    }
  }

  deleteRequirement(index) {
    this.props.removeRequirement(index);
  }

  submit(data) {
    const params = $.param({ api_action: '/round_tasks' });
    Remote().request({
      method: "POST",
      url: this.props.proxyUrl + '?' + params,
      data: ({ ...data, template_type: 'community_general' })
    }).then(() => {
      window.location.href = this.props.baseUrl;
    }).catch((error) => {
      this.props.stopSubmitting();
      this.props.updateMeta({ error: true, errorText: "Internal Error. Please try again later." });
    });
  }

  render() {
    const cardStyle = {'position': 'relative'};

    return (
      <div>
        <div className='full-width-page-title--wrapper'>
          <h1 className='full-width-page-title--heading text-center'>New Community General Template</h1>
        </div>
        <div className='container main-container'>
          <div className='card-wrapper--white-bg' style={cardStyle}>
            <GeneralRequirementsForm {...this.props} updateField={this.updateFieldValue}/>
            <RoundTasksRequirements
              {...this.props}
              isOpen={true}
              requirements={this.props.roundTask.round_task_requirements || []}
              addRequirement={this.addRequirement}
              deleteRequirement={this.deleteRequirement}
              updateField={this.updateFieldValue}
              updateRequirementSort={this.props.updateRequirementSort} />
            <RoundTasksFormActions {...this.props} submit={this.submit}/>
            {this.props.error && <div className='text-center text-danger'>{this.props.errorText}</div>}
            {this.props.loading && <LoadingSpinner />}
          </div>
        </div>
      </div>
    );
  }
  static mapStateToProps(state, myProps) {
    return {
      ...state,
      ...myProps,
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      setCommunities: data => dispatch({ type: 'SET_COMMUNITIES', value: data }),
      setTask: data => dispatch({ type: 'SET_TASK', value: data }),
      updateMeta: data => dispatch({ type: 'UPDATE_META', value: data }),
      startLoading: () => dispatch({ type: 'START_LOADING' }),
      stopLoading: () => dispatch({ type: 'STOP_LOADING' }),
      startSubmitting: () => dispatch({ type: 'START_SUBMITTING'}),
      stopSubmitting: () => dispatch({ type: 'STOP_SUBMITTING'}),
      updateField: (field, value) => dispatch({ type: 'UPDATE_FIELD', value: {field: field, value: value } }),
      addRequirement: requirement => dispatch({type: 'ADD_REQUIREMENT', value: requirement }),
      removeRequirement: index => dispatch({type: 'REMOVE_REQUIREMENT', value: index }),
      resetRequirement: index => dispatch({type: 'RESET_REQUIREMENT', value: index }),
      disableRequirement: index => dispatch({type: 'DISABLE_REQUIREMENT', value: index }),
      updateRequirement: (field, value, index) => dispatch({type: 'UPDATE_REQUIREMENT', value: {field: field, value: value, index: index} }),
      setEditingType: type => dispatch({ type: 'SET_EDITING_TYPE', value: type }),
      updateRequirementSort: (from, to) => dispatch({
        type: 'UPDATE_REQUIREMENT_SORT',
        value: {
          from: from,
          to: to
        }
      })
    };
  }
};

GeneralRequirementsNew.displayName = 'GeneralRequirements.New';

export default connect(
  GeneralRequirementsNew.mapStateToProps,
  GeneralRequirementsNew.mapDispatchToProps
)(GeneralRequirementsNew);
