import React from 'react';
import PropTypes from 'prop-types'
import SubmissionStateIndicator from './content_types/StateIndicator'

export default class SubmissionInstagramDraftContentAttachment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: false
    }

    this.video = React.createRef();
    this.renderVideoPlayer = this.renderVideoPlayer.bind(this);
    this.setPlayingState = this.setPlayingState.bind(this);
    this.render = this.render.bind(this);
    this.isVideo = this.isVideo.bind(this);
  }

  isVideo(fileType){
    return String(fileType).includes("video")
  }

  render() {
    const { attachment } = this.props;
    const selected  = !!attachment.selected ? 'passed' : null
    const isVideo = this.isVideo(attachment.file_type);
    let background = null;
    if (isVideo) 
      background = {backgroundImage: `url(${attachment.media_url})`}
    else 
      attachment.media_url == undefined ? background = {backgroundImage: `url(${attachment.file.url})`} 
                                        : background = {backgroundImage: `url(${attachment.media_url})`};

    return (
      <div key={this.props.index}
           className={`submission grid-item instagram ${this.state.playing ? 'playing' : ''}`}>
        <div className='item-image' style={background}>
          <SubmissionStateIndicator state={selected} isSelected={attachment.selected} isVideo={isVideo} setPlayingState={this.setPlayingState}/>
        </div>
        {!this.props.readOnly && <div className='delete-item' onClick={()=> this.props.deleteAttachment(attachment)}><i className="fas fa-times" /></div>}
        { isVideo && this.renderVideoPlayer(attachment) }
      </div>
    )
  }

  renderVideoPlayer() {
    return (
      <video ref={this.video}
        className='video draft'
        onPause={(e) => this.setPlayingState(e, false)}
        src={this.props.attachment.media_url}
        preload='true'
        controls={this.state.playing}></video>
    )
  }

  setPlayingState(e, state) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      playing: state
    }, () => {
      if (state) {
        this.video.current.play();
      } else {
        this.video.current.pause();
      }
    });
  }
};

SubmissionInstagramDraftContentAttachment.displayName = 'Submission.InstagramDraftContentAttachment';
SubmissionInstagramDraftContentAttachment.propTypes = {
  index: PropTypes.number.isRequired,
  readOnly: PropTypes.bool.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  attachment: PropTypes.shape({
    file_type: PropTypes.string.isRequired,
    file: PropTypes.shape({
      url: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
