import React from 'react';

const TagInput = ({ tags, readOnly, onTagsChange, onTagDeletion, tagClassName, placeHolder }) => {

  const handleKeyDown = (e) => {
    const value = e.target.value;
    if (e.key !== 'Enter' || !value.trim()) return;
    onTagsChange(value);
    e.target.value = '';
  };

  const handleDeletion = (tagId) => {
    if (tagId) onTagDeletion(tagId);
  };

  return (
    <div className='tag-input-container'>
      {tags && tags.map(tag => (
        <div key={tag.id} className={`tag-item ${tagClassName}`}>
          <span className='tag-text'>{tag.name}</span>
          {!readOnly && (
            <span className='tag-close' onClick={() => handleDeletion(tag.id)}>
              &times;
            </span>
          )}
        </div>
      ))}
      {!readOnly && (
        <input
          type='text'
          onKeyDown={handleKeyDown}
          className='tag-input'
          placeholder={placeHolder}
        />
      )}
    </div>
  );
};

export default TagInput;
