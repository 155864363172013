class window.notification_utils

  no_notifs_msg = "<div class='alert alert-info slide-fade'>You do not have any notifications.</div>"

  @notification_dropdown_funcs: ->
    title = document.title.split(') ');
    title_text = title[1];
    this_ = this
    $('#notifications_counter').on 'show.bs.dropdown', ->
      if $(document).height() - $(window).height() > 0
        $('body').addClass('no-scroll')

      icon = "<i style='color:#187bd1;opacity:1;margin-left: 5px;' class='fas fa-sync fa-spin'></i>"

      $('#notifications_counter .js-notifications').scroll ->
        url = $('#header_notifications_dropdown .pagination .next_page a').attr('href')
        if url && $('#notifications_counter .js-notifications').prop('scrollHeight') - 100 < $('#notifications_counter .js-notifications').scrollTop() + $('#notifications_counter .js-notifications').height()
          original_text = $('.notif_drop_footer_link').html()
          new_text = 'Loading'
          $('.notif_drop_footer_link').html(new_text + icon)
          $('#header_notifications_dropdown .pagination').text('')
          $('.js-load-more-section').remove()
          $.ajax
            url: url
            type: 'GET'
            success: ->
              $('.notif_drop_footer_link').html(original_text)

    $('#notifications_counter').on 'hide.bs.dropdown', ->
      $('body').removeClass('no-scroll')

    $('#header_notifications_dropdown').click (e) ->
      e.stopPropagation()
      if e.target.type == 'button' && $(e.target).hasClass('js-destroy-notification')
        notification = e.target
        url = $(notification).data("url")
        $(notification).siblings().css('background', 'rgba(225, 34, 50, 0.4)')
        $(notification).parent().addClass('slide-fade')
        setTimeout ->
          $(notification).parent().remove()
        , 200
        $.ajax
          url: url
          type: 'DELETE'

    if $('.js-notif-count').length
      $('#notifications_counter').one 'click', (e) ->
        if $(e.target).hasClass('js-notif-count')
          e.stopPropagation()
          $('#js_notification_dropdown_toggle').dropdown('toggle')
        url = $(this).data('url')
        mark_as_seen(url)

    mark_as_seen = (url) ->
      $('#notifications_counter .js-notif-count').remove()
      if typeof(title_text) != 'undefined'
        document.title = title_text
      $.ajax
        url: url
        type: 'POST'

  @destroy_all_notifications: ->
    $('.js-clear-all-notifs').click ->
      if $('.js-notification').length
        url = $(this).data('url')
        $.ajax
          url: url
          type: 'POST'
          success: ->
            $('.js-load-more-section').remove()
            $('.js-notification').addClass('slide-fade')
            setTimeout ->
              $('.js-notification').remove()
            , 100
            $('#clear_all_notifs, #mark_all_notifs_read').attr('disabled', 'true')

            setTimeout ->
              $('.js-notifications').append(no_notifs_msg)
              setTimeout ->
                $('.fade-transition').addClass('show')
              , 100
            , 500

  @mark_all_read: ->
    $('.js-mark-all-notifs-read').click ->
      if $('.js-notification').length
        url = $(this).data('url')
        $.ajax
          url: url
          type: 'POST'
          success: ->
            $('.js-notification > a').addClass('viewed')
            $('.js-mark-all-notifs-read').attr('disabled', 'true')

  @destroy_notification: ->
    $('body').on 'click', '.js-destroy-notification', ->
      notif_klass = document.getElementsByClassName($(this).parent().prop('className'))
      this_ = $(this)
      $(this).siblings().css('background', 'rgba(225, 34, 50, 0.4)')
      $(this).parent().addClass('slide-fade')
      setTimeout ->
        $(notif_klass).remove()
      , 200
      url = $(this).data('url')
      $.ajax
        url: url
        type: 'DELETE'
        complete: ->
          if $('.js-notification').length <= 2
            $('.js-notifications').append(no_notifs_msg)
            setTimeout ->
              $('.fade-transition').addClass('show')
            , 500
            $('#mark_all_notifs_read, #clear_all_notifs').attr('disabled', 'true')
