import React from 'react';
import { connect } from 'react-redux'
import { Remote } from '../utils';

class TableDeleteConfirmation extends React.Component {
  constructor(props){
    super(props);

    this.render = this.render.bind(this);
    this.apiDelete = this.apiDelete.bind(this);
    this.state = {
      message: 'Are you sure you want to remove the selected record?'
    };
  }

  render() {
    const message = this.props.message || this.state.message;
    return (
      <div className="modal centered-modal" id="delete-roundtask-modal">
        <div className="modal-content modal-dialog">
          <div className="modal-header">
              <button className='close' data-dismiss='modal'><i className="fas fa-times"></i></button>
          </div>
          <div className="modal-body">
            <h4>{message}</h4>
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary" data-dismiss='modal' onClick={() => this.apiDelete()}>Yes</button>
            <button className='btn btn-default' data-dismiss='modal'>No</button>
          </div>
        </div>
      </div>
    );
  }

  apiDelete(){
    Remote().request({
      url: `${this.props.deletionUrl}/${this.props.deletionId}`,
      method: 'DELETE',
    }).then(() => {
      this.props.deleteDataRecord(this.props.deletionId)
    });
  }

  static mapStateToProps(state, myProps) {
    return {
      ...state,
      ...myProps
    };
  }

  static mapDispatchToProps(dispatch){
    return {
      deleteDataRecord: (deletionId) => dispatch({ type: 'DELETE_DATA_RECORD', value: deletionId})
    }
  }

};

TableDeleteConfirmation.displayName = 'Table.DeleteConfirmation';
export default connect(
  TableDeleteConfirmation.mapStateToProps,
  TableDeleteConfirmation.mapDispatchToProps
)(TableDeleteConfirmation);

