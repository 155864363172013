import React from 'react';
import ShoppertunitiesPaymentCalculator from '../shoppertunities/PaymentCalculator'

export default class RoundCard extends React.Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
  }

  render () {
    const payment = this.props.open_bid ? 'Open Bid' : `$${this.props.calc_final_payment + this.props.pre_payment}`;
    const platform = this.props.shop_type.toLowerCase();

    let uniqRoundTasks = [];
    if (this.props.round_tasks && this.props.round_tasks.length > 0) {
      uniqRoundTasks = _.uniqBy(this.props.round_tasks, task => task.name);
    }

    return (
      <div className='card round-card'>
        <div className='round-card-inner'>
          <span className='name'>{this.props.name}</span>
          <span className='due-date'>Due: {this.props.humanized_end_date}</span>
          <div className='platform-payment'>
            <i className={'platform fab social-icon--' + platform}></i>
            <span className='payment'>{payment}</span>
          </div>
          <span className='round-description' dangerouslySetInnerHTML={{__html: this.props.description}}></span>
          { uniqRoundTasks.length > 0 &&
            <div className='tasks chip-container'>
              {uniqRoundTasks.map((task, key) => {
                 return <span className='chip default' key={key}>{task.name}</span>;
              })}
            </div>
          }
          {this.props.open_bid &&
            <ShoppertunitiesPaymentCalculator {...this.props}/>
          }
        </div>
      </div>
    );
  }
};

RoundCard.displayName = 'Rounds.RoundCard';
