class window.Users
  @new_account_settings: ->
    @handle_warning()
    $('.js-notification-select').select2()

  @update_account_settings: ->
    @handle_warning()
    $('.js-notification-select').select2()

  @handle_warning: ->
    for node in $('#notification_preferences select')
      show_warning = node.value == 'false' || node.value == 'never'
      node.insertAdjacentHTML('afterend', """
        <div class='notification-settings-warning'>
          <small class='alert alert-warning text-warning #{'hidden' unless show_warning} #{node.id}_warning'>
            <i class='fas fa-exclamation-triangle'></i>
            #{node.dataset['warning']}
          </small>
        </div>
        """)

      $(node).on 'change', ->
        warning_message = $('.' + this.id + "_warning")
        if this.value == 'false' || this.value == 'never'
          warning_message.removeClass('hidden')
        else
          warning_message.addClass('hidden')
