import React from 'react';
import SkeletonCard from '../skeleton/Card'
import PageTitleBar from '../PageTitleBar'
import defaultThumb from '../../images/fallback/thumb_profile_pic.jpg'

export default class CommunitiesHeaderPreview extends React.Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
  }


  render() {
    const { theme_settings } = this.props;

    return <div className='community-header-preview'>
      <header className="navbar navbar-default" id="back_to_top_anchor">
        <div className="container">
          <div className="navbar-header">
            <div className="header--home-icons">
              {theme_settings.theme_image &&
                <a className="header--community-image" href="#">
                  <img src={theme_settings.theme_image} />
                </a>
              }
              {!theme_settings.theme_image &&
                <a className="header--home-logo navbar-brand" href="#">
                  <img src={this.props.sofabLogoPath} alt={'Social Fabric Logo'} />
                </a>
              }
            </div>
            <button className="navbar-toggle mobile-nav-toggle-btn collapsed" data-target="#sofab-main-nav"
                    data-toggle="collapse" type="button">
              <span>Toggle navigation</span>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="sofab-main-nav">
            <nav className="nav header--sofab-top-nav">
              <div className="navbar-right">
                <a className="js_show_search_bar nav-action-icon" href="#" title="Search">
                  <i className="fas fa-fw fa-search hidden-xs" />
                </a>
                <div className="count-bubble-group">
                  <a title="Messages" className="nav-action-icon" href="#">
                    <i className="fas fa-fw fa-envelope hidden-xs" />
                    <span className='notification_badge' title={99}>99+</span>
                  </a>
                </div>
                <div className="count-bubble-group dropdown"
                     data-url="/notifications/mark_as_seen"
                     id="notifications_counter">
                  <a className="dropdown-toggle hidden-xs" data-toggle="dropdown" href="#"
                     id="js_notification_dropdown_toggle" title="Notifications"
                  >
                    <i className="fas fa-fw fa-bell hidden-xs"></i>
                  </a>
                </div>
                <div className="dropdown user_dropdown pull-left" style={{marginTop: '10px'}}>
                  <a className="dropdown-toggle navatar" data-toggle="dropdown" href="#"
                     style={{backgroundImage: `url(${defaultThumb})`}}
                     aria-expanded="false" />
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
      <PageTitleBar
        title='Temporary Page Title'
        community_theme_settings={theme_settings}
      />
      <div className='header-preview--skeletons'>
        { _.range(3).map(idx => <SkeletonCard key={idx} />) }
      </div>

    </div>;
  }
}

CommunitiesHeaderPreview.displayName = 'Communities.HeaderPreview';
