import React from 'react';

import MessagesEvents from './MessagesEvents'
import LoadingSpinner from './LoadingSpinner'

export default class Show extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      totalPages: this.props.meta.pagination.total_pages,
      pagesLoaded: 1,
      messages: this.props.conversation.messages,
      events: this.props.conversation.events,
      text: '',
      loadMoreErrorMsg: null,
      replyErrorMsg: null,
      loading: false,
      sending: false
    };

    this.buildParticipantNames = this.buildParticipantNames.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.sendReply = this.sendReply.bind(this);
    this.persistText = this.persistText.bind(this);
    this.clearText = this.clearText.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
    this.render = this.render.bind(this);
  }

  render() {
    const loadMoreBtn = this.state.loading ? <LoadingSpinner loading={true} /> : 'Load More'

    const loadMoreBtnStyle = {
      'background': '#e4e8ec',
      'color': '#92979B',
      'marginBottom': '20px',
      'paddingTop': '10px',
      'paddingBottom': '10px',
      'cursor': 'pointer',
      'user-select': 'none'
    }

    const errorStyle = {
      background: 'none',
      color: '#B22828',
      paddingTop: '2px',
      paddingBottom: '0',
      border: 'none'
    };

    return(
      <div>
        <div className='row'>
          <div className='col-xs-12 back-link'>
            <a href={this.props.back_to_conversations_url}>
              <i className="fas fa-fw fa-arrow-left"></i> Back to my messages
            </a>
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-12'>
            <h2>{this.buildParticipantNames()}</h2>
          </div>
        </div>

        {
          (this.state.totalPages > 1) &&
          (this.state.pagesLoaded < this.state.totalPages) &&
          <div className='row'>
            <div className='col-xs-12'>
              <h4 className='text-center'
                  style={loadMoreBtnStyle}
                  onClick={this.loadMore}
                  disabled={this.state.loading}>
                {loadMoreBtn}
              </h4>
              {
              this.state.loadMoreErrorMsg &&
              <div className="alert alert-danger" style={errorStyle} role="alert">
                {this.state.loadMoreErrorMsg}
              </div>
            }
            </div>
          </div>
        }


        <div className='row'>
          <div className='col-xs-12' id='message_list'>
            <MessagesEvents
              messages={this.state.messages}
              events={this.state.events}
              sender={_.find(this.props.conversation.participants, ['current_user', true])}
            />
          </div>
        </div>


        { !this.props.viewOnly &&
          <div className='row'>
            <div className='col-xs-12'>
              <textarea
                className='form-control'
                placeholder='Enter message'
                rows='5'
                disabled={this.state.sending}
                onChange={this.persistText}
              >
              </textarea>
              {
                this.state.replyErrorMsg &&
                <div className="alert alert-danger" style={ErrorStyle} role="alert">
                  {this.state.replyErrorMsg}
                </div>
              }
              <div>
                <div className='alert alert-info mt-10'>
                  <i className='fas fa-exclamation-triangle fa-fw' title='Notice'></i>
                  {this.props.i18n.monitoring}
                </div>
                <div className="text-center mt-10">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={this.state.sending || this.state.text.length == 0}
                    onClick={this.sendReply}>
                    {this.state.sending ? <LoadingSpinner loading={true} text='Sending' /> : 'Reply'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }

  buildParticipantNames() {
    let currentUserParticipant = _.find(this.props.conversation.participants, ['current_user', true]);
    let recipients = _.filter(this.props.conversation.participants, ['current_user', false]);
    let names =_.map(recipients, recipient => {
      return recipient.first_name
    });


    if (currentUserParticipant) {
      return (`You, ${names.join(', ')}`);
    } else {
      return names.join(', ');
    }
  }


  loadMore() {
    this.clearErrors();
    this.setState({
      loading: true
    });

    var _this = this;
    var nextPage = this.state.pagesLoaded + 1
    $.get(`${this.props.conversations_path}/${this.props.conversation.id}/more?page=${nextPage}`).done(data => {
       _this.setState({
         messages: [...data.messages, ..._this.state.messages],
         pagesLoaded: nextPage
       });
     })
     .fail(error => {
       _this.setState({
         loadMoreErrorMsg: error.responseJSON.message
       });
     })
     .always(() => {
       _this.setState({
         loading: false
       });
     });
  }

  sendReply() {
    this.clearErrors()
    this.setState({
      sending: true
    });

    let sender = _.find(this.props.conversation.participants, ['current_user', true])
    let reply_params = {
      message: {
        body: this.state.text,
        conversation_id: this.props.conversation.id
      }
    };

    var _this = this;
    $.post(`${this.props.conversations_path}/${this.props.conversation.id}`, reply_params).done(data => {
       data['author'] = sender;
       _this.state.messages.push(data);
       _this.setState({
         messages: _this.state.messages
       });
       _this.clearText();
     })
     .fail((error) => {
       _this.setState({
         errorMsg: error.responseJSON.message
       });
     })
     .always(() => {
       _this.setState({
         sending: false
       });
     });
  }

  persistText(e) {
    this.setState({
      text: e.target.value
    });
  }

  clearText() {
    $('textarea').val('');
    this.setState({
      sending: false,
      text: ''
    });
  };

  clearErrors() {
    this.setState({
      loadMoreErrorMsg: null,
      replyErrorMsg: null
    });
  }
}
