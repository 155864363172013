import React from 'react';
import PropTypes from 'prop-types'

export default class NoOppsAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alertClass: "none",
      message: "",
    };
  }

  componentDidMount() {
    this.updateMessage();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.updateMessage();
    }
  }
  updateMessage() {
    const { phaseCount, phaseSelected, i18n } = this.props;
    this.setState({
      alertClass: phaseCount > 0 ? "none" : "block",
      message: phaseCount > 0 ? "" : _.get(i18n, phaseSelected, i18n["open"]),
    });
  }

  render() {
    const { message, alertClass } = this.state;
    return (
      <div
        className="alert alert-info no-shops-alert"
        style={{ display: alertClass }}
      >
        {message}
      </div>
    );
  }
};

NoOppsAlert.displayName = "Shoppertunities.NoOppsAlert";
NoOppsAlert.propTypes = {
  i18n: PropTypes.object.isRequired,
  phaseCount: PropTypes.number.isRequired,
  phaseSelected: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};
