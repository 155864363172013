import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { Remote } from '../utils';
import AuthModal from '../user_sign_up/AuthModal'
import ProfilesSocialAccountRow from './SocialAccountRow'

class ProfilesSocialAccounts extends React.Component {
  constructor(props) {
    super(props);

    this.platformCacheKey = this.platformCacheKey.bind(this);
    this.authDisabled = this.authDisabled.bind(this);
    this.authListener = this.authListener.bind(this);
    this.disconnectPlatform = this.disconnectPlatform.bind(this);
    this.handleAuth = this.handleAuth.bind(this);
    this.platformLabel = this.platformLabel.bind(this);
    this.platformName = this.platformName.bind(this);
    this.render = this.render.bind(this);
    this.renderPlatform = this.renderPlatform.bind(this);
  }

  platformName(platform) {
    switch(platform){
      case 'facebook_instagram_business':
        return 'instagram';
      case 'facebook_page':
        return 'facebook';
      case 'google_youtube':
        return 'youtube'
      default:
        return platform;
    }
  }

  platformLabel(platform) {
    switch(platform){
      case 'facebook_instagram_business':
        return 'Instagram';
      case 'facebook_page':
        return 'Facebook';
      case 'facebook_advertiser':
        return "Advertiser's Access";
      case 'google_youtube':
        return 'Youtube'
      case 'tiktok':
        return 'TikTok';
      default:
        return platform;
    }
  }

  disconnectPlatform(platform) {
    return Remote().request({
      method: 'GET',
      url: this.props.deauthUrls[platform],
    }).then(response => {
      this.props.resetSocialAccount(platform)
    });
  }

  platformCacheKey(platform){
    return `${this.platformName(platform)}_auth`
  }

  handleAuth(platform) {
    window.localStorage.setItem(this.platformCacheKey(platform), null);
    window.addEventListener('storage', () => this.authListener(platform));
    window.open(this.props.authConnectUrls[platform], '_blank');
  }

  authListener(platform) {
    const platformStorageKey = this.platformCacheKey(platform);
    if (window.localStorage.getItem(platformStorageKey) != 'null') {
      const storedItem = window.localStorage.getItem(platformStorageKey);
      // Guard here, sometimes the browser will trigger events when nothing has
      // happened to the key we care about
      if(!!storedItem) {
        window.localStorage.removeItem(platformStorageKey);
        this.props.setAuthConnection(platform, JSON.parse(storedItem));
      }
    };
  }

  authDisabled(platform) {
    if(platform != 'facebook_advertiser') {
      return false;
    }

    const instagramBusiness = this.props.accounts.find(x => x.provider == 'facebook_instagram_business');
    return !_.get(instagramBusiness, 'connected');
  }

  renderPlatform(platform) {
    if(!this.props.advertiserAuth && platform == 'facebook_advertiser'){
      return null;
    }

    const platformName = this.platformName(platform)
    const platformAccount = _.find(this.props.accounts, (account) => { return account.provider === platform });
    const disabled = this.authDisabled(platform);

    return <ProfilesSocialAccountRow
      key={platform}
      platform={platform}
      platformAccount={platformAccount}
      platformName={platformName}
      platformLabel={this.platformLabel(platform)}
      disabled={disabled}
      openModal={this.props.openAuthModal}
      auth={() => this.handleAuth(platform)}
      deAuth={() => this.disconnectPlatform(platform)}
      />;
  }
  render() {
    const socialPlatforms = [
      'facebook_page',
      'facebook_instagram_business',
      'facebook_advertiser',
      'twitter',
      'pinterest',
      'google_youtube',
      'tiktok'
    ];
    return (
      <div className='card-plus social-accounts-card'>
        <div className='card-header'>Connected Social Accounts</div>
        <div className='card-body'>
          {socialPlatforms.map(platform => {
            return this.renderPlatform(platform)
          })}
        </div>
        {this.props.authModal &&
          <AuthModal
            isOpen={this.props.authModal}
            closeModal={this.props.closeAuthModal}
            authType={this.props.authType}
            currentUser={this.props.username}
            currentUserId={this.props.userId}
            setAuthConnection={this.props.setAuthConnection}
          />
        }
      </div>
    );
  }

  static mapStateToProps(state, myProps) {
    return {
      ...state,
      accounts: (state.socialAccounts && state.socialAccounts.length) > 0 ? state.socialAccounts : myProps.accounts,
      authConnectUrls: myProps.authConnectUrls || state.authConnectUrls,
      deauthUrls:  myProps.deauthUrls || state.deauthUrls,
      blogs: state.blogs,
      authModal: state.authModal,
      authType: state.authType
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      resetSocialAccount: (platform) => dispatch({ type: 'RESET_SOCIAL_ACCOUNT', value: platform }),
      openAuthModal: (type) => dispatch({ type: 'OPEN_AUTH_MODAL', value: type }),
      closeAuthModal: () => dispatch({ type: 'CLOSE_AUTH_MODAL'}),
      setAuthConnection: (platform, connected) => dispatch({
        type: 'SET_AUTH_CONNECTION',
        value: {
          platform,
          connected
        }
      })
    };
  }
};

ProfilesSocialAccounts.displayName = 'Profiles.SocialAccounts';
ProfilesSocialAccounts.propTypes = {
  accounts: PropTypes.array.isRequired,
  authConnectUrls: PropTypes.object.isRequired,
  deauthUrls: PropTypes.object.isRequired,
  advertiserAuth: PropTypes.bool,
  username: PropTypes.string
};
export default connect(
  ProfilesSocialAccounts.mapStateToProps,
  ProfilesSocialAccounts.mapDispatchToProps
)(ProfilesSocialAccounts);
