import React from 'react';

export default class UserSignUpTosPrompt extends React.Component {
  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.render = this.render.bind(this);
    this.renderCommunityTos = this.renderCommunityTos.bind(this);
    this.renderTermsOfService = this.renderTermsOfService.bind(this);
  }

  componentDidMount() {
    this.props.disableNavigation(true);
  }

  renderTermsOfService() {
    const { termsOfService } = this.props;
    
    return <div dangerouslySetInnerHTML={{ __html: termsOfService }} />
  }

  renderCommunityTos() {
    const {
      communityTos,
      communityName,
      community_heading
    } = this.props;

    if(!this.props.communityName || !this.props.communityTos) {
      return null;
    }

    return <React.Fragment>
      <h4>{community_heading.replace('%{community}', communityName)}</h4>

      <p className='terms-of-service'>
        <div dangerouslySetInnerHTML={{__html: communityTos}}/>
      </p>
    </React.Fragment>;
  }

  render(){
    const { platform_heading, agreement } = this.props
    return (
      <div className='sign-up-tos-info'>
        <h4>{platform_heading}</h4>
        <div className='terms-of-service'>
          {this.renderTermsOfService()}
        </div>
        
        {this.renderCommunityTos()}

        <p className='agreement'>
          {agreement}
        </p>
      </div>
    )
  }
};

UserSignUpTosPrompt.displayName = 'UserSignUpTosPrompt';
