import React from 'react';
export default class LoadingSpinner extends React.Component {
  render() {
    const containerStyle = {
      'zIndex': '2',
      'position': 'absolute',
      'top': '0',
      'left': '0',
      'height': '100%',
      'width': '100%',
      'display': 'flex',
      'justifyContent': 'center',
      'alignItems': 'center',
      'background': 'rgba(255, 255, 255, 0.50)'
    }
    const boxStyle = {
      display: 'inline-block',
      padding: '10px 20px 10px 20px',
      backgroundColor: 'white',
      borderRadius: '4px',
      boxShadow: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)'
    };
    const textStyle = {
      fontSize: '12pt',
      paddingTop: '10px',
      fontWeight: '200'
    };
    const spinnerStyle = {
      color: '#59c58a'
    };

    return (
      <div style={containerStyle} className='text-center'>
        <div style={boxStyle}>
          <i style={spinnerStyle} className='fas fa-spin fa-4x fa-circle-notch' />
          <div style={textStyle}>
            Loading...
          </div>
        </div>
      </div>
    );
  };
};

LoadingSpinner.displayName = 'LoadingSpinner';
