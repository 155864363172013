class window.ValidateDate

  #
  # converted from
  #   http://www.w3resource.com/javascript/form/javascript-date-validation.php
  #
  isDate = (day, month, year) ->
    # Create list of days of a month [assume there is no leap year by default]
    ListofDays = [
      31
      28
      31
      30
      31
      30
      31
      31
      30
      31
      30
      31
    ]
    if month is 1 or month > 2
      if day > ListofDays[month - 1]
        return false
    if month is 2
      leapyear = false
      leapyear = true  if (not (year % 4) and year % 100) or not (year % 400)
      if (leapyear is false) and (day >= 29)
        return false
      if (leapyear is true) and (day > 29)
        return false
    true

  class this.DateField
    constructor: (@submit_button, @fields) ->

    bind_values: ->
      that = this

      # ensure that all fields pass validation
      check_button = ->
        all_pass = true
        for field in that.fields
          day   = parseInt( $("##{field}_3i").val() )
          month = parseInt( $("##{field}_2i").val() )
          year  = parseInt( $("##{field}_1i").val() )

          result   = isDate(day, month, year)
          all_pass = false unless result
        if all_pass
          $(that.submit_button).attr("disabled", false)

      # watch an individual field and call check_button after it changes
      watch = (field) ->
        $(".#{field}").on 'change', (e) ->
          $(".#{field}").removeClass('alert-error')

          day   = parseInt( $("##{field}_3i").val() )
          month = parseInt( $("##{field}_2i").val() )
          year  = parseInt( $("##{field}_1i").val() )

          result = isDate(day, month, year)

          unless result
            $(".#{field}").addClass('alert-error')
            $(that.submit_button).attr("disabled", "disabled")
          check_button()

      # watch each individual field passed in
      for field in that.fields
        watch(field)
