import React from 'react';
import { Remote } from '../utils';

import LoadingSpinner from '../LoadingSpinner'
import MonetaryValue from '../MonetaryValue'
import RichTextEditor from '../RichTextEditor'
import SaveIndicator from '../SaveIndicator'
import SaveError from '../SaveError'
import InstructionsCampaignRequirements from './CampaignRequirements'
import InstructionsReadOnlyRequirementCard from './read_only/RequirementCard'
import InstructionsIndependentContractorAgreement from './IndependentContractorAgreement'
import InstructionsReadOnlyCard from './read_only/Card'
import Guardrails   from '../Guardrails'
import GuardCaption from '../GuardCaption'

export default class InstructionsEdit extends React.Component {
  constructor(props) {
    super(props);
    let guardrail_labels = this.props.guardrail_labels;
    this.state = {
      instructionId: this.props.instruction_id,
      proxyUrl: this.props.proxy_url,
      required_links: [], //props.instructions.required_links.map(x => x.value),
      required_hashtags: [], //props.instructions.required_hashtags.map(x => x.value),
      saving: false,
      saveType: 'info',
      saveText: '',
      saveErrors: null,
      timeoutId: null,
      legacy: false,
      loading: true
    };

    this.debouncedUpdate = _.debounce(this.saveChanges.bind(this), 1000);
    this.debouncedInstructionNoteUpdate = _.debounce(this.instructionNoteUpdate.bind(this), 1000);

    this.componentDidCatch = this.componentDidCatch.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.deleteRequirement = this.deleteRequirement.bind(this);
    this.editRequirement = this.editRequirement.bind(this);
    this.flash = this.flash.bind(this);
    this.instructionNoteUpdate = this.instructionNoteUpdate.bind(this);
    this.loadInstructions = this.loadInstructions.bind(this);
    this.omitTaskRequirements = this.omitTaskRequirements.bind(this);
    this.render = this.render.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.toJson = this.toJson.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.updateTask = this.updateTask.bind(this);
    this.loadContentGuardrails = this.loadContentGuardrails.bind(this);
  }

  componentDidMount() {
    this.loadInstructions();
  }

  render() {
    if(this.state.loading) {
      return <div className='vh-100'>
        <LoadingSpinner />
      </div>;
    }

    const {
      header,
      footer,
      ica,
      pre_payment,
      round_tasks,
      revision_notes,
      state,
      instruction_notes,
      guardrails
    } = this.state;

    const contentCaptionConfigProps = [
      {
        key: 'dos',
        title: "Content Captions - Do's",
        tagClassName: 'tag-item-success',
        placeHolder: "Add Captions - Do's",
        check_operator: true,
        guardCaptions: this.props.guard_caption_dos
      },
      {
        key: 'donts',
        title: "Content Captions - Dont's",
        tagClassName: 'tag-item-danger',
        placeHolder: "Add Captions - Don'ts",
        check_operator: false,
        guardCaptions: this.props.guard_caption_donts
      }
    ]

    return (
      <div>
        <h3>Pre-Payment</h3>
        <div>
          <MonetaryValue value={pre_payment}/>
        </div>

        <h3>EXHIBIT A (Instructions)</h3>
        <RichTextEditor onUpdate={html => this.updateItem('header', html)} value={header}/>

        <InstructionsCampaignRequirements
          instructionNotes={instruction_notes}
          updateRequirement={this.editRequirement}
          deleteRequirement={this.deleteRequirement}
        />
        <InstructionsReadOnlyCard title='Additional Guardrails'>
           <p><strong data-redactor-tag="strong">Please ensure that your content does not include these following:</strong></p>
              <div className="radio">
                <table>
                  <tbody>
                    <tr>
                      <Guardrails guardrails={guardrails} instructionId={this.state.instructionId}
                         proxy_url={this.props.proxy_url}>
                      </Guardrails>
                    </tr>
                  </tbody>
                </table>
              </div>
          </InstructionsReadOnlyCard>

        {this.props.display_guard_captions && contentCaptionConfigProps.map((configProps) => (
          <InstructionsReadOnlyCard key={configProps.key} title={configProps.title}>
            <GuardCaption
              tagClassName={configProps.tagClassName}
              placeHolder={configProps.placeHolder}
              instructionId={this.props.instruction_id}
              shoppertunityId={this.props.shoppertunity_id}
              proxy_url={this.props.proxy_url}
              operator={configProps.check_operator}
              guardCaptions={configProps.guardCaptions}
              readOnly={false}
            />
          </InstructionsReadOnlyCard>
        ))}

        <div id='content_requirements'>
          <h3>Content Requirements</h3>
          {round_tasks.map((requirement, index) =>
            <InstructionsReadOnlyRequirementCard
              requirement={requirement}
              key={requirement.id}
              hideVerification={true}
            />
          )}
        </div>

        <h3>Accept or Decline this Opportunity</h3>
        <RichTextEditor value={footer} onUpdate={html => this.updateItem('footer', html)} />

        <InstructionsIndependentContractorAgreement text={ica} height={300} />

        {state == 'pending_update' &&
          <div>
            <h3>Instruction Updates</h3>
            <RichTextEditor
              value={revision_notes}
              placeHolder="What's changing for the influencer and why?"
              onUpdate={html => this.updateItem('revision_notes', html)}
            />
          </div>
        }

        <div className='text-center' style={{ marginTop: '16px' }}>
          <a href={this.props.show_url} disabled={this.state.saving} className='btn btn-primary'>
            Close
          </a>
        </div>

        {
          this.state.saving &&
          <SaveIndicator type={this.state.saveType}>
            {this.state.saveText}
            {this.state.saveErrors && <SaveError errors={this.state.saveErrors}/>}
          </SaveIndicator>
        }
      </div>
    );
  }

  updateItem(key, value) {
    this.setState({
      [key]: value
    }, this.debouncedUpdate);
  }

  componentDidCatch(error, info) {
    console.warn(error, info);
  }

  updateTask(task) {
    this.setState({
      round_tasks: this.state.round_tasks.map(x => x.id != task.id ? x : task)
    }, this.debouncedUpdate);
  }

  toJson() {
    const { header, footer } = this.state;
    return {
      header,
      footer
    };
  }

  omitTaskRequirements(state) {
    state.round_tasks = state.round_tasks.map((round) => _.omit(round, 'round_task_requirements'));
    return state;
  }

  saveChanges() {
    // Cancel the timeout associated with any current flash message
    if(this.state.timeoutId) {
      window.clearTimeout(this.state.timeoutId);
    }

    this.setState({
      saving: true,
      saveType: 'info',
      saveText: 'Saving instructions...',
      saveErrors: null,
      timeoutId: null
    });

    const params = $.param({
      api_action: `/instructions/${this.state.instructionId}`
    });

    Remote().request({
      url: this.props.proxy_url + '?' + params,
      data: this.toJson(),
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      this.flash({
        saveText: 'Successfully saved instructions.',
        saveType: 'success'
      }, 3000)
    }).catch((error) => {
      this.flash({
        saveText: 'Failed to save instructions! Please correct these errors:',
        saveType: 'failure',
        saveErrors: error
      }, 5000)
    });
  }

  flash(newState, duration) {
    this.setState(newState, () => {
      const timeoutId = window.setTimeout(() => this.setState({
        saving: false
      }), duration);

      this.setState({ timeoutId });
    });
  }

  loadInstructions() {
    const params = $.param({
      api_action: `/instructions/${this.state.instructionId}`
    });

    return Remote().request({
      method: 'GET',
      url: this.state.proxyUrl + '?' + params,
    }).then((response) => {
      this.setState({
        ...this.state,
        ...response.data,
        loading: false
      })
    });
  }

  editRequirement(id, change) {
    const reqs = this.state.instruction_notes.map(req => {
      if(req.id != id) {
        return req;
      }

      return {
        ...req,
        ...change
      };
    });

    this.setState({
      instruction_notes: reqs
    });

    this.debouncedInstructionNoteUpdate(id, change);
  }

  deleteRequirement(id) {
    const params = $.param({
      api_action: `/instruction_notes/${id}`
    });

    Remote().request({
      method: 'DELETE',
      url: this.state.proxyUrl + '?' + params,
    }).then(() => {
      this.setState({
        instruction_notes: this.state.instruction_notes.filter(x => x.id != id)
      });
    });
  }

  loadContentGuardrails(){
    const params = $.param({
      api_action: `/instructions/${this.state.instructionId}/guardrails`
    });

    return Remote().request({
      method: 'GET',
      url: this.state.proxyUrl + '?' + params,
    }).then((response) => {
      this.setState({
        ...this.state,
        ...response.data,
        loading: false
      })
    });
  }

  instructionNoteUpdate(id, change) {
    const params = $.param({
      api_action: `/instruction_notes/${id}`
    });

    this.setState({
      saving: true,
      saveType: 'info',
      saveText: 'Saving instruction note...',
      saveErrors: null,
      timeoutId: null
    });

    return Remote().request({
      method: 'PATCH',
      url: this.state.proxyUrl + '?' + params,
      data: {
        note: change
      },
    }).then(() => {
      this.flash({
        saveText: 'Successfully saved instructions.',
        saveType: 'success'
      }, 3000);
    }).catch(() => {
      this.flash({
        saveText: 'Failed to save instructions.',
        saveType: 'failure'
      }, 3000);
    });
  }
}

InstructionsEdit.displayName = 'Instructions.Edit';
