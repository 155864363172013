import React from 'react';
import { Remote } from '../utils';

import LoadingSpinner from '../LoadingSpinner'

export default class CommunitiesIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      communities: []
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.loadCommunities = this.loadCommunities.bind(this);
    this.newCommunity = this.newCommunity.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    this.loadCommunities();
  }

  render() {
    if(this.state.loading) {
      return <LoadingSpinner />;
    }

    return <div>
      <button className='btn btn-primary' onClick={this.newCommunity}>
        New Community
      </button>
      <table className='table table-striped'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {
            this.state.communities.map(c => (
              <tr key={c.id}>
                <td>{c.id}</td>
                <td>
                  <a href={`/communities/${c.id}`}>{c.name}</a>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>;
  }

  loadCommunities() {
    const params = $.param({ api_action: '/communities' });

    Remote().request({
      method: 'GET',
      url: this.props.proxyUrl + '?' + params,
      
    }).then((response) => {
      this.setState({
        loading: false,
        communities: response.data
      });
    });
  }

  newCommunity() {
    const params = $.param({ api_action: '/communities' });

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'POST',
      
    }).then((response) => {
      window.location.href = `/communities/${response.data.id}`;
    });
  }
};

CommunitiesIndex.displayName = 'Communities.Index';
