import React from 'react';
class InstagramContentCard extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.selectThisCard = this.selectThisCard.bind(this);
    this.createInstagramUrl = this.createInstagramUrl.bind(this);
    this.selectionClass = this.selectionClass.bind(this);
  }

  render() {
    const post = this.props.post;

    return (
      <div className={`post ${this.selectionClass()}`} onClick={this.selectThisCard}>
        <div className="preview">
          <img src={post.image_url} />
        </div>
        <div className="caption-text">
          <InstagramTextPreview caption={post.caption} />
        </div>
      </div>
    );
  }

  selectThisCard() {
    this.props.updateSelectedUrl(this.createInstagramUrl());
  }

  createInstagramUrl() {
    return `https://instagram.com/p/${this.props.post.shortcode}/`;
  }

  selectionClass() {
    if (!this.props.selected) {
      return "";
    }

    return "post-selected";
  }
}
