var plugin_list = ['bufferbuttons', 'underline', 'fontcolor', 'backcolor', 'fontfamily', 'fontsize'];
window.plugin_list = plugin_list

if (typeof RedactorPlugins === 'undefined') {var RedactorPlugins = {}};

RedactorPlugins.bufferbuttons = function()
{
  return {
    init: function()
    {
      var undo = this.button.addFirst('undo', 'Undo');
      var redo = this.button.addAfter('undo', 'redo', 'Redo');

      this.button.addCallback(undo, this.buffer.undo);
      this.button.addCallback(redo, this.buffer.redo);
    }
  };
};
RedactorPlugins.fontcolor = function()
{
  return {
    init: function()
    {
      var colors = [
        '#ffffff', '#000000', '#eeece1', '#1f497d', '#4f81bd', '#c0504d', '#9bbb59', '#8064a2', '#4bacc6', '#f79646', '#ffff00',
        '#f2f2f2', '#7f7f7f', '#ddd9c3', '#c6d9f0', '#dbe5f1', '#f2dcdb', '#ebf1dd', '#e5e0ec', '#dbeef3', '#fdeada', '#fff2ca',
        '#d8d8d8', '#595959', '#c4bd97', '#8db3e2', '#b8cce4', '#e5b9b7', '#d7e3bc', '#ccc1d9', '#b7dde8', '#fbd5b5', '#ffe694',
        '#bfbfbf', '#3f3f3f', '#938953', '#548dd4', '#95b3d7', '#d99694', '#c3d69b', '#b2a2c7', '#b7dde8', '#fac08f', '#f2c314',
        '#a5a5a5', '#262626', '#494429', '#17365d', '#366092', '#953734', '#76923c', '#5f497a', '#92cddc', '#e36c09', '#c09100',
        '#7f7f7f', '#0c0c0c', '#1d1b10', '#0f243e', '#244061', '#632423', '#4f6128', '#3f3151', '#31859b',  '#974806', '#7f6000'
      ];

      var buttons = ['fontcolor', 'backcolor'];
      var prev_buttons = ['deleted', 'fontcolor'];

      for (var i = 0; i < 2; i++)
      {
        var name = buttons[i];
        var prev_name = prev_buttons[i];

        var button = this.button.addAfter(prev_name, name, this.lang.get(name));
        var $dropdown = this.button.addDropdown(button);

        $dropdown.width(242);
        this.fontcolor.buildPicker($dropdown, name, colors);

      }
    },
    buildPicker: function($dropdown, name, colors)
    {
      var rule = (name == 'backcolor') ? 'background-color' : 'color';

      var len = colors.length;
      var self = this;
      var func = function(e)
      {
        e.preventDefault();
        self.fontcolor.set($(this).data('rule'), $(this).attr('rel'));
      };

      for (var z = 0; z < len; z++)
      {
        var color = colors[z];

        var $swatch = $('<a rel="' + color + '" data-rule="' + rule +'" href="#" style="float: left; font-size: 0; border: 2px solid #fff; padding: 0; margin: 0; width: 22px; height: 22px;"></a>');
        $swatch.css('background-color', color);
        $swatch.on('click', func);

        $dropdown.append($swatch);
      }

      var $elNone = $('<a href="#" style="display: block; clear: both; padding: 5px; font-size: 12px; line-height: 1;"></a>').html(this.lang.get('none'));
      $elNone.on('click', $.proxy(function(e)
      {
        e.preventDefault();
        this.fontcolor.remove(rule);

      }, this));

      $dropdown.append($elNone);
    },
    set: function(rule, type)
    {
      this.inline.format('span', 'style', rule + ': ' + type + ';');
    },
    remove: function(rule)
    {
      this.inline.removeStyleRule(rule);
    }
  };
};
RedactorPlugins.fontfamily = function()
{
  return {
    init: function ()
    {
      var fonts = [ 'Arial', 'Helvetica', 'Georgia', 'Times New Roman', 'Monospace' ];
      var that = this;
      var dropdown = {};

      $.each(fonts, function(i, s)
      {
        dropdown['s' + i] = { title: s, func: function() { that.fontfamily.set(s); }};
      });

      dropdown.remove = { title: 'Remove Font Family', func: that.fontfamily.reset };

      var button = this.button.addAfter('formatting', 'fontfamily', 'Change Font Family');
      this.button.addDropdown(button, dropdown);

    },
    set: function (value)
    {
      this.inline.format('span', 'style', 'font-family:' + value + ';');
    },
    reset: function()
    {
      this.inline.removeStyleRule('font-family');
    }
  };
};
RedactorPlugins.fontsize = function()
{
  return {
    init: function()
    {
      var fonts = [10, 11, 12, 14, 16, 18, 20, 24, 28, 30];
      var that = this;
      var dropdown = {};

      $.each(fonts, function(i, s)
      {
        dropdown['s' + i] = { title: s + 'px', func: function() { that.fontsize.set(s); } };
      });

      dropdown.remove = { title: 'Remove Font Size', func: that.fontsize.reset };

      var button = this.button.addAfter('fontfamily', 'fontsize', 'Change Font Size');
      this.button.addDropdown(button, dropdown);
    },
    set: function(size)
    {
      this.inline.format('span', 'style', 'font-size: ' + size + 'px;');
    },
    reset: function()
    {
      this.inline.removeStyleRule('font-size');
    }
  };
};
RedactorPlugins.underline = function(){
  return {
    init: function() {
      var button = this.button.addAfter('italic', 'underline', 'Underline')
      this.button.addCallback(button, this.underline.format)
    },
    format: function() {
      this.inline.format('u')
    }
  }
}

window.RedactorPlugins = RedactorPlugins
export default RedactorPlugins;