import React from 'react';

export default class RoundTasksTextArea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      height: null
    }

    this.textarea = React.createRef();
    this.componentDidMount = this.componentDidMount.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    this.setState({
      height: this.textarea.current.offsetHeight
    })
  }

  collapseTextArea() {
    this.textarea.current.style["height"] = `${this.state.height}px`;
  }

  onBlurHandler(event) {
    const height = event.target.offsetHeight;
    if (height !== this.state.height) {
      this.collapseTextArea();
    }
  }

  changeHandler(event, value=null) {
    const { index, name, required } = this.props;

    if (!value) {
      value = event.target.value;
    }

    this.props.updateField(name, value, index);
  }

  onFocusHandler(event){
    const scroll_height = event.target.scrollHeight + 1;
    this.textarea.current.style["height"] = `${scroll_height}px`;
  }


  render() {
    const { name, required, placeholder, error, value, disabled } = this.props;
    let klass = 'form-control'
    if (error && error.length > 0) {
      klass += ' input-error'
    }

    return(
      <div>
        <textarea
          ref={this.textarea}
          style={{minHeight: '36px'}}
          className={klass}
          rows='1'
          name={name}
          required={required}
          onChange={(e) => this.changeHandler(e)}
          onBlur={(e) => this.onBlurHandler(e)}
          onMouseDown={() => this.textarea.current.focus()}
          onMouseUp={() => this.textarea.current.focus()}
          onFocus={(e) => this.onFocusHandler(e)}
          placeholder={placeholder}
          value={value || ''}
          disabled={disabled}
          />
      </div>
    )
  }
};

RoundTasksTextArea.displayName = 'RoundTasks.TextArea';
