import React from 'react'
import { FileUploader } from '../utils';
import axios from 'axios';

export default class AuxillaryContentsTiktokVideo extends React.Component {
  METRIC_FIELD_INPUTS = ["views", "likes", "comments", "shares", "followers"];

  constructor(props) {
    super(props);

    const defaultMetrics = this.METRIC_FIELD_INPUTS.reduce((hsh, val) => {
      hsh[val] = props.media[0][val] || props[val] || 0;
      return hsh;
    }, {});

    this.state = {
      handle: props.handle || null,
      oembed_html: props.oembed_html || null,
      ...defaultMetrics,
      image_url: props.media[0].image_url || null,
      loading: false,
      error: null,
    };

    this.fetchTiktokPost = this.fetchTiktokPost.bind(this);
    this.formatTiktokData = this.formatTiktokData.bind(this);
    this.saveVal = this.saveVal.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.save = this.save.bind(this);

    this.debouncedUpdate = _.debounce(this.saveVal, 500);
  }

  componentDidMount() {
    this.fetchTiktokPost();
  }

  fetchTiktokPost() {
    const params = $.param({
      url: this.props.url,
    });

    axios
      .get("https://www.tiktok.com/oembed" + "?" + params)
      .then((response) => {
        this.setState(this.formatTiktokData(response.data), () => {
          const script = document.createElement("script");
          script.async = true;
          script.src = "https://www.tiktok.com/embed.js";
          document.head.appendChild(script);
        });
      });
  }

  formatTiktokData(data) {
    const { image_url } = this.state;
    return {
      title: "TikTok Video Content",
      handle: data["author_name"],
      oembed_html: data["html"],
      image_url: image_url ? image_url : data["thumbnail_url"],
      description: data["title"],
    };
  }

  saveVal(prop, value) {
    if (!!value && !isNaN(parseInt(value))) {
      this.setState({ [prop]: parseInt(value) });
    }
  }

  uploadImage() {
    const { image_url } = this.state;
    const { upload_url } = this.props;
    this.setState({ loading: true });
    const formData = new FormData();
    formData.append("file_url", image_url);
    FileUploader()
      .request({
        url: upload_url + "?type=tiktok",
        method: "POST",
        data: formData,
      })
      .then((response) => {
        this.setState({ image_url: response.data.image_url }, () => {
          this.save();
        });
      })
      .catch((jXHR, textStatus, error) => {
        console.log(jXHR);
        console.log(textStatus);
        this.setState({ error: error, loading: false });
      });
  }

  save() {
    const { title, image_url, handle, description } = this.state;
    const { type, setValue, onSave } = this.props;
    const metricData = this.METRIC_FIELD_INPUTS.reduce((hsh, val) => {
      hsh[val] = this.state[val] || 0;
      return hsh;
    }, {});
    const followers = metricData["followers"];
    delete metricData["followers"];
    this.setState({ loading: true });

    let media = [{ ...metricData, image_url }];
    setValue(
      "contentMeta",
      { type, media, title, handle, description, followers },
      onSave
    );
  }

  render() {
    const { oembed_html, loading } = this.state;
    const { disabled } = this.props;
    const saveAction = disabled ? this.save : this.uploadImage;

    return (
      <div
        style={{
          width: "100%",
          padding: "10px 0",
          boxShadow:
            "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h3>Video Details</h3>
              <hr />
            </div>
            <div className="col-md-9">
              <div
                className="tiktok-content-embed"
                dangerouslySetInnerHTML={{ __html: oembed_html }}
              />
            </div>
            <div className="col-md-3">
              {this.METRIC_FIELD_INPUTS.map((key) => this.renderField(key))}
            </div>
            <div className="col-md-12 text-right">
              <hr />
              <button
                className="btn btn-primary"
                disabled={loading}
                onClick={saveAction}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  captialize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  getMetricData(key) {
    return [this.state[key], this.captialize(key)];
  }

  INLINE_STYLE = {
    margin: "0 5px",
  };

  renderField(metric) {
    let [init, label] = this.getMetricData(metric);
    return (
      <div key={metric} className="form-group">
        <label htmlFor={metric} style={this.INLINE_STYLE}>
          {label}
        </label>
        <input
          defaultValue={init}
          onChange={(event) => {
            this.debouncedUpdate(metric, event.target.value);
          }}
          type="number"
          className="form-control"
        />
      </div>
    );
  }
}
AuxillaryContentsTiktokVideo.displayName = 'AuxillaryContent.TikTokVideo';
