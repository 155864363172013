import React from 'react';
import Prism from 'prismjs';
import 'prismjs/components/prism-jsx'
import 'prismjs/themes/prism-okaidia.css'
import Tooltip from '../Tooltip'

export default class LivingStyleGuideTooltips extends React.Component {

  componentDidMount(){
    Prism.highlightAll()
  }

  render() {
    //TODO: Change tooltips to be other styles when they become available
    return (
      <div id='tooltips' className='style-guide-section'>
        <h1 className='section-header'>Tooltips</h1>
        <div className='section-description'>

        </div>
        <div className='styles-container'>
          <div className='tooltip-styles'>
            <div className="card">
              <div className="card-header">Dark(Default)<code>color="dark"</code></div>
              <div className="card-body">
                <div className='examples'>
                  <div className='btn primary' style={{ position: 'relative', display: 'block', margin: 'auto', width: '250px' }}>
                    I have questions about this button.
                    <Tooltip centerText={false} placement='left' message={"This is a tooltip on the left"} />
                    <Tooltip centerText={true} placement='top' message={"This is a tooltip on top."} />
                    <Tooltip centerText={false} placement='right' message={"This is a tooltip on the right."} />
                    <Tooltip centerText={true} placement='bottom' message={"This is a tooltip on the bottom."} />
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">Light <code>color="light"</code></div>
              <div className="card-body">
                <div className='examples'>
                  <div className='btn primary' style={{ position: 'relative', display: 'block', margin: 'auto', width: '250px' }}>
                    I have questions about this button.
                    <Tooltip centerText={false} placement='left' color="light" message={"This is a tooltip on the left"} />
                    <Tooltip centerText={true} placement='top' color="light" message={"This is a tooltip on top."} />
                    <Tooltip centerText={false} placement='right' color="light" message={"This is a tooltip on the right."} />
                    <Tooltip centerText={true} placement='bottom' color="light" message={"This is a tooltip on the bottom."} />
                  </div>
                </div>
              </div>
            </div>
            <div className='code-example'>
              <pre className="language-jsx">
                <code className="language-jsx">
                  {`
                    toggleTooltip(button) {
                      this.setState({
                        ...this.state,
                        tooltip: {
                          button: button,
                          visible: !this.state.tooltip.visible
                        }
                      })
                    }

                    render() {
                      const { tooltip: { button, visible } } = this.state;
                      return (
                        <div
                          onMouseLeave={() => this.toggleTooltip('thisTooltipButton')}
                          onMouseEnter={() => this.toggleTooltip('thisTooltipButton')}
                        >
                          Some text with a tooltip
                          { (visible && button === 'thisTooltipButton') &&
                            <Tooltip centerText={true} placement='bottom' message={tooltipText}/>
                          }
                        </div>
                      );
                    }
                  `}
                </code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
LivingStyleGuideTooltips.displayName = 'LivingStyleGuide.Tooltips';