import React from 'react';
import PropTypes from 'prop-types'
import RoundTaskSelectionRow from './RoundTaskSelectionRow'

export default class RoundTaskSelection extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.toggleRow = this.toggleRow.bind(this);
  }

  render() {
    return <div className='round-tasks selection'>
      {
        this.props.options.map(task =>
          <RoundTaskSelectionRow
            key={task.id}
            onSelect={() => this.toggleRow(task.id)}
            roundTask={task}
            selected={_.includes(this.props.selected, task.id)}
            />
        )
      }
    </div>;
  }

  toggleRow(id) {
    if(_.some(this.props.selected, taskId => taskId === id)) {
      this.props.onSelect(this.props.selected.filter(taskId => taskId != id));
      return;
    }

    this.props.onSelect([...this.props.selected, id]);
  }
}

RoundTaskSelection.displayName = 'RoundTasks.RoundTaskSelection';

RoundTaskSelection.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired
};
