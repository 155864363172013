import React from 'react';
import { Provider } from "react-redux";
import AdminContentStore from '../redux/AdminContentStore'
import AdminOtherContentManualMetrics from "./AdminOtherContentManualMetrics";

class AdminOtherContentManualMetricsWrapper extends React.Component {
  render() {

    return <Provider store={AdminContentStore}>
      <AdminOtherContentManualMetrics {...this.props} />
    </Provider>;
  }
};

AdminOtherContentManualMetricsWrapper.displayName = 'Contents.AdminOtherContentManualMetricsWrapper';
AdminOtherContentManualMetricsWrapper.propTypes = {};
export default AdminOtherContentManualMetricsWrapper;


