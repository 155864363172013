import React from 'react';
import PropTypes from 'prop-types'
import defaultProfileImage from '../../images/fallback/profile_pic.jpg';

export default class ProfilesPublicInfo extends React.Component {
  render() {
    if (this.props.profile) {
      const { bio, full_name, last_active, member_since, profile_picture_url } = this.props.profile

      return (
        <div className='profile-public-info-container'>
          <div className='basic-info'>
            <div className='profile-photo' style={{backgroundImage: `url(${profile_picture_url ? profile_picture_url : defaultProfileImage})`}}></div>
            <div className='about'>
              <h1>{full_name}</h1>
              <p>{bio}</p>
            </div>
          </div>
          <div className='dates'>
            <span className='member-since'>
              <i className='far fa-calendar'></i>
              Joined {member_since}
            </span>
            <span className='last-active'>
              <i className='far fa-clock'></i>
              Last active {last_active} ago
            </span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
};

ProfilesPublicInfo.displayName = 'Profiles.PublicInfo';
ProfilesPublicInfo.propTypes = {
  profile: PropTypes.object.isRequired
};