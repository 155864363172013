import React from 'react';
import { Provider } from "react-redux"
import ProfileStore from '../redux/ProfileStore';
import ProfilesEdit from './Edit'

export default class ProfilesEditWrapper extends React.Component {
  render() {
    return <Provider store={ProfileStore}>
      <ProfilesEdit {...this.props} />
    </Provider>;
  }
};

ProfilesEditWrapper.displayName = 'Profiles.EditWrapper';
