import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Remote } from '../../utils';

import RichTextEditor from '../../RichTextEditor'
import BackgroundFade from '../../BackgroundFade'
import '../../../plugins/annotator-full.min.js'


class SubmissionBlogDraft extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visualEditor: true,
      copyText: 'Copy HTML to Clipboard'
    };

    this.draftRef = React.createRef();

    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.copyHtml = this.copyHtml.bind(this);
    this.hasAnnotations = this.hasAnnotations.bind(this);
    this.render = this.render.bind(this);
    this.renderAnnotationButtons = this.renderAnnotationButtons.bind(this);
    this.swapText = this.swapText.bind(this);
    this.validatePost = this.validatePost.bind(this);
  }

  render() {
    if(this.props.readOnly) {
      return <div className='submission-blog-draft'>
        {this.renderAnnotationButtons()}
        <label>Blog post content</label>
        <div className='pull-right'>
          <button className='btn btn-primary' onClick={this.copyHtml}>
            <i className='fas fa-copy'/> {this.state.copyText}
          </button>
        </div>
        <h4>{this.props.submission.title}</h4>
        <div ref={this.draftRef} dangerouslySetInnerHTML={{__html: this.props.submission.draft.body}}/>
      </div>
    }

    return <div className='submission-blog-draft'>
      {this.renderAnnotationButtons()}
      <div className='form-group'>
        <label>Title</label>
        <input
          className='form-control'
          placeholder='Title'
          value={this.props.title || ''}
          onChange={e => this.props.updateTitle(e.target.value)}
          />
      </div>

      <label>Draft Body</label>
      {
        this.state.visualEditor ?
          <RichTextEditor onUpdate={html => this.props.updateBody(html)} value={this.props.body || ''} minHeight='50vh'/>
          :
          <div className='form-group'>
            <textarea
              className='form-control'
              rows={20}
              value={this.props.body || ''}
              onChange={e => this.props.updateBody(e.target.value)}/>
          </div>
      }
      <div className='blog-draft-buttons'>
        <button className='btn btn-block btn-primary' onClick={this.validatePost}>
          Validate Post
        </button>
        <button className='btn btn-block' onClick={() => this.setState({ visualEditor: !this.state.visualEditor })}>
          {this.swapText()}
        </button>
      </div>
    </div>;
  }

  componentDidUpdate(prevProps) {
    if(this.props.readOnly && this.props.submission.draft.body) {
      this.validatePost();

      if(this.draftRef.current.querySelectorAll('.annotator-wrapper').length == 0) {
        const annotator = $(this.draftRef.current).annotator({ readOnly: true });
        annotator.annotator('loadAnnotations', _.cloneDeep(this.props.annotations)); // Spread the array here -- annotator mutates the array
      }
    }
  }

  swapText() {
    if(this.state.visualEditor) {
      return 'Switch to HTML view';
    }

    return 'Switch to editor';
  }

  validatePost() {
    this.props.setLoading(true);

    const params = $.param({
      api_action: `/blogger_shop_tasks/${this.props.bloggerShopTaskId}/validate`
    });

    return Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'POST',
      data: {
        ...this.props.submission,
        draft: {},
        draft_attributes: this.props.submission.draft,
        type: 'DraftContent'
      },
    }).then((response) => {
      this.props.setValidations(response.data);
    }).then(() => { this.props.setLoading(false) });
  }

  hasAnnotations() {
    return this.props.annotations.length !== 0;
  }

  renderAnnotationButtons() {
    // If the display is large enough, never render buttons or backgrounds
    if(!this.hasAnnotations() || this.props.screenWidth > 1024) {
      return null;
    }

    if(this.props.showAnnotations) {
      return <BackgroundFade onClick={() => this.props.toggleAnnotations(false)} zIndex={10}/>;
    }

    return <FloatingActionButton
      icon='list'
      right='10px'
      bottom='120px'
      color='red'
      invertText
      onClick={() => this.props.toggleAnnotations(true)}
      />;
  }

  copyHtml() {
    this.setState({ copyText: 'HTML Copied to clipboard!' });
    navigator.clipboard.writeText(this.props.submission.draft.body);

    setTimeout(() => {
      this.setState({ copyText: 'Copy HTML to Clipboard' });
    }, 5000);
  }

  static mapStateFromProps(state, myProps) {
    return {
      ...myProps,
      bloggerShopTaskId: state.bloggerShopTask.id,
      submission: state.submission,
      proxyUrl: state.meta.proxyUrl,
      body: state.submission.draft.body,
      readOnly: state.readOnly,
      title: state.submission.title,
      annotations: _.get(state.bloggerShopTask, 'content.draft.annotations') || [],
      showAnnotations: state.showAnnotations,
      screenWidth: state.meta.screenWidth
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateBody: html => dispatch({ type: 'UPDATE_DRAFT', value: { body: html }}),
      updateTitle: title => dispatch({ type: 'UPDATE_SUBMISSION', value: { title: title }}),
      setValidations: validations => dispatch({ type: 'SET_VALIDATIONS', value: validations }),
      setLoading: loading => dispatch({ type: 'SET_LOADING', value: loading }),
      toggleAnnotations: flag => dispatch({ type: 'TOGGLE_ANNOTATIONS', value: flag })
    };
  }
}

SubmissionBlogDraft.displayName = 'Submission.BlogDraft';
export default connect(
  SubmissionBlogDraft.mapStateFromProps,
  SubmissionBlogDraft.mapDispatchToProps
)(SubmissionBlogDraft);
