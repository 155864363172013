import React from 'react';

class ExternalSocialAuthWrapper extends React.Component {
  render() {
    return (
      <ReactRedux.Provider store={Redux.ProfileStore}>
        <div className='external_social_auth_wrapper'>
          <div className='social_accounts_wrapper'>
            <Profiles.SocialAccounts {...this.props} />
          </div>
          <div className='finish_auth_wrapper'>
            <a className='btn primary' href={this.props.referrerUrl}>
              Done
            </a>
          </div>
        </div>


      </ReactRedux.Provider>
    )
  }
}

ExternalSocialAuthWrapper.displayName = 'UserSignUp.ExternalSocialAuthWrapper';
export default ExternalSocialAuthWrapper