import React from 'react';
import PropTypes from 'prop-types'

export default class Modal extends React.Component {
  render() {
    const modalSize = `modal-dialog modal-${this.props.size || 'lg'}`;

    return <div className='modal fade in' style={{display: 'block'}}>
      <div className={modalSize} role='document'>
        <div className='modal-content'>
          {this.props.children}
        </div>
      </div>
    </div>
  }
}

Modal.propTypes = {
  size: PropTypes.oneOf([
    'lg',
    'md',
    'sm'
  ])
}

