import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { Remote } from '../utils';

import ProfilesGoogleAnalyticsAuth from './GoogleAnalyticsAuth'

class ProfilesBlogs extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showAnalyticsTooltip: {}}

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.renderBlogCategories = this.renderBlogCategories.bind(this);
    this.renderBlogCard = this.renderBlogCard.bind(this);
    this.gaAuthCacheKey = this.gaAuthCacheKey.bind(this);
    this.gaMeasurementIdKey = this.gaMeasurementIdKey.bind(this);
    this.authListener = this.authListener.bind(this);
    this.handleAuth = this.handleAuth.bind(this);
    this.handleAuthDisconnect = this.handleAuthDisconnect.bind(this);

    this.render = this.render.bind(this);
  }

  componentDidMount(){
    this.props.blogs.map(blog =>{
      this.setState({showAnalyticsTooltip: {...this.state.showAnalyticsTooltip, [blog.id]: false}})
    })
  }

  toggleTooltip(blog_id) {
    let current_state = this.state.showAnalyticsTooltip[blog_id]
    this.setState(
      {
        showAnalyticsTooltip: {...this.state.showAnalyticsTooltip, [blog_id]: !current_state}
      }
    )
  }

  handleAuth(blog_id) {
    window.localStorage.setItem(this.gaAuthCacheKey(blog_id), null);
    window.localStorage.setItem(this.gaMeasurementIdKey(blog_id), null);
    window.addEventListener('storage', () => this.authListener(blog_id));
    window.open(`${this.props.meta.gaAuthUrl}?blog_id=${blog_id}`, '_blank');
  }

  authListener(blog_id) {
    const blogCacheKey = this.gaAuthCacheKey(blog_id)
    if (window.localStorage.getItem(blogCacheKey) != 'null') {
      const connectedKey = window.localStorage.getItem(blogCacheKey);
      const measurementId = window.localStorage.getItem(this.gaMeasurementIdKey(blog_id));
      if(!!connectedKey) {
        window.localStorage.removeItem(blogCacheKey);
        this.props.setBlogGaAuthConnection(blog_id, JSON.parse(connectedKey), measurementId)
      }
    };
  }

  handleAuthDisconnect(blog){
    return Remote().request({
      method: 'GET',
      url: blog.deauth_ga_url,

    }).then((response) => {
      this.props.setBlogGaAuthConnection(blog.id, false, null)
    });
  }

  gaAuthCacheKey(blog_id){
    return `google_analytics_auth_${blog_id}`
  }

  gaMeasurementIdKey(blog_id){
    return `google_analytics_measurement_id_${blog_id}`
  }

  renderBlogCategories(blogCategories){
    return <div>
      {
        blogCategories.map(categegory => {
          return <span key={categegory.name} className='badge background-color-subdued'>{categegory.name}</span>
        })
      }
    </div>
  }

  renderBlogCard(blog) {
    const { gaStatusMessages, analyticsTooltip, showGAStatus } = this.props;

    return (
      <div key={blog.name} className='card-plus profile-blog-card'>
        <div className='card-header'>{blog.name}</div>
        <div className='card-body'>
          <div className='blog-url'>
            <label>Blog URL</label>
            <a href={blog.url} className='value' target='_blank'>{blog.fqdn}</a>
          </div>
          <div className='blog-categories'>
            <label>Blog Categories</label>
            {this.renderBlogCategories(blog.categories)}
          </div>
          <ProfilesGoogleAnalyticsAuth blog={blog}
                                       analyticsTooltip={analyticsTooltip}
                                       showGAStatus={showGAStatus}
                                       gaStatusMessages={gaStatusMessages}/>
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.props.blogs.map(network => {
          return this.renderBlogCard(network)
        })}
      </React.Fragment>
    );
  }

  static mapStateToProps(state, myProps) {
    return {
      meta: state.meta,
      ...myProps,
    };
  };


};

ProfilesBlogs.displayName = 'Profiles.Blogs';

export default connect(
  ProfilesBlogs.mapStateToProps
)(ProfilesBlogs);

ProfilesBlogs.propTypes = {
  blogs: PropTypes.array.isRequired
};

