import React from 'react';
class YoutubeCard extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  renderCheckMark() {
    return (
      <div className='selected-video'>
        <i className='text-success fas fa-check fa-check-circle'/>
      </div>
    );
  }

  render() {
    return (
      <div className='card' onClick={() => this.props.selectVideo()}>
        {this.props.selected && this.renderCheckMark()}
        <img src={this.props.video.preview_url}
             alt={this.props.video.title}
             className='card-preview'
             />

        <div className='card-title'>
          {this.props.video.title}
        </div>

        <div className='card-body'>
          {this.props.video.description}
        </div>
      </div>
    );
  }
}
YoutubeCard.displayName = 'YoutubeCard'
export default YoutubeCard
