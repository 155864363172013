class window.PasswordResets

  @new: ->
    $("#password_reset").validate
      validClass: "success"
      rules:
        "user[email]":
          email: true
          required: true
      messages:
        "user[email]":
          required: 'Please enter your e-mail address'

  @edit: ->
    $('#password_reset').validate
      validClass: 'success'
      rules:
        'user[password]':
          required: true
        'user[password_confirmation]':
          required: true
          equalTo: '#user_password'

      messages:
        'user[password]':
          required: 'Please enter your password'
        'user[password_confirmation]':
          required: 'Please confirm your password'
          equalTo:  'Your password and confirmation do not match'