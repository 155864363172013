import React from 'react';
import { connect } from 'react-redux';
import { Remote } from '../utils';

import Tooltip from '../Tooltip'

class AdvertiserAccessButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      hover: false
    };

    this.buttonClass = this.buttonClass.bind(this);
    this.buttonText = this.buttonText.bind(this);
    this.hover = this.hover.bind(this);
    this.onFail = this.onFail.bind(this);
    this.render = this.render.bind(this);
    this.requestAccess = this.requestAccess.bind(this);
    this.tooltipMessage = this.tooltipMessage.bind(this);
  }

  render() {
    const {
      advertiserAccess,
      userId,
      isInstagram,
      advertiserAuth
    } = this.props;

    if(!isInstagram || !advertiserAuth) {
      return 'N/A';
    }

    if(this.state.loading) {
      return <i className='fas fa-circle-notch fa-spin' />;
    }

    // TODO: We can do better than an O(n) lookup by making the lookup a hash from userid -> record
    const access = advertiserAccess.find(a => a.sofab_user_id === userId);

    if(_.get(access, 'state') == 'active') {
      return <span className='badge background-color-green'>
        Connected
      </span>
    }

    return <div className='position-relative' onMouseEnter={() => this.hover(true)} onMouseLeave={() => this.hover(false)}>
      <button className={this.buttonClass(access)} onClick={this.requestAccess}>
        {this.buttonText(access)}
      </button>

      {
        this.state.hover &&
        <Tooltip
          message={this.tooltipMessage(access)}
          placement='top'
          color='dark'
          size='large'
          centerText={true}
          />
      }
    </div>;
  }

  requestAccess(e) {
    e.preventDefault();
    this.setState({
      loading: true,
      hover: false
    });

    const {
      campaignId,
      userId,
      name,
      email,
      shopName,
      replyEmail
    } = this.props;
    const url = `/admin/campaigns/${campaignId}/promoters_v2/api_proxy/advertiser_invites`;
    const params = $.param({
      resource: ''
    });

    Remote().request({
      url: url + '?' + params,
      method: 'POST',
      data: {
        sofab_user_id: userId,
        influencer_name: name,
        influencer_email: email,
        shop_name: shopName,
        reply_to: replyEmail
      }
    }).then(response => {
      this.props.updateAuth(data.sofab_user_id, { advertiser_invite: response.data });
    }).catch(this.onFail).then(() => {
      this.setState({ loading: false });
    });
  }

  tooltipMessage(access) {
    if(!_.get(access, 'advertiser_invite')) {
      return 'Never invited';
    }

    return `${access.advertiser_invite.requested_at} ago by ${access.advertiser_invite.reply_to}`;
  }

  buttonText(access) {
    if(!access) {
      return 'Request';
    }

    if(access.advertiser_invite) {
      return 'Request Again';
    }

    return 'Request';
  }

  buttonClass(access) {
    if(_.get(access, 'advertiser_invite')) {
      return 'btn btn-default';
    }

    return 'btn btn-primary';
  }

  onFail(ajaxErr) {
    try {
      const resp = JSON.parse(ajaxErr.responseText);

      console.warn(resp);
    } catch(e) {
      console.warn(e);
    }
  }

  hover(enabled) {
    this.setState({ hover: enabled });
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      advertiserAccess: state.advertiserAccess,
      campaignId: state.campaignId
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateAuth: (id, data) => dispatch({ type: 'UPDATE_AUTH_BY_ID', value: { id, data } })
    };
  }
};

AdvertiserAccessButton.displayName = 'FacebookAds.AdvertiserAccessButton';
export default connect(
  AdvertiserAccessButton.mapStateToProps,
  AdvertiserAccessButton.mapDispatchToProps
)(AdvertiserAccessButton);
