`import notif from 'javascripts/notifIt.js'`

class window.AdminCampaigns

  @index: ->
    DataTableUtils.datatable $('.datatable'),
      bServerSide: true
      bStateSave: true
      iCookieDuration: 300
      sScrollX: '100%'
      aaSorting: [ [0, 'desc'] ]
      aoColumnDefs: [
        { aTargets: [0], sWidth: '0%', bVisible: false }
        { aTargets: [1], sWidth: '20%' }
        { aTargets: [2], sWidth: '8%' }
        { aTargets: [3], sWidth: '9%' }
        { aTargets: [4], sWidth: '9%' }
        { aTargets: [5], sWidth: '8%' }
        { aTargets: [6], sWidth: '20%' }
        { aTargets: [7], sWidth: '10%' }
        { aTargets: [8], sWidth: '10%' }
        { aTargets: [9], sWidth: '5%' }
      ]
      sDom: '<"dt-length col-xs-2 col-sm-3"l><"dt-filter col-xs-10 col-sm-5"f>rt<"dt-info col-xs-12 col-sm-4"i><"dt-pagination col-xs-12 col-sm-8"p>'

      fnRowCallback: (nRow, aData) ->
        stateCol = $('td:last', nRow)
        campaignState = stateCol.text().toLowerCase() + "-state"
        stateCol.addClass(campaignState)

  @show: ->
    $('body').on 'click', '.clone_shop', (e) ->
      e.preventDefault()
      $('#shop_selector_modal').modal('show')
      new_url = "/admin/opportunities/" + $('select#shop_id').val() + "/clone"
      $('#continue_clone_shop').attr("href", new_url)

    $('#shop_selector_modal_form').on 'change', '#shop_id', (e) ->
      new_url = "/admin/opportunities/" + $(this).val() + "/clone"
      $('#continue_clone_shop').attr("href", new_url)

  @new: ->
    @form()

  @edit: ->
    @form()

  @update: ->
    @edit()
    $('.help-inline').parent().css('marginTop', 20)

  @create: ->
    @new()

  @content: ->
    DataTableUtils.datatable($('.datatable'),
      bServerSide: true
      iDisplayLength: 50

      fnCreatedRow: (row, data, dataIndex) ->
        $reach = $(row).find('.content-grade')
        $reach.load($reach.data('source'))
    )

    $('input:radio[name="auxillary_content[external]"]').change ->
      if this.value == 'true' then $('#aux-content-username').removeClass('hidden') else $('#aux-content-username').addClass('hidden')

    $('#aux_content_form').validate
      rules:
        'auxillary_content[platform_id]':
          required: true
        'auxillary_content[title]':
          required: true
        'auxillary_content[url]':
          required: true
          url: true
          facebook_domain:
            depends: (element) ->
              selected_platform = $('#auxillary_content_platform').select2('data')
              selected_platform && selected_platform.text == 'Facebook'
          youtube_domain:
            depends: (element) ->
              selected_platform = $('#auxillary_content_platform').select2('data')
              selected_platform && selected_platform.text == 'Youtube'
        'auxillary_content[external]':
          required: true
        'auxillary_content[username]':
          required:
            depends: (element) ->
              $('#aux-content-username').length > 0

      messages:
        'auxillary_content[platform_id]':
          required: 'Please select a platform.'
        'auxillary_content[title]':
          required: 'Please enter a title.'
        'auxillary_content[url]':
          required: 'Please enter a URL.'
        'auxillary_content[external]':
          required: 'Please select an option.'
        'auxillary_content[username]':
          required: "Please enter the influencer's username."

      errorPlacement: (error, element) ->
        if element.attr('name') == 'auxillary_content[external]'
          $('.external-radios-err').html error
        else
          error.insertAfter(element)

    jQuery.validator.addMethod 'facebook_domain', ((value, element) ->
      @optional(element) or /^(https?:\/\/)?(www.)?facebook.com/.test(value)), 'Please enter a valid Facebook URL.'

    jQuery.validator.addMethod 'youtube_domain', ((value, element) ->
      @optional(element) or /^(https?:\/\/)?(www.)?youtube.com/.test(value)), 'Please enter a valid Youtube URL.'

  @form: ->
    @new_client()
    new ValidateDate.DateField( "#submit-btn", ["campaign_start_date", "campaign_end_date"] ).bind_values()

    $('body').on 'click', '#submit-btn', ->
      icon = $('<i class="fas fa-spinner fa-spin"></i>')
      $(this).addClass('disabled').html(icon)

    $("#campaign_client_id").on 'change', (e) ->
      if $(this).val().length > 0
        $.ajax(
          url: "/admin/clients/#{$("#campaign_client_id").val()}.json"
          success: (data) ->
            changed = false
            if data.tags.length > 0
              changed = true
              $('#campaign_tag_list').select2('val', data.tags)

            if changed
              $("#tag-helper").show()
          )

    $('#campaign_overview').redactor
      toolbarFixed: true
      toolbarFixedTarget: '#campaign_overview'
      plugins: plugin_list

    $('#campaign_objectives').redactor
      toolbarFixed: true
      toolbarFixedTarget: '#campaign_objectives'
      plugins: plugin_list

    $('#campaign_tactics').redactor
      toolbarFixed: true
      toolbarFixedTarget: '#campaign_tactics'
      plugins: plugin_list

    $('#campaign_other_info').redactor
      toolbarFixed: true
      toolbarFixedTarget: '#campaign_other_info'
      plugins: plugin_list

  @new_client: ->
    $(document).on 'shown.bs.modal', (e) ->
      $('#client_name').focus()

    $('#new_client_form').on "ajax:complete", (event, xhr, settings)->
      $.ajax(
        url: "#{location.origin}/admin/campaigns/clients.json"
        success: (clients) ->
          select = $('select#campaign_client_id')
          select.find('option').remove()
          options = select.prop('options')
          $.each(clients, (txt, val) ->
            selected = $('input#client_name').val()
            o = new Option(txt, val)
            if selected.toLowerCase() == txt.toLowerCase()
              o.selected = true
              select.select2('data', {id: o.value, text: o.text})
            options[options.length] = o
          )
          $('#client_modal').modal('hide')
          $('input#client_name').val("")
        )

  @influencers_export: ->
    @influencers()

  @influencers: ->
    Rails.fire($(".influencer-filter-form")[0], 'submit')

    bind_form = ->
      # #one so that this will only be called once until binded again
      $('.influencer-filter-form').one 'change', ->
        $('#export-modal-btn').addClass('disabled')
        setTimeout(->
          tr = $('<tr></tr>').html("<td colspan='2' class='text-center'><i class='fas fa-circle-notch fa-spin'></i> Loading...</td>")
          $('#influencers-preview').find('tbody').html(tr)
          setTimeout( ->
            Rails.fire($(".influencer-filter-form")[0], 'submit')
          , 1000)
          bind_form()
        , 1000)

    bind_form()

    $('.select-all').on 'change', ->
      data = $(this).data()
      type = data.type
      $("input[name^='#{type}'").prop('checked', this.checked).change()


    $('fieldset').on 'change', 'input', ->
      data = $(this).data()
      type = data.type

      unless this.checked
        $("#select_all_#{type}").prop('checked', false)


      if type is 'shoppertunities'
        select_shop_rounds( data.id, this.checked )

      if type is 'rounds'
        select_shops( data.id, this.checked )

    select_shop_rounds = (id, checked) ->
      $(".shop-#{id}").prop('checked', checked)

    select_shops = ( id, checked ) ->
      unless checked
        $("input[name='shoppertunities[#{id}]'").prop('checked', false)
        $("#select_all_shoppertunities").prop('checked', false)

    $('body').on 'ajax:complete', '#export-influencer-form', ->
      $('#export-modal').modal('hide')
      $('#ui_notifIt').remove()
      notif(
        msg: 'The report is being generated. You will receive an email when it is complete.'
        type: 'notice'
        position: 'right'
        autohide: true
        timeout: 3000
        top: 120
      )

  @edit_details: ->
    $('.js-redactor--cdetails').redactor
      plugins: plugin_list
      toolbarFixed: true
      toolbarFixedTarget: '.js-redactor--cdetails'

    $('#product-image-previews').on 'click', '.js-remove-product-image', (e) ->
      $(this).parent().addClass('is-hidden')

    @preview_selected_images('#product-images-input', '#selected-images')

  @update_details: ->
    @edit_details()

  @preview_selected_images: (input_id, preview_div) ->
    $('body').on 'change', input_id, (e) ->
      $(preview_div).html('')
      images = $(this)[0].files
      for img in images
        reader = new FileReader()
        reader.onload = (e) ->
          $(preview_div).append "<div class='image-display'>
                                  <img src='#{e.target.result}' class='product_image'/>
                                </div>"
          return
        reader.readAsDataURL(img)

  @attachments: ->
    DataTableUtils.datatable $('.datatable'),
      bServerSide: true,
      searching: false,
      aoColumns: [
        { bSortable: true,  sWidth: '20%'  }, # name
        { bSortable: true,  sWidth: '50px'  }, # type
        { bSortable: true,  sWidth: '50px' }, # size
        { bSortable: true,  sWidth: '80px' }, # submitted by
        { bSortable: true,  sWidth: '80px' }, # submitted on
        { bSortable: false, sWidth: '30px'}, # actions
       ]

    $('body').on 'click', '.js-delete-attachment', (e) ->
      e.preventDefault()
      $.ajax
        url: "/admin/campaign_attachments/#{e.target.dataset["attachmentId"]}"
        method: 'DELETE'
        success: (data) ->
          $('#uploadsDataTable').DataTable().ajax.reload()
        error: (data) ->
          notif(
            msg: data.responseJSON
            type: 'error'
            timeout: 3000
          )
          $('#uploadsDataTable').DataTable().ajax.reload()
    $('body').on 'click', '.js-edit-attachment-edit', (e) ->
      e.preventDefault()
      $(e.target).parent().toggleClass('active');
      $(e.target).siblings('input').focus();
    $('body').on 'click', '.js-edit-attachment-cancel', (e) ->
      e.preventDefault()
      $(e.target).parent().toggleClass('active');
    $('body').on 'click', '.js-edit-attachment-confirm', (e) ->
      e.preventDefault()
      $(e.target).parent().toggleClass('active');
      $.ajax
        url: "/admin/campaign_attachments/#{e.target.dataset["attachmentId"]}/edit"
        method: 'GET',
        data: {name: $(e.target).siblings('input').val()},
        success: (data) ->
          $('#uploadsDataTable').DataTable().ajax.reload()
        error: (data) ->
          notif(
            msg: data.responseJSON
            type: 'error'
            timeout: 3000
          )
          $('#uploadsDataTable').DataTable().ajax.reload()


