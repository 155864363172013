import React from 'react';

export const UserSignUpPersonalInfoFields = {
  gender: {
    label: 'Gender',
    required: true,
    fieldType: 'select',
    className: 'input_wrap'
  },
  ethnicity: {
    label: 'Ethnicities',
    required: true,
    fieldType: 'select',
    className: 'input_wrap',
    multiSelect: true,
  },
  education: {
    label: 'Education',
    required: true,
    fieldType: 'select',
    className: 'input_wrap',
    multiSelect: true,
  },
  maritalStatus: {
    label: 'Marital Status',
    required: true,
    fieldType: 'select',
    className: 'input_wrap'
  },
  income: {
    label: 'Income',
    required: true,
    fieldType: 'select',
    className: 'input_wrap'
  },
  retailer: {
    label: 'Retailers',
    required: true,
    fieldType: 'select',
    className: 'input_wrap',
    multiSelect: true,
  }
}