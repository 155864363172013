import React from 'react'
import { FileUploader } from '../utils';
import axios from 'axios';

export default class AuxillaryContentsPinterestPost extends React.Component {
  METRIC_FIELD_INPUTS = ["re_pins", "likes", "comments", "followers"];

  constructor(props) {
    super(props);
    const media = props.media || [];

    const defaultMetrics = this.METRIC_FIELD_INPUTS.reduce((hsh, val) => {
      hsh[val] = media.length ? media[0][val] : 0;
      return hsh;
    }, {});

    this.state = {
      title: props.title || "",
      description: props.description || "",
      handle: props.handle || null,
      username: props.username || null,
      ...defaultMetrics,
      image_url: media.length ? media[0].image_url : this.props.image_url,
      followers: props.followers || 0,
      loading: false,
      error: null,
    };

    this.saveVal = this.saveVal.bind(this);
    this.save = this.save.bind(this);

    this.debouncedUpdate = _.debounce(this.saveVal, 500);
  }

  saveVal(prop, value) {
    if (!!value && !isNaN(parseInt(value))) {
      this.setState({ [prop]: parseInt(value) });
    }
  }

  save() {
    const { title, image_url, handle, description } = this.state;
    const { type, setValue, onSave } = this.props;
    const metricData = this.METRIC_FIELD_INPUTS.reduce((hsh, val) => {
      hsh[val] = this.state[val] || 0;
      return hsh;
    }, {});
    const followers = metricData["followers"];
    delete metricData["followers"];
    this.setState({ loading: true });

    let media = [{ ...metricData, image_url }];
    setValue(
      "contentMeta",
      { type, media, title, handle, description, followers },
      onSave
    );
  }

  render() {
    const { loading, title, description, url, image_url } = this.state;
    const imageStyles = {
      width: '100%',
      height: "75%",
      margin: 'auto 0px',
      padding: '5px',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)'
    };

    return (
      <div
        style={{
          width: "100%",
          padding: "10px 0",
          boxShadow:
            "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h2>Post Details</h2>
              <hr />
            </div>
            <div className="col-md-6">
                <a href={url} target="_blank">
                  <img src={image_url} alt="post thumbnail" style={imageStyles}/>
                </a>
              </div>
            <div className="col-md-6">
              <p><b>Title:  </b>{title}</p>
              <p><b>Description:  </b>{description}</p>
              {this.METRIC_FIELD_INPUTS.map((key) => this.renderField(key))}
            </div>
            <div className="col-md-12 text-right">
              <hr />
              <button
                className="btn btn-primary"
                disabled={loading}
                onClick={this.save}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  captialize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  getMetricData(key) {
    return [this.state[key], this.captialize(key)];
  }

  INLINE_STYLE = {
    margin: "0 5px",
  };

  renderField(metric) {
    let [init, label] = this.getMetricData(metric);
    return (
      <div key={metric} className="form-group">
        <label htmlFor={metric} style={this.INLINE_STYLE}>
          {label.replace("_", " ")}
        </label>
        <input
          defaultValue={init}
          onChange={(event) => {
            this.debouncedUpdate(metric, event.target.value);
          }}
          type="number"
          className="form-control"
        />
      </div>
    );
  }
}
AuxillaryContentsPinterestPost.displayName = 'AuxillaryContent.PinterestPost';
