import React from 'react';
import { Provider } from "react-redux"
import SubmissionStore from '../redux/SubmissionStore';
import SubmissionShow from './Show'

export default class SubmissionShowWrapper extends React.Component {
  render() {
    return <Provider store={SubmissionStore}>
      <SubmissionShow {...this.props} />
    </Provider>;
  }
};

SubmissionShowWrapper.displayName = 'Submission.ShowWrapper';
