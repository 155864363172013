import React from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash'
import '../plugins/redactor-plugins.js'
import '../plugins/redactor.min.js'

export default class RichTextEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
      started: false,
      keyPressed: false
    };

    // Manually create a ref for the jquery interactions
    this.formRef = React.createRef();

    this.componentDidMount = this.componentDidMount.bind(this);
    this.onChange = this.onChange.bind(this);
    this.render = this.render.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    /*
     * NOTE: The documentation for our version of Redactor is completely gone from the internet. This
     * does not work with the current API, and the current API does not work here. Prettifying redactor.min.js
     * and looking for setCallback() calls will reveal what callbacks are available.
     */
    $(this.formRef.current).redactor({
      changeCallback: this.onChange,
      plugins: window.plugin_list,
      minHeight: this.props.minHeight || undefined,
      convertLinks: false
    });
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    $(this.formRef.current).redactor('core.destroy');
    document.removeEventListener("keydown", this.handleKeyDown);
  }
   handleKeyDown() {
      this.setState({ keyPressed: true });
  }

  saveCodeToEditor = () => {
  if (this.props.value && this.state.value !== this.props.value && !this.state.started) {
    $(this.formRef.current).redactor('code.set', this.props.value || this.state.value);
    this.setState({ started: true });
  }
  }

  componentDidUpdate() {
    if (this.state.keyPressed) {
      window.addEventListener("beforeunload", event => {
        event.preventDefault();
        this.saveCodeToEditor();
      });
    }
   else {
    this.saveCodeToEditor();
  }
  }

  render() {
    // onChange is noop'd to prevent raising warnings from React
    return <textarea ref={this.formRef}
                     placeholder={this.props.placeHolder}
                     value={this.state.value}
                     onChange={_.noop}/>

  }

  onChange(html) {
    this.setState({
      value: html
    }, () => {
      this.props.onUpdate(html);
    });
  }
}

RichTextEditor.displayName = 'RichTextEditor';
RichTextEditor.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  value: PropTypes.string,
  minHeight: PropTypes.string,
}
