import React from 'react';
import { Remote } from '../utils';
import notif from "javascripts/notifIt.js"
import moment from 'moment';
export default class SchedulerDate extends React.Component {
  constructor(props) {
    super(props);

    var date = this.props.start ? this.formatDate(this.props.startDate) : this.formatDate(this.props.endDate);

    this.state = {
      originalDate: date,
      date: date
    };

    this.serializeDate = this.serializeDate.bind(this);
    this.updateDate = this.updateDate.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.show_notification_error = this.show_notification_error.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.render = this.render.bind(this);
  }

  serializeDate(column) {
    var request = { id: this.props.schedulerId }
    request[column] = moment(this.state.date, 'MM/DD/YYYY').toDate();
    return request;
  }

  updateDate() {
    const component = this;
    Remote().request({
      url: this.createProxyUrl('ad_schedulers', { id: this.props.schedulerId }),
      method: 'PATCH',
      data: this.props.start ? this.serializeDate('start_date') : this.serializeDate('end_date'),
    }).then((response) => {
      component.setState({ originalDate: component.state.date });
      this.props.updateDate(this.props.start ? 'startDate' : 'endDate', this.state.date);
    });
  }

  createProxyUrl(route, params) {
    const query = Object.keys(params).map((key) => key + '=' + params[key]).join('&');
    return `/admin/campaigns/${this.props.campaignId}/promoters_v2/api_proxy/${route}?${query}`
  }

  formatDate(dateToFormat){
    if(dateToFormat == null){
      return undefined;
    }
    return moment(dateToFormat).format('MM/DD/YYYY');
  }

  show_notification_error(message, loglevel) {
    notif({
      msg: message,
      type: loglevel,
      timeout: 3000
    });
  }

  handleChange(e){
    var valid = /(\d{0,2})(\d{0,2})(\d{0,4})/.test(e.target.value)

    if(valid && e.target.value != null){
      const newDate = moment(e.target.value, 'YYYY-MM-DD').toDate()
      if(this.props.start){
        if(newDate > this.props.endDate){
          this.setState({date: this.state.originalDate})
          this.show_notification_error('Start Date cannot be before End Date', 'error')
        } else {
          this.setState({
            date: e.target.value
          }, () => this.updateDate());
        }
      }else{
        if(newDate < this.props.startDate){
          this.setState({date: this.state.originalDate})
          this.show_notification_error('End Date cannot be before Start Date', 'error')
        } else {
          this.setState({
            date: e.target.value
          }, () => this.updateDate());
        }
      }
    } else {
      this.setState({date: e.target.value})
    }
  }

  render(){
    tag = this.props.start ? "start" : "end"
    return <input type='date'
                  className='form-control'
                  onChange={this.handleChange}
                  value={this.state.date}/>
  }
}

