class window.AdminTemplateTasks

  @form: ->
    $('#round_task_description').redactor
      plugins: plugin_list

    $('#round_task_requires_content').on 'change', ->
      if $(this).is(':checked')
        $('#has_content').removeClass('is-hidden')
      else
        $('#has_content').addClass('is-hidden')

    $('#round_task_is_variable_pay').on 'change', ->
      if $(this).is(':checked')
        $('#not_variable').addClass('is-hidden')
      else
        $('#not_variable').removeClass('is-hidden')

    $('#round_task_icon').on 'input', ->
      iconText = $("#round_task_icon").val()
      fontIcon(iconText)

    fontIcon = (val) ->
      if val.startsWith('fa-')
        $('.fa-icon span').removeClass().addClass("fas #{val}")
      else
        $('.fa-icon span').removeClass()

  @index: ->
    $('#template_tasks').DataTable( {
        "ordering": false
        "searching": false
        "paging": false
        rowReorder: {
          dataSrc: 'sort',
          snapX: 0,
          update: false
        }
    } );

    $('#template_tasks').on 'row-reorder.dt', (e, ui, y) ->
      return if ui.length == 0
      reorders = {}
      reorders[node.id] = newPosition for {node, newPosition} in ui
      $.ajax
        type: 'POST'
        url:  '/admin/tasks/templates/sort'
        data: { round_task: reorders }

  @edit: ->
    @form()

  @update: ->
    @form()

  @new: ->
    @form()

  @create: ->
    @form()