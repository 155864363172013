import React from "react";

import SingleSelect from "../SingleSelect";
import MultiSelect from "../MultiSelect";

import UserFormSection from "./Section";
import UserFormCheckbox from "./Checkbox";

const UserFormSofabAccess = ({
    communityOptions, communities,
    accessSocialFabric, oracleLegalNameWarning, w9, paymentOptions,
    paymentType, reviewed, updateField, reviewNotes, validatedField,
    errors, blogPlatformsAttributes, blogTierOptions, updateBlogTier,
    agentRoleSelected
  }) => {

  const noteText = agentRoleSelected ? 'agent' : 'influencer'
  return <UserFormSection
    title="Social Fabric Settings"
    disabled={!accessSocialFabric}
    fields={
      <div>
        <UserFormCheckbox label='This user has given a valid W-9.'
          value={w9} onUpdate={() => updateField('w9', !w9)} />
        <div className="mb-15">
          <label>Payment Type</label>
          <SingleSelect
            preload={paymentOptions}
            selected={paymentType}
            search={(term, callback) => (
              callback(paymentOptions.filter(
                item => item && item.name.toLowerCase().includes(term.toLowerCase())
              ))
            )}
            onUpdate={(value) => updateField('paymentType', value, true)}
            placeholder={'Select'}
            clearInvalidInput={true}
            hasError={!!errors.paymentType}
          />
        </div>
        { paymentType === 'paypal' && validatedField('paypalEmail') }
        { paymentType === 'paypal' && validatedField('paypalName') }
        { validatedField('legalName') }
        <small className="d-block mb-15 text-muted">
          {oracleLegalNameWarning}
        </small>
        <UserFormCheckbox label='This user has been reviewed.'
          value={reviewed} onUpdate={() => updateField('reviewed', !reviewed)} />
        <div className='mb-15'>
          <label htmlFor="text-area">Review Notes</label>
          <textarea
            name="text-area"
            rows="3"
            placeholder={`Add notes on ${noteText}...`}
            value={reviewNotes}
            onChange={(e) => updateField('reviewNotes', e.target.value)}
          />
        </div>
        <div className='mb-15'>
          <label>Community Membership</label>
          <MultiSelect
            preload={communityOptions}
            selected={communities}
            search={(term, callback) => (
              callback(communityOptions.filter(
                item => item && item.name.toLowerCase().includes(term.toLowerCase())))
            )}
            onUpdate={(value) => updateField('communities', value)}
            placeholder={'select a community'}
            clearInvalidInput={true}
            hasError={!!errors.communities}
          />
        </div>
        <div className='mb-15'>
          <label>Blog Settings</label>
          {blogPlatformsAttributes.length == 0 &&
            <small className="d-block text-muted">
              This user has no connected blogs</small>
          }
          {blogPlatformsAttributes.length > 0 &&
            blogPlatformsAttributes.map( blog => {
              return(
                <div key={blog.id} className='user-blog-tier mb-15'>
                  {blog.name}
                  <SingleSelect
                    preload={blogTierOptions}
                    selected={blog.tier_id}
                    search={(term, callback) => (
                      callback(blogTierOptions.filter(
                        item => item && item.name.toLowerCase().includes(term.toLowerCase())
                      ))
                    )}
                    onUpdate={(value) => updateBlogTier(blog, value)}
                    placeholder={'Select'}
                    clearInvalidInput={true}
                    hasError={!!errors.blogTier}
                  />
                </div>
              )})
          }
        </div>
      </div>
    }
  />;
};

export default UserFormSofabAccess;
UserFormSofabAccess.displayName = 'UserForm.SofabAccess';