class window.UserSearchUtils

  @search: (input_id, url, options={}) ->

    $(input_id).select2(
      $.extend(
        minimumInputLength: 1
        multiple: true
        ajax:
          url: url
          quietMillis: 300
          type: 'GET'
          dataType: 'json'
          data: (query, page) ->
            q: query
          results: (data, page) ->
            results: data.results
        initSelection: (element, callback) ->
          if $('#message_default_recipients').length
            $('#message_receiver_ids').val("");
            callback( JSON.parse( $('#message_default_recipients').val() ));
        escapeMarkup: (user) ->
          user
        formatResult: (user) ->
          "<div class='select-user-search'><img src=#{user.profile_picture.thumb.url} class='img-rounded'>#{user.full_name}</div>"
        formatSelection: (user) ->
          user.full_name
        options
      )
    )