import React from 'react';
import { connect } from 'react-redux';
import defaultProfileImage from '../../images/fallback/profile_pic.jpg';

class ProfilesBasicInfo extends React.Component {
  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.updateField = this.updateField.bind(this);
    this.updateProfilePhoto = this.updateProfilePhoto.bind(this);
    this.validation = this.validation.bind(this);
    this.inputClass = this.inputClass.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    this.validateFields();
  }

  validateFields() {
    let validations = {};
    let validatedCount = 0;
    let requiredFields = {
      profile_picture_url: {
        name: 'Profile Photo'
      },
      first_name: {
        name: 'First Name',
        regex: /^[A-Za-z]+$/
      },
      last_name: {
        name: 'Last Name',
        regex: /^[A-Za-z]+$/
      },
      birthdate: {
        name: 'Birthdate'
      },
      bio: {
        name: 'Bio'
      }
    };

    // reset our required fields. If they are an agent, all we care about is the agency_name.
    if(this.props.profile.agent_role){
      requiredFields = {
        agency_name: {
          name: 'Agency Name'
        }
      };
    };

    const promise = () => new Promise((resolve) => {
      Object.keys(requiredFields).forEach((key) => {
        validatedCount++;
        const field = requiredFields[key];
        const value = this.props.profile[key];

        if (!value || value.length === 0) {
          validations[key] = `${field.name} cannot be left blank.`;
        } else if (field.regex && !(value || '').match(field.regex)) {
          validations[key] = `${field.name} must be valid.`;
        } else {
          validations[key] = undefined;
        }

        if (validatedCount === Object.keys(requiredFields).length) {
          resolve();
        }
      });
    });

    promise().then(() => {
      this.props.updateFrontendValidations(validations);
    });
  }

  updateField(field, value) {
    const promise = () => new Promise((resolve) => {
      resolve(this.props.updateProfile(field, value));
    });

    promise().then(() => {
      this.validateFields();
    });
  }

  updateProfilePhoto(e) {
    this.updateField('profile_picture', e.target.files)
    this.updateField('profile_picture_url', URL.createObjectURL(e.target.files[0]))
  }

  validation(field) {
    const validations = this.props.frontendValidations[this.props.activeTab] || {};
    const fieldValidations = validations[field];

    if(!fieldValidations) {
      return null;
    }

    return <span className='help-text no-prefix'>
      {fieldValidations}
    </span>;
  }

  inputClass(field) {
    if(this.validation(field)) {
      return 'form-control invalid';
    }

    return 'form-control';
  }

  render() {
    const {
      profile_picture_url,
      agency_name,
      agent_role,
      first_name,
      last_name,
      birthdate,
      bio
    } = this.props.profile;
    const bio_length = _.isEmpty(bio) ? 0 : bio.length;

    return (
     <div className='basic-info-container'>

        <div className='card-plus profile-photo-card'>
          <div className='card-header'>Profile Photo</div>
          <div className='card-body'>
            <div className='profile-photo' style={{backgroundImage: `url(${profile_picture_url ? profile_picture_url : defaultProfileImage})`}}></div>
            <div className='upload-container'>
              <label className='btn btn-primary' htmlFor="profile_photo">Change Profile Photo</label>
              <input
                id='profile_photo'
                type='file'
                accept='image/*'
                className={this.inputClass('profile_picture_url')}
                onChange={(e) => this.updateProfilePhoto(e)}
                style={{display:'none'}}
                required
              />
              {this.validation('profile_picture_url')}
            </div>
          </div>
        </div>

        <div className='card-plus basic-info-form-card'>
          <div className='card-header'>Basic Information</div>
          <div className='card-body'>
            <div className='basic-info-form'>
              {
              !agent_role && <div className='input-wrap'>
                <label>First Name</label>
                <input
                  type='text'
                  value={first_name}
                  pattern='[A-Za-z]+'
                  className={this.inputClass('first_name')}
                  onChange={(e) => this.updateField('first_name', e.target.value)}
                  required={!agent_role}
                ></input>
                {this.validation('first_name')}
              </div>
              }   
              {
              !agent_role && <div className='input-wrap'>
                <label>Last Name</label>
                <input
                  type='text'
                  value={last_name}
                  pattern='[A-Za-z]+'
                  className={this.inputClass('last_name')}
                  onChange={(e) => this.updateField('last_name', e.target.value)}
                  required={!agent_role}
                ></input>
                {this.validation('last_name')}
              </div>
              }
              {
                agent_role && <div className='input-wrap'>
                  <label>Agency Name</label>
                  <input
                    type='text'
                    value={agency_name}
                    className={this.inputClass('agency_name')}
                    onChange={(e) => this.updateField('agency_name', e.target.value)}
                    required
                  ></input>
                </div>
              }
              <div className='input-wrap'>
                <label>Birthdate</label>
                <input
                  type='date'
                  value={birthdate || ''}
                  max={new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toISOString().split("T")[0]}
                  className={this.inputClass('birthdate')}
                  onChange={(e) => this.updateField('birthdate', e.target.value)}
                  required={!agent_role}
                ></input>
                {this.validation('birthdate')}
              </div>
              <div className='input-wrap'>
                <label>Bio</label>
                <textarea
                  value={bio || ''}
                  rows='5'
                  maxLength='425'
                  className={this.inputClass('bio')}
                  onChange={(e) => this.updateField('bio', e.target.value)}
                ></textarea>
                {this.validation('bio')}
                <span className={`bio-limit ${bio_length < 425 ? '' : 'maxed'}`}>{425 - bio_length} characters remaining</span>
              </div>
            </div>
          </div>
        </div>
     </div>
    );
  }
  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      activeTab: state.activeTab,
      profile: state.profile,
      frontendValidations: state.frontendValidations
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateFrontendValidations: validations => dispatch({ type: 'UPDATE_FRONTEND_VALIDATIONS', value: validations }),
      updateProfile: (field, value) => dispatch({ type: 'UPDATE_PROFILE', value: { field: field, value: value } })
    }
  }
};

ProfilesBasicInfo.displayName = 'Profiles.BasicInfo';
export default connect(
  ProfilesBasicInfo.mapStateToProps,
  ProfilesBasicInfo.mapDispatchToProps
)(ProfilesBasicInfo);

