import React from 'react';
export default class NoteEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      note: props.value ? props.value.note : null,
      edit: false
    }

    this.debouncedUpdate = _.debounce(props.callBack, 500);

    this.updateNote = this.updateNote.bind(this);
    this.render = this.render.bind(this);
  }

  updateNote(note) {
    const return_id = this.props.value.id || this.props.value.stateId
    this.setState({
      note: note
    }, () => this.debouncedUpdate(return_id, note));
  }

  render() {
    const containerStyle = {
      display: 'flex',
      border: '1px solid lightgrey',
      backgroundColor: 'white',
      padding: '2px'
    };
    const inputStyle = {
      border: 'none',
      flex: '1',
      outline: 'none'
    };

    if(!this.props.value) {
      return null
    }

    if(!this.state.edit) {
      return (
        <td style={{ cursor: 'text' }} onClick={(e) => this.setState({ edit: true})}>
          <span>{this.state.note}</span>
        </td>
      );
    }

    return (
      <td>
        <span style={containerStyle}>
          <input style={inputStyle} placeholder='Notes' value={this.state.note || ''} onChange={(e) => this.updateNote(e.target.value)}/>
          <button className='btn btn-primary btn-xs' onClick={() => this.setState({ edit: false })}>
            Done
          </button>
        </span>
      </td>
    );
  }
};