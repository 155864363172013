import React from 'react';
import { Remote } from '../utils';

import HeaderNotificationsContent from './NotificationsContent';

export default class HeaderNotificationsDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      loading: false,
      notifications: [],
      notificationCount: props.notificationCount,
      allRead: (props.notificationCount === 0)
    };

    this.loadNotifications = this.loadNotifications.bind(this);
    this.deleteViewed = this.deleteViewed.bind(this);
    this.markAsRead = this.markAsRead.bind(this);
    this.destroyNotification = this.destroyNotification.bind(this);
    this.removeNotification = this.removeNotification.bind(this);
  }

  loadNotifications() {
    const {notificationsDropdownPath} = this.props;
    if (this.state.allRead || this.state.notifications.length > 0) {
      return;
    }
    this.setState({loading: true});
    Remote().request({
      method: 'GET',
      url: notificationsDropdownPath,
    }).then((response) => {
      this.setState({notifications: response.data, loading: false});
    }).catch((response) => {
      this.setState({loading: false});
    });
  }

  deleteViewed(){
    Remote().request({
      method: 'POST',
      url: '/notifications/delete_viewed',
    }).then(() => {
      this.setState({notifications: [], notificationCount: 0});
      this.props.updateNotificationCount(0);
    }).catch((data) => {
      this.setState({loading: false});
    });
  }

  markAsRead(){
    Remote().request({
      method: 'POST',
      url: '/notifications/mark_as_read',
    }).then(() => {
      this.setState({notifications: [], notificationCount: 0});
      this.props.updateNotificationCount(0);
    }).catch((data) => {
      this.setState({loading: false});
    });
  }

  destroyNotification(notification){
    Remote().request({
      method: 'DELETE',
      url: `/notifications/${notification.id}`,
    }).then(() => {
      this.removeNotification(notification);
    }).catch((data) => {
      this.setState({loading: false});
    });
  }

  removeNotification(notification){
    let {notifications, notificationCount} = this.state;
    let i = notifications.length;
    while(i--){
      if( notifications[i] && notifications[i]['id'] === notification.id ){
        notifications.splice(i,1);
      }
    }
    this.setState({notifications, notificationCount: notificationCount-1});
    this.props.updateNotificationCount(notificationCount-1);
  }

  render() {
    const {notifications, loading} = this.state;
    return(
      <div className="dropdown-menu notifications_dropdown_menu"
           id="header_notifications_dropdown"
           onFocus={this.loadNotifications}
           ref={this.props.clickRef}
           tabIndex="1"
      >
        <div className="dropdown_notifications js-notifications">
          { loading &&
            <div className='notification_modal'>
              <i className="fas fa-circle-notch fa-spin fa-3x fa-fw" />
              <span className="sr-only">Loading...</span>
            </div>
          }
          <div className="notif_dropdown_header clearfix">
            <div className="pull-left" style={{lineHeight: '29px'}}>Notifications</div>
            <div className="pull-right">
              <button className="btn-link js-clear-all-notifs" onClick={this.deleteViewed}>
                Clear All
              </button>
              <button className="btn-link js-mark-all-notifs-read" onClick={this.markAsRead}>
                Mark all as read
              </button>
            </div>
          </div>
          <HeaderNotificationsContent notifications={notifications} destroyNotification={this.destroyNotification}/>
        </div>
        <a className="notif_drop_footer_link" role="button" href="/notifications">Show All
          <i className="fas fa-arrow-circle-right fa-fw"></i>
        </a>
      </div>
    )
  }
}

HeaderNotificationsDropdown.displayName = 'Header.NotificationsDropdown';