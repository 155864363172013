import React from 'react';
import { connect } from 'react-redux';

class UsersDeleteAccount extends React.Component {
  constructor(props) {
    super(props);

    this.deleteAccountModal = this.deleteAccountModal.bind(this);
    this.render = this.render.bind(this);
  }

  render() {
    const { i18n } = this.props;
    return <a className='card clickable users-delete-account' href="" onClick={this.deleteAccountModal}>
      <div className='form-lr'>
        <label>
          {i18n.delete_account.button_text}
        </label>
        <i className='fas fa-chevron-right'/>
      </div>
    </a>;
  }

  deleteAccountModal(e) {
    e.preventDefault();
    // TODO: At some point, let's rewrite the survey in react so we can take out this
    // jquery call here.
    $('#delete_user_account').modal();
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      i18n: state.meta.i18n
    };
  }
};

UsersDeleteAccount.displayName = 'Users.DeleteAccount';
export default connect(
  UsersDeleteAccount.mapStateToProps
)(UsersDeleteAccount);
