import React from 'react';
import {Provider} from 'react-redux'

import FacebookAdStore from '../redux/FacebookAds'
import WavesPage from './WavesPage'

// Simply acts as redux context provider for the whole page
export default class WavesPageWrapper extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Provider store={FacebookAdStore}>
        <WavesPage {...this.props}/>
      </Provider>
    );
  }
};
