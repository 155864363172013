class window.AdminUsers

  @index: ->

    $('body').on 'click', '.btn-strike', ->
      $.get $(this).data('url'), (data) ->
        $('#strike_modal').html(data)

        $.validator.addMethod 'reasons', ((value) ->
          $('.reasons:checked').size() > 0
        ), 'Please select at least one reason.'

        $('#strike_form').validate
          groups: checks: $('.reasons').attr 'name'
          errorPlacement: (error, element) ->
            $('#strike_form .err').html(error)

        $('#strike_form').on('ajax:success', (e, data, status, xhr) ->
          $(this).addClass('hidden')
          $('.strike-success').removeClass('hidden')
        ).on 'ajax:error', (e, xhr, status, error) ->
          $(this).addClass('hidden')
          $('.strike-error').removeClass('hidden')

  @new: ->
    @form()

  @edit: ->
    @form()

  @update: ->
    @edit()

  @create: ->
    @new()

  @form: ->
    $('.gradient-checkbox').change =>
      if ( $('#employee_check').prop('checked') )
        roles = $("#roledata").data('employeeroles')
      else
        roles = $("#roledata").data('nonemployeeroles')

      select = $('select#user_role_ids')
      select.select2('val', '')
      select.find('option').remove()

      options = select.prop('options')
      $.each(roles, (txt, val) ->
        options[options.length] = new Option(txt, val)
      )

    $('#payment-type').change ->
      if $(this).val() == 'paypal'
        $('#paypal-info').removeClass('hidden')
      else
        $('#paypal-info').addClass('hidden')

    $('#payment-type').trigger('change')
