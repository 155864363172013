import React from 'react';

export default class Comment extends React.Component {

  renderTemplate(message){
    const P = React.Fragment;
    const {user_id, notification_user_id, group_name, user_name} = this.props.notification;
    return (
        <P>
          {notification_user_id === user_id && <b>You </b>}
          {notification_user_id !== user_id && <b>{user_name} </b>}
          {message} <b>{group_name}</b>
        </P>
    )
  }

  render(){
    const {activity} = this.props.notification;
    let message = `${activity !== 'replied' ? "posted" : activity} a comment in`
    return this.renderTemplate(message)
  }
}
Comment.displayName = 'Notification.Templates.Comment'