import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types'

export default class HumanDate extends React.Component {
  render() {
    if(!this.props.value) {
      return <em>-</em>;
    }

    return (
      <span>{moment(this.props.value).calendar()}</span>
    );
  }
};

HumanDate.displayName = 'HumanDate';
HumanDate.propTypes = {
  value: PropTypes.string
};
