import React from 'react';
import { Provider } from "react-redux";
import AdminContentStore from '../redux/AdminContentStore'
import AdminTiktokManualMetrics from "./AdminTiktokManualMetrics";

class AdminTiktokManualMetricsWrapper extends React.Component {
  render() {

    return (
      <Provider store={AdminContentStore}>
        <AdminTiktokManualMetrics {...this.props} />
      </Provider>
    );
  }
};

AdminTiktokManualMetricsWrapper.displayName = 'Contents.AdminTiktokManualMetricsWrapper';
AdminTiktokManualMetricsWrapper.propTypes = {};
export default AdminTiktokManualMetricsWrapper;


