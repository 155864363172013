import React from 'react';
import { createStore } from 'redux';

const intialState = {
  loading: true,
  submitting: false,
  view: undefined,
  form: { errors: {}, valid: true }
};

const reducer = (state = intialState, action) => {
  const { type, value } = action
  switch(type) {
    case 'UPDATE':
      return {
        ...state,
        ...value
      };
    case 'UPDATE_FIELD':
      return {
        ...state,
        [value.field]: value.data
      };
    case 'UPDATE_FORM':
      const { field, data, validate } = value
      const errors = validate ? validate(field, data, state.form.errors, state) : {};
      return {
        ...state,
        [field]: data,
        form: {
          ...state.form,
          [field]: data,
          errors: errors,
          valid: (errors && Object.keys(errors).length == 0)
        }
      }
    case 'SET_CURRENT_VIEW':
      if (value && value.route)
        window.history.pushState({}, document.title, value.route);
      return {
        ...state,
        view: value.view,
        loading: true
      };
    case 'START_SUBMITTING':
      return {
        ...state,
        submitting: true
      };
    case 'STOP_SUBMITTING':
      return {
        ...state,
        submitting: false
      };
    case 'SET_FORM_VALUES':
      const { attributes } = value
      return {
        ...state,
        form: {
          ...state.form,
          ...attributes
        }
      }
    default:
      return state;
  };
};

const BasicWrapperStore = createStore(
  reducer,
  window && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ name: 'Redux.BasicWrapperStore' })
);

export default BasicWrapperStore;