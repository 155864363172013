import React from 'react';
import PropTypes from 'prop-types'

export default class RoundTasksTitleInput extends React.Component {
  render() {
    const labelTextStyle = {
      margin: '8.5px 0',
      fontWeight: '700'
    };
    const labelInputStyle = {
      width: '100%',
      padding: '3px',
      paddingLeft: '10px'
    };

    if(!this.props.open || (this.props.type.indexOf('instruction_notes') === -1 && this.props.type.indexOf('general') === -1)) {
      return <h4 style={labelTextStyle}>{this.props.value || 'New Requirement'}</h4>;
    }

    return (
      <input
        type='text'
        value={this.props.value || ''}
        style={labelInputStyle}
        onChange={(e) => this.props.onChange(e.target.value)}
        placeholder='New Requirement'
        />
    );
  }
};

RoundTasksTitleInput.displayName = 'RoundTasks.TitleInput';

RoundTasksTitleInput.propTypes = {
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired
};
