import React from 'react'

function TiktokAuthFailure(props) {
  return <div>
    <h2>Authentication Failure</h2>
    <p dangerouslySetInnerHTML={{ __html: props.failure_message }}></p>
    <a className='btn btn-primary' href={props.connectedNetworksUrl}>
      Go to Connected Networks
    </a>
  </div>;
}
export default TiktokAuthFailure