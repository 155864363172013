import React from 'react';
import PropTypes from 'prop-types'
import { Remote } from '../utils';

export default class ShoppertunitiesHideShopBtn extends React.Component {
    constructor(props) {
        super(props);
        this.hideShopFromFeed = this.hideShopFromFeed.bind(this);
    }

    hideShopFromFeed(hidden_status){
        const shoppertunityId = this.props.shoppertunity.id;
        const url = `/opportunities/${shoppertunityId}/hide`
        Remote().request({
            url:  url,
            data: {hide: hidden_status},
            method: 'POST',
            
        }).then(function(response){
            window.location.href = '/shoppertunities';
        }).catch(function(error) {
            console.log("Something went wrong.")
        });
    }

    render() {
        const isShopHidden = this.props.shoppertunity.shop_hidden_from_feed;
        const btnClass = isShopHidden === true ? 'shop_details--unhide_shop_btn' :'shop_details--hide_shop_btn'

        const button =
            <div className={btnClass}>
                <a className="btn btn-default" onClick={() => this.hideShopFromFeed(!isShopHidden)}>
                    { isShopHidden === true ? 'Show in Feed' : 'Hide From Feed'}
                </a>
            </div>


        return ( this.props.isMobile ?
                <div className='col-xs-12 text-center'>
                    {button}
                </div>
                :
                <div className="shop_details--top_box_col text-center">
                    <div className="shop_details--top_box">
                        <div className="shop_details--box_item">
                            <div className='col-xs-6 text-right'>
                                {button}
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

ShoppertunitiesHideShopBtn.displayName = 'Shoppertunities.HideShopBtn';
ShoppertunitiesHideShopBtn.propTypes = {
    shoppertunity: PropTypes.object.isRequired,
    isMobile: PropTypes.bool.isRequired,
};