import React from 'react';
import PropTypes from 'prop-types'

export default class RoundTasksText extends React.Component {
  changeHandler(event, value=null) {
    const { index, name, required } = this.props;

    if (!value) {
      value = event.target.value;
    }

    this.props.updateField(name, value, index);
  }

  render() {
    const { name, type, required, placeholder, value, disabled } = this.props;

    return(
      <div>
        <input name={name}
               type={type || 'text'}
               required={required}
               onChange={(e) => this.changeHandler(e)}
               placeholder={placeholder}
               value={value || ''}
               disabled={disabled}/>
      </div>
    )
  }
};

RoundTasksText.displayName = 'RoundTasks.Text';
RoundTasksText.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  updateField: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  index: PropTypes.number,
  value: PropTypes.string
};
