import React from 'react';
import { connect } from 'react-redux';
import ConversationsList from './List';
import ConversationsNew from './New';
import ConversationsMessageNavBar from './MessageNavbar';
import ConversationsMessageWindow from './MessageWindow';
import ConversationsMessageEntry from './MessageEntry';

class ConversationsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.renderConversations = this.renderConversations.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    let { initialProps, conversations } = this.props;

    this.props.initializeConversations({
      conversations: initialProps.conversations || conversations,
      authenticity_token: initialProps.authenticity_token,
      loading: false,
      selected: false,
      media_mode: false,
      body: '',
      recipients: initialProps.default_recipients || [],
      recipient_ids: initialProps.default_recipient_ids || [],
      shop_conversation: initialProps.shop_conversation || false,
      show_recipients: false,
      no_recipients_error: false,
      file: null,
      new_conversation: initialProps.new_conversation || false,
      load_more_messages: false,
      message_loading: false,
      messages_page: 1,
      conversation: {
        messages: []
      }
    });
  }

  render() {
    return(
      <div className='container-fluid'>
        { (this.props.conversations.length === 0) &&
          <h3 className='no-messages-text'>
            You have no new messages at this time.
          </h3>
        }
        { this.renderConversations() }
      </div>
    );
  }

  renderConversations() {
    const {conversations, selected, loading, conversation, load_more_messages, messages_page, new_conversation} = this.props;
    return (
      <div className='row conversation-card-plus'>
        <div className='conversation-container'>
          <ConversationsList conversations={conversations} shop_conversation={this.props.initialProps.shop_conversation} />
          {
            (new_conversation && !selected) &&
            <div className="col-md-8 conversation-window active">
              <ConversationsNew />
            </div>
          }
          {
            !new_conversation &&
            <div className={`col-md-8 conversation-window ${selected ? 'active': '' }`}>
              <ConversationsMessageNavBar conversation={conversation} selected={selected} />
              <ConversationsMessageWindow selected={selected}
                                           loading={loading}
                                           conversation={conversation}
                                           load_more_messages={load_more_messages}
                                           messages_page={messages_page}
              />
              <ConversationsMessageEntry selected={selected} loading={loading} />
            </div>
          }
        </div>
      </div>
    )
  }

  static mapStateToProps(state, myProps) {
    return {
      conversations: state.conversations,
      loading: state.loading,
      conversation: state.conversation,
      selected: state.selected,
      current_page: state.current_page,
      total_pages: state.total_pages,
      messages_page: state.messages_page,
      load_more_messages: state.load_more_messages,
      new_conversation: state.new_conversation,
      authenticity_token: state.authenticity_token,
      ...myProps
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      initializeConversations: data => dispatch({ type: 'INITIALIZE_DATA', value: data }),
      newConversation: () => dispatch({type: 'SET_NEW_CONVERSATION'})
    };
  };
};

ConversationsContainer.displayName = 'Conversations.ConversationsContainer';

export default connect(
  ConversationsContainer.mapStateToProps,
  ConversationsContainer.mapDispatchToProps
)(ConversationsContainer);