class window.Conversations

  @index: ->
    $('tr.message').on 'click', (event) ->
      window.location.href = $(this).data('url')

  @new: ->
    @search()

  @create: ->
    @search()

  @search: ->
    UserSearchUtils.search( '.ajax-select.recipients', "#{location.origin}/search.json?c=users" )
    $('.ajax-select.shoppertunity').select2(
      minimumInputLength: 1
      multiple: false
      ajax:
        url: "#{location.origin}/search.json?c=shoppertunities&per_page=50"
        quietMillis: 300
        type: 'GET'
        dataType: 'json'
        data: (query, page) ->
          q: query
        results: (data, page) ->
          results: _.filter(data.results, {open: true})
      escapeMarkup: (shoppertunity) ->
        shoppertunity
      formatResult: (shoppertunity) ->
        "<div class='select-shoppertunity-search'>#{shoppertunity.name}</div>"
      formatSelection: (shoppertunity) ->
        shoppertunity.name
    )

  @show: ->
    window.page = 2
    cid  = document.URL.split("/").pop()

    $('a#load_older').on 'click', (event) ->
      loadMessages(page, cid)
      return false

    loadMessages = (page, cid) ->
      $.ajax
        type: 'GET'
        url: '/conversations/' + cid + '/more?page=' + page
        success: (response) ->
          window.page++

          for message in response.messages.reverse()
            prependMessages(message)

          $('a#load_older').hide() if !response.more
        error: (response) ->
          console.log('Failed to get more responses for some reason....', response)

    prependMessages = (message) ->
      $('div#messages_list').prepend(messageBody(message))

    messageBody = (message) ->
      str = '<div class="well">
        <div class="row">
          <div class="col-md-6">
            <p class="pull-left">
              </p><div class="navatar" style="background-image: url('
      str += message.author.avatar_url
      str += ')"></div>'
      str += message.author.full_name
      str += '<p></p>
          </div>
          <div class="col-md-6">
            <p class="pull-right" title="'
      str += message.timestamp_exact
      str += '">'
      str += message.timestamp
      str += '</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p>'
      str += message.body
      str += '</p>
          </div>
        </div>
      </div>'
      return str