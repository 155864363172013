import React from 'react';
import { connect } from 'react-redux';
import InfluencerDashboardContentSummary from './content/Summary'
import InfluencerDashboardContentList from './content/ContentList'

class InfluencerDashboardContent extends React.Component {
  constructor(props) {
    super(props);

    this.data = this.data.bind(this);
    this.render = this.render.bind(this);
  }

  data(key=null) {
    return (key ? _.get(this.props, key) : content) || {};
  }

  render() {
    return (
      <React.Fragment>
        { !_.isEmpty(this.data('summary')) && this.data('summary').total_count > 0 &&
          <InfluencerDashboardContentSummary {...this.data('summary')} />
        }
        { !_.isEmpty(this.data('content')) &&
            <InfluencerDashboardContentList />
        }
        { _.isEmpty(this.data('content')) &&
          <div className='card influencer-dashboard-no-content'>
            <i className='fas fa-clipboard fa-2x text-color-subdued' />
            <h1 className='text-weight-bold'>
              No Content Available
            </h1>
            <h4 className='text-color-subdued'>
              Review <a className='text-color-subdued' href='/opportunities' target='_blank' title='View all Opportunities'>Opportunities</a> to submit content
            </h4>
          </div>
        }
      </React.Fragment>
    );
  }
  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      content: _.get(state, 'content.content', []),
      summary: _.get(state, 'content.summary', [])
    };
  }
};

InfluencerDashboardContent.displayName = 'InfluencerDashboard.Content';
export default connect(
  InfluencerDashboardContent.mapStateToProps,
  InfluencerDashboardContent.mapDispatchToProps
)(InfluencerDashboardContent);