`import notif from 'javascripts/notifIt.js'`
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js';
class window.AdminShopApplications

  @index: ->
    ShopDashboard.setup()
    table = $('.datatable')

    storage_key = table.data('storage-key')
    column_options = table.data('column-options')

    table.DataTable
      dom:  "<'row'<'col-sm-3'l><'col-sm-3'B><'col-sm-6'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-5'i><'col-sm-7'p>>" 
      paginationType: 'full_numbers'
      searchDelay: 600
      ajaxSource: table.data('source')
      processing: true
      language:
        search: ''
        searchPlaceholder: 'Search the table...'
        lengthMenu: '_MENU_per page'
        info: "Showing <strong>_START_ to _END_</strong> of <strong>_TOTAL_</strong>"
        paginate:
          first: "<<"
          previous: "<"
          last: ">>"
          next: ">"
        processing: "<div class='datatable_spinner'><i class='fas fa-circle-notch fa-spin fa-fw'></i><span>Loading</span></div>"
      fixedHeader:
        headerOffset: -8
      serverSide: true
      cookieDuration: 300
      autoWidth: false
      responsive: true
      order: [[2, 'desc']]
      columns: [
        # Add .hide to the `th` to disable visibility for the column
        $.extend(column_options['id'], {
          targets: [ 0 ],
          title: '<div class="checkbox"><label><input type="checkbox" id="checkall" title="Select All"></label></div>',
          orderable: false,
          class: 'text-center',
          render: (data, type, row) ->
              "<div class='checkbox'><label><input type='checkbox' class='applicants js_applicants_cbx' name='selected_apps[]' value=#{data}></label></div>"
        }),
        column_options['applicant_name'],
        column_options['fit_score'],
        column_options['tier_name'],
        column_options['reach'],
        column_options['state'],
        $.extend(column_options['average_engagements'], {render: $.fn.dataTable.render.number(',')}),
        column_options['engagement_rate_views'],
        column_options['engagement_rate_followers'],
        column_options['average_content_views'],
        column_options['average_instagram_photo_views'],
        column_options['average_instagram_video_views'],
        column_options['average_instagram_story_views'],
        column_options['instagram_photo_view_rate'],
        column_options['instagram_video_view_rate'],
        column_options['instagram_story_view_rate'],
        column_options['view_rate'],
        column_options['payment'],
        column_options['cost_per_view'],
        $.extend(column_options['internal_note'], {
          targets: [18],
          render: (data, type, row) ->
            "<p class='internal-notes' data-url='#{data.route}' contenteditable='true'>#{data.value}</p>"
        }),
        column_options['rounds'],
        column_options['past_blogger_accepted_rounds'],
        column_options['future_blogger_accepted_rounds'],
        column_options['activated'],
        column_options['audience'],
        column_options['joined'],
        column_options['review_notes']
      ]
      colReorder: {
        # Prevent user reordering due of checkbox and app picture
        fixedColumnsLeft: 2,
        order: column_options['column_order']
      }
      buttons:  {
        buttons: [
          {
            extend: 'colvis',
            # Any `th` with .noVis will not show in visibility button toggle
            columns: ':not(.noVis)'
          },
          {
            text: 'Reset Order',
            className: 'resetOrder',
            action: () ->
              datatable = table.DataTable()
              datatable.colReorder.order(column_options['column_order'], true)
          }
        ],
        dom: {
          container: {
            className: 'dt-buttons btn-group'
          },
          button: {
            className: 'btn btn-default'
          },
          collection: {
            tag: 'ul',
            className: 'dt-button-collection dropdown-menu',
            button: {
              tag: 'li',
              className: 'dt-button'
            },
            buttonLiner: {
              tag: 'a',
              className: ''
            }
          }
        }
      }
      displayLength: 20
      lengthMenu: [
        [5, 10, 20, 50, 9999],
        [5, 10, 20, 50, 'All']
      ]
      stateSave: true
      stateSaveCallback: (oSettings, oData) ->
        localStorage.setItem( storage_key, JSON.stringify(oData) )
      stateLoadCallback: (oSettings) ->
        JSON.parse( localStorage.getItem(storage_key) )
      fnCreatedRow: (row, data, dataIndex) ->
        state = $(row).find('[data-state]').data('state')
        $(row).addClass(state)
        $reach = $(row).find('.reach')
        $cell = $reach.parent()
        $reach.data('loading', true)
        $reach.load $reach.data('source'), (res, status, xhr) ->
          dataCell = table.DataTable().cell($cell.get())
          if (dataCell.any())
            dataCell.data(xhr.responseText)

      fnStateLoadParams: (oSettings, oData) ->
        oData.abVisCols = []
      fnDrawCallback: ->
        $('.js_applicants_cbx').change ->
          if $('.js_applicants_cbx:checked').length
            $(".js_applicant_actions").attr( "disabled", false )
          else
            $(".js_applicant_actions").attr( "disabled", true )
      initComplete: ( settings, json ) ->
        refreshTooltips()

    $('.resetOrder').removeClass('btn-default')

    table.DataTable().columns('.hide').visible(false)
    table.on 'column-visibility.dt', (e, settings, column, state) ->
      loadReach()
      refreshTooltips()

    table.on 'column-reorder.dt',  (e, settings, details) ->
      loadReach()
      refreshTooltips()

    loadReach = ->
      $('.reach').each (e) ->
        $reach = $(this)
        $cell = $reach.parent()
        if ($reach.text().match(/load/i) && !$reach.data('loading'))
          $reach.data('loading', true)
          $reach.load $reach.data('source'), (res, status, xhr) ->
            dataCell = table.DataTable().cell($cell.get())
            if (dataCell.any())
              dataCell.data(xhr.responseText)

    refreshTooltips = ->

    $('thead tr th').on 'mousedown', ->
      $(this).addClass('mouseDown')

    $('thead tr th').on 'mouseleave', ->
      $(this).removeClass('mouseDown')


    $('#checkall').change ->
      $('input:checkbox:enabled').prop 'checked', $(this).prop('checked')
      if $('.js_applicants_cbx:checked').length
        $(".js_applicant_actions").attr( "disabled", false )
      else
        $(".js_applicant_actions").attr( "disabled", true )
      return

    $('#shop_app_table_wrapper').on 'click', '.js_applicants_cbx', ->
      if $('.js_applicants_cbx:checked').length
        $('.js_applicant_actions').attr('disabled', false)
      else
        $('.js_applicant_actions').attr('disabled', true)

    $('body').on('focus', '.internal-notes', ->
      $(this).addClass('form-control editable')
      window.internalNote = $(this).text()
      return
    ).on 'blur', '.internal-notes', ->
      $(this).removeClass('form-control editable')
      if window.internalNote != $(this).text()
        $(this).trigger 'change'
      return
    $('body').on 'change', '.internal-notes', ->
      url = $(this).data('url')
      $.post(url, internal_note: $(this).text())
      return

    $('body').on 'click', '.loading', ->
      icon = $('<i class="fas fa-spinner fa-spin"></i>')
      $(this).addClass('disabled')
      $(this).html(icon)

    document.addEventListener 'scroll', ((event) ->
        $('#shop_app_table').DataTable().columns.adjust()
        return
      ), true
    
    @client_modal()

  @show: ->
    ShopDashboard.setup()
    window.Utils.autolink('.a-val')

    $.extend( $.fn.dataTable.defaults, {
        sDom: '<"dt-length col-xs-2 col-sm-3"l><"dt-filter col-xs-10 col-sm-5"f>rt<"dt-info col-xs-12 col-sm-4"i><"dt-pagination col-xs-12 col-sm-8"p>'
        sAjaxSource: false,
        searching: false,
        order: [[ 5, "desc" ]],
        pagingType: 'simple_numbers',
        language:
          info: 'Showing <strong>_START_</strong> to <strong>_END_</strong> of <strong>_TOTAL_</strong>'
          paginate:
            next: '>',
            previous: '<'
        lengthMenu: [
          [5, 10, 20, 50, 9999],
          [5, 10, 20, 50, 'All']
        ]
     })

    $('.datatable#completed-work').dataTable(
      aoColumnDefs: [{ 'bSortable': false, 'aTargets': [ 0, 6 ] }],
      pageLength: 5,
    )

    $('.datatable#applied-shops').dataTable(
      pageLength: 10,
    )

    $('body').on 'click', '.edit-role-note', ->
      parent = $(this).parents('.app-note')
      parent.find('.display-note').slideToggle(200, ->
        parent.find('.role-note').slideToggle(200)
      )
      false

    state_err_msg = @assign_applicant_msg("status")
    round_err_msg = @assign_applicant_msg("round")
    $('#save-btn').addClass('disabled')

    $('body').on 'change', '#shop_application_state', ->
      $round = $('#select-round select')
      $('#err-msg').remove()

      if $.inArray( $(this).val(), ['', 'pending'] ) > -1
        $round.attr('disabled', true)
        $('#save-btn').addClass('disabled')
        $('#app-state-msg').append(state_err_msg)
      else if $.inArray( $(this).val(), ['maybe', 'denied'] ) > -1
        $round.attr('disabled', true)
        $('#save-btn').removeClass('disabled')
      else if $round.val()
        $round.attr('disabled', false)
        $('#save-btn').removeClass('disabled')
      else
        $round.attr('disabled', false)
        $('#save-btn').addClass('disabled')
        $('#app-state-msg').append(round_err_msg)

    $('body').on 'change', '#shop_application_selected_round_id', ->
      $round = $('#select-round select')
      $('#err-msg').remove()

      if $round.val()
        $('#save-btn').removeClass('disabled')
      else
        $('#app-state-msg').append(round_err_msg)
        $('#save-btn').addClass('disabled')

    Chart.register(
      ArcElement,
      LineElement,
      BarElement,
      PointElement,
      BarController,
      BubbleController,
      DoughnutController,
      LineController,
      PieController,
      PolarAreaController,
      RadarController,
      ScatterController,
      CategoryScale,
      LinearScale,
      LogarithmicScale,
      RadialLinearScale,
      TimeScale,
      TimeSeriesScale,
      Decimation,
      Filler,
      Legend,
      Title,
      Tooltip,
      SubTitle
    );
    @gender_chart()
    @age_chart()
    @location_chart()

  @assign_applicant_msg = (selectionNeeded) ->
    $("<div id='err-msg' class='text-danger'>Please select a #{selectionNeeded} first.</div>")

  @invited: ->
    ShopDashboard.setup()

  @invite: ->
    shop_id = $('#shop-id').val()

    $('.ajax-select').select2
      minimumInputLength: 1
      multiple: true
      ajax:
        url: "#{location.origin}/admin/opportunities/#{shop_id}/applicants/search.json"
        quietMillis: 300
        type: 'GET'
        dataType: 'json'
        data: (query, page) ->
          q: query
        results: (users, page) ->
          # results need to look like {id: 1, full_name: '...', profile_picture: '...'}
          results: users
      escapeMarkup: (user) ->
        user
      formatResult: (user) ->
        "<div class='select-user-search'><img src=#{user.profile_picture.thumb.url} class='img-rounded'>#{user.full_name}</div>"
      formatSelection: (user) ->
        user.full_name

  @client_modal: ->
    $('body').on 'click', '.btn-notify', ->
      modal = $('#client_notify_modal')

      modal.modal('hide')
      modal.on 'ajax:success', ->
        notif(
          msg: 'Client influencer approval e-mail has been sent.'
          type: 'notice'
          top: 120
        )
        $('.notify-success').removeClass('hidden')
        $('.modal-body').addClass('hidden')
      modal.on 'ajax:error', ->
        notif(
          msg: 'There was an issue notifying the selected contact(s). Please try again.'
          type: 'error'
          top: 120
        )
        $('.notify-error').removeClass('hidden')


  @gender_chart: ->
    ctx = document.getElementById("js-gender-chart")
    genderData = $('#js-gender-chart').data()

    unless jQuery.isEmptyObject(genderData)
      labels = [ 'Female', 'Male' ]
      values = [ genderData['female']  || 0, genderData['male']  || 0 ]

      data = {
        labels: labels,
        datasets: [{
            data: values,
            backgroundColor: ['#073C63', '#969696']
        }],
      }

      options = {
        pieceLabel: {
          mode: 'percentage',
          precision: 0,
          fontColor: '#eee',
          fontSize: 14,
          fontFamily: '"Lato", Arial, sans-serif'
        },
        title: {
          display: true,
          text: 'Gender Distribution',
          fontFamily: '"Lato", Arial, sans-serif',
          fontSize: 20,
          fontColor: '#5C5C5C'
        },
        animation: {
          duration: 1
        },
        tooltips: {
          enabled: false,
        }
      }

      myDoughnutChart = new Chart(ctx, {
        type: 'doughnut',
        data: data,
        options: options
      })
    else
      $('#gender-chart').find('.no-data-message').show()

  @age_chart: ->

    ctx = document.getElementById("js-age-chart")
    ageData = $('#js-age-chart').data()

    unless jQuery.isEmptyObject(ageData)
      labels = ['18-24', '25-34', '35-44', '45-54', '55-64', '65+']
      values = []

      for bracket in labels
        values.push($('#js-age-chart').data(bracket) || 0)

      data = {
        labels: labels,
        datasets: [{
            data: values,
            backgroundColor: '#073C63'
        }],
      }
      options = {
        title: {
          display: true,
          text: 'Age Group Distribution',
          fontFamily: '"Lato", Arial, sans-serif',
          fontSize: 20,
          fontColor: '#5C5C5C'
        },
        legend: {
          display: false
        },
        scales: {
          xAxis: {
            grid: {
              display:false
            }
          },
          yAxis: {
            grid: {
              display:false
            }
          },
        },
        tooltips: {
          enabled: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          BarLabels: {
            decimalPoints: 0,
          }
        }
      }

      myBarChart = new Chart(ctx, {
        type: 'bar',
        data: data,
        options: options
      });
    else
      $('#age-chart').find('.no-data-message').show()

  @location_chart: ->
    ctx = document.getElementById("js-location-chart")
    locData = $('#js-location-chart').data().locdata

    unless jQuery.isEmptyObject(locData)
      labels = []
      values = []
      totalSum = 0

      for row, index in locData
        if(index < 7)
          labels.push(row[0])
          values.push((row[1]))
        totalSum += row[1]

      data = {
        labels: labels,
        datasets: [{
            data: values,
            backgroundColor: '#073C63'
        }],
      }
      options = {
        title: {
          display: true,
          text: 'Top Influencial Regions',
          fontFamily: '"Lato", Arial, sans-serif',
          fontSize: 20,
          fontColor: '#5C5C5C'
        },
        legend: {
          display: false
        },
        scales: {
          xAxis: {
            grid: {
              display: false,
            }
          },
          yAxis: {
            grid: {
              display: false,
            }
          },
        },
        tooltips: {
          enabled: false,
        },
        layout: {
          padding: {
            right: 30,
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          BarLabels: {
            totalValue: totalSum,
            decimalPoints: 1,
          }
        }
      }
      myHorBarChart = new Chart(ctx, {
        type: 'bar',
        data: data,
        options: options
      });
    else
      $('#location-chart').find('.no-data-message').show()
