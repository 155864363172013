import React from 'react';
import { connect } from 'react-redux';
import CarouselContainer from '../../CarouselContainer'
import ShopCard from '../../ShopCard'

class InfluencerDashboardHomeSuggestedShops extends React.Component {
  render() {
    const {
      opportunities,
      i18n
    } = this.props;

    // The data may not be present the first time this renders, so skip rendering if there are no shops
    if(_.isNull(opportunities.topMatches) || _.isUndefined(opportunities.topMatches)) {
      return null;
    }

    return <div>
      <div className='form-lr mb-15'>
        <h2 className='pl-10'>
          {i18n.suggested_opps}
        </h2>
        <a className='btn' href='/opportunities'>
          {i18n.see_all}
        </a>
      </div>

      {
        opportunities.topMatches.length == 0 &&
        <div className='card text-center'>
          <i className='far fa-clock fa-2x text-color-subdued mb-15' />
          <h1 className='text-weight-bold'>
            {i18n.no_opportunities}
          </h1>
          <h4 className='text-color-subdued'>
            {i18n.check_back_later}
          </h4>
        </div>
      }

      {
        opportunities.topMatches.length != 0 &&
        <CarouselContainer neverExpand={true} desktopArrows={true}>
          {
            opportunities.topMatches.map(shop =>
              <ShopCard
                key={shop.id}
                shop={shop}
                selectedPhase='Open'
                show_new_badge={false}
                />
            )
          }
        </CarouselContainer>
      }
    </div>;
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      opportunities: _.get(state, 'index.opportunities', {}),
      i18n: {
        ...state.i18n,
        ..._.get(state,'index.i18n')
      }
    };
  }
};

InfluencerDashboardHomeSuggestedShops.displayName = 'InfluencerDashboard.Home.SuggestedShops';
export default InfluencerDashboardHomeSuggestedShops = connect(
  InfluencerDashboardHomeSuggestedShops.mapStateToProps,
  null
)(InfluencerDashboardHomeSuggestedShops);
