import React from 'react';
var PromoInstructionsModal = createReactClass({
  propTypes: {
    handleHideModal: PropTypes.func.isRequired,
  },

  getInitialState: function () {
    return {
      copyTextAlert: false,
    };
  },

  componentDidMount: function () {
    $(this.getDOMNode()).modal('show');
    $(this.getDOMNode()).on('hidden.bs.modal', this.props.handleHideModal);
    new Clipboard('.js-copy_promo_text');
  },

  handleCopyTextClick: function () {
    var that = this;
    this.setState({
      copyTextAlert: true,
    });
    setTimeout(function () {
      that.setState({
        copyTextAlert: false,
      });
    }, 2000);
  },


  render: function () {
    var promotion = this.props.promotion;
    var platform = promotion.platform;
    var mediaStepDisplay = promotion.has_media;
    var imageDownloadPath = promotion.promo_post_id ? 'promotions/download_promo_post_img/' + promotion.promo_post_id : '#'
    var FacebookSponsoredContentHelpLink = 'https://www.facebook.com/business/help/788160621327601/';

    return (
      <div className="modal promo_instructions_modal">
        <div className="modal-dialog">
          <button
            type="button"
            className="promo_instructions_modal--close_btn"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="modal-content">
            <div className="modal-body">
              <div className="promo_instructions_modal--promo_conf_msg text-info text-center">
                <span>Participation confirmed! </span>
                Follow the instructions below to submit your {promotion.action_label.toLowerCase()}.
              </div>
              <div className="promo_instructions_modal--step clearfix">
                <div className="promo_instructions_modal--step_inner center-block text-center">
                  <h4 className="promo_instructions_modal--step_heading mt-0" />
                  <div>
                    <button
                      type="button"
                      className="btn btn-default btn-md t-transform-none js-copy_promo_text"
                      data-clipboard-text={promotion.content}
                      onClick={this.handleCopyTextClick}
                    >
                      <i className="fas fa-copy fa-fw btn--icon-left" />
                      Copy the text
                    </button>
                    &nbsp; below and paste into a new {promotion.action_label.toLowerCase()} EXACTLY as it is written below.
                  </div>
                  <div style={{ position: 'relative' }}>
                    <textarea
                      className="form-control copy_promo_textarea"
                      value={promotion.content}
                      readOnly
                    />
                    <small className="text-warning">
                      Do not change the text in any way.
                    </small>
                    <button
                      type="button"
                      className="btn
                                btn-default
                                btn-md
                                t-transform-none
                                js-copy_promo_text"
                      style={{ position: 'absolute', top: 0, right: 0 }}
                      data-clipboard-text={promotion.content}
                      onClick={this.handleCopyTextClick}
                    >
                      <i className="fas fa-copy" />
                    </button>
                    {
                      this.state.copyTextAlert &&
                        <div
                          className="alert alert-success alert-narrow"
                          style={{ position: 'absolute', top: '0', zIndex: '1' }}
                        >
                          Copied to clipboard!
                        </div>
                    }
                  </div>
                </div>
              </div>

              {
                mediaStepDisplay &&
                  <div className="promo_instructions_modal--step clearfix">
                    <div className="promo_instructions_modal--step_inner center-block text-center">
                      <h4 className="promo_instructions_modal--step_heading mt-0" />
                      <a
                        href={imageDownloadPath}
                        className="btn btn-default btn-md t-transform-none mt-10 mb-15"
                      >
                        <i className="fas fa-download fa-fw btn--icon-left" />
                        Download the image
                      </a>
                      &nbsp; and add it to your {promotion.action_label.toLowerCase()}.
                      <small className="text-warning mb-10 d-block">
                        Do not edit or change the image at all.
                      </small>
                      <img src={promotion.media} className="img-responsive center-block" />
                    </div>
                  </div>
              }

              <div className="promo_instructions_modal--step clearfix">
                <div className="promo_instructions_modal--step_inner center-block text-center">
                  <h4 className="promo_instructions_modal--step_heading mt-0" />
                  <div style={{ margin: '20px auto' }}>
                    <a
                      href={promotion.user_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-default t-transform-none promo--create_btn"
                    >
                      <i className={"fas fa-fw " + promotion.social_icon} />
                      Create your {promotion.action_label.toLowerCase()} &nbsp;
                      <i className="fas fa-long-arrow-right fa-fw" />
                    </a>
                    <span className="ml-10">and copy the URL.</span>
                  </div>
                </div>
                {
                  platform == 'Facebook' &&
                    <div className="promo_instructions_modal--step_inner center-block fb_sponsored_warning">
                      <div style={{ margin: '0 20px'}}>
                        <p><b>IMPORTANT</b> - since this is a sponsored post you MUST tag {promotion.sponsor_name} using the Branded Content Tool.
                            If you do not properly tag {promotion.sponsor_name} then your post will be rejected.
                            For help using the Branded Content Tool, <a href={FacebookSponsoredContentHelpLink}>click here.</a></p>
                      </div>
                    </div>
                }
              </div>
              {
                platform == "Twitter" &&
                  <TwitterPromoInstructionsSubmit promotion={promotion} />
                ||
                platform == "Facebook" &&
                  <FacebookPromoInstructionsSubmit promotion={promotion} />
              }
            </div>
          </div>
        </div>
      </div>
    );
  },
});
