import React from 'react';
export default class YoutubeVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.url && this.props.image_url !== nextProps.image_url) {
      this.setState({...nextProps});
    }
  }

  render() {
    const {title, description, image_url, hightlightHashTags, url} = this.state;
    const highlighted = hightlightHashTags(description);
    const imageStyles = {
      width: '100%',
      height: 'auto',
      margin: 'auto 0px',
      padding: '5px',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)'
    };
    return (
      <div style={{ width: '100%', paddingBottom: 15, boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)' }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h3>Video Details</h3>
              <p><b>Note:</b> All the metrics for the video will be collected in daily collection.</p>
              <hr/>
            </div>
            <div className="col-md-3">
              <a href={url} target="_blank">
                <img src={image_url} alt="youtube video thumbnail" style={imageStyles} />
              </a>
            </div>
            <div className="col-md-9">
              <p><b>Title:</b>{title}</p>
              <p>
                <b>Description</b>
                <span dangerouslySetInnerHTML={{ __html: highlighted }}></span>
              </p>
            </div>
            <div className="col-md-12 text-right">
              <hr />
              <button className="btn btn-primary" onClick={this.props.onSave}>Save</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
YoutubeVideo.displayName = 'AuxillaryContent.YoutubeVideo';
