import React from 'react';
import PropTypes from 'prop-types'

export default class BackgroundFade extends React.Component {
  render() {
    const { zIndex, onClick, styleOverrides } = this.props;
    let overrides = styleOverrides || {};
    if(zIndex) {
      overrides.zIndex = zIndex;
    }

    return <div className='background-fade' style={overrides} onClick={onClick}/>;
  }
}

BackgroundFade.propTypes = {
  zIndex: PropTypes.number,
  onClick: PropTypes.func.isRequired
}
