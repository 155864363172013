import React from 'react';
import { connect } from 'react-redux';
import Toggle from '../Toggle'

class UsersNotificationSettings extends React.Component {
  render() {
    const { i18n } = this.props;

    return <div className='card-plus account-settings notification-settings'>
      <div className='card-header'>
        {i18n.labels.notification_settings}
      </div>

      <div className='card-body'>
        <div className='form-lr'>
          <label>{i18n.labels.content_emails}</label>
          <Toggle
            checked={!!this.props.content_emails}
            onUpdate={checked => this.props.updateNotifications({ content_emails: checked })}
            />
        </div>
        <p className='email-description'>
          {i18n.descriptions.content}
        </p>
        <div className='form-lr'>
          <label>{i18n.labels.invitation_emails}</label>
          <Toggle
            checked={!!this.props.invitation_emails}
            onUpdate={checked => this.props.updateNotifications({ invitation_emails: checked })}
            />
        </div>
        <p className='email-description'>
          {i18n.descriptions.invitations}
        </p>
        <div className='form-lr'>
          <label>{i18n.labels.general_emails}</label>
          <select
            className='form-control'
            value={this.props.frequency}
            onChange={e => this.props.updateNotifications({ frequency: e.target.value })}
            >
            <option value='hourly'>
              {i18n.frequency_options.hourly}
            </option>
            <option value='daily'>
              {i18n.frequency_options.daily}
            </option>
            <option value='weekly'>
              {i18n.frequency_options.weekly}
            </option>
            <option value='never'>
              {i18n.frequency_options.never}
            </option>
          </select>
        </div>
        <p className='email-description'>
          {i18n.descriptions.general}
        </p>
      </div>
    </div>;
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      ...state.settings.notification_setting,
      i18n: state.meta.i18n
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateNotifications: change => dispatch({ type: 'UPDATE_NOTIFICATIONS', value: change })
    };
  }
};

UsersNotificationSettings.displayName = 'Users.NotificationSettings';
export default connect(
  UsersNotificationSettings.mapStateToProps,
  UsersNotificationSettings.mapDispatchToProps
)(UsersNotificationSettings);
