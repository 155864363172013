import React from 'react';
import { connect } from 'react-redux';
import SingleSelect from '../SingleSelect'
class ProfilesChildren extends React.Component {

  constructor(props) {
    super(props);

    this.validateFields = this.validateFields.bind(this);
    this.addChild = this.addChild.bind(this);
    this.removeChild = this.removeChild.bind(this);
    this.updateField = this.updateField.bind(this);
    this.searchOptions = this.searchOptions.bind(this);
    this.renderChild = this.renderChild.bind(this);
    this.validation = this.validation.bind(this);
    this.inputHasError = this.inputHasError.bind(this);
    this.inputClass = this.inputClass.bind(this);
    this.render = this.render.bind(this);
  }

  validateFields() {
    const { children } = this.props.profile;
    let validations = {};
    let validatedCount = 0;
    const requiredFields = {
      relation: {
        name: 'Relationship'
      },
      age: {
        name: 'Age'
      },
      gender: {
        name: 'Gender',
      }
    };

    const promise = () => new Promise((resolve) => {
      Object.keys(requiredFields).forEach((key) => {
        validatedCount++;
        const field = requiredFields[key];

        children.forEach((child, index) => {
          const value = child[key];

          if (!value || value.length === 0) {
            validations[index] = {
              ...validations[index],
              [key]: `${field.name} cannot be left blank.`
            };
          }
        });

        if (validatedCount === Object.keys(requiredFields).length) {
          resolve();
        }
      });
    });

    promise().then(() => {
      if(_.isEqual({}, validations)) {
        this.props.updateFrontendValidations({ 'Children': undefined });
      } else {
        this.props.updateFrontendValidations({ 'Children': validations });
      }
    });
  }

  addChild(e) {
    e.preventDefault();
    const promise = () => new Promise((resolve) => {
      resolve(this.props.addChild());
    });

    promise().then(() => {
      this.validateFields();
    });
  }

  removeChild(e, index) {
    e.preventDefault();
    const promise = () => new Promise((resolve) => {
      resolve(this.props.removeChild(index));
    });

    promise().then(() => {
      this.validateFields();
    });
  }

  updateField(index, field, value) {
    const promise = () => new Promise((resolve) => {
      resolve(this.props.updateChild(index, field, value));
    });

    promise().then(() => {
      this.validateFields();
    });
  }

  searchOptions(field, query, callback) {
    const results = _.filter(
      this.props[field],
      value => value.name.toLowerCase().includes(query.toLowerCase())
    );
    callback(results);
  }

  validation(index, field) {
    const validations = this.props.frontendValidations['Children'] || {};
    const fieldValidations = validations[index] ? validations[index][field] : null;

    if(!fieldValidations) {
      return null;
    }

    return <span className='help-text no-prefix invalid'>
      {fieldValidations}
    </span>;
  }

  inputHasError(index, field) {
    if(this.validation(index, field)) {
      return true;
    }

    return false;
  }

  inputClass(index, field) {
    if(this.validation(index, field)) {
      return 'form-control invalid';
    }

    return 'form-control';
  }

  renderChild(child, index) {
    return (
      <div className='card-body child' key={index}>

        <div className='input-wrap'>
          <label>Relationship</label>
          <div className='radio'>
            <label>
              <input
                name={`child-${index}`}
                type='radio'
                value='Child'
                checked={child.relation === 'Child' ? 'checked' : ''}
                onChange={(e) => this.updateField(index, 'relation', 'Child')}>
              </input>
              Child
            </label>
          </div>
          <div className='radio'>
            <label>
              <input
                name={`child-${index}`}
                type='radio'
                value='Grandchild'
                checked={child.relation === 'Grandchild' ? 'checked' : ''}
                onChange={(e) => this.updateField(index, 'relation', 'Grandchild')}>
              </input>
              Grandchild
            </label>
          </div>
          {this.validation(index, 'relation')}
        </div>

        <div className='input-wrap'>
          <label>Age</label>
          <input
            className={this.inputClass(index, 'age')}
            type='number'
            name='Age'
            min='0'
            max='100'
            value={child.age}
            onChange={(e) => this.updateField(index, 'age', e.target.value)}>
          </input>
          {this.validation(index, 'age')}
        </div>

        <div className='input-wrap'>
          <label>Gender</label>
          <SingleSelect
            hasError={this.inputHasError(index, 'gender')}
            search={(query, callback) => this.searchOptions('genders', query, callback)}
            selected={child.gender}
            onUpdate={(item) => this.updateField(index, 'gender', item)}
            placeholder='Select Gender'
          />
          {this.validation(index, 'gender')}
        </div>

        <button className='btn danger' onClick={(e) => this.removeChild(e, index)}>Remove</button>
        <div className='divider'></div>
      </div>
    );
  }

  render() {
    const { children } = this.props.profile;

    return (
      <div className='card-plus children-demographics-card'>
        <div className='card-header'>Children/ Grandchildren</div>
        <div className='children-container'>
          {
            children &&
            children.length > 0 &&
            children.map((child, index) => { return this.renderChild(child, index) })
          }
        </div>
        <div className='card-footer'>
          <button className='btn primary' onClick={(e) => this.addChild(e)}>Add Child/ Grandchild</button>
        </div>
      </div>
    );
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      activeTab: state.activeTab,
      profile: state.profile,
      genders: state.genders,
      frontendValidations: state.frontendValidations,
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      addChild: () => dispatch({ type: 'ADD_CHILD' }),
      removeChild: (index) => dispatch({ type: 'REMOVE_CHILD', value: { index: index }}),
      updateChild: (index, field, value) => dispatch({ type: 'UPDATE_CHILD', value: { index: index, field: field, value: value } }),
      updateProfile: (field, value) => dispatch({ type: 'UPDATE_PROFILE', value: { field: field, value: value } }),
      updateFrontendValidations: validations => dispatch({ type: 'UPDATE_FRONTEND_VALIDATIONS', value: validations }),
    }
  }
};

ProfilesChildren.displayName = 'Profiles.Children';
export default connect(
  ProfilesChildren.mapStateToProps,
  ProfilesChildren.mapDispatchToProps
)(ProfilesChildren);

