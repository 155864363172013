/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require("@rails/ujs").start();
// require("turbolinks").start();
require('../plugins/tokenizer.min');
require('../plugins/bootstrap-datetimepicker_customized');
const select2 = require('../plugins/select2.min');
const _ = require('lodash');
import $ from 'jquery';
import 'jqueryui';
import 'jquery-ui';
import 'core-js/stable'; //required?
import 'regenerator-runtime/runtime'; //required?
import "bootstrap-sass/assets/javascripts/bootstrap.min";
import "bootstrap-filestyle/src/bootstrap-filestyle.min.js";
import './application.css';

// Support component names relative to this directory:
var componentRequireContext = require.context('components', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);

export * as Header from '../components/header/Header';

require('../coffee');

global.select2 = select2;
global.$ = $;
