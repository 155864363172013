import React from 'react';
import PropTypes from 'prop-types'

export default class FloatingBox extends React.Component {
  constructor(props) {
    super(props);

    this.getPlacement = this.getPlacement.bind(this);
    this.getType = this.getType.bind(this);
    this.render = this.render.bind(this);
  }

  render() {
    const floatingStyle = {
      backgroundColor: this.props.bgColor || 'white',
      borderRadius: '4px',
      boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
      padding: '8px',
      position: 'absolute',
      width: this.props.width || '300px',
      top: '-100%',
      zIndex: '900',
      ...this.getPlacement()
    };
    const innerStyle = {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      fontSize: '12pt',
      textAlign: 'left',
    };

    return (
      <div style={floatingStyle}>
        {
          this.props.message &&
          <div style={innerStyle}>
            {!this.props.hideIcon && <i style={{paddingRight: '12px'}} className={this.getType()}/>} {this.props.message}
          </div>
        }
        {this.props.children}
      </div>
    );
  }

  getType() {
    switch(this.props.type) {
      case 'warning':
        return 'fas fa-exclamation-triangle text-warning';
      case 'error':
        return 'fas fa-exclamation-triangle text-danger';
      case 'info':
      default:
        return 'fas fa-info';
    }
  }

  getPlacement() {
    switch(this.props.placement)  {
    case 'right':
      return {
        left: '103%'
      };
    case 'left':
    default:
      return {
        right: '103%'
      };
    }
  }
};

FloatingBox.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  hideIcon: PropTypes.bool,
  placement: PropTypes.string
};
