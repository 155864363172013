import React from 'react';
import { Remote } from '../utils';

var FacebookPromoInstructionsSubmit = createReactClass({

  getInitialState: function () {
    return {
      post_url: '',
    };
  },

  componentDidMount: function () {
    this.validateForm('#facebook_url_form');
  },

  validateForm: function (form) {
    var _this = this;
    var promotion = _this.props.promotion;
    var facebookName = promotion.handle;

    $(form).validate({
      rules: {
        facebook_url: {
          required: true,
          facebook_url_check: true,
          facebook_name_match: true,
          facebook_post_path_check: true,
        },
      },
    });

    jQuery.validator.addMethod('facebook_url_check', (function (value, element) {
      return this.optional(element) || /(?:www\.)?facebook\.com\/[\w\.]+\/.*/i.test(value);
    }), 'Enter a valid Facebook status url');

    jQuery.validator.addMethod('facebook_name_match', (function (value, element) {
      return this.optional(element) || value.split('/')[3].toLowerCase() === facebookName.toLowerCase();
    }), 'This post URL does not belong to the account with which you claimed the Promotion (' + facebookName + ').');

    jQuery.validator.addMethod('facebook_post_path_check', (function (value, element) {
      var url_regex = promotion.has_media ? /\/(photos|video)\/[\w]/ : /\/posts\/[\w]/
      return this.optional(element) || url_regex.test(value);
    }), 'There is an issue with the image in your post. Check out the <a href="https://cbias-kb.socialfabric.us/" target="_blank">Promotions FAQ</a> for more info.');
  },

  handlePostUrlChange: function (e) {
    this.setState({ post_url: e.target.value });
  },

  submitPost: function (e) {
    e.preventDefault();
    if ( $('#facebook_url_form').valid() ) {
      document.getElementById('submit_post_button').setAttribute('disabled', 'disabled');
      Remote().request({
        url: 'user_promo_posts/' + this.props.promotion.id,
        method: 'PUT',
        data: {
          post_url: this.state.post_url,
          username: this.props.promotion.handle,
        },
      })
    } else {
      e.stopPropagation();
    }
  },

  render: function () {

    var promotion = this.props.promotion;
    var submission_path = promotion.has_media ? 'photos' : 'posts';

    return (
      <div className="promo_instructions_modal--step clearfix">
        <div className="promo_instructions_modal--step_inner center-block text-center" style={{ paddingBottom: '35px' }}>
          <h4 className="promo_instructions_modal--step_heading mt-0" />
          <div className="mb-15 text-left-sm">
            Paste the Facebook post URL in the input below, press submit and get paid!
          </div>
          <form onSubmit={this.submitPost} id="facebook_url_form">
            <div className="form-group">
              <input
                className="form-control mb-5"
                placeholder={promotion.user_link + '/'+ submission_path +'/12345678'}
                name="facebook_url"
                value={this.state.facebook_url}
                onChange={this.handlePostUrlChange}
              />
            </div>
            <button type="submit" className="btn btn-primary ml-10" id="submit_post_button">
              Submit Facebook post URL
            </button>
          </form>
          <a className="btn btn-link btn-cancel" data-dismiss="modal" style={{ position: 'absolute', bottom: 0, left: 0 }}>
            <i className="fas fa-angle-left fa-fw" />
            cancel
          </a>
          <div className="text-center text-left-sm mt-10 mb-10">
            You have until <b>{promotion.submit_by}</b> to submit your link.
          </div>
        </div>
      </div>
    );
  },
});
