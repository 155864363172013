import React from 'react';
import PropTypes from 'prop-types'
import TableActions from '../../../table/Actions'

export default class GeneralRequirementsRow extends React.Component {
  render() {
    const { name, round_task_requirements } = this.props.roundTask;

    return (
      <tr>
        <td>{name}</td>
        <td>{round_task_requirements.length} Template Content Requirements</td>
        <td>
          <TableActions record={this.props.roundTask}/>
        </td>
      </tr>
    );
  }
};

GeneralRequirementsRow.displayName = 'GeneralRequirements.Row';
GeneralRequirementsRow.propTypes = {
  roundTask: PropTypes.any.isRequired
};

