import React from 'react';
import { Provider } from "react-redux"
import RoundTaskStore from '../../../redux/RoundTasks';
import Index from './Index'

export default class IndexWrapper extends React.Component {
  render() {
    return (
      <Provider store={RoundTaskStore}>
        <Index {...this.props}/>
      </Provider>
    );
  }
};
