import React from 'react';
import InstructionsChecklistItem from './ChecklistItem'
import PropTypes from 'prop-types'

export default class InstructionsChecklistOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: props.items
    };

    this.addItem = this.addItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.generateKey = this.generateKey.bind(this);
    this.itemUpdated = this.itemUpdated.bind(this);
    this.render = this.render.bind(this);
  }

  render() {
    return (
      <div className='row'>
        <div className='col-md-3'>
          <button className='btn btn-xs btn-primary' onClick={this.addItem}>
            <i className='fas fa-plus'/> Add Checklist Item for {this.props.name}
          </button>
        </div>

        <div className='col-md-9'>
          {
            this.state.items.map(item =>
              <InstructionsChecklistItem
                onUpdate={(val) => this.itemUpdated(item.id || item.uuid, val)}
                onDelete={() => this.deleteItem(item)}
                item={item}
                key={item.id || item.uuid} />
            )
          }
        </div>
      </div>
    );
  }

  itemUpdated(key, value) {
    const list = _.cloneDeep(this.state.items);
    const item = list.find((item) => item.uuid ? key == item.uuid : key == item.id);
    item.description = value.description;
    this.setState({
      items: list
    }, () => this.props.onUpdate(this.state.items));
  }

  deleteItem(item) {
    // Some elements will be keyed by uuid since they were created client side, so pick the most suitable
    // key for insert or delete operations.
    let newList = [];
    if(item.id) {
      newList = _.filter(this.state.items, (existing) => existing.id != item.id);
    } else {
      newList = _.filter(this.state.items, (existing) => existing.uuid != item.uuid);
    }

    this.setState({ items: newList }, () => this.props.onUpdate(this.state.items));
  }

  addItem() {
    const newItem = {
      id: null,
      description: '',
      name: this.props.name,
      uuid: this.generateKey()
    };

    this.setState({
      items: [...this.state.items, newItem]
    });
  }

  // Get a random-enough value to key on
  generateKey() {
    return 'clientside-' + Math.floor(Math.random() * 1000000000);
  }
}

InstructionsChecklistOptions.displayName = 'Instructions.ChecklistOptions';
InstructionsChecklistOptions.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired
};
