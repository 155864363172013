import { createStore } from 'redux';

const initialState = {
  meta: {},
  preview: null,
  contentType: {},
  contentState: 'pending',
  rejectionComment: '',
  bloggerShopTask: {},
  submitting: false,
  readOnly: true,
  dashboardVisible: undefined,
  updatingMetrics: false,
  alert: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_META':
      return {
        ...state,
        meta: action.value
      };
    case 'TOGGLE_SUBMITTING':
      return {
        ...state,
        submitting: !state.submitting
      };
    case 'TOGGLE_UPDATING_METRICS':
      return {
        ...state,
        updatingMetrics: !state.updatingMetrics
      };
    case 'SET_REJECTION_DATA':
      return {
        ...state,
        contentState: action.value.contentState,
        rejectionComment: action.value.rejectionComment
      };
    case 'SET_BLOGGER_SHOP_TASK':
      return {
        ...state,
        bloggerShopTask: action.value
      };
    case 'UPDATE_REQUIREMENT_VALIDATION':
    {
      let nextRequirements = _.cloneDeep(state.bloggerShopTask.round_task.round_task_requirements);

      nextRequirements.forEach((requirement) => {
        if(requirement.id === action.value.id){
          _.set(requirement, 'latest_validation_state.verification_status', action.value.latest_validation_state.verification_status);
        }
      });

      let anyFailed = _.some(nextRequirements, [ 'latest_validation_state.verification_status', 'failed' ]);

      return {
        ...state,
        contentState: anyFailed ? 'rejected' : 'accepted' ,
        bloggerShopTask: {
          ...state.bloggerShopTask,
          round_task: {
            ...state.bloggerShopTask.round_task,
            round_task_requirements: nextRequirements
          }
        }
      };
    }
    case 'SET_INSTAGRAM_METRIC_COUNT':
      {
        let instagramAttachments = state.bloggerShopTask.instagram_attachments;
        let updatedAttachments =[];

        if(instagramAttachments.length){
          updatedAttachments = state.bloggerShopTask.instagram_attachments.map((attachment) => {
            if(attachment.id == action.value.id){
              return {...attachment, ...action.value}
            }else{
              return attachment;
            };
          });
        } else {
          updatedAttachments = [{
            ...action.value,
            attachment_type: 'analytics'
          }]
        }
        return {
          ...state,
          bloggerShopTask: {
            ...state.bloggerShopTask,
            instagram_attachments: updatedAttachments
          }
        }
      }
    case 'SET_PREVIEW':
    {
      return {
        ...state,
        preview: action.value
      };
    }
    case 'value.':
    {
      return {
        ...state,
        contentState: action.value.contentState,
        rejectionComment: action.value.rejectionComment
      };
    }
    case 'SET_CONTENT_STATE':
    {
      return {
        ...state,
        contentState: action.value,
      };
    }
    case 'UPDATE_STORY':
    {
      let storyMedias = _.cloneDeep(state.bloggerShopTask.instagram_business_story_medias);

      let newStoryMedias = storyMedias.map((story) => {
                        if(story.id == action.value.id){
                          return action.value;
                        } else {
                          return story;
                        }
                      })

      return {
        ...state,
        bloggerShopTask: {
          ...state.bloggerShopTask,
          instagram_business_story_medias: newStoryMedias
        }
      };
    }
    case 'UPDATE_STORY_FALLBACK_FOLLOWERS':
    {
      let followers = parseInt(action.value);
      let storyMedias = _.cloneDeep(state.bloggerShopTask.instagram_business_story_medias);

      let newStoryMedias = storyMedias.map((story) => {
                        story.fallback_follower_count = followers
                        return story
                      })

      return {
        ...state,
        bloggerShopTask: {
          ...state.bloggerShopTask,
          instagram_business_story_medias: newStoryMedias
        }
      };
    }
    case 'DASHBOARD_VISIBILITY': {
      const { bool } = action.value
      return {
        ...state,
        dashboardVisible: bool
      }
    }
    case 'INITIALIZE_MANUAL_METRICS': {
      return {
        ...state,
        [action.value.name]: action.value.metrics
      }
    }
    case 'SET_MANUAL_METRIC_COUNT': {
      return {
        ...state,
        [action.value.contentName]: {
          ...state.[action.value.contentName],
          [action.value.metric]: action.value.value
        }
      }
    }
    case 'SHOW_ALERT':
      return {
        ...state,
        alert: {
          type: action.value.type,
          message: action.value.message
        }
      }
    case 'HIDE_ALERT':
      return {
        ...state,
        alert: null
      };
    default:
      return state;
  }
};

const AdminContentStore = createStore(
  reducer,
  window && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ name: 'Redux.AdminContentStore' }) // Enable redux devtools middleware
);

export default AdminContentStore;