import '../../plugins/autolink.min'

class window.Utils
  @to_class_case: (underscore_case_str) ->
    if !underscore_case_str
      return ''
    ret_str = ''
    words = underscore_case_str.replace('/', '_')
    words = words.split('_')
    for word in words
      ret_str += word.substr(0,1).toUpperCase() + word.substr(1)
    return ret_str

  # Add hyperlink to selector's text
  @autolink: (selector) ->
    $.each $(selector), ->
      text_with_link = $(this).text().autoLink
        target: '_blank'

      $(this).html(text_with_link)

  @character_limit: (e, text_max = 140) ->
    Utils.remaining_msg(e, text_max)
    $(e).keyup ->
      Utils.remaining_msg(e, text_max)
      return

  @remaining_msg: (e, text_max) ->
    feedback = "#{e}_feedback"
    text = $(e).val() || ""
    text_length = text.replace(/\n/g, "\n\r").length
    text_remaining = text_max - text_length
    msg_color = if text_remaining <= 0 then 'red' else 'inherit'
    if text_remaining < 0
      tail = " too many"
      text_remaining = -text_remaining
    else
      tail = " remaining"
    msg = text_remaining + " character"
    if text_remaining != 1
      msg += "s"
    msg += tail
    $(feedback).css('color', msg_color).text(msg)
    return

  @twitter_character_limit: (e, text_max = 140) ->
    Utils.twitter_remaining_msg(e)
    $(e).keyup ->
      Utils.twitter_remaining_msg(e)
      return

  @twitter_remaining_msg: (e) ->
    feedback = "#{e}_feedback"
    text_length = twttr.txt.getTweetLength($(e).val());
    text_remaining = 140 - text_length
    msg_color = if text_remaining <= 0 then 'red' else 'inherit'
    if text_remaining < 0
      tail = " too many"
      text_remaining = -text_remaining
    else
      tail = " remaining"
    msg = text_remaining + " character"
    if text_remaining != 1
      msg += "s"
    msg += tail
    $(feedback).css('color', msg_color).text(msg)
    return

  @popover_overlay: ->
    $(document.createElement('div')).addClass('overlay').appendTo($('body'))
    $('body').on 'show.bs.popover', ->
      $('.overlay').addClass('in')
    # this is to make the popover dismissable on iOS devices
    $('.overlay').on 'click', ->
      $('[data-toggle="popover"]').popover('hide')
    $('body').on 'hide.bs.popover', ->
      $('.overlay').removeClass('in')
