import React from 'react';
import { connect } from 'react-redux';
import { FileUploader } from '../utils';

import ConversationsMessageEntryFields from './MessageEntryFields'

class ConversationsMessageEntry extends React.Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
  }

  sendMessage(event){
    event.preventDefault();
    const {conversation, file, media_mode} = this.props;
    const body = (!!media_mode ? file : this.props.body);
    const messageType = (!!media_mode ? 'image' : 'text');
    const recepientIds = Object.values(conversation.participants).filter(participant => participant.current_user !== true).map((participant) => participant.id)
    let data = new FormData();
    data.append('body', body);
    data.append('conversation_id', conversation.id);
    recepientIds.forEach((id, index) => {
      data.append('recepient_ids[]', id);
    })
    data.append('message_type', messageType);
    this.props.setMessageLoading({message_loading: true})
    FileUploader().request({
      method: 'POST',
      url: `/conversations/upload_and_send_message`,
      data: data,
      //contentType: false,
      //processData: false,
      
    }).then((response) => {
      this.props.appendMessage(response.data);
      this.props.resetMessageState();
      const node = document.getElementById('conversation-messages-container')
      node.scrollTop = node.scrollHeight;
    }).catch((error) => {
      this.props.setMessageLoading({message_loading: false});
    });
  }

  render() {
    const {selected, loading} = this.props;
    if(selected && !loading) {
      return (
         <form onSubmit={this.sendMessage}>
           <ConversationsMessageEntryFields />
        </form>
      );
    }else{
      return null;
    }
  }

  static mapStateToProps(state, myProps) {
    return {
      conversation: state.conversation,
      loading: state.loading,
      selected: state.selected,
      media_mode: state.media_mode,
      file: state.file,
      body: state.body,
      ...myProps
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      toggleLoading: () => dispatch({type: 'TOGGLE_LOADING'}),
      setSelected: data => dispatch({type: 'SET_SELECTED', value: data}),
      setMessageLoading: data => dispatch({type: 'SET_MESSAGE_LOADING', value: data}),
      appendMessage: data => dispatch({type: 'ADD_MESSAGE', value: data}),
      setMessageFields: data => dispatch({type: 'SET_MESSAGE_FIELDS', value: data}),
      resetMessageState: () => dispatch({type: 'RESET_MESSAGE_STATE'})
    };
  }
}

ConversationsMessageEntry.displayName = 'Conversations.MessageEntry';
export default connect(
  ConversationsMessageEntry.mapStateToProps,
  ConversationsMessageEntry.mapDispatchToProps
)(ConversationsMessageEntry);