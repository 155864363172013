import React from 'react';
import { connect } from 'react-redux';
import { Remote } from '../utils';

import ShoppertunitiesHideShopBtn from './HideShopBtn'
import ShoppertunitiesRoundCard from './RoundCard';
import ShoppertunitiesActionBar from './ActionBar';
import ShoppertunitiesShowApplyAlert from './ShowApplyAlert';
import LoadingSpinner from '../LoadingSpinner';

class ShoppertunitiesShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isMobile: false
    }

    this.componentDidMount = this.componentDidMount.bind(this);
    this.loadShoppertunityPosting = this.loadShoppertunityPosting.bind(this);
    this.posting = this.posting.bind(this);
    this.canApply = this.canApply.bind(this);
    this.actionBar = this.actionBar.bind(this);
    this.days = this.days.bind(this);
    this.render = this.render.bind(this);
    this.showHideShopButton = this.showHideShopButton.bind(this);
  }

  componentDidMount(){
    this.props.setMeta({
      shoppertunityId: this.props.shoppertunityId,
      userId: this.props.userId,
      proxyUrl: this.props.proxyUrl,
      I18n: this.props.I18n,
      connectUrls: this.props.connectUrls,
      alerts: this.props.alerts,
      controller: this.props.controller,
    });
    this.loadShoppertunityPosting();
    // 992px is bootstrap md-min width standard
    const mediaQuery = window.matchMedia('(max-width: 992px)');
    if(mediaQuery.matches){
        this.setState({
            isMobile: true
        })
    }
    const component = this;

    // fallback for safari & ios
    if (!mediaQuery.addEventListener) {
      mediaQuery.addEventListener = (_event, event_handler) => {
        mediaQuery.addListener(event_handler)
      }
    }
    mediaQuery.addEventListener('change', (event) => {
        component.setState({
        isMobile: event.matches
      })
    })
  }

  loadShoppertunityPosting(){
    const { proxyUrl, shoppertunityId } = this.props;
    const params = $.param({
      api_action: `shop_postings/${shoppertunityId}`
    });

    Remote().request({
      url: proxyUrl + '?' + params,
      method: 'GET',

    }).then((response) => {
      this.props.setShoppertunity(response.data)
      this.props.setLoading(false);
    });
  }

  days(){
    const { shoppertunity } = this.props;
    if(shoppertunity.shop_days_remaining > 1){
      return 'days'
    }
    return 'day'
  }


  showHideShopButton(shoppertunity){
      return ( shoppertunity.show_hide_shop_button && !this.props.meta.controller.includes('admin/') )
  }

  posting(){
    const { meta, shoppertunity, defaultImage } = this.props;
    const canApplyForShop = this.canApply()
    const showHideShopButton = this.showHideShopButton(shoppertunity);

    return <div className="container main-container" style={{paddingTop:'0'}}>
              <div className="card-plus clickable shop-card shop_details--top_box_row clearfix">
                <div className="shop_details--top_box_col text-center">
                  <img src={shoppertunity.image_url || defaultImage}/>
                </div>
                <div className="shop_details--top_box_col text-center">
                  <div className="shop_details--top_box">
                    <div className="shop_details--label">{meta.I18n.shop_posting.deadline}</div>
                    <div className="shop_details--box_item">
                      { shoppertunity.shop_days_remaining ? `${shoppertunity.shop_days_remaining} ${this.days()} left to ${shoppertunity.require_applications ? 'apply' : 'join'}` : "Last chance to apply"}
                    </div>
                  </div>
                </div>
                <div className="shop_details--top_box_col text-center">
                  <div className="shop_details--top_box">
                    <div className="shop_details--label">{meta.I18n.shop_posting.social_platform}</div>
                    <div className="shop_details--box_item">
                      <i className={`fas fa-i social-icon--${shoppertunity.platform.name.toLowerCase()}`} /> {shoppertunity.platform.name}
                    </div>
                  </div>
                </div>
                <div className="shop_details--top_box_col text-center">
                  <div className="shop_details--top_box">
                    <div className="shop_details--label">{meta.I18n.shop_posting.client}</div>
                    <div className="shop_details--box_item">
                    {shoppertunity.campaign.client.name}
                    </div>
                  </div>
                </div>
                <div className="shop_details--top_box_col text-center">
                  <div className="shop_details--top_box">
                    <div className="shop_details--label">{meta.I18n.shop_posting.retailer}</div>
                    <div className="shop_details--box_item">
                      {shoppertunity.retailers.map((retailer) => retailer.name ).join(', ')}
                    </div>
                  </div>
                </div>
                { showHideShopButton && !this.state.isMobile &&
                   <ShoppertunitiesHideShopBtn shoppertunity={shoppertunity}
                                isMobile={this.state.isMobile}/> }
              </div>
              { showHideShopButton && this.state.isMobile &&
                <div className="row">
                  <ShoppertunitiesHideShopBtn shoppertunity={shoppertunity}
                               isMobile={this.state.isMobile}/>
                </div>
              }
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="card-plus clickable card">
                    <h2><strong>Description</strong></h2>
                    <div className="shop_details--shop_description" dangerouslySetInnerHTML={{__html: shoppertunity.full_description}}></div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12" id="round_card_selection">
                  {shoppertunity.rounds.map((round, index) => <ShoppertunitiesRoundCard round={round} key={round.id} canApply={canApplyForShop} />)}
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 text-right">
                  <a className="back_to_top" href="#back_to_top_anchor">
                    <i className="fas fa-arrow-circle-up"/>
                    {meta.I18n.shop_posting.back_to_top}
                  </a>
                </div>
              </div>
          </div>
  }

  canApply(){
    const { shoppertunity } = this.props;
    return shoppertunity.can_apply && !shoppertunity.shop_application
  }

  actionBar(){
    if(this.canApply()){
      return <ShoppertunitiesActionBar/>
    }
    else if(this.props.meta.controller != 'admin/shop_posting'){
      return <ShoppertunitiesShowApplyAlert {...this.props.alerts} />
    }
  }

  render(){
    const { loading } = this.props;
    if(loading) {
      return <LoadingSpinner />;
    }

    return <div>
      {this.actionBar()}
      {this.posting()}
    </div>
  }

  static mapStateToProps(state, myProps){
    return {
      ...myProps,
      meta: state.meta,
      shoppertunity: state.shoppertunity,
      loading: state.loading,
      selectedRounds: state.selectedRounds
    };
  }

  static mapDispatchToProps(dispatch){
    return {
      setMeta: meta => dispatch({ type: 'SET_META', value: meta }),
      setShoppertunity: shoppertunity => dispatch({ type: 'SET_SHOPPERTUNITY', value: shoppertunity}),
      setLoading: loading => dispatch({ type: 'SET_LOADING', value: loading}),
      setSelectedRounds: rounds => dispatch({ type: 'SET_SELECTED_ROUNDS', value: rounds})
    };
  }
};

ShoppertunitiesShow.displayName = 'Shoppertunities.Show';

export default connect(
  ShoppertunitiesShow.mapStateToProps,
  ShoppertunitiesShow.mapDispatchToProps
)(ShoppertunitiesShow);