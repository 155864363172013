class window.Searches

  @index: ->
    glyph = $("a[data-search='#{$('input#search_class').val()}']").data('fa')
    $("#selected_search").removeClass().addClass("fas fa-#{glyph}")
    if $('input#search_class').val() == 'users'
      $(".dropdown-toggle.search_menu>i.fas").html("<span>Members</span>")
    else if $('input#search_class').val() == 'shoppertunities'
      $(".dropdown-toggle.search_menu>i.fas").html("<span>Shops</span>")
    else
    	$(".dropdown-toggle.search_menu>i.fas").html("<span>"+$('input#search_class').val()+"</span>")



    if $('.shop-card-search').length

      msnry = new Masonry '.shop-card-search',
        itemSelector: '.shop-card-wrapper'

      $('.shop-card-search').imagesLoaded ->
        msnry.layout()

      msnry.on 'layoutComplete', ( msnryInstance, laidOutItems) ->
        $.each laidOutItems, ->
          $elem = $(this.element)
          Shoppertunities.card_effects( $elem )
