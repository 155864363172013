import React from 'react';

const KeyToComponent = {
  'Messaging::Message': Conversations.MessageBubble,
  'Messaging::Event': Conversations.EventBubble
}

export default class MessagesEvents extends React.Component {
  render() {
    const { messages, events } = this.props
    const messagesAndEvents = messages.concat(events).sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

    return (
      <div>
        {
          messagesAndEvents.map(item => {
            const TagName = KeyToComponent[item.type];
            return (
              <TagName
                key={item.id}
                {...item}
              />
            )
          })
        }
      </div>
    );
  }
}
