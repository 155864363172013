import React from 'react';
import { connect } from 'react-redux';
import InfluencerDashboardHomeTaskCard from './TaskCard'
import { Remote } from '../../utils';

class InfluencerDashboardHomeUpcomingTaskList extends React.Component {
  constructor(props) {
    super(props);
    this.renderFilterBar = this.renderFilterBar.bind(this);
    this.renderCards = this.renderCards.bind(this);
    this.renderLoadMore = this.renderLoadMore.bind(this);
    this.render = this.render.bind(this);
    this.fetch = this.fetch.bind(this);
    this.showMore = this.showMore.bind(this);
    this.updateSort = this.updateSort.bind(this);
  }

  renderCards() {
    const { tasks } = this.props;
    if ( tasks.length == 0 )
      return;

    return (
      tasks.map(task =>
        <InfluencerDashboardHomeTaskCard key={task.id} {...task} />
      )
    )
  }

  renderEmptyCard(){
    const { tasks, no_tasks } = this.props;
    if ( tasks.length != 0 )
      return;

    return (
      <div className='card text-center'>
        <h4 className='text-color-subdued'>
          {no_tasks}
        </h4>
      </div>
    )
  }

  renderFilterBar() {
    const { options, filter_name, sort } = this.props;
    let filters = (<option></option>);

    if (_.isPlainObject(options)) {
      filters = Object.keys(options).map( (key ) => {
        return <option key={key} value={key}>{options[key]}</option>
      });
    }

    return (
      <div className='filter-bar'>
        <h2 className='mb-10 pl-10'> {filter_name || ''} </h2>
        <div className='filter-options'>
          <select className='form-control' value={sort.sort} onChange={this.updateSort}>
            <React.Fragment>
              { filters }
            </React.Fragment>
          </select>
          <i className='fas fa-caret-down' />
        </div>
      </div>
    )
  }

  renderLoadMore() {
    const { sort, tasks } = this.props;

    if ( sort.complete || tasks.length == 0)
      return;

    return (
      <div className='text-center'>
        <button disabled={!!sort.fetching} className='btn btn-outline' onClick={this.showMore}>
          Show More
          {sort.fetching && <i className='fas fa-circle-notch fa-spin'/>}
        </button>
      </div>
    )
  }

  render() {
    return (
      <div className='influencer-dashboard task-list'>
        { this.renderFilterBar() }
        { this.renderEmptyCard() }
        { this.renderCards() }
        { this.renderLoadMore() }
      </div>
    );
  }

  fetch() {
    const { sort, proxyUrl } = this.props;
    const params = {
      api_action: '/influencer_dashboard/index',
      sort: sort.sort,
      page: sort.page
    }

    return Remote().request({
      url: proxyUrl,
      method: 'GET',
      params: params
    });
  }

  // TODO: Move entire thing to reducer SHOW_MORE
  showMore(e) {
    const { sort } = this.props;
    e.preventDefault();

    // Using a promise here to ensure that we have the latest sort params when we fetch
    new Promise((resolve, _reject) => {
      this.props.updateSort({
        fetching: true,
        page: sort.page + 1
      });
      resolve();
    }).then(() => {
      this.fetch().then((resp) => {
        const { index: tasks } = resp.data;
        this.props.addTasks(tasks);
        this.props.updateSort({
          fetching: false
        });
      });
    });
  }

  // TODO: Move entire thing to reducer UPDATE_SORT
  updateSort(e) {
    e.preventDefault();

    // Using a promise here to ensure that we have the latest sort params when we fetch
    new Promise((resolve, _reject) => {
      this.props.updateSort({ sort: e.target.value, page: 1 });
      resolve();
    }).then(() => {
      this.props.setFetching();
      this.fetch().then(resp => this.props.updateView(resp.data));
    });
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      sort: _.get(state, 'index.tasks.sort', {}),
      tasks: _.get(state, 'index.tasks.upcomingTasks', []),
      options: _.get(state, 'index.i18n.filters', {}),
      filter_name: _.get(state, 'index.i18n.filter_name',''),
      no_tasks: _.get(state,'index.i18n.no_tasks',''),
      proxyUrl: state.proxyUrl
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateView: value => dispatch({
        type: 'UPDATE_VIEW',
        value: {
          view: value.view,
          data: value
        }
      }),
      setFetching: () => dispatch({
        type: 'FETCHING_VIEW',
        value: {
          view: 'index',
          bool: true
        }
      }),
      updateSort: change => dispatch({
        type: 'UPDATE_SORT',
        value: {
          view: 'index',
          list: 'tasks',
          data: change ,
        }
      }),
      addTasks: tasks => dispatch({
        type: 'ADD_TO_LIST',
        value: {
          view: 'index',
          section: 'tasks',
          list: 'upcomingTasks',
          data: tasks
        }
      })
    };
  }
};

InfluencerDashboardHomeUpcomingTaskList.displayName = 'InfluencerDashboard.UpcomingTaskList';
export default connect(
  InfluencerDashboardHomeUpcomingTaskList.mapStateToProps,
  InfluencerDashboardHomeUpcomingTaskList.mapDispatchToProps
)(InfluencerDashboardHomeUpcomingTaskList);
