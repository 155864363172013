import React from 'react';
var PromotionAuth = createReactClass({

  addCommas: function (x) {
    if (x) {return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");}
  },

  render: function () {

    return (
      <div className="alert alert-info text-center mb-0">
        <p style={{ marginBottom: '10px' }}>
          <i className="fas fa-exclamation-triangle fa-fw" />
          Participation in Promotions requires authenticating your social platforms and meeting the minimum follower count.
        </p>
        <div>
          { this.props.twitter_connection_required &&
            <TwitterAuthButton url={this.props.url}
                               follower_requirements={this.addCommas(this.props.twitter_follower_requirements)}
            />
          }
          { this.props.facebook_connection_required &&
            <FacebookPageAuthButton follower_requirements={this.addCommas(this.props.facebook_follower_requirements)}
            />
          }
        </div>
      </div>
    );
  }
});
