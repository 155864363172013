import { createStore } from 'redux';

const initialState = {
  uploads: []
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
  case 'REFRESH_UPLOADS':
    return {
      ...state,
      uploads: action.value
    };
  default:
    return state;
  };
};

const AdMetricsUploadStore = createStore(
  reducer,
  window && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ name: 'Redux.AdMetricsUploadStore' }) // Enable redux devtools middleware
);

export default AdMetricsUploadStore;