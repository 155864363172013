import React from 'react';
class ValidationStateCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showRejections: false,
      arrowClass: 'fa-chevron-down'
    }

    this.rejectedRequirements = this.rejectedRequirements.bind(this);
    this.toggleRejections = this.toggleRejections.bind(this);
    this.rejectionsList = this.rejectionsList.bind(this);
    this.render = this.render.bind(this);
  }


  rejectedRequirements(){
    const requirements = this.props.content_validation_states
                                   .filter(validation => validation.verification_status == 'failed')
                                   .map((validation, i) => {
                                     return(
                                       <li key={i} dangerouslySetInnerHTML={{__html: validation.requirement_description}}></li>
                                     );
                                   })
    return requirements
  }

  toggleRejections() {
    const showRejections = !this.state.showRejections;
    const arrowClass = showRejections ? 'fa-chevron-up' : 'fa-chevron-down';
    this.setState({
      showRejections: showRejections,
      arrowClass: arrowClass
    });
  }

  rejectionsList(){
    const requirements = this.rejectedRequirements();
    if(requirements.length){
      return requirements;
    }else{
      return <li style={{listStyleType: 'none'}}>There are no rejection reasons.</li>
    };
  }

  render() {
    const rejectionCount = this.rejectedRequirements().length
    return(
      <div className="validation-history--row" onClick={this.toggleRejections}>
        <div className="row">
          <div className="cell">{this.props.user || 'Client'}</div>
          <div className="cell">{this.props.humanized_content_state}</div>
          <div className="cell">{this.props.created_date}</div>
          <div className="cell comment-column">{this.props.comment || '-'}</div>
          <div className="cell">
            {rejectionCount} Rejected Requirements
            <i className={`pull-right arrow fas ${this.state.arrowClass}`}></i>
          </div>
        </div>

        {
          this.state.showRejections &&
          <div className="rejected_requirements">
            <b>Rejected Requirements:</b>
            <ul>
              { this.rejectionsList() }
            </ul>
          </div>
        }
      </div>
    );
  }
}

ValidationStateCard.displayName = 'Contents.ValidationStateCard';
export default ValidationStateCard