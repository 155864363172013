import React from 'react';
import { Provider } from "react-redux"
import RoundTasksFormStore from '../redux/RoundTasksForm';
import RoundTasksNew from './New'

export default class RoundTasksNewWrapper extends React.Component {
  render() {
    return (
      <Provider store={RoundTasksFormStore}>
        <RoundTasksNew {...this.props}/>
      </Provider>
    );
  }
};
