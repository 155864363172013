import React from 'react';
class DraftComment extends React.Component {

  constructor(props) {
    super(props);

    this.state = {}
  }

  render() {
    var bubbleKlass = 'col-xs-12';

    const bubbleStyle = {
      background: `#fbfbfb`,
      marginBottom: '10px',
      padding: '10px',
      border: `1px solid #cfe6f3`,
      borderRadius: '10px'
    };

    const date_options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };

    let updated_at = new Date(this.props.comment.updated_at).toLocaleString('en-us', date_options)

    return(
      <div className='row'>

        <div className={bubbleKlass} style={bubbleStyle}>

          <div className='col-xs-6 mb-5'>
            <strong>{this.props.comment.author_user_name}</strong>
          </div>

          <div className='col-xs-6 mb-5 text-right'>
            {updated_at}
          </div>

          <div className="col-xs-12">
            {this.props.comment.comment}
          </div>
        </div>
      </div>
    );
  }
}
export default DraftComment