import React from 'react';
import { Remote } from '../utils';

import InputGroup from '../InputGroup'
import LoadingSpinner from '../LoadingSpinner'
import SaveIndicator from '../SaveIndicator'
import SaveError from '../SaveError'

export default class ShoppertunityPlatformSettingsEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      platformSettings: {},
      saveDisabled: false,
      saveType: 'default',
      saveText: 'Saving...',
      saveErrors: null
    }


    this.componentDidMount = this.componentDidMount.bind(this);
    this.loadShoppertunityPlatformSettings = this.loadShoppertunityPlatformSettings.bind(this);
    this.formatData = this.formatData.bind(this);
    this.renderBlogForm = this.renderBlogForm.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.changePlatformHandler = this.changePlatformHandler.bind(this);
    this.changeTierHandler = this.changeTierHandler.bind(this);
    this.renderSocialForm = this.renderSocialForm.bind(this);
    this.renderPlatformSettings = this.renderPlatformSettings.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount(){
    this.loadShoppertunityPlatformSettings()
  }

  loadShoppertunityPlatformSettings(){
    const params = $.param({
      api_action: `/shoppertunities/${this.props.shoppertunity_id}/shoppertunity_platform_settings`,
    });
    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'GET',

    }).then((response) => {
      this.setState({
        platformSettings: response.data,
        loading: false
      })
    }).catch((error) => {
      saveErrors = error.response.data.responseJSON.errors;
      saveType = 'failure';
      saveText = 'Issue loading platform settings.';

      this.setState({
        platformSettings: {},
        loading: false
      }, () => {
      this.flash({
          saveText: saveText,
          saveType: saveType
      }, 5000)});
    });
  }

  changePlatformHandler(field, value){
    let { platformSettings } = this.state;
    this.setState({
      platformSettings: {
        ...platformSettings,
        [field]: value
      }
    });
  }

  changeTierHandler(tierIndex ,field, value){
    let { platformSettings } = this.state;
    let tiers = platformSettings.shoppertunity_platform_tier_settings;
    let tier = tiers[tierIndex];
    this.setState({
      platformSettings: {
        ...platformSettings,
        shoppertunity_platform_tier_settings: [
          ...tiers.slice(0, tierIndex),
          Object.assign({}, tier, { [field]: value }),
          ...tiers.slice(tierIndex+1)
        ]
      }
    });
  }

  renderTierForms(platform){
    let fieldDisabled = !this.props.form_enabled
    return _.map(this.state.platformSettings.shoppertunity_platform_tier_settings, (tier, index) => {
      return(
        <div key={tier.id}>
          <div className='tier-form'>
            <div className='flex-row'>
              <div className='input_wrap flex-item'>
                <label>{`${tier.name} Final Payment:`}</label>
                <InputGroup
                  type='number'
                  value={tier.payment || 0.00}
                  iconClass='fas fa-dollar-sign'
                  onChange={e => this.changeTierHandler(index, 'payment', e)}
                  placeholder={`${tier.name} Payment Amount`}
                  disabled={fieldDisabled}
                  />
              </div>
              <div className='input_wrap flex-item'>
                <label>{`${tier.name} Campaign Allocation Goal:`}</label>
                <InputGroup
                  type='number'
                  value={tier.campaign_percentage || 0.0}
                  iconClass='fas fa-percent'
                  onChange={e => this.changeTierHandler(index, 'campaign_percentage', e)}
                  placeholder={`${tier.name} Campaign Percentage`}
                  disabled={true}
                  />
              </div>
              <div className='input_wrap flex-item'>
                <label>{`${tier.name} Community Percentile:`}</label>
                <InputGroup
                  type='number'
                  value={tier.community_percentile || 0.0}
                  iconClass='fas fa-percent'
                  onChange={e => this.changeTierHandler(index, 'community_percentile', e)}
                  placeholder={`${tier.name} Campaign Percentage`}
                  disabled={true}
                  />
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  renderBlogForm(){
    let { platform } = this.state.platformSettings
    return(
      <div>
        <div className='tier-settings'>
          { this.renderTierForms() }
        </div>
        <div>
          <h3 className='platform-header'>Facebook Video:</h3>
          <hr/>
          { this.renderSocialForm('Facebook Video') }
        </div>
      </div>
    )
  }

  renderSocialForm(label){
    let { platformSettings } = this.state;
    let { platform } = platformSettings
    let fieldDisabled = !this.props.form_enabled

    return(
      <div className='opportunity_platform_setting_inputs'>
        <div className='input_wrap'>
          <label>{label} Cost Per Thousand Followers:</label>
          <InputGroup
            type='number'
            value={platformSettings.cpm || ''}
            iconClass='fas fa-dollar-sign'
            onChange={e => this.changePlatformHandler('cpm', e)}
            placeholder='Cost Per Thousand'
            disabled={fieldDisabled}
            />
        </div>
        <div className='input_wrap'>
          <label>{label} High Reach Minimum:</label>
          <input
            type='number'
            className='form-control'
            value={platformSettings.high_reach_minimum || 0}
            onChange={e => this.changePlatformHandler('high_reach_minimum', e.target.value)}
            placeholder='High Reach Minimum'
            disabled={fieldDisabled}
            />
        </div>
      </div>
    );
  }

  renderPlatformSettings(){
    let { platform } = this.state.platformSettings;

    if(platform.name == 'Blog'){
      return this.renderBlogForm()
    }else{
      return this.renderSocialForm(platform.name);
    }
  }

  flash(newState, duration) {
    this.setState(newState, () => setTimeout(() => this.setState({
      saving: false
    }), duration));
  }

  formatData() {
    let formData =  _.cloneDeep(this.state.platformSettings)

    formData['shoppertunity_platform_tier_settings_attributes'] = formData.shoppertunity_platform_tier_settings;
    delete formData['shoppertunity_platform_tier_settings'];

    return { shoppertunity_platform_setting: formData }
  }

  saveChanges() {
    this.setState({saveType: 'default', saveErrors: null, saveText: 'Saving...'})
    let {saveText, saveType, saveErrors } = this.state;
    this.setState({
      saveDisabled: true,
      saving: true
    })
    const params = $.param({
      api_action: `/shoppertunities/${this.props.shoppertunity_id}/shoppertunity_platform_settings`,
    });

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      data: this.formatData(),
      method: 'PATCH',

    }).then((response) => {
      saveErrors = null;
      saveText = response.data.message;
      saveType = 'success';
    }).catch((error) => {
      saveErrors = error.response.data.responseJSON.errors;
      saveType = 'failure';
      saveText = '';
    }).then(() =>{
      this.setState({
        saveDisabled: false,
        saveErrors: saveErrors
      }, ()=>{
        this.flash({
          saveText: saveText,
          saveType: saveType
      }, 5000)});
    });
  }

  render() {
    let { loading, saveDisabled } = this.state
    if(loading){
      return <LoadingSpinner />
    }else{
      return (
        <div className='shoppertunity-platform-settings-edit'>
          <h3 className='platform-header'>{this.state.platformSettings.platform.name}:</h3>
          <hr/>
          {this.renderPlatformSettings()}

          {
            this.props.form_enabled &&
            <div className='form-buttons'>
              <a className='btn secondary' href={this.props.redirectUrl}>Cancel</a>
              <button
                className='btn btn-primary'
                onClick={this.saveChanges}
                disabled={saveDisabled}
                >
                Save
              </button>
            </div>
          }
          {
            this.state.saving &&
            <SaveIndicator type={this.state.saveType}>
                {this.state.saveText}
                {this.state.saveErrors && <SaveError errors={this.state.saveErrors}/>}
            </SaveIndicator>
          }
        </div>
      );
    }
  }
}