import React from 'react';
import PropTypes from 'prop-types';

export default class InstructionsIndependentContractorAgreement extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  render() {
    const { text, height } = this.props;
    const textStyle = {
      maxHeight: `${height}px`
    }

    return (
      <div className='ica'>
        <h4>Independent Contractor Agreement</h4>
        <p style={textStyle} dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    );
  }
}
InstructionsIndependentContractorAgreement.displayName = 'Instructions.IndependentContractorAgreement';
InstructionsIndependentContractorAgreement.propTypes = {
  height: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired
}
