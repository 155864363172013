import React from 'react';

export default class LoadingError extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  render() {
    return (
      <div className='wizard-loading-spinner'>
        <div className='text-danger'>
          <i className='fas fa-exclamation-triangle' />
        </div>
        <div className='text'>
          <h3>
            { this.props.text }
          </h3>
        </div>
      </div>
    );
  }
};