import React from 'react';
import { connect } from 'react-redux'
import InfluencerDashboardEarningsSummary from './earnings/Summary'
import InfluencerDashboardPaymentList from './earnings/PaymentList'

class InfluencerDashboardEarnings extends React.Component {
  render() {
    if(_.isEmpty(this.props.payments)){
      return (
        <div className='card influencer-dashboard-no-payments'>
          <i className='fas fa-dollar-sign fa-2x text-color-subdued' />
          <h1 className='text-weight-bold'>
            No Payment Information
          </h1>
          <h4 className='text-color-subdued'>
            Review opportunities to submit content and receive payments
          </h4>
        </div>
      );
    } else {
      return (
        <div className='influencer-dashboard earnings-component'>
          <InfluencerDashboardEarningsSummary />
          <InfluencerDashboardPaymentList />
        </div>
      );
    }
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      payments: _.get(state, 'earnings.payments', [])
    };
  }
};

InfluencerDashboardEarnings.displayName = 'InfluencerDashboard.Earnings';
export default connect(
  InfluencerDashboardEarnings.mapStateToProps, null
)(InfluencerDashboardEarnings);
