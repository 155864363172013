import React from 'react';

class Pagination extends React.Component {
  info() {
    return <div>{this.props.dashboard.page_info}</div>
  }

  control(page, offset, extraClass){
    if (page === 0 || offset < 0)
      return
    return (
      <li key={page} className={`paginate_button ${page} ${extraClass}`} >
        <a href="#" onClick={(e) => e.preventDefault && this.props.updatePage(offset)}>{page}</a>
      </li>
    )
  }

  renderControls(){
    const { page_controls, offset } = this.props.dashboard

    if(!page_controls)
      return null;

    return page_controls.map((page) => this.control(page[0], page[1], page[1] == offset ? 'disabled' : ''))
  }

  render() {
    const { dashboard, loading } = this.props
    if(loading || !dashboard)
      return <div />

    return (
      <div>
        <div className='col-xs-12 col-sm-4'>
          { this.info() }
        </div>
        <div className='col-xs-12 col-sm-8'>
          <div className="dataTables_paginate paging_full_numbers" id="admin_users_table_paginate">
            <ul className="pagination" style={{float: 'right'}}>
              { this.renderControls() }
            </ul>
          </div>
        </div>
      </div>
    )
  }
}
Pagination.displayName = 'UserTable.Pagination';
export default Pagination;