import React from 'react';
import { connect } from 'react-redux'
import { Remote } from '../../../utils'
import LoadingSpinner from '../../../LoadingSpinner';
import CampaignsRequirementsCard from '../Card';
import CampaignsRequirementsInstructionNotesButton from './InstructionNotesButton';
import CampaignsRequirementsInstructionNotesReorderSwitch from './InstructionNotesReorderSwitch';
import CampaignsRequirementsInitialDisplay from '../InitialDisplay';
import CampaignsRequirementsAddFromTemplatesButton from '../AddFromTemplatesButton'

class CampaignsRequirementsInstructionNotesContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sorted: false,
      dragIndex: null,
      dragOverIndex: null,
      draggingEnable: false,
      disableReorderSwitch: false
    }

    this.componentDidMount = this.componentDidMount.bind(this);
    this.loadInstructionNotes = this.loadInstructionNotes.bind(this);
    this.loadInstructionTemplates = this.loadInstructionTemplates.bind(this);
    this.render = this.render.bind(this);
    this.templateCallback = this.templateCallback.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.updateInstructionNotesSort = this.updateInstructionNotesSort.bind(this);
    this.reorderSwitchCallBack = this.reorderSwitchCallBack.bind(this);
    this.reorderSwitchDisableCallBack = this.reorderSwitchDisableCallBack.bind(this);
  }

  componentDidMount() {
    this.loadInstructionNotes();
    this.loadInstructionTemplates();
  }

  loadInstructionNotes(){
    this.props.startLoading()
    const params = $.param({
      api_action: `/instruction_notes`,
      campaign_id: this.props.campaign_id
    });
    $.get(this.props.proxyUrl + '?' + params).then((results) => {
      this.props.initialize('instructionNotes', results)
      this.props.stopLoading()
    });
  }

  loadInstructionTemplates() {
    this.props.remoteLoading(true)
    const params = $.param({
      campaign_id: this.props.campaign_id
    });
    $.get('/admin/communities/instruction_notes.json?' + params).then((results) => {
      this.props.setNoteTemplates(typeof(results) === "object" ? results : [])
      this.props.remoteLoading(false)
    });
  }

  templateCallback(note) {
    const { campaign_id } = this.props

    Remote().request({
      url: `/admin/communities/instruction_notes/${note.id}/clone?campaign_id=${campaign_id}`,
      method: 'POST',
      contentType: 'application/json'
    }).then(response => {
      this.props.addNote(response.data);
    }).catch(error => {
      this.props.openNotification('Failed to create from template', 'failure', JSON.stringify(error));
      setTimeout(this.props.closeNotification, 5000);
    });
  }

  buttons() {
    return [
      <CampaignsRequirementsInstructionNotesButton {...this.props} />,
      <CampaignsRequirementsAddFromTemplatesButton
        selectCallback={this.templateCallback}
        campaign_id={this.props.campaign_id}
        templateType='instruction_note'
        loadingTemplates={this.props.loadingTemplates} />
    ]
  }

  updateInstructionNotesSort() {
    const params = 'api_action=/instruction_notes/order'

    const data = {
      instruction_notes_ids: this.props.instructionNotes.map((instruction) => { return instruction.id }),
      instruction_notes_order: this.props.instructionNotes.map((instruction) => { return instruction.order })
    };

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'POST',
      data: data
    }).then((response) => {
      this.props.openNotification(
        'Successfully reordered instruction notes.',
        'success',
        ''
      )
    }).catch((error) => {
      this.props.openNotification(
        'Failed to reorder instruction notes.',
        'failure',
        error.response.data.responseJSON
      )
    })
    setTimeout(this.props.closeNotification, 5000)
  }

  onDragStart(index) {
    this.setState({ dragIndex: index });
  }

  onDragOver(index) {
    this.setState({ dragOverIndex: index });
  }

  onDragEnd() {
    const { dragIndex, dragOverIndex } = this.state;
    const { updateInstructionSort } = this.props;

    if (dragIndex !== null && dragOverIndex !== null) {
      const promise = () => new Promise((resolve) => {
        resolve(updateInstructionSort(dragIndex, dragOverIndex));
      });

      promise().then(() => {
        this.updateInstructionNotesSort();
        this.setState({
          sorted: true,
          dragIndex: null,
          dragOverIndex: null
        });
      });
    }
  }

  reorderSwitchCallBack() {
    this.setState({ draggingEnable: !this.state.draggingEnable })
  }

  reorderSwitchDisableCallBack(callback) {
    this.setState({ disableReorderSwitch: callback })
  }

  render(){
    let noteCount = this.props.instructionNotes ? this.props.instructionNotes.length : 0;
    if(this.props.loading){
      return( <LoadingSpinner /> )
    } else if (noteCount) {
      return (
        <div className='instruction_notes_container'>
          <div className='instruction_notes_title'>
            <CampaignsRequirementsInstructionNotesReorderSwitch {...this.props}
              reorderButtonToggle={this.reorderSwitchCallBack}
              enableReorderSwitch={this.state.draggingEnable}
              disableReorderSwitch={this.state.disableReorderSwitch}/>
          </div>
          {
            this.props.instructionNotes.map((requirement, index) =>
              <CampaignsRequirementsCard
                key={index}
                {...this.props}
                index={index}
                draggable={true}
                draggingEnable={this.state.draggingEnable}
                onDragStart={this.onDragStart}
                onDragEnd={this.onDragEnd}
                onDragOver={this.onDragOver}
                draggedOver={this.state.dragOverIndex === index}
                dragOverIndex={this.state.dragOverIndex}
                reorderSwitchDisable={this.reorderSwitchDisableCallBack}
                requirement={requirement}
              />
            )
          }

          <div className='display_buttons'>
            { this.buttons() }
          </div>
        </div>
      );
    } else {
      return (
        <CampaignsRequirementsInitialDisplay {...this.props}>
          { this.buttons() }
        </CampaignsRequirementsInitialDisplay>
      )
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      addNote: note => dispatch({ type: 'ADD_INSTRUCTION_NOTES', data: note }),
      setNoteTemplates: (templates) => dispatch({ type: 'UPDATE_META', value: {instructionTemplates: templates} }),
      remoteLoading: (bool) => dispatch({type: 'UPDATE_META', value: {loadingTemplates: bool}}),
      openNotification: (saveText, saveType, saveError) => dispatch({type: 'OPEN_NOTIFICATION', value: {saveText, saveType, saveError}}),
      closeNotification: data => dispatch({ type: 'CLOSE_NOTIFICATION'}),
      updateInstructionSort: (from, to) => dispatch({
        type: 'UPDATE_INSTRUCTION_SORT',
        value: {
          from: from,
          to: to
        }
      }),
    };
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      ...state
    };
  }

}

CampaignsRequirementsInstructionNotesContainer.displayName = 'Campaigns.Requirements.InstructionNotesContainer';

export default connect(
CampaignsRequirementsInstructionNotesContainer.mapStateToProps,
CampaignsRequirementsInstructionNotesContainer.mapDispatchToProps
)(CampaignsRequirementsInstructionNotesContainer);
