import React from 'react';
import LoadingSpinner from './LoadingSpinner'
import Scheduler from './Scheduler'
import FacebookAdCampaignTable from './FacebookAdCampaignTable'

export default class PublishedWave extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      waveId: this.props.waveId,
      wave: null
    };


    this.loadWave = this.loadWave.bind(this);
    this.render = this.render.bind(this);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this)
  }

  componentWillMount() {
    this.loadWave(this.props.waveId);
  }

  componentWillReceiveProps(nextProps) {
    newWaveId = nextProps.waveId
    if (newWaveId !== this.props.waveId) {
      this.setState({loading: true, waveId: newWaveId})
      this.loadWave(newWaveId)
    }
  }

  render() {
    const wave = this.state.wave

    if(this.state.loading) {
      return <LoadingSpinner loading={true}/>
    }

    return (
      <div>
        <Scheduler campaignId={this.props.campaignId}
                               waveId={this.props.waveId}
                               editing={false}/>

        {
          wave.facebook_ad_campaigns.map((ad_campaign) =>
            <FacebookAdCampaignTable key={ad_campaign.id}
                                                 facebookAdCampaign={ad_campaign}
                                                 waveState={wave.state}/>
          )
        }
      </div>
    );
  }

  loadWave(waveId){
    $.get(`/admin/campaigns/${this.props.campaignId}/promoters_v2/api_proxy/waves?id=${waveId}`).then((data) => {
      this.setState(
        {
          wave: data,
          loading: false
        }
      );
    });
  }
}
PublishedWave.displayName = 'FacebookAds.PublishedWave';