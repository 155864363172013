import React from 'react';

export default class RoundBlogger extends React.Component {
  render(){
    const {activity, shoppertunity_name, round_name, user_name} = this.props.notification;
    const P = React.Fragment;
    switch(activity) {
      case 'declined':
        return (
          <P><b>{user_name}</b> declined instructions for the {shoppertunity_name} - <b>{round_name}</b></P>
        )
      case 'withdrawn':
        return (
          <P>
            <b>{user_name}</b> has been withdrawn from the <b>{round_name}</b>
            <div>
              Please notify the CA immediately if the opportunity round had a pre-payment that should be canceled.
            </div>
          </P>
        )
      default:
        return null;
    }
  }
}
RoundBlogger.displayName = 'Notification.Templates.RoundBlogger'