import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

class SubmissionAnnotationRow extends React.Component {
  render() {
    const { annotation } = this.props;
    const rightClass = `right ${annotation.done ? 'done' : ''}`;
    return <div className='submission annotation-row'>
      <div className='left'>
        <blockquote>{annotation.quote}</blockquote>
        <div className='comment'>
          {annotation.cleaned_text}
        </div>
      </div>
      <div className={rightClass} onClick={() => this.props.toggleAnnotationStatus(annotation.id)}>
        {
          annotation.done
          ? <i className='fas fa-undo'/>
          : <i className='fas fa-check'/>
        }
      </div>
    </div>;
  }

  static mapDispatchToProps(dispatch) {
    return {
      toggleAnnotationStatus: id => dispatch({ type: 'TOGGLE_ANNOTATION_DONE', value: id })
    };
  }
};

SubmissionAnnotationRow.displayName = 'Submission.AnnotationRow';
SubmissionAnnotationRow.propTypes = {
  annotation: PropTypes.any.isRequired
};

export default connect(
  null,
  SubmissionAnnotationRow.mapDispatchToProps
)(SubmissionAnnotationRow);
