import React from 'react';
export default class AutoCreateAnchorTags extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.replaceWithClickableLinks = this.replaceWithClickableLinks.bind(this);
  }

  replaceWithClickableLinks(text) {
    // Return early if we don't have valid text to replace
    if(_.isNull(text) || _.isUndefined(text)) {
      return text;
    }

    let exp_match1 = /(\b(https?|):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    let element_content = text.replace(exp_match1, "<a target='_blank' href='$1'>$1</a>");
    let exp_match2 =/(^|[^\/])(www\.[\S]+(\b|$))/gim;
    let new_content=element_content.replace(exp_match2, '$1<a target="_blank" href="http://$2">$2</a>');
    return new_content;
  }

  render() {
    return <div dangerouslySetInnerHTML={{__html: this.replaceWithClickableLinks(this.props.text)}}/>
  }
}

