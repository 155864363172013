import React from 'react';

class CommunityHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render(){
    const { logo_path,
            logo_title,
            current_community } = this.props;
    let community_theme_settings = current_community.community_theme_settings
    let community_theme_image = community_theme_settings ? community_theme_settings.theme_image : null;

    return (
      <header className="navbar navbar-default" id="back_to_top_anchor">
        <div className="container header--base-community">
          <div className="navbar-header">
            <div className="header--home-icons">
              {community_theme_image && community_theme_image.url !== null &&
                <div className="header--community-image">
                  <img src={community_theme_image.url} alt='custom_community_header' />
                </div>

              }
              { ( !community_theme_image || community_theme_image.url === null) &&
                <div className="header--home-logo navbar-brand">
                  <img src={logo_path} alt={logo_title} alt='social_fabric_header'/>
                </div>
              }
            </div>
          </div>
        </div>
        <hr></hr>
      </header>
    )
  }
}

CommunityHeader.displayName = 'Header.CommunityHeader';

export default CommunityHeader;