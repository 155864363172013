import React from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from '../../../LoadingSpinner';
import CampaignsRequirementsCard from '../Card';
import CampaignsRequirementsTaskContentRequirementButton from './TaskContentRequirementButton';
import CampaignsRequirementsAddFromTemplatesButton from '../AddFromTemplatesButton';
import CampaignsRequirementsDeletedRequirements from '../DeletedRequirements';
import CampaignsRequirementsInitialDisplay from '../InitialDisplay';

class CampaignsRequirementsTaskContentRequirementsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingTemplates: true
    }
    
    this.componentDidMount = this.componentDidMount.bind(this);
    this.loadCampaignContentTemplates = this.loadCampaignContentTemplates.bind(this);
    this.loadContentTypes = this.loadContentTypes.bind(this);
    this.loadGeneralTemplates = this.loadGeneralTemplates.bind(this);
    this.loadRoundTasks = this.loadRoundTasks.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    this.props.setEditingType(this.props.type);
    this.loadRoundTasks();
    this.loadContentTypes();
    this.loadCampaignContentTemplates();
    this.loadGeneralTemplates();
  }

  loadRoundTasks(){
    this.props.startLoading()
    const params = $.param({
      api_action: `/round_tasks`,
      campaign_id: this.props.campaign_id
    });
    $.get(this.props.proxyUrl + '?' + params).then((data) => {
      this.props.setTasks(data);
      this.props.stopLoading()
    });
  }

  loadContentTypes() {
    const params = $.param({
      api_action: '/content_types',
    });
    $.get(this.props.proxyUrl + '?' + params).then((data) => {
      this.props.setContentTypes(data);
    });
  }

  loadCampaignContentTemplates() {
    const params = $.param({
      api_action: `/round_tasks`,
      community_id: this.props.communityIds,
      template_type: 'community'
    });
    $.get(this.props.proxyUrl + '?' + params).then((data) => {
      this.props.setCampaignTemplates(data.active);
      this.setState({ loadingTemplates: false });
    });
  }

  loadGeneralTemplates() {
    const params = $.param({
      api_action: `/round_tasks`,
      community_id: this.props.communityIds,
      template_type: 'community_general'
    });
    $.get(this.props.proxyUrl + '?' + params).then((data) => {
      this.props.setGeneralTemplates(data.active);
    });
  }

  render(){
    let requirementCount = this.props.taskContentRequirements ? this.props.taskContentRequirements.length : 0;
    if (requirementCount) {
      return (
        <div className='task_requirement_container' >
          {
            this.props.taskContentRequirements.map((requirement, index) =>
              <CampaignsRequirementsCard
                key={index}
                {...this.props}
                requirement={requirement}
              />
            )
          }

          <div className='display_buttons add_content_task_buttons'>
            <CampaignsRequirementsTaskContentRequirementButton {...this.props} />
            <CampaignsRequirementsAddFromTemplatesButton proxyUrl={this.props.proxyUrl}
                                                           campaign_id={this.props.campaign_id}
                                                           templateType='campaign'
                                                           loadingTemplates={this.state.loadingTemplates} />
          </div>
          <CampaignsRequirementsDeletedRequirements requirements={this.props.deletedTaskContentRequirements}/>
          {this.props.loading && <LoadingSpinner/>}
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <CampaignsRequirementsInitialDisplay {...this.props} loadingTemplates={this.state.loadingTemplates}/>
          {this.props.loading && <LoadingSpinner/>}
        </React.Fragment>
      )
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      setCampaignTemplates: (templates) => dispatch({ type: 'SET_COMMUNITY_TEMPLATES', value: templates }),
      setGeneralTemplates: (templates) => dispatch({ type: 'SET_GENERAL_TEMPLATES', value: templates }),
      setEditingType: type => dispatch({ type: 'SET_EDITING_TYPE', value: type })
    };
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      communityTemplates: state.communityTemplates
    };
  }
}

CampaignsRequirementsTaskContentRequirementsContainer.displayName = 'Campaigns.Requirements.TaskContentRequirementsContainer';
export default connect(
  CampaignsRequirementsTaskContentRequirementsContainer.mapStateToProps,
  CampaignsRequirementsTaskContentRequirementsContainer.mapDispatchToProps
)(CampaignsRequirementsTaskContentRequirementsContainer);
