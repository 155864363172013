class window.ValidateNumber

  class this.PositiveField
    constructor: (@submit_button, @fields) ->

    bind_values: ->
      that = this

      # ensure that all fields pass validation
      check_button = ->
        all_pass = true
        for field in that.fields
          result = isPositive( $("#{field}").val() )

          all_pass = false unless result


        if all_pass
          $(that.submit_button).attr("disabled", false)

      # watch an individual field and call check_button after it changes
      watch = (field) ->
        $("#{field}").on 'change', (e) ->
          parent = $("#{field}").closest(".form-group")
          parent.removeClass('alert-error')
          result = isPositive( $("#{field}").val() )
          unless result
            parent.addClass('alert-error')
            $(that.submit_button).attr("disabled", "disabled")
          check_button()

      # watch each individual field passed in
      for field in that.fields
        watch(field)

    isPositive = ( number ) ->
      if number >= 0
        return true
      else
        return false
