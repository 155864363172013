import React from 'react';
import PropTypes from 'prop-types'
import { Remote } from '../../utils';

import RoundsValidation from '../../rounds/Validation'
import RoundTasksTitleInput from '../../round_tasks/TitleInput'
import CampaignsRequirementsGeneralRequirementInputs from './general_requirements/GeneralTaskRequirementInputs'
import CampaignsRequirementsTaskRequirementInputs from './task_content_requirements/TaskRequirementInputs'
import CampaignsRequirementsInstructionNotes from './instruction_notes/InstructionNotes'

export default class CampaignsRequirementsCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      dragging: false,
      dragPosition: null,
      height: null
    };

    this.componentRef = React.createRef();

    this.addRequirement = this.addRequirement.bind(this);
    this.cardLabel = this.cardLabel.bind(this);
    this.cloneTask = this.cloneTask.bind(this);
    this.deleteRequirement = this.deleteRequirement.bind(this);
    this.deleteRequirementById = this.deleteRequirementById.bind(this);
    this.dragEnd = this.dragEnd.bind(this);
    this.dragOver = this.dragOver.bind(this);
    this.dragStart = this.dragStart.bind(this);
    this.editField = this.editField.bind(this);
    this.removeCard = this.removeCard.bind(this);
    this.render = this.render.bind(this);
    this.requirementParams = this.requirementParams.bind(this);
    this.setRequirementSort = this.setRequirementSort.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.updateLabel = this.updateLabel.bind(this);
    this.updateRequirement = this.updateRequirement.bind(this);
    this.updateRequirementField = this.updateRequirementField.bind(this);
    this.updateRequirementState = this.updateRequirementState.bind(this);
    this.updateRoundTask = this.updateRoundTask.bind(this);
    this.updateRequirement = _.debounce(this.updateRequirement.bind(this), 500);
    this.updateCampaignInstructionNotes = _.debounce(this.updateCampaignInstructionNotes.bind(this), 500);
    this.updateRoundTask = _.debounce(this.updateRoundTask.bind(this), 500);
    this.isDraggable = this.isDraggable.bind(this);
    this.setHeight = this.setHeight.bind(this);
    this.placeholderClassType = this.placeholderClassType.bind(this);
  }

  componentDidMount() {
    this.setHeight();
  }

  toggleOpen(){
    this.setState({
      open: !this.state.open
    })

    if (this.props.type == 'instruction_notes')
      this.props.reorderSwitchDisable(!this.state.open);
  }

  setHeight() {
    this.setState({ height: this.componentRef.current.getBoundingClientRect().height })
  }

  editField(field, value) {
    let updatePromise;
    switch(this.props.type){
      case 'general_requirement':
      case 'task_content_requirement':
        updatePromise = () => new Promise((resolve, reject) => {
          const requirement = this.props.requirement
          resolve(this.props.updateTaskField(field, value, requirement.id, requirement.type));
        })
        updatePromise().then(() => {
          this.updateRoundTask()
        })
        break;
      case 'instruction_notes':
        let requirement = {
          ...this.props.requirement,
          [field]: value };
        updatePromise = () => new Promise((resolve, reject) => {
          resolve(this.props.editInstructionNotes(requirement));
        })
        updatePromise().then(() => {
          this.updateCampaignInstructionNotes(requirement);
        })
        break;
    }
  }

  updateRoundTask() {
    const params = $.param({
      api_action: `/round_tasks/${this.props.requirement.id}`
    });

    // Syndication behaves strangely because it is a nullable boolean attached to a select field, so
    // this logic is to ensure that we actually null it out.
    let syndication = this.props.requirement.syndication
    if (Array.isArray(syndication)) {
      syndication = _.flatten([this.props.requirement.syndication]).map(x => x && x.value)[0]
      if(syndication === undefined) {
        syndication = null;
      }
    }

    const task_params = {
      ...this.props.requirement,

      // Remove these to make the payload smaller
      round_task_requirements:[],
      round_task_requirements_attributes: [], // No need to send these -- they get updated independently
      submission_type: [],
      payment_types: [],
      validations: [],
      repeat_types: [],
      verification_types: [],

      // These fields need to be transformed from arrays to a single value
      // The funky _.flatten is to make sure we end up with a value even if it's null
      template_id: _.flatten([this.props.requirement.template_id]).map(x => x && x.id)[0],
      syndication: syndication,
      payment_type: _.flatten([this.props.requirement.payment_type]).map(x => x && x.id)[0]
    };

    Remote().request({
      method: 'PATCH',
      url: this.props.proxyUrl + '?' + params,
      data: task_params,
    }).then((response) => {
      this.props.openNotification(
        'Successfully saved Task Content Requirement',
        'success',
        '')
      this.props.updateTaskField('validations', {}, this.props.requirement.id, this.props.requirement.type);
      this.props.updateTaskField('human_validations', {}, this.props.requirement.id, this.props.requirement.type);
      this.props.updateRound && Object.keys(response.data.round).forEach(key => {
        this.props.updateRound(key, response.data.round[key]);
      });
      setTimeout(this.props.closeNotification, 5000)
    }).catch((error) => {
      const result = JSON.parse(error);
      this.props.openNotification(
        'Failed to edit Task Content Requirement! Please correct these errors:',
        'failure',
        _.get(result, 'human_validations', [])
      )

      if(result.validations) {
        this.props.updateTaskField('validations', result.validations, this.props.requirement.id, this.props.requirement.type);
        this.props.updateTaskField('human_validations', result.human_validations, this.props.requirement.id, this.props.requirement.type);
      }
      setTimeout(this.props.closeNotification, 5000)
    });
  }

  updateLabel(value){
    switch(this.props.type){
      case 'general_requirement':
      case 'task_content_requirement':
        this.editField('name', value)
        break;
      case 'instruction_notes':
        this.editField('label', value)
        break;
    }
  }

  cloneTask() {
    let requirement = this.props.requirement

    let endpoint = '';
    switch(this.props.type) {
    case 'general_requirement':
      endpoint = `/round_tasks/${this.props.requirement.id}/clone/campaign_general/${this.props.campaign_id}`;
      break;
    case 'task_content_requirement':
      switch(this.props.requirement.template_type) {
      case 'instance':
        endpoint = `/round_tasks/${this.props.requirement.id}/clone/round/${this.props.requirement.round_id}`;
        break;
      case 'campaign':
      default:
        endpoint = `/round_tasks/${this.props.requirement.id}/clone/campaign/${this.props.campaign_id}`;
      }
      break;
    case 'instruction_notes':
      endpoint = `/instruction_notes/${requirement.id}/clone/campaign/${this.props.campaign_id}`
      break;
    }

    const params = $.param({
      api_action: endpoint
    });

    Remote().request({
      method: 'POST',
      url: this.props.proxyUrl + '?' + params,

    }).then((response) => {
    switch(this.props.type){
      case 'general_requirement':
        this.props.addTask(response.data);
        break;
      case 'task_content_requirement':
        this.props.addTask(response.data);
        break;
      case 'instruction_notes':
        this.props.addInstructionNotes(response.data);
        break;  }
    }).catch((error) => {
      this.props.openNotification(
        'Failed to clone card! Please try again.',
        'failure',
        error
      )
      setTimeout(this.props.closeNotification, 5000)
    });
  }

  removeCard() {
    let requirement = this.props.requirement

    let endpoint = ''
    switch(this.props.type){
      case 'general_requirement':
      case 'task_content_requirement':
        endpoint = `/round_tasks/${requirement.id}`
        break;
      case 'instruction_notes':
        endpoint = `/instruction_notes/${requirement.id}`
        break;
      }

    const params = $.param({
      api_action: endpoint
    });

    Remote().request({
      method: 'DELETE',
      url: this.props.proxyUrl + '?' + params,

    }).then((response) => {
      switch(this.props.type){
        case 'general_requirement':
          this.props.removeGeneralRequirement(requirement.id)
          break;
        case 'task_content_requirement':
          this.props.removeTaskContentRequirement(requirement.id)
          break;
        case 'instruction_notes':
          this.props.removeInstructionNotes(requirement.id)
          break;
        }
      this.props.openNotification(
        'Successfully deleted card',
        'success',
        '')
        setTimeout(this.props.closeNotification, 5000)
    }).catch((error) => {
      this.props.openNotification(
        'Failed to delete card! Please correct these errors:',
        'failure',
        error
      )
      setTimeout(this.props.closeNotification, 5000)
    });
  }

  updateCampaignInstructionNotes(requirement){
    const params = $.param({
      api_action: `/instruction_notes/${requirement.id}`
    });

    Remote().request({
      method: 'PATCH',
      url: this.props.proxyUrl + '?' + params,
      data: {note: requirement },
    }).then((response) => {
      this.props.openNotification(
        'Successfully saved instructions notes.',
        'success'
      )
      setTimeout(this.props.closeNotification, 5000)
    }).catch((error) => {
      this.props.openNotification(
        'Failed to save instructions notes! Please correct these errors:',
        'failure',
        error
      )
      setTimeout(this.props.closeNotification, 5000)
    });
  }

  updateRequirement(index){
    const taskId = this.props.requirement.id;
    const requirement = this.props.requirement.round_task_requirements[index]
    const requirementParams = this.requirementParams(requirement)
    const params = $.param({ api_action: `/round_task_requirements/${requirement.id}` });

    Remote().request({
      method: 'PATCH',
      url: this.props.proxyUrl + '?' + params,
      data: requirementParams,
    }).then((response) => {
      if (response.data) {
        const type = response.data.round_task.type
        this.props.updateRequirementField(taskId, 'validations', {}, index, type);
        this.props.updateRequirementField(taskId, 'human_validations', {}, index, type);
        this.props.updateRequirementField(taskId, 'id', response.data.id || requirement.id, index, type);

        this.props.openNotification(
          'Successfully saved requirement change',
          'success'
        )
        setTimeout(this.props.closeNotification, 5000)
      }
    }).catch((error) => {
      const data = JSON.parse(error);
      if(data) {
        const type = data.round_task.type
        this.props.updateRequirementField(taskId, 'validations', data.validations, index, type);
        this.props.updateRequirementField(taskId, 'human_validations', data.human_validations, index, type);
        this.props.updateRequirementField(taskId, 'id', data.id || requirement.id, index, type);

        this.props.openNotification(
          'Failed to save requirement change! Please try again:',
          'failure',
          data.responseJSON
        )
        setTimeout(this.props.closeNotification, 5000)
      }
    });
  }

  requirementParams(requirement) {
    let verification_type = _.flatten([requirement.verification_type]).map(x => x.name && x.name.toLowerCase())[0]
    return {
      ...requirement,

      // Format these for the server
      verification_type: verification_type || 'manual',
      repeat_type: _.flatten([requirement.repeat_type]).map(x => x && x.id)[0],
      repeat_count: verification_type == 'manual' ? null : (requirement.repeat_count || 0)
    };
  }

  addRequirement(requirement = {}) {
    const taskId = this.props.requirement.id;
    const params = $.param({
      api_action: `/round_task_requirements`,
    });

    const payload = {
      ...requirement,
      round_task_id: taskId,
      verification_type: 1
    };

    return Remote().request({
      method: 'POST',
      url: this.props.proxyUrl + '?' + params,
      data: payload,
    }).then((response) => {
      if (response.data) {
        this.props.addRequirement(taskId, response.data, response.data.round_task.type);
      }
    })
  }

  deleteRequirementById(requirementId) {
    const taskId = this.props.requirement.id;
    const params = $.param({ api_action: `/round_task_requirements/${requirementId}`});

    return Remote().request({
      method: 'DELETE',
      url: this.props.proxyUrl + '?' + params,
    }).then((response) => {
      if (response.data) {
        this.props.deleteRequirementById(taskId, requirementId, response.data.round_task.type);
      }
    });
  }

  deleteRequirement(index) {
    const taskId = this.props.requirement.id;
    const requirementId = this.props.requirement.round_task_requirements[index].id;
    const params = $.param({ api_action: `/round_task_requirements/${requirementId}`});

    return Remote().request({
      method: 'DELETE',
      url: this.props.proxyUrl + '?' + params,

    }).then((response) => {
      if (response.data) {
        this.props.deleteRequirement(taskId, index, response.data.round_task.type);
      }
    })
  }

  updateRequirementField(field, value, index) {
    const task = this.props.requirement;

    const updatePromise = () => new Promise((resolve) => {
      resolve(this.props.updateRequirementField(task.id, field, value, index, task.type));
    })

    updatePromise().then(() => {
      this.updateRequirement(index)
    })
  }

  updateRequirementState(reduxCallback, index) {
    const task = this.props.requirement;

    const updatePromise = () => new Promise((resolve) => {
      resolve(reduxCallback(task.id, index, task.type));
    })

    updatePromise().then(() => {
      this.updateRequirement(index)
    })
  }

  setRequirementSort() {
    const params = $.param({ api_action: `/round_task_requirements/sort`});

    const data = {
      round_task_requirement_ids: this.props.requirement.round_task_requirements.map((req) => { return req.id })
    };

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'POST',
      data: data,
    }).then(response => {
      this.props.openNotification(
        'Successfully saved round tasks order.',
        'success',
        ''
      )
    }).catch(error => {
      this.props.openNotification(
        'Failed to saved round tasks order.',
        'failure',
        error
      )
    });
    setTimeout(this.props.closeNotification, 5000)
  }

  cardLabel(requirement){
    let label
    switch(this.props.type){
      case 'general_requirement':
      case 'task_content_requirement':
        label = requirement.name
        break;
      default:
        label = requirement.label
    }
    return label || ''
  }

  dragStart(e) {
    if (!this.state.open && this.props.draggable) {
      this.setState({ dragging: true })
      this.props.onDragStart(this.props.index)
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/html', e.currentTarget);
    }
  }

  dragOver(e) {
    if (!this.state.open && this.props.draggable) {
      e.preventDefault();
      const bounding = e.target.getBoundingClientRect()
      const offset = bounding.y + (bounding.height/2);
      const position = (e.clientY - offset >= 0) ? 'above' : 'below';

      if (position !== !this.state.dragPosition) {
        this.setState({ 
          dragPosition: position,
          height: bounding.height
        });
      }

      switch(this.props.draggedOver) {
        case true:
          e.stopPropagation();
          if (position === 'above')
            this.props.onDragOver(this.props.index - 1)
          else if (position === 'below')
            this.props.onDragOver(this.props.index + 1)
          break;
        case false:
          this.props.onDragOver(this.props.index)
          break;
      }
    }
  }

  dragEnd(e) {
    if (!this.state.open && this.props.draggable) {
      e.preventDefault();
      this.setState({ dragging: false })
      this.props.onDragEnd();
    }
  }

  isDraggable(type) {
    switch(type) {
      case 'instruction_notes':
        return !this.state.open && this.props.draggable && this.props.draggingEnable;
      default:
        return !this.state.open && this.props.draggable;
    }
  }

  placeholderClassType(type) {
    switch(type) {
      case 'instruction_notes':
        return 'instruction-note';
      default:
        return '';
    }
  }

  render(){
    const { open, dragging, dragPosition } = this.state;
    const { requirement, instructionApproved, shopPosted, proxyUrl, contentTypes, type, index, draggedOver, dragOverIndex, draggingEnable } = this.props;
    const disabled = instructionApproved || shopPosted ? 'disabled' : '';
    const editIconClass = open ? 'fa-check-square' : 'fa-edit';
    const draggableClass = this.isDraggable(type) ? 'draggable' : '';
    const draggingClass = dragging ? 'dragging' : '';
    const draggedOverClass = draggedOver ? 'dragged-over' : '';
    const hideDraggingClass = dragging && dragOverIndex !== null ? 'hide-dragging' : ''
    const placeholderClass = this.placeholderClassType(type);

    return (
      <React.Fragment>
        { (draggedOver && dragPosition === 'above') && <div className={`placeholder-card ${placeholderClass}`}></div> }
        <div className={`requirement_card_wrapper ${draggableClass} ${draggingClass} ${draggedOverClass} ${hideDraggingClass}`}
          id={index}
          draggable={this.isDraggable(type)}
          onDragStart={(e) => this.dragStart(e)}
          onDragEnd={(e) => this.dragEnd(e)}
          onDragOver={(e) => this.dragOver(e)}
          ref={this.componentRef}>
          <div className='card_header col-md-12'>
            <div className='validation_section'>
              <RoundsValidation field='name' validations={requirement.validations} />
              <RoundsValidation field='label' validations={requirement.validations} />
            </div>
            <div className='requirement_card_label'>
              <RoundTasksTitleInput type={type} value={this.cardLabel(requirement)} open={open} onChange={this.updateLabel}/>
            </div>
            { !draggingEnable &&
              <div className='requirement_card_icons'>
                <i className={`far ${editIconClass}`} title="Edit Card" onClick={this.toggleOpen} />
                <i className={`far fa-copy ${disabled}`} disabled={disabled} title="Copy Card" onClick={this.cloneTask} />
                <i className={`fas fa-trash ${disabled}`} disabled={disabled} title="Delete Card" onClick={this.removeCard} />
              </div>
            }
            { draggingEnable &&
              <div className='requirement_card_icons_reorder'>
                <i className={'fas fa-bars'} title="Drag Card"/>
              </div>
            }
          </div>
          {requirement.type == "campaign_general" &&
            <CampaignsRequirementsGeneralRequirementInputs
              {...this.props}
              isOpen={open}
              contentTypes={contentTypes}
              proxyUrl={proxyUrl}
              editField={this.editField}
              updateRoundTask={this.editField}
              addRequirement={this.addRequirement}
              deleteRequirement={this.deleteRequirement}
              deleteRequirementById={this.deleteRequirementById}
              updateRequirementField={this.updateRequirementField}
              updateRequirementState={this.updateRequirementState}
              setRequirementSort={this.setRequirementSort}/>
          }
          {(requirement.type == 'campaign' || requirement.type == 'instance') &&
            <CampaignsRequirementsTaskRequirementInputs
              {...this.props}
              isOpen={open}
              contentTypes={contentTypes}
              proxyUrl={proxyUrl}
              editField={this.editField}
              updateRoundTask={this.editField}
              addRequirement={this.addRequirement}
              deleteRequirement={this.deleteRequirement}
              deleteRequirementById={this.deleteRequirementById}
              updateRequirementField={this.updateRequirementField}
              updateRequirementState={this.updateRequirementState}
              setRequirementSort={this.setRequirementSort}
              payment={this.props.payment}
              />
          }
          {!draggingEnable && requirement.type == "instruction_notes" &&
            <CampaignsRequirementsInstructionNotes
              {...this.props}
              isOpen={open}
              updateCampaignInstructionNotes={this.updateCampaignInstructionNotes}
            />
          }
        </div>
        { (draggedOver && dragPosition === 'below') && <div className={`placeholder-card ${placeholderClass}`}></div> }
      </React.Fragment>
    );
  }
}

CampaignsRequirementsCard.displayName = 'Campaigns.Requirements.Card';

CampaignsRequirementsCard.propTypes = {
  index: PropTypes.number,
  type: PropTypes.string.isRequired,
  requirement: PropTypes.any.isRequired,
  instructionApproved: PropTypes.bool,
  shopPosted: PropTypes.bool,
  contentTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    display_name: PropTypes.string
  })).isRequired,
  templateTypes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string
    }))
  ]).isRequired,
  updateTaskField: PropTypes.func.isRequired,
  updateRound: PropTypes.func,
  proxyUrl: PropTypes.string.isRequired,
  addRequirement: PropTypes.func.isRequired,
  updateRequirementField: PropTypes.func.isRequired,
  deleteRequirement: PropTypes.func.isRequired,
  removeTaskContentRequirement: PropTypes.func.isRequired,
  addTask: PropTypes.func.isRequired,
  openNotification: PropTypes.func.isRequired,
  closeNotification: PropTypes.func.isRequired,
  displayPage: PropTypes.string,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDragOver: PropTypes.func,
  draggedOver: PropTypes.bool,
  dragOverIndex: PropTypes.number,
  draggable: PropTypes.bool,
  palette: PropTypes.any,
  payment: PropTypes.bool
};
