import React from 'react'
import { Remote } from './utils';
import { debounce } from 'lodash';

export default class InstagramOembed extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      oembed_html: props.oembed_html || null,
      loading: false,
      error: null
    }

    this.loadEmbed = this.loadEmbed.bind(this);
    this.fetchContent = this.fetchContent.bind(this);
    this.formatData = this.formatData.bind(this);
  }

  componentDidMount(){
    if (!this.state.oembed_html) {
      this.fetchContent();
    } else {
      this.loadEmbed();
    }
  }

  componentDidUpdate(){
    if (this.state.oembed_html){
      this.loadEmbed();
    }
  }

  loadEmbed() {
    const { oembed_html } = this.state;
    if ( !!oembed_html ) {
      const ig_script = document.createElement('script');
      ig_script.src="//www.instagram.com/embed.js"
      ig_script.onload = () => {
        try {
          instgrm.Embeds.process();
        } catch (error) {
          console.log(error)
        }
      }
      document.head.appendChild(ig_script)
    }
  }

  fetchContent(){
    const params = $.param({
      api_action: '/auxillary_contents/fetch_instagram_preview',
      url: this.props.url
    });

    Remote('multipart/form-data').request({
      method: 'GET',
      url: this.props.proxyUrl + '?' + params,
    }).then(response => {
      this.setState({oembed_html: response.data['html']}, () => {
        this.loadEmbed();
        if(this.props.setOembedData) {
          this.props.setOembedData(this.formatData(response.data))
        }
      })
    });
  }

  formatData(data){
    return {
      username: data['author_name'],
      oembed_html: data['html'],
      image_url: data['thumbnail_url'],
    }
  }

  render() {
    const {oembed_html} = this.state;

    return (
      <div className='instagram-content-embed' dangerouslySetInnerHTML={{__html: oembed_html }} />
    )
  }
}
InstagramOembed.displayName = 'InstagramOembed';
