import React from 'react';
import { Provider } from 'react-redux'
import BasicWrapperStore from '../redux/shared/BasicWrapperStore';
import Container from './Container'

export default class Wrapper extends React.Component {
  render() {
    return (
      <Provider store={BasicWrapperStore}>
        <Container {...this.props} />
      </Provider>
    );
  }
};

Wrapper.displayName = 'UserForm.Wrapper';