import React from 'react';

export default class BlogPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    }
    this.save = this.save.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.url && this.props.image_url !== nextProps.image_url) {
      this.setState({ ...nextProps });
    }
  }
  saveVal(type, value) {
    if (!!value && !isNaN(parseInt(value))) {
      this.setState({ [type]: parseInt(value) })
    }
  }

  save() {
    const {shares, description, title, image_url, comments, views} = this.state;
    const {setValue, onSave} = this.props;
    setValue('contentMeta', {shares, description, title, image_url, comments, views}, onSave);
  }
  render() {
    const {title, description, shares, views, comments, image_url, flexStyles, url} = this.state;
    const imageStyles = {
      width: '100%',
      height: 'auto',
      margin: 'auto 0px',
      padding: '5px',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)'
    };
    const flexCenter = flexStyles();
    return (
      <div style={{ width: '100%', paddingBottom: 15, boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)' }}>
        <div className="container-fluid">
          <div className="row" style={{...flexCenter, padding: 5}}>
            <div className="col-md-12"><h3>Post Details</h3><hr/></div>
            <div className="col-md-3">
              <a href={url} target="_blank">
                <img src={image_url} alt="post thumbnail" style={imageStyles}/>
              </a>
            </div>
            <div className="col-md-9">
              <p><b>Title:</b>{title}</p>
              <p><b>Description:</b>{description}</p>
              <div className="form-group">
                <label htmlFor="views">Views</label>
                <input onChange={(event) => {this.saveVal('views', event.target.value)}} value={views || 0} id="views" type="number" value={views} className="form-control"/>
              </div>
              <div className="form-group">
                <label htmlFor="comments">Comments</label>
                <input onChange={(event) => {this.saveVal('comments', event.target.value)}} value={comments || 0} id="comments" type="number" className="form-control"/>
              </div>
              <div className="form-group">
                <label htmlFor="shares">Shares</label>
                <input onChange={(event) => {this.saveVal('shares', event.target.value)}} value={shares || 0} id="shares" type="number" className="form-control"/>
              </div>
            </div>
            <div className="col-md-12 text-right">
              <hr />
              <button className="btn btn-primary" onClick={this.save}>Save</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
BlogPost.displayName = 'AuxillaryContent.BlogPost';
