import React from 'react';
var WillPaginate = createReactClass({

  prev_page: function(){
    var current_page = parseInt(this.props.pagination.current_page);
    var page_count   = parseInt(this.props.pagination.page_count);
    current_page > 1 ? this.props.goToPage(current_page - 1) : null;
    $('body').animate({scrollTop:100},'normal');
  },

  next_page: function(){
    var current_page = parseInt(this.props.pagination.current_page);
    var page_count   = parseInt(this.props.pagination.page_count);
    page_count > current_page ? this.props.goToPage( current_page + 1) : null;
    $('body').animate({scrollTop:100},'normal');
  },

  render: function(){
    return (
      <div className="pagination clear">
        <ul className="pagination">
          <li className="prev previous_page">
            <button rel="prev start" onClick={this.prev_page}>
              <span className="sr-only">Previous</span>
              <span className="arrow">&laquo;</span>
            </button>
          </li>
          <li className="next next_page">
            <button rel='next start' onClick={this.next_page}>
              <span className="sr-only">Next</span>
               <span className="arrow">&raquo;</span>
            </button>
          </li>
        </ul>
      </div>
    );
  }
});

