import React from 'react';
class DashboardTable extends React.Component {
  constructor(props) {
    super(props)
  }

  editLink(user) {
    return (
      <a className='btn btn-primary background-color-faded-blue' title='Edit User' href={`/admin/users/${user.id}/edit`}>
        <i className='fas fa-pencil-alt'/>
      </a>
    )
  }

  deleteLink(user) {
    return (
      <a className='btn btn-danger background-color-faded-red' title='Delete User' href={`/admin/users/${user.id}/update/disable`}>
        <i className='fas fa-trash'/>
      </a>
    )
  }

  userRows() {
    const { dashboard, actionLinks } = this.props
    const { users } = dashboard;

    if (!users)
      return <span>No users founds.</span>

    return users.map(user =>
      <tr key={user.id}>
        <td>{user.full_name}</td>
        <td>{user.email}</td>
        <td>{user.roles}</td>
        <td>{user.created}</td>
        <td>{user.last_visit}</td>
        <td>
          { actionLinks.includes('edit') && this.editLink(user) }
          { actionLinks.includes('delete') && this.deleteLink(user) }
        </td>
      </tr>
    )
  }

  render() {
    const { dashboard, loading, loading_error } = this.props

    if ( !!loading_error)
      return (
        <div className='col-xs-12'>
          <span>{loading_error}</span>
        </div>
      )


    if (loading || !dashboard)
      return <div />

    return (
      <div className='col-xs-12'>
        <table className='table table-striped table-bordered table-condensed mt-10'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Roles</th>
              <th>Created</th>
              <th>Last Active</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.userRows() }
          </tbody>
        </table>
      </div>
    )
  }
}
DashboardTable.displayName = 'UserTable.DashboardTable';
export default DashboardTable