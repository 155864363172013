import React from 'react';
import { Provider } from "react-redux"
import BasicWrapperStore from '../redux/shared/BasicWrapperStore'
import InstructionNotesNew from './New'

export default class InstructionNotesNewWrapper extends React.Component {
  render() {
    return (
      <Provider store={BasicWrapperStore}>
        <InstructionNotesNew {...this.props}/>
      </Provider>
    );
  }
};
