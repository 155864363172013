import React from 'react';
import { connect } from 'react-redux';

class ConversationsMessageEntryFields extends React.Component {
  constructor(props) {
    super(props);

    this.fileRef = React.createRef();
    this.selectRef = React.createRef();

    this.render = this.render.bind(this);
    this.fileSelect = this.fileSelect.bind(this);
    this.removePreview = this.removePreview.bind(this);
  }

  fileSelect(event){
    const file = event.target.files[0];
    const media_mode = true;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {this.fileRef.current.src = e.target.result}
    this.props.setMessageFields({body: '', file: file, media_mode: media_mode});
  }

  removePreview(event){
    event.preventDefault();
    this.props.resetMessageState();
  }

  render() {
    const {message_loading, media_mode, body, file} = this.props;
    return(
      <div className='message-entry-field'>
        <div className={`message-text-container${media_mode && !!file ? ' hide-message-container': ''}`}>
          <div className='input-group'>
            <input type='text' contentEditable='true' placeholder='Message..'
                   className='form-control' value={body}
                   disabled={message_loading}
                   onChange={(e) =>
                    { this.props.setMessageFields({body: e.target.value, media_mode: false, file: null}) }
                   }
            />
            <span className='input-group-btn'>
                { !!body && <button className='btn primary' type='submit'>Send</button> }
              { !body &&
              <button className='btn primary'
                      type='button'
                      disabled={message_loading}
                      onClick={() => {this.selectRef.current.click()}}>
                <i className='fas fa-image'></i>
                <input id='file-input'
                       type='file'
                       disabled={message_loading}
                       accept='image/*'
                       style={{display: 'none'}}
                       ref={this.selectRef} onChange={this.fileSelect}
                />
              </button>
              }
              </span>
          </div>
        </div>
        <div className={`message-media-container${media_mode && !!file ? '': ' hide-message-container'}`}>
          <div className='cancel-control'>
            <a className='cancel-control-icon' href='#' onClick={this.removePreview}>
              <i className='fas fa-times'></i>
            </a>
            <img className='message-media' ref={this.fileRef} />
          </div>
          <button className='btn primary message-send-button' disabled={message_loading} type='submit'>Send</button>
        </div>
      </div>
    );
  }

  static mapStateToProps(state, myProps) {
    return {
      loading: state.loading,
      selected: state.selected,
      conversation: state.conversation,
      message_loading: state.message_loading,
      media_mode: state.media_mode,
      file: state.file,
      body: state.body,
      ...myProps
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      setMessageFields: data => dispatch({type: 'SET_MESSAGE_FIELDS', value: data}),
      resetMessageState: () => dispatch({type: 'RESET_MESSAGE_STATE'})
    };
  }
}

ConversationsMessageEntryFields.displayName = 'Conversations.MessageEntryFields';

export default connect(
  ConversationsMessageEntryFields.mapStateToProps,
  ConversationsMessageEntryFields.mapDispatchToProps
)(ConversationsMessageEntryFields);