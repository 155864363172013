import React from 'react';

export default class Note extends React.Component {
  render(){
    const {activity, link_text, shoppertunity_name, user_id, notification_user_id, user_name, note_title, notes} = this.props.notification;
    const P = React.Fragment;
    switch(activity) {
      case 'content_note_created':
        return (
          <P>
            <b>{user_name}</b>  created a new note on a <b>{link_text}</b> for <b>{shoppertunity_name}</b>
          </P>
        )
      case 'shoppertunity_note_created':
        return (
          <P>
            <b>{user_name}</b> created a new note on <b>{note_title}</b>
            <br />
            <div>{notes}</div>
          </P>
        )
      case 'user_application':
        return(
          <P>
            { notification_user_id === user_id && <b>You </b> }
            { notification_user_id !== user_id && <b>{user_name} </b> }
            added a note to <b>{note_title}</b>
          </P>
        )
    }
  }
}
Note.displayName = 'Notification.Templates.Note'