import React from 'react';
import ActionAlert from '../ActionAlert'

// TODO: Move this and its styles into a unified system alert
// See: https://collectivebias.atlassian.net/browse/SF-2918
export default class ShoppertunitiesIndexApplyAlert extends React.Component {
  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
    this.paymentAlert = this.paymentAlert.bind(this);
  }

  paymentAlert() {
    const { I18n, showPaymentAlert } = this.props;
    if(showPaymentAlert)
    {
      return <div>{I18n.alerts.complete_payment_profile}</div>
    }
    return <div>{I18n.alerts.complete_your_profile}</div>
  }

  render() {
    const { I18n, connectBlogsLink, connectInstagramLink, 
            showProfileAlert, showActionAlertIcon, showSuspensionAlert,
            showAnalyticsAlert, showConnectInstagramAlert,
            username, suspensionDaysRemaining } = this.props;
    const editProfileUrl = `/profile/${username}/edit`;
    return (
      <div>
        {
          showProfileAlert &&
          <div className="action-alert-area">
            <ActionAlert
              text={I18n.alerts.no_profile}
              type={showActionAlertIcon ? "error" : "info"}
              icon={showActionAlertIcon ? "warning" : "user-circle"}
              actionText={I18n.alerts.complete_profile}
              actionDestination={editProfileUrl}
            />
          </div>
        }
        {
          showAnalyticsAlert &&
          <div className="action-alert-area">
            <ActionAlert
              text={I18n.alerts.plugin_not_installed}
              type={showActionAlertIcon ? "error" : "info"}
              icon={showActionAlertIcon ? "warning" : "plug"}
              actionText={I18n.alerts.connect_blogs}
              actionDestination={connectBlogsLink}
            />
          </div>
        }
        {
          showConnectInstagramAlert &&
          <div className="action-alert-area">
            <ActionAlert
              text={I18n.alerts.connect_instagram_alert_action}
              type={showActionAlertIcon ? "error" : "info"}
              icon={showActionAlertIcon ? "warning" : "user-circle"}
              actionText={I18n.alerts.connect_instagram}
              actionDestination={connectInstagramLink}
            />
          </div>
        }
        {showSuspensionAlert && <div className="alert_area">
          <div className="row">
            <div className="col-sm-12">
              <i className="fa">
                <img src="/assets/ico-alert.png"/>
              </i>
              <div className="alert_text">
                <div>{I18n.alerts.suspended}</div>
                <div>You have {suspensionDaysRemaining} days remaining in your suspension.</div>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    );
  }
};

ShoppertunitiesIndexApplyAlert.displayName = 'Shoppertunities.IndexApplyAlert';
