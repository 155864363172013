import React from 'react';


class SubmissionContentModal extends  React.Component {

  constructor(props){
    super(props);
    this.state = {}
  }

  componentDidMount(){
    if(this.props.contentType == 'tiktok-video'){
      //runs the tiktok oembed js script after the blockquotes have been implemented on the page
      const script = document.createElement("script");
      script.async = true;
      script.src = this.props.tiktok_oembed_js;
      document.head.appendChild(script)
    }
  }

  render() {

    return (
      <div id='view-content-modal' className='modal fade in' style={{display: 'block'}}>
        <div className='modal-content modal-dialog'>
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" onClick={this.props.closeModal}>&times;</span>
            </button>
          </div>
          <div className="modal-body" ref={el => (this.div = el)}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

SubmissionContentModal.displayName = 'Submission.ContentModal';
export default SubmissionContentModal;
