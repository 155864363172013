import React from 'react';

export default class Campaign extends React.Component {
  render(){
    const {campaign_name, activity, id} = this.props.notification;
    const P = React.Fragment;
    if(activity ==='added'){
      return(
        <P>You have been added to the <b>{campaign_name}</b> campaign.</P>
      )
    }else{
      return(
        <P>You have been added to the <a href={`/notifications/${id}`}>{campaign_name}</a> campaign.</P>
      )
    }

  }
}
Campaign.displayName = 'Notification.Templates.Campaign'