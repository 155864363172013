import React from 'react';
import PropTypes from 'prop-types';
import MultiSelectChip from './MultiSelectChip'
export default class MultiSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.selected || [],
      focused: false,
      searchText: '',
      results: props.preload || [], // Preload all results
      cache: props.preload || [], // Result cache -- used to hold on to data for displaying in the result list
      queryCache: {}              // Query cache -- KV lookup to save potential api requests
    };

    this.addItem = this.addItem.bind(this);
    this.componentDidCatch = this.componentDidCatch.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    this.isObject = this.isObject.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.query = this.query.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.render = this.render.bind(this);
    this.renderResults = this.renderResults.bind(this);
    this.searchChanged = this.searchChanged.bind(this);
    this.limitReached = this.limitReached.bind(this);

    // debounced function wrapper
    this.debouncedQuery = _.debounce(this.query, 500);
  }

  componentDidMount() {
    this.query();
  }

  componentWillReceiveProps(nextProps) {
    const cache = this.state.cache || [];
    const preload = nextProps.preload || [];
    const selected = nextProps.selected || [];

    this.setState({
      cache: _.uniqBy(preload.concat(cache), x => x.value || x.id),
      selected: selected
    }, () => this.query());
  }

  limitReached(){
    return (this.state.selected.length >= (this.props.limit || undefined));
  }

  render() {
    const placeholder = (this.props.placeholder && this.state.selected.length === 0) ? this.props.placeholder : '';

    return (
      <div>
        <div style={{borderRadius: '20px' }}className={`multi-select form-control ${this.props.hasError ? 'invalid' : ''} ${this.state.focused ? 'focus' : ''} `}
             disabled={this.props.disabled}>
          {
            // Note the use of isEqual for Object comparisons if needed
            this.state.selected.map(value => this.state.cache.find(x => _.isEqual((x.value || x.id), value)))
                                .filter(x => !!x) // Only truthy values
                                .map(x => <MultiSelectChip name={x.name} disabled={this.props.disabled || x.disabled} itemClicked={() => this.removeItem(x.value || x.id)} key={x.name}/>)
          }
          {
            !this.limitReached() &&
            <input type='text'
                   className={`${this.props.hasError ? 'invalid' : ''}`}
                   placeholder={placeholder}
                   value={this.state.searchText}
                   onFocus={this.onFocus}
                   disabled={this.props.disabled}
                   onBlur={(e) => this.onBlur(e)}
                   onChange={(e) => this.searchChanged(e)}/>
          }
          {
            this.state.focused &&
            <div className='options'>
              {this.renderResults()}
            </div>
          }
        </div>
      </div>
    );
  }

  removeItem(value) {
    let selected = [];
    selected = this.state.selected.filter(x => !_.isEqual(x, value));
    this.setState({
      selected
    }, () => this.props.onUpdate(selected));
  }

  addItem(value) {
    let {selected} = this.state;
    const compare = this.isObject(value) ? _.findIndex(selected, value) < 0 : selected.indexOf(value) < 0;
    if (compare) {
      selected = [...selected, value];
      this.setState({
        selected,
        searchText: ''
      }, () => this.props.onUpdate(selected));
    }
  }

  isObject(item) {
    return item instanceof Object;
  }

  renderResults() {
    const results = this.state.results.filter(x => !_.includes(this.state.selected, (x.value || x.id)))
                              .map(result => {
                                 return (
                                   <li key={JSON.stringify(result.value || result.id)} onClick={() => this.addItem(result.value || result.id)}>
                                     {result.name}
                                   </li>
                                 );
                              });

    return (
      <ul>
        { results.length > 0 ? results : <li className='no-results'><i className='fas fa-exclamation-triangle'/> No Results</li> }
      </ul>
    );
  }

  query() {
    const {searchText, queryCache, cache} = this.state

    if(!!queryCache[searchText]) {
      this.setState({
        results: queryCache[searchText]
      });
    }

    this.props.search(searchText, (data) => this.setState({
      cache: _.uniqBy(cache.concat(data), (x) => (x.value || x.id)),
      results: data,
      queryCache: {
        ...queryCache,
        [searchText]: data
      }
    }));
  }

  searchChanged(e) {
    this.setState({
      searchText: e.target.value
    }, this.debouncedQuery);
  }

  onFocus() {
    this.setState({
      focused: true
    }, () => this.query());
  }

  onBlur(e) {
    const { clearInvalidInput, dropDownCloseMilliseconds } = this.props
    const searchText = clearInvalidInput ? '' : this.state.searchText

    setTimeout(
      function() {
        this.setState({
          focused: false,
          searchText: searchText
        });
      }
      .bind(this),
      (dropDownCloseMilliseconds || 250)
    );
  }

  componentDidCatch(error, info) {
    console.warn(error, info);
  }

}
MultiSelect.displayName = 'MultiSelect';
MultiSelect.propTypes = {
  placeholder: PropTypes.string,
  search: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.any).isRequired,
  preload: PropTypes.arrayOf(PropTypes.any),
  clearInvalidInput: PropTypes.bool,
  hasError: PropTypes.bool
}
