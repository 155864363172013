import React from 'react';
import { connect } from 'react-redux';
import ProfilesSocialAccounts from './SocialAccounts'
import ProfilesBlogForms from './BlogForms'

class ProfilesConnectedNetworks extends React.Component {

  constructor(props) {
    super(props);

    this.render = this.render.bind(this);
  }

  render() {
    return (
      <div className='connected-networks-container'>
        <ProfilesSocialAccounts accounts={_.get(this.props,'socialAccounts',[])}
                                 authConnectUrls={this.props.authConnectUrls}
                                 deauthUrls={this.props.deauthUrls}
                                 authRedirectUrl={this.props.authRedirectUrl}
                                 advertiserAuth={this.props.advertiserAuth}
                                 username={this.props.username}/>
        <ProfilesBlogForms { ...this.props } />
      </div>
    );
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps,
      advertiserAuth: state.meta.advertiserAuth
    };
  }
};

ProfilesConnectedNetworks.displayName = 'Profiles.ConnectedNetworks';
export default connect(
  ProfilesConnectedNetworks.mapStateToProps,
  ProfilesConnectedNetworks.mapDispatchToProps
)(ProfilesConnectedNetworks);

