import React from 'react';
import { connect } from 'react-redux';
import Tooltip from '../Tooltip'

class ShoppertunitiesConnectButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showTooltip: false}

    this.handleAuth = this.handleAuth.bind(this);
    this.tooltip = this.tooltip.bind(this);
    this.fans = this.fans.bind(this);
    this.message = this.message.bind(this);
    this.authListener = this.authListener.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.isInstagram = this.isInstagram.bind(this);
    this.render = this.render.bind(this);
  }

  isInstagram() {
    return this.props.shoppertunity.platform.name === 'Instagram'
  }

  toggleTooltip() {
    this.setState({ showTooltip: !this.state.showTooltip })
  }

  tooltip(){
    if(this.isInstagram()){
      return this.props.meta.alerts.I18n.alerts.help_text.facebook_instagram_business;
    }
  }

  fans(){
    const { shoppertunity } = this.props;
    const platform_settings = shoppertunity.shoppertunity_platform_setting
    const { platform } = shoppertunity;
    if(this.isInstagram()){
      return platform_settings.high_reach_minimum + (platform_settings.high_reach_minimum > 1 ? ' followers' : ' follower');
    }
  }

  message(){
    const { meta, shoppertunity } = this.props;
    const { platform } = shoppertunity;
    if(this.isInstagram()){
      return meta.alerts.I18n.alerts.ig_connect.replace('%{fans}', this.fans());
    }
  }

  handleAuth() {
    const { meta, shoppertunity } = this.props;
    const { platform } = shoppertunity;
    const urls = meta.connectUrls;
    let authType = (() => {
      switch(platform.name) {
        case 'Instagram':
          return 'instagram_auth'
        default:
          return;
      }
    })();

    let authUrl = (() => {
      switch(platform.name) {
      case 'Instagram':
        return urls.facebook_instagram;
      default:
        return;
      }
    })();

    window.localStorage.setItem(authType, null);
    window.addEventListener('storage', () => this.authListener(authType));
    window.open(authUrl, '_blank');
  }

  authListener(authType) {
    // Check local storage for successful reconnect
    if (window.localStorage.getItem(authType) != 'null') {
      location.reload();
    }
  }

  render() {
    if(this.isInstagram()){
      return <div className="alert alert-info">
      <i className="fas fa-exclamation-triangle fa-fw"/>
      {this.message()}
      <a className="btn btn-default position-relative"
        onClick={this.handleAuth}
        onMouseLeave={() => this.toggleTooltip()}
        onMouseEnter={() => this.toggleTooltip()}>
        Reconnect
        <i className="fas fa-arrow-circle-right"/>
        {this.state.showTooltip && <Tooltip centerText={true} placement='bottom' message={this.tooltip()}/>}
      </a>
    </div>
    }
    return null;
  }

  static mapStateToProps(state, myProps){
    return {
      ...myProps,
      meta: state.meta,
      shoppertunity: state.shoppertunity
    };
  }
};

ShoppertunitiesConnectButton.displayName = 'Shoppertunities.ConnectButton';

export default connect(
  ShoppertunitiesConnectButton.mapStateToProps
)(ShoppertunitiesConnectButton);