import React from 'react';
import { connect } from 'react-redux'
import { Remote } from '../utils';
import RequirementRow from './RequirementRow'
import FlexBoxContainer from '../FlexBoxContainer'
import Toggle from '../Toggle'
import Tooltip from '../Tooltip'


class RequirementChecklist extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      rejectionComment: '',
      tooltip: {
        button: null,
        visible: false
      }
    }

    this.render = this.render.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.loadBloggerShopTask = this.loadBloggerShopTask.bind(this);
    this.displayRequirements = this.displayRequirements.bind(this);
    this.submitAdminContent = this.submitAdminContent.bind(this);
    this.updateRejectionData = this.updateRejectionData.bind(this);
    this.contentState = this.contentState.bind(this);
    this.updateRejectionComment = this.updateRejectionComment.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.setTooltipText = this.setTooltipText.bind(this);
    this.invalidStoryFollowersValue = this.invalidStoryFollowersValue.bind(this);
    this.analyticsViewCount = this.analyticsViewCount.bind(this);
    this.submitDraft = this.submitDraft.bind(this);
    this.getDraftBody = this.getDraftBody.bind(this);
    this.unwrapDraftBody = this.unwrapDraftBody.bind(this);
    this.matchingTaskType = this.matchingTaskType.bind(this);
    this.contentRequirements = this.contentRequirements.bind(this);
    this.debouncedSync = _.debounce(this.updateRejectionComment, 500);
    this.updateVisiblity = this.updateVisiblity.bind(this);
    this.toggleVisiblity = _.throttle(this.updateVisiblity, 500);
    this.remoteUpdateContent = this.remoteUpdateContent.bind(this);
  }

  componentDidMount() {
    this.loadBloggerShopTask();
  }

  componentDidUpdate(prevProps){
    if(this.props !== prevProps){
      if ( this.props.submitting ){
        this.setState({disabled: true});
      } else if ( this.invalidStoryFollowersValue() ) {
        this.setState({disabled: true});
      } else if ( this.props.bloggerShopTask.content.state === 'accepted' || this.props.contentState == 'pending' ){
        this.setState({disabled: true});
      } else {
        this.setState({disabled: false});
      }
    }
  }

  loadBloggerShopTask() {
    const params = $.param({
      api_action: `/blogger_shop_tasks/${this.props.meta.bloggerShopTaskId}`
    });
    Remote().request({
      url: this.props.meta.proxyUrl + '?' + params,
      method: 'GET'
    }).then(response => {
      this.props.setBloggerShopTask(response.data);
      this.updateRejectionData();
      this.props.setContentState(this.contentState());
    });
  }

  contentRequirements(){
    const requirements = this.props.bloggerShopTask.round_task.round_task_requirements
    if(requirements.length > 0){
      return(
        <div>
          <div className='requirement_checklist--approval-label'>Approval Status</div>
          {
            requirements.map( (req) =>
              <RequirementRow key={req.id} requirement={req}/>)
          }
        </div>
      );
    }else{
      return(
        <div className='requirement_checklist--no-requirements'>
          No requirements found.
        </div>
      );
    }
  }

  displayRequirements() {
    return (
      <div className='requirement_checklist--list'>
        {
          Object.keys(this.props.bloggerShopTask).length > 0 &&
          this.contentRequirements()
        }
      </div>
    );
  }

  invalidStoryFollowersValue(){
    let medias = this.props.bloggerShopTask.instagram_business_story_medias;
    if (medias.length === 0){
      return false;
    } else {
      return medias.map((media) => { return media.fallback_follower_count; } ).includes(NaN);
    }
  }

  analyticsViewCount() {
    return(this.props.bloggerShopTask.instagram_attachments.map(attachment => {
      return { id: attachment.id, count: attachment.count }
    }));
  }

  submitAdminContent(accepted, forced_state) {
    let props = this.props;
    props.toggleSubmitting()
    const params = $.param({
      api_action: `/contents/${props.bloggerShopTask.content_id}/content_validations`,
      content_state: accepted ? forced_state || props.contentState : 'rejected',
      comment: props.rejectionComment,
      user_id: props.meta.userId
    });

    let encodedString = '';
    if(this.matchingTaskType('draft')) {
      const result = this.getDraftBody();
      encodedString = btoa(unescape(encodeURIComponent(result)));
    }

    Remote().request({
      url: props.meta.proxyUrl + '?' + params,
      data: {
        requirements: props.bloggerShopTask.round_task.round_task_requirements,
        instagram_attachments_attributes: this.analyticsViewCount(),
        tiktok_manual_metrics: props.tiktokManualMetrics,
        body: encodedString
      },
      method: 'POST',
    }).then(response => {
      if (props.bloggerShopTask.content.type == 'InstagramBusinessStoryContent'){
        props.submitAdminStoryContent(accepted ? props.contentState : 'rejected');
      } else {
        window.location.href = props.meta.returnUrl;
      }
    }).catch(() => {
      props.toggleSubmitting();
    });
  }

  updateRejectionComment(value){
    this.setState({ rejectionComment: value }, () => {
      this.updateRejectionData();
    });
  }

  updateRejectionData() {
    const task = this.props.bloggerShopTask
    if(Object.keys(task).length > 0 && task.round_task.round_task_requirements.length > 0){
      this.props.setRejectionData(this.contentState(), this.state.rejectionComment || '')
    }
  }

  contentState() {
    const task = this.props.bloggerShopTask
    const round_task_requirements = task.round_task.round_task_requirements
    let anyFailed = _.some(round_task_requirements, ['latest_validation_state.verification_status', 'failed' ]);

    if (anyFailed){
      return 'rejected';
    }
    else{
      return 'accepted';
    }
  }

  toggleTooltip(button) {
    this.setState({
      ...this.state,
      tooltip: {
        button: button,
        visible: !this.state.tooltip.visible
      }
    })
  }

  setTooltipText() {
    const task = this.props.bloggerShopTask
    const tooltips = this.props.meta.adminEn;
    if(task.content.state == 'accepted'){
      return tooltips.hide_from_dashboard_approved_tooltip;
    } else if(_.some(task.round_task.round_task_requirements, ['latest_validation_state.verification_status', 'pending' ])){
      return tooltips.requirements_missing_tooltip;
    } else if(task.content.state === 'pending_client_approval' && this.state.tooltip.button === 'submitToClient') {
      return tooltips.submitted_to_client_tooltip;
    } else if(this.state.tooltip.button === 'dashboardVisible') {
      return tooltips.hide_from_dashboard_draft_tooltip;
    } else {
      return ''
    }
  }

  matchingTaskType(taskType) {
    let re = new RegExp(taskType,'i');
    return !!_.get(this.props.bloggerShopTask, 'content.type','').match(re);
  }

  remoteUpdateContent(content_id, props) {
    Remote().request({
      url: `/admin/contents/${content_id}`,
      method: 'PUT',
      data: props,
      dataType: 'json',
    }).then(() => {
      this.props.toggleSubmitting();
    }).catch((error) => {
      console.log(`error: ${error}`);
      this.props.toggleSubmitting();
    });

  }

  updateVisiblity(bool) {
    const { submitting, toggleSubmitting, setDashboardVisiblity, bloggerShopTask: {content: { id }} } = this.props
    if (!submitting)
      Promise.all([
        toggleSubmitting(),
        setDashboardVisiblity(bool),
        this.remoteUpdateContent(id, {dashboard_viewable: bool})
      ])
  }

  submitDraft() {
    const props = this.props;
    const params = $.param({
      api_action: `/contents/${props.bloggerShopTask.content_id}/draft?archive_annotations=true`,
    });

    props.toggleSubmitting();
    Remote().request({
      url: props.meta.proxyUrl + '?' + params,
      method: 'POST',
      data: {
        body: this.getDraftBody(),
        validations: _.get(props.bloggerShopTask, 'round_task.round_task_requirements', []).map(req => ({
          round_task_requirement_id: req.id,
          verification_status: _.get(req, 'latest_validation_state.verification_status', 'pending')
        }))
      },
    }).then(response => {
      window.location.href = props.meta.returnUrl;
    }).catch(() => {
      props.toggleSubmitting();
    });
  }

  getDraftBody() {
    if (this.matchingTaskType('instagram'))
      return document.getElementById("content_description2").value

    // Switch to the visual mode
    if ($('#html-btn').text() === 'Switch to Visual Mode') {
      $('#html-btn').trigger('click');
    }

    // Clean up redactor tags
    this.unwrapDraftBody('.annotator-wrapper');
    let el, i, len, ref;
    ref = $('.annotator-hl').get();
    for (i = 0, len = ref.length; i < len; i++) {
      el = ref[i];
      this.unwrapDraftBody(el);
    }
    $('.annotator-adder, .annotator-widget').remove();
    $('.annotator-outer, .annotator-hide').remove();
    return ($('.redactor-box > .redactor-editor').html() || $('#draft_editor .draft-body').html());
  }

  unwrapDraftBody(selector) {
    var el, i, len, ref, results;
    ref = $(selector).get();
    results = [];
    for (i = 0, len = ref.length; i < len; i++) {
      el = ref[i];
      $(el).contents().insertBefore($(el));
      results.push($(el).remove());
    }
    return results;
  }

  render() {
    const { dashboardVisible, rejectSelected } = this.props;
    const task = this.props.bloggerShopTask;
    const hasTask = Object.keys(task).length > 0;

    if(hasTask){
      const { disabled, tooltip: { button, visible } } = this.state;
      const hasStoryRejections = this.matchingTaskType('InstagramBusinessStoryContent') ? rejectSelected : false;
      const hideFromDashboard = dashboardVisible === undefined ? false : !dashboardVisible;
      const tooltipText = this.state.tooltip && this.setTooltipText();
      const isDraftApproval = _.get(task, 'campaign.draft_dashboard_display', '') === 'draft_approval';
      const draftContent = this.matchingTaskType('draft')
      const otherContent = this.matchingTaskType('otherContent')

      return(
        <div className='requirement_checklist--container'>
          <h3>Content Requirement Checklist</h3>
          <hr/>
          {this.displayRequirements()}
          <div className='requirement_checklist--actions'>
            <label className='influencer-feedback--label'>Influencer Feedback:
              <textarea
                className='influencer-feedback'
                rows="4"
                defaultValue={task.content.latest_content_validation ? task.content.latest_content_validation.comment : ''}
                onChange={(event) => this.debouncedSync(event.target.value)}
                />
            </label>
            <FlexBoxContainer>
              { (draftContent || otherContent) &&
                <div onMouseLeave={() => this.toggleTooltip('dashboardVisible')}
                  onMouseEnter={() => this.toggleTooltip('dashboardVisible')}
                  style={{position: 'relative'}}>
                  <label>Hide from Dashboard</label>
                  <Toggle checked={hideFromDashboard} onUpdate={(b) => this.toggleVisiblity(!b)}/>
                  { (visible && button === 'dashboardVisible' && !!tooltipText) &&
                    <Tooltip centerText={true} placement='bottom' message={tooltipText}/>
                  }
                </div>
              }
              {(draftContent && task.content.state !== 'accepted' && task.content.state !== 'pending_client_approval') &&
                <div className='btn btn-primary' onClick={() => this.submitDraft()} disabled={disabled} >
                  Save Draft
                </div>
              }
              {(draftContent && isDraftApproval && this.props.contentState === 'accepted' && !hasStoryRejections) &&
                <div
                  className='btn btn-primary'
                  onClick={() => this.submitAdminContent(true, 'pending_client_approval')}
                  onMouseLeave={() => this.toggleTooltip('submitToClient')}
                  onMouseEnter={() => this.toggleTooltip('submitToClient')}
                  disabled={!this.props.dashboardVisible || task.content.state === 'pending_client_approval' || disabled}
                >
                  Submit for Dashboard Approval
                  { (visible && button === 'submitToClient' && tooltipText !== '') &&
                    <Tooltip centerText={true} placement='bottom' message={tooltipText}/>
                  }
                </div>
              }
              <div
                className='btn btn-primary approve-btn'
                onClick={() => this.submitAdminContent(true, 'accepted')}
                onMouseLeave={() => this.toggleTooltip('approve')}
                onMouseEnter={() => this.toggleTooltip('approve')}
                disabled={disabled}
              >
                Approve
                { (visible && button === 'approve' && tooltipText !== '') &&
                  <Tooltip centerText={true} placement='bottom' message={tooltipText}/>
                }
              </div>
              <div
                className='btn btn-primary reject-btn'
                disabled={disabled}
                onClick={() => this.submitAdminContent(false)}
              >
                Reject
              </div>
            </FlexBoxContainer>
          </div>
        </div>
      );
    } else {
      return(
        <div className='requirement_checklist--container'>
          <h3>Content Requirement Checklist</h3>
          <hr/>
          <div className='requirement_checklist--no-requirements'>
            No requirements found.
          </div>
        </div>
      );
    }
  }

  static mapStateToProps(state, props) {
    return {
      ...state,
      ...props,
      meta: state.meta,
      bloggerShopTask: state.bloggerShopTask,
      contentState: state.contentState,
      rejectionComment: state.rejectionComment,
      submitting: state.submitting,
      dashboardVisible: state.dashboardVisible
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      toggleSubmitting: () => dispatch({ type: 'TOGGLE_SUBMITTING'}),
      setRejectionData: (state, comment) => dispatch({ type: 'SET_REJECTION_DATA', value: {contentState: state, rejectionComment: comment} }),
      setContentState: contentState => dispatch({ type: 'SET_CONTENT_STATE', value: contentState }),
      setBloggerShopTask: task => dispatch({ type: 'SET_BLOGGER_SHOP_TASK', value: task }),
      setDashboardVisiblity: (bool) => dispatch({ type: 'DASHBOARD_VISIBILITY', value: {bool} })
    };
  }
};

RequirementChecklist.displayName = 'Contents.RequirementChecklist';

export default connect(
  RequirementChecklist.mapStateToProps,
  RequirementChecklist.mapDispatchToProps
)(RequirementChecklist);
