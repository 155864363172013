import { createStore } from 'redux';

const initialState = {
  notifications: {
    saving: false,
    saveType: '',
    saveText: '',
    saveErrors: ''
  },
};

const reducer = (state = initialState, action) => {
  const { type, value } = action;
  switch(type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [value.field]: value.data
      };
    case 'OPEN_NOTIFICATION':

      const {saveType, saveText, saveErrors} = action.value;
      return {
        ...state,
        notifications: {
          saving: true,
          saveType: saveType,
          saveText: saveText,
          saveErrors: saveErrors
        }
      };
    case 'CLOSE_NOTIFICATION':
      return {
        ...state,
        notifications: {
          ...initialState.notifications
        }
      };
    default:
      return state;
  };
};

const PostingStore = createStore(
  reducer,
  window && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ name: 'Redux.PostingStore' }) // Enable redux devtools middleware
);

export default PostingStore;