import React from 'react';
import {connect} from 'react-redux'

import {Remote} from '../utils'
import PublishedWave from './PublishedWave'

class WavesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      waves: [],
      selectedWaveId: null,
      selectedWaveNumber: null
    };

    this.componentDidMount = this.componentDidMount.bind(this)
    this.loadWaves = this.loadWaves.bind(this);
    this.render = this.render.bind(this);
  }

  componentDidMount() {
    // Use a promise to defer loading the waves until we've updated the store
    new Promise(resolve => {
      this.props.updateState({
        campaignId: this.props.campaignId
      });
      resolve(true);
    }).then(() => {
      this.loadWaves();
    });
  }

  render() {
    const waves = this.state.waves

    if(waves.length == 0) {
      return (
        <h4 className="alert alert-info text-center mb-0">
            <i className="fas fa-info-circle fa-fw" />
            No Ad Waves have been published for this campaign
        </h4>
      );
    };

    const waveOptions = waves.map((wave) => {
      return(
        <li key={wave.id} className='dropdown-item' style={{fontSize: '18px'}}>
          <a onClick={(e) => this.handleWaveChange(e, wave.id, wave.wave_number)}>Wave #{wave.wave_number}</a>
        </li>
      )
    });

    return (
      <div>
        <h1>Published Ads</h1>
        <div className='dropdown wave-dropdown pt-5'>
          <button className='btn btn-default dropdown-toggle'
                  style={{fontSize: '18px'}}
                  type='button'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'>
            Wave #{this.state.selectedWaveNumber}
            <i className='fas fa-caret-down' style={{paddingLeft: '5px'}}></i>
          </button>
          <ul className='dropdown-menu'>
            {waveOptions}
          </ul>
        </div>
        <PublishedWave campaignId={this.props.campaignId}
                                   waveId={this.state.selectedWaveId}/>
      </div>
    )
  }

  handleWaveChange(e, waveId, waveNumber){
    this.setState(
      {
        selectedWaveId: waveId,
        selectedWaveNumber: waveNumber
      }
    )
  }

  loadWaves() {
    Remote().get(`/admin/campaigns/${this.props.campaignId}/promoters_v2/${this.props.waveState}`).then((resp) => {
      const data = resp.data
      if (data.length == 0) return
      this.setState(
        {
          waves: data,
          selectedWaveId: data && data[0].id,
          selectedWaveNumber: data && data[0].wave_number
        }
      );
    });
  }

  static mapStateToProps(state, myProps) {
    return {
      ...myProps
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateState: changes => dispatch({ type: 'UPDATE_STATE', value: changes })
    };
  }
}

WavesPage.displayName = 'FacebookAds.WavesPage';
export default connect(
  WavesPage.mapStateToProps,
  WavesPage.mapDispatchToProps
)(WavesPage);
