import React from 'react';
import NavigationTabsLink from './Link';

export default class NavigationTabsDesktop extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { order, tabs, links, activeTab, counts, onClickCallback, errors } = this.props;
    const visibleTabs = order && order.map( (tab) => {
      const selected = activeTab === tab;
      const title = (tabs||{})[tab] || '';
      const link = (links||{})[tab];
      const count = (counts || {})[tab];
      const hasError = !!(errors || {})[tab];
      return <NavigationTabsLink
        key={tab}
        tab={tab}
        title={title}
        link={link}
        selected={selected}
        count={count}
        hasError={hasError}
        onClickCallback={onClickCallback}
        />
    });

    return(
      <div className='shoppertunity_nav'>
        <div className='shoppertunity_nav--container'>
          { visibleTabs }
        </div>
      </div>
    );
  }
}
NavigationTabsDesktop.displayName = 'NavigationTabs.Desktop';
