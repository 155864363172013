import React from 'react';

export default class ShoppertunitiesRoundsTable extends React.Component {

  constructor(props) {
    super(props);

    this.roundRows = this.roundRows.bind(this);
    this.render = this.render.bind(this);
  }

  roundRows(){
    const rounds = this.props.rounds
    if(rounds){
      return rounds.map(round => {
        return <tr key={round.id}>
                 <td width='52%'>
                   <a href={round.show_url}>
                     {round.name}
                   </a>
                 </td>
                 <td width='12%'>{round.due_date}</td>
                 <td width='12%'>{round.selected_influencers}</td>
                 <td width='12%'>{round.utilization}</td>
                 <td width='12%'>{round.tasks_count}</td>
               </tr>
      })
    }else{
      return <tr className='no-content-row'>
               <td colspan='5'>There are no rounds for this Opportunity</td>
             </tr>
    }
  }

  render() {
    return(
      <table className='table shoppertunity-rounds--table'>
        <thead>
          <tr>
            <th scope='col'>Round</th>
            <th scope='col'>Due Date</th>
            <th scope='col'>Selected Influencers</th>
            <th scope='col'>Properly Utilized</th>
            <th scope='col'># of Tasks</th>
          </tr>
        </thead>
        <tbody>
          {this.roundRows()}
        </tbody>
      </table>
      );
  }
}
ShoppertunitiesRoundsTable.displayName = 'Shoppertunities.RoundsTable';