import React from 'react';
import { connect } from 'react-redux'
import { Remote } from '../utils';

import LoadingSpinner from '../LoadingSpinner'
import RichTextEditor from '../RichTextEditor'
import DatePicker from '../DatePicker'
import InputGroup from '../InputGroup'
import Tooltip from '../Tooltip'
import SingleSelect from '../SingleSelect'
import MonetaryValue from '../MonetaryValue'
import SaveIndicator from '../SaveIndicator'
import SaveError from '../SaveError'
import RoundsValidation from './Validation'
import RoundsTasksContainer from './TasksContainer'
import RoundsCustomTaskButton from './CustomTaskButton'
import RoundsAddTaskButton from './AddTaskButton'
import RoundTasksAddFromGeneralModal from '../round_tasks/AddFromGeneralModal'

class RoundsShow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingTemplates: true,
      additionalPaymentHovered: false
    };

    this.allValidations = this.allValidations.bind(this);
    this.cloneRound = this.cloneRound.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.deleteRound = this.deleteRound.bind(this);
    this.editRoundValue = this.editRoundValue.bind(this);
    this.hasVariablePay = this.hasVariablePay.bind(this);
    this.loadContentTypes = this.loadContentTypes.bind(this);
    this.loadGeneralTemplates = this.loadGeneralTemplates.bind(this);
    this.loadRound = this.loadRound.bind(this);
    this.loadTemplates = this.loadTemplates.bind(this);
    this.persistChanges = this.persistChanges.bind(this);
    this.render = this.render.bind(this);
    this.searchDeliverables = this.searchDeliverables.bind(this);

    this.handleChanges = _.debounce(this.persistChanges, 500)
  }

  componentDidMount() {
    this.loadTemplates();
    this.loadContentTypes();
    this.loadRound();
    this.loadGeneralTemplates();
    this.props.setMeta({
      proxyUrl: this.props.proxyUrl
    });
    this.props.setDisplayPage('round');
  }

  loadRound() {
    const params = $.param({
      api_action: `shoppertunities/${this.props.shop_id}/rounds/${this.props.round_id}`,
    });

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'GET',

    }).then((response) => {
      this.props.set(response.data);
      this.props.buildPallete();
      this.setState({loading: false});
    }).catch((error) => {
      this.setState({loading: false});
      this.props.openNotification(
        'Failed to load Round! Please refresh the page.',
        'failure',
        null
      )
      setTimeout(this.props.closeNotification, 5000)
    });
  }

  loadContentTypes() {
    const params = $.param({
      api_action: '/content_types',
    });

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'GET',
    }).then((response) => {
      this.props.setContentTypes(response.data);
    });
  }

  loadTemplates(){
    const params = $.param({
      api_action: `/round_tasks`,
      template_type: 'campaign',
      template: true,
      campaign_id: this.props.campaign_id
    });

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'GET',

    }).then((response) => {
      this.props.templates(response.data.active)
    }).catch((error) => {
      this.props.openNotification(
        'Failed to load templates! Please refresh the page.',
        'failure',
        null
      )
      setTimeout(this.props.closeNotification, 5000)
    });
  }

  loadGeneralTemplates() {
    const params = $.param({
      api_action: `/round_tasks`,
      template_type: 'campaign_general',
      campaign_id: this.props.campaign_id
    });

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'GET',

    }).then((response) => {
      this.props.setGeneralTemplates(response.data.active)
      this.setState({loadingTemplates: false});
    }).catch((error) => {
      this.props.openNotification(
        'Failed to load general templates! Please refresh the page.',
        'failure',
        null
      )
      this.setState({loadingTemplates: false});
      setTimeout(this.props.closeNotification, 5000)
    });
  };

  deleteRound() {
    const params = $.param({
      api_action: `/shoppertunities/${this.props.shop_id}/rounds/${this.props.round.id}`
    });

    this.setState({loading: true});

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'DELETE',

    }).then(() => {
      document.location.href = `/admin/shoppertunities/${this.props.shop_id}`
    }).catch((error) => {
      this.setState({loading: false});
      this.props.openNotification(
        'Failed to delete round. Please try again.',
        'failure',
        JSON.parse(error.response.data.responseText)
      )
      setTimeout(this.props.closeNotification, 5000)
    });
  }

  hasVariablePay() {
    return _.some(this.props.round.round_tasks, task => _.get(task, 'payment_type[0].name') == 'Variable');
  }

  // Aggregate all errors into one place
  allValidations() {
    const taskValidations = this.props.round.round_tasks.map(task => {
      const reqValidations = task.round_task_requirements.map(req => req.human_validations);
      return [...task.human_validations, ...reqValidations]
    });

    // flattenDeep is used here because we'll end up with a shape like [msg, msg, [msg, msg, [msg]]]
    return _.uniq([...this.props.round.human_validations, ..._.flattenDeep(taskValidations)])
  }

  cloneRound() {
    const validations = this.allValidations();
    if(validations.length) {
      this.props.openNotification(
        'Round is not valid!',
        'failure',
        validations
      )
      setTimeout(this.props.closeNotification, 5000)
      return;
    }

    const params = $.param({
      api_action: `/shoppertunities/${this.props.shop_id}/rounds/${this.props.round.id}/clone`
    });

    this.setState({loading: true});

    Remote().request({
      url: this.props.proxyUrl + '?' + params,
      method: 'POST',

    }).then((response) => {
      document.location.href = `/admin/shoppertunities/${this.props.shop_id}/rounds/${response.data.id}`
    }).catch((error) => {
      this.setState({loading: false});
      this.props.openNotification(
        'Failed to clone round. Please try again.',
        'failure',
        JSON.parse(error.response.data.responseText)
      )
      setTimeout(this.props.closeNotification, 5000)
    });
  }

  searchDeliverables(query, callback) {
    const results = _.filter(
      this.props.round.deliverable_names,
      value => value.name.toLowerCase().includes(query.toLowerCase())
    );
    callback(results);
  }

  editRoundValue(fieldName, value){
    const newRound = {
      ...this.props.round,
      [fieldName]: value
    };
    this.props.edit(newRound);
    this.handleChanges(newRound);
  }

  persistChanges(round) {
    const params = $.param({
      api_action: `/shoppertunities/${this.props.shop_id}/rounds/${round.id}`
    });

    const props = this.props;
    const roundInfo = {
      ...round,
      round_tasks: [],
      submission_types: []
    };

    Remote().request({
      url: `${this.props.proxyUrl}?${params}`,
      method: 'PATCH',
      data: roundInfo,
    }).then((response) => {
      this.props.edit({
        ...this.props.round,
        final_payment: response.data.final_payment,
        validations: {}
      });
      this.props.openNotification(
        'Successfully saved Round change',
        'success'
      );
      setTimeout(this.props.closeNotification, 5000)
    }).catch((error) => {
      const response = JSON.parse(error.response.data.responseText);
      props.edit({
        ...props.round,
        validations: response.validations,
        human_validations: response.human_validations
      });
      this.props.openNotification(
        'Failed to save round change! Please try again:',
        'failure',
        response.human_validations
      )
    });
  }

  componentDidCatch(error, info) {
    console.warn(error, info);
  }

  render() {
    const round = this.props.round|| {};

    if (this.state.loading || (round === undefined || round.length == 0)) {
      return <LoadingSpinner />;
    } else {
      return (
        <div className='shop_rounds--container'>
          <div className="shop_rounds--actions">
            <div className="shop_rounds-nav-dropdown dropdown">
              <button className="form-control btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">
                Select Round
                &nbsp; <span className="caret"></span>
              </button>
              <ul className="dropdown-menu">
                {
                round.shop_rounds && round.shop_rounds.map((shop_round, index) => {
                  return (
                    <li key={index} className={shop_round.id == this.props.round_id ? 'active' : ''}>
                        <a href={`/admin/shoppertunities/${this.props.shop_id}/rounds/${shop_round.id}`}>
                          {_.truncate(shop_round.name, {'length': 45})}
                        </a>
                      </li>
                    );
                  })
                }
              </ul>
            </div>
            <div className='shop_rounds--icons'>
              <a className='btn btn-primary' href={this.props.newRoundUrl} target='_blank'>
                Add Round
              </a>
              <button className='btn secondary' onClick={this.cloneRound}>
                Clone
              </button>
              <div className='round-icon' title='Delete Round'>
                <i className='fas fa-trash text-danger' onClick={this.deleteRound} />
              </div>
            </div>
          </div>
          <div className='shop_rounds--top-section'>
            <div className='shop_rounds-value'>
              <label>
                Name
                <RoundsValidation field='name' validations={round.validations} />
              </label>
              <input
                className='form-control'
                placeholder='Round Name'
                defaultValue={round.name}
                onChange={e => this.editRoundValue('name', e.target.value)}
              />
            </div>
            <div className='shop_rounds-value'>
              <label>
                Description
                <RoundsValidation field='description' validations={round.validations} />
              </label>
              <RichTextEditor
                placeholder='Round description goes here...'
                value={round.description}
                onUpdate={(html) => this.editRoundValue('description', html)}
              />
            </div>
            <div className='shop_rounds--top-info'>
              <div className='shop_rounds-value'>
                <label>
                  Due Date
                  <RoundsValidation field='end_date' validations={round.validations} />
                </label>
                <DatePicker className='form-control' value={round.end_date || ''} onChange={date => this.editRoundValue('end_date', date)} />
              </div>
              { round.payment &&
                <React.Fragment>
                  <div className='shop_rounds-value'>
                    <label>
                      Pre-Payment
                      <RoundsValidation field='pre_payment' validations={round.validations} />
                    </label>
                    <InputGroup
                        disabled={(round.instruction_approved || !round.payment) || false}
                        iconClass='fas fa-dollar-sign'
                        type='number'
                        value={round.pre_payment || ''}
                        onChange={x => this.editRoundValue('pre_payment', x)}
                      />
                  </div>
                  <div className='shop_rounds-value'>
                    <label
                      onMouseEnter={() => this.setState({ additionalPaymentHovered: true })}
                      onMouseLeave={() => this.setState({ additionalPaymentHovered: false })}
                      style={{position: 'relative'}}
                      >
                      Additional Payment
                      <RoundsValidation field='additional_payment' validations={round.validations} />
                      {
                        this.state.additionalPaymentHovered &&
                        <Tooltip centerText={true} placement='top' message='Payment after Pre-Payment, not included in Task payments.'/>
                      }
                    </label>
                    <InputGroup
                        disabled={(round.instruction_approved || !round.payment) || false}
                        iconClass='fas fa-dollar-sign'
                        type='number'
                        /* Extra checks here to account for how JS treats 0 vs null vs undefined */
                        value={typeof(round.additional_payment) === 'object' || typeof(round.additional_payment) === 'undefined' ? '' : round.additional_payment}
                        onChange={x => this.editRoundValue('additional_payment', x)}
                      />
                  </div>
                  <div className='shop_rounds-value'>
                    <label>Final Payment</label>
                    {
                      (round.payment && !round.open_bid) &&
                        <div className='text-muted'>
                          <MonetaryValue value={round.final_payment || round.calc_final_payment} /> <br />
                          {this.hasVariablePay() && '+ Variable Pay'}
                        </div>
                      }
                      {round.open_bid && 'Open Bid'}
                      {!round.payment && 'None'}
                  </div>
                </React.Fragment>
              }
              <div className='shop_rounds-value'>
                <label>
                  Influencer Count
                  <RoundsValidation field='total_bloggers' validations={round.validations} />
                </label>
                <InputGroup
                    iconClass='fas fa-users'
                    type='number'
                    value={round.total_bloggers || ''}
                    onChange={x => this.editRoundValue('total_bloggers', x)}
                  />
              </div>
              { this.props.round.shop_type == 'Instagram' &&
                <div className='shop_rounds-value'>
                  <label>
                      {round.shop_deliverable || 'Reach Allocation'}
                      <RoundsValidation field='instagram_reach_deliverable' validations={round.validations} />
                    </label>
                    <SingleSelect
                      preload={round.deliverable_names}
                      selected={round.instagram_reach_deliverable || ''}
                      search={this.searchDeliverables}
                      onUpdate={value => this.editRoundValue('instagram_reach_deliverable', value)}
                      resultFontSize='12pt'
                      placeholder={round.deliverable_names.length != 0 ? 'Select a deliverable' : ''}
                      disabled={round.deliverable_names.length == 0}
                    />
                </div>
            }
            </div>
          </div>
          <hr/>
          <div className='requirements_container'>
            <RoundsTasksContainer />
            <div className='display_buttons' >
              <RoundsCustomTaskButton disabled={round.instruction_approved || round.shop_posted} {...this.props}  />
              <RoundsAddTaskButton disabled={round.instruction_approved || round.shop_posted} loadingTemplates={this.state.loadingTemplates} {...this.props} />
            </div>
          </div>
          {
            this.props.notifications && this.props.notifications.saving &&
            <SaveIndicator type={this.props.notifications.saveType}>
              {this.props.notifications.saveText}
              {this.props.notifications.saveErrors &&
                <SaveError errors={this.props.notifications.saveErrors}/>
              }
            </SaveIndicator>
          }

          {this.props.generalModalOpen && <RoundTasksAddFromGeneralModal loadingTemplates={this.state.loadingTemplates}/>}
        </div>
      );
    }
  }

  static mapStateToProps(state, myProps) {
    return {
      ...state,
      ...myProps
    };
  }

  static mapDispatchToProps(dispatch) {
    return {
      set: (round) => dispatch({type: 'SET_ROUND', value: round }),
      setContentTypes: data => dispatch({ type: 'SET_CONTENT_TYPES', value: data }),
      templates: (templates) => dispatch({type: 'SET_TEMPLATES', value: templates }),
      setGeneralTemplates: templates => dispatch({ type: 'SET_GENERAL_TEMPLATES', value: templates }),
      edit: (round) => dispatch({ type: 'EDIT_ROUND', value: { id: round.id, data: round } }),
      deleteRound: (round) => dispatch({ type: 'DELETE_ROUND', value: round.id }),
      openNotification: (saveText, saveType, saveError) => dispatch({
        type: 'OPEN_NOTIFICATION',
        value: {
          saveText,
          saveType,
          saveErrors: saveError
        }
      }),
      closeNotification: () => dispatch({ type: 'CLOSE_NOTIFICATION'}),
      addRound: round => dispatch({ type: 'ADD_ROUND', value: round }),
      toggleModal: () => dispatch({ type: 'TOGGLE_GENERAL_MODAL' }),
      setMeta: meta => dispatch({ type: 'SET_META', value: meta }),
      setEditingType: type => dispatch({ type: 'SET_EDITING_TYPE', value: type }),
      setDisplayPage: type => dispatch({ type: 'SET_DISPLAY_PAGE', value: type }),
      addTask: (_roundId, roundTask) => dispatch({ type: 'ADD_TASK', value: { roundTask } }),
      buildPallete: () => dispatch({ type: 'BUILD_PALETTE'  })
    };
  }
};

RoundsShow.displayName = 'Rounds.Show';

export default connect(
  RoundsShow.mapStateToProps,
  RoundsShow.mapDispatchToProps
)(RoundsShow);

