# Watches the state of annotations to update the server on any modifications or deletions
class window.DynamicAnnotations
  constructor: (annotationSelector) ->
    @annotations = $(annotationSelector).data('annotations') || []
    @content_id = $('#content-id').data('id') || 0

  changes: =>
      removed_ids: @getEmptyAnnotations().concat(@getDeletedAnnotations())
      updated_text: @getUpdatedPayload()

  sync: =>
    $.ajax
        url: "/contents/#{@content_id}/sync_annotations"
        async: false
        method: 'POST'
        success: (response) =>
          ids_to_remove = @changes().removed_ids
          new_list = @annotations
          for a in @annotations
            new_list.push(a) unless ids_to_remove.some((id) -> id == a.id)
          @annotations = new_list
        data: @changes()

  getEmptyAnnotations: =>
    _.map(@filterBy(@annotationEmpty), @getId)

  getDeletedAnnotations: =>
    result = []
    for a in @annotations
      result.push(a.id) unless $('.annotator-wrapper').find(".annotator-hl[data-annotation-id=#{a.id}]").length
    result

  getUpdatedAnnotations: =>
    _.uniq(_.map(@filterBy(@annotationNotEmpty), @getId))

  getUpdatedPayload: =>
    payloadArray = []
    for id in @getUpdatedAnnotations()
      quote = $(".annotator-hl[data-annotation-id=#{id}]").text()
      payloadArray.push
          id: Number(id)
          quote: quote
    payloadArray

  getId: (selector) =>
    $(selector).attr('data-annotation-id')

  selectAnnotations: =>
    $('.annotator-hl[data-annotation-id]').get()

  annotationEmpty: (annotation) =>
    $(annotation).text().match(/^\s+?$/)

  annotationNotEmpty: (annotation) =>
    !@annotationEmpty(annotation)

  filterBy: (method) ->
    _.filter(@selectAnnotations(), method)
