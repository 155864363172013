import React from 'react';
import { connect } from 'react-redux'
import { FileUploader, Remote } from '../utils';

import Alert from '../Alert';
import FileInputGroup from '../FileInputGroup'
import SingleSelect from '../SingleSelect'

const adTemplateOptions = [//{id: 1, name: 'Pinterest', value: 'pinterest-ad-metric'},
                           {id: 2, name: 'Twitter', value: 'twitter-ad-metric'}];

class UploadAdMetrics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      adTemplate: null,
      uploadFile: null,
      fileName: '',
      submitting: false,
      alert: {
        type: null,
        message: null,
        visible: false
      }
    }

    this.selectSearch = this.selectSearch.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.submitAdMetrics = this.submitAdMetrics.bind(this);
    this.refreshUploads = this.refreshUploads.bind(this);
    this.render = this.render.bind(this);
  }

  selectSearch(text, callback) {
    if(!text) {
      callback(adTemplateOptions);
    }
    callback(adTemplateOptions.filter(x => x.name.toLowerCase().includes(text.toLowerCase())));
  }

  handleFileUpload(e) {
    this.setState({
      uploadFile: e.target.files[0],
      fileName: e.target.files[0].name
    });
  }

  submitAdMetrics() {
    this.setState({submitting: true});
    let formData = new FormData();
    formData.append('csv_file', this.state.uploadFile);
    formData.append('template', this.state.adTemplate);
    let url = this.props.campaignId ? `/admin/campaigns/${this.props.campaignId}/promoters_v2/upload_ad_metrics` : `/admin/pinterests`;

    FileUploader().request({
      url: url,
      data: formData,
      method: 'POST',
      processData: false,
      contentType: false
    }).catch(() => {
      this.setState({
        submitting: false,
        alert: {
          type: 'error',
          message: 'Ad metrics file upload failed. Please try again.',
          visible: true
        }
      });
      setTimeout( () => this.setState({alert: { visible: false}}), 5000);
    }).then(() => {
      this.setState({
        submitting: false,
        alert: {
          type: 'success',
          message: 'Ad metrics file uploaded successfully!',
          visible: true
        }
      });
      setTimeout( () => this.setState({alert: { visible: false}}), 5000);
      !this.props.multi_campaign && this.refreshUploads()
    });
  }

  refreshUploads() {
    Remote().request({
      method: 'GET',
      url: `${this.props.proxyUrl}?api_action=/csv_uploads`
    }).then( response => {
      this.props.updateUploads(response.data);
    })
  }

  render() {
    let {adTemplate, uploadFile, submitting, alert} = this.state
    let submitDisabled = !(adTemplate && uploadFile) || submitting
    let submitText = submitting ? <i className='fas fa-circle-notch fa-spin fa-lg text-color-blue' /> : 'Submit'
    return (
      <div className={`card-plus ${this.props.multi_campaign ? 'multi' : ''} promoter-ad-metrics-uploader`}>
        <div className='card-header'>
          <h2>Upload Ad Metrics</h2>
        </div>
        <div className='card-body'>
          { this.props.multi_campaign ?
            <p>Include ad performance metrics in the dashboards of campaigns by uploading a platform report into Social Fabric.</p>
            : // else
            <p>Include ad performance metrics in the dashboard for this campaign by uploading a platform report into Social Fabric.</p>
          }
          <div className='ad-upload-inputs'>
            <div className="form-group">
              <label>Upload Template</label>
              <SingleSelect placeholder={'Select'}
                            preload={adTemplateOptions}
                            selected={this.state.adTemplate || ''}
                            search={this.selectSearch}
                            onUpdate={selected => this.setState({adTemplate: selected })}
                            disabled={false}/>
            </div>
            <div className='input_wrap'>
              <FileInputGroup
                onChange={this.handleFileUpload}
                placeholder={this.state.fileName || 'Select a report...'}
                addonPlacement='right'
                fileTypes='.csv'
                disabled={false}
              />
            </div>
            <div className='button-group'>
              <button className='btn primary' disabled={submitDisabled} onClick={this.submitAdMetrics}>{submitText}</button>
            </div>
            {alert.visible &&
              <Alert type={alert.type} message={alert.message} callback={() => this.setState({alert: { visible: false}})}/>
            }
          </div>
        </div>
      </div>
    );
  }

  static mapDispatchToProps(dispatch) {
    return {
      updateUploads: uploads => dispatch({ type: 'REFRESH_UPLOADS', value: uploads })
    }
  }
};

UploadAdMetrics.displayName = 'UploadAdMetrics';
export default connect(
  null,
  UploadAdMetrics.mapDispatchToProps
) (UploadAdMetrics);
