import React from 'react';
import AutoCreateAnchorTags from '../AutoCreateAnchorTags'
import RoundTasksRequirementsRow from './RequirementsRow'
import RoundTasksTemplateLegend from './TemplateLegend'

export default class RoundTasksRequirements extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sorted: false,
      dragIndex: null,
      dragOverIndex: null,
      requirementLoading: false,
      viewRequirements: false
    }

    this.addRequirement = this.addRequirement.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.render = this.render.bind(this);
    this.renderAddRequirementButton = this.renderAddRequirementButton.bind(this);
    this.renderRequirements = this.renderRequirements.bind(this);
    this.renderRequirementDescriptions = this.renderRequirementDescriptions.bind(this);
    this.changeRequirementView = this.changeRequirementView.bind(this);
  }

  onDragStart(index) {
    this.setState({ dragIndex: index });
  }

  onDragOver(index) {
    this.setState({ dragOverIndex: index });
  }

  onDragEnd() {
    const { dragIndex, dragOverIndex } = this.state;
    const { updateRequirementSort, setRequirementSort } = this.props;

    if (dragIndex !== null && dragOverIndex !== null) {
      const promise = () => new Promise((resolve) => {
        resolve(updateRequirementSort(dragIndex, dragOverIndex));
      });

      promise().then(() => {
        if ( setRequirementSort ) {
          setRequirementSort()
        }
        this.setState({
          sorted: true,
          dragIndex: null,
          dragOverIndex: null
        });
      });
    }
  }

  addRequirement(e) {
    e.preventDefault();
    this.setState({ requirementLoading: true });
    this.props
      .addRequirement()
      .then(() => this.setState({ requirementLoading: false }));
  }

  renderRequirements() {
    const requirements = this.state.sorted ? this.props.requirements : _.sortBy(this.props.requirements, ['sort']);

    return (
      <React.Fragment>
        <div className={`requirement_rows_container`}>
          <div className='requirements_header'>
            <div className='requirements_header_grip'></div>
            <div className='col-sm-3'>Description</div>
            <div className='col-sm-2'>Verification</div>
            <div className='col-sm-3'>Verification Value</div>
            <div className='col-sm-2'>Frequency</div>
            <div className='col-sm-1'>#</div>
            <div className='requirements_header_actions'></div>
          </div>
          {
            requirements.map((requirement, index) => {
              // Non-standard round tasks (IE instruction notes) are hidden so that they don't clutter the page for
              // our internal teams.
              if(requirement.requirement_type !== 'standard') {
                return null;
              }

              return <RoundTasksRequirementsRow
                {...this.props}
                key={requirement.id || index}
                index={index}
                requirement={requirement}
                renderInput={this.renderInput}
                onDragStart={this.onDragStart}
                onDragEnd={this.onDragEnd}
                onDragOver={this.onDragOver}
                draggedOver={this.state.dragOverIndex === index}
                dragOverIndex={this.state.dragOverIndex}
              />
            })
          }
        </div>
        {
          /* Check that we have anything to render from the palette */
          Object.keys(this.props.palette || {}).length != 0 &&
          <RoundTasksTemplateLegend
            proxyUrl={this.props.proxyUrl}
            taskId={this.props.roundTask.id}
            templateIds={requirements.map(x => _.get(x, 'based_on.id')).filter(id => !!id)}
            instructionApproved={this.props.instructionApproved}
          />
        }
      </React.Fragment>
    )
  }

  renderRequirementDescriptions() {
    const requirements = this.state.sorted ? this.props.requirements : _.sortBy(this.props.requirements, ['sort']);

    return requirements.map((req) =>
      <div key={req.id} className='round_task_requirements_view-description'>
        <i className='fas fa-square'/>
        <AutoCreateAnchorTags text={req.description} />
      </div>
    )
  }

  renderAddRequirementButton() {
    let iconClass = 'fas fa-plus-circle';
    if(this.state.requirementLoading) {
      iconClass = 'fas fa-spin fa-circle-notch';
    };

    return <button className='btn btn-default add_requirement_btn' onClick={this.addRequirement} disabled={this.state.requirementLoading}>
      <i className={iconClass}></i> Add Content Requirement
    </button>;
  }

  changeRequirementView(){
    this.setState({
      viewRequirements: !this.state.viewRequirements
    });
  }


  render() {
    const inCardClass = this.props.inCard ? 'in_card' : '';
    let requirementCount = this.props.requirements.filter(req => req.requirement_type === 'standard').length;
    let deletedCount = 0;

    let requirements_title
    if(this.props.displayPage == 'round'){
      requirements_title = 'Content Requirements'
    }else{
      requirements_title = 'Template Content Requirements'
    }

    this.props.requirements.map(requirement => {
      if(requirement.destroy && requirement._destroy.value) {
        ++deletedCount
      }
    });

    return (
      <React.Fragment>
        {this.props.isOpen ?
          <form className={`form round_task_requirements_container ${inCardClass}`}>
            <div className='requirements_container_title'>{requirements_title}</div>
            { requirementCount > 0 && requirementCount > deletedCount && this.renderRequirements() }

            <div className='requirement_btn_footer'>
              {this.renderAddRequirementButton()}
            </div>
          </form>
          :
          <div className='round_task_requirements_view'>
            <h5 className='round_task_requirements_count_label' onClick={this.changeRequirementView}>
              <i>{requirementCount} {requirements_title}...</i>
              { this.state.viewRequirements && <i className="fas fa-lg fa-chevron-up" /> }
              { !this.state.viewRequirements && <i className="fas fa-lg fa-chevron-down" /> }
            </h5>
            { this.state.viewRequirements && this.renderRequirementDescriptions() }
          </div>
        }
      </React.Fragment>
    );
  }
};

RoundTasksRequirements.displayName = 'RoundTasks.Requirements';
