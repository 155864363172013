import { createStore } from 'redux';

const initialState = {
  order: [ 'tab1', 'tab2' ], // Also determines visibility
  tabs: { tab1: 'Tab 1', tab2: 'Tab 2' },
  links: { tab1: '#tab1', tab2: '#tab2'},
  activeTab: 'tab1',
  counts: {},
  viewport: { height: null, width: null},
  viewAs: 'desktop',
  mobileMenuOpen: false,
  showProfileTabsErrors: true
};

const MobileBreakPoint = '575';

const reducer = (state = initialState, action) => {
  switch(action.type) {
  case 'INITIALIZE':
    return {
      ...state,
      [action.value.field]: action.value.data
    };
  case 'SET_TABS':
    return {
      ...state,
      tabs: action.data
    };
  case 'SET_ACTIVE_TAB':
    return {
      ...state,
      activeTab: action.value
    };
  case 'SET_VIEWPORT':
    return {
      ...state,
      viewport: action.data,
      viewAs: action.data.width <= MobileBreakPoint ? 'mobile' : 'desktop'
    };
  case 'SET_MENU':
    return {
      ...state,
      mobileMenuOpen: action.value
    }
  case 'TOGGLE_MENU':
    return {
      ...state,
      mobileMenuOpen: !state.mobileMenuOpen
    };
  case 'LOAD_STATE':
    return {
      ...state,
      ...action.state
    };
  case 'HIDE_PROFILE_TABS_ERRORS':
    return {
      ...state,
      showProfileTabsErrors: false
    };
  default:
    return state;
  };
};

const NavigationStore = createStore(
  reducer,
  window && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({ name: 'Redux.NavigationStore' }) // Enable redux devtools middleware
);

export default NavigationStore;